import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemsTree } from '@fragments/itemsTree';
import { type ItemOptionsBar, type ItemSummary } from '@fragments/itemsTree/item';
import { type ItemsStructure } from '@fragments/screenItemsTree/utils';

import { BasePopup } from '@components/basePopup';
import { Icon } from '@components/icon';

import { getAPIItems, getSelectedAPIItem, setSelectedItemID } from '@reducers/index';

import { objectToHash } from '@globalUtils/strings';

import { type PostmanItems } from '@globalTypes/apis';

import { RequestTag } from './requestTag';

export const APISidebarPane = (): React.JSX.Element => {
  const dispatch = useDispatch();

  const [foldedItems, setFoldedItems] = useState<Record<string, boolean>>({});
  const [contextMenuIsOpen, setContextMenuIsOpen] = useState<boolean>(false);

  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [offset, setOffset] = useState<number>(0);

  const items = useSelector(getAPIItems);
  const selectedItem = useSelector(getSelectedAPIItem);
  const selectedItemId = selectedItem?.id ?? '';

  const arrayIncludesItemName = (arr: ItemsStructure[], name: string): [boolean, number] => {
    const isInside = false;
    let counter = 0;
    for (const item of arr) {
      if (item.name === name) return [true, counter];
      counter++;
    }
    return [isInside, -1];
  };

  const getStructuredItems = (items: PostmanItems): ItemsStructure => {
    let idCounter = 1;
    const parentName = 'test';
    const res: ItemsStructure = {
      id: idCounter.toString(),
      pid: '',
      kind: 'collection',
      name: parentName,
      collapsable: true,
      children: [],
    };
    idCounter++;
    for (const itemsKey in items) {
      const item = items[itemsKey];
      let currentRef = res;
      const path = itemsKey.split('/');
      let pid = parentName;
      for (let i = 0; i < path.length; i++) {
        const partPath = path[i];
        if (i === path.length - 1) {
          currentRef.children.push({
            id: item.id,
            pid, // It should be the last collection before the request name
            kind: item.request.method,
            name: partPath,
            collapsable: false,
            children: [],
          });
        } else {
          const found = arrayIncludesItemName(currentRef.children, partPath);
          const isInside = found[0];
          let pos = found[1];
          if (isInside) {
            pid = partPath;
          } else {
            currentRef.children.push({
              id: idCounter.toString(),
              pid,
              kind: 'folder',
              name: partPath,
              collapsable: true,
              children: [],
            });
            idCounter++;
            pid = partPath;
            pos = currentRef.children.length - 1;
          }
          currentRef = currentRef.children[pos];
        }
      }
    }
    return res;
  };

  const itemsStructured = getStructuredItems(items);

  const getItemIcon = (item: ItemSummary): React.JSX.Element => {
    if (item.kind === 'collection') return <Icon icon="view_compact_alt" color={'var(--primary-color)'} />;
    if (item.kind === 'folder') return <Icon icon="folder" color={'var(--primary-color)'} />;
    return <RequestTag method={item.kind} />;
  };

  const optionsBar: ItemOptionsBar<string> = [
    {
      id: '1',
      content: <Icon icon="more_vert" color="var(--title-color)" />,
      type: 'context',
    },
  ];

  const contextMenu = (
    <BasePopup offsetY={offset}>
      <div
        style={{
          left: position[0] + 4 + 'px',
          top: position[1] - 4 + 'px',
          position: 'absolute',
          backgroundColor: 'var(--background-color-1)',
          borderRadius: 'var(--border-radius-m)',
          border: '1px solid var(--border-color)',
          padding: 'var(--padding-xs)',
          zIndex: 10,
          fontSize: 'var(--font-size-body)',
        }}
      >
        <div>Hola</div>
        <div>Hola</div>
        <div>Hola</div>
        <div>Hola</div>
        <div>Hola</div>
      </div>
    </BasePopup>
  );

  const itemsTree = (
    <ItemsTree
      itemOptionsBar={optionsBar}
      key={objectToHash(foldedItems)}
      items={itemsStructured}
      currentlySelectedItem={selectedItemId}
      currentlyCollapsedItems={foldedItems}
      itemIconPreviewGetter={getItemIcon}
      notifyChangeHierarchy={(from: string, to: string, id: string, position: number) => {
        console.log(
          'moved item ' + id + ' from parent ' + from + ' to new parent ' + to + ' at position ' + position
        );
      }}
      notifyItemClick={(itemId: string) => {
        dispatch(setSelectedItemID(itemId));
      }}
      notifyItemFolding={(id: string, folded: boolean) => {
        const copy: Record<string, boolean> = JSON.parse(JSON.stringify(foldedItems));
        copy[id] = copy[id] != null ? !copy[id] : true;
        setFoldedItems(copy);
      }}
      notifyItemHovered={() => {}}
      notifyContextMenuOpened={(item: ItemSummary, position: [number, number], offset: number) => {
        setContextMenuIsOpen((prev) => !prev);
        setPosition(position);
        setOffset(offset);
      }}
      contextMenuView={contextMenu}
      contextMenuOpen={contextMenuIsOpen}
      notifyAddViewMenuOpened={() => {}}
    />
  );
  return (
    <div className="hide-native-scrollbar" style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
      {itemsTree}
    </div>
  );
};
