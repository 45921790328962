import { Icon } from '..';
import React, { useEffect, useRef, useState } from 'react';

import styles from './styles.module.css';

const propsAreEqual = (a: IInputNumber, b: IInputNumber): boolean => {
  if (a.allowDecimals !== b.allowDecimals) return false;
  if (a.min !== b.min) return false;
  if (a.max !== b.max) return false;
  if (a.step !== b.step) return false;
  if (a.defaultValue !== b.defaultValue) return false;
  if (a.disabled !== b.disabled) return false;
  if (a.expandedWidth !== b.expandedWidth) return false;
  if (a.notifyValueUpdate !== b.notifyValueUpdate) return false;
  return true;
};

export interface IInputNumber {
  min?: number;
  max?: number;
  step?: number;
  allowDecimals?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  expandedWidth?: boolean;
  notifyValueUpdate?: (val: string) => any;
}

export const InputNumber = React.memo(
  ({
    min = 0,
    max = 9999,
    step = 1,
    allowDecimals = false,
    defaultValue = '',
    disabled = false,
    expandedWidth = false,
    notifyValueUpdate,
  }: IInputNumber): React.JSX.Element => {
    const mDefaultValue = defaultValue !== 'NaN' ? defaultValue.toString() : '';
    const [currentVal, setCurrentVal] = useState<string>(mDefaultValue);
    const alreadyEditedRef = useRef<boolean>(false);

    useEffect(() => {
      alreadyEditedRef.current = false;
      setCurrentVal(defaultValue !== 'NaN' ? defaultValue.toString() : '');
    }, [defaultValue]);

    useEffect(() => {
      if (notifyValueUpdate != null && alreadyEditedRef.current) notifyValueUpdate(currentVal);
    }, [currentVal]);

    const parseNumber = (val: string): number => {
      if (allowDecimals) return parseFloat(val);
      else return parseInt(val);
    };

    const handleChangeNumber = (val: string): void => {
      if (!alreadyEditedRef.current) alreadyEditedRef.current = true;
      if (val === '' || val === 'NaN') {
        setCurrentVal('');
        return;
      }
      const mInt = parseNumber(val);
      if (isNaN(mInt)) {
        setCurrentVal('');
        return;
      }
      if (mInt >= min && mInt <= max) setCurrentVal(mInt.toString());
      else {
        if (mInt > max) setCurrentVal(max.toString());
        else if (mInt < min) setCurrentVal(min.toString());
      }
    };

    const handleStepUp = (): void => {
      if (!disabled) {
        if (currentVal === '') handleChangeNumber(min.toString());
        else handleChangeNumber((parseNumber(currentVal) + step).toString());
      }
    };

    const handleStepDown = (): void => {
      if (!disabled) {
        if (currentVal === '') handleChangeNumber(min.toString());
        else handleChangeNumber((parseNumber(currentVal) - step).toString());
      }
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          disabled={disabled}
          style={{
            cursor: disabled ? 'not-allowed' : 'auto',
            backgroundColor: disabled ? 'var(--background-color-disabled-1)' : '',
          }}
          className={styles.inputNumber + (expandedWidth ? ' ' + styles.expanded : '')}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChangeNumber(e.currentTarget.value);
          }}
          type="text"
          spellCheck={false}
          value={currentVal}
        />
        <div className={styles.arrowButtonContainer}>
          <div
            className={styles.arrowButton}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              handleStepUp();
            }}
          >
            <Icon icon="expand_less" color="var(--title-color)" iconSize="small" />
          </div>
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: 'var(--border-color-tooltip)',
              flexShrink: 0,
            }}
          ></div>
          <div
            className={styles.arrowButton}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              handleStepDown();
            }}
          >
            <Icon icon="expand_more" color="var(--title-color)" iconSize="small" />
          </div>
        </div>
      </div>
    );
  },
  propsAreEqual
);
InputNumber.displayName = 'InputNumber';
