import React from 'react';

import { Icon, type IconProps } from '@components/icon';

import styles from './styles.module.css';

export interface IconButtonProps extends IconProps {
  children?: string;
  textColor?: string;
  alignment?: string;
  onClick?: (e?: any) => void;
  paddingVertical?: string;
  paddingHorizontal?: string;
  width?: string;
  height?: string;
  variant?: 'primary' | 'secondary' | 'outline' | 'outline-borderDefault' | 'success';
  hover?: 'none' | 'solid' | 'outline' | 'outline-primary';
  cursor?: 'clickable' | 'default' | 'link';
  link?: string;
  backgroundColor?: string;
  border?: string;
  disabled?: boolean;
  hasIcon?: boolean;
  hasText?: boolean;
}

export const IconButton = ({
  children = '',
  icon,
  color,
  textColor = 'inherit',
  onClick,
  alignment = 'center',
  iconSize = 'large',
  paddingVertical,
  paddingHorizontal = '16px',
  variant = 'primary',
  hover = 'none',
  width = 'fit-content',
  height = 'var(--button-height)',
  cursor = 'clickable',
  backgroundColor,
  border = '1px solid var(--primary-color)',
  link,
  disabled = false,
  bold = false,
  hasIcon = true,
  hasText = true,
}: IconButtonProps): React.JSX.Element => {
  const measures: string[] = paddingHorizontal.match(/[a-zA-Z]+|[0-9]+/g) ?? [];

  const halfMeasure = measures[0] === '0' ? 0 : parseInt(measures[0]) / 2;
  const measureUnit = measures[1];
  const marginLeftText = `${halfMeasure}${measureUnit}`;

  const iconColor = color ?? 'var(--title-color)';

  const hoverClass = `buttonContainerHover-${hover}`;

  const mPaddingVertical = paddingVertical ?? '0px';

  const mHeight = paddingVertical != null ? 'fit-content' : height;

  const mBackgroundColor = backgroundColor ?? '';

  const clickHandler = (e: any): void => {
    if (onClick != null) onClick(e);
    else if (link != null) {
      window.open(link, '_blank')?.focus();
    }
  };

  return (
    <div
      className={`${styles.buttonContainer} ${styles[variant]} ${styles[cursor]} ${
        styles[hoverClass as keyof typeof styles]
      } ${disabled ? styles.disabled : ''}`}
      onClick={(e) => {
        clickHandler(e);
      }}
      style={{
        padding: `${mPaddingVertical} ${paddingHorizontal}`,
        width,
        height: mHeight,
        justifyContent: alignment,
        backgroundColor: mBackgroundColor,
        border: border !== '' ? border : '',
      }}
    >
      {hasIcon && (
        <Icon iconSize={iconSize} icon={icon} color={disabled ? 'var(--font-disabled-color-2)' : iconColor} />
      )}
      {hasText && (
        <div
          className={styles.buttonText}
          style={{
            marginLeft: hasIcon ? marginLeftText : '',
            marginRight: hasIcon ? `${halfMeasure / 2}${measureUnit}` : '',
            color: textColor,
            fontWeight: bold ? 600 : 400,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
