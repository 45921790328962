import React, { useState, useEffect } from 'react';

import { type ITab, Tab } from '@components/tab';

export const Tabs = ({
  tabs,
  width = '100%',
  height = 'var(--button-height)',
  notifySelectedTab,
  defaultSelected,
}: {
  tabs: ITab[];
  width?: string;
  height?: string;
  defaultSelected?: string;
  notifySelectedTab: (tab: ITab) => any;
}): React.JSX.Element => {
  const initiallySelected = defaultSelected ?? tabs[0].value;
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState<string>(initiallySelected);

  const notifyClickTab = (value: string): void => {
    setCurrentlySelectedTab(value);
    const mTab = tabs.find((tab) => tab.value === value);
    if (mTab != null) notifySelectedTab(mTab);
  };

  useEffect(() => {
    if (defaultSelected == null) return;
    if (defaultSelected !== currentlySelectedTab) setCurrentlySelectedTab(defaultSelected);
  }, [defaultSelected]);

  return (
    <div
      style={{
        width,
        height,
        backgroundColor: 'var(--input-field-background-color)',
        borderRadius: 'var(--border-radius-m)',
        display: 'flex',
        minWidth: '0',
        border: '1px solid var(--border-color)',
        overflow: 'hidden',
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={'tab-' + tab.value}
          selected={currentlySelectedTab === tab.value}
          notifyClick={notifyClickTab}
          label={tab.label}
          value={tab.value}
        />
      ))}
    </div>
  );
};
