import { MVERTICAL } from '@compiler/constants/alignmentOrientationConstants';
import { DEFAULT_COLOR_WHITE } from '@compiler/constants/colorConstants';
import { MAIN, SCREEN } from '@compiler/constants/screenConstants';

import {
  AUTHOR,
  CANONICAL,
  COLOR,
  DESCRIPTION,
  IS_MAIN,
  KEYWORDS,
  LEFT,
  ROBOTS,
  TITLE,
  TOP,
  UNIT_PX,
  URL,
} from '@globalTypes/constants';

import {
  BackgroundColorType,
  CommonKindType,
  type CurrentVariantType,
  DirectionsType,
  type GroupVariablesType,
  HorizontalAlignmentType,
  IdType,
  type NameChangedType,
  NameType,
  OrientationType,
  PaddingsType,
  type ReferencesType,
  ScrollableType,
  SizeValueType,
  TagType,
  VerticalAlignmentType,
} from './common';

export namespace IsMainType {
  export type IsMainProperty = 'isMain';
  export const IsMainProperty: IsMainProperty = IS_MAIN;

  export type IsMainType = boolean;
}

export namespace UrlType {
  export type UrlProperty = 'url';
  export const UrlProperty: UrlProperty = URL;

  export type UrlType = string;
}

export namespace TitleType {
  export type TitleProperty = 'title';
  export const TitleProperty: TitleProperty = TITLE;

  export type TitleType = string;
}

export namespace DescriptionType {
  export type DescriptionProperty = 'description';
  export const DescriptionProperty: DescriptionProperty = DESCRIPTION;

  export type DescriptionType = string;
}

export namespace KeywordsType {
  export type KeywordsProperty = 'keywords';
  export const KeywordsProperty: KeywordsProperty = KEYWORDS;

  export type KeywordsType = string;
}

export namespace AuthorType {
  export type AuthorProperty = 'author';
  export const AuthorProperty: AuthorProperty = AUTHOR;

  export type AuthorType = string;
}

export namespace CanonicalType {
  export type CanonicalProperty = 'canonical';
  export const CanonicalProperty: CanonicalProperty = CANONICAL;

  export type CanonicalType = string;
}

export namespace RobotsType {
  export type RobotsProperty = 'robots';
  export const RobotsProperty: RobotsProperty = ROBOTS;

  export type RobotsType = string;
}

export interface ScreenProperties {
  paddingsLocked?: boolean;
  marginsLocked?: boolean;
  [CommonKindType.CommonKindProperty]: 'screen';
  [IdType.IdProperty]: IdType.IdType;
  [TagType.TagProperty]: TagType.TagType;
  [NameType.NameProperty]: NameType.NameType;
  [BackgroundColorType.BackgroundColorProperty]: BackgroundColorType.BackgroundColorType;
  [VerticalAlignmentType.VerticalAlignmentProperty]: VerticalAlignmentType.VerticalAlignmentType;
  [HorizontalAlignmentType.HorizontalAlignmentProperty]: HorizontalAlignmentType.HorizontalAlignmentType;
  [OrientationType.OrientationProperty]: OrientationType.OrientationType;
  [ScrollableType.ScrollableProperty]: ScrollableType.ScrollableType;
  [IsMainType.IsMainProperty]: IsMainType.IsMainType;
  [PaddingsType.PaddingsProperty]: PaddingsType.PaddingsType;
  [UrlType.UrlProperty]: UrlType.UrlType;
  [TitleType.TitleProperty]: TitleType.TitleType;
  [DescriptionType.DescriptionProperty]: DescriptionType.DescriptionType;
  [KeywordsType.KeywordsProperty]: KeywordsType.KeywordsType;
  [AuthorType.AuthorProperty]: AuthorType.AuthorType;
  [CanonicalType.CanonicalProperty]: CanonicalType.CanonicalType;
  [RobotsType.RobotsProperty]: RobotsType.RobotsType;
  [NameChangedType.NameChangedProperty]?: NameChangedType.NameChangedType;
  [ReferencesType.ReferencesProperty]?: ReferencesType.ReferencesType;
  [GroupVariablesType.GroupVariablesProperty]?: GroupVariablesType.GroupVariablesType;
  [CurrentVariantType.CurrentVariantProperty]?: CurrentVariantType.CurrentVariantType;
}

export const DefaultScreen: ScreenProperties = {
  [CommonKindType.CommonKindProperty]: SCREEN,
  [IdType.IdProperty]: '0',
  [TagType.TagProperty]: MAIN,
  [BackgroundColorType.BackgroundColorProperty]: {
    [BackgroundColorType.BackgroundColorKindProperty]: COLOR,
    [BackgroundColorType.BackgroundColorDataProperty]: DEFAULT_COLOR_WHITE,
  },
  [NameType.NameProperty]: SCREEN,
  [PaddingsType.PaddingsProperty]: {
    [DirectionsType.TopProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.BottomProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.LeftProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.RightProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [VerticalAlignmentType.VerticalAlignmentProperty]: TOP,
  [HorizontalAlignmentType.HorizontalAlignmentProperty]: LEFT,
  [OrientationType.OrientationProperty]: MVERTICAL,
  [ScrollableType.ScrollableProperty]: 'auto',
  [IsMainType.IsMainProperty]: false,
  [UrlType.UrlProperty]: '',
  [TitleType.TitleProperty]: '',
  [DescriptionType.DescriptionProperty]: '',
  [KeywordsType.KeywordsProperty]: '',
  [AuthorType.AuthorProperty]: '',
  [CanonicalType.CanonicalProperty]: '',
  [RobotsType.RobotsProperty]: '',
};
