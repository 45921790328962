export const PARENTESIS_PLUS = ') + ';
export const PARENTESIS_MINUS = ') - ';
export const LESS_THAN = '<';
export const MORE_THAN = '>';
export const SEMICOLON = ';';
export const SEMICOLON_LINE_JUMP = ';\n';
export const LINE_JUMP = '\n';
export const SLASH = '/';
export const VAR = '--';
export const UNDERSCORE = '_';
export const CLOSING_PARENTESIS = ')';
export const TWO_CLOSING_PARENTESIS = '))';
export const THREE_CLOSING_PARENTESIS = ')))';
export const TWO_POINTS_SPACE = ': ';
export const TWO_POINTS = ':';
export const BRACKET = '{';
export const SQUARE_BRACKET = '[';
export const CLOSE_BRACKET = '}';
export const CLOSE_SQUARE_BRACKET = ']';
export const EQUAL_BRACKET = '={';
export const EQUAL_QUOTE = '="';
export const BRACKET_OPEN = '{\n';
export const BRACKET_CLOSE = '\n}\n';
export const TRIPLE_PATH = '../../../';
export const DOUBLE_PATH = '../../';
export const PATH = '../';
export const SAME_PATH = './';
export const SPACE_PLUS = ' + ';
export const COMMA = ',';
export const POINT = '.';
export const MINUS = '-';
export const TERNARY_OPEN = '? "';
export const TERNARY_CLOSE = '" : "';
export const LAYOUT_THEME_SEPARATOR = '😀';
export const PARAMETER_SEPARATOR = '🚀';
export const EQUAL = '=';
export const ASTERISK = ' * ';
export const DIFFERENT = ' != "';
export const AT_SIGN = '@';
export const MONEY_SIGN = '$';
export const PARENTESIS = '(';
export const NUMBER_SIGN = '#';
export const CSS_CLOSING = ');\n}\n';
export const TRUE_COMMA = 'true,';
export const FALSE_COMMA = 'false,';
