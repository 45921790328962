export const TOP = 'top';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
export const RIGHT = 'right';
export const MARGINS = 'margins';
export const MARGIN_LEFT = 'margin-left';
export const MARGIN_RIGHT = 'margin-right';
export const MARGIN_TOP = 'margin-top';
export const MARGIN_BOTTOM = 'margin-bottom';
export const PADDINGS = 'paddings';
export const POSITIONS = 'positions';
export const PADDING = 'padding';
export const MARGIN = 'margin';
export const BRACKET_POSITION = '{position}';
export const BRACKET_PADDING = '{padding}';
export const MARGIN_BRACKET = '{margin}';
export const SCREEN_PADDING = '{screenPadding}';
