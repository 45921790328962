import { IMAGE_VIEW } from '@compiler/constants/imageConstants';

import { DEFAULT, FIX, SRC, UNIT_PX } from '@globalTypes/constants';

import {
  type AnimationType,
  type AnimationWhenVisibleType,
  type AspectRatio,
  type ClassNamesType, type ClassesType,
  type ClassnameType,
  CommonKindType,
  type CurrentVariantType,
  CursorPointerType,
  type CustomStylesType,
  DimensionType,
  DirectionsType,
  type GroupVariablesType,
  HeightType,
  type HrefType,
  IdType,
  IndexType,
  MarginsType,
  type NameChangedType,
  NameType,
  ParentType,
  type ReferencesType,
  SizeValueType,
  type StateType,
  type VariantType,
  VisibleType,
  WidthType,
} from './common';

export namespace ImageViewSrcType {
  export type ImageViewSrcProperty = 'src';
  export const ImageViewSrcProperty: ImageViewSrcProperty = SRC;

  export type ImageViewSrcType = string;
}

export interface ImageViewProperties {
  paddingsLocked?: boolean;
  marginsLocked?: boolean;
  [AspectRatio.AspectRatioProperty]?: AspectRatio.AspectRatioType;
  [WidthType.WidthProperty]: WidthType.WidthType;
  [HeightType.HeightProperty]: HeightType.HeightType;
  [MarginsType.MarginsProperty]: MarginsType.MarginsType;
  [CommonKindType.CommonKindProperty]: 'imageView';
  [IdType.IdProperty]: IdType.IdType;
  [NameType.NameProperty]: NameType.NameType;
  [CursorPointerType.CursorPointerProperty]: CursorPointerType.CursorPointerType;
  [VisibleType.VisibleProperty]: VisibleType.VisibleType;
  [ParentType.ParentProperty]: ParentType.ParentType;
  [IndexType.IndexProperty]: IndexType.IndexType;
  [HrefType.HrefProperty]?: HrefType.HrefType;
  [AnimationType.AnimationProperty]?: AnimationType.AnimationType;
  [AnimationWhenVisibleType.AnimationWhenVisibleProperty]?: AnimationWhenVisibleType.AnimationWhenVisibleType;
  [ClassesType.ClassesProperty]?: ClassesType.ClassesType;
  [ClassnameType.ClassnameProperty]?: ClassnameType.ClassnameType;
  [CustomStylesType.CustomStylesProperty]?: CustomStylesType.CustomStylesType;
  [ImageViewSrcType.ImageViewSrcProperty]: ImageViewSrcType.ImageViewSrcType;
  [ClassNamesType.ClassNamesProperty]?: ClassNamesType.ClassNamesType;
  [NameChangedType.NameChangedProperty]?: NameChangedType.NameChangedType;
  [ReferencesType.ReferencesProperty]?: ReferencesType.ReferencesType;
  [GroupVariablesType.GroupVariablesProperty]?: GroupVariablesType.GroupVariablesType;
  [VariantType.VariantProperty]?: VariantType.VariantType;
  [CurrentVariantType.CurrentVariantProperty]?: CurrentVariantType.CurrentVariantType;
  [StateType.StateProperty]?: StateType.StateType;
}

export const DefaultImage: ImageViewProperties = {
  [WidthType.WidthProperty]: {
    [DimensionType.DimensionKindProperty]: FIX,
    [DimensionType.DimensionDataProperty]: {
      [SizeValueType.SizeValueProperty]: 115,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [HeightType.HeightProperty]: {
    [DimensionType.DimensionKindProperty]: FIX,
    [DimensionType.DimensionDataProperty]: {
      [SizeValueType.SizeValueProperty]: 90,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [MarginsType.MarginsProperty]: {
    [DirectionsType.TopProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.BottomProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.LeftProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.RightProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [CommonKindType.CommonKindProperty]: IMAGE_VIEW,
  [IdType.IdProperty]: '0',
  [NameType.NameProperty]: IMAGE_VIEW,
  [CursorPointerType.CursorPointerProperty]: 'none',
  [VisibleType.VisibleProperty]: true,
  [ParentType.ParentProperty]: '0',
  [IndexType.IndexProperty]: 0,
  [ImageViewSrcType.ImageViewSrcProperty]: DEFAULT,
};
