import { store } from '@src/reducers';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { WithAuth } from '@pages/signInPage';

import App from './app';
import './i18n';

const firebaseConfig = {
  apiKey: 'AIzaSyA_u2xZk5efn-rl7BAX67U1lIpSQigIwX0',
  authDomain: 'acqua-cb27b.firebaseapp.com',
  databaseURL: 'https://acqua-cb27b-default-rtdb.firebaseio.com',
  projectId: 'acqua-cb27b',
  storageBucket: 'acqua-cb27b.appspot.com',
  messagingSenderId: '633435186488',
  appId: '1:633435186488:web:0b19ff5fb591c9a0220dcd',
  measurementId: 'G-QXF81VSH1X',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

window.app = app;
window.auth = auth;
window.firestore = firestore;

const root = ReactDOM.createRoot(document.getElementById('rootReact') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WithAuth>
        <App />
      </WithAuth>
    </Provider>
  </React.StrictMode>
);
