import { ASPECT_RATIO, DATA, FILL, FIX, HEIGHT, KIND, WIDTH, WRAP } from '@globalTypes/constants';

import { type SizeValueType } from './sizeProperties';

export namespace DimensionType {
  export type DimensionDataProperty = 'data';
  export const DimensionDataProperty: DimensionDataProperty = DATA;
  export type DimensionKindProperty = 'kind';
  export const DimensionKindProperty: DimensionKindProperty = KIND;
  export type DimensionMaxProperty = 'max';
  export const DimensionMaxProperty: DimensionMaxProperty = 'max';
  export type DimensionMinProperty = 'min';
  export const DimensionMinProperty: DimensionMinProperty = 'min';
  export const DimensionKindFillPropertyType = FILL;
  export const DimensionKindWrapPropertyType = WRAP;
  export const DimensionKindFixPropertyType = FIX;
  export const DimensionKinds = [
    DimensionKindFillPropertyType,
    DimensionKindWrapPropertyType,
    DimensionKindFixPropertyType,
  ];
  export interface DimensionType {
    [DimensionKindProperty]: string;
    [DimensionDataProperty]?: SizeValueType.SizeValueType;
    [DimensionMaxProperty]?: SizeValueType.SizeValueType;
    [DimensionMinProperty]?: SizeValueType.SizeValueType;
  }
}

export namespace WidthType {
  export type WidthProperty = 'width';
  export const WidthProperty: WidthProperty = WIDTH;

  export type WidthType = DimensionType.DimensionType;
}

export namespace HeightType {
  export type HeightProperty = 'height';
  export const HeightProperty: HeightProperty = HEIGHT;

  export type HeightType = DimensionType.DimensionType;
}

export namespace AspectRatio {
  export type AspectRatioProperty = 'aspectRatio';
  export const AspectRatioProperty: AspectRatioProperty = ASPECT_RATIO;

  export type AspectRatioType = boolean;
}
