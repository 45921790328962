/* eslint-disable max-len */
export interface TypeItemDictionary {
  layerTemplate: string;
  layerStyle: string;
  imageViewTemplate: string;
  imageViewStyle: string;
  textTemplate: string;
  textStyle: string;
  iconTemplate: string;
  iconStyle: string;
  screenTemplate: string;
  screenStyle: string;
  baseSrcImage: string;
}

export const ReactItemDictionary: TypeItemDictionary = {
  layerTemplate: '{destination}<{tag} custom-agua-item="layer" {classNames} {variant} {ref} {reference} {href}>{children}</{tag}>',
  layerStyle: `.{name}-{id}{{componentVariable}
{orientation}{cursorPointer}{align}{borderStyle}{border}{shadow}{scrollable}{padding}{margin}{boxSizing}{color}{dimensions}{position}{wrap}{zIndex}{visibility}{reference}
}`,

  baseSrcImage: 'src={src}',
  imageViewTemplate: '{destination}{contentResponsive}<img custom-agua-item="imageView" {classNames} {variant} {ref} alt="{name}" {src} {reference} {href}/>',
  imageViewStyle: `.{name}-{id}{
{margin}{cursorPointer}{boxSizing}{dimensions}{visibility}{reference}
}`,

  textTemplate: '{destination}<{tag} custom-agua-item="text" {classNames} {variant} {ref} {reference} {href}>{content}</{tag}>',
  textStyle: `.{name}-{id}{
{textStyles}{cursorPointer}{margin}{boxSizing}{color}{dimensions}{visibility}{reference}
}`,

  iconTemplate: '{destination}<span custom-agua-item="icon" {classNames} {variant} {reference} {href}>{content}</span>',
  iconStyle: `.{name}-{id}{
{margin}{cursorPointer}{boxSizing}{color}{dimensions}{visibility}{reference}
}`,

  screenTemplate: `{animationImports}import React from 'react';{link}{customStylesImport}{aguaNotOverwritable}
{componentImports}{pageExternalFile}
{imagesVariables}{canvasMode}{pageHaveForm}
import styles from './{nameStyle}.module.css';

/**
* {name} component.
* @component
* @type {React.FC}
* 
* @returns {React.ReactElement} The {name} component.
*/
const {name} = () =>{
{animationRefs}
{hasOnclick}
{pageExternalFunctions}
function aguaRender() {
    return(
        <{tag} custom-agua-item="screen"  {classNames}>
            {projectContent}
        </{tag}>
    );
};

return aguaRender();
}

export default {name};
        `,

  screenStyle: `.{name}{{responsiveVariables}
      {screenPadding}{screenBackgroundColor}{screenOrientation}{screenAlignment}{screenScollable}{reference}{visibility}
  }`,
};
