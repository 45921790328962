export const VERTICAL_ALIGNMENT = 'verticalAlignment';
export const HORIZONTAL_ALIGNMENT = 'horizontalAlignment';
export const MVERTICAL_ALIGNMENT = 'VerticalAlignment';
export const MHORIZONTAL_ALIGNMENT = 'HorizontalAlignment';
export const AXIS = 'Axis-';
export const ALIGNMENT = 'Alignment';
export const JUSTIFY_CONTENT = 'justify-content';
export const ALIGN_ITEMS = 'align-items';
export const FLEX_START = 'flex-start';
export const BRACKET_VER_ALIGN = '{verticalAlignment}';
export const BRACKET_HOR_ALIGN = '{horizontalAlignment}';
export const BRACKET_ALIGN = '{align}';
export const VERTICAL_AXIS = '--verticalAxis-';
export const HORIZONTAL_AXIS = '--horizontalAxis-';
export const ALIGN_VAR = 'align-items: var(';
export const JUSTIFY_VAR = 'justify-content: var(';
export const ORIENTATION_VAR = '-orientation[custom-agua-item="';
export const ORIENTATION_MID = '"] {\n';
export const ORIENTATION_CLOSE = ');\n}';
export const FLEX_DIRECTION = 'flex-direction';
export const BRACKET_ORIENTATION = '{orientation}';
export const MORIENTATION = 'Orientation';
export const HORIZONTAL = 'Horizontal';
export const MHORIZONTAL = 'horizontal';
export const VERTICAL = 'Vertical';
export const MVERTICAL = 'vertical';
export const SCREEN_ORIENTATION = '{screenOrientation}';
export const SCREEN_ALIGNMENT = '{screenAlignment}';
export const ROW = 'row';
export const COLUMN = 'column';
export const ORIENTATION = 'orientation';
