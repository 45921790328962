import { COMMON_KIND, ID, INDEX, NAME, PARENT } from '@globalTypes/constants';

export namespace CommonKindType {
  export type CommonKindProperty = 'commonKind';
  export const CommonKindProperty: CommonKindProperty = COMMON_KIND;

  export type CommonKindType = string;
}

export namespace IdType {
  export type IdProperty = 'id';
  export const IdProperty: IdProperty = ID;

  export type IdType = string;
}

export namespace NameType {
  export type NameProperty = 'name';
  export const NameProperty: NameProperty = NAME;

  export type NameType = string;
}

export namespace ParentType {
  export type ParentProperty = 'parent';
  export const ParentProperty: ParentProperty = PARENT;

  export type ParentType = string;
}

export namespace IndexType {
  export type IndexProperty = 'index';
  export const IndexProperty: IndexProperty = INDEX;

  export type IndexType = number;
}
