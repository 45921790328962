export interface TypeColorDictionary {
  colorNumeral: string;
  backgroundColor: string;
  color: string;
}

export const ReactColorDictionary: TypeColorDictionary = {
  colorNumeral: '#',
  backgroundColor: 'background-color: ',
  color: 'background: ',
};
