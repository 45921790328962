export interface TypeImageDictionary {
  altImage: string;
  srcImage: string;
  baseSrcImage: string;
}

export const ReactImageDictionary: TypeImageDictionary = {
  altImage: 'alt="',
  srcImage: 'src="',
  baseSrcImage: 'src={src}',
};
