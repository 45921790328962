/* eslint-disable prefer-const */
import { type PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { get as idbGet } from 'idb-keyval';

import { type StateType } from '@reducers/store';

import { type ExtractedComponent, rawExtractor } from '@globalUtils/extractor';
import { b64toBlob } from '@globalUtils/strings';

import { type IFile, type IFiles } from '@globalTypes/conversor';

const initialAssets: IFiles = [];
const initialFiles: IFiles = [];
const initialComponent: ExtractedComponent[] = [];

export interface SavePayload {
  data: string;
  dirHandlePath: string;
}

export const FilesSlice = createSlice({
  name: 'files',
  initialState: {
    files: initialFiles,
    cwd: '',
    assets: initialAssets,
    currentFileName: '',
    currentlySelectedAsset: '',
    loadedComponents: initialComponent,
    dirHandlePath: '',
    saving: false,
  },
  reducers: {
    setCurrentlySelectedAsset: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      if (state.currentlySelectedAsset === id) state.currentlySelectedAsset = '';
      else state.currentlySelectedAsset = id;
    },
    saveData: (state, action: PayloadAction<SavePayload>) => {
      const { data } = action.payload;
      void fetch('http://localhost:4035/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });
      state.saving = false;
    },
    triggerSaveMiddleware: (state, action) => {
      state.saving = true;
    },
    setDirHandlePath: (state, action: PayloadAction<string>) => {
      state.dirHandlePath = action.payload;
    },
    setCWD: (state, action: PayloadAction<string>) => {
      state.cwd = action.payload;
    },
    setFiles: (state, action: PayloadAction<IFiles>) => {
      const files = action.payload;
      state.files = files;
      let extracted: ExtractedComponent[] = [];
      const assets: IFile[] = [];
      for (const file of files) {
        const fileName = file.name;
        if (fileName != null) {
          if (
            fileName.endsWith('.js') ||
            fileName.endsWith('.ts') ||
            fileName.endsWith('.jsx') ||
            fileName.endsWith('.tsx')
          ) {
            continue;
            /*
            if (
              fileName.endsWith('spec.js') ||
              fileName.endsWith('spec.ts') ||
              fileName.endsWith('spec.jsx') ||
              fileName.endsWith('spec.tsx') ||
              fileName.endsWith('test.js') ||
              fileName.endsWith('test.ts') ||
              fileName.endsWith('test.jsx') ||
              fileName.endsWith('test.tsx')
            ) {
              continue;
            }
            const data = rawExtractor(file.data, fileName);
            const mRes: ExtractedComponent[] = [];
            for (const compo of data) compo.path = fileName;
            for (const compo of data) {
              mRes.push({
                name: compo.name,
                id: compo.path + '/' + compo.name ?? '',
                path: compo.path ?? '',
                props: compo.props,
                display: compo.display ?? compo.name,
                type: compo.type,
              });
            }
            extracted = extracted.concat(mRes);
            */
          } else if (
            fileName.endsWith('.ico') ||
            fileName.endsWith('.svg') ||
            fileName.endsWith('.png') ||
            fileName.endsWith('.jpg') ||
            fileName.endsWith('.jpeg') ||
            fileName.endsWith('.mp4') ||
            fileName.endsWith('.webp') ||
            fileName.endsWith('.gif') ||
            fileName.endsWith('.ttf') ||
            fileName.endsWith('.woff') ||
            fileName.endsWith('.woff2') ||
            fileName.endsWith('.otf')
          ) {
            assets.push(file);
          }
        }
      }
      state.assets = assets;
      state.loadedComponents = extracted;
    },
    setAssets: (state, action: PayloadAction<IFiles>) => {
      state.assets = action.payload;
    },
    setCurrentFileName: (state, action: PayloadAction<string>) => {
      state.currentFileName = action.payload;
    },
  },
});

export const getCurrentFileName = (state: StateType): string => state.files.currentFileName;

export const getCWD = (state: StateType): string => state.files.cwd;

export const getFiles = (state: StateType): IFiles => state.files.files;

export const getAssets = (state: StateType): IFiles => state.files.assets;

export const getCurrentlySelectedAsset = (state: StateType): string => state.files.currentlySelectedAsset;

export const getLoadedComponents = createSelector(
  (state: StateType) => state.files.loadedComponents,
  (components) => {
    let res = [];
    for (const compo of components) {
      const firstLetter = compo.name[0];
      if (firstLetter == null || firstLetter === '') continue;
      if (firstLetter !== firstLetter.toUpperCase()) continue;
      const name = compo.name;
      if (name.length > 2 && name.toUpperCase() === name) continue;
      res.push(compo);
    }
    const mRes: Record<string, ExtractedComponent[]> = {};
    res.forEach((component: ExtractedComponent) => {
      if (mRes[component.name] == null) mRes[component.name] = [];
      mRes[component.name].push({
        id: component.path + '/' + component.name ?? '',
        path: component.path,
        props: component.props,
        name: component.name,
        display: component.name,
        type: component.type,
      });
    });
    let tRes: ExtractedComponent[] = [];
    for (const displayName in mRes) {
      const group = mRes[displayName];
      const l = group.length;
      const groupNames: Record<string, number> = {};
      const firstIndexes: Record<string, number> = {};
      let hadDuplicates = false;
      for (const component of group) {
        if (l > 1) {
          const pathParts = component.path?.split('/') ?? [];
          let mDisplay = '(' + pathParts[pathParts?.length - 1] + ') ' + component.display;
          const timesAppeared = groupNames[mDisplay];
          if (timesAppeared == null || timesAppeared === 0) {
            groupNames[mDisplay] = 1;
            firstIndexes[mDisplay] = tRes.length;
          } else {
            groupNames[mDisplay]++;
            mDisplay += ' ' + (timesAppeared + 1);
            component.id += timesAppeared + 1;
            hadDuplicates = true;
          }
          component.display = mDisplay;
        }
        tRes.push(component);
      }
      if (hadDuplicates) {
        const mGroups = Object.keys(groupNames);
        for (const key of mGroups) {
          const numberOfDuplicates = groupNames[key];
          if (numberOfDuplicates < 2) continue;
          const lastIndex = firstIndexes[key];
          const prevDisplay = tRes[lastIndex].display;
          tRes[lastIndex].display = prevDisplay + ' 1';
        }
      }
    }

    tRes = tRes.sort((a, b) => {
      let mDisplayA = a.display;
      const indexOfOpenA = mDisplayA.indexOf('(');
      const indexOfCloseA = mDisplayA.indexOf(')');
      if (indexOfOpenA !== -1 && indexOfCloseA !== -1 && indexOfOpenA < indexOfCloseA) {
        mDisplayA = mDisplayA.substring(indexOfCloseA + 1).trim();
      }
      let mDisplayB: any = b.display;
      const indexOfOpenB = mDisplayB.indexOf('(');
      const indexOfCloseB = mDisplayB.indexOf(')');
      if (indexOfOpenB !== -1 && indexOfCloseB !== -1 && indexOfOpenB < indexOfCloseB) {
        mDisplayB = mDisplayB.substring(indexOfCloseB + 1).trim();
      }
      return mDisplayA > mDisplayB ? 1 : -1;
    });
    return tRes;
  }
);

const writeAguaFiles = (files: any, path: string, dirHandle: any): void => {
  for (const mFile in files) {
    const content = files[mFile];
    if (typeof content === 'object') writeAguaFiles(content, path + '/' + mFile, dirHandle);
    else void writeAguaFile(path + '/' + mFile, content, dirHandle);
  }
};

const writeAguaFile = async (path: string, content: string, dirHandle: any): Promise<void> => {
  if (content == null || path == null) return;
  let mPath = path;
  if (mPath.indexOf('/') === 0) mPath = mPath.substring(1);
  const route = mPath.split('/');
  for (let i = 0; i < route.length - 1; i++) {
    dirHandle = await dirHandle.getDirectoryHandle(route[i], {
      create: true,
    });
  }
  const newFileHandle = await dirHandle.getFileHandle(route[route.length - 1], { create: true });
  const writable = await newFileHandle.createWritable();
  let mContent;

  let kind;
  if (path.endsWith('.ttf')) kind = 'font/ttf';
  else if (path.endsWith('.otf')) kind = 'font/otf';
  else if (path.endsWith('.woff')) kind = 'font/woff';
  else if (path.endsWith('.woff2')) kind = 'font/woff2';
  else if (path.endsWith('.mp4')) kind = 'vide/mp4';
  else if (path.endsWith('.png')) kind = 'image/png';
  else if (path.endsWith('.jpg')) kind = 'image/jpeg';
  else if (path.endsWith('.jpeg')) kind = 'image/jpeg';
  else if (path.endsWith('.gif')) kind = 'image/gif';

  if (kind != null) mContent = b64toBlob(content, kind);
  else mContent = content;

  await writable.write(mContent);
  await writable.close();
};

export const {
  setCurrentlySelectedAsset,
  setCWD,
  setFiles,
  setAssets,
  triggerSaveMiddleware,
  saveData,
  setDirHandlePath,
  setCurrentFileName,
} = FilesSlice.actions;

export const FilesReducer = FilesSlice.reducer;
