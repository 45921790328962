import React, { useEffect, useState } from 'react';

import { Icon } from '@components/icon';

import styles from './styles.module.css';

function propsAreEqual(a: SideMenuProps, b: SideMenuProps): boolean {
  if (JSON.stringify(a.options) !== JSON.stringify(b.options)) return false;
  if (a.defaultValue !== b.defaultValue) return false;
  if (a.notifyMenuClicked !== b.notifyMenuClicked) return false;
  return true;
}

export interface SideMenuOption {
  label: string;
  value: string;
  icon: string;
}

export interface SideMenuProps {
  options: SideMenuOption[];
  defaultValue: string;
  notifyMenuClicked?: (tab: string) => any;
}

export const SideMenu = React.memo(
  ({ options, defaultValue, notifyMenuClicked }: SideMenuProps): React.JSX.Element => {
    const [selectedMenu, setSelectedMenu] = useState<string>(defaultValue);

    useEffect(() => {
      if (defaultValue !== selectedMenu) setSelectedMenu(defaultValue);
    }, [defaultValue]);

    const handleMenuSelected = (identifier: string): void => {
      setSelectedMenu(identifier);
      if (notifyMenuClicked != null) notifyMenuClicked(identifier);
    };

    return (
      <div className={styles.sideMenuContainer}>
        {options.map((option) => {
          return (
            <button
              key={option.value}
              className={
                styles.menuButton + ' ' + (selectedMenu === option.value ? styles.menuButtonSelected : '')
              }
              onClick={(e) => {
                handleMenuSelected(option.value);
              }}
            >
              <Icon iconSize="large" icon={option.icon} color="var(--tittle-color)" />
              <div className={styles.titleButton}>{option.label}</div>
            </button>
          );
        })}
      </div>
    );
  },
  propsAreEqual
);
SideMenu.displayName = 'SideMenu';
