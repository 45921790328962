/* eslint-disable multiline-ternary */
import { ConfigProvider, theme } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { ComponentTreeDataListener } from '@pages/pageComponentTreeExtractor';
import { WithFSLoaded } from '@pages/pageFS';
// import { WithFolderSelected } from '@pages/pageFolderSelection';
import { MainIframe } from '@pages/pageMainIframe';
import { WithServerOn } from '@pages/pageServer';
import { WithSetupReady } from '@pages/pageSetup';
import { WithViteSetupReady } from '@pages/pageSetupVite';
import { WithWebsocket } from '@pages/pageWebsocket';

import { getCurrentTheme } from '@reducers/themes';

import './index.css';
import { MainLanding } from './main';

const { defaultAlgorithm, darkAlgorithm } = theme;

function App(): React.JSX.Element {
  const theme = useSelector(getCurrentTheme);
  const isDarkMode = theme === 'dark';

  return (
    <ConfigProvider theme={{ cssVar: true, algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm }}>
      <WithServerOn>
        <WithFSLoaded>
          <WithWebsocket>
            <WithSetupReady>
              <WithViteSetupReady>
                <ComponentTreeDataListener>
                  <MainIframe>
                    <MainLanding />
                  </MainIframe>
                </ComponentTreeDataListener>
              </WithViteSetupReady>
            </WithSetupReady>
          </WithWebsocket>
        </WithFSLoaded>
      </WithServerOn>
    </ConfigProvider>
  );
}

export default App;
