import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  AT_SIGN,
  BRACKET,
  CHILDREN_VARIABLES,
  CLOSE_BRACKET,
  COLOR_VARIABLE,
  COMMA,
  COMMA_SET,
  COMPONENT_VARIABLE,
  CONST_CLOSE,
  CONST_OPEN,
  DIFFERENT,
  E,
  EQUAL,
  EQUAL_BRACKET,
  EQUAL_FALSE,
  EQUAL_TRUE,
  F,
  GRADIENT,
  IF,
  IS_NESTED,
  LINE_JUMP,
  MINUS,
  MONEY_SIGN,
  NUMBER_SIGN,
  POINT,
  SET,
  SET_CLOSE,
  SET_OPEN,
  SIZE_VARIABLE,
  SLASH,
  T,
  TEXT_VARIABLE,
  TWO_POINTS,
  UNDERSCORE,
  VISIBILITY_VARIABLE
} from '../../constants/constants';
import { type IComponentinfo, type TypeParamsInfo, type ReactInfo } from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';
import { adaptItemName } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { convertVariableinfo } from '../reactThemeMethods/reactVariableMethods';

export function loadLayoutChildrenVariableNested (parts: string[],
  defaultValuesNested: Record<string, Record<string, string>>, defaultValue: string, mref: string,
  defaultValues: Record<string, string>): void {
  const names = parts[3].split(COMMA);
  for (const name of names) {
    if (defaultValuesNested[name] == null) defaultValuesNested[name] = {};
    defaultValuesNested[name][mref] = defaultValue;
  }
  defaultValues[mref] = IS_NESTED + parts[3];
}

export function loadLayoutChildrenVariableDefaultValue (parts: string[]): string {
  const mKind = parts[1];
  let defaultValue = '';
  if (mKind === SIZE_VARIABLE) defaultValue = ('' + parts[0]).replaceAll(COMMA, '');
  else if (mKind === COLOR_VARIABLE) defaultValue = NUMBER_SIGN + parts[0];
  else if (mKind === TEXT_VARIABLE) defaultValue = parts[0];
  else defaultValue = parts[0];
  return defaultValue;
}

export function loadLayoutChildrenVariables (cRef: any, defaultValues: Record<string, string>,
  defaultValuesNested: Record<string, Record<string, string>>): void {
  for (const mref in cRef) {
    let parts = [cRef[mref].value, cRef[mref].type];
    if (parts[1].indexOf(GRADIENT) === 0) {
      parts = [parts[0] + parts[1], parts[2]];
    }
    const defaultValue = loadLayoutChildrenVariableDefaultValue(parts);
    if (parts[2] != null) {
      loadLayoutChildrenVariableNested(parts, defaultValuesNested, defaultValue, mref, defaultValues);
    } else defaultValues[mref] = defaultValue;
  }
}

export function getLayoutModeChildVariable (mref: string, item: TypeItemProperties,
  defaultValues: Record<string, string>, variables: string, nestedContent: Record<string, string>): string {
  if (!mref.includes(UNDERSCORE)) {
    const name = adaptItemName(item.name, false);
    if (!defaultValues[mref].includes(IS_NESTED)) {
      variables += mref + EQUAL_BRACKET + name.replaceAll(MINUS, '') + POINT + mref + CLOSE_BRACKET + ' ';
    } else {
      const nest = defaultValues[mref].split(MINUS)[1];
      const items = nest.split(COMMA);
      for (const item of items) {
        if (nestedContent[item] == null) {
          nestedContent[item] = item + EQUAL_BRACKET + name + POINT + item + CLOSE_BRACKET + ' ';
        }
      }
    }
  }
  return variables;
}

export function getLayoutModeChildrenVariables (item: TypeItemProperties, viewDOM: IComponentinfo): string {
  const componentName = 'component' in item && item.component !== '' ? item.component : '';
  const component = viewDOM.getComponentByName(componentName, viewDOM.components);
  if (component?.references == null) {
    window.logger.warn('component ' + componentName + ' not found');
    return '';
  }
  const cRef = component.references.variables;
  const defaultValues = {};
  const defaultValuesNested = {};
  loadLayoutChildrenVariables(cRef, defaultValues, defaultValuesNested);
  let variables = '';
  const nestedContent: Record<string, string> = {};
  for (const mref in cRef) {
    variables = getLayoutModeChildVariable(mref, item, defaultValues, variables, nestedContent);
  }
  for (const nested in nestedContent) {
    variables += nestedContent[nested] + ' ';
  }
  return variables;
}

export function adaptParametersCustomCode (parameterJson: string): string {
  const params = JSON.parse(parameterJson);
  let res = '';
  for (const mParam in params) {
    const value = params[mParam].value ?? params[mParam];
    res += mParam + EQUAL_BRACKET + '"' + value + '"' + CLOSE_BRACKET + ' ';
  }
  return res.trim();
}

export function adaptParametersInObjectQuote (info: TypeParamsInfo, parameterJson: string): void {
  if (info.lastChar === TWO_POINTS) info.parameters += info.actualChar;
  else if (parameterJson[info.index + 1] === COMMA) info.parameters += info.actualChar;
  else if (parameterJson[info.index + 1] === CLOSE_BRACKET && (info.index + 2) === parameterJson.length) {
    info.parameters += info.actualChar;
  }
}

export function adaptParametersInObjectComma (info: TypeParamsInfo, parameterJson: string): void {
  if ((info.lastChar === '"' && parameterJson[info.index + 1] === '"') ||
      (info.lastChar === CLOSE_BRACKET && parameterJson[info.index + 1] === '"') ||
      (parameterJson[info.index - 1] === E && parameterJson[info.index + 1] === '"')) {
    info.parameters += ' ';
  } else info.parameters += info.actualChar;
}

export function adaptParametersInObjectInformation (info: TypeParamsInfo, parameterJson: string): void {
  if (info.actualChar === TWO_POINTS && info.lastChar === '"' && parameterJson[info.index + 1] === '"') {
    info.parameters += EQUAL;
  } else if (info.actualChar === TWO_POINTS && parameterJson[info.index + 1] === BRACKET) {
    info.parameters += EQUAL;
  } else if (info.actualChar === TWO_POINTS &&
      (parameterJson[info.index + 1] === T || parameterJson[info.index + 1] === F)) {
    info.parameters += parameterJson[info.index + 1] === T ? EQUAL_TRUE : EQUAL_FALSE;
    info.index += parameterJson[info.index + 1] === T ? 4 : 5;
  } else info.parameters += info.actualChar;
}

export function adaptParametersInObject (info: TypeParamsInfo, parameterJson: string): void {
  if (info.actualChar === BRACKET) {
    if (info.lastChar !== '') {
      info.isInObject++;
      info.parameters += info.actualChar + BRACKET;
    }
  } else if (info.actualChar === '"') adaptParametersInObjectQuote(info, parameterJson);
  else if (info.actualChar === COMMA) adaptParametersInObjectComma(info, parameterJson);
  else if (!(info.actualChar === CLOSE_BRACKET && (info.index + 1) === parameterJson.length)) {
    adaptParametersInObjectInformation(info, parameterJson);
  }
}

export function adaptParametersNoObject (info: TypeParamsInfo, parameterJson: string): void {
  if (info.actualChar === CLOSE_BRACKET) {
    info.isInObject--;
    info.parameters += CLOSE_BRACKET;
    if (info.isInObject === 0) info.parameters += CLOSE_BRACKET;
  } else if (info.actualChar === BRACKET) {
    info.isInObject++;
    info.parameters += BRACKET;
  } else if (info.actualChar === '"') {
    if (info.lastChar === TWO_POINTS ||
      parameterJson[info.index + 1] === CLOSE_BRACKET ||
      parameterJson[info.index + 1] === COMMA) info.parameters += info.actualChar;
  } else info.parameters += info.actualChar;
}

export function adaptParameters (parameterJson: string): string {
  const info = {
    parameters: '',
    index: 0,
    isInObject: 0,
    lastChar: '',
    actualChar: ''
  };
  while (info.index < parameterJson.length) {
    info.actualChar = parameterJson[info.index];
    if (info.isInObject === 0) {
      adaptParametersInObject(info, parameterJson);
    } else {
      adaptParametersNoObject(info, parameterJson);
    }
    info.lastChar = info.actualChar;
    info.index++;
  }
  return info.parameters;
}

export function componentChildrenReferencesResponsive (neededInfo: ReactInfo, component: string,
  responsiveLength: number, reference: any, responsiveReference: string[], name: string,
  item: TypeItemProperties): string {
  let childrenResponsiveVariable = '';
  let desktopValues = '';
  let phoneValues = '';
  let tabletValues = '';
  for (let i = 0; i < responsiveLength; i++) {
    const info = responsiveReference[i];
    const val = info[0].substring(0, 1).toUpperCase() + info[0].substring(1);
    childrenResponsiveVariable += CONST_OPEN + info[0] + COMMA_SET + val + CONST_CLOSE + info[1][0] +
      SET_CLOSE + LINE_JUMP;
    const mVal = LINE_JUMP + IF + info[0] + DIFFERENT;
    desktopValues += mVal + info[1][0] + SET + val + SET_OPEN + info[1][0] + SET_CLOSE;
    phoneValues += mVal + info[1][1] + SET + val + SET_OPEN + info[1][1] + SET_CLOSE;
    tabletValues += mVal + info[1][2] + SET + val + SET_OPEN + info[1][2] + SET_CLOSE;
  }
  neededInfo.pageResponsiveVariables[neededInfo.pageName] = childrenResponsiveVariable;
  neededInfo.pagesResponsiveValues[neededInfo.pageName] = [desktopValues, phoneValues, tabletValues];
  const value = reference['component' in item && item.component !== ''
    ? item.component
    : name] ?? reference[name];
  let mVal = JSON.stringify(value).replaceAll('"' + MONEY_SIGN, '`' + MONEY_SIGN);
  mVal = mVal.replaceAll(CLOSE_BRACKET + '"', CLOSE_BRACKET + '`').replaceAll('"', '');
  component = component.replace(CHILDREN_VARIABLES, ' ' + mVal + ' ');
  return component;
}

export function setInActualRoute (references: any, item: TypeItemProperties, neededInfo: ReactInfo,
  dictionary: TypeDictionary, reference: any): void {
  for (const information of references) {
    const routes = adaptItemName(information[2].replaceAll(AT_SIGN, MINUS), true).split(SLASH);
    let actualRoute = reference;
    for (const route of routes) {
      if (actualRoute[route] == null) actualRoute[route] = {};
      actualRoute = actualRoute[route];
    }
    const theme = ('component' in item ? item.component : '') + UNDERSCORE + item.id;
    const isParam = information[5] ?? false;
    const value = convertVariableinfo(theme, information[0], information[1], neededInfo,
      COMPONENT_VARIABLE, isParam, dictionary);
    if (information[1][1] === VISIBILITY_VARIABLE) actualRoute[information[0]] = information[1][0];
    else actualRoute[information[0]] = value[0];
  }
}

export function handleComponentChildrenReferences (item: TypeItemProperties, neededInfo: ReactInfo,
  dictionary: TypeDictionary, component: string, pageName: string): string {
  if ('childrenReferences' in item) {
    const references = item.childrenReferences;
    if (references != null) {
      const reference: Record<string, string> = {};
      const responsiveReference: string[] = [];
      setInActualRoute(references, item, neededInfo, dictionary, reference);
      const name = adaptItemName(neededInfo.unmodifiedItem[item.id].name, true);
      const responsiveLength = responsiveReference.length;
      if (responsiveLength > 0) {
        component = componentChildrenReferencesResponsive(neededInfo, component, responsiveLength, reference,
          responsiveReference, name, item);
      } else if ('component' in item && item.component !== '') {
        neededInfo.pageResponsiveVariables[pageName] = '';
        const paramVal = reference[item.component.toLowerCase() ?? name] ?? reference[name];
        const parameters = adaptParameters(JSON.stringify(paramVal));
        component = component.replace(CHILDREN_VARIABLES, ' ' + parameters + ' ');
      }
      return component;
    } else {
      return component.replace(CHILDREN_VARIABLES, '');
    }
  } return component;
}

export function componentChildrenReferencesValues (component: string, item: TypeItemProperties,
  pageName: string, neededInfo: ReactInfo, dictionary: TypeDictionary): string {
  if ('customcode' in item && item.customcode != null) {
    const parameters = JSON.stringify(item.parameterValues ??
      neededInfo.infoThemes?.[item.component + UNDERSCORE + item.id] ?? '');
    const adaptedParameters = adaptParametersCustomCode(parameters);
    return component.replace(CHILDREN_VARIABLES, adaptedParameters);
  } else if ('childrenReferences' in item) {
    component = handleComponentChildrenReferences(item, neededInfo, dictionary, component, pageName);
  }
  return component.replace(CHILDREN_VARIABLES, '');
}
