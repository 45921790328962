import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderText } from '@components/headerText';

import { KeyBindings, KeySymbols } from '@globalUtils/shortcuts';

import styles from './styles.module.css';

const isMac = navigator.userAgent.includes('Mac OS X');

const commandKeys = Object.keys(KeyBindings);
const commands = commandKeys.map((command: string) => ({
  name: command,
  command: KeyBindings[command as keyof typeof KeyBindings][isMac ? 'mac' : 'windows'],
}));

export const ShortcutsPage = (): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className="hide-native-scrollbar"
      style={{ width: '100%', height: '100%', overflowY: 'scroll', padding: 'calc(var(--padding-l) * 2)' }}
    >
      <HeaderText fontSize="var(--font-size-title)" bold>
        {t('shortcuts-label')}
      </HeaderText>
      <div style={{ display: 'flex', width: '100%', marginTop: 'var(--padding-m)' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', flexShrink: 0 }}>
          {commands.map((command, i) => (
            <div
              key={'label' + command.name}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: 'var(--button-height)',
                padding: 'var(--padding-s) var(--padding-l)',
                borderTopLeftRadius: 'var(--border-radius-m)',
                borderBottomLeftRadius: 'var(--border-radius-m)',
                backgroundColor: i % 2 === 0 ? 'var(--background-color-1)' : 'transparent',
              }}
            >
              {t('command-' + command.name)}
            </div>
          ))}
        </div>
        <div
          style={{
            minWidth: 0,
            flex: '1 1 50%',
            display: 'flex',
            flexDirection: 'column',
            fontSize: 'var(--font-size-body)',
            justifyContent: 'flex-start',
          }}
        >
          {commands.map((command, i) => {
            let elems: React.JSX.Element[] = [];
            const shortcut = command.command;
            if (shortcut != null) {
              elems = shortcut.map((elem, i) => {
                const mKey = elem in KeySymbols ? KeySymbols[elem as keyof typeof KeySymbols] : elem;
                return (
                  <div className={styles.itemKey} key={'key' + i} style={{ marginLeft: 'var(--padding-xs)' }}>
                    {mKey}
                  </div>
                );
              });
            }
            return (
              <div
                key={'command' + command.name}
                className={styles.keysContainer}
                style={{
                  border: 0,
                  marginLeft: 0,
                  borderRadius: 0,
                  borderTopRightRadius: 'var(--border-radius-m)',
                  borderBottomRightRadius: 'var(--border-radius-m)',
                  justifyContent: 'flex-start',
                  height: 'var(--button-height)',
                  backgroundColor: i % 2 === 0 ? 'var(--background-color-1)' : 'transparent',
                }}
              >
                <div
                  style={{
                    marginLeft: 'var(--padding-s)',
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 'var(--font-size-body)',
                  }}
                >
                  {elems}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
