import React from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenu } from '@fragments/sideMenu';

export const APISideMenu = (): React.JSX.Element => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('api-collections-label'),
      icon: 'inventory_2',
      value: 'collections',
    },
    {
      label: t('api-environments-label'),
      icon: 'auto_transmission',
      value: 'envs',
    },
  ];

  return <SideMenu defaultValue="collections" options={options} />;
};
