import React, { useEffect, useRef } from 'react';

export const BasePopup = ({
  children,
  width = 'fit-content',
  height = 'fit-content',
  offsetY = 0,
  offsetX,
  inverted = false,
  withScroll = false,
}: {
  children: React.JSX.Element;
  width?: string;
  height?: string;
  offsetY?: number;
  offsetX?: number;
  withScroll?: boolean;
  inverted?: boolean;
}): React.JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const findParentWithScroll = (elem: any): number => {
    let mElem = elem;
    let scroll = mElem.scrollTop;
    let follow = scroll === 0;
    while (mElem.parentNode != null && follow) {
      mElem = mElem.parentNode;
      scroll = mElem.scrollTop;
      follow = scroll === 0;
    }
    if (!follow && scroll != null) return scroll;
    else return 0;
  };

  useEffect(() => {
    if (ref.current != null) {
      const h = window.innerHeight;
      const mScroll = findParentWithScroll(ref.current);
      const scroll = withScroll ? mScroll : 0;
      const elem: any = ref.current.children[0];
      elem.style.marginTop = '0px';
      const box = elem.getBoundingClientRect();
      if (box.top + box.height - scroll > h) {
        elem.style.marginTop = -box.height + (inverted ? 0 : -offsetY) - scroll + 'px';
      } else if (withScroll) elem.style.marginTop = (inverted ? offsetY : 0) - scroll + 'px';
    }
  }, [children]);

  return (
    <div style={{ width, height, marginLeft: offsetX ?? '' }} ref={ref}>
      {children}
    </div>
  );
};
