/* eslint-disable max-lines-per-function */

/* eslint-disable max-lines */

/* eslint-disable @typescript-eslint/no-dynamic-delete */

/* eslint-disable @typescript-eslint/no-for-in-array */
import { type PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { type TypeComponentReferencesVariables, type TypeComponentVariableDOM } from '@data/components';
import { type VariablesType } from '@data/variables';

import { type StateType } from '@reducers/store';

import { type ExtractedComponent, type Prop } from '@globalUtils/extractor';
import { generateId } from '@globalUtils/ids';
import {
  type ITree,
  type ITreeAttributes,
  type ITreeNode,
  type ITreeNodeAttributes,
  Tree,
  TreeNode,
} from '@globalUtils/tree';

import {
  type AssetVariable,
  type BooleanVariable,
  type ColorVariable,
  type IParametersTypeList,
  type IParametersValuesInternalList,
  type IParametersVariableJustValue,
  type IScriptType,
  type SizeVariable,
  type TextVariable,
  type TypeAttributeReference,
  type TypeAttributeReferenceVariable,
  type TypeComponentData,
  type TypeItems,
  type TypeVariable,
  type TypographyVariable,
} from '@globalTypes/conversor';
import {
  ClassesType,
  DefaultIcon,
  DefaultImage,
  DefaultLayer,
  DefaultScreen,
  DefaultText,
  type IconProperties,
  type ImageViewProperties,
  type LayerProperties,
  ParentType,
  type ScreenProperties,
  type TextProperties,
  type TypeItemProperties,
} from '@globalTypes/itemProperties';
import { type ConversorReferenceGroupType } from '@globalTypes/itemProperties/classesType';

import { getComponentByName, mConversor, renderFileRecursively } from './editor';
import { getLoadedComponents } from './files';
import {
  baseActions,
  iconActions,
  imageViewActions,
  layerActions,
  screenActions,
  textActions,
} from './itemActions';

export interface ComponentsType {
  allIds: string[];
  byId: Record<string, TypeComponentData>;
  selectedComponentPreview: string;
}

export interface ItemCopiedType {
  items: TypeItems;
  tree: ITreeNodeAttributes;
  attributes: TypeAttributeReferenceVariable;
  variables: TypeComponentReferencesVariables;
}

export interface ItemsTypeItems {
  allIds: string[];
  byId: TypeItems;
}

export interface ItemStylesCopiedType {
  item: TypeItemProperties;
  attributes: TypeAttributeReference[];
}

export interface ItemsType {
  currentDevice: string;
  items: ItemsTypeItems;
  itemCopied?: ItemCopiedType;
  stylesCopied?: ItemStylesCopiedType;
  tree: ITreeAttributes;
  customTags: CustomTagType[];
  classes: ConversorReferenceGroupType;
  variables: VariablesType;
  components: ComponentsType;
  componentIframePreviewData: [string, string, string];
  componentParams: {
    byId: Record<string, IParametersValuesInternalList>;
  };
}

export interface CustomTagType {
  name: string;
  importFrom: string;
  importName: string;
  useName: string;
}

export interface ClassType {
  name: string;
  properties: string[];
  id: string;
}

export interface VariantType {
  name: string;
}

export interface IChangeTag {
  id: string;
  tag: string;
}

export interface ICreate {
  parent: string;
  commonKind: string;
  name?: string;
}

export interface ICut {
  id: string;
}

export interface IDelete {
  id: string;
}

export interface IPasteItem {
  parentId: string;
}

export interface IDuplicateItem {
  id: string;
}

export interface ICopyItem {
  id: string;
}

export interface ICopyItemStyles {
  id: string;
}

export interface IPasteItemStyles {
  id: string;
}

export interface IChangeHierarchy {
  id: string;
  parent: string;
  newParent: string;
  index: number;
}

export interface IChangeClass {
  id: string;
  classes: string[];
}

export interface IChangeVariant {
  id: string;
  variant: string;
}

export interface ICreateVariant {
  id: string;
  variant: VariantType;
}

export interface IInsertComponent {
  parent: string;
  componentId: string;
  loadedComponents: ExtractedComponent[];
}

export interface IChangeVariableValue {
  name: string;
  theme: string;
  value: TypeVariable;
  device: string;
}

export interface IChangeVariableName {
  name: string;
  theme: string;
  newName: string;
}

export interface IVariableItem {
  id: string;
  theme: string;
  variable: string;
  attribute: string;
}

export interface IRemoveVariable {
  theme: string;
  variable: string;
}

export interface ICreateVariable {
  theme: string;
  variable: string;
  type: string;
}

export interface ICreateComponent {
  id: string;
  component: TypeComponentData;
}

export interface IChangeComponentDescription {
  id: string;
  description: string;
}

export interface IUpdateComponentParam {
  id: string;
  param: IParametersVariableJustValue;
  paramName: string;
}

export interface IChangeSelectedPreviewComponent {
  id: string;
}

export interface IDeleteComponent {
  id: string;
}

export interface ICreateClass {
  id: string;
  name: string;
  properties: string[];
}

const initialData: ItemsType = {
  currentDevice: 'desktop',
  items: {
    allIds: [],
    byId: {},
  },
  tree: { root: { parent: '', id: '', children: [] } },
  customTags: [],
  classes: {},
  variables: {
    model: {
      referenceValues: {
        Default: {},
        Responsiveness: {},
      },
      attributeReference: {},
      responsiveReferenceValues: {
        desktop: {
          Default: {},
          Responsiveness: {},
        },
        phone: {
          Default: {},
          Responsiveness: {},
        },
        tablet: {
          Default: {},
          Responsiveness: {},
        },
      },
    },
  },
  components: {
    allIds: [],
    byId: {},
    selectedComponentPreview: '',
  },
  componentParams: { byId: {} },
  componentIframePreviewData: ['', '', ''],
};

const baseItems: Record<string, TypeItemProperties> = {
  layer: { ...DefaultLayer },
  text: { ...DefaultText },
  icon: { ...DefaultIcon },
  imageView: { ...DefaultImage },
  screen: { ...DefaultScreen },
};

const defaultVariableValues: Record<string, TypeVariable> = {
  colors: { color: true, value: 'FFFFFF' },
  typography: {
    typography: true,
    value: {
      fontFamily: 'Arial',
      fontSize: { value: 18, unit: 'px' },
      leading: 1,
      spacing: 1,
      weight: 700,
      style: false,
    },
  },
  booleans: { boolean: true, value: true, kind: 'Visibility' },
  sizes: { size: true, value: { value: 0, unit: 'px' } },
  assets: { asset: true, value: 'default' },
  labels: { text: true, value: { content: { default: 'Input here ...' } } },
};

const doDeleteItem = (
  id: string,
  stateTree: ITreeAttributes,
  stateItems: ItemsTypeItems,
  stateVariables: VariablesType
): ITreeNodeAttributes => {
  const root = new TreeNode('-1', '0');
  root.children = Tree.objectToTreeNode(stateTree.root.children);
  const tree = new Tree(root);
  const item = stateItems.byId[id];
  const deletedIds = [id];
  if (item.commonKind === 'screen' || item.commonKind === 'layer') {
    const itemChildren = tree.root.getAllSubnodes(id) ?? [];
    for (const pId in itemChildren) {
      deletedIds.push(itemChildren[pId]);
      delete stateItems.byId[itemChildren[pId]];
    }
  }
  if ('parent' in item) {
    const parent = tree.root.getNodeById(item.parent);
    if (parent != null) {
      const newOldChildren = [];
      let oldPIndex = 0;
      for (let i = 0; i < parent.children.length; i++) {
        const oldId = parent.children[i].id;
        if (oldId !== id) {
          const cItem = stateItems.byId[oldId];
          if ('index' in cItem) cItem.index = oldPIndex;
          oldPIndex++;
          newOldChildren.push(parent.children[i]);
        }
      }
      parent.children = newOldChildren;
    }
  }
  const newList = [];
  for (const cId of stateItems.allIds) {
    if (!deletedIds.includes(cId)) newList.push(cId);
  }
  stateItems.allIds = newList;
  const themes = stateVariables.model.attributeReference;
  for (const theme in themes) {
    if (theme.includes(id)) {
      delete themes[theme as keyof typeof themes];
      delete stateVariables.model.referenceValues[theme];
      delete stateVariables.model.responsiveReferenceValues.desktop[theme];
      delete stateVariables.model.responsiveReferenceValues.tablet[theme];
      delete stateVariables.model.responsiveReferenceValues.phone[theme];
    }
  }
  const defaultTheme = stateVariables.model.attributeReference.Default;
  for (const variable in defaultTheme) {
    const idList = defaultTheme[variable];
    for (const dId of deletedIds) {
      if (idList[dId] != null) {
        delete idList[dId];
      }
    }
  }
  const responsivenessTheme = stateVariables.model.attributeReference.Responsiveness;
  for (const variable in responsivenessTheme) {
    const idList = responsivenessTheme[variable];
    for (const dId of deletedIds) {
      if (idList[dId] != null) {
        delete responsivenessTheme[variable];
        delete stateVariables.model.referenceValues.Responsiveness[variable];
        delete stateVariables.model.responsiveReferenceValues.desktop.Responsiveness[variable];
        delete stateVariables.model.responsiveReferenceValues.tablet.Responsiveness[variable];
        delete stateVariables.model.responsiveReferenceValues.phone.Responsiveness[variable];
      }
    }
  }
  delete stateItems.byId[id];
  return tree.root.getObject();
};

const doCopyItem = (
  id: string,
  stateTree: ITreeAttributes,
  stateItems: ItemsTypeItems,
  stateVariables: VariablesType
): ItemCopiedType | undefined => {
  const root = new TreeNode('-1', '0');
  root.children = Tree.objectToTreeNode(stateTree.root.children);
  const itree = new Tree(root);
  const tree = itree.root.getNodeById(id);
  if (tree != null) {
    const items: TypeItems = {};
    const lists = getNonComponentList(tree, stateItems.byId);
    const itemList = lists[0];
    const componentList = lists[1];
    for (const pId in itemList) {
      items[itemList[pId]] = stateItems.byId[itemList[pId]];
    }
    for (const pId in componentList) {
      items[componentList[pId]] = stateItems.byId[componentList[pId]];
    }
    const attributes: TypeAttributeReferenceVariable = {};
    const variables: TypeComponentReferencesVariables = {};
    const themes = stateVariables.model.attributeReference;
    const values = stateVariables.model.referenceValues;
    for (const theme in themes) {
      if (!theme.includes('_')) {
        const themeVariables = theme === 'Default' ? themes.Default : themes.Responsiveness;
        for (const variable in themeVariables) {
          const idList = themeVariables[variable];
          let isInComponent = false;
          for (const cId in idList) {
            if (itemList.includes(cId)) {
              isInComponent = true;
              const itemAttributes = idList[cId] as TypeAttributeReference[];
              if (attributes[cId] == null) attributes[cId] = [];
              attributes[cId] = attributes[cId].concat(itemAttributes);
            }
          }
          if (isInComponent) {
            if (theme !== 'Default') {
              const responsiveVariables = stateVariables.model.responsiveReferenceValues;
              const valueDesktop = responsiveVariables.desktop[theme][variable];
              const valuePhone = responsiveVariables.phone[theme][variable];
              const valueTablet = responsiveVariables.tablet[theme][variable];
              variables[variable] = {
                type: 'Responsivenes',
                tag: 'responsiveVariable',
                valueDesktop,
                valuePhone,
                valueTablet,
                description: '',
              };
            }
          }
        }
      } else {
        let themeId = theme.split('_')[1];
        if (!isNaN(parseInt(themeId))) {
          for (const id in itemList) {
            if (items[itemList[id]].name.replaceAll(' ', '') === theme) {
              themeId = id;
            }
          }
        }
        if (itemList.includes(themeId)) {
          const keyTheme = theme as keyof typeof themes;
          const pTheme = themes[keyTheme];
          for (const internalTheme in pTheme) {
            const variables = pTheme[internalTheme];
            for (const variable in variables) {
              const idList = variables[variable] as TypeAttributeReference[];
              for (const cId in idList) {
                if (attributes[cId] == null) attributes[cId] = [];
                attributes[cId] = attributes[cId].concat(idList[cId]);
              }
            }
          }
          const variableTheme = values[theme];
          for (const variable in variableTheme) {
            variables[variable] = { type: 'param', value: values[theme][variable], description: '' };
          }
        }
      }
    }
    return { items, tree: tree.getObject(), attributes, variables };
  }
  return undefined;
};

const doPasteItem = (
  parentId: string,
  itemCopied: ItemCopiedType,
  stateTree: ITreeAttributes,
  items: ItemsTypeItems,
  variables: VariablesType
): ITreeNodeAttributes => {
  const root = new TreeNode('-1', '0');
  root.children = Tree.objectToTreeNode(stateTree.root.children);
  const tree = new Tree(root);
  const parentTree = tree.getNodeById(parentId);
  const newIds: Record<string, string> = {};
  const newNames: Record<string, string> = {};
  const copiedInfo = itemCopied;
  if (parentTree != null) {
    for (const oldId in copiedInfo.items) newIds[oldId] = generateId();
    for (const oldId in copiedInfo.items) {
      const item: TypeItemProperties = JSON.parse(JSON.stringify(copiedInfo.items[oldId]));
      if ('parent' in item) {
        if (newIds[item.parent] != null) item.parent = newIds[item.parent];
        else {
          item.parent = parentId;
          item.index = parentTree.children.length;
        }
      }
      const lastChar = parseInt(item.name.substring(item.name.length - 1));
      if (duplicatedName(items, item.name)) {
        let increase = 1;
        if (!isNaN(lastChar)) {
          increase = lastChar + 1;
        }
        while (duplicatedName(items, item.name + increase)) increase++;
        newNames[oldId] = item.name + increase;
        item.name = item.name + increase;
      } else newNames[oldId] = item.name;
      item.id = newIds[oldId];
      items.allIds.push(newIds[oldId]);
      items.byId[newIds[oldId]] = item;
    }
    const pasteTree = copiedInfo.tree;
    const changedIdTree = changeTreeIds(pasteTree, newIds);
    changedIdTree.parent = parentId;
    parentTree.children.push(changedIdTree);
    const attributes = copiedInfo.attributes;
    for (const id in attributes) {
      const itemAttributes = attributes[id];
      for (const attribute of itemAttributes) {
        const themeLocation =
          attribute.theme === 'Default'
            ? variables.model.attributeReference.Default
            : variables.model.attributeReference.Responsiveness;
        let variableValue = attribute.variable;
        if (variableValue.includes('_')) {
          const varInfo = variableValue.split('_');
          if (varInfo[0] === id) variableValue = newIds[id] + '_' + varInfo[1];
          else variableValue = newNames[id] + '_' + varInfo[1];
        }
        const pVariable = attribute.theme === 'Default' ? attribute.variable : variableValue;
        if (themeLocation[pVariable] == null) {
          const attributeValues = copiedInfo.variables;
          themeLocation[pVariable] = {};
          const value = attributeValues[attribute.variable];
          if ('tag' in value) {
            variables.model.referenceValues.Responsiveness[pVariable] = value.valueDesktop;
            variables.model.responsiveReferenceValues.desktop.Responsiveness[pVariable] = value.valueDesktop;
            variables.model.responsiveReferenceValues.tablet.Responsiveness[pVariable] = value.valueDesktop;
            variables.model.responsiveReferenceValues.phone.Responsiveness[pVariable] = value.valueDesktop;
          }
        }
        const variableLocation = themeLocation[pVariable];
        if (variableLocation[newIds[id]] == null) variableLocation[newIds[id]] = [];
        const idVariableLocation = variableLocation[newIds[id]] as TypeAttributeReference[];
        idVariableLocation.push({
          attribute: attribute.attribute,
          variable: variableValue,
          theme: attribute.theme,
        });
      }
    }
  }
  return tree.root.getObject();
};

export const BaseItemsSlice = createSlice({
  name: 'items',
  initialState: initialData,
  reducers: {
    setInitialItems: (state, action) => {
      const screenInitial = JSON.parse(JSON.stringify(DefaultScreen));
      screenInitial.id = '24011984';
      state.currentDevice = 'desktop';
      state.items = {
        allIds: ['24011984'],
        byId: {
          24011984: screenInitial,
        },
      };
      state.variables = {
        model: {
          referenceValues: {
            Default: {},
            Responsiveness: {},
          },
          attributeReference: {},
          responsiveReferenceValues: {
            desktop: {
              Default: {},
              Responsiveness: {},
            },
            phone: {
              Default: {},
              Responsiveness: {},
            },
            tablet: {
              Default: {},
              Responsiveness: {},
            },
          },
        },
      };
      state.tree = {
        root: {
          parent: '',
          id: '',
          children: [
            {
              parent: '',
              id: '24011984',
              children: [],
            },
          ],
        },
      };
      state.customTags = [];
      state.classes = {};
      state.components = {
        allIds: [],
        byId: {},
        selectedComponentPreview: '',
      };
      state.componentParams = { byId: {} };
      state.componentIframePreviewData = ['', '', ''];
    },
    setWholeItems: (state, action) => {
      const { currentDevice, items, tree, customTags, classes, variables, components, componentParams } =
        action.payload;
      state.currentDevice = currentDevice;
      state.items = items;
      state.tree = tree;
      state.customTags = customTags;
      state.classes = classes;
      state.variables = variables;
      state.components = components;
      state.componentParams = componentParams;
    },
    changeSelectedDevice: (state, action: PayloadAction<string>): void => {
      const device = action.payload;
      state.currentDevice = device;
    },
    updateComponentParams: (state, action: PayloadAction<IUpdateComponentParam>): void => {
      const { id, paramName, param } = action.payload;
      const componentsParams = state.componentParams.byId;
      if (componentsParams[id] == null) componentsParams[id] = {};
      const componentParams = componentsParams[id];
      componentParams[paramName] = param;
    },
    changeComponentDescription: (state, action: PayloadAction<IChangeComponentDescription>): void => {
      const { id, description } = action.payload;
      state.components.byId[id].description = description;
    },
    createItem: (state, action: PayloadAction<ICreate>): void => {
      const { commonKind, parent } = action.payload;
      const generatedId = generateId();
      const baseItem = JSON.parse(JSON.stringify(baseItems[commonKind]));
      baseItem.id = generatedId;
      const ids = state.items.allIds;
      let max = 1;
      for (const id of ids) {
        const item = state.items.byId[id];
        if (item.name.startsWith(commonKind)) {
          const number = parseInt(item.name.replace(commonKind, ''));
          if (!isNaN(number) && number >= max) max = number + 1;
        }
      }
      baseItem.name = commonKind + max;
      const root = new TreeNode('-1', '0');
      root.children = Tree.objectToTreeNode(state.tree.root.children);
      const tree = new Tree(root);
      if ('parent' in baseItem) {
        baseItem.parent = parent;
        baseItem.index = tree.root.getNodeById(parent)?.children.length ?? 0 + 1;
        tree.root.getNodeById(parent)?.children.push(new TreeNode(parent, generatedId));
      } else {
        const { name } = action.payload;
        const mName = name ?? '';
        baseItem.name = mName;
        tree.root.children.push(new TreeNode(tree.root.id, generatedId));
      }
      state.items.allIds.push(generatedId);
      state.items.byId[generatedId] = baseItem;
      state.tree = { root: tree.root.getObject() };
    },
    deleteItem: (state, action: PayloadAction<IDelete>): void => {
      const { id } = action.payload;
      const root = doDeleteItem(id, state.tree, state.items, state.variables);
      state.tree = { root };
    },
    cutItem: (state, action: PayloadAction<ICut>): void => {
      const { id } = action.payload;
      state.itemCopied = doCopyItem(id, state.tree, state.items, state.variables);
      const root = doDeleteItem(id, state.tree, state.items, state.variables);
      state.tree = { root };
    },
    duplicateItem: (state, action: PayloadAction<IDuplicateItem>): void => {
      const pastItemCopied = state.itemCopied == null ? undefined : { ...state.itemCopied };
      const { id } = action.payload;
      const item = state.items.byId[id];
      if ('parent' in item) {
        state.itemCopied = doCopyItem(id, state.tree, state.items, state.variables);
        if (state.itemCopied != null) {
          const root = doPasteItem(item.parent, state.itemCopied, state.tree, state.items, state.variables);
          state.tree = { root };
        }
        state.itemCopied = pastItemCopied;
      }
    },
    pasteItem: (state, action: PayloadAction<IPasteItem>): void => {
      const { parentId } = action.payload;
      if (state.itemCopied != null) {
        const root = doPasteItem(parentId, state.itemCopied, state.tree, state.items, state.variables);
        state.tree = { root };
      }
    },
    copyItem: (state, action: PayloadAction<ICopyItem>): void => {
      const { id } = action.payload;
      state.itemCopied = doCopyItem(id, state.tree, state.items, state.variables);
    },
    copyItemStyles: (state, action: PayloadAction<ICopyItemStyles>): void => {
      const { id } = action.payload;
      const item = state.items.byId[id];
      if ('component' in item && item.component !== '') return;
      let attributes: TypeAttributeReference[] = [];
      const defaultTheme = state.variables.model.attributeReference.Default;
      for (const variable in defaultTheme) {
        const idList = defaultTheme[variable];
        if (idList[id] != null) {
          const idAttr = idList[id] as TypeAttributeReference[];
          attributes = attributes.concat(idAttr);
        }
      }
      const responsivenessTheme = state.variables.model.attributeReference.Responsiveness;
      for (const variable in responsivenessTheme) {
        const idList = responsivenessTheme[variable];
        if (idList[id] != null) {
          const idAttr = idList[id] as TypeAttributeReference[];
          attributes = attributes.concat(idAttr);
        }
      }
      state.stylesCopied = { item, attributes };
    },
    pasteItemStyles: (state, action: PayloadAction<IPasteItemStyles>): void => {
      const { id } = action.payload;
      const item = state.items.byId[id];
      if ('component' in item && item.component !== '') return;
      if (state.stylesCopied != null) {
        const attributes = state.stylesCopied.attributes;
        const attributeReference = state.variables.model.attributeReference;
        const defaultTheme = attributeReference.Default;
        for (const variable in defaultTheme) {
          const idList = defaultTheme[variable];
          if (idList[id] != null) delete idList[id];
        }
        const responsivenessTheme = attributeReference.Responsiveness;
        for (const variable in responsivenessTheme) {
          const idList = responsivenessTheme[variable];
          if (idList[id] != null) delete idList[id];
        }
        for (const attributeInfo of attributes) {
          const pTheme = attributeInfo.theme === 'Default' ? defaultTheme : responsivenessTheme;
          const pVar = pTheme[attributeInfo.variable] as TypeAttributeReferenceVariable;
          if (pVar[id] == null) pVar[id] = [];
          pVar[id].push(attributeInfo);
        }
        const cItem = state.stylesCopied.item;
        if (cItem != null && item != null) {
          if (item.commonKind !== 'icon') {
            if ('width' in item && 'width' in cItem) item.width = cItem.width;
            if ('height' in item && 'height' in cItem) item.height = cItem.height;
          } else {
            if ('fontSize' in cItem) {
              if ('width' in item) item.width.data = cItem.fontSize;
              if ('height' in item) item.height.data = cItem.fontSize;
              if ('fontSize' in item) item.fontSize = cItem.fontSize;
            } else if ('width' in cItem && cItem.width.data != null) {
              if ('width' in item) item.width = cItem.width;
              if ('height' in item) item.height = cItem.width;
              if ('fontSize' in item) item.fontSize = cItem.width.data;
            } else if ('height' in cItem && cItem.height.data != null) {
              if ('width' in item) item.width = cItem.height;
              if ('height' in item) item.height = cItem.height;
              if ('fontSize' in item) item.fontSize = cItem.height.data;
            }
          }
          if ('background' in item && 'background' in cItem) item.background = cItem.background;
          else if ('background' in item && 'backgroundColor' in cItem) {
            item.background.data = cItem.backgroundColor.data;
          } else if ('backgroundColor' in item && 'background' in cItem) {
            if (typeof cItem.background.data === 'string') {
              item.backgroundColor.data = cItem.background.data;
            }
          } else if ('backgroundColor' in item && 'backgroundColor' in cItem) {
            item.backgroundColor = cItem.backgroundColor;
          }
          if ('paddings' in item && 'paddings' in cItem) item.paddings = cItem.paddings;
          if ('margins' in item && 'margins' in cItem) item.margins = cItem.margins;
          if ('animation' in item && 'animation' in cItem) item.animation = cItem.animation;
          if ('animationWhenVisible' in item && 'animationWhenVisible' in cItem) {
            item.animationWhenVisible = cItem.animationWhenVisible;
          }
          if ('orientation' in item && 'orientation' in cItem) item.orientation = cItem.orientation;
          if ('horizontalAlignment' in item && 'horizontalAlignment' in cItem) {
            item.horizontalAlignment = cItem.horizontalAlignment;
          }
          if ('verticalAlignment' in item && 'verticalAlignment' in cItem) {
            item.verticalAlignment = cItem.verticalAlignment;
          }
          if ('visible' in item && 'visible' in cItem) item.visible = cItem.visible;
          if ('cursorPointer' in item && 'cursorPointer' in cItem) item.cursorPointer = cItem.cursorPointer;
          if ('classes' in item && 'classes' in cItem) item.classes = cItem.classes;
          if ('classname' in item && 'classname' in cItem) item.classname = cItem.classname;
          if ('customStyles' in item && 'customStyles' in cItem) item.customStyles = cItem.customStyles;
          if (item.commonKind === 'layer' && cItem.commonKind === 'layer') {
            if ('positions' in item && 'positions' in cItem) item.positions = cItem.positions;
            if ('cssPosition' in item && 'cssPosition' in cItem) item.cssPosition = cItem.cssPosition;
            if ('border' in item && 'border' in cItem) item.border = cItem.border;
            if ('shadow' in item && 'shadow' in cItem) item.shadow = cItem.shadow;
          } else if (item.commonKind === 'text' && cItem.commonKind === 'text') {
            if ('fontFamily' in item && 'fontFamily' in cItem) item.fontFamily = cItem.fontFamily;
            if ('fontSize' in item && 'fontSize' in cItem) item.fontSize = cItem.fontSize;
            if ('leading' in item && 'leading' in cItem) item.leading = cItem.leading;
            if ('weight' in item && 'weight' in cItem) item.weight = cItem.weight;
            if ('spacing' in item && 'spacing' in cItem) item.spacing = cItem.spacing;
            if ('textAlignment' in item && 'textAlignment' in cItem) item.textAlignment = cItem.textAlignment;
          }
        }
      }
    },
    changeHierarchy: (state, action: PayloadAction<IChangeHierarchy>): void => {
      const { id, parent, newParent, index } = action.payload;
      const item = state.items.byId[id];
      if ('parent' in item) {
        item.parent = newParent;
        const root = new TreeNode('-1', '0');
        root.children = Tree.objectToTreeNode(state.tree.root.children);
        const tree = new Tree(root);
        const oldParent = tree.root.getNodeById(parent);
        const newParentTree = tree.root.getNodeById(newParent);
        const child = tree.root.getNodeById(id);
        if (oldParent != null && newParentTree != null && child != null) {
          child.parent = newParent;
          const oldChildren = oldParent.children;
          const newOldChildren = [];
          let oldPIndex = 0;
          for (let i = 0; i < oldChildren.length; i++) {
            const oldId = oldParent.children[i].id;
            if (oldId !== id) {
              const cItem = state.items.byId[oldId];
              if ('index' in cItem) cItem.index = oldPIndex;
              oldPIndex++;
              newOldChildren.push(oldParent.children[i]);
            }
          }
          oldParent.children = newOldChildren;
          item.index = index;
          const newParentChildren = newParentTree.children ?? [];
          const newChildren = [];
          let pIndex = 0;
          for (let i = 0; i < newParentChildren.length; i++) {
            const idChild = newParentChildren[i].id;
            if (pIndex === index) {
              newChildren.push(child);
              pIndex++;
            }
            const cItem = state.items.byId[idChild];
            if ('index' in cItem) cItem.index = pIndex;
            const pChild = tree.root.getNodeById(idChild);
            if (pChild != null) newChildren.push(pChild);
            pIndex++;
          }
          if (pIndex === newParentChildren.length) {
            newChildren.push(child);
          }
          newParentTree.children = newChildren;
          state.tree = { root: tree.root.getObject() };
        }
      }
    },
    insertComponent: (state, action: PayloadAction<IInsertComponent>): void => {
      const { parent, componentId, loadedComponents } = action.payload;
      const componentData = state.components.byId[componentId];
      const componentDataLocal = loadedComponents.find((compo) => compo.id === componentId);
      if (componentData == null && componentDataLocal == null) return;
      const root = new TreeNode('-1', '0');
      root.children = Tree.objectToTreeNode(state.tree.root.children);
      const tree = new Tree(root);
      const item = tree.root.getNodeById(parent);
      if (componentDataLocal != null) {
        // Esto es custom code
        console.log(componentDataLocal);
        const layer = { ...DefaultLayer };
        item?.children.push(new TreeNode(parent, componentId));
        layer.id = componentId;
        layer.component = componentDataLocal.name;
        state.items.allIds.push(componentId);
        state.items.byId[componentId] = layer;
      } else if ('data' in componentData && componentData.data != null) {
        // Esto NO es custom code
        const { items, tree, attributeReference } = componentData.data;
        const firstItem = items[tree.id];
        if (firstItem.commonKind === 'layer') {
          firstItem.parent = parent;
          firstItem.component = componentData.name;
          firstItem.id = generateId();
        }
        const newIds = { [tree.id]: firstItem.id };
        for (const id in items) {
          const newId = generateId();
          newIds[id] = newId;
          state.items.allIds.push(newId);
          state.items.byId[newId] = items[id];
        }
        item?.children.push(tree);
        const externalTheme = componentData.name + '_' + componentId;
        if ('references' in componentData && componentData.references != null) {
          const variables = componentData.references.variables;
          state.variables.model.referenceValues[externalTheme] = {};
          const theme = state.variables.model.referenceValues[externalTheme];
          for (const variable in variables) {
            const varInfo = variables[variable];
            if ('tag' in varInfo) {
              if ('valueDesktop' in varInfo) theme[variable] = varInfo.valueDesktop;
              if (state.variables.model.responsiveReferenceValues == null) {
                state.variables.model.responsiveReferenceValues = {
                  desktop: { Default: {}, Responsiveness: {} },
                  tablet: { Default: {}, Responsiveness: {} },
                  phone: { Default: {}, Responsiveness: {} },
                };
              }
              const desktop = state.variables.model.responsiveReferenceValues.desktop;
              if (desktop[externalTheme] == null) desktop[externalTheme] = {};
              if ('valueDesktop' in varInfo) desktop[externalTheme][variable] = varInfo.valueDesktop;
              const tablet = state.variables.model.responsiveReferenceValues.tablet;
              if (tablet[externalTheme] == null) tablet[externalTheme] = {};
              if ('valueTablet' in varInfo) tablet[externalTheme][variable] = varInfo.valueTablet;
              const phone = state.variables.model.responsiveReferenceValues.phone;
              if (phone[externalTheme] == null) phone[externalTheme] = {};
              if ('valuePhone' in varInfo) phone[externalTheme][variable] = varInfo.valuePhone;
            } else {
              if ('value' in varInfo) theme[variable] = varInfo.value;
            }
          }
        }
        if (attributeReference != null) {
          const place = state.variables.model.attributeReference;
          const keyTheme = externalTheme as keyof typeof place;
          place[keyTheme] = {};
          const theme = place[keyTheme];
          for (const id in attributeReference) {
            const attributes = attributeReference[id];
            for (const attribute of attributes) {
              if (attribute.isResponsive != null && attribute.isResponsive) {
                let componentParent = items[id];
                let found = false;
                let internalTheme = '';
                while (!found) {
                  if (componentParent.commonKind === 'layer' && componentParent.component !== '') {
                    found = true;
                    internalTheme = componentParent.component + '_' + componentParent.id;
                  } else if (componentParent.commonKind === 'screen') found = true;
                  else {
                    componentParent = items[componentParent.parent];
                  }
                }
                attribute.route = firstItem.name + '/' + attribute.route;
                if (theme[internalTheme] == null) theme[internalTheme] = {};
                if (theme[internalTheme][id] == null) theme[internalTheme][id] = [];
                const ptheme = theme[internalTheme][id] as TypeAttributeReference[];
                ptheme.push(attribute);
              } else {
                attribute.route = firstItem.name;
                if (theme[externalTheme] == null) theme[externalTheme] = {};
                if (theme[externalTheme][id] == null) theme[externalTheme][id] = [];
                const ptheme = theme[externalTheme][id] as TypeAttributeReference[];
                ptheme.push(attribute);
              }
            }
          }
        }
      }
      state.tree = { root: tree.root.getObject() };
    },
    changeTag: (state, action: PayloadAction<IChangeTag>): void => {
      const { id, tag } = action.payload;
      const item = state.items.byId[id];
      if (item == null || !('tag' in item)) return;
      item.tag = tag;
    },
    createTag: (state, action: PayloadAction<CustomTagType>): void => {
      const { name, importFrom, importName, useName } = action.payload;
      const mTag = { name, importFrom, importName, useName };
      state.customTags.push(mTag);
    },
    changeClass: (state, action: PayloadAction<IChangeClass>): void => {
      const { id, classes } = action.payload;
      const item = state.items.byId[id];
      if (item == null) return;
      if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
        if ('state' in item) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          item.state[item.currentVariant].normal.classes = classes;
        }
      } else if (ParentType.ParentProperty in item) {
        item[ClassesType.ClassesProperty] = classes;
      }
    },
    changeVariant: (state, action: PayloadAction<IChangeVariant>): void => {
      const { id, variant } = action.payload;
      const item = state.items.byId[id];
      if (item == null) return;
      if (ParentType.ParentProperty in item) {
        item.currentVariant = variant;
        if (!('state' in item)) item.state = {};
      }
    },
    createVariant: (state, action: PayloadAction<ICreateVariant>): void => {
      const { id, variant } = action.payload;
      const item = state.items.byId[id];
      if (item == null) return;
      if (ParentType.ParentProperty in item) {
        if (item.variants == null) item.variants = [];
        item.variants.push(variant.name);
      }
    },

    changeVariableValue: (state, action: PayloadAction<IChangeVariableValue>) => {
      const { theme, name, value, device } = action.payload;
      const themeValues =
        theme === 'Default'
          ? state.variables.model.referenceValues.Default
          : state.variables.model.referenceValues.Responsiveness;
      themeValues[name] = value;
      const pDevice = device === 'desktop' ? 'desktop' : device === 'tablet' ? 'tablet' : 'phone';
      const deviceValues = state.variables.model.responsiveReferenceValues[pDevice];
      const resThemeValues = theme === 'Default' ? deviceValues.Default : deviceValues.Responsiveness;
      resThemeValues[name] = value;
      /*
      if (theme === 'Default') {
        const domResValues = state.variables.dom[0].responsiveVariables;
        const domDeviceValues = domResValues[device as keyof typeof domResValues];
        /** let type = '';
        const domValues = state.variables.dom[0].values;
        for (const pValue of domValues) {
          if (pValue.name === name) type = pValue.type;
        }
        // const pVal = type === 'Color' ? [value as string, ''] : value;
        domDeviceValues[theme][name] = value;
      }
      */
    },

    changeVariableName: (state, action: PayloadAction<IChangeVariableName>) => {
      const { theme, name, newName } = action.payload;
      const themeAttributes =
        theme === 'Default'
          ? state.variables.model.attributeReference.Default
          : state.variables.model.attributeReference.Responsiveness;
      const oldVariable = themeAttributes[name];
      for (const id in oldVariable) {
        const itemAttributes = oldVariable[id] as TypeAttributeReference[];
        for (const attribute of itemAttributes) {
          attribute.variable = newName;
        }
      }
      themeAttributes[newName] = oldVariable;
      delete themeAttributes[name];
      const themeValues =
        theme === 'Default'
          ? state.variables.model.referenceValues.Default
          : state.variables.model.referenceValues.Responsiveness;
      themeValues[newName] = themeValues[name];
      delete themeValues[name];
      /*
      if (theme === 'Default') {
        const domValues = state.variables.dom[0].values;
        for (const value of domValues) {
          if (value.name === name) value.name = newName;
        }
        const domResValues = state.variables.dom[0].responsiveVariables;
        domResValues.desktop[theme][newName] = domResValues.desktop[theme][name];
        delete domResValues.desktop[theme][name];
        domResValues.tablet[theme][newName] = domResValues.tablet[theme][name];
        delete domResValues.tablet[theme][name];
        domResValues.phone[theme][newName] = domResValues.phone[theme][name];
        delete domResValues.phone[theme][name];
      }
      */
    },
    addVariableToItem: (state, action: PayloadAction<IVariableItem>) => {
      const { id, theme, variable, attribute } = action.payload;
      const item = state.items.byId[id];
      if ('currentVariant' in item && item.currentVariant !== '') {
        if ('state' in item && item.currentVariant != null) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          if (variable !== '') item.state[item.currentVariant].variable[attribute] = variable;
          else delete item.state[item.currentVariant].variable[attribute];
        }
      } else {
        const attrRefs = state.variables.model.attributeReference;
        for (const themes in attrRefs) {
          const keyThemes = themes as keyof typeof attrRefs;
          const pTheme = attrRefs[keyThemes];
          for (const pVariable in pTheme) {
            const idList = pTheme[pVariable];
            for (const pId in idList) {
              if (pId === id) {
                const list = idList[pId] as TypeAttributeReference[];
                let location = -1;
                for (let i = 0; i < list.length; i++) {
                  if (list[i].attribute === attribute) location = i;
                }
                if (location !== -1) list.splice(location, 1);
              }
            }
          }
        }
        if (variable !== '') {
          const themeAttributes =
            theme === 'Default'
              ? state.variables.model.attributeReference.Default
              : state.variables.model.attributeReference.Responsiveness;
          if (themeAttributes[variable][id] == null) themeAttributes[variable][id] = [];
          const place = themeAttributes[variable][id] as TypeAttributeReference[];
          place.push({ id, theme, variable, attribute });
        }
      }
    },
    removeVariable: (state, action: PayloadAction<IRemoveVariable>) => {
      const { theme, variable } = action.payload;
      const themeAttributes =
        theme === 'Default'
          ? state.variables.model.attributeReference.Default
          : state.variables.model.attributeReference.Responsiveness;
      delete themeAttributes[variable];
      const themeValues =
        theme === 'Default'
          ? state.variables.model.referenceValues.Default
          : state.variables.model.referenceValues.Responsiveness;
      delete themeValues[variable];
      /*
      if (theme === 'Default') {
        const domValues = state.variables.dom[0].values;
        let index = -1;
        for (let i = 0; i < domValues.length && index === -1; i++) {
          const info = domValues[i];
          if (info.name === variable) index = i;
        }
        if (index !== -1) domValues.splice(index, 1);
      }
      */
    },
    createVariable: (state, action: PayloadAction<ICreateVariable>) => {
      const { theme, variable, type } = action.payload;
      const value = JSON.parse(JSON.stringify(defaultVariableValues[type]));
      const themeAttributes =
        theme === 'Default'
          ? state.variables.model.attributeReference.Default
          : state.variables.model.attributeReference.Responsiveness;
      const themeValues =
        theme === 'Default'
          ? state.variables.model.referenceValues.Default
          : state.variables.model.referenceValues.Responsiveness;
      themeAttributes[variable] = {};
      themeValues[variable] = value;
      /*
      if (theme === 'Default') {
        const domValues = state.variables.dom[0].values;
        let pType = type.endsWith('s') ? type.substring(0, type.length - 1) : type;
        pType = pType.substring(0, 1).toUpperCase() + pType.substring(1);
        domValues.push({
          name: variable,
          type: pType,
          value,
          unit: '',
        });
      }
      */
    },
    selectComponentPreview: (state, action: PayloadAction<IChangeSelectedPreviewComponent>) => {
      const itemId = action.payload.id;
      if (itemId === state.components.selectedComponentPreview) {
        state.components.selectedComponentPreview = '';
      } else state.components.selectedComponentPreview = itemId;
    },
    createComponent: (state, action: PayloadAction<ICreateComponent>) => {
      const { id, component } = action.payload;
      const root = new TreeNode('-1', '0');
      root.children = Tree.objectToTreeNode(state.tree.root.children);
      const itree = new Tree(root);
      if (!component.imported) {
        const tree = itree.root.getNodeById(id);
        if (tree != null) {
          const items: TypeItems = {};
          const lists = getNonComponentList(tree, state.items.byId);
          const itemList = lists[0];
          const componentList = lists[1];
          for (const pId in itemList) {
            items[itemList[pId]] = state.items.byId[itemList[pId]];
          }
          for (const pId in componentList) {
            items[componentList[pId]] = state.items.byId[componentList[pId]];
          }
          component.data = { tree, items };
          const attributes: TypeAttributeReferenceVariable = {};
          const variables: TypeComponentReferencesVariables = {};
          const themes = state.variables.model.attributeReference;
          const values = state.variables.model.referenceValues;
          for (const theme in themes) {
            if (!theme.includes('_')) {
              const themeVariables = theme === 'Default' ? themes.Default : themes.Responsiveness;
              for (const variable in themeVariables) {
                const idList = themeVariables[variable];
                let isInComponent = false;
                for (const cId in idList) {
                  if (itemList.includes(cId)) {
                    isInComponent = true;
                    const itemAttributes = idList[cId] as TypeAttributeReference[];
                    if (attributes[cId] == null) attributes[cId] = [];
                    attributes[cId] = attributes[cId].concat(itemAttributes);
                  }
                }
                if (isInComponent) {
                  if (theme === 'Default') {
                    /*
                    const domValues = state.variables.dom[0].values;
                    let type = 'param';
                    for (const value of domValues) {
                      if (value.name === variable) type = value.type;
                    }
                    variables[variable] = { type, value: values[theme][variable], description: '' };
                    */
                  } else {
                    const responsiveVariables = state.variables.model.responsiveReferenceValues;
                    const valueDesktop = responsiveVariables.desktop[theme][variable];
                    const valuePhone = responsiveVariables.phone[theme][variable];
                    const valueTablet = responsiveVariables.tablet[theme][variable];
                    variables[variable] = {
                      type: 'Responsivenes',
                      tag: 'responsiveVariable',
                      valueDesktop,
                      valuePhone,
                      valueTablet,
                      description: '',
                    };
                  }
                }
              }
            } else {
              let themeId = theme.split('_')[1];
              if (!isNaN(parseInt(themeId))) {
                for (const id in itemList) {
                  if (items[itemList[id]].name.replaceAll(' ', '') === theme) {
                    themeId = id;
                  }
                }
              }
              if (itemList.includes(themeId)) {
                const keyTheme = theme as keyof typeof themes;
                const pTheme = themes[keyTheme];
                for (const internalTheme in pTheme) {
                  const variables = pTheme[internalTheme];
                  for (const variable in variables) {
                    const idList = variables[variable] as TypeAttributeReference[];
                    for (const cId in idList) {
                      if (attributes[cId] == null) attributes[cId] = [];
                      attributes[cId] = attributes[cId].concat(idList[cId]);
                    }
                  }
                }
                const variableTheme = values[theme];
                for (const variable in variableTheme) {
                  variables[variable] = { type: 'param', value: values[theme][variable], description: '' };
                }
              }
            }
          }
          component.data.attributeReference = attributes;
          component.references = { variables };
          if (componentList.length > 0) {
            component.references.componentChildren = {};
            for (const pId in componentList) {
              const item = items[componentList[pId]];
              if ('component' in item) {
                const childComp = getComponentByName(item.component, state.components.byId, []);
                if (childComp != null && 'imported' in childComp) {
                  if (childComp?.references != null) {
                    component.references.componentChildren[item.name] = {
                      component: item.component,
                      references: childComp.references,
                    };
                  }
                }
              }
            }
          }
        }
      }
      const componentId = component.id;
      state.components.allIds.push(componentId);
      state.components.byId[componentId] = component;
      state.tree = { root: itree.root.getObject() };
      console.log('component: ', component);
    },
    deleteComponent: (state, action: PayloadAction<IDeleteComponent>) => {
      const { id } = action.payload;
      let itsUsed = false;
      const mComponentName = state.components.byId[id].name;
      const items = state.items.byId;
      for (const id in items) {
        const item = items[id];
        if ('component' in item && item.component !== '' && item.component === mComponentName) {
          itsUsed = true;
        }
      }
      if (!itsUsed) {
        delete state.components.byId[id];
        let location = -1;
        for (let i = 0; i < state.components.allIds.length && location === -1; i++) {
          if (state.components.allIds[i] === id) location = i;
        }
        if (location !== -1) state.components.allIds.splice(location, 1);
      } else {
        throw new Error('deleteUsedComponent');
      }
    },
    ...layerActions,
    ...iconActions,
    ...imageViewActions,
    ...textActions,
    ...screenActions,
    ...baseActions,
  },
});

const duplicatedName = (items: ItemsTypeItems, name: string): boolean => {
  const ids = items.allIds;
  for (const id of ids) {
    const item = items.byId[id];
    if (item.name === name) return true;
  }
  return false;
};

const changeTreeIds = (pasteTree: ITreeNodeAttributes, newIds: Record<string, string>): ITreeNode => {
  const newTreeNode = new TreeNode(newIds[pasteTree.parent] ?? 'paste', newIds[pasteTree.id]);
  for (const child of pasteTree.children) {
    newTreeNode.children.push(changeTreeIds(child, newIds));
  }
  return newTreeNode;
};

const getNonComponentList = (itemTree: ITreeNode, items: TypeItems): [string[], string[]] => {
  let itemList: string[] = [];
  let componentList: string[] = [];
  const item = items[itemTree.getId()];
  if (!('component' in item) || ('component' in item && item.component === '')) {
    itemList.push(item.id);
    const children = itemTree.children;
    for (const child of children) {
      const childLists = getNonComponentList(child, items);
      itemList = itemList.concat(childLists[0]);
      componentList = componentList.concat(childLists[1]);
    }
  } else {
    componentList.push(item.id);
  }
  return [itemList, componentList];
};

export const getRawVariables = (state: StateType): any => state.items.variables;

export const getDefaultVariables = (state: StateType): any => {
  const vars = getRawVariables(state);
  return vars?.model.attributeReference.Default;
};

export interface BooleanExportedVar {
  name: string;
  type: string;
  desktop: BooleanVariable;
  tablet: BooleanVariable;
  phone: BooleanVariable;
}

export interface ColorExportedVar {
  name: string;
  type: string;
  desktop: ColorVariable;
  tablet: ColorVariable;
  phone: ColorVariable;
}

export interface SizeExportedVar {
  name: string;
  type: string;
  desktop: SizeVariable;
  tablet: SizeVariable;
  phone: SizeVariable;
}

export interface TextExportedVar {
  name: string;
  type: string;
  desktop: TextVariable;
  tablet: TextVariable;
  phone: TextVariable;
}

export interface TypographyExportedVar {
  name: string;
  type: string;
  desktop: TypographyVariable;
  tablet: TypographyVariable;
  phone: TypographyVariable;
}

export interface AssetExportedVar {
  name: string;
  type: string;
  desktop: AssetVariable;
  tablet: AssetVariable;
  phone: AssetVariable;
}

export interface StandarExportedVar {
  name: string;
  type: string;
  desktop: TypeVariable;
  tablet: TypeVariable;
  phone: TypeVariable;
}

export type ExportedVarTypes = 'typography' | 'size' | 'color' | 'asset' | 'text' | 'boolean';

export type ExportedVar =
  | StandarExportedVar
  | AssetExportedVar
  | TypographyExportedVar
  | TextExportedVar
  | SizeExportedVar
  | ColorExportedVar
  | BooleanExportedVar;

export const getVariablesOfItem = createSelector(
  [(state: StateType) => getDefaultVariables(state), (state: StateType, id: string) => id],
  (vars: any, id) => {
    const foundVars: any = {};
    for (const key in vars) {
      const mvar = vars[key];
      if (id in mvar) {
        foundVars[key] = mvar[id];
      }
    }
    return foundVars;
  }
);

export const getTypeOfVariable = (val: TypeVariable): string => {
  if ('size' in val) return 'size';
  if ('color' in val) return 'color';
  if ('text' in val) return 'text';
  if ('asset' in val) return 'asset';
  if ('boolean' in val) return 'boolean';
  if ('typography' in val) return 'typography';
  return 'standard';
};

export const getResponsiveDOMVariables = createSelector(
  (state: StateType) => state.items.variables.model,
  (modelVariables) => {
    const variables = modelVariables.referenceValues.Default;
    const resVariables = modelVariables.responsiveReferenceValues;
    const desktopVars = resVariables.desktop.Default;
    const tabletVars = resVariables.tablet.Default;
    const phoneVars = resVariables.phone.Default;
    const convertedVariables: ExportedVar[] = [];
    for (const mvar in variables) {
      const name = mvar;
      const variable = variables[mvar];
      const mType = getTypeOfVariable(variable);
      const desktopVar = desktopVars?.[name];
      const tabletVar = tabletVars?.[name];
      const phoneVar = phoneVars?.[name];
      if (mType !== 'standard') {
        const convertedVar: ExportedVar = {
          name,
          type: mType as ExportedVarTypes,
          desktop: desktopVar ?? variable,
          tablet: tabletVar ?? variable,
          phone: phoneVar ?? variable,
        };
        convertedVariables.push(convertedVar);
      }
    }
    return convertedVariables;
  }
);

export const getResponsiveDOMVariablesForKinds = createSelector(
  getResponsiveDOMVariables,
  (state: StateType, kinds: string[]) => kinds,
  (vars, kinds) => {
    if (vars == null) return [];
    return vars.filter((mvar: any) => kinds.includes(mvar.type));
  }
);

export const getDOMVariablesForKind = createSelector(
  [(state: StateType) => getResponsiveDOMVariables(state), (state: StateType, kind: string) => kind],
  (vars, kind) => {
    if (vars == null) return [];
    return vars.filter((mvar) => mvar.type === kind);
  }
);

export const getDOMVariablesForKinds = createSelector(
  [(state: StateType) => getResponsiveDOMVariables(state), (state: StateType, kinds: string[]) => kinds],
  (vars, kinds) => {
    if (vars == null) return [];
    return vars.filter((mvar: any) => kinds.includes(mvar.type));
  }
);

export const getItems = (state: StateType): TypeItems => state.items.items.byId;

export const getCustomTags = (state: StateType): CustomTagType[] => state.items.customTags;

export const getClasses = (state: StateType): ConversorReferenceGroupType => state.items.classes;

export const getVariants = createSelector(
  [(state: StateType) => state.items.items, (state: StateType, id: string) => id],
  (items, id) => {
    const item = items.byId[id];
    if (item == null || !('variants' in item)) return null;
    const variants = [];
    for (const variant in item.variants) {
      variants.push({ name: variant });
    }
    return variants;
  }
);

export const getCurrentVariant = createSelector(
  [(state: StateType) => state.items.items, (state: StateType, id: string) => id],
  (items, id) => {
    const item = items.byId[id];
    if (item == null || 'currentVariant' in item || item.currentVariant == null) return null;
    return { name: item.currentVariant };
  }
);

const selectTree = createSelector(
  (state: StateType) => state.items.tree.root.children,
  (children) => {
    const root = new TreeNode('-1', '0');
    root.children = Tree.objectToTreeNode(children);
    const tree = new Tree(root);
    return tree;
  }
);

export const getTree = createSelector([selectTree], (tree: ITree) => {
  const rootNode = new TreeNode('', '');
  rootNode.children = Tree.objectToTreeNode(JSON.parse(JSON.stringify(tree.root.children)));
  const nTree = new Tree(rootNode);
  return nTree;
});

export const getSelectedComponentPreview = (state: StateType): string => {
  return state.items.components.selectedComponentPreview;
};

export const getComponents = (state: StateType): Record<string, TypeComponentData> =>
  state.items.components.byId;

export const doGetAllComponents = (
  components: Record<string, TypeComponentData>,
  loadedComponents: ExtractedComponent[]
): ExtractedComponent[] => {
  let componentsData = [];
  for (const id in components) {
    const component = components[id];
    const props: Prop[] = [];
    componentsData.push({
      name: component.display,
      id,
      path: '',
      props,
      display: component.display ?? component.name,
    });
  }
  componentsData = componentsData.concat(loadedComponents);
  // componentsData = componentsData.sort((a, b) => (a.name > b.name ? 1 : -1));
  return componentsData;
};

export const getAllComponents = createSelector(
  (state: StateType) => state.items.components.byId,
  (state: StateType) => getLoadedComponents(state),
  (components, loadedComponents) => {
    return doGetAllComponents(components, loadedComponents);
  }
);

export const getComponentParams = (state: StateType, id: string): IParametersValuesInternalList =>
  state.items.componentParams.byId[id] ?? {};

export const getComponentProps = createSelector(
  (state: StateType) => state.items.components.byId,
  (state: StateType, id: string) => id,
  (components, id) => {
    const component = components[id];
    if (component == null) return null;
    const props: TypeComponentVariableDOM[] = [];
    if (component != null) {
      if (component.imported) {
        if (component.code != null && typeof component.code !== 'string') {
          for (const a in component.code.parameters) {
            const info = component.code.parameters[a];
            props.push({
              name: info.name,
              type: 'param',
              description: info.description,
              value: { parameter: true, value: info.value },
            });
          }
        }
      } else if (component.references != null) {
        const variables = component.references.variables;
        for (const reference in variables) {
          const mVar = variables[reference];
          if ('value' in mVar && 'description' in mVar) {
            props.push({
              type: mVar.type,
              value: mVar.value,
              description: mVar.description,
              name: reference,
            });
          }
        }
      }
    }
    return props;
  }
);

export const getComponentDependencies = createSelector(
  (state: StateType) => state.items.components.byId,
  (state: StateType, id: string) => id,
  (components, id) => {
    const component: TypeComponentData = components[id];
    if (component != null) {
      if (component.imported) {
        if (component.code != null && typeof component.code !== 'string') {
          return component.code.dependencies;
        }
      }
    }
    return [];
  }
);

export const getCurrentDevice = (state: StateType): string => state.items.currentDevice;

export const getComponentIframeData = createSelector(
  [
    (state: StateType) => state.items.components.byId,
    (state: StateType, id: string) => id,
    (state: StateType, id: string, paramList: IParametersTypeList) => paramList,
  ],
  (components, id, paramList) => {
    const component = components[id];
    if (component == null) return null;
    let tree;
    let items;
    let references;
    if (component.imported) {
      tree = new Tree(new TreeNode('0', '1'));
      items = {};
      references = { Default: {}, Responsiveness: {} };
    } else {
      tree = component.data?.tree;
      items = component.data?.items;
      references = component.data?.attributeReference;
    }
    const values = component.references?.variables;
    const data = {
      script: {
        component,
        name: 'projectName',
        icon: undefined,
        classDeviceCoverage: {},
        userEdited: {},
        themes: {
          variantsAttributeReference: [],
          parametersValuesList: {},
          referenceGroups: {},
          responsiveVariableInClass: {},
          references,
          values,
          responsiveValues: {
            desktop: { Default: {}, Responsiveness: {} },
            tablet: { Default: {}, Responsiveness: {} },
            phone: { Default: {}, Responsiveness: {} },
          },
        },
        selectedScreen: '',
        showWatermark: true,
        items,
        tree,
        params: paramList,
      },
      files: [],
      isPreview: true,
      components,
    };
    const res = mConversor.renderComponent(data.script as unknown as IScriptType, data.files, {
      components: data.components,
      getComponentByName,
    });
    if (res == null) return ['', '', ''];
    const mFiles = renderFileRecursively(res[0], 0, '');
    const mPack = JSON.parse(mFiles['package.json']);
    const mDependencies = mPack.dependencies;
    const mFilesString = JSON.stringify(mFiles);
    const mDependenciesString = JSON.stringify(mDependencies);

    return [mFilesString, mDependenciesString, 'react'];
  }
);

export const getScreenProperties = createSelector(
  (state: StateType) => state.items.variables,
  (state: StateType, item: ScreenProperties) => item,
  (state: StateType, item: ScreenProperties, device: string) => device,
  (variables, item, device): ScreenProperties => {
    const newItem: ScreenProperties = JSON.parse(JSON.stringify(item));
    const id = item.id;
    const themes = variables.model.attributeReference;
    const deviceKey = device as keyof typeof variables.model.responsiveReferenceValues;
    const deviceValues = variables.model.responsiveReferenceValues[deviceKey];
    for (const theme in themes) {
      for (const variable in themes[theme as keyof typeof themes]) {
        const idList = themes[theme as keyof typeof themes][variable];
        if (idList[id] != null) {
          const attributes = idList[id] as TypeAttributeReference[];
          for (const attribute of attributes) {
            let value = variables.model.referenceValues[theme][variable];
            if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
            switch (attribute.attribute) {
              case 'background':
                if ('color' in value) newItem.backgroundColor.data = value.value;
                break;
              case 'orientation':
                if ('orientation' in value) newItem.orientation = value.value;
                break;
              case 'verticalAlignment':
                if ('alignment' in value) newItem.verticalAlignment = value.value;
                break;
              case 'horizontalAlignment':
                if ('alignment' in value) newItem.horizontalAlignment = value.value;
                break;
              case 'padding-left':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'padding-right':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'padding-top':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'padding-bottom':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'scrollable':
                if ('boolean' in value && value.kind === 'Scrollable') {
                  newItem.scrollable = value.value ? 'scroll' : 'hidden';
                }
                break;
            }
          }
        }
      }
    }
    return newItem;
  }
);

export const getLayerProperties = createSelector(
  (state: StateType) => state.items.variables,
  (state: StateType) => state.items.classes,
  (state: StateType, item: LayerProperties) => item,
  (state: StateType, item: LayerProperties, device: string) => device,
  (variables, classes, item, device): LayerProperties => {
    const newItem: LayerProperties = JSON.parse(JSON.stringify(item));
    const id = item.id;
    const themes = variables.model.attributeReference;
    const deviceKey = device as keyof typeof variables.model.responsiveReferenceValues;
    const deviceValues = variables.model.responsiveReferenceValues[deviceKey];
    for (const theme in themes) {
      for (const variable in themes[theme as keyof typeof themes]) {
        const idList = themes[theme as keyof typeof themes][variable];
        if (idList[id] != null) {
          const attributes = idList[id] as TypeAttributeReference[];
          for (const attribute of attributes) {
            let value = variables.model.referenceValues[theme][variable];
            if (deviceValues[theme][variable] != null) value = deviceValues[theme][variable];
            switch (attribute.attribute) {
              case 'background':
                if ('color' in value) newItem.background.data = value.value;
                break;
              case 'margin-left':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-right':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-top':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-bottom':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'visible':
                if ('boolean' in value && value.kind === 'Visibility') {
                  newItem.visible = value.value;
                }
                break;
              case 'orientation':
                if ('orientation' in value) newItem.orientation = value.value;
                break;
              case 'verticalAlignment':
                if ('alignment' in value) newItem.verticalAlignment = value.value;
                break;
              case 'horizontalAlignment':
                if ('alignment' in value) newItem.horizontalAlignment = value.value;
                break;
              case 'padding-left':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'padding-right':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'padding-top':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'padding-bottom':
                if ('size' in value) newItem.paddings.left = value.value;
                break;
              case 'scrollable':
                if ('boolean' in value && value.kind === 'Scrollable') {
                  newItem.scrollable = value.value ? 'scroll' : 'hidden';
                }
                break;
              case 'width':
                if ('dimension' in value) newItem.width = value.value;
                break;
              case 'height':
                if ('dimension' in value) newItem.height = value.value;
                break;
              case 'shadowOffsetX':
                if ('size' in value) newItem.shadow.offSetX = value.value;
                break;
              case 'shadowOffsetY':
                if ('size' in value) newItem.shadow.offSetY = value.value;
                break;
              case 'shadowBlur':
                if ('size' in value) newItem.shadow.blur = value.value;
                break;
              case 'shadowColor':
                if ('color' in value) newItem.shadow.color = value.value;
                break;
              case 'borderWidth':
                if ('size' in value && 'width' in newItem.border.data) {
                  newItem.border.data.width = value.value;
                }
                break;
              case 'borderColor':
                if ('color' in value && 'color' in newItem.border.data) {
                  newItem.border.data.color = value.value;
                }
                break;
              case 'borderWidth-top':
                if ('size' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.top.width = value.value;
                }
                break;
              case 'borderColor-top':
                if ('color' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.top.color = value.value;
                }
                break;
              case 'borderWidth-bottom':
                if ('size' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.bottom.width = value.value;
                }
                break;
              case 'borderColor-bottom':
                if ('color' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.bottom.color = value.value;
                }
                break;
              case 'borderWidth-left':
                if ('size' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.left.width = value.value;
                }
                break;
              case 'borderColor-top-left':
                if ('color' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.left.color = value.value;
                }
                break;
              case 'borderWidth-right':
                if ('size' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.right.width = value.value;
                }
                break;
              case 'borderColor-right':
                if ('color' in value && 'direction' in newItem.border.data) {
                  newItem.border.data.direction.right.color = value.value;
                }
                break;
              case 'radiusAbsolute':
                if ('size' in value && !('topLeft' in newItem.border.data.radius.data)) {
                  newItem.border.data.radius.data = value.value;
                }
                break;
              case 'radiusAbsolute-topLeft':
                if ('size' in value && 'topLeft' in newItem.border.data.radius.data) {
                  newItem.border.data.radius.data.topLeft = value.value;
                }
                break;
              case 'radiusAbsolute-topRight':
                if ('size' in value && 'topRight' in newItem.border.data.radius.data) {
                  newItem.border.data.radius.data.topRight = value.value;
                }
                break;
              case 'radiusAbsolute-bottomLeft':
                if ('size' in value && 'bottomLeft' in newItem.border.data.radius.data) {
                  newItem.border.data.radius.data.bottomLeft = value.value;
                }
                break;
              case 'radiusAbsolute-bottomRight':
                if ('size' in value && 'bottomRight' in newItem.border.data.radius.data) {
                  newItem.border.data.radius.data.bottomRight = value.value;
                }
                break;
            }
          }
        }
      }
    }
    let classesList = item.classes;
    if (
      'currentVariant' in item &&
      item.currentVariant != null &&
      item.currentVariant !== '' &&
      'state' in item &&
      item.state != null
    ) {
      const info = item.state[item.currentVariant];
      if (info != null) {
        for (const attribute in info.normal) {
          switch (attribute) {
            case 'classes':
              classesList = classesList?.concat(info.normal.classes ?? []);
              break;
            case 'background':
              if (info.normal.background != null) {
                newItem.background = info.normal.background;
              }
              break;
            case 'margins':
              if (info.normal.margins != null) {
                newItem.margins = info.normal.margins;
              }
              break;
            case 'visible':
              if (info.normal.visible != null) {
                newItem.visible = info.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (info.normal.cursorPointer != null) {
                newItem.cursorPointer = info.normal.cursorPointer;
              }
              break;
            case 'shadow':
              if (info.normal.shadow != null) newItem.shadow = info.normal.shadow;
              break;
            case 'border':
              if (info.normal.border != null) newItem.border = info.normal.border;
              break;
          }
        }
        for (const attribute in info.variable) {
          const theme = info.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = info.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'background':
              if ('color' in value) newItem.background.data = value.value;
              break;
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'orientation':
              if ('orientation' in value) newItem.orientation = value.value;
              break;
            case 'verticalAlignment':
              if ('alignment' in value) newItem.verticalAlignment = value.value;
              break;
            case 'horizontalAlignment':
              if ('alignment' in value) newItem.horizontalAlignment = value.value;
              break;
            case 'padding-left':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'padding-right':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'padding-top':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'padding-bottom':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'scrollable':
              if ('boolean' in value && value.kind === 'Scrollable') {
                newItem.scrollable = value.value ? 'scroll' : 'hidden';
              }
              break;
            case 'shadowOffsetX':
              if ('size' in value) newItem.shadow.offSetX = value.value;
              break;
            case 'shadowOffsetY':
              if ('size' in value) newItem.shadow.offSetY = value.value;
              break;
            case 'shadowBlur':
              if ('size' in value) newItem.shadow.blur = value.value;
              break;
            case 'shadowColor':
              if ('color' in value) newItem.shadow.color = value.value;
              break;
            case 'radiusAbsolute':
              if ('size' in value && !('topLeft' in newItem.border.data.radius)) {
                newItem.border.data.radius.data = value.value;
              }
              break;
            case 'border-topLeft':
              if ('size' in value && 'topLeft' in newItem.border.data.radius) {
                newItem.border.data.radius.topLeft = value.value;
              }
              break;
            case 'border-topRight':
              if ('size' in value && 'topRight' in newItem.border.data.radius) {
                newItem.border.data.radius.topRight = value.value;
              }
              break;
            case 'border-bottomRight':
              if ('size' in value && 'bottomRight' in newItem.border.data.radius) {
                newItem.border.data.radius.bottomRight = value.value;
              }
              break;
            case 'border-bottomLeft':
              if ('size' in value && 'bottomLeft' in newItem.border.data.radius) {
                newItem.border.data.radius.bottomLeft = value.value;
              }
              break;
            case 'borderWidth':
              if ('size' in value && 'width' in newItem.border.data) {
                newItem.border.data.width = value.value;
              }
              break;
            case 'border-topwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'border-bottomwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'border-leftwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'border-rightwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'borderColor':
              if ('color' in value && 'color' in newItem.border.data) {
                newItem.border.data.color = value.value;
              }
              break;
            case 'border-topcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.top.color = value.value;
              }
              break;
            case 'border-bottomcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.bottom.color = value.value;
              }
              break;
            case 'border-leftcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.left.color = value.value;
              }
              break;
            case 'border-rightcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.color = value.value;
              }
              break;
          }
        }
      }
    }
    if ('classes' in item && classesList != null) {
      for (const className in classesList) {
        const classInfo = classes[className];
        for (const attribute in classInfo.normal) {
          switch (attribute) {
            case 'backgroundColor':
              if (classInfo.normal.backgroundColor != null) {
                newItem.background = classInfo.normal.backgroundColor;
              }
              break;
            case 'margins':
              if (classInfo.normal.margins != null) {
                newItem.margins = classInfo.normal.margins;
              }
              break;
            case 'visible':
              if (classInfo.normal.visible != null) {
                newItem.visible = classInfo.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (classInfo.normal.cursorPointer != null) {
                newItem.cursorPointer = classInfo.normal.cursorPointer;
              }
              break;
            case 'shadow':
              if (classInfo.normal.shadow != null) {
                newItem.shadow = classInfo.normal.shadow;
              }
              break;
            case 'border':
              if (classInfo.normal.border != null) newItem.border = classInfo.normal.border;
              break;
          }
        }
        for (const attribute in classInfo.variable) {
          const theme = classInfo.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = classInfo.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'background':
              if ('color' in value) newItem.background.data = value.value;
              break;
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'orientation':
              if ('orientation' in value) newItem.orientation = value.value;
              break;
            case 'verticalAlignment':
              if ('alignment' in value) newItem.verticalAlignment = value.value;
              break;
            case 'horizontalAlignment':
              if ('alignment' in value) newItem.horizontalAlignment = value.value;
              break;
            case 'padding-left':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'padding-right':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'padding-top':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'padding-bottom':
              if ('size' in value) newItem.paddings.left = value.value;
              break;
            case 'scrollable':
              if ('boolean' in value && value.kind === 'Scrollable') {
                newItem.scrollable = value.value ? 'scroll' : 'hidden';
              }
              break;
            case 'shadowOffsetX':
              if ('size' in value) newItem.shadow.offSetX = value.value;
              break;
            case 'shadowOffsetY':
              if ('size' in value) newItem.shadow.offSetY = value.value;
              break;
            case 'shadowBlur':
              if ('size' in value) newItem.shadow.blur = value.value;
              break;
            case 'shadowColor':
              if ('color' in value) newItem.shadow.color = value.value;
              break;
            case 'radiusAbsolute':
              if ('size' in value && !('topLeft' in newItem.border.data.radius)) {
                newItem.border.data.radius.data = value.value;
              }
              break;
            case 'border-topLeft':
              if ('size' in value && 'topLeft' in newItem.border.data.radius) {
                newItem.border.data.radius.topLeft = value.value;
              }
              break;
            case 'border-topRight':
              if ('size' in value && 'topRight' in newItem.border.data.radius) {
                newItem.border.data.radius.topRight = value.value;
              }
              break;
            case 'border-bottomRight':
              if ('size' in value && 'bottomRight' in newItem.border.data.radius) {
                newItem.border.data.radius.bottomRight = value.value;
              }
              break;
            case 'border-bottomLeft':
              if ('size' in value && 'bottomLeft' in newItem.border.data.radius) {
                newItem.border.data.radius.bottomLeft = value.value;
              }
              break;
            case 'borderWidth':
              if ('size' in value && 'width' in newItem.border.data) {
                newItem.border.data.width = value.value;
              }
              break;
            case 'border-topwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'border-bottomwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'border-leftwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'border-rightwidth':
              if ('size' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.width = value.value;
              }
              break;
            case 'borderColor':
              if ('color' in value && 'color' in newItem.border.data) {
                newItem.border.data.color = value.value;
              }
              break;
            case 'border-topcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.top.color = value.value;
              }
              break;
            case 'border-bottomcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.bottom.color = value.value;
              }
              break;
            case 'border-leftcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.left.color = value.value;
              }
              break;
            case 'border-rightcolor':
              if ('color' in value && 'direction' in newItem.border.data) {
                newItem.border.data.direction.right.color = value.value;
              }
              break;
          }
        }
      }
    }
    return newItem;
  }
);

export const getTextProperties = createSelector(
  (state: StateType) => state.items.variables,
  (state: StateType) => state.items.classes,
  (state: StateType, item: TextProperties) => item,
  (state: StateType, item: TextProperties, device: string) => device,
  (variables, classes, item, device): TextProperties => {
    const newItem: TextProperties = JSON.parse(JSON.stringify(item));
    const id = item.id;
    const themes = variables.model.attributeReference;
    const deviceKey = device as keyof typeof variables.model.responsiveReferenceValues;
    const deviceValues = variables.model.responsiveReferenceValues[deviceKey];
    for (const theme in themes) {
      for (const variable in themes[theme as keyof typeof themes]) {
        const idList = themes[theme as keyof typeof themes][variable];
        if (idList[id] != null) {
          const attributes = idList[id] as TypeAttributeReference[];
          for (const attribute of attributes) {
            let value = variables.model.referenceValues[theme][variable];
            if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
            switch (attribute.attribute) {
              case 'fontStyles':
                if ('typography' in value) {
                  newItem.fontFamily = value.value.fontFamily;
                  newItem.fontSize = value.value.fontSize;
                  newItem.leading = value.value.leading;
                  newItem.spacing = value.value.spacing;
                  newItem.weight = value.value.weight;
                  newItem.style = value.value.style ? 'italic' : 'normal';
                }
                break;
              case 'margin-left':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-right':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-top':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-bottom':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'valueheight':
                if ('dimension' in value) newItem.height = value.value;
                break;
              case 'valuewidth':
                if ('dimension' in value) newItem.width = value.value;
                break;
              case 'visible':
                if ('boolean' in value && value.kind === 'Visibility') {
                  newItem.visible = value.value;
                }
                break;
              case 'textAlign':
                if ('alignment' in value) newItem.textAlignment = value.value;
                break;
              case 'fontFamily':
                if ('asset' in value) newItem.fontFamily = value.value;
                break;
              case 'fontSize':
                if ('size' in value) newItem.fontSize = value.value;
                break;
              case 'leading':
                if ('size' in value) newItem.leading = value.value.value;
                break;
              case 'textWeight':
                if ('size' in value) newItem.weight = value.value.value;
                break;
              case 'spacing':
                if ('size' in value) newItem.spacing = value.value.value;
                break;
              case 'text':
                if ('text' in value) newItem.content.default = value.value.content.default;
                break;
              case 'width':
                if ('dimension' in value) newItem.width = value.value;
                break;
              case 'height':
                if ('dimension' in value) newItem.height = value.value;
                break;
            }
          }
        }
      }
    }
    let classesList = item.classes;
    if (
      'currentVariant' in item &&
      item.currentVariant != null &&
      item.currentVariant !== '' &&
      'state' in item &&
      item.state != null
    ) {
      const info = item.state[item.currentVariant];
      if (info != null) {
        for (const attribute in info.normal) {
          switch (attribute) {
            case 'margins':
              if (info.normal.margins != null) {
                newItem.margins = info.normal.margins;
              }
              break;
            case 'visible':
              if (info.normal.visible != null) {
                newItem.visible = info.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (info.normal.cursorPointer != null) {
                newItem.cursorPointer = info.normal.cursorPointer;
              }
              break;
            case 'classes':
              classesList = classesList?.concat(info.normal.classes ?? []);
              break;
            case 'textAlign':
              if (info.normal.textAlignment != null) newItem.textAlignment = info.normal.textAlignment;
              break;
            case 'fontFamily':
              if (info.normal.fontFamily != null) newItem.fontFamily = info.normal.fontFamily;
              break;
            case 'fontSize':
              if (info.normal.fontSize != null) newItem.fontSize = info.normal.fontSize;
              break;
            case 'leading':
              if (info.normal.leading != null) newItem.leading = info.normal.leading;
              break;
            case 'textWeight':
              if (info.normal.weight != null) newItem.weight = info.normal.weight;
              break;
            case 'spacing':
              if (info.normal.spacing != null) newItem.spacing = info.normal.spacing;
              break;
            case 'width':
              if (info.normal.width != null) newItem.width = info.normal.width;
              break;
            case 'height':
              if (info.normal.height != null) newItem.height = info.normal.height;
              break;
          }
        }
        for (const attribute in info.variable) {
          const theme = info.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = info.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'fontStyles':
              if ('typography' in value) {
                newItem.fontFamily = value.value.fontFamily;
                newItem.fontSize = value.value.fontSize;
                newItem.leading = value.value.leading;
                newItem.spacing = value.value.spacing;
                newItem.weight = value.value.weight;
                newItem.style = value.value.style ? 'italic' : 'normal';
              }
              break;
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'valueheight':
              if ('dimension' in value) newItem.height = value.value;
              break;
            case 'valuewidth':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'textAlign':
              if ('alignment' in value) newItem.textAlignment = value.value;
              break;
            case 'fontFamily':
              if ('asset' in value) newItem.fontFamily = value.value;
              break;
            case 'fontSize':
              if ('size' in value) newItem.fontSize = value.value;
              break;
            case 'leading':
              if ('size' in value) newItem.leading = value.value.value;
              break;
            case 'textWeight':
              if ('size' in value) newItem.weight = value.value.value;
              break;
            case 'spacing':
              if ('size' in value) newItem.spacing = value.value.value;
              break;
            case 'width':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'height':
              if ('dimension' in value) newItem.height = value.value;
              break;
          }
        }
      }
    }
    if ('classes' in item && classesList != null) {
      for (const className in classesList) {
        const classInfo = classes[className];
        for (const attribute in classInfo.normal) {
          switch (attribute) {
            case 'margins':
              if (classInfo.normal.margins != null) {
                newItem.margins = classInfo.normal.margins;
              }
              break;
            case 'visible':
              if (classInfo.normal.visible != null) {
                newItem.visible = classInfo.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (classInfo.normal.cursorPointer != null) {
                newItem.cursorPointer = classInfo.normal.cursorPointer;
              }
              break;
            case 'textAlign':
              if (classInfo.normal.textAlignment != null) {
                newItem.textAlignment = classInfo.normal.textAlignment;
              }
              break;
            case 'fontFamily':
              if (classInfo.normal.fontFamily != null) newItem.fontFamily = classInfo.normal.fontFamily;
              break;
            case 'fontSize':
              if (classInfo.normal.fontSize != null) newItem.fontSize = classInfo.normal.fontSize;
              break;
            case 'leading':
              if (classInfo.normal.leading != null) newItem.leading = classInfo.normal.leading;
              break;
            case 'textWeight':
              if (classInfo.normal.weight != null) newItem.weight = classInfo.normal.weight;
              break;
            case 'spacing':
              if (classInfo.normal.spacing != null) newItem.spacing = classInfo.normal.spacing;
              break;
            case 'width':
              if (classInfo.normal.width != null) newItem.width = classInfo.normal.width;
              break;
            case 'height':
              if (classInfo.normal.height != null) newItem.height = classInfo.normal.height;
              break;
          }
        }
        for (const attribute in classInfo.variable) {
          const theme = classInfo.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = classInfo.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'fontStyles':
              if ('typography' in value) {
                newItem.fontFamily = value.value.fontFamily;
                newItem.fontSize = value.value.fontSize;
                newItem.leading = value.value.leading;
                newItem.spacing = value.value.spacing;
                newItem.weight = value.value.weight;
                newItem.style = value.value.style ? 'italic' : 'normal';
              }
              break;
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'valueheight':
              if ('dimension' in value) newItem.height = value.value;
              break;
            case 'valuewidth':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'textAlign':
              if ('alignment' in value) newItem.textAlignment = value.value;
              break;
            case 'fontFamily':
              if ('asset' in value) newItem.fontFamily = value.value;
              break;
            case 'fontSize':
              if ('size' in value) newItem.fontSize = value.value;
              break;
            case 'leading':
              if ('size' in value) newItem.leading = value.value.value;
              break;
            case 'textWeight':
              if ('size' in value) newItem.weight = value.value.value;
              break;
            case 'spacing':
              if ('size' in value) newItem.spacing = value.value.value;
              break;
            case 'width':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'height':
              if ('dimension' in value) newItem.height = value.value;
              break;
          }
        }
      }
    }
    return newItem;
  }
);

export const getMediaProperties = createSelector(
  (state: StateType) => state.items.variables,
  (state: StateType) => state.items.classes,
  (state: StateType, item: ImageViewProperties) => item,
  (state: StateType, item: ImageViewProperties, device: string) => device,
  (variables, classes, item, device): ImageViewProperties => {
    const newItem: ImageViewProperties = JSON.parse(JSON.stringify(item));
    const id = item.id;
    const themes = variables.model.attributeReference;
    const deviceKey = device as keyof typeof variables.model.responsiveReferenceValues;
    const deviceValues = variables.model.responsiveReferenceValues[deviceKey];
    for (const theme in themes) {
      for (const variable in themes[theme as keyof typeof themes]) {
        const idList = themes[theme as keyof typeof themes][variable];
        if (idList[id] != null) {
          const attributes = idList[id] as TypeAttributeReference[];
          for (const attribute of attributes) {
            let value = variables.model.referenceValues[theme][variable];
            if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
            switch (attribute.attribute) {
              case 'margin-left':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-right':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-top':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-bottom':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'valueheight':
                if ('dimension' in value) newItem.height = value.value;
                break;
              case 'valuewidth':
                if ('dimension' in value) newItem.width = value.value;
                break;
              case 'visible':
                if ('boolean' in value && value.kind === 'Visibility') {
                  newItem.visible = value.value;
                }
                break;
              case 'src':
                if ('asset' in value) newItem.src = value.value;
                break;
              case 'width':
                if ('dimension' in value) newItem.width = value.value;
                break;
              case 'height':
                if ('dimension' in value) newItem.height = value.value;
                break;
            }
          }
        }
      }
    }
    let classesList = item.classes;
    if (
      'currentVariant' in item &&
      item.currentVariant != null &&
      item.currentVariant !== '' &&
      'state' in item &&
      item.state != null
    ) {
      const info = item.state[item.currentVariant];
      if (info != null) {
        for (const attribute in info.normal) {
          switch (attribute) {
            case 'margins':
              if (info.normal.margins != null) {
                newItem.margins = info.normal.margins;
              }
              break;
            case 'visible':
              if (info.normal.visible != null) {
                newItem.visible = info.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (info.normal.cursorPointer != null) {
                newItem.cursorPointer = info.normal.cursorPointer;
              }
              break;
            case 'classes':
              classesList = classesList?.concat(info.normal.classes ?? []);
              break;
            case 'width':
              if (info.normal.width != null) newItem.width = info.normal.width;
              break;
            case 'height':
              if (info.normal.height != null) newItem.height = info.normal.height;
              break;
          }
        }
        for (const attribute in info.variable) {
          const theme = info.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = info.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'valueheight':
              if ('dimension' in value) newItem.height = value.value;
              break;
            case 'valuewidth':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'src':
              if ('asset' in value) newItem.src = value.value;
              break;
            case 'width':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'height':
              if ('dimension' in value) newItem.height = value.value;
              break;
          }
        }
      }
    }
    if ('classes' in item && classesList != null) {
      for (const className in classesList) {
        const classInfo = classes[className];
        for (const attribute in classInfo.normal) {
          switch (attribute) {
            case 'margins':
              if (classInfo.normal.margins != null) {
                newItem.margins = classInfo.normal.margins;
              }
              break;
            case 'visible':
              if (classInfo.normal.visible != null) {
                newItem.visible = classInfo.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (classInfo.normal.cursorPointer != null) {
                newItem.cursorPointer = classInfo.normal.cursorPointer;
              }
              break;
            case 'width':
              if (classInfo.normal.width != null) newItem.width = classInfo.normal.width;
              break;
            case 'height':
              if (classInfo.normal.height != null) newItem.height = classInfo.normal.height;
              break;
          }
        }
        for (const attribute in classInfo.variable) {
          const theme = classInfo.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = classInfo.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'valueheight':
              if ('dimension' in value) newItem.height = value.value;
              break;
            case 'valuewidth':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'src':
              if ('asset' in value) newItem.src = value.value;
              break;
            case 'width':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'height':
              if ('dimension' in value) newItem.height = value.value;
              break;
          }
        }
      }
    }
    return newItem;
  }
);

export const getIconProperties = createSelector(
  (state: StateType) => state.items.variables,
  (state: StateType) => state.items.classes,
  (state: StateType, item: IconProperties) => item,
  (state: StateType, item: IconProperties, device: string) => device,
  (variables, classes, item, device): IconProperties => {
    const newItem: IconProperties = JSON.parse(JSON.stringify(item));
    const id = item.id;
    const themes = variables.model.attributeReference;
    const deviceKey = device as keyof typeof variables.model.responsiveReferenceValues;
    const deviceValues = variables.model.responsiveReferenceValues[deviceKey];
    for (const theme in themes) {
      for (const variable in themes[theme as keyof typeof themes]) {
        const idList = themes[theme as keyof typeof themes][variable];
        if (idList[id] != null) {
          const attributes = idList[id] as TypeAttributeReference[];
          for (const attribute of attributes) {
            let value = variables.model.referenceValues[theme][variable];
            if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
            switch (attribute.attribute) {
              case 'iconColor':
                if ('color' in value) newItem.backgroundColor.data = value.value;
                break;
              case 'margin-left':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-right':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-top':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'margin-bottom':
                if ('size' in value) newItem.margins.left = value.value;
                break;
              case 'fontSize':
                if ('size' in value) {
                  newItem.fontSize = value.value;
                  newItem.width.data = value.value;
                  newItem.height.data = value.value;
                }
                break;
              case 'icon':
                if ('text' in value) newItem.icon = value.value.content.default;
                break;
              case 'visible':
                if ('boolean' in value && value.kind === 'Visibility') {
                  newItem.visible = value.value;
                }
                break;
              case 'width':
                if ('dimension' in value) newItem.width = value.value;
                break;
              case 'height':
                if ('dimension' in value) newItem.height = value.value;
                break;
            }
          }
        }
      }
    }
    let classesList = item.classes;
    if (
      'currentVariant' in item &&
      item.currentVariant != null &&
      item.currentVariant !== '' &&
      'state' in item &&
      item.state != null
    ) {
      const info = item.state[item.currentVariant];
      if (info != null) {
        for (const attribute in info.normal) {
          switch (attribute) {
            case 'classes':
              classesList = classesList?.concat(info.normal.classes ?? []);
              break;
            case 'backgroundColor':
              if (info.normal.backgroundColor != null) {
                newItem.backgroundColor = info.normal.backgroundColor;
              }
              break;
            case 'margins':
              if (info.normal.margins != null) {
                newItem.margins = info.normal.margins;
              }
              break;
            case 'fontSize':
              if (info.normal.fontSize != null) {
                newItem.fontSize = info.normal.fontSize;
              }
              break;
            case 'visible':
              if (info.normal.visible != null) {
                newItem.visible = info.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (info.normal.cursorPointer != null) {
                newItem.cursorPointer = info.normal.cursorPointer;
              }
              break;
            case 'width':
              if (info.normal.width != null) newItem.width = info.normal.width;
              break;
            case 'height':
              if (info.normal.height != null) newItem.height = info.normal.height;
              break;
          }
        }
        for (const attribute in info.variable) {
          const theme = info.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = info.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'iconColor':
              if ('color' in value) newItem.backgroundColor.data = value.value;
              break;
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'fontSize':
              if ('size' in value) {
                newItem.fontSize = value.value;
                newItem.width.data = value.value;
                newItem.height.data = value.value;
              }
              break;
            case 'icon':
              if ('text' in value) newItem.icon = value.value.content.default;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'width':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'height':
              if ('dimension' in value) newItem.height = value.value;
              break;
          }
        }
      }
    }
    if ('classes' in item && classesList != null) {
      for (const className in classesList) {
        const classInfo = classes[className];
        for (const attribute in classInfo.normal) {
          switch (attribute) {
            case 'backgroundColor':
              if (classInfo.normal.backgroundColor != null) {
                newItem.backgroundColor = classInfo.normal.backgroundColor;
              }
              break;
            case 'margins':
              if (classInfo.normal.margins != null) {
                newItem.margins = classInfo.normal.margins;
              }
              break;
            case 'fontSize':
              if (classInfo.normal.fontSize != null) {
                newItem.fontSize = classInfo.normal.fontSize;
              }
              break;
            case 'visible':
              if (classInfo.normal.visible != null) {
                newItem.visible = classInfo.normal.visible;
              }
              break;
            case 'cursorPointer':
              if (classInfo.normal.cursorPointer != null) {
                newItem.cursorPointer = classInfo.normal.cursorPointer;
              }
              break;
            case 'width':
              if (classInfo.normal.width != null) newItem.width = classInfo.normal.width;
              break;
            case 'height':
              if (classInfo.normal.height != null) newItem.height = classInfo.normal.height;
              break;
          }
        }
        for (const attribute in classInfo.variable) {
          const theme = classInfo.variable[attribute].includes('_') ? 'Responsiveness' : 'Default';
          const variable = classInfo.variable[attribute];
          let value = variables.model.referenceValues[theme][variable];
          if (deviceValues.Default[variable] != null) value = deviceValues.Default[variable];
          switch (attribute) {
            case 'iconColor':
              if ('color' in value) newItem.backgroundColor.data = value.value;
              break;
            case 'margin-left':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-right':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-top':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'margin-bottom':
              if ('size' in value) newItem.margins.left = value.value;
              break;
            case 'fontSize':
              if ('size' in value) {
                newItem.fontSize = value.value;
                newItem.width.data = value.value;
                newItem.height.data = value.value;
              }
              break;
            case 'icon':
              if ('text' in value) newItem.icon = value.value.content.default;
              break;
            case 'visible':
              if ('boolean' in value && value.kind === 'Visibility') {
                newItem.visible = value.value;
              }
              break;
            case 'width':
              if ('dimension' in value) newItem.width = value.value;
              break;
            case 'height':
              if ('dimension' in value) newItem.height = value.value;
              break;
          }
        }
      }
    }
    return newItem;
  }
);

export const getItemNames = createSelector(
  (state: StateType) => state.items.items.byId,
  (state: StateType) => getTree(state),
  (state: StateType, screen: string) => screen,
  (items, tree, screen) => {
    if (screen === '') return [];
    const subNodes = tree.getAllNodeSubnodes(screen);
    const names = [];
    for (const node of subNodes) names.push({ name: items[node].name, id: node });
    return names;
  }
);

export const {
  setInitialItems,
  setWholeItems,
  changeHierarchy,
  updateComponentParams,
  createComponent,
  insertComponent,
  deleteComponent,
  selectComponentPreview,
  changeVariableValue,
  changeVariableName,
  addVariableToItem,
  removeVariable,
  createVariable,
  changeComponentDescription,
  createItem,
  deleteItem,
  cutItem,
  copyItem,
  copyItemStyles,
  pasteItem,
  pasteItemStyles,
  duplicateItem,
  changeTag,
  createTag,
  changeClass,
  changeVariant,
  createVariant,
  changeBackground,
  changePosition,
  changeZIndex,
  changePositionLocated,
  changeFlexWrap,
  changeExternalFile,
  changeExternalFunction,
  changeBorderWidth,
  changeBorderLocationWidth,
  changeBorderStyle,
  changeBorderColor,
  changeBorderLocationColor,
  changeBorderRadiusKind,
  changeBorderKind,
  changeBorderRadiusLocationData,
  changeBorderRadiusData,
  changeShadowColor,
  changeShadowBlur,
  changeShadowOffsetX,
  changeShadowOffsetY,
  changeIconIcon,
  changeImageViewSrc,
  changeTextAlignment,
  changeTextWeight,
  changeFontFamily,
  changeFontSize,
  changeTextStyle,
  changeTextLeading,
  changeTextSpacing,
  changeTextContent,
  changeScreenIsMain,
  changeScreenUrl,
  changeScreenTitle,
  changeScreenDescription,
  changeScreenKeywords,
  changeScreenAuthor,
  changeScreenCanonical,
  changeScreenRobots,
  changeWidth,
  changeHeight,
  changeMargin,
  changeBackgroundColor,
  changeName,
  changeCursorPointer,
  changeVisible,
  changeHref,
  changeAnimation,
  changeAnimationWhenVisible,
  changePadding,
  changeVerticalAlignment,
  changeHorizontalAlingment,
  changeOrientation,
  createClassFromItem,
  changeScrollable,
  setAspectRatio,
  toggleLockPaddings,
  toggleLockMargins,
  changeSelectedDevice,
} = BaseItemsSlice.actions;

export const ItemsReducer = BaseItemsSlice.reducer;
