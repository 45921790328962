import { Icon } from '../icon';
import React from 'react';

import { type ExtractedComponent } from '@globalUtils/extractor';

import styles from './styles.module.css';

const propsAreEqual = (a: ComponentItemProps, b: ComponentItemProps): boolean => {
  if (a.name !== b.name) return false;
  if (a.id !== b.id) return false;
  if (a.selected !== b.selected) return false;
  if (a.notifyClick !== b.notifyClick) return false;
  return true;
};

export interface ComponentItemProps {
  name: string;
  id: string;
  notifyClick: (elem: ExtractedComponent) => void;
  selected?: boolean;
  elem: ExtractedComponent;
}

export const ComponentItem = React.memo(
  ({ name, id, notifyClick, selected = false, elem }: ComponentItemProps): React.JSX.Element => {
    return (
      <div
        onClick={() => {
          notifyClick(elem);
        }}
        className={styles.container + (selected ? ' ' + styles.selected : '')}
        title={name}
      >
        <Icon icon="deployed_code" color="var(--primary-color)" />
        <div className={styles.componentName}>{name}</div>
      </div>
    );
  },
  propsAreEqual
);
ComponentItem.displayName = 'ComponentItem';
