/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable max-len */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BackgroundGradient } from '@components/backgroundGradient';
import { Icon } from '@components/icon';
import { IconButton } from '@components/iconButton';

import { getCurrentTheme } from '@reducers/themes';

import { trackEvent } from '@globalUtils/metrics';

import styles from './styles.module.css';

export const WithServerOn = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const { t } = useTranslation();

  const theme = useSelector(getCurrentTheme);

  const [copied, setCopied] = useState<boolean>(false);

  const [serverIsOn, setServerIsOn] = useState<boolean>(false);
  const [serverIsOnFetched, setServerIsOnFetched] = useState<boolean>(false);
  const [installingPending, setInstallingPending] = useState<boolean>(false);

  const [piecesFetched, setPiecesFetched] = useState<Record<string, boolean>>({});
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [devtoolsAreOn, setDevtoolsAreOn] = useState<boolean>(false);
  const [devtoolsLaunchClicked, setDevtoolsLaunchClicked] = useState<boolean>(false);
  const [VSIsInstalled, setVSIsInstalled] = useState<boolean>(false);
  const [VSInstallClicked, setVSInstallClicked] = useState<boolean>(false);
  const [VSIsOn, setVSIsOn] = useState<boolean>(false);
  const [VSLaunchClicked, setVSLaunchClicked] = useState<boolean>(false);

  const firstTime = useRef<boolean>(true);

  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to Server Page');
  }, []);

  const checkServerStatus = (): void => {
    fetch('http://localhost:4035/info')
      .then(() => {
        setServerIsOn(true);
        setServerIsOnFetched(true);
      })
      .catch((e) => {
        setServerIsOn(false);
        setServerIsOnFetched(true);
        setPiecesFetched({});
        setDataFetched(false);
        setDevtoolsAreOn(false);
        setVSIsInstalled(false);
        setVSIsOn(false);
      });
  };

  const checkDevtools = (): void => {
    fetch('http://localhost:4035/devtools')
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 'ok') setDevtoolsAreOn(true);
        else setDevtoolsAreOn(false);
        setDevtoolsLaunchClicked(false);
        setPiecesFetched((prev) => {
          const copy = { ...prev };
          copy.devtools = true;
          return copy;
        });
      })
      .catch((e) => {});
  };

  const launchDevtools = (): void => {
    setDevtoolsLaunchClicked(true);
    fetch('http://localhost:4035/devtools', {
      method: 'POST',
    })
      .then(() => {})
      .catch((e) => {});
  };

  const installVS = (): void => {
    setVSInstallClicked(true);
    fetch('http://localhost:4035/fs/install', {
      method: 'POST',
    })
      .then(() => {})
      .catch((e) => {});
  };

  const launchVS = (): void => {
    setVSLaunchClicked(true);
    fetch('http://localhost:4035/fs/run', {
      method: 'POST',
    })
      .then(() => {})
      .catch((e) => {});
  };

  const checkVS = (): void => {
    fetch('http://localhost:4035/fs')
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 'pending') {
          setInstallingPending(true);
          setVSIsOn(false);
          setPiecesFetched((prev) => {
            const copy = { ...prev };
            copy.vsInstalled = false;
            copy.vsRunning = false;
            return copy;
          });
        } else if (data.status === 'installed') {
          setInstallingPending(false);
          setVSIsInstalled(true);
          setVSInstallClicked(false);
          setVSIsOn(false);
          setVSLaunchClicked(false);
          setPiecesFetched((prev) => {
            const copy = { ...prev };
            copy.vsInstalled = true;
            copy.vsRunning = false;
            return copy;
          });
        } else if (data.status === 'open') {
          setInstallingPending(false);
          setVSIsInstalled(true);
          setVSInstallClicked(false);
          setVSIsOn(true);
          setVSLaunchClicked(false);
          setPiecesFetched((prev) => {
            const copy = { ...prev };
            copy.vsInstalled = true;
            copy.vsRunning = true;
            return copy;
          });
        } else {
          setInstallingPending(false);
          setVSIsInstalled(false);
          setVSIsOn(false);
          setVSInstallClicked(false);
          setVSLaunchClicked(false);
          setPiecesFetched((prev) => {
            const copy = { ...prev };
            copy.vsInstalled = false;
            copy.vsRunning = false;
            return copy;
          });
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    checkServerStatus();
    const id = setInterval(checkServerStatus, 2000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (!serverIsOn) {
      return;
    }
    const idDevtools = setInterval(checkDevtools, 2000);
    const idVS = setInterval(checkVS, 2000);
    return () => {
      clearInterval(idDevtools);
      clearInterval(idVS);
    };
  }, [serverIsOn]);

  useEffect(() => {
    if (Object.keys(piecesFetched).length !== 3) return;
    setDataFetched(true);
  }, [piecesFetched]);

  const copyCommand = (): void => {
    void navigator.clipboard.writeText('npx --yes @agua-dev/agua-server');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 150);
  };

  const bgDot = theme === 'dark' ? 'bg-dot-white/[0.2]' : 'bg-dot-black/[0.2]';

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'var(--background-color-2)' }}>
      {(!serverIsOn || !devtoolsAreOn || !VSIsOn || !VSIsInstalled) && (
        <>
          <div className={'h-full w-full relative flex items-center justify-center ' + bgDot}>
            <div
              style={{ backgroundColor: theme === 'dark' ? 'black' : 'white' }}
              className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"
            ></div>
            <BackgroundGradient className="w-auto h-auto">
              <div
                style={{
                  width: '400px',
                  height: 'fit-content',
                  padding: 'var(--padding-l)',
                  backgroundColor: 'var(--background-color-2)',
                  borderRadius: 'var(--border-radius-xxl)',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 'calc(var(--padding-l) + var(--padding-m))',
                  }}
                >
                  <img
                    style={{ height: '50px' }}
                    src={theme === 'dark' ? '/agua_white.png' : '/agua.png'}
                  ></img>
                </div>
                {!serverIsOnFetched && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'var(--padding-xl) 0px',
                    }}
                  >
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  </div>
                )}
                {serverIsOnFetched && (
                  <>
                    {!serverIsOn && (
                      <>
                        <div style={{ margin: 'var(--padding-m) 0px' }}>{t('start-using-devtools')}</div>
                        <div
                          className={styles.commandShell}
                          style={{
                            width: '100%',
                            position: 'relative',
                            fontFamily: 'var(--font-family-code)',
                            display: 'flex',
                            backgroundColor: 'var(--bar-background-color)',
                            padding: 'var(--padding-m)',
                            border: '1px solid var(--border-color)',
                            borderRadius: 'var(--border-radius-m)',
                          }}
                        >
                          <div style={{ color: 'var(--api-put-color)' }}>$&nbsp;</div>
                          <p style={{ color: 'var(--title-color)', width: 'fit-content' }}>
                            npx --yes @agua-dev/agua-server
                          </p>
                          <div
                            onClick={copyCommand}
                            className={styles.hoveredItem}
                            style={{
                              position: 'absolute',
                              right: 'var(--padding-s)',
                              top: '50%',
                              zIndex: 2,
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                              padding: 'var(--padding-s)',
                              backgroundColor: 'var(--input-field-background-color)',
                              borderRadius: 'var(--border-radius-s)',
                              border:
                                '1px solid ' + (copied ? 'var(--primary-color)' : 'var(--border-color)'),
                            }}
                          >
                            <Icon
                              icon="content_copy"
                              color={copied ? 'var(--primary-color)' : 'var(--title-color)'}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {serverIsOn && !dataFetched && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'var(--padding-xl) 0px',
                        }}
                      >
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                      </div>
                    )}
                    {serverIsOn && dataFetched && (
                      <>
                        <div style={{ marginTop: 'var(--padding-m)' }}></div>
                        <div className={styles.callToActionContainer}>
                          <div>{t('react-devtools-label')}</div>
                          {!devtoolsAreOn && (
                            <IconButton
                              paddingHorizontal="0px"
                              border=""
                              alignment="space-around"
                              width="100px"
                              onClick={launchDevtools}
                              icon={'play_arrow'}
                              hasIcon={false}
                              color="var(--title-color)"
                              disabled={devtoolsLaunchClicked}
                              variant="success"
                              iconSize="xlarge"
                            >
                              {devtoolsLaunchClicked
                                ? t('react-devtools-button-clicked')
                                : t('react-devtools-button')}
                            </IconButton>
                          )}
                          {devtoolsAreOn && (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                              <Icon icon="check" iconSize="xlarge" color="var(--success-color-3)" />
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: 'var(--padding-m)' }}></div>
                        <div className={styles.callToActionContainer}>
                          <div>{t('vs-installed-label')}</div>
                          {!VSIsInstalled && (
                            <IconButton
                              paddingHorizontal="0px"
                              border=""
                              alignment="space-around"
                              width="100px"
                              onClick={installVS}
                              icon={'play_arrow'}
                              hasIcon={false}
                              color="var(--title-color)"
                              disabled={VSInstallClicked}
                              variant="success"
                              iconSize="xlarge"
                            >
                              {installingPending
                                ? t('vs-install-button-clicked-pending')
                                : VSInstallClicked
                                  ? t('vs-install-button-clicked')
                                  : t('vs-install-button')}
                            </IconButton>
                          )}
                          {VSIsInstalled && (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                              <Icon icon="check" iconSize="xlarge" color="var(--success-color-3)" />
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: 'var(--padding-m)' }}></div>
                        <div className={styles.callToActionContainer}>
                          <div>{t('vs-launch-label')}</div>
                          {!VSIsOn && (
                            <IconButton
                              paddingHorizontal="0px"
                              border=""
                              alignment="space-around"
                              width="100px"
                              onClick={launchVS}
                              icon={'play_arrow'}
                              hasIcon={false}
                              color="var(--title-color)"
                              disabled={VSLaunchClicked || !VSIsInstalled}
                              variant="success"
                              iconSize="xlarge"
                            >
                              {VSLaunchClicked ? t('vs-launch-button-clicked') : t('vs-launch-button')}
                            </IconButton>
                          )}
                          {VSIsOn && (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                              <Icon icon="check" iconSize="xlarge" color="var(--success-color-3)" />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </BackgroundGradient>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 'var(--padding-s)',
              left: 'var(--padding-s)',
              fontSize: 'var(--font-size-body)',
              color: 'var(--border-color)',
            }}
          >{`${t('app-version')} ${APP_VERSION}`}</div>
        </>
      )}
      {serverIsOn && devtoolsAreOn && VSIsInstalled && VSIsOn && children}
    </div>
  );
};
