export interface TypeDimensionDictionary {
  padding: string;
  margin: string;
  vh: string;
  vw: string;
  '%': string;
  px: string;
  wrap: string;
  fill: string;
  fillOtherOrientation: string;
  height: string;
  width: string;
  heightImage: string;
  widthImage: string;
}

export const ReactDimensionDictionary: TypeDimensionDictionary = {
  padding: 'box-sizing: border-box; padding:',
  margin: 'margin:',
  vh: 'vh',
  vw: 'vw',
  '%': '%',
  px: 'px',
  wrap: 'fit-content;',
  fill: 'flex: 1 1 50%;',
  fillOtherOrientation: 'align-self: stretch;',
  height: 'height: ',
  width: 'width: ',
  heightImage: 'height: ',
  widthImage: 'width: ',
};
