import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  ANIMATE,
  BRACKET_CLASSNAMES,
  BRACKET_CUSTOM_STYLES,
  CLASSNAMES_VALUE,
  CLOSE_BRACKET,
  CLOSE_STYLE_SPACE,
  EXTERNAL_CLASSNAME,
  GROUP_CLASSNAMES,
  LAYER,
  LAYER_BORDER,
  MINUS,
  OPEN_PARENTESIS_STRING,
  SELECT_ITEM,
  STYLE_NO_SPACE,
  STYLE_SPACE
} from '../../../constants/constants';
import { type ReactInfo } from '@globalTypes/conversor';

export function referenceGroups (item: TypeItemProperties, neededInfo: ReactInfo): string {
  let stringClasses = '';
  if ('classes' in item) {
    const classes = item.classes;
    if (classes != null) {
      for (const iClass of classes) {
        if (neededInfo.referenceGroupsList[iClass] != null) {
          stringClasses += ' ' + iClass;
        }
      }
    }
  }
  return stringClasses;
}

export function evaluateClassName (classNameInfo: [string, boolean], onlyClass: boolean): string {
  let classNames = '';
  const animateClass = classNameInfo[0].startsWith(ANIMATE);
  const groupClasses = classNameInfo[0].startsWith(GROUP_CLASSNAMES);
  const stringTypeClass = animateClass || groupClasses;
  const notStringTypeClass = !animateClass && !groupClasses;
  if (onlyClass && stringTypeClass) classNames += '"';
  if (!onlyClass && notStringTypeClass) classNames += EXTERNAL_CLASSNAME;
  if (classNameInfo[1]) {
    if (classNameInfo[0].includes(MINUS)) {
      classNames += STYLE_SPACE + classNameInfo[0] + CLOSE_STYLE_SPACE;
    } else classNames += STYLE_NO_SPACE + classNameInfo[0];
  } else {
    classNames += classNameInfo[0].replace(GROUP_CLASSNAMES, '');
  }
  if (onlyClass && stringTypeClass) classNames += '"';
  if (!onlyClass && notStringTypeClass) classNames += CLOSE_BRACKET;
  return classNames;
}

export function handleClassnames (item: TypeItemProperties, neededInfo: ReactInfo): string {
  let classNames = '';
  const referenceGroup = referenceGroups(item, neededInfo);
  if (neededInfo.objectClassnames[item.id].length === 0) {
    if (referenceGroup !== '') {
      return CLASSNAMES_VALUE + '`' + referenceGroup + '`' + CLOSE_BRACKET;
    } else return '';
  }
  classNames += CLASSNAMES_VALUE;
  if (neededInfo.objectClassnames[item.id].length === 1 && referenceGroup === '') {
    const actualClassname = neededInfo.objectClassnames[item.id][0];
    classNames += evaluateClassName(actualClassname, true);
  } else {
    classNames += '`';
    for (let i = 0; i < neededInfo.objectClassnames[item.id].length; i++) {
      const actualClassname = neededInfo.objectClassnames[item.id][i];
      classNames += evaluateClassName(actualClassname, false);
      if (i < neededInfo.objectClassnames[item.id].length - 1) classNames += ' ';
    }
    if (referenceGroup !== '') classNames += referenceGroup;
    classNames += '`';
  }
  classNames += CLOSE_BRACKET;
  if (referenceGroup !== '') classNames += ' custom-agua-class="true" ';
  return classNames;
}

export function handleCanvasModeClassNames (item: TypeItemProperties, neededInfo: ReactInfo): void {
  if (neededInfo.canvas) {
    const mVal = OPEN_PARENTESIS_STRING + item.id + SELECT_ITEM;
    neededInfo.objectClassnames[item.id].push([mVal, false]);
    if (item.commonKind === LAYER) {
      neededInfo.objectClassnames[item.id].push([LAYER_BORDER, false]);
    }
  }
}

export function handleItemObjectClassNames (item: TypeItemProperties, neededInfo: ReactInfo): void {
  if ('classNames' in item && item.classNames != null) {
    for (const classname of item.classNames) {
      neededInfo.objectClassnames[item.id].push([GROUP_CLASSNAMES + classname, false]);
      if (neededInfo.groupListClassnames[classname] != null) {
        neededInfo.objectClassnames[item.id].push(
          [GROUP_CLASSNAMES + neededInfo.groupListClassnames[classname], false]
        );
      }
    }
  }
}

export function handleItemClassNames (item: TypeItemProperties, component: string,
  neededInfo: ReactInfo): string {
  if ('classname' in item && item.classname != null) {
    const classNames = item.classname.split(' ');
    const classes = '';
    for (const classname of classNames) {
      if (classname.trim() !== '') neededInfo.objectClassnames[item.id].push([classname, true]);
    }
    component = component.replace(BRACKET_CUSTOM_STYLES, classes);
  } else component = component.replace(BRACKET_CUSTOM_STYLES, '');
  handleCanvasModeClassNames(item, neededInfo);
  handleItemObjectClassNames(item, neededInfo);
  return component.replace(BRACKET_CLASSNAMES, handleClassnames(item, neededInfo));
}
