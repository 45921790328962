import {
  ALIGN_ITEMS,
  AXIS,
  CLOSING_PARENTESIS,
  JUSTIFY_CONTENT,
  MHORIZONTAL,
  MVERTICAL,
  ORIENTATION,
  SEMICOLON_LINE_JUMP,
  VAR,
  VAR_DEFINITION,
} from '../../../../constants/constants';
import {
  type TypeReactAttribute,
  type ReactInfo,
  type TypeRefItemInfo,
  type TypeVerifications
} from '@globalTypes/conversor';
import { referenceValue } from '../../reactHTMLMethods/reactItemUtils';

export function alignOrientationNoVariable (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  ver: TypeVerifications, variableName: string, direction: string): void {
  if (neededInfo.item == null) return;
  if (ver.groupVerification) {
    const varName = neededInfo.item.nameChanged != null ? neededInfo.item.name : neededInfo.item.id;
    refItemInfo.referenceString += VAR + direction + AXIS + varName + VAR_DEFINITION +
     variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  if (ver.noAddVerification) refItemInfo.referenceList.push(variableName);
}

export function alignOrientationVariable (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  groupVerification: boolean, noAddVerification: boolean, variableName: string, direction: string): void {
  if (neededInfo.item == null) return;
  const orientation = 'orientation' in neededInfo.item ? neededInfo.item.orientation : '';
  const verValue = orientation === MHORIZONTAL ? ALIGN_ITEMS : JUSTIFY_CONTENT;
  const horValue = orientation === MHORIZONTAL ? JUSTIFY_CONTENT : ALIGN_ITEMS;
  refItemInfo.location = direction === MVERTICAL ? verValue : horValue;
  if (groupVerification) {
    refItemInfo.referenceString += refItemInfo.location + VAR_DEFINITION + variableName +
      CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  if (noAddVerification) {
    refItemInfo.referenceList.push(variableName);
  }
}

export function alignReferencesItem (neededInfo: ReactInfo, referenceItem: TypeReactAttribute,
  refItemInfo: TypeRefItemInfo, variableName: string, verify: TypeVerifications): void {
  refItemInfo.location = '';
  if (neededInfo.item == null) return;
  const isOrientationVariable = referenceValue(ORIENTATION, neededInfo.item, false);
  const direction = referenceItem[0].includes(MVERTICAL) ? MVERTICAL : MHORIZONTAL;
  if (isOrientationVariable !== false) {
    alignOrientationNoVariable(neededInfo, refItemInfo, verify, variableName, direction);
  } else {
    alignOrientationVariable(neededInfo, refItemInfo, verify.groupVerification, verify.noAddVerification,
      variableName, direction);
  }
}
