import React from 'react';

export interface ITab {
  value: string;
  label: string;
}
export interface TabProps extends ITab {
  selected: boolean;
  notifyClick: (value: string) => any;
}
export const Tab = ({ selected, label, value, notifyClick }: TabProps): React.JSX.Element => {
  return (
    <div
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        padding: 'var(--padding-s)',
        color: 'var(--title-color)',
        fontSize: 'var(--font-size-body)',
        fontWeight: 500,
        backgroundColor: selected ? 'var(--background-color-2)' : 'transparent',
        borderRadius: 'var(--border-radius-s)',
        flex: '1 1 50%',
      }}
      onClick={() => notifyClick(value)}
    >
      {label}
    </div>
  );
};
