import React from 'react';

import styles from './styles.module.css';

export const MainTitle = ({
  children,
  weight = 200,
}: {
  children: string | React.JSX.Element;
  weight?: number;
}): React.JSX.Element => {
  return (
    <div className={styles.title} style={{ fontWeight: weight }}>
      {children}
    </div>
  );
};
