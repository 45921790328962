/* eslint-disable max-lines-per-function */

/* eslint-disable max-lines */
import { type ItemsType } from '..';
import { type PayloadAction } from '@reduxjs/toolkit';

import {
  AnimationType,
  AnimationWhenVisibleType,
  AspectRatio,
  BackgroundColorType,
  type BorderType,
  CursorPointerType,
  DirectionsType,
  HeightType,
  HorizontalAlignmentType,
  HrefType,
  MarginsType,
  NameType,
  OrientationType,
  PaddingsType,
  ScrollableType,
  type SizeValueType,
  VerticalAlignmentType,
  VisibleType,
  WidthType,
} from '@globalTypes/itemProperties';
import { type GroupClassesType, type ReferencesGroupType } from '@globalTypes/itemProperties/classesType';

import { checkResponsiveCase, getItemReferences } from './itemUtils';

export const toggleLockPaddings = (state: ItemsType, action: PayloadAction<string>): void => {
  const id = action.payload;
  const item = state.items.byId[id];
  const prevValue: boolean = item.paddingsLocked ?? false;
  item.paddingsLocked = !prevValue;
};

export const toggleLockMargins = (state: ItemsType, action: PayloadAction<string>): void => {
  const id = action.payload;
  const item = state.items.byId[id];
  const prevValue: boolean = item.marginsLocked ?? false;
  item.marginsLocked = !prevValue;
};

interface WidthPayload {
  [WidthType.WidthProperty]: WidthType.WidthType;
  id: string;
}
export const changeWidth = (state: ItemsType, action: PayloadAction<WidthPayload>): void => {
  const { id } = action.payload;
  const width = action.payload[WidthType.WidthProperty];
  const item = state.items.byId[id];
  if (WidthType.WidthProperty in item) {
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        item.state[item.currentVariant].normal.width = width;
      }
    } else {
      checkResponsiveCase(state, id, width, 'valuewidth', item[WidthType.WidthProperty]);
      item[WidthType.WidthProperty] = width;
    }
  }
};

interface HeightPayload {
  [HeightType.HeightProperty]: HeightType.HeightType;
  id: string;
}
export const changeHeight = (state: ItemsType, action: PayloadAction<HeightPayload>): void => {
  const { id } = action.payload;
  const height = action.payload[HeightType.HeightProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.height = height;
    }
  } else if (HeightType.HeightProperty in item) {
    checkResponsiveCase(state, id, height, 'valueheight', item[HeightType.HeightProperty]);
    item[HeightType.HeightProperty] = height;
  }
};

interface AspectRatioPayload {
  id: string;
  [AspectRatio.AspectRatioProperty]: AspectRatio.AspectRatioType;
}
export const setAspectRatio = (state: ItemsType, action: PayloadAction<AspectRatioPayload>): void => {
  const { id, aspectRatio } = action.payload;
  const item = state.items.byId[id];
  if (item.commonKind !== 'screen' && item.commonKind !== 'icon') {
    item[AspectRatio.AspectRatioProperty] = aspectRatio;
  }
};

interface MarginPayload {
  value: SizeValueType.SizeValueType;
  position: string;
  id: string;
}
export const changeMargin = (state: ItemsType, action: PayloadAction<MarginPayload>): void => {
  const { id, position, value } = action.payload;
  const item = state.items.byId[id];
  if (MarginsType.MarginsProperty in item) {
    let indexing;
    if (position === 'top') {
      indexing = DirectionsType.TopProperty;
    } else if (position === 'bottom') {
      indexing = DirectionsType.BottomProperty;
    } else if (position === 'left') {
      indexing = DirectionsType.LeftProperty;
    } else if (position === 'right') {
      indexing = DirectionsType.RightProperty;
    }
    if (indexing != null) {
      if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
        if ('state' in item) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          const itemVariant = item.state[item.currentVariant];
          if (itemVariant.normal.margins == null) itemVariant.normal.margins = item.margins;
          itemVariant.normal.margins[indexing] = value;
        }
      } else {
        checkResponsiveCase(
          state,
          id,
          value,
          'margin-' + position,
          item[MarginsType.MarginsProperty][indexing]
        );
        item[MarginsType.MarginsProperty][indexing] = value;
      }
    }
  }
};

interface BackgroundColorPayload extends BackgroundColorType.BackgroundColorType {
  id: string;
}
export const changeBackgroundColor = (
  state: ItemsType,
  action: PayloadAction<BackgroundColorPayload>
): void => {
  const { id, kind, data } = action.payload;
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.backgroundColor = { kind, data };
    }
  } else if (BackgroundColorType.BackgroundColorProperty in item) {
    checkResponsiveCase(
      state,
      id,
      { kind, data },
      'backgroundColor',
      item[BackgroundColorType.BackgroundColorProperty]
    );
    item[BackgroundColorType.BackgroundColorProperty] = { kind, data };
  }
};

function camelCase(name: NameType.NameType): string {
  const info = name.split(' ');
  let newName = '';
  for (const word of info) {
    newName += word.substring(0, 1).toUpperCase() + word.substring(1);
  }
  name = newName.replaceAll('_', '').replaceAll('-', '');
  name = name.replaceAll('@', '').replaceAll('&', '').replaceAll('$', '');
  name = name.replaceAll('  ', '').replaceAll('*', '').replaceAll('(', '');
  name = name.replaceAll('\t', '').replaceAll('\n', '').replaceAll(')', '');
  name = name.replaceAll('[', '').replaceAll(']', '').replaceAll('{', '');
  name = name.replaceAll('}', '').replaceAll("'", '').replaceAll('"', '');
  name = name.replaceAll('´', '').replaceAll('/', '').replaceAll('\\', '');
  name = name.replaceAll('¿', '').replaceAll('?', '').replaceAll('=', '');
  name = name.replaceAll('+', '').replaceAll('.', '').replaceAll(',', '');
  name = name.replaceAll(';', '').replaceAll(':', '').replaceAll('>', '');
  name = name.replaceAll('<', '').replaceAll('|', '').replaceAll('°', '');
  name = name.replaceAll('¬', '').replaceAll('¨', '').replaceAll('~', '');
  name = name.replaceAll('^', '').replaceAll('`', '').replaceAll('~', '');
  name = name.substring(0, 1).toLowerCase() + name.substring(1);
  return name;
}

function startsWithNumberName(name: string): boolean {
  const first = name.substring(0, 1);
  if (isNaN(parseInt(first))) return false;
  return true;
}

function duplicatedName(state: ItemsType, name: string): boolean {
  const ids = state.items.allIds;
  for (const id of ids) {
    const item = state.items.byId[id];
    if (item.name === name) return true;
  }
  return false;
}

interface NamePayload {
  [NameType.NameProperty]: NameType.NameType;
  id: string;
}
export const changeName = (state: ItemsType, action: PayloadAction<NamePayload>): void => {
  const { id } = action.payload;
  let name = action.payload[NameType.NameProperty];
  name = camelCase(name);
  if (startsWithNumberName(name)) {
    console.error('starts with number name');
    return;
  }
  if (name === '') {
    console.error('empty name');
    return;
  } else if (duplicatedName(state, name)) {
    console.error('duplicated name');
    return;
  }
  const item = state.items.byId[id];
  item.name = name;
};

interface CursorPointerPayload {
  [CursorPointerType.CursorPointerProperty]: CursorPointerType.CursorPointerType;
  id: string;
}
export const changeCursorPointer = (state: ItemsType, action: PayloadAction<CursorPointerPayload>): void => {
  const { id } = action.payload;
  const cursorPointer = action.payload[CursorPointerType.CursorPointerProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.cursorPointer = cursorPointer;
    }
  } else if (CursorPointerType.CursorPointerProperty in item) {
    checkResponsiveCase(
      state,
      id,
      cursorPointer,
      'cursorPointer',
      item[CursorPointerType.CursorPointerProperty]
    );
    item[CursorPointerType.CursorPointerProperty] = cursorPointer;
  }
};

interface VisiblePayload {
  [VisibleType.VisibleProperty]: VisibleType.VisibleType;
  id: string;
}
export const changeVisible = (state: ItemsType, action: PayloadAction<VisiblePayload>): void => {
  const { id } = action.payload;
  const visible = action.payload[VisibleType.VisibleProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.visible = visible;
    }
  } else if (VisibleType.VisibleProperty in item) {
    checkResponsiveCase(state, id, visible, 'visible', item[VisibleType.VisibleProperty]);
    item[VisibleType.VisibleProperty] = visible;
  }
};

interface HrefPayload {
  [HrefType.HrefProperty]: HrefType.HrefType;
  id: string;
}
export const changeHref = (state: ItemsType, action: PayloadAction<HrefPayload>): void => {
  const { id } = action.payload;
  const href = action.payload[HrefType.HrefProperty];
  const item = state.items.byId[id];
  if (HrefType.HrefProperty in item) {
    item[HrefType.HrefProperty] = href;
  }
};

interface AnimationPayload {
  [AnimationType.AnimationProperty]: AnimationType.AnimationType;
  id: string;
}
export const changeAnimation = (state: ItemsType, action: PayloadAction<AnimationPayload>): void => {
  const { id } = action.payload;
  const animation = action.payload[AnimationType.AnimationProperty];
  const item = state.items.byId[id];
  if (AnimationType.AnimationProperty in item) {
    item[AnimationType.AnimationProperty] = animation;
  }
};

interface AnimationWhenVisiblePayload {
  [AnimationWhenVisibleType.AnimationWhenVisibleProperty]: AnimationWhenVisibleType.AnimationWhenVisibleType;
  id: string;
}
export const changeAnimationWhenVisible = (
  state: ItemsType,
  action: PayloadAction<AnimationWhenVisiblePayload>
): void => {
  const { id } = action.payload;
  const animationWhenVisible = action.payload[AnimationWhenVisibleType.AnimationWhenVisibleProperty];
  const item = state.items.byId[id];
  if (AnimationWhenVisibleType.AnimationWhenVisibleProperty in item) {
    item[AnimationWhenVisibleType.AnimationWhenVisibleProperty] = animationWhenVisible;
  }
};

interface PaddingPayload {
  value: SizeValueType.SizeValueType;
  position: string;
  id: string;
}
export const changePadding = (state: ItemsType, action: PayloadAction<PaddingPayload>): void => {
  const { id, position, value } = action.payload;
  const item = state.items.byId[id];
  if (PaddingsType.PaddingsProperty in item) {
    let indexing;
    if (position === 'top') {
      indexing = DirectionsType.TopProperty;
    } else if (position === 'bottom') {
      indexing = DirectionsType.BottomProperty;
    } else if (position === 'left') {
      indexing = DirectionsType.LeftProperty;
    } else if (position === 'right') {
      indexing = DirectionsType.RightProperty;
    }
    if (indexing != null) {
      if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
        if ('state' in item) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          const itemVariant = item.state[item.currentVariant];
          if (itemVariant.normal.paddings == null) itemVariant.normal.paddings = item.paddings;
          itemVariant.normal.paddings[indexing] = value;
        }
      } else {
        checkResponsiveCase(
          state,
          id,
          value,
          'padding-' + position,
          item[PaddingsType.PaddingsProperty][indexing]
        );
        item[PaddingsType.PaddingsProperty][indexing] = value;
      }
    }
  }
};

interface VerticalAlignmentPayload {
  [VerticalAlignmentType.VerticalAlignmentProperty]: VerticalAlignmentType.VerticalAlignmentType;
  id: string;
}
export const changeVerticalAlignment = (
  state: ItemsType,
  action: PayloadAction<VerticalAlignmentPayload>
): void => {
  const { id } = action.payload;
  const alignment = action.payload[VerticalAlignmentType.VerticalAlignmentProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.verticalAlignment = alignment;
    }
  } else if (VerticalAlignmentType.VerticalAlignmentProperty in item) {
    checkResponsiveCase(
      state,
      id,
      alignment,
      'verticalAlignment',
      item[VerticalAlignmentType.VerticalAlignmentProperty]
    );
    item[VerticalAlignmentType.VerticalAlignmentProperty] = alignment;
  }
};

interface HorizontalAlignmentPayload {
  [HorizontalAlignmentType.HorizontalAlignmentProperty]: HorizontalAlignmentType.HorizontalAlignmentType;
  id: string;
}
export const changeHorizontalAlingment = (
  state: ItemsType,
  action: PayloadAction<HorizontalAlignmentPayload>
): void => {
  const { id } = action.payload;
  const alignment = action.payload[HorizontalAlignmentType.HorizontalAlignmentProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.horizontalAlignment = alignment;
    }
  } else if (HorizontalAlignmentType.HorizontalAlignmentProperty in item) {
    checkResponsiveCase(
      state,
      id,
      alignment,
      'horizontalAlignment',
      item[HorizontalAlignmentType.HorizontalAlignmentProperty]
    );
    item[HorizontalAlignmentType.HorizontalAlignmentProperty] = alignment;
  }
};

interface OrientationPayload {
  [OrientationType.OrientationProperty]: OrientationType.OrientationType;
  id: string;
}
export const changeOrientation = (state: ItemsType, action: PayloadAction<OrientationPayload>): void => {
  const { id } = action.payload;
  const orientation = action.payload[OrientationType.OrientationProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.orientation = orientation;
    }
  } else if (OrientationType.OrientationProperty in item) {
    checkResponsiveCase(state, id, orientation, 'orientation', item[OrientationType.OrientationProperty]);
    item[OrientationType.OrientationProperty] = orientation;
  }
};

interface ScrollablePayload {
  [ScrollableType.ScrollableProperty]: ScrollableType.ScrollableType;
  id: string;
}
export const changeScrollable = (state: ItemsType, action: PayloadAction<ScrollablePayload>): void => {
  const { id } = action.payload;
  const scrollable = action.payload[ScrollableType.ScrollableProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.scrollable = scrollable;
    }
  } else if (ScrollableType.ScrollableProperty in item) {
    checkResponsiveCase(state, id, scrollable, 'scrollable', item[ScrollableType.ScrollableProperty]);
    item[ScrollableType.ScrollableProperty] = scrollable;
  }
};

interface ClassPayload {
  name: string;
  properties: string[];
  id: string;
}
export const createClassFromItem = (state: ItemsType, action: PayloadAction<ClassPayload>): void => {
  const { id, properties, name } = action.payload;
  const item = state.items.byId[id];
  const newClass: GroupClassesType = { commonKind: item.commonKind };
  const variableList: Record<string, string> = {};
  const itemReferences = getItemReferences(state, id);
  const defaultDirections = {
    top: { value: 0, unit: 'px' },
    bottom: { value: 0, unit: 'px' },
    left: { value: 0, unit: 'px' },
    right: { value: 0, unit: 'px' },
  };
  const defaultBorderDirections = {
    top: { style: 'solid', color: 'ffffff', width: { value: 0, unit: 'px' } },
    bottom: { style: 'solid', color: 'ffffff', width: { value: 0, unit: 'px' } },
    left: { style: 'solid', color: 'ffffff', width: { value: 0, unit: 'px' } },
    right: { style: 'solid', color: 'ffffff', width: { value: 0, unit: 'px' } },
  };
  const defaultShadow = {
    offSetX: { value: 0, unit: 'px' },
    offSetY: { value: 0, unit: 'px' },
    blur: { value: 0, unit: 'px' },
    color: 'ffffff',
  };
  const defaultBorder: BorderType.BorderType = {
    kind: 'all',
    data: {
      style: 'solid',
      color: '000000',
      width: { value: 0, unit: 'px' },
      radius: {
        kind: 'all',
        data: { value: 0, unit: 'px' },
      },
    },
  };
  for (const property of properties) {
    const pProperty = property.includes('border') ? 'border' : property;
    switch (pProperty) {
      case 'backgroundColor':
        if (itemReferences.backgroundColor != null) {
          variableList.backgroundColor = itemReferences.backgroundColor;
        } else if ('backgroundColor' in item) newClass.backgroundColor = item.backgroundColor;
        break;
      case 'background':
        if (itemReferences.background != null) {
          variableList.background = itemReferences.background;
        } else if ('background' in item) newClass.background = item.background;
        break;
      case 'margin-top':
        if ('margins' in item) {
          if (itemReferences['margin-top'] != null) {
            variableList['margin-top'] = itemReferences['margin-top'];
          }
          if (newClass.margins == null) newClass.margins = defaultDirections;
          newClass.margins.top = item.margins.top;
        }
        break;
      case 'margin-bottom':
        if ('margins' in item) {
          if (itemReferences['margin-bottom'] != null) {
            variableList['margin-bottom'] = itemReferences['margin-bottom'];
          }
          if (newClass.margins == null) newClass.margins = defaultDirections;
          newClass.margins.bottom = item.margins.bottom;
        }
        break;
      case 'margin-left':
        if ('margins' in item) {
          if (itemReferences['margin-left'] != null) {
            variableList['margin-left'] = itemReferences['margin-left'];
          }
          if (newClass.margins == null) newClass.margins = defaultDirections;
          newClass.margins.left = item.margins.left;
        }
        break;
      case 'margin-right':
        if ('margins' in item) {
          if (itemReferences['margin-right'] != null) {
            variableList['margin-right'] = itemReferences['margin-right'];
          }
          if (newClass.margins == null) newClass.margins = defaultDirections;
          newClass.margins.right = item.margins.right;
        }
        break;
      case 'margins':
        if ('margins' in item) {
          if (itemReferences['margin-top'] != null) {
            variableList['margin-top'] = itemReferences['margin-top'];
          } else if (itemReferences['margin-bottom'] != null) {
            variableList['margin-bottom'] = itemReferences['margin-bottom'];
          } else if (itemReferences['margin-right'] != null) {
            variableList['margin-right'] = itemReferences['margin-right'];
          } else if (itemReferences['margin-left'] != null) {
            variableList['margin-left'] = itemReferences['margin-left'];
          }
          newClass.margins = item.margins;
        }
        break;
      case 'padding-top':
        if ('paddings' in item) {
          if (itemReferences['padding-top'] != null) {
            variableList['padding-top'] = itemReferences['padding-top'];
          }
          if (newClass.paddings == null) newClass.paddings = defaultDirections;
          newClass.paddings.top = item.paddings.top;
        }
        break;
      case 'padding-bottom':
        if ('paddings' in item) {
          if (itemReferences['padding-bottom'] != null) {
            variableList['padding-bottom'] = itemReferences['padding-bottom'];
          }
          if (newClass.paddings == null) newClass.paddings = defaultDirections;
          newClass.paddings.bottom = item.paddings.bottom;
        }
        break;
      case 'padding-left':
        if ('paddings' in item) {
          if (itemReferences['padding-left'] != null) {
            variableList['padding-left'] = itemReferences['padding-left'];
          }
          if (newClass.paddings == null) newClass.paddings = defaultDirections;
          newClass.paddings.left = item.paddings.left;
        }
        break;
      case 'padding-right':
        if ('paddings' in item) {
          if (itemReferences['padding-right'] != null) {
            variableList['padding-right'] = itemReferences['padding-right'];
          }
          if (newClass.paddings == null) newClass.paddings = defaultDirections;
          newClass.paddings.right = item.paddings.right;
        }
        break;
      case 'paddings':
        if ('paddings' in item) {
          if (itemReferences['padding-top'] != null) {
            variableList['padding-top'] = itemReferences['padding-top'];
          } else if (itemReferences['padding-bottom'] != null) {
            variableList['padding-bottom'] = itemReferences['padding-bottom'];
          } else if (itemReferences['padding-right'] != null) {
            variableList['padding-right'] = itemReferences['padding-right'];
          } else if (itemReferences['padding-left'] != null) {
            variableList['padding-left'] = itemReferences['padding-left'];
          }
          newClass.paddings = item.paddings;
        }
        break;
      case 'textAlignment':
        if (itemReferences.textAlignment != null) {
          variableList.textAlignment = itemReferences.textAlignment;
        } else if ('textAlignment' in item) newClass.textAlignment = item.textAlignment;
        break;
      case 'visible':
        if (itemReferences.visible != null) {
          variableList.visible = itemReferences.visible;
        } else if ('visible' in item) newClass.visible = item.visible;
        break;
      case 'cursorPointer':
        if ('cursorPointer' in item) newClass.cursorPointer = item.cursorPointer;
        break;
      case 'width':
        if (itemReferences.valuewidth != null) {
          variableList.valuewidth = itemReferences.valuewidth;
        } else if ('width' in item) newClass.width = item.width;
        break;
      case 'height':
        if (itemReferences.valueheight != null) {
          variableList.valueheight = itemReferences.valueheight;
        } else if ('height' in item) newClass.height = item.height;
        break;
      case 'verticalAlignment':
        if (itemReferences['{verticalAlignment}'] != null) {
          variableList['{verticalAlignment}'] = itemReferences['{verticalAlignment}'];
        } else if ('verticalAlignment' in item) newClass.verticalAlignment = item.verticalAlignment;
        break;
      case 'horizontalAlignment':
        if (itemReferences['{horizontalAlignment}'] != null) {
          variableList['{horizontalAlignment}'] = itemReferences['{horizontalAlignment}'];
        } else if ('horizontalAlignment' in item) newClass.horizontalAlignment = item.horizontalAlignment;
        break;
      case 'orientation':
        if (itemReferences.orientation != null) {
          variableList.orientation = itemReferences.orientation;
        } else if ('orientation' in item) newClass.orientation = item.orientation;
        break;
      case 'scrollable':
        if (itemReferences.scrollable != null) {
          variableList.scrollable = itemReferences.scrollable;
        } else if ('scrollable' in item) newClass.scrollable = item.scrollable;
        break;
      case 'position-top':
        if ('positions' in item) {
          if (itemReferences['position-top'] != null) {
            variableList['position-top'] = itemReferences['position-top'];
          }
          if (newClass.positions == null) newClass.positions = {};
          newClass.positions.top = item.positions.top;
        }
        break;
      case 'position-bottom':
        if ('positions' in item) {
          if (itemReferences['position-bottom'] != null) {
            variableList['position-bottom'] = itemReferences['position-bottom'];
          }
          if (newClass.positions == null) newClass.positions = {};
          newClass.positions.bottom = item.positions.bottom;
        }
        break;
      case 'position-left':
        if ('positions' in item) {
          if (itemReferences['position-left'] != null) {
            variableList['position-left'] = itemReferences['position-left'];
          }
          if (newClass.positions == null) newClass.positions = {};
          newClass.positions.left = item.positions.left;
        }
        break;
      case 'position-right':
        if ('positions' in item) {
          if (itemReferences['position-right'] != null) {
            variableList['position-right'] = itemReferences['position-right'];
          }
          if (newClass.positions == null) newClass.positions = {};
          newClass.positions.right = item.positions.right;
        }
        break;
      case 'positions':
        if ('positions' in item) {
          if (itemReferences['position-top'] != null) {
            variableList['position-top'] = itemReferences['position-top'];
          } else if (itemReferences['position-bottom'] != null) {
            variableList['position-bottom'] = itemReferences['position-bottom'];
          } else if (itemReferences['position-right'] != null) {
            variableList['position-right'] = itemReferences['position-right'];
          } else if (itemReferences['position-left'] != null) {
            variableList['position-left'] = itemReferences['position-left'];
          }
          newClass.positions = item.positions;
        }
        break;
      case 'cssPosition':
        if ('cssPosition' in item) newClass.cssPosition = item.cssPosition;
        break;
      case 'borderRadius':
        if ('border' in item) {
          if (itemReferences.radiusAbsolute != null) {
            variableList.radiusAbsolute = itemReferences.radiusAbsolute;
          }
          if (itemReferences['border-topLeft'] != null) {
            variableList['border-topLeft'] = itemReferences['border-topLeft'];
          }
          if (itemReferences['border-topRight'] != null) {
            variableList['border-topRight'] = itemReferences['border-topRight'];
          }
          if (itemReferences['border-bottomRight'] != null) {
            variableList['border-bottomRight'] = itemReferences['border-bottomRight'];
          }
          if (itemReferences['border-bottomLeft'] != null) {
            variableList['border-bottomLeft'] = itemReferences['border-bottomLeft'];
          }
          if (itemReferences.borderWidth != null) {
            variableList.borderWidth = itemReferences.borderWidth;
          }
          if (itemReferences['border-topwidth'] != null) {
            variableList['border-topwidth'] = itemReferences['border-topwidth'];
          }
          if (itemReferences['border-bottomwidth'] != null) {
            variableList['border-bottomwidth'] = itemReferences['border-bottomwidth'];
          }
          if (itemReferences['border-leftwidth'] != null) {
            variableList['border-leftwidth'] = itemReferences['border-leftwidth'];
          }
          if (itemReferences['border-bottomLeft'] != null) {
            variableList['border-rightwidth'] = itemReferences['border-rightwidth'];
          }
          if (itemReferences.borderColor != null) {
            variableList.borderColor = itemReferences.borderColor;
          }
          if (itemReferences['border-topcolor'] != null) {
            variableList['border-topcolor'] = itemReferences['border-topcolor'];
          }
          if (itemReferences['border-bottomcolor'] != null) {
            variableList['border-bottomcolor'] = itemReferences['border-bottomcolor'];
          }
          if (itemReferences['border-leftcolor'] != null) {
            variableList['border-leftcolor'] = itemReferences['border-leftcolor'];
          }
          if (itemReferences['border-bottomLeft'] != null) {
            variableList['border-rightcolor'] = itemReferences['border-rightcolor'];
          }
          if (newClass.border == null) newClass.border = defaultBorder;
          newClass.border.data.radius = item.border.data.radius;
        }
        break;
      case 'borderWidth':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if ('width' in item.border.data && 'width' in newClass.border.data) {
            newClass.border.data.width = item.border.data.width;
          }
        }
        break;
      case 'borderRadius-topLeft':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if ('topLeft' in item.border.data.radius.data && 'topLeft' in newClass.border.data.radius.data) {
            newClass.border.data.radius.kind = 'partial';
            newClass.border.data.radius.data.topLeft = item.border.data.radius.data.topLeft;
          }
        }
        break;
      case 'borderRadius-bottomLeft':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if (
            'bottomLeft' in item.border.data.radius.data &&
            'bottomLeft' in newClass.border.data.radius.data
          ) {
            newClass.border.data.radius.kind = 'partial';
            newClass.border.data.radius.data.bottomLeft = item.border.data.radius.data.bottomLeft;
          }
        }
        break;
      case 'borderRadius-topRight':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if ('topRight' in item.border.data.radius.data && 'topRight' in newClass.border.data.radius.data) {
            newClass.border.data.radius.kind = 'partial';
            newClass.border.data.radius.data.topRight = item.border.data.radius.data.topRight;
          }
        }
        break;
      case 'borderRadius-bottomRight':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if (
            'bottomRight' in item.border.data.radius.data &&
            'bottomRight' in newClass.border.data.radius.data
          ) {
            newClass.border.data.radius.kind = 'partial';
            newClass.border.data.radius.data.bottomRight = item.border.data.radius.data.bottomRight;
          }
        }
        break;
      case 'border-top':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if (!('direction' in newClass.border.data)) {
            newClass.border.data = {
              direction: defaultBorderDirections,
              radius: newClass.border.data.radius,
              style: defaultBorder.data.style,
            };
          }
          if ('direction' in item.border.data && 'direction' in newClass.border.data) {
            newClass.border.kind = 'partial';
            newClass.border.data.direction.top = item.border.data.direction.top;
          }
        }
        break;
      case 'border-bottom':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if (!('direction' in newClass.border.data)) {
            newClass.border.data = {
              direction: defaultBorderDirections,
              radius: newClass.border.data.radius,
              style: defaultBorder.data.style,
            };
          }
          if ('direction' in item.border.data && 'direction' in newClass.border.data) {
            newClass.border.kind = 'partial';
            newClass.border.data.direction.bottom = item.border.data.direction.bottom;
          }
        }
        break;
      case 'border-left':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if (!('direction' in newClass.border.data)) {
            newClass.border.data = {
              direction: defaultBorderDirections,
              radius: newClass.border.data.radius,
              style: defaultBorder.data.style,
            };
          }
          if ('direction' in item.border.data && 'direction' in newClass.border.data) {
            newClass.border.kind = 'partial';
            newClass.border.data.direction.left = item.border.data.direction.left;
          }
        }
        break;
      case 'border-right':
        if ('border' in item) {
          if (newClass.border == null) newClass.border = defaultBorder;
          if (!('direction' in newClass.border.data)) {
            newClass.border.data = {
              direction: defaultBorderDirections,
              radius: newClass.border.data.radius,
              style: defaultBorder.data.style,
            };
          }
          if ('direction' in item.border.data && 'direction' in newClass.border.data) {
            newClass.border.kind = 'partial';
            newClass.border.data.direction.right = item.border.data.direction.right;
          }
        }
        break;
      case 'border':
        if ('border' in item) {
          if (itemReferences.radiusAbsolute != null) {
            variableList.radiusAbsolute = itemReferences.radiusAbsolute;
          }
          newClass.border = item.border;
        }
        break;
      case 'shadowOffsetX':
        if ('shadow' in item) {
          if (itemReferences.shadowOffsetX != null) {
            variableList.shadowOffsetX = itemReferences.shadowOffsetX;
          }
          if (newClass.shadow == null) newClass.shadow = defaultShadow;
          newClass.shadow.offSetX = item.shadow.offSetX;
        }
        break;
      case 'shadowOffsetY':
        if ('shadow' in item) {
          if (itemReferences.shadowOffsetY != null) {
            variableList.shadowOffsetY = itemReferences.shadowOffsetY;
          }
          if (newClass.shadow == null) newClass.shadow = defaultShadow;
          newClass.shadow.offSetY = item.shadow.offSetY;
        }
        break;
      case 'shadowBlur':
        if ('shadow' in item) {
          if (itemReferences.shadowBlur != null) {
            variableList.shadowBlur = itemReferences.shadowBlur;
          }
          if (newClass.shadow == null) newClass.shadow = defaultShadow;
          newClass.shadow.blur = item.shadow.blur;
        }
        break;
      case 'shadowColor':
        if ('shadow' in item) {
          if (itemReferences.shadowColor != null) {
            variableList.shadowColor = itemReferences.shadowColor;
          }
          if (newClass.shadow == null) newClass.shadow = defaultShadow;
          newClass.shadow.color = item.shadow.color;
        }
        break;
      case 'shadow':
        if ('shadow' in item) {
          if (itemReferences.shadowOffsetX != null) {
            variableList.shadowOffsetX = itemReferences.shadowOffsetX;
          } else if (itemReferences.shadowOffsetY != null) {
            variableList.shadowOffsetY = itemReferences.shadowOffsetY;
          } else if (itemReferences.shadowBlur != null) {
            variableList.shadowBlur = itemReferences.shadowBlur;
          } else if (itemReferences.shadowColor != null) {
            variableList.shadowColor = itemReferences.shadowColor;
          }
          newClass.shadow = item.shadow;
        }
        break;
      case 'fontFamily':
        if (itemReferences.fontFamily != null) {
          variableList.fontFamily = itemReferences.fontFamily;
        } else if ('fontFamily' in item) newClass.fontFamily = item.fontFamily;
        break;
      case 'fontSize':
        if (itemReferences.fontSize != null) {
          variableList.fontSize = itemReferences.fontSize;
        } else if ('fontSize' in item) newClass.fontSize = item.fontSize;
        break;
      case 'leading':
        if ('leading' in item) newClass.leading = item.leading;
        break;
      case 'weight':
        if ('weight' in item) newClass.weight = item.weight;
        break;
      case 'spacing':
        if ('spacing' in item) newClass.spacing = item.spacing;
        break;
      case 'style':
        if ('style' in item) newClass.style = item.style;
        break;
      case 'zindex':
        if ('zindex' in item) newClass.zindex = item.zindex;
        break;
      default:
        break;
    }
  }
  const createdClass: ReferencesGroupType = {
    normal: newClass,
    variable: variableList,
  };
  state.classes[name] = createdClass;
};
