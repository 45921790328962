/* eslint-disable max-len */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BackgroundBeams } from '@components/backgroundBeams';
import { World } from '@components/globe';

import { setCurrentUserID, setUserData } from '@reducers/editor';

import { trackEvent } from '@globalUtils/metrics';

import { globeConfig, sampleArcs } from './data';
import styles from './styles.module.css';
import { UserForm } from './userForm';

const provider = new GoogleAuthProvider();

export const WithAuth = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const firstTime = useRef<boolean>(true);

  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const [loadedAuth, setLoadedAuth] = useState<boolean>(false);
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [firstSignUp, setFirstSignUp] = useState<boolean>(false);
  const [gotUserData, setGotUserData] = useState<boolean>(false);
  const [uid, setUid] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const receiveDataFromForm = async (data: any): Promise<void> => {
    setFormIsLoading(true);
    try {
      const db = window.firestore;
      const mData = { ...data, email };
      await setDoc(doc(db, 'users/' + uid), mData);
      await getLoginInfo(uid);
    } catch (e) {
      console.error(e);
    }
  };

  const getLoginInfo = async (uid: string): Promise<void> => {
    try {
      const db = window.firestore;
      const querySnapshot = await getDoc(doc(db, 'users/' + uid));
      const data = querySnapshot.data();
      if (data == null) {
        setSignedIn(true);
        setFirstSignUp(true);
        trackEvent('Started Sign Up');
      } else {
        setSignedIn(true);
        setGotUserData(true);
        const mData = JSON.parse(JSON.stringify(data));
        if (window.prodMode) {
          mixpanel.people.set({
            $name: mData.name + ' ' + mData.lastName,
            $email: mData.email,
            companySize: mData.companySize,
            role: mData.role,
          });
        }
        trackEvent('Signed in');
        dispatch(setUserData(mData));
      }
      setLoadedAuth(true);
      dispatch(setCurrentUserID(uid));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!firstSignUp) return;
    if (!gotUserData) return;
    trackEvent('Finished Sign Up');
  }, [firstSignUp, gotUserData]);

  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = false;
    if (window.prodMode) {
      mixpanel.init('8a6c6e70beaa0ec8eaba47d6428af420', {
        debug: false,
        track_pageview: true,
        persistence: 'localStorage',
      });
    }
    trackEvent('Loaded Agua');
    const auth = window.auth;
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        const uid = user.uid;
        if (window.prodMode) mixpanel.identify(uid);
        const email = user.email ?? '';
        void getLoginInfo(uid);
        setUid(uid);
        setEmail(email);
      } else {
        setLoadedAuth(true);
        setSignedIn(false);
      }
    });
  }, []);

  const handleSignInClick = (): void => {
    const auth = window.auth;
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const uid = user.uid;
        dispatch(setCurrentUserID(uid));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Code: ' + errorCode + '\nMessage: ' + errorMessage);
      });
  };

  if (signedIn && gotUserData) return children;

  if (signedIn) {
    return <UserForm loading={formIsLoading} notifyData={receiveDataFromForm} />;
  }

  if (loadedAuth && !signedIn) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'var(--background-color-2)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="flex flex-row items-center justify-center h-screen bg-black  relative w-full">
          <div className="absolute w-full bottom-0 inset-x-0 h-40 bg-gradient-to-b pointer-events-none select-none from-transparent to-black z-40" />
          <div className="max-w-7xl mx-auto w-full relative overflow-hidden h-full px-4">
            <div
              style={{
                position: 'absolute',
                zIndex: 10,
                height: '700px',
                width: '700px',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%) translateY(40%)',
              }}
            >
              <World data={sampleArcs} globeConfig={globeConfig} />;
            </div>
          </div>
        </div>
        <div
          style={{
            width: 'fit-content',
            height: 'fit-content',
            position: 'absolute',
            left: '50%',
            top: '35%',
            display: 'flex',
            transform: 'translateX(-50%) translateY(-50%)',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            zIndex: 50,
          }}
        >
          <img style={{ width: '30px' }} src="./agua_white.png" />
          <div
            style={{
              margin: 'var(--padding-m)',
              fontSize: 'var(--font-size-display-cta)',
              color: 'var(--title-color)',
            }}
          >
            {t('welcome-agua')}
          </div>
          <div
            onClick={handleSignInClick}
            className={`${styles.signContainer} ${styles.signAnotherContainerSize} ${styles.normalBorder} ${styles.centeredRow} ${styles.cursorPointer}`}
          >
            <img className={`${styles.signGoogle}`} src="./google.png" />
            <div className={`${styles.fieldLabel} ${styles.marginLeft}`}>Sign in with Google</div>
          </div>
        </div>
        <BackgroundBeams />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
};
