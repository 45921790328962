'use strict';

export interface ITreeNodeAttributes {
  parent: string;
  id: string;
  children: ITreeNodeAttributes[];
}

export interface ITreeNode {
  parent: string;
  id: string;
  children: ITreeNode[];
  getId: () => string;
  getChildren: () => ITreeNode[];
  parentHasChild: (parentId: string, childId: string) => boolean;
  getAllSubnodes: (id: string) => string[];
  listAllSubnodes: () => string[];
  getNodeById: (id: string) => ITreeNode | null;
  addChild: (idParent: string, idChild: string) => ITreeNode | null;
  addNode: (idParent: string, childNode: ITreeNode) => ITreeNode | null;
  removeChild: (idChild: string) => ITreeNode | null;
  removeChildren: (id: string) => boolean;
  getObject: () => ITreeNodeAttributes;
}

class TreeNode implements ITreeNode {
  parent: string;
  id: string;
  children: ITreeNode[];

  constructor (parent: string, id: string) {
    this.parent = parent;
    this.id = id;
    this.children = [];
  }

  getObject (): ITreeNodeAttributes {
    const attributeChildren = [];
    for (const child of this.children) {
      attributeChildren.push(child.getObject());
    }
    return { parent: this.parent, id: this.id, children: attributeChildren };
  }

  setParent (parent: string): void {
    this.parent = parent;
  }

  getId (): string {
    return this.id;
  }

  getParent (): string {
    return this.parent;
  }

  getChildren (): ITreeNode[] {
    return this.children;
  }

  parentHasChild (parentId: string, childId: string): boolean {
    if (this.id === parentId) {
      let hasChild = false;
      for (let i = 0; i < this.children.length && !hasChild; i++) {
        if (this.children[i].getId() === childId) hasChild = true;
      }
      return hasChild;
    } else {
      let childHasChild = false;
      for (let i = 0; i < this.children.length && !childHasChild; i++) {
        childHasChild = this.children[i].parentHasChild(parentId, childId);
      }
      return childHasChild;
    }
  }

  getAllSubnodes (id: string): string[] {
    if (this.id === id) {
      return this.listAllSubnodes();
    } else {
      let nodes: string[] = [];
      for (let i = 0; i < this.children.length; i++) {
        nodes = nodes.concat(this.children[i].getAllSubnodes(id));
      }
      return nodes;
    }
  }

  listAllSubnodes (): string[] {
    let nodes: string[] = [];
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i] != null) {
        nodes.push(this.children[i].getId());
        nodes = nodes.concat(this.children[i].listAllSubnodes());
      }
    }
    return nodes;
  }

  getNodeById (id: string): ITreeNode | null {
    if (id === this.getId()) {
      return this;
    } else {
      let node = null;
      for (let i = 0; i < this.children.length && node == null; i++) {
        if (this.children[i] != null) node = this.children[i].getNodeById(id);
      }
      return node;
    }
  }

  addChild (idParent: string, idChild: string): ITreeNode | null {
    if (idParent === this.id) {
      let err = false;
      for (let i = 0; i < this.children.length && !err; i++) {
        if (this.children[i].id === idChild &&
            this.getId() !== Number.NEGATIVE_INFINITY.toString()) err = true;
      }
      if (!err) {
        const node = new TreeNode(idParent, idChild);
        this.children.push(node);
        return node;
      }
      return null;
    } else {
      let node = null;
      for (let i = 0; i < this.children.length && node == null; i++) {
        node = this.children[i].addChild(idParent, idChild);
      }
      return node;
    }
  }

  addNode (idParent: string, childNode: ITreeNode): ITreeNode | null {
    if (idParent === this.id) {
      let err = false;
      for (let i = 0; i < this.children.length && !err; i++) {
        if (this.children[i].id === childNode.getId() &&
            this.getId() !== Number.NEGATIVE_INFINITY.toString()) err = true;
      }
      if (!err) {
        const node = childNode;
        this.children.push(node);
        return node;
      }
      return null;
    } else {
      let node = null;
      for (let i = 0; i < this.children.length && node == null; i++) {
        node = this.children[i].addNode(idParent, childNode);
      }
      return node;
    }
  }

  removeChild (idChild: string): ITreeNode | null {
    let node = null;
    for (let i = 0; i < this.children.length && node == null; i++) {
      if (this.children[i].id === idChild) {
        node = this.children[i];
        this.children.splice(i, 1);
      } else node = this.children[i].removeChild(idChild);
    }
    return node;
  }

  removeChildren (id: string): boolean {
    if (this.id === id) {
      this.children = [];
      return true;
    } else {
      let removed = false;
      for (let i = 0; i < this.children.length && !removed; i++) {
        removed = this.children[i].removeChildren(id);
      }
      return removed;
    }
  }
}

export { TreeNode };
