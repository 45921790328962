import { type ReactInfo } from '@globalTypes/conversor';
import { type DirectionsType, type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BOTTOM,
  LEFT,
  LINE_JUMP,
  MINUS,
  RIGHT,
  SEMICOLON,
  TOP,
  TWO_POINTS,
  TWO_POINTS_SPACE,
  UNDERSCORE,
  UNIT_PX
} from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { referenceValue } from '../reactItemUtils';

export function handlePaddingMarginStyle (item: TypeItemProperties, type: string, creatingComponent: boolean,
  dictionary: TypeDictionary, variable: string, neededInfo: ReactInfo): string {
  let style = '';
  const mType = 'margins' in item ? item.margins : {};
  const pType = 'paddings' in item ? item.paddings : {};
  const pluralType = (type === 'margin' ? mType : pType) as DirectionsType.DirectionsType;
  const reference = referenceValue(type + variable, item, false);
  const mVar = variable as keyof typeof pluralType;
  const unit = pluralType[mVar]?.unit ?? UNIT_PX;
  const info = pluralType[mVar]?.value ?? 0;
  const nonVarResponsive = creatingComponent &&
    typeof (reference) !== 'boolean' &&
    !reference.includes(UNDERSCORE);
  const allVar = nonVarResponsive || (creatingComponent && reference !== false) ||
    (!creatingComponent && reference === false);
  const isClass = neededInfo.variableListInfo == null
    ? false
    : (type === 'margin'
        ? neededInfo.variableListInfo.normal.margins != null
        : neededInfo.variableListInfo.normal.paddings != null);
  if (allVar && info != null && (info > 0 || isClass)) {
    const key = unit as keyof typeof dictionary;
    style = LINE_JUMP + type + MINUS + variable + TWO_POINTS_SPACE + info + dictionary[key] + SEMICOLON;
  }
  return style;
}

export function paddingAndMarginStyle (item: TypeItemProperties, type: string, creatingComponent: boolean,
  dictionary: TypeDictionary, neededInfo: ReactInfo): string {
  let style = '';
  const variables = [TOP, BOTTOM, LEFT, RIGHT];
  const styles = { top: '', bottom: '', left: '', right: '' };
  for (const variable of variables) {
    styles[variable as keyof typeof styles] =
      handlePaddingMarginStyle(item, type, creatingComponent, dictionary, variable, neededInfo);
  }
  const valTop = styles.top.split(TWO_POINTS)[1] ?? '';
  const valBot = styles.bottom.split(TWO_POINTS)[1] ?? '';
  const valLeft = styles.left.split(TWO_POINTS)[1] ?? '';
  const valRight = styles.right.split(TWO_POINTS)[1] ?? '';
  if (valTop === valBot && valLeft === valRight && valLeft === valTop && valTop !== '') {
    style = type + TWO_POINTS_SPACE + valTop;
  } else if (valTop === valBot && valLeft === valRight && valRight !== '' && valTop !== '') {
    style = type + TWO_POINTS_SPACE + valTop.replace(SEMICOLON, ' ') + valRight;
  } else {
    style = styles.top + styles.bottom + styles.left + styles.right;
  }
  return LINE_JUMP + style;
}
