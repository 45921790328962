import { type ITreeNode } from '@globalUtils/tree';
import {
  ANIMATE_DEPENDENCY,
  ANTD,
  ANTD_DEPENDENCY,
  BASE_FONT,
  BRACKET_NAME,
  CANVAS_SPECIAL_CLASSES,
  CLOSE_SCRIPT,
  COMMA,
  COMPONENT,
  DEPENDENCIES,
  FAV_ICON,
  FIRST_SCREEN,
  FIRST_SCREEN_IMPORT,
  FONTS,
  FROM_AGUA_PAGES,
  ICON_BRACKET,
  IMPORT,
  IMPORTS,
  INDEX_CSS,
  INDEX_HTML,
  INITIAL_TEMPLATE,
  IS_WOFF,
  IS_WOFFTWO_FORMAT,
  IS_WOFF_FORMAT,
  LESS_THAN,
  LINE_JUMP,
  LINK_ICON,
  MORE_THAN,
  OBSERVER_DEPENDENCY,
  OPEN_SCRIPT,
  OTF_FORMAT,
  POINT,
  PROJECT_NAME,
  ROUTES,
  SCREEN,
  SCRIPT,
  SEMICOLON,
  SLASH,
  TTF_FORMAT,
  TWO_POINTS,
  URL_BRACKET,
  WINDOWS_ESC,
  WOFFTWO_FORMAT,
  WOFF_FORMAT
} from '../../constants/constants';
import {
  type IComponentinfo,
  type TypeItems,
  type ReactInfo,
  type TypeItemData,
  type IAguaRender
} from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';
import { isComponentALayout } from '../reactComponentMethods/reactComponentUtils';
import { getConvertNametoReact } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';

import { createFileInstance } from './reactCreateFilesMethods';
import { getRoutesCode } from './reactFileUtils';

export function fontUrlHandle (neededInfo: ReactInfo, font: string, file: string): string {
  if (neededInfo.isPreview || neededInfo.canvas) {
    font = font.replace(URL_BRACKET, BASE_FONT + neededInfo.files[file]);
  } else {
    font = font.replace(URL_BRACKET, POINT + SLASH + file);
  }
  return font;
}

export function fontFormatHandle (font: string, file: string): string {
  if (file.endsWith(WOFF_FORMAT)) {
    font = font.replace(IS_WOFF, IS_WOFF_FORMAT);
  } else if (file.endsWith(WOFFTWO_FORMAT)) {
    font = font.replace(IS_WOFF, IS_WOFFTWO_FORMAT);
  } else {
    font = font.replace(IS_WOFF, '');
  }
  return font;
}

export function getFontImportStyle (neededInfo: ReactInfo, dictionary: TypeDictionary): string {
  let fontImport = '';
  for (const file in neededInfo.files) {
    if (file.endsWith(TTF_FORMAT) || file.endsWith(OTF_FORMAT) || file.endsWith(WOFF_FORMAT) ||
        file.endsWith(WOFFTWO_FORMAT)) {
      let font = dictionary.fontImport;
      let name = file.split(POINT)[0];
      if (name.includes(SLASH)) name = name.substring(name.lastIndexOf(SLASH) + 1);
      font = font.replace(BRACKET_NAME, name);
      font = fontUrlHandle(neededInfo, font, file);
      font = fontFormatHandle(font, file);
      fontImport += LINE_JUMP + font;
    }
  }
  return fontImport;
}

export function initCreatePages (neededInfo: ReactInfo, userEdited: any,
  items: TypeItems): void {
  neededInfo.firstPage = '';
  neededInfo.orientationVariables = {};
  neededInfo.fillVariables = {};
  neededInfo.userPagesObject = userEdited?.src?.agua?.pages ?? false;
  neededInfo.differentArchives = {
    agua: {
      pages: {},
      components: {},
      layouts: {},
      routes: {}
    }
  };
  for (const id in items) {
    const item = items[id];
    if (item.commonKind === SCREEN) {
      const pageName = getConvertNametoReact(item.name);
      const archiveName = pageName.substring(0, 1).toLowerCase() + pageName.substring(1);
      neededInfo.differentArchives.agua.pages[archiveName] = {};
    }
    if ('component' in item && item.component != null && item.component !== '') {
      const name = getConvertNametoReact(item.component);
      const archiveName = name.substring(0, 1).toLowerCase() + name.substring(1);
      if (isComponentALayout(item.component, neededInfo.componentsUsed)) {
        neededInfo.differentArchives.agua.layouts[archiveName] = {};
      } else neededInfo.differentArchives.agua.components[archiveName] = {};
    }
  }
}

export function handlePackageJsonDependencies (dictionary: TypeDictionary, name: string,
  neededInfo: ReactInfo): string {
  let packageJson = dictionary.packageJson;
  let dependencies = '';
  for (const dependencie in neededInfo.dependenciesList) {
    dependencies += COMMA + LINE_JUMP + dependencie + TWO_POINTS + neededInfo.dependenciesList[dependencie];
  }
  if (neededInfo.proyectAnimation != null) {
    dependencies += ANIMATE_DEPENDENCY;
  }
  if (neededInfo.proyectAnimationVisible != null) {
    dependencies += OBSERVER_DEPENDENCY;
  }
  if (Object.keys(neededInfo.pageHasForm).length !== 0 && !dependencies.includes(ANTD)) {
    dependencies += ANTD_DEPENDENCY;
  }
  packageJson = packageJson.replace(DEPENDENCIES, dependencies);
  packageJson = packageJson.replace(PROJECT_NAME, name);
  return packageJson;
}

export function handleRoutesArchive (tree: ITreeNode[], items: TypeItems, dictionary: TypeDictionary,
  selectedScreen: string): string {
  let routesArchive = dictionary.routesArchive;
  const routesInfo = getRoutesCode(tree, items, selectedScreen, dictionary);
  routesArchive = routesArchive.replace(ROUTES, routesInfo[0]);
  routesArchive = routesArchive.replace(IMPORTS, routesInfo[1]);
  return routesArchive;
}

export function handleAppJs (dictionary: TypeDictionary, firstPage: string): string {
  let appjs = dictionary.appJs;
  appjs = appjs.replace(FIRST_SCREEN, firstPage === ''
    ? ''
    : LESS_THAN + firstPage + ' ' + SLASH + MORE_THAN);
  const archiveNameIndex = firstPage.substring(0, 1).toLowerCase() + firstPage.substring(1);
  const fpVal = IMPORT + ' ' + firstPage + FROM_AGUA_PAGES + archiveNameIndex + '"' + SEMICOLON;
  appjs = appjs.replace(FIRST_SCREEN_IMPORT, firstPage === '' ? '' : fpVal);
  return appjs;
}

export function handlePublicArchives (neededInfo: ReactInfo, icon: string, dictionary: TypeDictionary): any {
  const publicArchives: any = {};
  const key = (INITIAL_TEMPLATE + (!neededInfo.watermark ? COMPONENT : '')) as keyof typeof dictionary;
  let indexHtml = dictionary[key];
  if (!neededInfo.isPreview && icon != null) {
    if (publicArchives.assets == null) publicArchives.assets = {};
    publicArchives.assets[FAV_ICON] = icon;
    indexHtml = indexHtml.replace(ICON_BRACKET, LINK_ICON);
  } else indexHtml = indexHtml.replace(ICON_BRACKET, '');
  const scriptVal = neededInfo.scripts != null ? OPEN_SCRIPT + neededInfo.scripts + CLOSE_SCRIPT : '';
  indexHtml = indexHtml.replace(SCRIPT, scriptVal);
  publicArchives[INDEX_HTML] = indexHtml;
  if (neededInfo.userEdited?.public?.assets != null) {
    for (const archive in neededInfo.userEdited.public.assets) {
      if (publicArchives.assets[archive] == null) {
        publicArchives.assets[archive] = neededInfo.userEdited.public.assets[archive];
      }
    }
  }
  return publicArchives;
}

export function getAguaRender (neededInfo: ReactInfo, dictionary: TypeDictionary, info: any): IAguaRender {
  const agua: IAguaRender = {
    public: info.publicArchives,
    src: {
      agua: {
        routes: {
          'routes.jsx': info.routesArchive
        },
        pages: info.pagesObject,
        components: neededInfo.componentCode
      },
      'app.js': info.appjs.replace(WINDOWS_ESC, ''),
      'aguaNotOverwritable.jsx': dictionary.aguaNotOverwritable,
      'index.css': dictionary[INDEX_CSS].replace(FONTS, info.fonts) + neededInfo.themes,
      'index.js': info.indexjs
    },
    'package.json': info.packageJson
  };
  if (Object.keys(neededInfo.layoutCode).length !== 0) {
    agua.src.agua.layouts = neededInfo.layoutCode;
  }
  return agua;
}

export function createPages (data: any, userEdited: string, neededInfo: ReactInfo, dictionary: TypeDictionary,
  viewDOM: IComponentinfo): IAguaRender {
  const pagesObject = {};
  const fonts = getFontImportStyle(neededInfo, dictionary);
  initCreatePages(neededInfo, userEdited, data.items);
  for (const treeItem of data.tree) {
    const item = data.items[treeItem.id];
    const pageName = getConvertNametoReact(item.name);
    const treeRoot = treeItem.children;
    if (item.commonKind === SCREEN) {
      const pdata = { item, pageName, treeRoot, items: data.items };
      createFileInstance(pdata, neededInfo, dictionary, viewDOM, pagesObject);
    }
    neededInfo.aditionalTextVariable[pageName] = '';
  }
  const packageJson = handlePackageJsonDependencies(dictionary, data.name, neededInfo);
  const routesArchive = handleRoutesArchive(data.tree, data.items, dictionary, '');
  const indexjs = dictionary.indexApp;
  const appjs = handleAppJs(dictionary, neededInfo.firstPage);
  for (const custom in neededInfo.customCode) {
    neededInfo.componentCode[custom] = neededInfo.customCode[custom];
  }
  const publicArchives = handlePublicArchives(neededInfo, data.icon, dictionary);
  const info = { publicArchives, routesArchive, pagesObject, appjs, indexjs, packageJson, fonts };
  const agua = getAguaRender(neededInfo, dictionary, info);
  return agua;
}

export function canvasMode (data: TypeItemData, userEdited: string, neededInfo: ReactInfo,
  dictionary: TypeDictionary, viewDOM: IComponentinfo): IAguaRender {
  const pagesObject = {};
  const fonts = getFontImportStyle(neededInfo, dictionary);
  for (const id in neededInfo.items) {
    const hItem = neededInfo.items[id];
    if ('href' in hItem) hItem.href = 'canvas';
  }
  neededInfo.canvas = true;
  initCreatePages(neededInfo, userEdited, data.items);
  for (const treeItem of data.tree) {
    const item = data.items[treeItem.id];
    const pageName = getConvertNametoReact(item.name);
    const treeRoot = treeItem.children;
    if (item.commonKind === SCREEN && item.id === data.selectedScreen) {
      const pdata = { item, pageName, treeRoot, items: data.items };
      createFileInstance(pdata, neededInfo, dictionary, viewDOM, pagesObject);
    }
    neededInfo.aditionalTextVariable[pageName] = '';
  }
  const routesArchive =
    handleRoutesArchive(data.tree, data.items, dictionary, data.selectedScreen ?? '');
  for (const custom in neededInfo.customCode) {
    neededInfo.componentCode[custom] = neededInfo.customCode[custom];
  }
  const packageJson = handlePackageJsonDependencies(dictionary, data.name ?? '', neededInfo);
  const indexjs = dictionary.indexApp;
  const appjs = handleAppJs(dictionary, neededInfo.firstPage);
  const publicArchives = handlePublicArchives(neededInfo, data.icon ?? '', dictionary);
  const info = { publicArchives, routesArchive, pagesObject, appjs, indexjs, packageJson, fonts };
  neededInfo.themes += CANVAS_SPECIAL_CLASSES;
  const agua = getAguaRender(neededInfo, dictionary, info);
  neededInfo.canvas = false;
  return agua;
}
