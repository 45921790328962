import React, {
  type ChangeEvent,
  type MutableRefObject,
  type RefCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { type OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/icon';

import { getKeyBinding } from '@globalUtils/shortcuts';

import styles from './styles.module.css';

type MutableRefList<T> = Array<RefCallback<T> | MutableRefObject<T> | undefined | null>;

export function mergeRefs<T>(...refs: MutableRefList<T>): RefCallback<T> {
  return (val: T) => {
    setRef(val, ...refs);
  };
}

export function setRef<T>(val: T, ...refs: MutableRefList<T>): void {
  refs.forEach((ref) => {
    if (typeof ref === 'function') {
      ref(val);
    } else if (ref != null) {
      ref.current = val;
    }
  });
}

const isMac = navigator.userAgent.includes('Mac OS X');
const keyboardShortcutOptions: OptionsOrDependencyArray = {
  enableOnFormTags: ['input', 'select', 'textarea'],
};

export const SearchBar = ({
  onChange,
  onFinish,
  hideIcon = false,
  placeholder,
  register,
  defaultValue = '',
  disabled = false,
  preventLeftBorderRadius = false,
}: {
  onChange?: (value: string) => any;
  onFinish?: (value: string) => any;
  hideIcon?: boolean;
  placeholder?: string;
  register?: any;
  defaultValue?: string;
  disabled?: boolean;
  preventLeftBorderRadius?: boolean;
}): React.JSX.Element => {
  const { t } = useTranslation();

  const mPlaceholder = placeholder ?? t('search-label');

  const [value, setValue] = useState<string>(defaultValue);
  const [prevValue, setPrevValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);

  const handleEnter = (): void => {
    if (!isFocused) return;
    if (onFinish == null || value === prevValue) return;
    setPrevValue(value);
    onFinish(value);
  };

  useHotkeys(getKeyBinding('enter', isMac), handleEnter, keyboardShortcutOptions);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (register?.onChange != null) register.onChange(e);
    const val = e.target.value;
    if (val === value) return;
    setValue(val);
    if (onChange != null) onChange(val);
  };

  const handleBlur = (e: any): void => {
    if (register?.onBlur != null) register.onBlur(e);
  };

  const handleFocusIn = (): void => {
    setIsFocused(true);
  };

  const handleFocusOut = (): void => {
    setIsFocused(false);
    const mInput = ref.current;
    if (mInput == null) return;
    const val = mInput.value;
    if (onFinish == null || val === prevValue) return;
    setPrevValue(val);
    onFinish(val);
  };

  useEffect(() => {
    if (defaultValue !== value) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const mInput = ref.current;
    if (mInput == null) return;
    mInput.addEventListener('focus', handleFocusIn);
    return () => {
      mInput.removeEventListener('focus', handleFocusIn);
    };
  }, []);

  useEffect(() => {
    const mInput = ref.current;
    if (mInput == null) return;
    mInput.addEventListener('focusout', handleFocusOut);
    return () => {
      mInput.removeEventListener('focusout', handleFocusOut);
    };
  }, [value, prevValue]);

  useEffect(() => {
    const mInput = ref.current;
    if (mInput == null) return;
    mInput.addEventListener('focusout', handleFocusOut);
    return () => {
      mInput.removeEventListener('focusout', handleFocusOut);
    };
  }, [value]);

  return (
    <div
      className={styles.container}
      style={{
        cursor: disabled ? 'not-allowed' : 'auto',
        backgroundColor: disabled ? 'var(--background-color-disabled-1)' : '',
        borderRadius: preventLeftBorderRadius ? 0 : '',
        borderTopRightRadius: preventLeftBorderRadius ? 'var(--border-radius-m)' : '',
        borderBottomRightRadius: preventLeftBorderRadius ? 'var(--border-radius-m)' : '',
      }}
    >
      <input
        disabled={disabled}
        style={{
          cursor: disabled ? 'not-allowed' : 'auto',
        }}
        ref={register?.ref != null ? mergeRefs(register.ref, ref) : ref}
        className={styles.inputField}
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={mPlaceholder}
      ></input>
      {!hideIcon && <Icon color="var(--title-color)" icon="search" />}
    </div>
  );
};
