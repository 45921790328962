import { type PayloadAction } from '@reduxjs/toolkit';

import { type ItemsType } from '..';
import {
  AuthorType,
  CanonicalType,
  DescriptionType,
  IsMainType,
  KeywordsType,
  RobotsType,
  TitleType,
  UrlType
} from '@globalTypes/itemProperties';

interface ScreenIsMainPayload {
  [IsMainType.IsMainProperty]: IsMainType.IsMainType;
  id: string;
};
export const changeScreenIsMain = (state: ItemsType, action: PayloadAction<ScreenIsMainPayload>): void => {
  const { id } = action.payload;
  const isMain = action.payload[IsMainType.IsMainProperty];
  const item = state.items.byId[id];
  if (IsMainType.IsMainProperty in item) {
    item[IsMainType.IsMainProperty] = isMain;
  }
};

interface ScreenUrlPayload {
  [UrlType.UrlProperty]: UrlType.UrlType;
  id: string;
};
export const changeScreenUrl = (state: ItemsType, action: PayloadAction<ScreenUrlPayload>): void => {
  const { id } = action.payload;
  const url = action.payload[UrlType.UrlProperty];
  const item = state.items.byId[id];
  if (UrlType.UrlProperty in item) {
    item[UrlType.UrlProperty] = url;
  }
};

interface ScreenTitlePayload {
  [TitleType.TitleProperty]: TitleType.TitleType;
  id: string;
};
export const changeScreenTitle = (state: ItemsType, action: PayloadAction<ScreenTitlePayload>): void => {
  const { id } = action.payload;
  const title = action.payload[TitleType.TitleProperty];
  const item = state.items.byId[id];
  if (TitleType.TitleProperty in item) {
    item[TitleType.TitleProperty] = title;
  }
};

interface ScreenDescriptionPayload {
  [DescriptionType.DescriptionProperty]: DescriptionType.DescriptionType;
  id: string;
};
export const changeScreenDescription = (state: ItemsType,
  action: PayloadAction<ScreenDescriptionPayload>): void => {
  const { id } = action.payload;
  const description = action.payload[DescriptionType.DescriptionProperty];
  const item = state.items.byId[id];
  if (DescriptionType.DescriptionProperty in item) {
    item[DescriptionType.DescriptionProperty] = description;
  }
};

interface ScreenKeywordsPayload {
  [KeywordsType.KeywordsProperty]: KeywordsType.KeywordsType;
  id: string;
};
export const changeScreenKeywords = (state: ItemsType,
  action: PayloadAction<ScreenKeywordsPayload>): void => {
  const { id } = action.payload;
  const keywords = action.payload[KeywordsType.KeywordsProperty];
  const item = state.items.byId[id];
  if (KeywordsType.KeywordsProperty in item) {
    item[KeywordsType.KeywordsProperty] = keywords;
  }
};

interface ScreenAuthorPayload {
  [AuthorType.AuthorProperty]: AuthorType.AuthorType;
  id: string;
};
export const changeScreenAuthor = (state: ItemsType, action: PayloadAction<ScreenAuthorPayload>): void => {
  const { id } = action.payload;
  const author = action.payload[AuthorType.AuthorProperty];
  const item = state.items.byId[id];
  if (AuthorType.AuthorProperty in item) {
    item[AuthorType.AuthorProperty] = author;
  }
};

interface ScreenCanonicalPayload {
  [CanonicalType.CanonicalProperty]: CanonicalType.CanonicalType;
  id: string;
};
export const changeScreenCanonical = (state: ItemsType,
  action: PayloadAction<ScreenCanonicalPayload>): void => {
  const { id } = action.payload;
  const canonical = action.payload[CanonicalType.CanonicalProperty];
  const item = state.items.byId[id];
  if (CanonicalType.CanonicalProperty in item) {
    item[CanonicalType.CanonicalProperty] = canonical;
  }
};

interface ScreenRobotsPayload {
  [RobotsType.RobotsProperty]: RobotsType.RobotsType;
  id: string;
};
export const changeScreenRobots = (state: ItemsType, action: PayloadAction<ScreenRobotsPayload>): void => {
  const { id } = action.payload;
  const robots = action.payload[RobotsType.RobotsProperty];
  const item = state.items.byId[id];
  if (RobotsType.RobotsProperty in item) {
    item[RobotsType.RobotsProperty] = robots;
  }
};
