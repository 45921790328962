import { isColor } from './reactThemeUtils';
import { adaptImagePath } from '../reactItemMethods/reactHTMLMethods/propertyTypeMethods/reactImageMethods';
import { adaptItemName, getParent } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import {
  AGUA_ITEM,
  ANTD_FORM,
  BACKGROUND_COLOR,
  BEFORE_CONTENT,
  BOLD,
  CLOSING_PARENTESIS,
  COLUMN,
  COMMA,
  COMPONENT_VARIABLES,
  CSS_CLOSING,
  DEFAULT,
  DEFAULT_FONT,
  DEFAULT_THEME,
  FILL,
  FILL_VALUE,
  FIT_CONTENT,
  FLEX,
  FONT_FAMILY,
  HIDDEN,
  HORIZONTAL,
  HORIZONTAL_ALIGNMENT,
  ICON,
  ICON_ACTION,
  ITALIC,
  LINE_JUMP,
  MHORIZONTAL_ALIGNMENT,
  MINUS,
  MINUS_STYLE,
  MIN_MINUS,
  MVERTICAL_ALIGNMENT,
  NONE,
  NORMAL,
  NUMBER_SIGN,
  POINT,
  RESPONSIVENESS_THEME,
  ROW,
  SCROLL,
  SCROLLABLE,
  SEMICOLON_LINE_JUMP,
  STICKY,
  STICKY_VARIABLE,
  TEXT,
  TEXT_CONTENT,
  TWO_POINTS,
  TWO_POINTS_SPACE,
  UNDERSCORE,
  UNSET,
  URL_OPENING,
  VAR,
  VAR_DEFINITION,
  VERTICAL,
  VERTICAL_ALIGNMENT,
  VISIBILITY_VARIABLE,
  WRAP,
  WRAP_VARIABLE
} from '../../constants/constants';
import { DimensionType, SizeValueType, type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  type TypeAttributeReference,
  type ReactInfo,
  type TypeAttributeReferenceVariable,
  type TypeVariable,
  type TypeVariableFontStyles
} from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';
import { FIX, FONT_SIZE, LEADING, SPACING, WEIGHT } from '@globalTypes/constants';

export function convertColorVariable (type: string, isParameter: boolean, info: string): string {
  if (type === COMPONENT_VARIABLES) {
    if (isParameter) return info;
  }
  return NUMBER_SIGN + info;
}

export function getExtraStyles (parent: string, neededInfo: ReactInfo, item: TypeItemProperties, kind: string,
  variable: string): void {
  if (neededInfo.aditionalTextVariable[parent] == null) neededInfo.aditionalTextVariable[parent] = '';
  const tag = 'tag' in item && item.tag !== ANTD_FORM ? item.tag : '';
  const name = adaptItemName(item.name, false);
  const id = !item.name.startsWith(item.commonKind) ? '' : MINUS + item.id;
  const mVal = AGUA_ITEM + kind + BEFORE_CONTENT + VAR_DEFINITION + variable + CSS_CLOSING;
  neededInfo.aditionalTextVariable[parent] += LINE_JUMP + tag + POINT + name + id + mVal;
}

export function responsivenessExtraStyles (neededInfo: ReactInfo, variable: string, theme: string,
  info: string): string {
  if (theme === RESPONSIVENESS_THEME && neededInfo.creatingComponent &&
      (variable.endsWith(ICON) || variable.endsWith(TEXT))) {
    const kind = variable.endsWith(ICON) ? ICON : TEXT;
    const item = neededInfo.items[variable.split(UNDERSCORE)[0]];
    const parent = getParent(item.id, neededInfo.items, neededInfo.creatingComponent);
    getExtraStyles(parent, neededInfo, item, kind, variable);
    if (kind === TEXT) info = '"' + info + '"';
    else info = '"' + info + '"';
  }
  return info;
}

export function nonDefaultExtraStyles (item: TypeItemProperties, neededInfo: ReactInfo,
  variable: string, theme: string, info: string, reference: TypeAttributeReference): string {
  if (theme.endsWith(TEXT) || theme.endsWith(ICON)) {
    if (item == null) {
      for (const itemObject in neededInfo.items) {
        if (neededInfo.items[itemObject].name === theme.split(UNDERSCORE)[0]) {
          item = neededInfo.items[itemObject];
        }
      }
    }
    info = defaultExtraStyles(item, neededInfo, variable, info, reference);
  }
  return info;
}

export function defaultExtraStyles (item: TypeItemProperties, neededInfo: ReactInfo,
  variable: string, info: string, reference: TypeAttributeReference): string {
  if (item != null) {
    const parent = getParent(item.id, neededInfo.items, neededInfo.creatingComponent);
    if ((reference.attribute.includes(TEXT_CONTENT) || reference.attribute.includes(ICON_ACTION)) &&
        neededInfo.creatingComponent) {
      info = '"' + info + '"';
      const kind = reference.attribute.includes(TEXT_CONTENT) ? TEXT : ICON;
      getExtraStyles(parent, neededInfo, item, kind, variable);
    }
  }
  return info;
}

export function addTypeExtraAditionalStyle (references: TypeAttributeReferenceVariable, id: string,
  neededInfo: ReactInfo, theme: string, variable: string, info: string): string {
  const item = neededInfo.items[id];
  const referenceList = references[id];
  for (const ref of referenceList) {
    if (theme !== DEFAULT_THEME) {
      info = nonDefaultExtraStyles(item, neededInfo, variable, theme, info, ref);
    } else {
      info = defaultExtraStyles(item, neededInfo, variable, info, ref);
    }
  }
  return info;
}

export function typeExtraAditionalStyles (type: string, info: string, neededInfo: ReactInfo, theme: string,
  variable: string): string {
  if (type === RESPONSIVENESS_THEME) {
    info = responsivenessExtraStyles(neededInfo, variable, theme, info);
  } else if (type === DEFAULT) {
    const references = neededInfo.attributeReference.Default[variable] as TypeAttributeReferenceVariable;
    if (references != null) {
      for (const reference in references) {
        info = addTypeExtraAditionalStyle(references, reference, neededInfo, theme, variable, info);
      }
    }
  }
  return info;
}

export function getStringInfo (info: string, theme: string, variable: string, neededInfo: ReactInfo): string {
  if (theme === RESPONSIVENESS_THEME && variable.endsWith(BACKGROUND_COLOR) && info.includes(TWO_POINTS)) {
    info = URL_OPENING + neededInfo.files[info.split(TWO_POINTS)[2]] + CLOSING_PARENTESIS;
  }
  return info;
}

export function convertFontVariable (variable: string, neededInfo: ReactInfo,
  value: TypeVariableFontStyles): void {
  neededInfo.variableList += VAR + FONT_FAMILY + UNDERSCORE + variable + TWO_POINTS_SPACE +
    value.fontFamily + SEMICOLON_LINE_JUMP;
  neededInfo.variableList += VAR + FONT_SIZE + UNDERSCORE + variable + TWO_POINTS_SPACE +
    value.fontSize[SizeValueType.SizeValueProperty] + value.fontSize.unit + SEMICOLON_LINE_JUMP;
  neededInfo.variableList += VAR + LEADING + UNDERSCORE + variable + TWO_POINTS_SPACE +
    value.leading + SEMICOLON_LINE_JUMP;
  neededInfo.variableList += VAR + SPACING + UNDERSCORE + variable + TWO_POINTS_SPACE +
    value.spacing + SEMICOLON_LINE_JUMP;
  neededInfo.variableList += VAR + WEIGHT + UNDERSCORE + variable + TWO_POINTS_SPACE +
    value.weight + SEMICOLON_LINE_JUMP;
}

export function convertStringVariable (trueValue: string, type: string, isParameter: boolean,
  neededInfo: ReactInfo, dictionary: TypeDictionary, variable: string, theme: string): string {
  let info = trueValue;
  if (isColor(info)) info = convertColorVariable(type, isParameter, info);
  else if (info === WRAP) {
    info = FIT_CONTENT;
    neededInfo.variableList += VAR + MIN_MINUS + variable + TWO_POINTS_SPACE + UNSET + SEMICOLON_LINE_JUMP;
  } else if (info === FILL) {
    info = FILL_VALUE;
    neededInfo.variableList += VAR + MIN_MINUS + variable + TWO_POINTS_SPACE + UNSET + SEMICOLON_LINE_JUMP;
  } else if (info === HORIZONTAL) info = ROW;
  else if (info === VERTICAL) info = COLUMN;
  else if (variable.includes(VERTICAL_ALIGNMENT)) {
    const key = (info + MVERTICAL_ALIGNMENT) as keyof typeof dictionary;
    info = key in dictionary ? dictionary[key] : '';
  } else if (variable.includes(HORIZONTAL_ALIGNMENT)) {
    const key = (info + MHORIZONTAL_ALIGNMENT) as keyof typeof dictionary;
    info = key in dictionary ? dictionary[key] : '';
  } else if (info === ITALIC) {
    neededInfo.variableList += VAR + variable + MINUS_STYLE + TWO_POINTS_SPACE + ITALIC +
      SEMICOLON_LINE_JUMP;
  } else if (info === NORMAL || info === BOLD) {
    neededInfo.variableList += VAR + variable + MINUS_STYLE + TWO_POINTS_SPACE + UNSET +
      SEMICOLON_LINE_JUMP;
  }
  info = getStringInfo(info, theme, variable, neededInfo);
  return typeExtraAditionalStyles(type, info, neededInfo, theme, variable);
}

export function convertBooleanVariable (trueValue: [boolean, string]): string {
  if (trueValue[1] === VISIBILITY_VARIABLE) return trueValue[0] ? FLEX : NONE;
  else if (trueValue[1] === WRAP_VARIABLE) return trueValue[0] ? WRAP : UNSET;
  else if (trueValue[1] === STICKY_VARIABLE) return trueValue[0] ? STICKY : UNSET;
  else if (trueValue[1] === SCROLLABLE) return trueValue[0] ? SCROLL : HIDDEN;
  else return '';
}

export function convertOtherVariable (value: string, dictionary: TypeDictionary, neededInfo: ReactInfo,
  variable: string): string {
  const info = value.split(COMMA);
  if (info[0] === info[1]) {
    if (info[0] === DEFAULT_THEME) {
      value = DEFAULT_FONT;
    } else value = '' + info[0];
  } else {
    const key = info[1] as keyof typeof dictionary;
    const unit = key in dictionary ? (dictionary[key] ?? dictionary.px) : dictionary.px;
    value = '' + info[0] + unit;
    neededInfo.variableList += VAR + MIN_MINUS + variable + TWO_POINTS_SPACE + value + SEMICOLON_LINE_JUMP;
  }
  return value;
}

export function convertVariableinfo (theme: string, variable: string, trueValue: TypeVariable,
  neededInfo: ReactInfo, type: string, isParameter: boolean, dictionary: TypeDictionary): string {
  let value = '';
  if ('boolean' in trueValue) value = convertBooleanVariable([trueValue.value, trueValue.kind]);
  else if ('color' in trueValue || 'orientation' in trueValue || 'alignment' in trueValue) {
    value = convertStringVariable(trueValue.value, type, isParameter, neededInfo,
      dictionary, variable, theme);
  } else if ('size' in trueValue) {
    value = trueValue.value[SizeValueType.SizeValueProperty] + COMMA + trueValue.value.unit;
    value = convertOtherVariable(value, dictionary, neededInfo, variable);
  } else if ('typography' in trueValue) {
    convertFontVariable(variable, neededInfo, trueValue.value);
  } else if ('dimension' in trueValue) {
    if (trueValue.value[DimensionType.DimensionKindProperty] !== FIX) {
      value = trueValue.value[DimensionType.DimensionKindProperty];
      if (value === 'fill') value = '100%';
      else value = 'fit-content';
    } else if (trueValue.value.data != null) {
      value = trueValue.value.data[SizeValueType.SizeValueProperty] + COMMA + trueValue.value.data.unit;
      value = convertOtherVariable(value, dictionary, neededInfo, variable);
    }
  } else if ('asset' in trueValue) {
    value = adaptImagePath(trueValue.value, '', neededInfo.creatingComponent, neededInfo.componentsUsed,
      neededInfo.differentArchives);
  } else if ('text' in trueValue) {
    value = convertStringVariable(trueValue.value.content.default, type, isParameter, neededInfo,
      dictionary, variable, theme);
  } else value = trueValue.value;
  return value;
}
