/* eslint-disable multiline-ternary */

/* eslint-disable max-len */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackgroundGradient } from '@components/backgroundGradient';

import { setInitialEditor, setWholeEditor } from '@reducers/editor';
import { getFiles, setFiles } from '@reducers/files';
import { setWholeGraphs } from '@reducers/graphs';
import { setInitialItems } from '@reducers/items';
import { setInitialThemes, setWholeThemes } from '@reducers/themes';

import { trackEvent } from '@globalUtils/metrics';

import { type IFiles } from '@globalTypes/conversor';

export const WithFSLoaded = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const dispatch = useDispatch();

  const files = useSelector(getFiles);

  const [filesLoaded, setFilesLoaded] = useState<boolean>(false);
  const firstTime = useRef<boolean>(false);

  const fetchFiles = async (): Promise<void> => {
    fetch('http://localhost:4035/fs/files')
      .then((res) => {
        res
          .json()
          .then((data) => {
            const mUpdatedAt = data.updatedAt;
            if (mUpdatedAt === 0) {
              setTimeout(() => {
                void fetchFiles();
              }, 2000);
              return;
            }
            const mFiles: IFiles = [];
            const files = data.data;
            for (const key in files) {
              mFiles.push({
                name: key,
                data: files[key],
              });
            }
            setFilesLoaded(true);
            dispatch(setFiles(mFiles));
          })
          .catch((e1) => {
            window.logger.error(e1);
          });
      })
      .catch((e2) => {
        window.logger.error(e2);
      });
  };

  const firstUsage = (): void => {
    dispatch(setInitialEditor(null));
    dispatch(setInitialItems(null));
    dispatch(setInitialThemes(null));
  };

  const loadState = (mData: any): void => {
    const data = JSON.parse(mData.data);
    dispatch(setWholeEditor(data.editor));
    dispatch(setWholeThemes(data.themes));
    dispatch(setWholeGraphs(data.graphs));
  };

  useEffect(() => {
    if (files.length === 0) return;
    const mAguaInit = files.find((file: any) => file.name === '.agua/agua.json');
    if (mAguaInit == null) firstUsage();
    else loadState(mAguaInit);
  }, [files]);

  useEffect(() => {
    if (firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to FS Page');
    void fetchFiles();
  }, []);

  return filesLoaded ? (
    children
  ) : (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'var(--background-color-2)' }}>
      <div className={'h-full w-full relative flex items-center justify-center bg-dot-white/[0.2]'}>
        <div
          style={{ backgroundColor: 'black' }}
          className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"
        ></div>
        <BackgroundGradient className="w-auto h-auto">
          <div
            style={{
              width: '400px',
              height: 'fit-content',
              padding: 'var(--padding-l)',
              backgroundColor: 'var(--background-color-2)',
              borderRadius: 'var(--border-radius-xxl)',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 'calc(var(--padding-l) + var(--padding-m))',
              }}
            >
              <img style={{ height: '50px' }} src={'/agua_white.png'}></img>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: 'var(--padding-xl) 0px',
              }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          </div>
        </BackgroundGradient>
      </div>
    </div>
  );
};
