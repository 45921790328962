/* eslint-disable max-len */

/* eslint-disable max-lines-per-function */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BackgroundGradient } from '@components/backgroundGradient';
import { IconButton } from '@components/index';
import { MultipleSingleLineInput } from '@components/multipleSingleLineInput';
import { SingleLineInput } from '@components/singleLineInput';

import { getFiles, triggerSaveMiddleware } from '@reducers/files';
import { getSetup, getViteSetup, setViteSetup } from '@reducers/graphs';
import { type AppDispatch } from '@reducers/store';
import { getCurrentTheme } from '@reducers/themes';

import { trackEvent } from '@globalUtils/metrics';

export const WithViteSetupReady = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const [viteConfigPath, setViteConfigPath] = useState<string>('');
  const [pathAliases, setPathAliases] = useState<string[][]>([]);
  const [loadingVite, setLoadingVite] = useState<boolean>(false);
  const [viteIsOn, setViteIsOn] = useState<boolean>(false);
  const [viteIsFetching, setViteIsFetching] = useState<boolean>(false);
  const [serverIsOnFetched, setServerIsOnFetched] = useState<boolean>(false);
  const [aliasesAreValid, setAliasesAreValid] = useState<boolean>(true);

  const pathAliasesSent = useRef<boolean>(false);
  const viteConfigured = useRef<boolean>(false);

  const theme = useSelector(getCurrentTheme);

  const filesData = useSelector(getFiles);
  const viteSetup = useSelector(getViteSetup);
  const setup = useSelector(getSetup);
  const mSetup = setup.setup;
  const htmlEntry = mSetup.entryhtml;
  const reactRoot = mSetup.reactRoot;

  const firstTime = useRef<boolean>(true);

  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to Vite Page');
  }, []);

  const checkVite = (): void => {
    void fetch('http://localhost:4035/vite')
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 'ok') setViteIsOn(true);
        else setViteIsOn(false);
        setLoadingVite(false);
        setServerIsOnFetched(true);
      })
      .catch((e) => {});
  };

  const launchVite = (pathHTML: string, mViteConfigPath?: string, mPathAliases?: string[][]): void => {
    setViteIsFetching(true);
    const obj: any = {
      entryhtml: pathHTML,
      reactRoot,
    };
    if (viteConfigPath !== '') obj.viteconfig = viteConfigPath;
    if (pathAliases.length > 0) obj.aliases = pathAliases;
    if (mViteConfigPath != null) obj.viteconfig = mViteConfigPath;
    if (mPathAliases != null) obj.aliases = mPathAliases;
    fetch('http://localhost:4035/vite/run', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then(() => {
        setViteIsFetching(false);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    let isValid = true;
    for (const vals of pathAliases) {
      if (vals[0] != null && vals[0] !== '' && (vals[1] == null || vals[1] === '')) {
        isValid = false;
        break;
      }
    }
    setAliasesAreValid(isValid);
  }, [pathAliases]);

  useEffect(() => {
    const idVite = setInterval(checkVite, 2000);
    if (viteIsOn) {
      dispatch(triggerSaveMiddleware(null));
      return;
    }
    if (!viteConfigured.current && viteSetup.initializedSetup) {
      viteConfigured.current = true;
      handleStart(viteSetup.setup.vitePath, viteSetup.setup.aliases, setup.setup.entryhtml);
      return;
    } else if (!viteSetup.initializedSetup) {
      let mEntryVite = viteSetup.setup.vitePath;
      if (!viteSetup.initializedSetup) {
        mEntryVite = getViteConfigPath();
        if (!pathAliasesSent.current) void getPathAliases(mEntryVite);
        pathAliasesSent.current = true;
      } else if (pathAliases.length === 0) {
        const aliases = viteSetup.setup.aliases;
        setPathAliases(aliases);
      }
      setViteConfigPath(mEntryVite);
    }
    return () => {
      clearInterval(idVite);
    };
  }, [filesData, viteIsOn, viteSetup, pathAliasesSent, setup]);

  const getViteConfigPath = (): string => {
    const filtered = filesData.filter((file) => {
      if (file.name === 'vite.config.js' || file.name.endsWith('/vite.config.js')) return true;
      if (file.name === 'vite.config.ts' || file.name.endsWith('/vite.config.ts')) return true;
      if (file.name === 'vite.config.mts' || file.name.endsWith('/vite.config.mts')) return true;
      if (file.name === 'vite.config.mjs' || file.name.endsWith('/vite.config.mjs')) return true;
      return false;
    });
    filtered.sort((a, b) => a.name.split('/').length - b.name.split('/').length);
    if (filtered.length > 0) return './' + filtered.shift()?.name ?? '';
    return '';
  };

  const getPathAliases = async (viteConfig: string): Promise<void> => {
    let mPathAliases: string[][] = [];
    if (viteConfig !== '') {
      try {
        const res = await fetch('http://localhost:4035/vite/aliases', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ viteconfig: viteConfig }),
        });
        const aliases = await res.json();
        if (aliases != null && aliases.length > 0) mPathAliases = aliases;
      } catch (e) {}
    }
    setPathAliases(mPathAliases);
  };

  const handleChangeEntryVitePath = (val: string): void => {
    setViteConfigPath(val);
  };

  const handleChangePathAliases = (val: string[][]): void => {
    setPathAliases(val);
  };

  const handleStart = (configPath?: string, aliases?: string[][], mHtml?: string): void => {
    const mSetup = {
      vitePath: configPath ?? viteConfigPath,
      aliases: aliases ?? pathAliases,
    };
    if (configPath == null && aliases == null) dispatch(setViteSetup(mSetup));
    setLoadingVite(true);
    launchVite(mHtml ?? htmlEntry, configPath, aliases);
  };

  const bgDot = theme === 'dark' ? 'bg-dot-white/[0.2]' : 'bg-dot-black/[0.2]';

  if (viteIsOn) return children;

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'var(--background-color-2)' }}>
      <div className={'h-full w-full relative flex items-center justify-center ' + bgDot}>
        <div
          style={{ backgroundColor: theme === 'dark' ? 'black' : 'white' }}
          className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"
        ></div>
        <BackgroundGradient className="w-auto h-auto">
          <div
            style={{
              width: '400px',
              height: 'fit-content',
              padding: 'var(--padding-l)',
              backgroundColor: 'var(--background-color-2)',
              borderRadius: 'var(--border-radius-xxl)',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 'calc(var(--padding-l) + var(--padding-m))',
              }}
            >
              <img style={{ height: '50px' }} src={theme === 'dark' ? '/agua_white.png' : '/agua.png'}></img>
            </div>
            {!serverIsOnFetched && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'var(--padding-xl) 0px',
                }}
              >
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </div>
            )}
            {serverIsOnFetched && (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 'var(--padding-xl)',
                  }}
                >
                  <div style={{ width: '100px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
                    {t('setup-vite-label')}
                  </div>
                  <div style={{ flex: '1 1 50%', minWidth: 0 }}>
                    <SingleLineInput
                      defaultValue={viteConfigPath}
                      onFinish={handleChangeEntryVitePath}
                      placeholder={t('setup-vite-placeholder')}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 'var(--padding-m)',
                  }}
                >
                  <div style={{ width: '100%', marginBottom: 'var(--padding-s)' }}>
                    {t('setup-alias-label')}
                  </div>
                  <MultipleSingleLineInput
                    defaultValue={pathAliases}
                    notifyChange={handleChangePathAliases}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 'var(--padding-xl)',
                  }}
                >
                  <IconButton
                    border=""
                    width="130px"
                    onClick={() => {
                      handleStart();
                    }}
                    disabled={loadingVite || !aliasesAreValid || viteIsFetching}
                    icon={'rocket_launch'}
                    color="var(--title-color)"
                    variant="success"
                    iconSize="xlarge"
                  >
                    {loadingVite ? t('vite-launch-button-pending') : t('start-project')}
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </BackgroundGradient>
      </div>
    </div>
  );
};
