import React from 'react';

import styles from './styles.module.css';

export const RequestTag = ({ method }: { method: string }): React.JSX.Element => {
  const getTag = (): React.JSX.Element => {
    if (method === 'GET') {
      return <div className={styles.requestTag + ' ' + styles.get}>{method}</div>;
    } else if (method === 'POST') {
      return <div className={styles.requestTag + ' ' + styles.post}>{method}</div>;
    } else if (method === 'PUT') {
      return <div className={styles.requestTag + ' ' + styles.put}>{method}</div>;
    } else if (method === 'DELETE') {
      return <div className={styles.requestTag + ' ' + styles.delete}>DEL</div>;
    } else {
      return <div className={styles.requestTag + ' ' + styles.noneTag}>{method}</div>;
    }
  };

  return <div style={{ width: '42px' }}>{getTag()}</div>;
};
