/* eslint-disable no-new */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Sortable from 'sortablejs';

import { type ItemsStructure } from '@fragments/screenItemsTree/utils';

import { objectToHash } from '@globalUtils/strings';

import { Item, type ItemOptionsBar, type ItemSummary } from './item';
import styles from './styles.module.css';

const propsAreEqual = <T,>(oldProps: ItemListProps<T>, newProps: ItemListProps<T>): boolean => {
  if (oldProps.itemNode.id !== newProps.itemNode.id) return false;
  if (objectToHash(oldProps.itemNode) !== objectToHash(newProps.itemNode)) return false;
  if (oldProps.currentlySelectedItem !== newProps.currentlySelectedItem) return false;
  return true;
};

interface ItemListProps<T> {
  itemNode: ItemsStructure;
  level: number;
  disableSorting: boolean;
  notifyItemClick: (id: string) => void;
  notifyCollapsedItem: (id: string) => void;
  currentlySelectedItem?: string;
  spacing?: number;
  notifyChangeHierarchy: (from: string, to: string, id: string, position: number) => void;
  notifyAddViewMenuOpened: (item: ItemSummary, position: [number, number], offset: number) => void;
  notifyOpenContextMenu: (item: ItemSummary, position: [number, number], offset: number) => void;
  notifyItemHovered: (id: string) => void;
  itemOptionsBar?: ItemOptionsBar<T>;
  itemIconPreviewGetter: (item: ItemSummary) => React.JSX.Element;
  currentlyCollapsedItems: Record<string, boolean>;
}

export const ItemListComponent = <T,>({
  itemNode,
  disableSorting,
  level,
  notifyItemClick,
  notifyCollapsedItem,
  currentlySelectedItem,
  spacing = 8,
  notifyChangeHierarchy,
  notifyOpenContextMenu,
  notifyAddViewMenuOpened,
  notifyItemHovered,
  itemOptionsBar,
  itemIconPreviewGetter,
  currentlyCollapsedItems,
}: ItemListProps<T>): React.JSX.Element => {
  const sortableRef = useRef<HTMLDivElement | null>(null);
  const sortableChildrenRef = useRef<HTMLDivElement | null>(null);

  const [childrenHash, setChildrenHash] = useState<number>(objectToHash(itemNode.children));

  useEffect(() => {
    if (itemNode.pid != null) startSortable(sortableRef.current);
    startSortable(sortableChildrenRef.current);
  }, []);

  useEffect(() => {
    const newChildrenHash = objectToHash(itemNode.children);
    if (newChildrenHash !== childrenHash) setChildrenHash(newChildrenHash);
  }, [itemNode]);

  const startSortable = (refCurrent: HTMLDivElement | null): void => {
    if (refCurrent != null && !disableSorting) {
      new Sortable(refCurrent, {
        group: 'nested',
        animation: 0,
        swapThreshold: 1,
        onEnd: itemFinishedMoveEvent,
        ghostClass: 'sortableGhostClass',
        dragClass: 'sortableDragClass',
        scroll: true,
      });
    }
  };

  const itemFinishedMoveEvent = useCallback((e: Sortable.SortableEvent) => {
    let id = e.clone.getAttribute('data-id') ?? '';
    if (id === '' && e.clone.children.length > 0) id = e.clone.children[0].getAttribute('data-id') ?? '';
    const idFrom = e.from.getAttribute('data-id') ?? '';
    const idTo = e.to.getAttribute('data-id') ?? '';
    const index = e.newIndex ?? 0;
    if (idFrom === '' || idTo === '') return;
    if (idFrom === idTo && index === e.oldDraggableIndex) return;
    notifyChangeHierarchy(idFrom, idTo, id, index);
  }, []);

  const collapseFolder = (id: string): void => {
    notifyCollapsedItem(id);
  };

  const childrenElems = useMemo(() => {
    return itemNode.children.map((child) => {
      const isContainer = child.collapsable;
      if (isContainer) {
        return (
          <ItemList<T>
            disableSorting={disableSorting}
            currentlyCollapsedItems={currentlyCollapsedItems}
            itemIconPreviewGetter={itemIconPreviewGetter}
            itemOptionsBar={itemOptionsBar}
            notifyItemHovered={notifyItemHovered}
            notifyOpenContextMenu={notifyOpenContextMenu}
            notifyAddViewMenuOpened={notifyAddViewMenuOpened}
            notifyCollapsedItem={notifyCollapsedItem}
            currentlySelectedItem={currentlySelectedItem}
            notifyItemClick={notifyItemClick}
            level={level + 1}
            key={child.id}
            itemNode={child}
            notifyChangeHierarchy={notifyChangeHierarchy}
          />
        );
      }
      return (
        <Item<T>
          itemIconPreviewGetter={itemIconPreviewGetter}
          itemOptionsBar={itemOptionsBar}
          notifyItemHovered={notifyItemHovered}
          notifyOpenContextMenu={notifyOpenContextMenu}
          notifyOpenAddViewMenu={notifyAddViewMenuOpened}
          selected={currentlySelectedItem === child.id}
          notifyItemClick={notifyItemClick}
          notifyCollapseClick={collapseFolder}
          collapsed={currentlyCollapsedItems[child.id] ?? false}
          key={child.id}
          item={child}
        />
      );
    });
  }, [currentlySelectedItem, childrenHash]);

  return (
    <div ref={sortableRef} data-level={level} data-id={itemNode.pid}>
      <div>
        <Item<T>
          itemIconPreviewGetter={itemIconPreviewGetter}
          itemOptionsBar={itemOptionsBar}
          notifyItemHovered={notifyItemHovered}
          notifyOpenContextMenu={notifyOpenContextMenu}
          notifyOpenAddViewMenu={notifyAddViewMenuOpened}
          notifyItemClick={notifyItemClick}
          notifyCollapseClick={collapseFolder}
          selected={currentlySelectedItem === itemNode.id}
          collapsed={currentlyCollapsedItems[itemNode.id] ?? false}
          item={itemNode}
        />
        <div
          style={{
            width: '100%',
            position: 'relative',
            display: currentlyCollapsedItems[itemNode.id] ?? false ? 'none' : 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            ref={sortableChildrenRef}
            data-id={itemNode.id}
            data-level={level + 1}
            style={{
              minHeight: '0px',
              marginLeft: spacing + 8 + 'px',
              paddingLeft: '1px',
            }}
          >
            {childrenElems}
          </div>
          <div
            className={styles.aguaItemTreeStructureLeftSeparator}
            style={{
              marginLeft: spacing + 8 + 'px',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export const ItemListMemoized = memo(ItemListComponent, propsAreEqual);
ItemListMemoized.displayName = 'ItemList';

export const ItemList = ItemListMemoized as typeof ItemListComponent;
