import { type PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { type StateType } from '@reducers/store';

export interface GraphData {
  nodes: string[];
  edges: Array<{ source: string; target: string }>;
}

export type ComponentTreeGraphData = GraphData & {
  props: Array<{ name: string; value: any }>;
  isComponent: boolean[];
};

export interface HierarchicalData {
  levelSeparation: number;
  nodeSpacing: number;
  treeSpacing: number;
  direction: 'UD' | 'DU' | 'LR' | 'RL';
  sortMethod: 'hubsize' | 'directed';
  shakeTowards: 'leaves' | 'roots';
  parentCentralization: boolean;
  edgeMinimization: boolean;
  blockShifting: boolean;
}

interface PayloadHierarchical {
  hierarchical: boolean;
  hierarchicalData: HierarchicalData;
}

interface SetupData {
  filename: string;
  entryhtml: string;
  reactRoot: string;
  globalcss: string | null;
  directory: string;
  webpackConfig: string | null;
  tsConfig: string | null;
  excludeNodeModules: boolean;
}

interface ViteSetupData {
  vitePath: string;
  aliases: string[][];
}

interface InitialGraphsState {
  currentGraphTab: string;
  currentFileToShow: string;
  initializedSetup: boolean;
  initializedViteSetup: boolean;
  setup: SetupData;
  viteSetup: ViteSetupData;
  VSpath: string;
  viteIsOn: boolean;
  fontSize: number;
  hierarchical: boolean;
  hierarchicalData: HierarchicalData;
  dependenciesData: GraphData;
  componentTreeData: ComponentTreeGraphData;
}

const initialData: InitialGraphsState = {
  currentGraphTab: 'tree',
  currentFileToShow: '',
  initializedSetup: false,
  initializedViteSetup: false,
  VSpath: '',
  setup: {
    filename: '',
    entryhtml: '',
    reactRoot: '',
    globalcss: null,
    directory: '',
    webpackConfig: null,
    tsConfig: null,
    excludeNodeModules: true,
  },
  viteSetup: {
    vitePath: '',
    aliases: [],
  },
  viteIsOn: false,
  fontSize: 16,
  hierarchical: true,
  hierarchicalData: {
    levelSeparation: 300,
    nodeSpacing: 300,
    treeSpacing: 200,
    direction: 'UD',
    sortMethod: 'hubsize',
    shakeTowards: 'leaves',
    parentCentralization: true,
    edgeMinimization: true,
    blockShifting: true,
  },
  dependenciesData: {
    nodes: [],
    edges: [],
  },
  componentTreeData: {
    nodes: [],
    edges: [],
    isComponent: [],
    props: [],
  },
};

export const fetchDependencies = createAsyncThunk('content/fetchDependencies', async (body: string) => {
  const res = await fetch('http://localhost:4035/dependencies', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  return (await res.json()) as GraphData;
});

export const getVSPath = createAsyncThunk('content/getVSPath', async () => {
  const res = await fetch('http://localhost:4035/fs/path');
  return await res.json();
});

export const GraphsSlice = createSlice({
  name: 'graphs',
  initialState: initialData,
  reducers: {
    setWholeGraphs: (state, action) => {
      const { setup, initializedSetup, viteSetup, initializedViteSetup } = action.payload;
      state.fontSize = initialData.fontSize;
      state.hierarchical = initialData.hierarchical;
      state.hierarchicalData = initialData.hierarchicalData;
      state.setup = setup;
      state.initializedSetup = initializedSetup;
      state.viteSetup = viteSetup;
      state.initializedViteSetup = initializedViteSetup;
    },
    setInitialGraphs: (state, action) => {
      state.fontSize = initialData.fontSize;
      state.hierarchical = initialData.hierarchical;
      state.hierarchicalData = initialData.hierarchicalData;
      state.setup = initialData.setup;
      state.initializedSetup = initialData.initializedSetup;
      state.viteSetup = initialData.viteSetup;
      state.initializedViteSetup = initialData.initializedViteSetup;
      state.VSpath = '';
    },
    setInitialGraphsData: (state, action) => {
      state.fontSize = initialData.fontSize;
      state.hierarchical = initialData.hierarchical;
      state.hierarchicalData = initialData.hierarchicalData;
    },
    setSetup: (state, action: PayloadAction<SetupData>) => {
      const setup = action.payload;
      state.setup = setup;
      state.initializedSetup = true;
    },
    setViteSetup: (state, action: PayloadAction<ViteSetupData>) => {
      const setup = action.payload;
      state.viteSetup = setup;
      state.initializedViteSetup = true;
    },
    setGraphFontSize: (state, action: PayloadAction<number>) => {
      const fontSize = action.payload;
      state.fontSize = fontSize;
    },
    setHierarchical: (state, action: PayloadAction<PayloadHierarchical>) => {
      const { hierarchical, hierarchicalData } = action.payload;
      state.hierarchical = hierarchical;
      state.hierarchicalData = hierarchicalData;
    },
    setDependenciesData: (state, action: PayloadAction<GraphData>) => {
      const data = action.payload;
      state.dependenciesData = data;
    },
    setComponentTreeData: (state, action: PayloadAction<ComponentTreeGraphData>) => {
      const data = action.payload;
      state.componentTreeData = data;
    },
    setCurrentGraphTab: (state, action: PayloadAction<string>) => {
      const data = action.payload;
      state.currentGraphTab = data;
    },
    setCurrentFileToShow: (state, action: PayloadAction<string>) => {
      const data = action.payload;
      state.currentFileToShow = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDependencies.pending, (state) => {});
    builder.addCase(fetchDependencies.fulfilled, (state, action) => {
      state.dependenciesData = action.payload;
    });
    builder.addCase(fetchDependencies.rejected, (state, action) => {});

    builder.addCase(getVSPath.pending, (state) => {});
    builder.addCase(getVSPath.fulfilled, (state, action) => {
      const data = action.payload;
      if (typeof data === 'object' && 'path' in data && data.path != null) {
        state.VSpath = data.path as string;
      }
    });
    builder.addCase(getVSPath.rejected, (state, action) => {});
  },
});

export const getCurrentFileToShow = (state: StateType): string => state.graphs.currentFileToShow;

export const getCurrentGraphTab = (state: StateType): string => state.graphs.currentGraphTab;

export const getGraphFontSize = (state: StateType): number => state.graphs.fontSize;

export const getPath = (state: StateType): string => state.graphs.VSpath;

export const getViteStatus = (state: StateType): boolean => state.graphs.viteIsOn;

export const getViteSetup = createSelector(
  (state: StateType) => state.graphs.viteSetup,
  (state: StateType) => state.graphs.initializedViteSetup,
  (setup, initializedSetup): { setup: ViteSetupData; initializedSetup: boolean } => ({
    setup,
    initializedSetup,
  })
);

export const getSetup = createSelector(
  (state: StateType) => state.graphs.setup,
  (state: StateType) => state.graphs.initializedSetup,
  (setup, initializedSetup): { setup: SetupData; initializedSetup: boolean } => ({
    setup,
    initializedSetup,
  })
);

export const getHierarchical = createSelector(
  (state: StateType) => state.graphs.hierarchical,
  (state: StateType) => state.graphs.hierarchicalData,
  (hierarchical, hierarchicalData): PayloadHierarchical => ({
    hierarchical,
    hierarchicalData,
  })
);

export const getDependenciesData = (state: StateType): GraphData => state.graphs.dependenciesData;
export const getComponentTreeData = (state: StateType): ComponentTreeGraphData =>
  state.graphs.componentTreeData;

export const {
  setWholeGraphs,
  setViteSetup,
  setInitialGraphs,
  setInitialGraphsData,
  setSetup,
  setGraphFontSize,
  setHierarchical,
  setDependenciesData,
  setComponentTreeData,
  setCurrentGraphTab,
  setCurrentFileToShow,
} = GraphsSlice.actions;

export const GraphsReducer = GraphsSlice.reducer;
