import React, { useEffect, useRef, useState } from 'react';

import styles from './styles.module.css';

export const Tooltip = ({
  children,
  text,
  width = 'fit-content',
  height = 'fit-content',
  flex,
  spacing = 8,
  ignoreTop = false,
}: {
  children: React.JSX.Element;
  text: string;
  width?: string;
  height?: string;
  spacing?: number;
  flex?: string;
  ignoreTop?: boolean;
}): React.JSX.Element => {
  const childRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    if (childRef.current != null && tooltipRef.current != null) {
      const bb = childRef.current.getBoundingClientRect();
      const mTop = bb.height;
      tooltipRef.current.style.marginTop = (ignoreTop ? 0 : mTop) + spacing + 'px';
    }
  }, []);

  const checkPos = (): void => {
    if (childRef.current != null && tooltipRef.current != null) {
      const bb = childRef.current.getBoundingClientRect();
      const mTop = bb.height;
      tooltipRef.current.style.marginTop = (ignoreTop ? 0 : mTop) + spacing + 'px';
    }
  };

  return (
    <div
      onPointerEnter={() => {
        if (!isHovered) {
          setIsHovered(true);
          checkPos();
        }
      }}
      onPointerLeave={() => {
        setIsHovered(false);
      }}
      className={styles.tooltipContainer}
      style={{ width, height, flex, display: 'flex' }}
    >
      <div ref={childRef} style={{ width, height }}>
        {children}
      </div>
      <div
        ref={tooltipRef}
        className={styles.tooltip}
        onPointerEnter={() => {
          if (isHovered) setIsHovered(false);
        }}
        style={{
          visibility: isHovered ? 'visible' : 'hidden',
        }}
      >
        {text}
      </div>
    </div>
  );
};
