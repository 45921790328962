// All BarItems have icon
interface BarItemIconProps {
  icon: string;
}

// All BarItems have name and value
interface BarItemBaseProps {
  label: string;
  value: string;
}

export namespace Kinds {
  export type BarItemKindPage = 'page';
  export type BarItemKindButton = 'button';
  export type BarItemKindLink = 'link';

  export type BarItemKinds = BarItemKindPage | BarItemKindButton | BarItemKindLink;

  export const BarItemKindPage: BarItemKindPage = 'page';
  export const BarItemKindButton: BarItemKindButton = 'button';
  export const BarItemKindLink: BarItemKindLink = 'link';
};

interface BarItemDefaultProps extends BarItemBaseProps {
  kind: Kinds.BarItemKindPage | Kinds.BarItemKindButton;
}

interface BarItemLinkProps extends BarItemBaseProps {
  kind: Kinds.BarItemKindLink;
  link: string;
}

export type BarItemProps = BarItemDefaultProps | BarItemLinkProps;
export type BarItemInnerProps = BarItemProps & BarItemIconProps;

type ArrayOfBarItemProps = BarItemInnerProps[];

export interface AppBarItemsProps {
  left: ArrayOfBarItemProps;
  right: ArrayOfBarItemProps;
};
