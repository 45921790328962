import { type ReferencesType, type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  ALIGNMENT,
  BRACKET_ID,
  BRACKET_ID_MINUS,
  BRACKET_NAME,
  CLOSING_PARENTESIS,
  ICON,
  MINUS,
  SRC,
  TEXT,
  UNDERSCORE
} from '../../../constants/constants';
import { type TypeItems } from '@globalTypes/conversor';

export function adaptItemName (name: string, noHyphen: boolean): string {
  name = name.replaceAll(' ', MINUS).replaceAll('(', MINUS);
  name = name.replaceAll(CLOSING_PARENTESIS, MINUS).replace(/(-)+/g, MINUS);
  if (noHyphen) name = name.replaceAll(MINUS, '');
  return name.toLowerCase();
}

export function adaptNameOfElement (item: TypeItemProperties, component: string,
  objectClassnames: Record<string, Array<[string, boolean]>>): string {
  item.name = adaptItemName(item.name, false);
  component = component.replaceAll(BRACKET_NAME, item.name);
  objectClassnames[item.id] = [];
  if (item.nameChanged != null) {
    component = component.replaceAll(BRACKET_ID_MINUS, '');
  }
  component = component.replaceAll(BRACKET_ID, item.id);
  return component;
}

export function getConvertNametoReact (name: string): string {
  const values = name.split(' ');
  let reactName = '';
  for (const value of values) {
    reactName += value.substring(0, 1).toUpperCase() + value.substring(1);
  }
  return reactName;
}

export function getParent (id: string, items: TypeItems, creatingComponent: boolean): string {
  let item = items[id];
  if (creatingComponent) {
    while ('component' in item && 'parent' in item && item.component == null &&
    item.component !== '') {
      item = items[item.parent];
    }
    if ('component' in item && item.component !== '') {
      return getConvertNametoReact(item.component);
    }
  }
  while (item != null && 'parent' in item) item = items[item.parent];
  return getConvertNametoReact(item?.name ?? '');
}

export function referenceValueComponent (item: TypeItemProperties, references: ReferencesType.ReferencesType,
  reference: string): string | boolean {
  if ('references' in item && item.references != null && references[reference] != null) {
    const hasOrientation = references.orientation != null;
    if (reference.includes(TEXT) ||
        reference.includes(SRC) ||
        reference.includes(ICON)) {
      return references[reference][0];
    } else if (reference.includes(ALIGNMENT) && hasOrientation &&
        references.orientation[0].includes(UNDERSCORE)) {
      return references[reference][0];
    } else if (references[reference][0].includes(UNDERSCORE)) {
      return references[reference][0];
    } else return false;
  } else return false;
}

export function referenceValue (reference: string, item: TypeItemProperties,
  creatingComponent: boolean): string | boolean {
  if ('references' in item && item.references != null) {
    const references = item.references;
    if (creatingComponent) {
      return referenceValueComponent(item, references, reference);
    }
    if (reference.includes(TEXT) || reference.includes(ICON)) return false;
    if (item.references != null && references[reference] != null) {
      if (reference.includes(MINUS) && reference.endsWith(MINUS)) {
        return references[reference][0];
      }
      return references[reference][1];
    } else if (item.groupVariables?.[reference] != null) return true;
  }
  return false;
}
