export const IMPORT = 'import';
export const FOR = ' for=';
export const FROM_PAGES = ' from "../pages/';
export const BR = '<br>';
export const ID_EQUAL = 'id=';
export const ON_CLICK = 'onClick={(e) => selectItem(e, "';
export const ON_CLICK_CLOSE = '")}';
export const MAILTO = 'mailto';
export const AGUA_ITEM = '[custom-agua-item="';
export const AGUA_ITEM_CLOSE = '"] {';
export const BEFORE_CONTENT = '"]:before{\ncontent';
export const IMPORT_CUSTOM = 'import customStyles from "./custom';
export const IMPORT_USE = '\nimport { useState, useEffect } from "react";';
export const VARIANT_PAGES = '__VariantPages';
export const GROUP_REFERENCE = 'groupReference';
export const BRACKET_GROUP_REFERENCE = '{referenceGroup}';
export const PROJECT_CONTENT = '{projectContent}';
export const CUSTOM_STYLES_BRACKET = '{customStyles}';
export const IMPORT_CUSTOM_STYLES = 'import customStyles from "./custom{nameStyle}.module.css";';
export const JSX_FORMAT = '.jsx';
export const MODULE_CSS_FORMAT = '.module.css';
export const OPEN_LINK = '<Link';
export const CLOSE_LINK = '</Link>';
export const TEMPLATE = 'Template';
export const SELECT_ITEM = '" == selectedItem ? "selectedItem":"")';
export const OPEN_PARENTESIS_STRING = '("';
export const BRACKET_CLASSNAMES = '{classNames}';
export const PAGE_HAVE_FORM = '{pageHaveForm}';
export const GROUP_CLASSNAMES = 'classNames::';
export const CLASSNAMES_VALUE = 'className={';
export const STYLE_SPACE = 'styles["';
export const CLOSE_STYLE_SPACE = '"]';
export const STYLE_NO_SPACE = 'styles.';
export const EXTERNAL_CLASSNAME = '${';
export const FROM = ' from ';
export const AGUA = 'agua';
export const PAGES = 'pages';
export const BRACKET_NAME = '{name}';
export const BRACKET_ID = '{id}';
export const BRACKET_ID_MINUS = '-{id}';
export const DESTINATION = '{destination}';
export const BRACKET_CONTENT = '{content}';
export const BRACKET_CUSTOM_STYLES = '{customStyles}';
export const BRACKET_TAG = '{tag}';
export const ANTD_FORM = 'AntD Form';
export const PROP_DECLARATION = '={ props.';
export const STYLE_OPEN = 'style={{';
export const STYLE_CLOSE = '}}';
export const MSTYLE_CLOSE = 'Style';
export const REF_BRACKET = '{ref}';
export const PARAMETERS = '{parameters}';
export const FROM_TWO_FOLDERS = 'src/agua/';
export const FUN_OPEN = '() {\n';
export const FUNCTION = 'function ';
export const RETURN_PARENTESIS = 'return(';
export const RETURN_SPACE_PARENTESIS = 'return (';
export const RETURN_BRACKECT = 'return{';
export const RETURN_SPACE_BRACKECT = 'return {';
export const RETURN_SPACE = 'return ';
export const RETURN_LESS = 'return<';
export const RETURN_SPACE_LESS = 'return <';
export const PARENTESIS_BRACKECT = '() }';
export const PAGE_EXTERNAL_FUNCTIONS = '{pageExternalFunctions}';
export const PAGE_EXTERNAL_FILE = '{pageExternalFile}';
export const ANIMATION_IMPORTS = '{animationImports}';
export const HAS_ON_CLICK = '{hasOnclick}';
export const CUSTOMSTYLES_IMPORT = '{customStylesImport}';
export const CUSTOM = 'custom';
export const PAGENAME_BRACKET = '{pagename}';
export const CANVAS_BRACKET = '{canvas}';
export const CANVA_BRACKET = '{canva}';
export const CANVAS_MODE = '{canvasMode}';
export const CANVAS = 'canvas';
export const IMPORT_FORM = 'import { Form } from "antd";';
export const NAME_STYLE = '{nameStyle}';
export const RESPONSIVE_VARIABLES = '{responsiveVariables}';
export const USE_CLIENT = '"use client;"';
export const REACT_OBSERVER = 'import { useInView } from "react-intersection-observer";';
export const LINK = '{link}';
export const IMPORT_REACT_ROUTER = 'import { Link } from "react-router-dom";';
export const IMAGES_VARIABLES = '{imagesVariables}';
export const AGUARENDER = 'aguaRender';
export const ANIMATE_DEPENDENCY = ',\n"animate.css": "^4.1.1"';
export const OBSERVER_DEPENDENCY = ',\n"react-intersection-observer": "^9.5.2"';
export const ANTD_DEPENDENCY = ',\n"antd": "latest"';
export const ANTD = 'antd';
export const DEPENDENCIES = '{dependencies}';
export const PROJECT_NAME = '{projectName}';
export const ROUTES = '{routes}';
export const IMPORTS = '{imports}';
export const FIRST_SCREEN = '{firstScreen}';
export const FROM_AGUA_PAGES = ' from "./agua/pages/';
export const FIRST_SCREEN_IMPORT = '{firstScreenImport}';
export const INITIAL_TEMPLATE = 'initialTemplate';
export const FAV_ICON = 'favicon.png';
export const ICON_BRACKET = '{icon}';
export const LINK_ICON = '\n    <link rel="icon" href="/assets/favicon.png" />';
export const OPEN_SCRIPT = '<script>';
export const CLOSE_SCRIPT = '</script>';
export const SCRIPT = '{script}';
export const INDEX_HTML = 'index.html';
export const INDEX_CSS = 'index.css';
export const WINDOWS_ESC = '{windowESCEvent}';
export const LOGO = 'logo';
export const CANVAS_SPECIAL_CLASSES = `
.layerBorder{
  outline: 1px dashed #2196F3 !important;box-sizing: border-box;
}
.selectedItem{
  outline: 1px solid #2196F3 !important;box-sizing: border-box;
  `;
