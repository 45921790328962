/* eslint-disable max-lines */

/* eslint-disable @typescript-eslint/indent */
import { CSS_POSITION, LAYER, SECTION, TRANSPARENT } from '../../compiler/constants/layerConstants';

import { MVERTICAL } from '@compiler/constants/alignmentOrientationConstants';
import { ALL } from '@compiler/constants/attributeConstants';
import { DEFAULT_COLOR, DEFAULT_COLOR_WHITE } from '@compiler/constants/colorConstants';

import {
  BLUR,
  BORDER,
  BOTTOM,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  COLOR,
  DATA,
  DIRECTION,
  FIX,
  KIND,
  LEFT,
  OFFSET_X,
  OFFSET_Y,
  RADIUS,
  RIGHT,
  SHADOW,
  STYLE,
  TOP,
  TOP_LEFT,
  TOP_RIGHT,
  UNIT_PX,
  WIDTH,
  WRAP,
  Z_INDEX,
} from '@globalTypes/constants';

import {
  type AnimationType,
  type AnimationWhenVisibleType,
  type AspectRatio,
  BackgroundType,
  type ChildrenReferencesType,
  type ClassNamesType, type ClassesType,
  type ClassnameType,
  CommonKindType,
  type CurrentVariantType,
  CursorPointerType,
  type CustomCodeType,
  type CustomCodeVerType,
  type CustomStylesType,
  DimensionType,
  DirectionsType,
  type GroupVariablesType,
  HeightType,
  HorizontalAlignmentType,
  type HrefType,
  IdType,
  IndexType,
  MarginsType,
  type NameChangedType,
  NameType,
  OrientationType,
  PaddingsType,
  type ParameterValuesType,
  ParentType,
  PositionsType,
  type ReferencesType,
  ScrollableType,
  SizeValueType,
  type StateType,
  TagType,
  type VariantType,
  VerticalAlignmentType,
  VisibleType,
  WidthType,
} from './common';

export namespace ComponentType {
  export type ComponentProperty = 'component';
  export const ComponentProperty: ComponentProperty = 'component';
  export type ComponentType = string;
}
export namespace ShadowType {
  export type OffSetXProperty = 'offSetX';
  export const OffSetXProperty: OffSetXProperty = OFFSET_X;
  export type OffSetYProperty = 'offSetY';
  export const OffSetYProperty: OffSetYProperty = OFFSET_Y;
  export type BlurProperty = 'blur';
  export const BlurProperty: BlurProperty = BLUR;
  export type ShadowColorProperty = 'color';
  export const ShadowColorProperty: ShadowColorProperty = COLOR;
  export type ShadowProperty = 'shadow';
  export const ShadowProperty: ShadowProperty = SHADOW;

  export interface ShadowType {
    [OffSetXProperty]: SizeValueType.SizeValueType;
    [OffSetYProperty]: SizeValueType.SizeValueType;
    [BlurProperty]: SizeValueType.SizeValueType;
    [ShadowColorProperty]: string;
  }
}
export namespace FlexWrapType {
  export type FlexWrapProperty = 'wrap';
  export const FlexWrapProperty: FlexWrapProperty = WRAP;

  export type FlexWrapType = boolean;
}
export namespace ZIndexType {
  export type ZIndexProperty = 'zindex';
  export const ZIndexProperty: ZIndexProperty = Z_INDEX;

  export type ZIndexType = string;
}

export namespace CssPositionType {
  export type CssPositionProperty = 'cssPosition';
  export const CssPositionProperty: CssPositionProperty = CSS_POSITION;

  export type CssPositionType = string;
}

export namespace BorderRadiusPartialType {
  export type TopLeftProperty = 'topLeft';
  export const TopLeftProperty: TopLeftProperty = TOP_LEFT;
  export type TopRightProperty = 'topRight';
  export const TopRightProperty: TopRightProperty = TOP_RIGHT;
  export type BottomLeftProperty = 'bottomLeft';
  export const BottomLeftProperty: BottomLeftProperty = BOTTOM_LEFT;
  export type BottomRightProperty = 'bottomRight';
  export const BottomRightProperty: BottomRightProperty = BOTTOM_RIGHT;
  export interface BorderRadiusPartialType {
    [TopLeftProperty]: SizeValueType.SizeValueType;
    [TopRightProperty]: SizeValueType.SizeValueType;
    [BottomLeftProperty]: SizeValueType.SizeValueType;
    [BottomRightProperty]: SizeValueType.SizeValueType;
  }
}

export namespace BorderRadiusType {
  export type BorderRadiusDataProperty = 'data';
  export const BorderRadiusDataProperty: BorderRadiusDataProperty = DATA;
  export type BorderRadiusKindProperty = 'kind';
  export const BorderRadiusKindProperty: BorderRadiusKindProperty = KIND;
  export type BorderRadiusProperty = 'radius';
  export const BorderRadiusProperty: BorderRadiusProperty = RADIUS;

  export interface BorderRadiusTypeAll {
    [BorderRadiusKindProperty]: 'all';
    [BorderRadiusDataProperty]: SizeValueType.SizeValueType;
  }

  export interface BorderRadiusTypePartial {
    [BorderRadiusKindProperty]: 'partial';
    [BorderRadiusDataProperty]: BorderRadiusPartialType.BorderRadiusPartialType;
  }

  export type BorderRadiusType = BorderRadiusTypeAll | BorderRadiusTypePartial;
}

export namespace BorderDirectionDataType {
  export type BorderDirectionDataStyleProperty = 'style';
  export const BorderDirectionDataStyleProperty: BorderDirectionDataStyleProperty = STYLE;
  export type BorderDirectionDataColorProperty = 'color';
  export const BorderDirectionDataColorProperty: BorderDirectionDataColorProperty = COLOR;
  export type BorderDirectionDataWidthProperty = 'width';
  export const BorderDirectionDataWidthProperty: BorderDirectionDataWidthProperty = WIDTH;
  export interface BorderDirectionDataType {
    [BorderDirectionDataColorProperty]: string;
    [BorderDirectionDataWidthProperty]: SizeValueType.SizeValueType;
  }
}

export namespace BorderDirectionType {
  export type BorderTopProperty = 'top';
  export const BorderTopProperty: BorderTopProperty = TOP;
  export type BorderBottomProperty = 'bottom';
  export const BorderBottomProperty: BorderBottomProperty = BOTTOM;
  export type BorderLeftProperty = 'left';
  export const BorderLeftProperty: BorderLeftProperty = LEFT;
  export type BorderRightProperty = 'right';
  export const BorderRightProperty: BorderRightProperty = RIGHT;
  export type BorderDirectionProperty = 'direction';
  export const BorderDirectionProperty: BorderDirectionProperty = DIRECTION;

  export interface BorderDirectionType {
    [BorderTopProperty]: BorderDirectionDataType.BorderDirectionDataType;
    [BorderBottomProperty]: BorderDirectionDataType.BorderDirectionDataType;
    [BorderLeftProperty]: BorderDirectionDataType.BorderDirectionDataType;
    [BorderRightProperty]: BorderDirectionDataType.BorderDirectionDataType;
  }
}

export namespace BorderDataType {
  export type BorderDataStyleProperty = 'style';
  export const BorderDataStyleProperty: BorderDataStyleProperty = STYLE;
  export type BorderDataColorProperty = 'color';
  export const BorderDataColorProperty: BorderDataColorProperty = COLOR;
  export type BorderDataWidthProperty = 'width';
  export const BorderDataWidthProperty: BorderDataWidthProperty = WIDTH;
  export type BorderDataRadiusProperty = 'radius';
  export const BorderDataRadiusProperty: BorderDataRadiusProperty = RADIUS;

  export interface BorderDataTypeAll {
    [BorderDataStyleProperty]: string;
    [BorderDataColorProperty]: string;
    [BorderDataWidthProperty]: SizeValueType.SizeValueType;
    [BorderDataRadiusProperty]: BorderRadiusType.BorderRadiusType;
  }

  export interface BorderDataTypePartial {
    [BorderDirectionType.BorderDirectionProperty]: BorderDirectionType.BorderDirectionType;
    [BorderRadiusType.BorderRadiusProperty]: BorderRadiusType.BorderRadiusType;
    [BorderDataStyleProperty]: string;
  }
}

export namespace BorderType {
  export type BorderProperty = 'border';
  export const BorderProperty: BorderProperty = BORDER;

  export type BorderKindProperty = 'kind';
  export const BorderKindProperty: BorderKindProperty = KIND;
  export type BorderData = 'data';
  export const BorderData: BorderData = DATA;

  export interface BorderAll {
    [BorderKindProperty]: 'all';
    [BorderData]: BorderDataType.BorderDataTypeAll;
  }

  export interface BorderPartial {
    [BorderKindProperty]: 'partial';
    [BorderData]: BorderDataType.BorderDataTypePartial;
  }

  export type BorderType = BorderAll | BorderPartial;
}

export namespace ExternalFileType {
  export type ExternalFileProperty = 'externalFile';
  export const ExternalFileProperty: ExternalFileProperty = 'externalFile';

  export type ExternalFileType = boolean;
}

export namespace ExternalFunctionType {
  export type ExternalFunctionProperty = 'externalFunction';
  export const ExternalFunctionProperty: ExternalFunctionProperty = 'externalFunction';

  export type ExternalFunctionType = boolean;
}

export interface LayerProperties {
  paddingsLocked?: boolean;
  marginsLocked?: boolean;
  [AspectRatio.AspectRatioProperty]?: AspectRatio.AspectRatioType;
  [ComponentType.ComponentProperty]: ComponentType.ComponentType;
  [WidthType.WidthProperty]: WidthType.WidthType;
  [HeightType.HeightProperty]: HeightType.HeightType;
  [MarginsType.MarginsProperty]: MarginsType.MarginsType;
  [CommonKindType.CommonKindProperty]: 'layer';
  [IdType.IdProperty]: IdType.IdType;
  [TagType.TagProperty]: TagType.TagType;
  [BackgroundType.BackgroundProperty]: BackgroundType.BackgroundType;
  [NameType.NameProperty]: NameType.NameType;
  [CursorPointerType.CursorPointerProperty]: CursorPointerType.CursorPointerType;
  [VisibleType.VisibleProperty]: VisibleType.VisibleType;
  [ParentType.ParentProperty]: ParentType.ParentType;
  [IndexType.IndexProperty]: IndexType.IndexType;
  [HrefType.HrefProperty]?: HrefType.HrefType;
  [AnimationType.AnimationProperty]?: AnimationType.AnimationType;
  [AnimationWhenVisibleType.AnimationWhenVisibleProperty]?: AnimationWhenVisibleType.AnimationWhenVisibleType;
  [ClassesType.ClassesProperty]?: ClassesType.ClassesType;
  [ClassnameType.ClassnameProperty]?: ClassnameType.ClassnameType;
  [CustomStylesType.CustomStylesProperty]?: CustomStylesType.CustomStylesType;
  [PaddingsType.PaddingsProperty]: PaddingsType.PaddingsType;
  [PositionsType.PositionsProperty]: Partial<PositionsType.PositionsType>;
  [VerticalAlignmentType.VerticalAlignmentProperty]: VerticalAlignmentType.VerticalAlignmentType;
  [HorizontalAlignmentType.HorizontalAlignmentProperty]: HorizontalAlignmentType.HorizontalAlignmentType;
  [OrientationType.OrientationProperty]: OrientationType.OrientationType;
  [ShadowType.ShadowProperty]: ShadowType.ShadowType;
  [ScrollableType.ScrollableProperty]: ScrollableType.ScrollableType;
  [FlexWrapType.FlexWrapProperty]: FlexWrapType.FlexWrapType;
  [ZIndexType.ZIndexProperty]?: ZIndexType.ZIndexType;
  [BorderType.BorderProperty]: BorderType.BorderType;
  [ClassNamesType.ClassNamesProperty]?: ClassNamesType.ClassNamesType;
  [CssPositionType.CssPositionProperty]?: CssPositionType.CssPositionType;
  [NameChangedType.NameChangedProperty]?: NameChangedType.NameChangedType;
  [ReferencesType.ReferencesProperty]?: ReferencesType.ReferencesType;
  [GroupVariablesType.GroupVariablesProperty]?: GroupVariablesType.GroupVariablesType;
  [ChildrenReferencesType.ChildrenReferencesProperty]?: ChildrenReferencesType.ChildrenReferencesType;
  [ParameterValuesType.ParameterValuesProperty]?: ParameterValuesType.ParameterValuesType;
  [CustomCodeType.CustomCodeProperty]?: CustomCodeType.CustomCodeType;
  [CustomCodeVerType.CustomCodeProperty]?: CustomCodeVerType.CustomCodeType;
  [VariantType.VariantProperty]?: VariantType.VariantType;
  [CurrentVariantType.CurrentVariantProperty]?: CurrentVariantType.CurrentVariantType;
  [StateType.StateProperty]?: StateType.StateType;
  [ExternalFileType.ExternalFileProperty]?: ExternalFileType.ExternalFileType;
  [ExternalFunctionType.ExternalFunctionProperty]?: ExternalFunctionType.ExternalFunctionType;
}

export const DefaultLayer: LayerProperties = {
  [ComponentType.ComponentProperty]: '',
  [WidthType.WidthProperty]: {
    [DimensionType.DimensionKindProperty]: FIX,
    [DimensionType.DimensionDataProperty]: {
      [SizeValueType.SizeValueProperty]: 100,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [HeightType.HeightProperty]: {
    [DimensionType.DimensionKindProperty]: FIX,
    [DimensionType.DimensionDataProperty]: {
      [SizeValueType.SizeValueProperty]: 100,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [MarginsType.MarginsProperty]: {
    [DirectionsType.TopProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.BottomProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.LeftProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.RightProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [CommonKindType.CommonKindProperty]: LAYER,
  [IdType.IdProperty]: '0',
  [TagType.TagProperty]: SECTION,
  [BackgroundType.BackgroundProperty]: {
    [BackgroundType.BackgroundKindProperty]: TRANSPARENT,
    [BackgroundType.BackgroundDataProperty]: TRANSPARENT,
  },
  [NameType.NameProperty]: LAYER,
  [CursorPointerType.CursorPointerProperty]: 'default',
  [VisibleType.VisibleProperty]: true,
  [ParentType.ParentProperty]: '0',
  [IndexType.IndexProperty]: 0,
  [PaddingsType.PaddingsProperty]: {
    [DirectionsType.TopProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.BottomProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.LeftProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.RightProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [PositionsType.PositionsProperty]: {},
  [VerticalAlignmentType.VerticalAlignmentProperty]: TOP,
  [HorizontalAlignmentType.HorizontalAlignmentProperty]: LEFT,
  [OrientationType.OrientationProperty]: MVERTICAL,
  [ShadowType.ShadowProperty]: {
    [ShadowType.OffSetXProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [ShadowType.OffSetYProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [ShadowType.BlurProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [ShadowType.ShadowColorProperty]: DEFAULT_COLOR_WHITE,
  },
  [ScrollableType.ScrollableProperty]: 'hidden',
  [FlexWrapType.FlexWrapProperty]: false,
  [BorderType.BorderProperty]: {
    [BorderType.BorderKindProperty]: ALL,
    [BorderType.BorderData]: {
      [BorderDataType.BorderDataStyleProperty]: 'solid',
      [BorderDataType.BorderDataColorProperty]: DEFAULT_COLOR,
      [BorderDataType.BorderDataWidthProperty]: {
        [SizeValueType.SizeValueProperty]: 0,
        [SizeValueType.SizeUnitProperty]: UNIT_PX,
      },
      [BorderRadiusType.BorderRadiusProperty]: {
        [BorderRadiusType.BorderRadiusKindProperty]: ALL,
        [BorderRadiusType.BorderRadiusDataProperty]: {
          [SizeValueType.SizeValueProperty]: 0,
          [SizeValueType.SizeUnitProperty]: UNIT_PX,
        },
      },
    },
  },
};
