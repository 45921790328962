import { type PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { Conversor } from '@compiler/conversor';

import { type StateType } from '@reducers/store';

import { type ExtractedComponent } from '@globalUtils/extractor';

import type { IFiles, IScriptType, TypeComponentData } from '@globalTypes/conversor';

import { GRADIENTS } from '@constants/gradients';

export type IScreens = Record<string, string>;

export const mConversor: Conversor = new Conversor('react');

export const getComponentByName = (
  name: string,
  components: Record<string, TypeComponentData>,
  loadedComponents: ExtractedComponent[]
): TypeComponentData | ExtractedComponent | null => {
  let componentData = null;
  for (const id in components) {
    if (components[id].name === name) componentData = components[id];
  }
  let compoLoaded;
  if (loadedComponents != null) compoLoaded = loadedComponents.find((compo) => compo.id === name);
  if (compoLoaded != null) return compoLoaded;
  return componentData;
};

const RECENT_COLORS_LENGTH = 12;

type IRecentColors = Record<string, number>;
const recentColors: IRecentColors = GRADIENTS;

export interface ConversorData {
  script: IScriptType;
  files: IFiles;
  isPreview: boolean;
  components: Record<string, TypeComponentData>;
  loadedComponents: ExtractedComponent[];
}

export const renderFileRecursively = (mFiles: Record<string, any>, depth: number, fullname: string): any => {
  let mResult: Record<string, any> = {};
  for (const mFile in mFiles) {
    const file = mFiles[mFile];
    const mType = typeof file;
    if (mType === 'object') {
      const mRecursiveResult = renderFileRecursively(file, depth + 1, fullname + '/' + mFile);
      mResult = { ...mResult, ...mRecursiveResult };
    } else {
      mResult[
        (fullname + '/' + mFile).charAt(0) === '/'
          ? (fullname + '/' + mFile).substring(1)
          : fullname + '/' + mFile
      ] = file;
    }
  }
  return mResult;
};

const foldedItems: Record<string, boolean> = {};

export interface UserData {
  companySize: string;
  email: string;
  filled: boolean;
  name: string;
  lastName: string;
  phone: string;
  role: string;
}

interface InitialEditorState {
  userData: null | UserData;
  currentUserID: string;
  mainMenuTab: string;
  customCSS: string;
  customScript: string;
  desktopBreakpoint: number;
  phoneBreakpoint: number;
  desktopViewport: [number, number];
  tabletViewport: [number, number];
  phoneViewport: [number, number];
  selectedItem: string;
  pressedESC: boolean;
  XRays: boolean;
  selectedScreen: string;
  selectedProjectTab: string;
  foldedItems: Record<string, boolean>;
  recentColors: Record<string, number>;
  iframePreviewData: string[];
}

const initialData: InitialEditorState = {
  userData: null,
  currentUserID: '',
  mainMenuTab: 'home',
  customCSS: '',
  customScript: '',
  desktopBreakpoint: 1280,
  phoneBreakpoint: 480,
  desktopViewport: [1536, 864],
  tabletViewport: [1024, 1366],
  phoneViewport: [430, 932],
  selectedItem: '',
  pressedESC: false,
  XRays: false,
  selectedScreen: '',
  selectedProjectTab: 'layers',
  foldedItems,
  recentColors,
  iframePreviewData: [],
};

export const EditorSlice = createSlice({
  name: 'editor',
  initialState: initialData,
  reducers: {
    setCurrentUserID: (state, action: PayloadAction<string>) => {
      state.currentUserID = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    setInitialEditor: (state, action) => {
      state.desktopBreakpoint = 1280;
      state.phoneBreakpoint = 480;
      state.desktopViewport = [1536, 864];
      state.tabletViewport = [1024, 1366];
      state.phoneViewport = [430, 932];
      state.selectedItem = '';
      state.pressedESC = false;
      state.XRays = false;
      state.selectedScreen = '24011984';
      state.selectedProjectTab = 'layers';
      state.foldedItems = {};
      state.recentColors = GRADIENTS;
      state.customCSS = '';
      state.customScript = '';
      state.mainMenuTab = 'home';
    },
    setWholeEditor: (state, action) => {
      const {
        desktopBreakpoint,
        phoneBreakpoint,
        desktopViewport,
        tabletViewport,
        phoneViewport,
        selectedItem,
        pressedESC,
        XRays,
        selectedScreen,
        selectedProjectTab,
        foldedItems,
        recentColors,
        customCSS,
        customScript,
      } = action.payload;
      state.desktopBreakpoint = desktopBreakpoint;
      state.phoneBreakpoint = phoneBreakpoint;
      state.desktopViewport = desktopViewport;
      state.tabletViewport = tabletViewport;
      state.phoneViewport = phoneViewport;
      state.selectedItem = selectedItem;
      state.pressedESC = pressedESC;
      state.XRays = XRays;
      state.selectedScreen = selectedScreen;
      state.selectedProjectTab = selectedProjectTab;
      state.foldedItems = foldedItems;
      state.recentColors = recentColors;
      state.customCSS = customCSS;
      state.customScript = customScript;
    },
    setMainMenuTab: (state, action: PayloadAction<string>) => {
      state.mainMenuTab = action.payload;
    },
    setCustomCSS: (state, action: PayloadAction<string>) => {
      state.customCSS = action.payload;
    },
    setCustomScript: (state, action: PayloadAction<string>) => {
      state.customScript = action.payload;
    },
    pressESC: (state, action: PayloadAction<boolean>) => {
      const pressed = action.payload;
      state.pressedESC = pressed;
    },
    XRays: (state, action: PayloadAction<boolean>) => {
      const pressed = action.payload;
      state.XRays = pressed;
    },
    toggleFoldItem: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const fold = state.foldedItems[id];
      if (fold == null) state.foldedItems[id] = true;
      else state.foldedItems[id] = !fold;
    },
    selectItem: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      let res = '';
      if (itemId !== state.selectedItem) res = itemId;
      state.selectedItem = res;
      if (res === '') return;
      const parent = document.querySelector('.itemsPane');
      const item = document.querySelector('*[data-id="' + res + '"]');
      if (parent == null) return;
      if (item == null) return;
      const bp = parent.getBoundingClientRect();
      const tp = bp.top;
      const hp = bp.height;
      const bi = item.getBoundingClientRect();
      const ti = bi.top - tp;
      const sp = parent.scrollTop;
      if (ti > hp) {
        parent.scrollTop = Math.max(sp + ti - hp / 2, 0);
      } else if (ti < 0) {
        parent.scrollTop = Math.max(sp + ti - hp / 2, 0);
      }
    },
    selectProjectTab: (state, action: PayloadAction<string>) => {
      const tab = action.payload;
      state.selectedProjectTab = tab;
    },
    registerColor: (state, action: PayloadAction<string>) => {
      if (action.payload === '') return;
      state.recentColors[action.payload] = Date.now();
    },
    updateIframe: (state, action: PayloadAction<ConversorData>) => {
      const data = action.payload;
      const res = mConversor.renderCanvas(data.script, data.files, data.isPreview, {
        components: data.components,
        getComponentByName,
      });
      if (res == null) return;
      const mFiles = renderFileRecursively(res, 0, '');
      const mPack = JSON.parse(mFiles['package.json']);
      const mDependencies = mPack.dependencies;
      const mFilesString = JSON.stringify(mFiles);
      const mDependenciesString = JSON.stringify(mDependencies);
      state.iframePreviewData = [mFilesString, mDependenciesString, 'react'];
    },
    updateComponentFrame: (state, action: PayloadAction<ConversorData>) => {
      const data = action.payload;
      const res = mConversor.renderComponent(data.script, data.files, {
        components: data.components,
        getComponentByName,
      });
      if (res == null) return;
      const mFiles = renderFileRecursively(res, 0, '');
      const mPack = JSON.parse(mFiles['package.json']);
      const mDependencies = mPack.dependencies;
      const mFilesString = JSON.stringify(mFiles);
      const mDependenciesString = JSON.stringify(mDependencies);
      // state.iframePreviewData = [mFilesString, mDependenciesString, 'react'];
    },
    setSelectedScreen: (state, action: PayloadAction<string>) => {
      state.selectedScreen = action.payload;
    },
  },
});

export const getMainMenuTab = (state: StateType): string => state.editor.mainMenuTab;

export const getIframeData = createSelector(
  (state: StateType) => state.editor,
  (editor) => editor.iframePreviewData
);

export const getRecentColors = createSelector(
  (state: StateType) => state.editor.recentColors,
  (colors) => {
    const entries: Array<[string, number]> = Object.entries(colors);
    const marr = entries.map((col) => ({ hex: col[0], time: col[1] }));
    const sorted = marr.sort((a, b) => b.time - a.time);
    const justColors = sorted.map((col) => col.hex);
    return justColors.slice(0, Math.min(RECENT_COLORS_LENGTH, justColors.length));
  }
);

export const getScreens = (state: StateType): IScreens => {
  const screens: IScreens = {};
  const items = state.items.items.byId;
  for (const itemID in items) {
    const item = items[itemID];
    if (item.commonKind === 'screen') {
      screens[item.id] = item.name;
    }
  }
  return screens;
};

export const getCurrentUserID = (state: StateType): string => state.editor.currentUserID;
export const getUserData = (state: StateType): UserData | null => state.editor.userData;

export const getCustomCSS = (state: StateType): string => state.editor.customCSS;
export const getCustomScript = (state: StateType): string => state.editor.customScript;
export const getSelectedItem = (state: StateType): any => state.editor.selectedItem;
export const getPressedESC = (state: StateType): any => state.editor.pressedESC;
export const getXRays = (state: StateType): any => state.editor.XRays;

export const getDesktopBreakpoint = (state: StateType): number => state.editor.desktopBreakpoint;
export const getPhoneBreakpoint = (state: StateType): number => state.editor.phoneBreakpoint;
export const getDesktopViewport = (state: StateType): number[] => state.editor.desktopViewport;
export const getTabletViewport = (state: StateType): number[] => state.editor.tabletViewport;
export const getPhoneViewport = (state: StateType): number[] => state.editor.phoneViewport;
export const getCurrentSelectedScreen = (state: StateType): string => state.editor.selectedScreen;
export const getSelectedProjectTab = (state: StateType): string => state.editor.selectedProjectTab;
export const getItemsFolded = (state: StateType): Record<string, boolean> => state.editor.foldedItems;
export const getItemFolded = (state: StateType, id: string): boolean => {
  return state.editor.foldedItems[id] ?? false;
};

export const {
  setInitialEditor,
  setWholeEditor,
  setMainMenuTab,
  selectItem,
  registerColor,
  updateIframe,
  setSelectedScreen,
  pressESC,
  XRays,
  selectProjectTab,
  toggleFoldItem,
  setCustomCSS,
  setCustomScript,
  setCurrentUserID,
  setUserData,
} = EditorSlice.actions;

export const EditorReducer = EditorSlice.reducer;
