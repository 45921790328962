import React, { memo, useEffect, useState } from 'react';

import { type IModal, type IModalContent } from '@modals/baseModal';

import { Icon } from '@components/icon';

import styles from './styles.module.css';

const arePropsEqual = <T,>(oldProps: ItemProps<T>, newProps: ItemProps<T>): boolean => {
  if (oldProps.item.id !== newProps.item.id) return false;
  if (oldProps.item.kind !== newProps.item.kind) return false;
  if (oldProps.item.name !== newProps.item.name) return false;
  if (oldProps.selected !== newProps.selected) return false;
  if (oldProps.collapsed !== newProps.collapsed) return false;
  return true;
};

export interface ItemSummary {
  id: string;
  pid: string;
  kind: string;
  name: string;
  otherData?: string;
  collapsable: boolean;
}

export interface BaseOptionBar {
  id: string;
}

export interface ItemOptionBar<T> {
  id: string;
  content: React.JSX.Element;
  type: 'add' | 'context' | 'other';
  shouldRender?: (item: ItemSummary) => boolean;
  onClick?: (position: [number, number], offset: number, item: ItemSummary) => any;
  modal?: {
    modalTitle?: string;
    modalWrapper: React.FC<IModal<T>>;
    modalContent: React.FC<IModalContent<T>>;
    newValueReceivedCallback?: (val: T, item: ItemSummary) => any;
  };
}

export type ItemOptionsBar<T> = Array<ItemOptionBar<T>>;

interface ItemProps<T> {
  item: ItemSummary;
  collapsed: boolean;
  selected: boolean;
  notifyItemClick: (id: string) => any;
  notifyCollapseClick?: (id: string) => any;
  notifyOpenContextMenu: (item: ItemSummary, position: [number, number], offset: number) => any;
  notifyOpenAddViewMenu: (item: ItemSummary, position: [number, number], offset: number) => any;
  notifyItemHovered: (id: string) => any;
  itemOptionsBar?: ItemOptionsBar<T>;
  itemIconPreviewGetter: (item: ItemSummary) => React.JSX.Element;
}

export const ItemComponent = <T,>({
  item,
  collapsed,
  notifyItemClick,
  selected,
  notifyCollapseClick,
  notifyOpenContextMenu,
  notifyOpenAddViewMenu,
  notifyItemHovered,
  itemOptionsBar,
  itemIconPreviewGetter,
}: ItemProps<T>): React.JSX.Element => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [showingModal, setShowingModal] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (hovered) {
      notifyItemHovered(item.id);
    }
  }, [hovered]);

  const handleCollapseClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    if (notifyCollapseClick != null) notifyCollapseClick(item.id);
  };

  return (
    <>
      <div
        data-id={item.id}
        style={{ marginLeft: '1px', border: '1px solid transparent', position: 'relative' }}
      >
        <div
          onClick={() => {
            notifyItemClick(item.id);
          }}
          onPointerEnter={() => {
            setHovered(true);
          }}
          onPointerLeave={() => {
            setHovered(false);
          }}
          className={styles.item}
          style={{
            border: '1px solid transparent',
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
              zIndex: 2,
            }}
          >
            {item.collapsable && (
              <div
                className={styles.foldableIconContainer}
                onClick={(e) => {
                  handleCollapseClick(e);
                }}
              >
                <Icon
                  icon={collapsed ? 'chevron_right' : 'expand_more'}
                  color="var(--title-color)"
                  iconSize="large"
                />
              </div>
            )}
            <div
              style={{
                marginLeft: '2px',
              }}
            >
              {itemIconPreviewGetter(item)}
            </div>
            <div
              style={{
                marginLeft: 'var(--padding-s)',
                fontSize: 'var(--font-size-body)',
                whiteSpace: 'nowrap',
                marginTop: '-2px',
              }}
            >
              {item.name}
            </div>
            <div
              className={`${styles.itemOptionsContainer} ${
                selected ? styles.itemOptionsContainerSelected : styles.itemOptionsContainerNotSelected
              }`}
            >
              {itemOptionsBar?.map((option) => {
                const filter = option.shouldRender;
                if (filter == null || (filter != null && filter(item))) {
                  return (
                    <div
                      key={option.id}
                      className={styles.itemOptions}
                      onClick={(e) => {
                        const copy = JSON.parse(JSON.stringify(showingModal));
                        copy[option.id] = true;
                        setShowingModal(copy);
                        e.stopPropagation();
                        const elem: any = e.target;
                        const bb = elem.getBoundingClientRect();
                        const position: [number, number] = [bb.left + bb.width + 2, bb.top - 5];
                        const offset = bb.height - 1;
                        if (option.type === 'add') {
                          notifyOpenAddViewMenu(item, position, offset * 2 - 2);
                        } else if (option.type === 'context') {
                          notifyOpenContextMenu(item, position, offset * 2 - 2);
                        }
                        if (option.onClick != null) {
                          option.onClick(position, offset, item);
                        }
                      }}
                    >
                      {option.content}
                    </div>
                  );
                }
                return <React.Fragment key={option.id} />;
              })}
            </div>
          </div>
          <div
            className={styles.itemShadow}
            style={{
              display: hovered || selected ? 'flex' : 'none',
              border: selected ? '1px solid var(--border-color)' : '1px solid transparent',
              backgroundColor: selected
                ? 'var(--input-field-background-color)'
                : hovered
                  ? 'var(--background-color-1)'
                  : 'transparent',
            }}
          ></div>
        </div>
      </div>
      {itemOptionsBar?.map((option, i) => {
        if (option.modal != null) {
          const Modal = option.modal.modalWrapper;
          return (
            <div
              key={option.id}
              style={{
                display: showingModal[option.id] ?? false ? 'block' : 'none',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 10,
              }}
            >
              <Modal
                open={showingModal[option.id] ?? false}
                notifyModalIsClosed={() => {
                  const copy = JSON.parse(JSON.stringify(showingModal));
                  copy[option.id] = false;
                  setShowingModal(copy);
                }}
                modalTitle={option.modal.modalTitle}
                modalContent={option.modal.modalContent}
              />
            </div>
          );
        }
        return <React.Fragment key={option.id} />;
      })}
    </>
  );
};

export const ItemMemoized = memo(ItemComponent, arePropsEqual);
ItemMemoized.displayName = 'Item';

export const Item = ItemMemoized as typeof ItemComponent;
