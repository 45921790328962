/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-base-to-string */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { HeaderText } from '@components/headerText';

import { getAssets } from '@reducers/files';

import { isMedia } from '@globalUtils/strings';

import styles from './styles.module.css';

export const AssetPreview = ({ assetId }: { assetId: string }): React.JSX.Element => {
  const { t } = useTranslation();

  const assets = useSelector(getAssets);
  const parts = assetId.split('-');
  const splitted = parts.slice(1);
  const name = splitted.join('-');
  const currentAsset = assets.find((asset) => asset.name === name);

  const kind = isMedia(name) ? 'font' : 'image';

  return (
    <div className={styles.container}>
      {currentAsset == null && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--background-color-1)',
          }}
        >
          <img src="/agua-vs.png" style={{ height: '40%' }}></img>
        </div>
      )}
      {currentAsset != null && (
        <div
          style={{
            width: '100%',
            height: '100%',
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            padding: 'var(--padding-m)',
            alignItems: 'center',
          }}
        >
          <HeaderText>{kind === 'image' ? t('image-pane-label') : t('font-pane-label')}</HeaderText>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {name.endsWith('.svg') ? (
              <div style={{ width: '300px' }} dangerouslySetInnerHTML={{ __html: currentAsset.data }}></div>
            ) : (
              <img
                className={styles.imageView}
                src={name.endsWith('.svg') ? currentAsset.data : 'data:image/gif;base64,' + currentAsset.data}
                alt={currentAsset.name}
              ></img>
            )}

            <div className={styles.text}>{currentAsset.name}</div>
          </div>
        </div>
      )}
    </div>
  );
};
