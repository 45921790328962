import { type TypeReactAttributeReferences } from '../../conversor/reactTypes';
import { type TypeCustomCode } from '../../conversor/componentInfo';

export namespace ReferencesType {
  export type ReferencesProperty = 'references';
  export const ReferencesProperty: ReferencesProperty = 'references';

  export type ReferencesType = TypeReactAttributeReferences;
}

export namespace ChildrenReferencesType {
  export type ChildrenReferencesProperty = 'childrenReferences';
  export const ChildrenReferencesProperty: ChildrenReferencesProperty = 'childrenReferences';

  export type ChildrenReferencesType =
    Array<[string, string, string, string | undefined, string, (string | undefined)?]>;
}

export namespace GroupVariablesType {
  export type GroupVariablesProperty = 'groupVariables';
  export const GroupVariablesProperty: GroupVariablesProperty = 'groupVariables';

  export type GroupVariablesType = TypeReactAttributeReferences;
}

export namespace ParameterValuesType {
  export type ParameterValuesProperty = 'parameterValues';
  export const ParameterValuesProperty: ParameterValuesProperty = 'parameterValues';

  export type ParameterValuesType = Record<string, string>;
}

export namespace CustomCodeType {
  export type CustomCodeProperty = 'code';
  export const CustomCodeProperty: CustomCodeProperty = 'code';

  export type CustomCodeType = TypeCustomCode;
}

export namespace CustomCodeVerType {
  export type CustomCodeProperty = 'customcode';
  export const CustomCodeProperty: CustomCodeProperty = 'customcode';

  export type CustomCodeType = boolean;
}
