import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { COLOR_CHARS, SCREEN, UNDERSCORE } from '../../constants/constants';
import { type TypeAttributeReferences, type TypeItems } from '@globalTypes/conversor';

export function isColor (value: string): boolean {
  if (value.length !== 6) return false;
  for (let i = 0; i < 6; i++) {
    if (!COLOR_CHARS.includes(value[i])) return false;
  }
  return true;
}

export function getPage (variable: string, references: TypeAttributeReferences,
  items: TypeItems): TypeItemProperties | boolean {
  let id = variable.split(UNDERSCORE)[0];
  if (references.Responsiveness[variable] != null) {
    for (const itemid in references.Responsiveness[variable]) {
      id = itemid;
    }
  }
  let item = items[id];
  if (item == null) return false;
  while (item.commonKind !== SCREEN && 'parent' in item) {
    item = items[item.parent];
  }
  return item;
}
