import React from 'react';

import styles from './styles.module.css';

export const HeaderText = ({
  children,
  bold = true,
  color = 'var(--title-color)',
  fontSize = '',
}: {
  children: string;
  bold?: boolean;
  color?: string;
  fontSize?: string;
}): React.JSX.Element => {
  return (
    <div style={{ fontWeight: bold ? 500 : 400, color, fontSize }} className={styles.text}>
      {children}
    </div>
  );
};
