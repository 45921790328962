export const KeySymbols = {
  meta: '\u{2318}',
  ctrl: 'Ctrl',
  backspace: '\u{232B}',
  shift: '\u{21E7}',
  alt: 'Alt',
  control: '\u{2325}',
  arrowdown: '\u{2193}',
  arrowup: '\u{2191}',
  arrowleft: '\u{2190}',
  arrowright: '\u{2192}',
  enter: '\u{23CE}',
};

export const Keys = {
  cmd: 'meta',
  ctrl: 'ctrl',
  alt: 'alt',
  delete: 'backspace',
  shift: 'shift',
  option: 'alt',
  control: 'control',
  enter: 'enter',
  left: 'arrowleft',
  right: 'arrowright',
  up: 'arrowup',
  down: 'arrowdown',
};

export const KeyBindings = {
  enter: {
    windows: [Keys.enter],
    mac: [Keys.enter],
  },
  copy: {
    windows: [Keys.ctrl, 'C'],
    mac: [Keys.cmd, 'C'],
  },
  paste: {
    windows: [Keys.ctrl, 'V'],
    mac: [Keys.cmd, 'V'],
  },
  duplicate: {
    windows: [Keys.ctrl, 'D'],
    mac: [Keys.cmd, 'D'],
  },
  cut: {
    windows: [Keys.ctrl, 'X'],
    mac: [Keys.cmd, 'X'],
  },
  copyStyle: {
    windows: [Keys.ctrl, Keys.shift, 'C'],
    mac: [Keys.cmd, Keys.shift, 'C'],
  },
  pasteStyle: {
    windows: [Keys.ctrl, Keys.shift, 'V'],
    mac: [Keys.cmd, Keys.shift, 'V'],
  },
  delete: {
    windows: [Keys.delete],
    mac: [Keys.delete],
  },
  rename: {
    windows: [Keys.ctrl, 'R'],
    mac: [Keys.cmd, 'R'],
  },
  undo: {
    windows: [Keys.ctrl, 'Z'],
    mac: [Keys.cmd, 'Z'],
  },
  save: {
    windows: [Keys.ctrl, 'S'],
    mac: [Keys.cmd, 'S'],
  },
  selectParent: {
    windows: [Keys.ctrl, Keys.left],
    mac: [Keys.cmd, Keys.left],
  },
  selectChild: {
    windows: [Keys.ctrl, Keys.right],
    mac: [Keys.cmd, Keys.right],
  },
  selectItemBelow: {
    windows: [Keys.ctrl, Keys.down],
    mac: [Keys.cmd, Keys.down],
  },
  selectItemAbove: {
    windows: [Keys.ctrl, Keys.up],
    mac: [Keys.cmd, Keys.up],
  },
  itemWidthFill: {
    windows: [Keys.shift, Keys.alt, 'W'],
    mac: [Keys.shift, Keys.alt, 'W'],
  },
  itemWidthWrap: {
    windows: [Keys.alt, 'W'],
    mac: [Keys.alt, 'W'],
  },
  itemHeightFill: {
    windows: [Keys.shift, Keys.alt, 'H'],
    mac: [Keys.shift, Keys.alt, 'H'],
  },
  itemHeightWrap: {
    windows: [Keys.alt, 'H'],
    mac: [Keys.alt, 'H'],
  },
  itemBold: {
    windows: [Keys.ctrl, 'B'],
    mac: [Keys.cmd, 'B'],
  },
  itemItalic: {
    windows: [Keys.ctrl, 'I'],
    mac: [Keys.cmd, 'I'],
  },
  itemEnterText: {
    windows: [Keys.ctrl, Keys.enter],
    mac: [Keys.cmd, Keys.enter],
  },
  itemAlignCenterMain: {
    windows: [Keys.alt, 'C'],
    mac: [Keys.alt, 'C'],
  },
  itemAlignCenterCross: {
    windows: [Keys.shift, Keys.alt, 'C'],
    mac: [Keys.shift, Keys.alt, 'C'],
  },
  itemAlignDistributeMain: {
    windows: [Keys.alt, 'D'],
    mac: [Keys.alt, 'D'],
  },
  itemAlignDistributeCross: {
    windows: [Keys.shift, Keys.alt, 'D'],
    mac: [Keys.shift, Keys.alt, 'D'],
  },
  itemAlignTop: {
    windows: [Keys.alt, Keys.up],
    mac: [Keys.alt, Keys.up],
  },
  itemAlignBottom: {
    windows: [Keys.alt, Keys.down],
    mac: [Keys.alt, Keys.down],
  },
  itemAlignLeft: {
    windows: [Keys.alt, Keys.left],
    mac: [Keys.alt, Keys.left],
  },
  itemAlignRight: {
    windows: [Keys.alt, Keys.right],
    mac: [Keys.alt, Keys.right],
  },
  itemOrientationVertical: {
    windows: [Keys.ctrl, Keys.shift, Keys.down],
    mac: [Keys.cmd, Keys.shift, Keys.down],
  },
  itemOrientationHorizontal: {
    windows: [Keys.ctrl, Keys.shift, Keys.right],
    mac: [Keys.cmd, Keys.shift, Keys.right],
  },
  itemContainerCollapseToggle: {
    windows: [Keys.ctrl, Keys.shift, 'F'],
    mac: [Keys.cmd, Keys.shift, 'F'],
  },
};

export const getKeyBindingArray = (name: string, isMac: boolean = false): string[] => {
  if (!(name in KeyBindings)) return [];
  return KeyBindings[name as keyof typeof KeyBindings][isMac ? 'mac' : 'windows'];
};

export const getKeyBinding = (name: string, isMac: boolean = false): string => {
  if (!(name in KeyBindings)) return '';
  const command = KeyBindings[name as keyof typeof KeyBindings][isMac ? 'mac' : 'windows'];
  return command.join('+').toLowerCase();
};
