import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  ASTERISK,
  A_PARAM,
  CLOSE_BRACKET,
  CLOSE_SQUARE_BRACKET,
  COLOR_VARIABLE,
  DEFAULT_FONT,
  DEFAULT_IMAGE,
  EQUAL,
  FONT_VARIABLE,
  IMAGE_VARIABLE,
  IS_NESTED,
  LINE_JUMP,
  NUMBER_SIGN,
  PARAM_OBJECT,
  PROP,
  PROP_COMPONENT_INSTANCE,
  SIZE_VARIABLE,
  POINT_PROP,
  SUBCOMPONENT,
  TEXT_VARIABLE,
  THE,
  VISIBILITY_VARIABLE,
  COMMA,
  GRADIENT,
  STRING,
  POINT,
  SLASH,
  MINUS,
  BOOLEAN,
  COMPONENT_INSTANCE,
  SQUARE_SUBCOMP,
  PARAM_OBJ
} from '../../../constants/constants';
import { type IComponentinfo, type ReactInfo } from '@globalTypes/conversor';
import { adaptItemName } from '../../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { adaptPartsProps, setUpDefaultValuesNested } from './reactComponentPropsUtils';

export function getComponentPropDocValue (parts: string[], defaultValues: Record<string, string>,
  types: Record<string, string>, mref: string): void {
  const mKind = parts[1];
  let defaultValue = '';
  let mType = STRING;
  if (mKind === SIZE_VARIABLE) defaultValue = (parts[0] + '').replaceAll(COMMA, '');
  else if (mKind === COLOR_VARIABLE && !parts[0].includes(GRADIENT)) {
    defaultValue = NUMBER_SIGN + parts[0];
  } else if (mKind === TEXT_VARIABLE) defaultValue = parts[0];
  else if (mKind === IMAGE_VARIABLE) defaultValue = DEFAULT_IMAGE;
  else if (mKind === FONT_VARIABLE) {
    defaultValue = parts[0][1];
    defaultValue = defaultValue.split(POINT)[0];
    if (defaultValue.includes(SLASH)) {
      defaultValue = DEFAULT_FONT;
    }
  } else if (mKind === VISIBILITY_VARIABLE) {
    mType = BOOLEAN;
    defaultValue = parts[0][0] + '';
  } else defaultValue = parts[0];
  defaultValues[mref] = defaultValue;
  types[mref] = mType;
}

export function setComponentPropsDocValues (viewDOM: IComponentinfo,
  defaultValuesNested: Record<string, Record<string, boolean>>, defaultValues: Record<string, string>,
  types: Record<string, string>, componentName: string): void {
  const component = viewDOM.getComponentByName(componentName, viewDOM.components);
  if (component?.references == null) {
    window.logger.warn('component ' + componentName + ' not found');
    return;
  }
  const cRef = component.references.variables;
  setUpDefaultValuesNested(defaultValuesNested, component);
  for (const mref in cRef) {
    const parts = adaptPartsProps(cRef, mref);
    getComponentPropDocValue(parts, defaultValues, types, mref);
  }
}

export function nestedComponentDocsString (neededInfo: ReactInfo, variable: string,
  nestedContent: Record<string, string>, nestName: string, componentName: string): void {
  const info = neededInfo.componentPropParameters[variable.split(MINUS)[0]];
  nestedContent[nestName] = PARAM_OBJ + nestName + SQUARE_SUBCOMP + nestName + ' ' + PROP + LINE_JUMP;
  nestedContent[nestName] += info.replaceAll(COMPONENT_INSTANCE, nestName);
  neededInfo.componentPropParameters[componentName] += PARAM_OBJ + COMPONENT_INSTANCE +
    POINT + nestName + SQUARE_SUBCOMP + nestName + ' ' + PROP + LINE_JUMP;
  neededInfo.componentPropParameters[componentName] +=
    info.replaceAll(COMPONENT_INSTANCE, COMPONENT_INSTANCE + POINT + nestName);
}

export function getNestedDefaultValueString (nest: string, nestedContent: Record<string, string>,
  children: string, defaultValuesNested: Record<string, Record<string, boolean>>, neededInfo: ReactInfo,
  componentName: string): void {
  const nestName = adaptItemName(nest, true);
  if (nestedContent[nestName] == null) {
    if (children.includes(nestName + POINT)) {
      for (const variable in defaultValuesNested[nest]) {
        nestedComponentDocsString(neededInfo, variable, nestedContent, nestName, componentName);
      }
    }
  }
}

export function getNestetdComponentPropsDoc (neededInfo: ReactInfo, content: string,
  defaultValuesNested: Record<string, Record<string, boolean>>, nestedContent: Record<string, string>,
  children: string, componentName: string): string {
  if (Object.keys(defaultValuesNested).length !== 0) {
    for (const nest in defaultValuesNested) {
      getNestedDefaultValueString(nest, nestedContent, children, defaultValuesNested, neededInfo,
        componentName);
    }
  }
  for (const nested in nestedContent) {
    content += nestedContent[nested];
  }
  return content;
}

export function getComponentPropsDoc (item: TypeItemProperties, viewDOM: IComponentinfo, children: string,
  neededInfo: ReactInfo): string {
  const componentName = 'component' in item && item.component !== '' ? item.component : '';
  const defaultValues: Record<string, string> = {};
  const defaultValuesNested: Record<string, Record<string, boolean>> = {};
  const types: Record<string, string> = {};
  setComponentPropsDocValues(viewDOM, defaultValuesNested, defaultValues, types, componentName);
  const references = neededInfo.pagesReferences[componentName];
  if (references == null) return '';
  let content = PARAM_OBJECT;
  neededInfo.componentPropParameters[componentName] = '';
  const nestedContent = {};
  for (const reference of references) {
    const paramName = reference;
    if (types[paramName] != null && !defaultValues[paramName].includes(IS_NESTED)) {
      const paramStr = A_PARAM + types[paramName] + CLOSE_BRACKET;
      const paramNameDoc = POINT_PROP + paramName + EQUAL + defaultValues[paramName] + CLOSE_SQUARE_BRACKET;
      const paramDescription = THE + paramName + PROP;
      const paramNameDocLayout = PROP_COMPONENT_INSTANCE + paramName + CLOSE_SQUARE_BRACKET;
      const paramDescriptionLayout = THE + paramName + SUBCOMPONENT;
      neededInfo.componentPropParameters[componentName] += ASTERISK + paramStr + ' ' + paramNameDocLayout +
          ' ' + paramDescriptionLayout + LINE_JUMP;
      content += ASTERISK + paramStr + ' ' + paramNameDoc + ' ' + paramDescription + LINE_JUMP;
    }
  }
  content = getNestetdComponentPropsDoc(neededInfo, content, defaultValuesNested, nestedContent,
    children, componentName);
  return content;
}
