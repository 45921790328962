import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type StateType } from '@reducers/store';

let theme = localStorage.getItem('aguatheme');
if (theme == null && window.matchMedia != null && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  theme = 'dark';
} else if (theme == null || theme === 'light') {
  theme = 'light';
  const htmlTag = document.getElementsByTagName('html')[0];
  htmlTag.removeAttribute('custom-theme');
}

interface InitialThemesState {
  currentTheme: string;
  watermark: boolean;
  siteName: string;
}

const initialData: InitialThemesState = {
  currentTheme: theme,
  watermark: true,
  siteName: 'Project',
};

const doToggleTheme = (state: any, changeState: boolean, theme?: string): void => {
  let currentTheme;
  if (state.currentTheme === 'dark') currentTheme = 'light';
  else currentTheme = 'dark';
  if (changeState) state.currentTheme = currentTheme;
  const htmlTag = document.getElementsByTagName('html')[0];
  if (theme != null) currentTheme = theme;
  if (currentTheme === 'dark') htmlTag.setAttribute('custom-theme', 'dark');
  else htmlTag.removeAttribute('custom-theme');
  localStorage.setItem('aguatheme', currentTheme);
};

export const ThemesSlice = createSlice({
  name: 'themes',
  initialState: initialData,
  reducers: {
    setInitialThemes: (state, action) => {
      state.currentTheme = theme ?? 'dark';
    },
    setWholeThemes: (state, action) => {
      const { currentTheme, watermark, siteName } = action.payload;
      state.watermark = watermark;
      state.siteName = siteName;
      let shouldToggle = false;
      if (state.currentTheme !== currentTheme) shouldToggle = true;
      state.currentTheme = currentTheme;
      if (shouldToggle) doToggleTheme(state, false, currentTheme);
    },
    setSiteName: (state, action: PayloadAction<string>) => {
      state.siteName = action.payload;
    },
    toggleWatermark: (state) => {
      state.watermark = !state.watermark;
    },
    toggleTheme: (state) => {
      doToggleTheme(state, true);
    },
  },
});

export const getCurrentTheme = (state: StateType): any => state.themes.currentTheme;
export const getWatermark = (state: StateType): any => state.themes.watermark;
export const getSiteName = (state: StateType): any => state.themes.siteName;

export const { setInitialThemes, setWholeThemes, toggleTheme, toggleWatermark, setSiteName } =
  ThemesSlice.actions;

export const ThemesReducer = ThemesSlice.reducer;
