/* eslint-disable max-lines-per-function */
import {
  ALIGNMENT,
  ALIGN_ITEMS,
  BACKGROUND,
  BACKGROUND_COLOR,
  BORDER_RADIUS,
  BRACKET_CLOSE,
  BRACKET_OPEN,
  CLOSING_PARENTESIS,
  COMMA,
  CSS_POSITION,
  CURSOR_POINTER,
  CURSOR_POINTER_CSS,
  DEFAULT_THEME,
  DISPLAY,
  FLEX,
  FLEX_DIRECTION,
  FLEX_WRAP,
  GROUP_REFERENCE,
  HIDDEN,
  HORIZONTAL,
  ITALIC,
  JUSTIFY_CONTENT,
  LINE_JUMP,
  MHORIZONTAL,
  MINUS,
  MIN_MINUS,
  MNONE,
  MVERTICAL,
  NONE,
  ORIENTATION,
  OVERFLOW,
  POINT,
  POSITION,
  RADIUS,
  REFERENCE_BRACKET,
  SCROLL,
  SCROLLABLE,
  SEMICOLON_LINE_JUMP,
  TEXT_STYLE,
  TEXT_WEIGHT,
  TWO_POINTS_SPACE,
  UNIT_PX,
  VALUE,
  VAR_DEFINITION,
  VERTICAL,
  VISIBLE,
  WEIGHT,
  WRAP,
  Z_INDEX,
  Z_INDEX_CSS,
} from '../../constants/constants';
import { type TypeDictionary } from '../dictionary/dictionary';
import {
  type TypeVariable,
  type ReactInfo,
  type IComponentinfo,
  type TypeReactAttributeReferences,
  type TypeReactAttribute
} from '@globalTypes/conversor';
import { convertVariableinfo } from './reactVariableMethods';
import {
  DefaultIcon,
  DefaultImage,
  DefaultLayer,
  DefaultScreen,
  DefaultText,
  type TypeItemProperties,
} from '@globalTypes/itemProperties';
import { type ReferencesGroupType } from '@globalTypes/itemProperties/classesType';
import { externalStyle } from '../reactItemMethods/reactCssMethods/reactCSSMethods';
import { getReferenceItem } from '../reactItemMethods/reactCssMethods/reactReferenceMethods';

const baseItems: Record<string, TypeItemProperties> = {
  layer: { ...DefaultLayer },
  text: { ...DefaultText },
  icon: { ...DefaultIcon },
  imageView: { ...DefaultImage },
  screen: { ...DefaultScreen },
};

export function addGroupRefrencesMayusLocationNoCursor(
  variableReference: string | string[],
  neededInfo: ReactInfo,
  location: string
): void {
  if (typeof variableReference === 'string') {
    neededInfo.referenceString += location + TWO_POINTS_SPACE + variableReference + SEMICOLON_LINE_JUMP;
  } else {
    neededInfo.referenceString +=
      location + VAR_DEFINITION + variableReference[0] + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
}

export function groupRefrencesMayusLocationNoCursor(
  variable: string,
  variableReference: string | string[],
  neededInfo: ReactInfo,
  mayusLocation: RegExpExecArray,
  variableList: Record<string, string>
): void {
  let location = variable.replace(mayusLocation[0], MINUS + mayusLocation[0].toLowerCase());
  if (variable.includes(BACKGROUND_COLOR)) location = BACKGROUND;
  else if (variable.includes(VISIBLE)) location = DISPLAY;
  else if (variable.includes(ALIGNMENT)) {
    const variableOrientation = variableList.orientation;
    const same =
      (variableOrientation === VERTICAL && variable.includes(MVERTICAL)) ||
      (variableOrientation === HORIZONTAL && variable.includes(MHORIZONTAL));
    location = same ? JUSTIFY_CONTENT : ALIGN_ITEMS;
  } else if (variable.includes(CSS_POSITION)) location = POSITION;
  addGroupRefrencesMayusLocationNoCursor(variableReference, neededInfo, location);
}

export function groupRefrencesMayusLocation(
  variable: string,
  variableReference: string,
  neededInfo: ReactInfo,
  mayusLocation: RegExpExecArray,
  variableList: Record<string, string>
): void {
  if (variable.includes(CURSOR_POINTER)) {
    if (typeof variableReference === 'boolean') {
      if (variableReference) neededInfo.referenceString += CURSOR_POINTER_CSS;
    } else {
      neededInfo.referenceString +=
        CURSOR_POINTER + VAR_DEFINITION + variableReference[0] + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  } else {
    groupRefrencesMayusLocationNoCursor(variable, variableReference, neededInfo, mayusLocation, variableList);
  }
}

export function addGroupReferencesNoMayusString(
  location: string,
  variableReference: TypeVariable,
  neededInfo: ReactInfo
): void {
  if (typeof variableReference.value === 'string') {
    if ('asset' in variableReference) {
      neededInfo.referenceString +=
        location + VAR_DEFINITION + variableReference.value + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
      if (location === TEXT_WEIGHT) {
        neededInfo.referenceString +=
          TEXT_STYLE + VAR_DEFINITION + variableReference.value + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
      } else if (location === TEXT_STYLE) {
        neededInfo.referenceString +=
          TEXT_WEIGHT + VAR_DEFINITION + variableReference.value + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
      }
    } else {
      neededInfo.referenceString += location + TWO_POINTS_SPACE + variableReference.value +
        SEMICOLON_LINE_JUMP;
    }
  }
}

export function groupReferencesNoMayusString(
  variable: string,
  variableReference: TypeVariable,
  neededInfo: ReactInfo
): void {
  let location = variable;
  const value = variableReference;
  if (!('asset' in value)) return;
  if (variable.includes(RADIUS)) location = BORDER_RADIUS;
  else if (variable.includes(ORIENTATION)) location = FLEX_DIRECTION;
  else if (variable.includes(VALUE)) {
    location = variable.replace(VALUE, '');
    if (typeof variableReference.value === 'string') {
      neededInfo.referenceString +=
        MIN_MINUS + location + TWO_POINTS_SPACE + variableReference.value + SEMICOLON_LINE_JUMP;
    } else {
      neededInfo.referenceString +=
        MIN_MINUS + location + VAR_DEFINITION + value.value + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  } else if (variable.includes(POSITION)) location = variable.replace(POSITION + MINUS, '');
  else if (variable.includes(Z_INDEX)) location = Z_INDEX_CSS;
  else if (variable.includes(WEIGHT)) {
    if (variableReference.value === ITALIC) location = TEXT_STYLE;
    else location = TEXT_WEIGHT;
  }
  const mVal = typeof variableReference === 'string' ? variableReference : value;
  addGroupReferencesNoMayusString(location, mVal, neededInfo);
}

export function groupReferencesNoMayus(
  variable: string,
  variableReference: TypeVariable,
  neededInfo: ReactInfo
): void {
  if (variable.includes(VISIBLE) || variable.includes(SCROLLABLE) || variable.includes(WRAP)) {
    let val = ['', '', ''];
    if (variable.includes(VISIBLE)) val = [DISPLAY, FLEX, NONE];
    else if (variable.includes(SCROLLABLE)) val = [OVERFLOW, SCROLL, HIDDEN];
    else if (variable.includes(WRAP)) val = [FLEX_WRAP, WRAP, NONE];
    if (typeof variableReference === 'boolean') {
      neededInfo.referenceString +=
        val[0] +
        TWO_POINTS_SPACE +
        (variableReference ? val[1] : val[2]) +
        CLOSING_PARENTESIS +
        SEMICOLON_LINE_JUMP;
    } else {
      const value = variableReference;
      if ('asset' in value) {
        neededInfo.referenceString +=
          val[0] + VAR_DEFINITION + value.value + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
      }
    }
  } else {
    groupReferencesNoMayusString(variable, variableReference, neededInfo);
  }
}

export function addGroupReferences(referenceString: string, neededInfo: ReactInfo, group: string): void {
  if (referenceString !== '') {
    // if (neededInfo.classDeviceCoverage[group] === ALL || true) {
    neededInfo.groupList += LINE_JUMP + '*' + POINT + group + '[custom-agua-class][custom-agua-item] ' +
      BRACKET_OPEN + referenceString + BRACKET_CLOSE;
    /** } else {
      const deviceVal = neededInfo.classDeviceCoverage[group] === DESKTOP ? 1 : 2;
      const place = neededInfo.classDeviceCoverage[group] === PHONE ? 0 : deviceVal;
      neededInfo.classNameGroups[place] +=
        LINE_JUMP + POINT + group + BRACKET_OPEN + referenceString + BRACKET_CLOSE;
    } */
  }
}

export function createGroupReferenece(
  variableList: Record<string, string>,
  variable: string,
  neededInfo: ReactInfo,
  dictionary: TypeDictionary
): void {
  let variableReference = variableList[variable];
  if (!isNaN(parseInt(variableReference))) {
    variableReference = variableReference.toString() + COMMA + UNIT_PX;
  }
  convertVariableinfo(
    DEFAULT_THEME,
    MNONE,
    { parameter: true, value: variableReference },
    neededInfo,
    GROUP_REFERENCE,
    false,
    dictionary
  );
  const mayusLocation = /[A-Z]/.exec(variable);
  if (mayusLocation != null) {
    groupRefrencesMayusLocation(variable, variableReference, neededInfo, mayusLocation, variableList);
  } else {
    groupReferencesNoMayus(variable, { parameter: true, value: variableReference }, neededInfo);
  }
}

export function convertClassToItem(info: ReferencesGroupType): TypeItemProperties {
  const normal = info.normal;
  const baseItem: TypeItemProperties = JSON.parse(JSON.stringify(baseItems[normal.commonKind]));
  for (const property in normal) {
    switch (property) {
      case 'classes':
        if ('classes' in baseItem && normal.classes != null) baseItem.classes = normal.classes;
        break;
      case 'backgroundColor':
        if ('backgroundColor' in baseItem && normal.backgroundColor != null) {
          baseItem.backgroundColor = normal.backgroundColor;
        }
        break;
      case 'background':
        if ('background' in baseItem && normal.background != null) baseItem.background = normal.background;
        break;
      case 'margins':
        if ('margins' in baseItem && normal.margins != null) baseItem.margins = normal.margins;
        break;
      case 'paddings':
        if ('paddings' in baseItem && normal.paddings != null) baseItem.paddings = normal.paddings;
        break;
      case 'textAlignment':
        if ('textAlignment' in baseItem && normal.textAlignment != null) {
          baseItem.textAlignment = normal.textAlignment;
        }
        break;
      case 'visible':
        if ('visible' in baseItem && normal.visible != null) baseItem.visible = normal.visible;
        break;
      case 'cursorPointer':
        if ('cursorPointer' in baseItem && normal.cursorPointer != null) {
          baseItem.cursorPointer = normal.cursorPointer;
        }
        break;
      case 'width':
        if ('width' in baseItem && normal.width != null) baseItem.width = normal.width;
        break;
      case 'height':
        if ('height' in baseItem && normal.height != null) baseItem.height = normal.height;
        break;
      case 'verticalAlignment':
        if ('verticalAlignment' in baseItem && normal.verticalAlignment != null) {
          baseItem.verticalAlignment = normal.verticalAlignment;
        }
        break;
      case 'horizontalAlignment':
        if ('horizontalAlignment' in baseItem && normal.horizontalAlignment != null) {
          baseItem.horizontalAlignment = normal.horizontalAlignment;
        }
        break;
      case 'orientation':
        if ('orientation' in baseItem && normal.orientation != null) {
          baseItem.orientation = normal.orientation;
        }
        break;
      case 'scrollable':
        if ('scrollable' in baseItem && normal.scrollable != null) baseItem.scrollable = normal.scrollable;
        break;
      case 'positions':
        if ('positions' in baseItem && normal.positions != null) baseItem.positions = normal.positions;
        break;
      case 'cssPosition':
        if ('cssPosition' in baseItem) baseItem.cssPosition = normal.cssPosition;
        break;
      case 'border':
        if ('border' in baseItem && normal.border != null) {
          baseItem.border = normal.border;
        }
        break;
      case 'shadow':
        if ('shadow' in baseItem && normal.shadow != null) {
          baseItem.shadow = normal.shadow;
        }
        break;
      case 'fontFamily':
        if ('fontFamily' in baseItem && normal.fontFamily != null) baseItem.fontFamily = normal.fontFamily;
        break;
      case 'fontSize':
        if ('fontSize' in baseItem && normal.fontSize != null) baseItem.fontSize = normal.fontSize;
        break;
      case 'leading':
        if ('leading' in baseItem && normal.leading != null) baseItem.leading = normal.leading;
        break;
      case 'weight':
        if ('weight' in baseItem && normal.weight != null) baseItem.weight = normal.weight;
        break;
      case 'spacing':
        if ('spacing' in baseItem && normal.spacing != null) baseItem.spacing = normal.spacing;
        break;
      case 'style':
        if ('style' in baseItem && normal.style != null) baseItem.style = normal.style;
        break;
      case 'zindex':
        if ('zindex' in baseItem) baseItem.zindex = normal.zindex;
        break;
      default: break;
    }
  }
  const variables = info.variable;
  const references: TypeReactAttributeReferences = {};
  for (const variable in variables) {
    const theme = variables[variable].includes('_') ? 'Responsiveness' : 'Default';
    const value: TypeReactAttribute = [variable, variables[variable], theme, undefined, '0'];
    references[variable] = value;
  }
  baseItem.references = references;
  return baseItem;
}

export function createGroupRefereneces(
  neededInfo: ReactInfo,
  dictionary: TypeDictionary,
  viewDOM: IComponentinfo
): void {
  for (const group in neededInfo.referenceGroupsList) {
    const variableList = neededInfo.referenceGroupsList[group];
    const classAsItem = convertClassToItem(variableList);
    neededInfo.variableListInfo = variableList;
    let cssString = externalStyle(classAsItem, dictionary, neededInfo);
    neededInfo.item = classAsItem;
    neededInfo.name = group;
    const references = getReferenceItem(viewDOM, neededInfo);
    cssString = cssString.replace(REFERENCE_BRACKET, references[0]);
    cssString = cssString.substring(cssString.indexOf('{'), cssString.lastIndexOf('}'));
    addGroupReferences(cssString.replace('{{componentVariable}', ''), neededInfo, group);
  }
}
