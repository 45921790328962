import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BRACKET_CONTENT,
  DEFAULT_ICON,
  DEFAULT_TEXT,
  DESTINATION,
  ICON,
  POINT,
  SLASH,
  TEXT,
  TWO_POINTS,
  VARIABLE_CONTENT
} from '../../../constants/constants';
import { type TypeDictionary } from '../../dictionary/dictionary';
import { type ReactInfo } from '@globalTypes/conversor';
import { referenceValue, getConvertNametoReact } from './reactItemUtils';

export function getContent (item: TypeItemProperties, component: string): string {
  if (item.commonKind === ICON) {
    const value = 'icon' in item ? item.icon : DEFAULT_ICON;
    const isReference = referenceValue(ICON, item, false);
    const adaptedIcon = value;
    const mVal: string = isReference !== false
      ? VARIABLE_CONTENT
      : adaptedIcon;
    return component.replace(BRACKET_CONTENT, mVal);
  } else {
    const isReference = referenceValue(TEXT, item, false) !== false;
    const nVal = 'content' in item ? item.content.default : DEFAULT_TEXT;
    const mVal = isReference ? VARIABLE_CONTENT : nVal;
    return component.replace(BRACKET_CONTENT, mVal);
  }
}

export function destinationAttribute (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  if ('href' in item && item.href != null && !item.href.includes(POINT) && !item.href.includes(TWO_POINTS)) {
    if (neededInfo.canvas) return '';
    const template = dictionary.goToTemmplate;
    const name = SLASH + getConvertNametoReact(item.href);
    const destinationReturn = template.replace(DESTINATION, name);
    return destinationReturn;
  }
  return '';
}
