import { type ReactInfo } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BOTTOM,
  LEFT,
  LINE_JUMP,
  MINUS,
  POSITION,
  RIGHT,
  SEMICOLON,
  STATIC,
  TOP,
  TWO_POINTS_SPACE,
  UNIT_PX,
  Z_INDEX_CSS
} from '../../../../../constants/constants';
import { referenceValue } from '../../reactItemUtils';

export function positionLogic (item: TypeItemProperties, location: string): string {
  if ('positions' in item && item.positions[location as keyof typeof item.positions] != null) {
    const position = item.positions[location as keyof typeof item.positions]?.value ?? '';
    const isPosReference = position != null &&
      referenceValue(POSITION + MINUS + location, item, false) === false;
    if (isPosReference && position !== '') {
      const unit = (item.positions[location as keyof typeof item.positions]?.unit ?? UNIT_PX);
      return LINE_JUMP + location + TWO_POINTS_SPACE + position + unit + SEMICOLON;
    }
  }
  return '';
}

export function position (item: TypeItemProperties, neededInfo: ReactInfo): string {
  let position = '';
  const cssPosClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.cssPosition != null;
  if ('cssPosition' in item && item.cssPosition != null && (item.cssPosition !== STATIC || cssPosClass)) {
    position += LINE_JUMP + POSITION + TWO_POINTS_SPACE + item.cssPosition + SEMICOLON;
  }
  const locations = [TOP, BOTTOM, LEFT, RIGHT];
  for (let i = 0; i < 4; i++) position += positionLogic(item, locations[i]);
  return position;
}

export function zindex (item: TypeItemProperties): string {
  if ('zindex' in item && item.zindex != null) {
    return LINE_JUMP + Z_INDEX_CSS + TWO_POINTS_SPACE + item.zindex + SEMICOLON;
  }
  return '';
}
