import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { LINE_JUMP, WRAP } from '../../../../../constants/constants';
import { type TypeDictionary } from '../../../../dictionary/dictionary';
import { referenceValue } from '../../reactItemUtils';

export function wrap (item: TypeItemProperties, dictionary: TypeDictionary): string {
  if (referenceValue(WRAP, item, false) !== false) return '';
  else if ('wrap' in item && item.wrap != null && item.wrap) return LINE_JUMP + dictionary.wrapStyle;
  else return '';
}
