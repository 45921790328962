import React from 'react';

interface OpenWindowWrapperProps {
  children: React.JSX.Element;
  link: string;
  width: string;
  height: string;
}
export const OpenWindowWrapper = ({
  children,
  link,
  width,
  height,
}: OpenWindowWrapperProps): React.JSX.Element => {
  const clickHandler = (): void => {
    window.open(link, '_blank')?.focus();
  };

  return (
    <div
      style={{ width, height }}
      onClick={() => {
        clickHandler();
      }}
    >
      {children}
    </div>
  );
};
