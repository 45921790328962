/* eslint-disable multiline-ternary */

/* eslint-disable max-lines-per-function */

/* eslint-disable prefer-promise-reject-errors */

/* eslint-disable max-len */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Spin } from 'antd';
import PhoneInput from 'antd-phone-input';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BackgroundGradient } from '@components/backgroundGradient';
import { HeaderText } from '@components/headerText';

const validator = (_: any, obj: any): any => {
  if (obj == null) return Promise.reject('Invalid phone number');
  const { valid } = obj;
  if (valid(true) === true) return Promise.resolve();
  return Promise.reject('Invalid phone number');
};

export const UserForm = ({
  notifyData,
  loading,
}: {
  notifyData: (data: any) => any;
  loading: boolean;
}): React.JSX.Element => {
  const { t } = useTranslation();

  const onFinish = (values: any): void => {
    const p = values.phone;
    let mPhone = '';
    const hasAreaCode = p.areaCode != null && p.areaCode !== '';
    if (hasAreaCode) mPhone = '+' + p.countryCode + ' (' + p.areaCode + ')' + ' ' + p.phoneNumber;
    else mPhone = '+' + p.countryCode + ' ' + p.phoneNumber;
    const data = {
      name: values.name.trim(),
      lastName: values.lastName.trim(),
      phone: mPhone,
      companySize: values.companySize,
      role: values.role,
      filled: true,
    };
    notifyData(data);
  };

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'var(--background-color-2)' }}>
      <div className={'h-full w-full relative flex items-center justify-center bg-dot-white/[0.2]'}>
        <div
          style={{ backgroundColor: 'black' }}
          className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"
        ></div>
        <BackgroundGradient className="w-auto h-auto">
          {loading ? (
            <div
              style={{
                width: '200px',
                height: '150px',
                backgroundColor: 'var(--background-color-2)',
                borderRadius: 'var(--border-radius-xxl)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          ) : (
            <div
              style={{
                width: '400px',
                height: 'fit-content',
                padding: 'calc(var(--padding-l) + var(--padding-m))',
                paddingTop: 'var(--padding-m)',
                backgroundColor: 'var(--background-color-2)',
                borderRadius: 'var(--border-radius-xxl)',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 'var(--padding-l)',
                  marginBottom: 'calc(var(--padding-l) + var(--padding-m))',
                }}
              >
                <img style={{ height: '50px' }} src={'/agua_white.png'}></img>
              </div>
              <Form name="get-user-nada" initialValues={{ remember: true }} onFinish={onFinish}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  <div style={{ marginRight: 'var(--padding-s)', flexShrink: 0, width: '80px' }}>
                    <HeaderText bold={false}>{t('name')}</HeaderText>
                  </div>
                  <div style={{ flex: '1 1 50%' }}>
                    <Form.Item
                      name="name"
                      rules={[{ required: true, message: t('name-error-label'), type: 'string' }]}
                    >
                      <Input placeholder={t('name-placeholder')} />
                    </Form.Item>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 'var(--padding-m)',
                  }}
                >
                  <div style={{ marginRight: 'var(--padding-s)', flexShrink: 0, width: '80px' }}>
                    <HeaderText bold={false}>{t('lastName')}</HeaderText>
                  </div>
                  <div style={{ flex: '1 1 50%' }}>
                    <Form.Item
                      name="lastName"
                      rules={[{ required: true, message: t('lastName-error-label'), type: 'string' }]}
                    >
                      <Input placeholder={t('lastName-placeholder')} />
                    </Form.Item>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 'var(--padding-m)',
                  }}
                >
                  <div style={{ marginRight: 'var(--padding-s)', flexShrink: 0, width: '80px' }}>
                    <HeaderText bold={false}>{t('phone')}</HeaderText>
                  </div>
                  <div style={{ flex: '1 1 50%' }}>
                    <Form.Item
                      name="phone"
                      rules={[{ validator, required: true, message: t('phone-error-label') }]}
                    >
                      <PhoneInput enableSearch />
                    </Form.Item>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 'var(--padding-m)',
                  }}
                >
                  <div style={{ marginRight: 'var(--padding-s)', flexShrink: 0, width: '80px' }}>
                    <HeaderText bold={false}>{t('company')}</HeaderText>
                  </div>
                  <div style={{ flex: '1 1 50%' }}>
                    <Form.Item
                      name="companySize"
                      rules={[{ required: true, message: t('noselection-error-label') }]}
                    >
                      <Select
                        placeholder={t('company-placeholder')}
                        options={[
                          {
                            value: '1',
                            label: '1-4',
                          },
                          {
                            value: '2',
                            label: '5-9',
                          },
                          {
                            value: '3',
                            label: '10-19',
                          },
                          {
                            value: '4',
                            label: '20-49',
                          },
                          {
                            value: '5',
                            label: '50-99',
                          },
                          {
                            value: '6',
                            label: '100-499',
                          },
                          {
                            value: '7',
                            label: '500-1000',
                          },
                          {
                            value: '8',
                            label: '> 1000',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 'var(--padding-m)',
                  }}
                >
                  <div style={{ marginRight: 'var(--padding-s)', flexShrink: 0, width: '80px' }}>
                    <HeaderText bold={false}>{t('role')}</HeaderText>
                  </div>
                  <div style={{ flex: '1 1 50%' }}>
                    <Form.Item
                      name="role"
                      rules={[{ required: true, message: t('noselection-error-label') }]}
                    >
                      <Select
                        placeholder={t('role-placeholder')}
                        options={[
                          {
                            value: 'jr',
                            label: 'Jr. Developer',
                          },
                          {
                            value: 'mid',
                            label: 'Mid Developer',
                          },
                          {
                            value: 'ssr',
                            label: 'Semi Sr. Developer',
                          },
                          {
                            value: 'sr',
                            label: 'Sr. Developer',
                          },
                          {
                            value: 'tl',
                            label: 'Tech Lead',
                          },
                          {
                            value: 'pl',
                            label: 'Project Lead',
                          },
                          {
                            value: 'po',
                            label: 'Product Owner',
                          },
                          {
                            value: 'man',
                            label: 'Manager',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 'calc(var(--padding-l) + var(--padding-m))',
                  }}
                >
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      {t('signup')}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </BackgroundGradient>
      </div>
    </div>
  );
};
