'use strict';

import * as Sentry from '@sentry/react';
import { type FirebaseApp } from 'firebase/app';
import { type Auth } from 'firebase/auth';
import { type Firestore } from 'firebase/firestore';

import './ui';

declare global {
  interface Window {
    showDirectoryPicker: (options?: DirectoryPickerOptions) => Promise<FileSystemDirectoryHandle>;
    app: FirebaseApp;
    auth: Auth;
    firestore: Firestore;
    idb: any;
    ace: any;
    prodMode: boolean;
    reduxLogs: boolean;
    logger: {
      log: (...obj: any) => void;
      warn: (...obj: any) => void;
      error: (...obj: any) => void;
    };
    logging: boolean;
    tinymce: any;
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
    vis: any;
    convertCSSUnit: (cssValue: string) => number;
  }
}

window.prodMode = !window.location.host.startsWith('localhost');
window.reduxLogs = false;
window.logging = !window.prodMode;

window.convertCSSUnit = function (cssValue: string) {
  // Match positive and negative numbers including decimals with following unit
  const pattern = new RegExp(`^([-+]?(?:\\d+(?:\\.\\d+)?))(${Object.keys(supportedUnits).join('|')})$`, 'i');

  // If is a match, return example: [ "-2.75rem", "-2.75", "rem" ]
  const matches = String.prototype.toString.apply(cssValue).trim().match(pattern);

  if (matches != null) {
    const value = Number(matches[1]);
    const unit = matches[2].toLocaleLowerCase();

    // Sanity check, make sure unit conversion function exists
    if (unit in supportedUnits) {
      return supportedUnits[unit](value);
    }
  }

  return Number.NaN;
};

const logger = {
  log: (...obj: any): void => {
    if (window.logging) {
      obj.forEach((arg: any) => {
        console.log(arg);
      });
    }
  },
  warn: (...obj: any): void => {
    if (window.logging) {
      obj.forEach((arg: any) => {
        console.warn(arg);
      });
    }
  },
  error: (...obj: any): void => {
    if (window.logging) {
      obj.forEach((arg: any) => {
        console.error(arg);
      });
    }
  },
};

const supportedUnits: Record<string, (value: number) => number> = {
  // Absolute sizes
  px: (value: number) => value,
  cm: (value: number) => value * 38,
  mm: (value: number) => value * 3.8,
  q: (value: number) => value * 0.95,
  in: (value: number) => value * 96,
  pc: (value: number) => value * 16,
  pt: (value: number) => value * 1.333333,

  // Relative sizes
  rem: (value: number) => value * parseFloat(getComputedStyle(document.documentElement).fontSize),
  em: (value: number) => value * parseFloat(getComputedStyle(document.body).fontSize),
  vw: (value: number) => (value / 100) * window.innerWidth,
  vh: (value: number) => (value / 100) * window.innerHeight,

  // Times
  ms: (value: number) => value,
  s: (value: number) => value * 1000,

  // Angles
  deg: (value: number) => value,
  rad: (value: number) => value * (180 / Math.PI),
  grad: (value: number) => value * (180 / 200),
  turn: (value: number) => value * 360,
};

window.logger = logger;

const prod = window.prodMode;
if (prod) {
  Sentry.init({
    dsn: 'https://c7f1b70cdd534bc297d9a16b47c8feb2@o4507342854356992.ingest.us.sentry.io/4507342856257536',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  });
}
