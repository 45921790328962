import { BOTTOM, LEFT, RIGHT, TOP } from '@globalTypes/constants';
import { type SizeValueType } from './sizeProperties';

export namespace DirectionsType {
  export type TopProperty = 'top';
  export const TopProperty: TopProperty = TOP;
  export type BottomProperty = 'bottom';
  export const BottomProperty: BottomProperty = BOTTOM;
  export type LeftProperty = 'left';
  export const LeftProperty: LeftProperty = LEFT;
  export type RightProperty = 'right';
  export const RightProperty: RightProperty = RIGHT;
  export interface DirectionsType {
    [TopProperty]: SizeValueType.SizeValueType;
    [BottomProperty]: SizeValueType.SizeValueType;
    [LeftProperty]: SizeValueType.SizeValueType;
    [RightProperty]: SizeValueType.SizeValueType;
  }
}
