import { backgroundColor } from '../reactItemMethods/reactHTMLMethods/propertyTypeMethods/reactColorMethods';
import {
  aligmentStyle,
  orientationStyle
} from '../reactItemMethods/reactHTMLMethods/propertyTypeMethods/reactOrientationAligmentMethods';
import {
  paddingAndMarginStyle
} from '../reactItemMethods/reactHTMLMethods/propertyTypeMethods/reactPaddingAndMarginMethods';
import { scrollable } from '../reactItemMethods/reactHTMLMethods/propertyTypeMethods/reactScrollableMethods';
import { handleClassnames } from '../reactItemMethods/reactHTMLMethods/reactClassnamesMethods';
import { getConvertNametoReact, referenceValue } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { getReferenceItem } from '../reactItemMethods/reactCssMethods/reactReferenceMethods';
import {
  AGUA_ITEM,
  AGUA_ITEM_CLOSE,
  BACKGROUND_COLOR,
  BRACKET,
  BRACKET_CLASSNAMES,
  BRACKET_HOR_ALIGN,
  BRACKET_NAME,
  BRACKET_TAG,
  BRACKET_VER_ALIGN,
  CANVA_BRACKET,
  CLOSE_SQUARE_BRACKET,
  COLUMN,
  CUSTOMSTYLES_IMPORT,
  CUSTOM_STYLES_BRACKET,
  DISPLAY,
  FLEX_START,
  IMPORT_CUSTOM_STYLES,
  IMPORT_FORM,
  LINE_JUMP,
  MAIN,
  NAME_STYLE,
  NONE,
  ON_CLICK,
  ON_CLICK_CLOSE,
  OPEN_PARENTESIS_STRING,
  ORIENTATION,
  PADDING,
  PAGE_HAVE_FORM,
  POINT,
  PROJECT_CONTENT,
  REFERENCE_BRACKET,
  SCREEN_ALIGNMENT,
  SCREEN_BACKGROUND,
  SCREEN_ORIENTATION,
  SCREEN_PADDING,
  SCREEN_SCROLLABLE,
  SELECT_ITEM,
  SEMICOLON,
  SQUARE_BRACKET,
  TWO_POINTS_SPACE,
  VISIBILITY_BRACKET,
  VISIBLE
} from '../../constants/constants';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { type ReactInfo, type IComponentinfo } from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';

export function pageStylesPadding (item: TypeItemProperties, creatingComponent: boolean,
  dictionary: TypeDictionary, neededInfo: ReactInfo): string {
  const paddings =
    paddingAndMarginStyle(item, PADDING, creatingComponent, dictionary, neededInfo).trim().split(LINE_JUMP);
  let paddingString = '';
  for (const padding of paddings) {
    if (padding !== '') paddingString += padding;
  }
  return paddingString;
}

export function fixNoAttibutesPageStyle (style: string, item: TypeItemProperties, name: string,
  neededInfo: ReactInfo): string {
  const styleOpenPosition = style.replaceAll(' ', '').replaceAll(LINE_JUMP, '').indexOf(BRACKET);
  const styleClosingPosition = style.replaceAll(' ', '').replaceAll(LINE_JUMP, '').indexOf('}');
  const hasAttributes = styleClosingPosition - styleOpenPosition;
  if (hasAttributes === 1) {
    style = '';
  } else {
    const className = name;
    neededInfo.objectClassnames[item.id].push([className, true]);
    style = style.replace(POINT, 'tag' in item ? item.tag + POINT : '');
    style = style.replace(BRACKET, AGUA_ITEM + item.commonKind + AGUA_ITEM_CLOSE);
  }
  neededInfo.hasAttributes = hasAttributes;
  return style;
}

export function createPageStyles (item: TypeItemProperties, name: string, pageName: string,
  dictionary: TypeDictionary, neededInfo: ReactInfo, viewDOM: IComponentinfo): number {
  const valPadding = pageStylesPadding(item, neededInfo.creatingComponent, dictionary, neededInfo);
  let style = dictionary.screenStyle.replace(SCREEN_PADDING, valPadding);
  let color = backgroundColor(item, dictionary, neededInfo);
  if (referenceValue(BACKGROUND_COLOR, item, neededInfo.creatingComponent) !== false) color = '';
  style = style.replace(SCREEN_BACKGROUND, color);
  let orientation = orientationStyle(item, dictionary);
  if (referenceValue(ORIENTATION, item, neededInfo.creatingComponent) !== false ||
        orientation.includes(COLUMN)) orientation = '';
  const aligments = aligmentStyle(item, dictionary).split(SEMICOLON);
  let align = '';
  if (referenceValue(BRACKET_VER_ALIGN, item, neededInfo.creatingComponent) === false &&
        !aligments[0].includes(FLEX_START)) align += LINE_JUMP + aligments[0] + SEMICOLON;
  if (aligments[1] != null &&
    referenceValue(BRACKET_HOR_ALIGN, item, neededInfo.creatingComponent) === false &&
        !aligments[1].includes(FLEX_START)) align += LINE_JUMP + aligments[1] + SEMICOLON;
  style = style.replace(SCREEN_ORIENTATION, orientation).replace(SCREEN_ALIGNMENT, align);
  const screenScroll = scrollable(item, dictionary, neededInfo);
  style = style.replace(SCREEN_SCROLLABLE, (screenScroll !== '' ? LINE_JUMP : '') + screenScroll);
  const references = getReferenceItem(viewDOM, { ...neededInfo, item, name, pageName });
  style = style.replace(REFERENCE_BRACKET, references[0]).replace(BRACKET_NAME, name);
  if (referenceValue(VISIBLE, item, neededInfo.creatingComponent) === false) {
    const visible = LINE_JUMP + DISPLAY + TWO_POINTS_SPACE + NONE + SEMICOLON;
    style = style.replace(VISIBILITY_BRACKET, 'visible' in item && !item.visible ? visible : '');
  } else style = style.replace(VISIBILITY_BRACKET, '');
  style = fixNoAttibutesPageStyle(style, item, name, neededInfo);
  neededInfo.styles[pageName] = style + LINE_JUMP + neededInfo.styles[pageName];
  return neededInfo.hasAttributes;
}

export function pageCanvasMode (item: TypeItemProperties, page: string, neededInfo: ReactInfo): string {
  if (neededInfo.canvas) {
    const mVal = OPEN_PARENTESIS_STRING + item.id + SELECT_ITEM;
    neededInfo.objectClassnames[item.id].push([mVal, false]);
    page = page.replace(CANVA_BRACKET, ON_CLICK + item.id + ON_CLICK_CLOSE);
  } else page = page.replace(CANVA_BRACKET, '');
  return page;
}

export function createPage (item: TypeItemProperties, body: string, pageName: string, neededInfo: ReactInfo,
  dictionary: TypeDictionary, viewDOM: IComponentinfo): string {
  neededInfo.objectClassnames[item.id] = [];
  const name = getConvertNametoReact(item.name);
  const hasAttributes = createPageStyles(item, name, pageName, dictionary, neededInfo, viewDOM);
  let page = dictionary.screenTemplate.replaceAll(BRACKET_NAME, name);
  page = pageCanvasMode(item, page, neededInfo);
  if (hasAttributes === 1) {
    page = page.substring(0, page.indexOf(SQUARE_BRACKET) - 8) +
      page.substring(page.indexOf(CLOSE_SQUARE_BRACKET) + 1);
  }
  if ('tag' in item && item.tag != null) page = page.replaceAll(BRACKET_TAG, item.tag);
  else page = page.replaceAll(BRACKET_TAG, MAIN);
  page = page.replace(PROJECT_CONTENT, body + LINE_JUMP + (neededInfo.aguaEditables?.[1]?.screen ?? ''));
  if ('classname' in item && item.classname != null) {
    const classNames = item.classname.split(' ');
    for (const classname of classNames) neededInfo.objectClassnames[item.id].push([classname, true]);
    page = page.replace(CUSTOM_STYLES_BRACKET, '');
  } else page = page.replace(CUSTOM_STYLES_BRACKET, '');
  if (neededInfo.customStyles[pageName] != null) {
    const customVal = LINE_JUMP + IMPORT_CUSTOM_STYLES;
    page = page.replace(CUSTOMSTYLES_IMPORT, customVal);
  } else page = page.replace(CUSTOMSTYLES_IMPORT, '');
  page = page.replaceAll(NAME_STYLE, name.substring(0, 1).toLowerCase() + name.substring(1));
  page = page.replace(BRACKET_CLASSNAMES, handleClassnames(item, neededInfo));
  const pageHaveForm = neededInfo.pageHasForm[pageName] != null;
  page = page.replace(PAGE_HAVE_FORM, pageHaveForm ? LINE_JUMP + IMPORT_FORM : '');
  return page;
}
