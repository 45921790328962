/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BackgroundBeams } from '@components/backgroundBeams';
import { BackgroundGradient } from '@components/backgroundGradient';
import { IconButton, MainTitle } from '@components/index';
import { SingleLineInput } from '@components/singleLineInput';

import { getFiles } from '@reducers/files';
import { fetchDependencies, getSetup, getVSPath, setSetup } from '@reducers/graphs';
import { type AppDispatch } from '@reducers/store';

import { trackEvent } from '@globalUtils/metrics';

export const WithSetupReady = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const [entryEntryFilePath, setEntryEntryFilePath] = useState<string>('');
  const [entryEntryHTMLPath, setEntryEntryHTMLPath] = useState<string>('');
  const [reactRootId, setReactRootId] = useState<string>('');
  const [globalCSSPath, setGlobalCSSPath] = useState<string>('');
  const [entrySourceDirectoryPath, setEntrySourceDirectoryPath] = useState<string>('');
  const [TSConfigPath, setTSConfigPath] = useState<string>('');
  const [webpackConfigPath, setWebpackConfigPath] = useState<string>('');

  const filesData = useSelector(getFiles);
  const setup = useSelector(getSetup);
  const initializedSetup = setup.initializedSetup;

  const firstTime = useRef<boolean>(true);

  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to Setup Page');
  }, []);

  useEffect(() => {
    if (setup.initializedSetup) {
      void dispatch(fetchDependencies(JSON.stringify(setup.setup)));
      void dispatch(getVSPath());
      return;
    }
    const mEntry = getEntryEntryFilePath();
    const mEntryHTML = getEntryEntryHTMLPath();
    const mReactRoot = getReactRoot(mEntryHTML);
    const mGlobalCSS = getEntryGlobalCSSPath();
    setEntryEntryFilePath(mEntry);
    setEntryEntryHTMLPath(mEntryHTML);
    setReactRootId(mReactRoot);
    setGlobalCSSPath(mGlobalCSS);
    setEntrySourceDirectoryPath(getEntrySourceDirectoryPath(mEntry));
    setTSConfigPath(getTSConfigPath());
    setWebpackConfigPath(getWebpackConfigPath());
  }, [filesData, setup]);

  const getEntryEntryFilePath = (): string => {
    const filtered = filesData.filter((file) => {
      return (
        file.name.includes('index.js') ||
        file.name.includes('index.ts') ||
        file.name.includes('index.jsx') ||
        file.name.includes('index.tsx')
      );
    });
    filtered.sort((a, b) => a.name.split('/').length - b.name.split('/').length);
    if (filtered.length > 0) return './' + filtered.shift()?.name ?? '';
    return '';
  };

  const getEntryEntryHTMLPath = (): string => {
    const filtered = filesData.filter(
      (file) => file.name.includes('index.html') && !file.name.includes('agua.index.html')
    );
    filtered.sort((a, b) => a.name.split('/').length - b.name.split('/').length);
    if (filtered.length > 0) return './' + filtered.shift()?.name ?? '';
    return '';
  };

  const getReactRoot = (mEntryhtml: string): string => {
    if (mEntryhtml == null || mEntryhtml === '') return '';
    let entryhtml = mEntryhtml;
    if (entryhtml.startsWith('./')) entryhtml = entryhtml.substring(2);
    const mEntry = filesData.find((file) => file.name.endsWith(entryhtml));
    if (mEntry == null) return '';
    const data = mEntry.data;
    const mRegex = /( |\n)id="/g;
    let match;
    const matches: any = [];
    while ((match = mRegex.exec(data)) != null) {
      matches.push({
        data: data.substring(match.index, mRegex.lastIndex),
        location: [match.index, mRegex.lastIndex],
      });
    }
    const ids: string[] = [];
    for (const match of matches) {
      let bef = data.substring(0, match.location[1]);
      bef = bef.substring(bef.lastIndexOf('<') + 1).trim();
      bef = bef.substring(0, bef.indexOf(' '));
      if (bef === 'div' || bef === 'main' || bef === 'section' || bef === 'article') {
        let id = data.substring(match.location[1]);
        id = id.substring(0, id.indexOf('"'));
        ids.push(id);
      }
    }
    const idsWithSemanticName = ids.filter(
      (id) => id.includes('root') || id.includes('react') || id.includes('container') || id.includes('app')
    );
    if (idsWithSemanticName.length > 0) return idsWithSemanticName[0];
    return '';
  };

  const getEntryGlobalCSSPath = (): string => {
    const filtered = filesData.filter((file) => file.name.endsWith('.css') || file.name.endsWith('.scss'));
    filtered.sort((a, b) => a.name.split('/').length - b.name.split('/').length);
    if (filtered.length > 0) return './' + filtered.shift()?.name ?? '';
    return '';
  };

  const getEntrySourceDirectoryPath = (entryFile: string): string => {
    if (entryFile === '') return '';
    return entryFile.substring(0, entryFile.indexOf('index.'));
  };

  const getTSConfigPath = (): string => {
    const filtered = filesData.filter((file) => file.name.includes('tsconfig.json'));
    filtered.sort((a, b) => a.name.split('/').length - b.name.split('/').length);
    if (filtered.length > 0) return './' + filtered.shift()?.name ?? '';
    return '';
  };

  const getWebpackConfigPath = (): string => {
    const filtered = filesData.filter((file) => file.name.includes('webpack.config.js'));
    filtered.sort((a, b) => a.name.split('/').length - b.name.split('/').length);
    if (filtered.length > 0) return './' + filtered.shift()?.name ?? '';
    return '';
  };

  const handleChangeEntryFilePath = (val: string): void => {
    setEntryEntryFilePath(val);
  };

  const handleChangeEntryHTMLPath = (val: string): void => {
    setEntryEntryHTMLPath(val);
  };

  const handleChangeReactRoot = (val: string): void => {
    setReactRootId(val);
  };

  const handleChangeGlobalCSSPath = (val: string): void => {
    setGlobalCSSPath(val);
  };

  const handleChangeSourceDirectoryPath = (val: string): void => {
    setEntrySourceDirectoryPath(val);
  };

  const handleChangeTSConfigPath = (val: string): void => {
    setTSConfigPath(val);
  };

  const handleChangeWebpackConfigPath = (val: string): void => {
    setWebpackConfigPath(val);
  };

  const handleStart = (): void => {
    const mSetup = {
      filename: entryEntryFilePath,
      entryhtml: entryEntryHTMLPath,
      reactRoot: reactRootId,
      globalcss: globalCSSPath,
      directory: entrySourceDirectoryPath,
      tsConfig: TSConfigPath,
      webpackConfig: webpackConfigPath,
      excludeNodeModules: true,
    };
    dispatch(setSetup(mSetup));
  };

  const whiteSpace = <>&nbsp;</>;
  const projectName = 'Agua';

  if (initializedSetup) return children;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--background-color-0)',
      }}
    >
      <BackgroundGradient className="w-auto h-auto">
        <div
          style={{
            position: 'relative',
            zIndex: 2,
            width: '600px',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 'var(--padding-xl)',
            backgroundColor: 'var(--background-color-0)',
            borderRadius: 'var(--border-radius-xxl)',
          }}
        >
          <div style={{ display: 'flex', width: 'fit-content' }}>
            <MainTitle weight={300}>{t('verify-setup-1')}</MainTitle>
            <MainTitle weight={300}>{whiteSpace}</MainTitle>
            <MainTitle weight={300}>{projectName + "'s"}</MainTitle>
            <MainTitle weight={300}>{whiteSpace}</MainTitle>
            <MainTitle weight={300}>{t('verify-setup-2')}</MainTitle>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-xl)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-filename-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={entryEntryFilePath}
                onFinish={handleChangeEntryFilePath}
                placeholder={t('setup-filename-placeholder')}
              />
            </div>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-m)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-entryhtml-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={entryEntryHTMLPath}
                onFinish={handleChangeEntryHTMLPath}
                placeholder={t('setup-entryhtml-placeholder')}
              />
            </div>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-m)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-reactroot-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={reactRootId}
                onFinish={handleChangeReactRoot}
                placeholder={t('setup-reactroot-placeholder')}
              />
            </div>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-m)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-globalcss-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={globalCSSPath}
                onFinish={handleChangeGlobalCSSPath}
                placeholder={t('setup-globalcss-placeholder')}
              />
            </div>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-m)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-directory-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={entrySourceDirectoryPath}
                onFinish={handleChangeSourceDirectoryPath}
                placeholder={t('setup-directory-placeholder')}
              />
            </div>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-m)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-tsconfig-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={TSConfigPath}
                onFinish={handleChangeTSConfigPath}
                placeholder={t('setup-tsconfig-placeholder')}
              />
            </div>
          </div>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 'var(--padding-m)' }}
          >
            <div style={{ width: '120px', marginRight: 'var(--padding-m)', flexShrink: 0 }}>
              {t('setup-webpack-label')}
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <SingleLineInput
                defaultValue={webpackConfigPath}
                onFinish={handleChangeWebpackConfigPath}
                placeholder={t('setup-webpack-placeholder')}
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 'var(--padding-xl)',
            }}
          >
            <IconButton
              border=""
              width="100px"
              onClick={handleStart}
              disabled={
                entryEntryFilePath === '' || entrySourceDirectoryPath === '' || entryEntryHTMLPath === ''
              }
              icon={'play_arrow'}
              color="var(--title-color)"
              variant="success"
              iconSize="xlarge"
              hasIcon={false}
            >
              {t('vite-launch-button')}
            </IconButton>
          </div>
        </div>
      </BackgroundGradient>
      <BackgroundBeams />
    </div>
  );
};
