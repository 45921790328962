import { shuffle } from '@globalUtils/arrays';

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result == null) return { h: 0, s: 0, l: 0 };
  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  const l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return { h, s, l };
};

export const hslToHex = (h: number, s: number, l: number): string => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number): string => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const generateRandomColor = (): string => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) color += letters[Math.round(Math.random() * 15)];
  return color;
};

export const getRandomColorsArray = (n?: number): string[] => {
  let copy = JSON.parse(JSON.stringify(equallyDistributedColors)) as string[];
  shuffle(copy);
  if (n != null) copy = copy.slice(0, n);
  return copy;
};

export const doGetRandomRolor = (colors: string[]): string | undefined => {
  return colors.pop();
};

export const getRandomColor = (colors: string[], theme: 'dark' | 'light'): [string, string] => {
  let random = doGetRandomRolor(colors);
  if (random == null) random = generateRandomColor();
  const hsl = hexToHSL(random);
  const l = hsl.l * 100;
  const primaryL = theme === 'dark' ? (l >= 60 ? l : 60) : l <= 40 ? l : 40;
  const backgroundL = theme === 'dark' ? 7 : 95;
  const res = hslToHex(hsl.h * 100, hsl.s * 100, primaryL);
  const clearRes = hslToHex(hsl.h * 100, hsl.s * 100, backgroundL);
  return [res, clearRes];
};

export const equallyDistributedColors = [
  '#ff0000',
  '#735a56',
  '#ffb380',
  '#4c3300',
  '#85a600',
  '#118000',
  '#204035',
  '#263033',
  '#0044ff',
  '#3300bf',
  '#f780ff',
  '#735665',
  '#e50000',
  '#bf3300',
  '#bf8660',
  '#332200',
  '#475900',
  '#003300',
  '#bfffea',
  '#40bfff',
  '#3056bf',
  '#14004d',
  '#ad59b3',
  '#ff0066',
  '#d90000',
  '#7f2200',
  '#664733',
  '#e5ac39',
  '#e5ff80',
  '#204020',
  '#4d665e',
  '#206080',
  '#1a2e66',
  '#413366',
  '#e600d6',
  '#73002e',
  '#990000',
  '#4c1400',
  '#ffd9bf',
  '#8c6923',
  '#b8cc66',
  '#99cc99',
  '#008066',
  '#164359',
  '#101d40',
  '#3e394d',
  '#a6009b',
  '#a6295b',
  '#730000',
  '#ff7340',
  '#a68d7c',
  '#e5bf73',
  '#8a994d',
  '#698c69',
  '#36d9b8',
  '#103040',
  '#80a2ff',
  '#7736d9',
  '#4d394b',
  '#591631',
  '#4c0000',
  '#cc5c33',
  '#736256',
  '#665533',
  '#5c6633',
  '#4d664d',
  '#00a69b',
  '#538aa6',
  '#69738c',
  '#7453a6',
  '#8c0070',
  '#330d1c',
  '#ff4040',
  '#994526',
  '#4d4139',
  '#807560',
  '#2e331a',
  '#00f220',
  '#40fff2',
  '#99bbcc',
  '#364cd9',
  '#8800ff',
  '#e673cf',
  '#994d6b',
  '#a62929',
  '#e59173',
  '#ff8800',
  '#b28f00',
  '#ace639',
  '#36d94c',
  '#1d736d',
  '#005299',
  '#23318c',
  '#c480ff',
  '#402039',
  '#d9003a',
  '#7f2020',
  '#a66953',
  '#bf6600',
  '#665200',
  '#bbcc99',
  '#6cd97b',
  '#99ccc9',
  '#003d73',
  '#5965b3',
  '#271a33',
  '#ffbff2',
  '#990029',
  '#401010',
  '#734939',
  '#7f4400',
  '#ffd940',
  '#758060',
  '#004d14',
  '#008f99',
  '#00294d',
  '#404880',
  '#6f00a6',
  '#660044',
  '#4c0014',
  '#d96c6c',
  '#4c3026',
  '#402200',
  '#998a4d',
  '#525943',
  '#bfffd0',
  '#40f2ff',
  '#001b33',
  '#99a0cc',
  '#4d0073',
  '#40002b',
  '#bf3056',
  '#733939',
  '#332a26',
  '#ffa640',
  '#fff2bf',
  '#81f200',
  '#00a642',
  '#103d40',
  '#368dd9',
  '#0000ff',
  '#220033',
  '#cc3399',
  '#d96c89',
  '#4c2626',
  '#ff6600',
  '#996326',
  '#b3aa86',
  '#224000',
  '#008033',
  '#6cd2d9',
  '#235b8c',
  '#000099',
  '#bf40ff',
  '#a6538a',
  '#733949',
  '#4d3939',
  '#bf4d00',
  '#734b1d',
  '#4d4939',
  '#5b8c23',
  '#40ff8c',
  '#2d5659',
  '#1a4266',
  '#101040',
  '#431659',
  '#733960',
  '#402028',
  '#d94c36',
  '#8c3800',
  '#ffc480',
  '#a69b00',
  '#2d3326',
  '#86b398',
  '#698a8c',
  '#6093bf',
  '#8080ff',
  '#6a4080',
  '#cc99bb',
  '#d9a3b1',
  '#66241a',
  '#401a00',
  '#bf9360',
  '#736d1d',
  '#38661a',
  '#1a6642',
  '#00a3cc',
  '#bfe1ff',
  '#bfbfff',
  '#c7a3d9',
  '#33262f',
  '#bf001a',
  '#ff9180',
  '#ff8c40',
  '#8c6c46',
  '#403d10',
  '#b2ff80',
  '#80ffc3',
  '#40d9ff',
  '#566573',
  '#737399',
  '#987ca6',
  '#d90074',
  '#330007',
  '#b26559',
  '#bf6930',
  '#403120',
  '#e5de73',
  '#d9ffbf',
  '#53a67f',
  '#1d6273',
  '#397ee6',
  '#0f0073',
  '#502d59',
  '#ff40a6',
  '#ff8091',
  '#331d1a',
  '#8c4d23',
  '#ffe1bf',
  '#e2f200',
  '#50b32d',
  '#698c7c',
  '#60acbf',
  '#1a2433',
  '#3a29a6',
  '#6d5673',
  '#802053',
  '#a6535e',
  '#ffc8bf',
  '#66381a',
  '#ffaa00',
  '#778000',
  '#71b359',
  '#00cc88',
  '#bff2ff',
  '#a3b8d9',
  '#241966',
  '#6d1d73',
  '#e673b0',
  '#8c696e',
  '#bf968f',
  '#331c0d',
  '#bf8000',
  '#c2cc33',
  '#39592d',
  '#134d39',
  '#435559',
  '#303640',
  '#5a5673',
  '#3d1040',
  '#997387',
];
