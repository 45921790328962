export interface TypeTextDicitionary {
  fontSize: string;
  rightTextAlignment: string;
  centerTextAlignment: string;
  leftTextAlignment: string;
  textSizeEnding: string;
  normalTextWeight: string;
  boldTextWeight: string;
  italicTextWeight: string;
  fontOpen: string;
  fontClose: string;
  defaultFont: string;
}

export const ReactTextDictionary: TypeTextDicitionary = {
  fontSize: 'font-size: ',
  rightTextAlignment: 'text-align: right;',
  centerTextAlignment: 'text-align: center;',
  leftTextAlignment: 'text-align: left;',
  textSizeEnding: ';',
  normalTextWeight: 'font-weight: normal;',
  boldTextWeight: 'font-weight: bold;',
  italicTextWeight: 'font-style: italic;',
  fontOpen: 'font-family: ',
  fontClose: ';',
  defaultFont: `font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
            Helvetica, Roboto, Oxygen, Arial, "Apple Color Emoji", "Segoe UI Emoji",
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;`,
};
