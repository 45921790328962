import { ReactColorDictionary, type TypeColorDictionary } from './colorDictionary';
import { ReactDimensionDictionary, type TypeDimensionDictionary } from './dimensionDictionary';
import { ReactFileDictionary, type TypeFileDictionary } from './fileDictionary';
import { ReactImageDictionary, type TypeImageDictionary } from './imageDictionary';
import { ReactItemDictionary, type TypeItemDictionary } from './itemDictionary';
import { ReactLayerDictionary, type TypeLayerDictionary } from './layerDictionary';
import {
  ReactOrientationAlignDictionary,
  type TypeOrientationAlignDictionary
} from './orientationAlignDictionary';
import { ReactPunctuationDictionary, type TypePunctuactionDictionary } from './punctuationDictionary';
import { ReactTextDictionary, type TypeTextDicitionary } from './textDictionary';

export interface TypeDictionary extends TypeColorDictionary, TypeDimensionDictionary, TypeFileDictionary,
  TypeImageDictionary, TypeItemDictionary, TypeLayerDictionary, TypeOrientationAlignDictionary,
  TypePunctuactionDictionary, TypeTextDicitionary {

};

class Dictionary {
  react: TypeDictionary;
  constructor () {
    this.react = {
      ...ReactColorDictionary,
      ...ReactDimensionDictionary,
      ...ReactFileDictionary,
      ...ReactImageDictionary,
      ...ReactItemDictionary,
      ...ReactLayerDictionary,
      ...ReactOrientationAlignDictionary,
      ...ReactPunctuationDictionary,
      ...ReactTextDictionary,
    };
  }
}

export { Dictionary };
