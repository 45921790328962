export const EXACT = 'exact({\n';
export const PROPSTYPE_CLOSE = '\n}),\n';
export const PROPSTYPE_EQUAL = '.propTypes = {\n';
export const EQUAL_BRACKEST_COMMA = ' = {},';
export const A_PARAM = '@param {';
export const PROP_COMPONENT_INSTANCE = '[props.{componentInstanceName}.';
export const SUBCOMPONENT = ' subcomponent prop.';
export const SQUARE_SUBCOMP = '] The subcomponent ';
export const THE = '- The ';
export const PROP = ' props.';
export const PARAM_OBJECT = ' * @param {Object} props\n';
export const PARAM_OBJ = ' * @param {object} [props.';
export const POINT_PROP = '[props.';
export const SET = '") set';
export const SET_OPEN = '("';
export const SET_CLOSE = '");';
export const CONST_OPEN = 'const [';
export const CONST_CLOSE = '] = useState("';
export const COMMA_SET = ', set';
export const IF = 'if(';
export const COMPONENT_INSTANCE = '{componentInstanceName}';
export const COMPONENT_IMPORT = '{componentImports}';
export const COMPONENT_VARIABLE = '{componentVariable}';
export const COMPONENT_VARIABLES = 'componentVariables';
export const COMPONENT = 'Component';
export const COMPONENT_MODE_INFO = '<{componentName} {childrenVariables}/>';
export const COMPONENT_NAME = '{componentName}';
export const COMPONENT_MODE = 'ComponentMode';
export const COMPONENT_LAYOUT = '{componentOrLayout}';
export const COMPONENT_ENTRY_FILE = '{componentEntryFile}';
export const COMPONENT_MODE_IMPORT = 'import ComponentMode from "./agua/pages/page/componentMode.jsx";';
export const COMPONENTMODE_IMPORT = 'import ComponentMode from "../pages/page/componentMode.jsx";';
export const COMPONENT_MODE_HTML = '<ComponentMode />';
export const ROUTES_STRING = `<Route path="/" element={<ComponentMode />} />
<Route path="/ComponentMode" element={<ComponentMode />} />`;
export const COMPONENT_MODE_ARCHIVE = 'componentMode.jsx';
export const PROPSDOC = '{propsdocs}';
export const PROPSTYPE = '{proptypes}';
export const PROPSTYPEIMPORT = '{proptypesImport}';
export const PROPSTYPE_IMPORT = 'import PropTypes from "prop-types";\n';
export const PROPS = '{props}';
export const CUSTOM_COMPONENT_HTML = ' {childrenVariables} {parameters} />';
export const FROM_COMPONETS = ' from "../../components/';
export const IS_NESTED = 'isNestedReference-';
export const POINT_PROPTYPE = ': PropTypes.';
export const LAYOUTS = 'layouts';
export const COMPONENTS = 'components';
export const COMPONENT_BRACKET = '{component}';
