export const BACKGROUND_IMAGE_ATTRIBUTES = `background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
`;
export const BACKGROUND_IMAGE_CSS = 'background-image';
export const BACKGROUND_IMAGE = 'backgroundImage';
export const BORDER_RADIUS = 'border-radius';
export const Z_INDEX_CSS = 'z-index';
export const OVERFLOW = 'overflow';
export const FLEX_WRAP = 'flex-wrap';
export const FLEX_SHRINK = 'flex-shrink';
export const STATIC = 'static';
export const BORDER_COLOR = 'borderColor';
export const BORDER_WIDTH = 'borderWidth';
export const BORDER_COLOR_CSS = 'border-color';
export const BORDER_WIDTH_CSS = 'border-width';
export const BORDER_STYLE_CSS = 'border-style';
export const SHADOW_OFFSET_X = 'shadowOffsetX';
export const SHADOW_OFFSET_Y = 'shadowOffsetY';
export const SHADOW_BLUR = 'shadowBlur';
export const SHADOW_COLOR = 'shadowColor';
export const BRACKET_OFFSET_X = '{OffsetX}';
export const BRACKET_OFFSET_Y = '{OffsetY}';
export const BRACKET_BLUR = '{Blur}';
export const BRACKET_COLOR = '{Color}';
export const LAYER = 'layer';
export const OFFSET_X = 'offSetX';
export const OFFSET_Y = 'offSetY';
export const BLUR = 'blur';
export const COLOR = 'color';
export const SHADOW = 'shadow';
export const Z_INDEX = 'zindex';
export const TOP_LEFT = 'topLeft';
export const TOP_RIGHT = 'topRight';
export const BOTTOM_LEFT = 'bottomLeft';
export const BOTTOM_RIGHT = 'bottomRight';
export const RADIUS = 'radius';
export const BORDER = 'border';
export const RADIUS_ABSOLUTE = 'radiusAbsolute';
export const BRACKET_BORDER = '{border}';
export const BRACKET_SHADOW = '{shadow}';
export const BRACKET_WRAP = '{wrap}';
export const BRACKET_Z_INDEX = '{zIndex}';
export const BRACKET_BORDER_STYLE = '{borderStyle}';
export const LAYER_BORDER = '(layerBorder ? "layerBorder":"")';
export const BOX_SIZING = 'box-sizing';
export const BORDER_BOX = 'border-box';
export const BOX_SIZING_BRACKET = '{boxSizing}';
export const BOX_SHADOW = ', boxShadow: ';
export const BOX_SHADOW_CSS = 'box-shadow';
export const CSS_POSITION = 'cssPosition';
export const POSITION = 'position';
export const SECTION = 'section';
export const FORM = 'Form';
export const TRANSPARENT = 'transparent';
export const BORDERRADIUS = 'borderRadius';
