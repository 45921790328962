import { type PayloadAction } from '@reduxjs/toolkit';

import { type ItemsType } from '..';
import { ImageViewSrcType } from '@globalTypes/itemProperties';
import { checkResponsiveCase } from './itemUtils';

interface ImageViewSrcPayload {
  [ImageViewSrcType.ImageViewSrcProperty]: ImageViewSrcType.ImageViewSrcType;
  id: string;
};
export const changeImageViewSrc = (state: ItemsType, action: PayloadAction<ImageViewSrcPayload>): void => {
  const { id } = action.payload;
  const src = action.payload[ImageViewSrcType.ImageViewSrcProperty];
  const item = state.items.byId[id];
  if (ImageViewSrcType.ImageViewSrcProperty in item) {
    checkResponsiveCase(state, id, src, 'src', item[ImageViewSrcType.ImageViewSrcProperty]);
    item[ImageViewSrcType.ImageViewSrcProperty] = src;
  }
};
