/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CardBody, CardContainer, CardItem } from '@components/3dcard';
import { Icon } from '@components/icon';

import { getAssets, getFiles } from '@reducers/files';

import { isFont, isMedia } from '@globalUtils/strings';

export const HomePage = (): React.JSX.Element => {
  const { t } = useTranslation();

  const files = useSelector(getFiles);
  const assets = useSelector(getAssets);

  const linesOfCode = useMemo(() => {
    let sum = 0;
    files.forEach((file) => {
      const fileName = file.name;
      if (!isMedia(fileName) && !isFont(fileName)) sum += file.data.split('/').length;
    });
    return sum;
  }, [files]);

  return (
    <div className={'h-full w-full relative flex items-center justify-center bg-dot-white/[0.2]'}>
      <div
        style={{ backgroundColor: 'black' }}
        className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"
      ></div>
      <div
        style={{
          position: 'relative',
          zIndex: 2,
          width: '800px',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 'var(--padding-xl)',
          borderRadius: 'var(--border-radius-xxl)',
        }}
      >
        <div
          style={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img style={{ height: '40px', width: 'fit-content' }} src="/agua_white.png"></img>
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
            <div
              style={{
                fontSize: 'var(--font-size-display-main-cta)',
                fontWeight: 600,
                letterSpacing: '1px',
              }}
            >
              {t('agua-label')}
            </div>
            <div
              style={{
                marginLeft: 'var(--padding-m)',
                fontSize: 'var(--font-size-display-main-cta)',
                fontWeight: 600,
                color: 'var(--secondary-color)',
                letterSpacing: '1px',
              }}
            >
              {t('for-devs-label')}
            </div>
          </div>
          <div
            style={{
              fontSize: 'var(--font-size-body)',
              color: 'var(--title-color)',
            }}
          >{`v${APP_VERSION}`}</div>
          <div
            style={{
              width: '100%',
              marginTop: 'var(--padding-m)',
              height: 'fit-content',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 'var(--padding-l)',
              justifyContent: 'center',
            }}
          >
            <HomePageCard
              icon={<Icon icon="workspaces" color="var(--title-color)" iconSize="xxlarge" />}
              title={'Coming soon'}
              number={'Components'}
              newLine
            />
            <HomePageCard
              icon={<Icon icon="text_snippet" color="var(--title-color)" iconSize="xxlarge" />}
              title={'files'}
              number={files.length}
            />
            <HomePageCard
              icon={<Icon icon="code_blocks" color="var(--title-color)" iconSize="xxlarge" />}
              title={'lines of code'}
              number={linesOfCode}
            />
            <HomePageCard
              icon={<Icon icon="account_tree" color="var(--title-color)" iconSize="xxlarge" />}
              title={'Coming soon'}
              number={'Types - '}
            />
            <HomePageCard
              icon={<Icon icon="photo_library" color="var(--title-color)" iconSize="xxlarge" />}
              title={'assets'}
              number={assets.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const HomePageCard = ({
  icon,
  title,
  number,
  newLine = false,
}: {
  icon: React.JSX.Element;
  title: string;
  number: number | string;
  newLine?: boolean;
}): React.JSX.Element => {
  return (
    <CardContainer className="inter-var">
      <CardBody className="relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
        <CardItem translateZ="60" className="font-bold text-white">
          {icon}
        </CardItem>
        <CardItem translateZ="60" className="font-bold text-white">
          <div style={{ height: 'calc(var(--padding-m) * 2 / 3)', width: '1px' }}></div>
        </CardItem>
        <CardItem translateZ="70" className="max-w-sm mt-2 text-neutral-300">
          <div>{`${number}${newLine ? '' : title}`}</div>
          {newLine ? <div>{title}</div> : <></>}
        </CardItem>
      </CardBody>
    </CardContainer>
  );
};
