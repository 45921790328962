import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Tabs } from '@fragments/tabs';

import { IconButton } from '@components/iconButton';
import { MultiSelect } from '@components/multiselect';
import { SingleLineInput } from '@components/singleLineInput';
import { type ITab } from '@components/tab';

import { getSelectedAPIItem } from '@reducers/index';

import styles from './styles.module.css';

export const APIRequestPane = (): React.JSX.Element => {
  const { t } = useTranslation();

  const selectedItem = useSelector(getSelectedAPIItem);
  console.log(selectedItem);

  const tabs: ITab[] = [
    {
      label: t('api-documentation-tab-label'),
      value: 'documentation',
    },
    {
      label: t('api-params-tab-label'),
      value: 'params',
    },
    {
      label: t('api-headers-tab-label'),
      value: 'headers',
    },
    {
      label: t('api-body-tab-label'),
      value: 'body',
    },
  ];

  const handleChangeTab = (tab: ITab): void => {
    console.log('changed tab to ' + tab.value);
  };

  if (selectedItem != null) {
    return (
      <div className={styles.requestContainer}>
        <div className={styles.pathContainer}>
          <div>Untitled Request</div>
          <IconButton
            alignment="flex-start"
            icon="save"
            textColor="var(--title-color-contrast)"
            color="var(--title-color-contrast)"
          >
            Save
          </IconButton>
        </div>
        <div className={styles.inputContainer}>
          <MultiSelect
            width="80px"
            minWidth="80px"
            preventRightBorderRadius={true}
            options={[]}
            placeholder={''}
            emptyPlaceholder={''}
            searchPlaceholder={''}
          />
          <SingleLineInput preventLeftBorderRadius={true} placeholder={t('placeholder-api-request-input')} />
        </div>
        <div
          style={{
            paddingTop: 'var(--padding-l)',
            width: '100%',
          }}
        >
          <Tabs tabs={tabs} notifySelectedTab={handleChangeTab} />
        </div>

        <div className={styles.tabsContentContainer}>
          <div>Query Params</div>
          <table>
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"></th>
                <td>Key</td>
                <td>Value</td>
                <td>Description</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return <div>{t('select-api-item-label')}</div>;
};
