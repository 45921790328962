import {
  componentChildrenReferencesValues,
  getLayoutModeChildrenVariables
} from '../reactComponentMethods/reactComponentChildrenVariablesMethods';
import { isComponentALayout } from '../reactComponentMethods/reactComponentUtils';
import { createElement, startElement } from '../reactItemMethods/reactHTMLMethods/reactItemMethods';
import { adaptItemName } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { getReferenceItem } from '../reactItemMethods/reactCssMethods/reactReferenceMethods';
import { handleImports, hrefHandle, updatePageReferences } from './reactFileUtils';
import {
  AGUANO_CLOSE,
  AGUANO_DECLARATION,
  AGUA_NOT_OVERWRITABLE,
  ANIMATION_IMPORTS,
  ANIMATION_REFS,
  BRACKET,
  BRACKET_NAME,
  CANVAS,
  CANVAS_BRACKET,
  CANVAS_MODE,
  CHILDREN,
  CHILDREN_VARIABLES,
  CLOSE_BRACKET,
  CLOSING_PARENTESIS,
  COMPONENT_BRACKET,
  COMPONENT_IMPORT,
  CUSTOM,
  CUSTOMSTYLES_IMPORT,
  EQUAL_BRACKET,
  FROM,
  FUNCTION,
  FUN_OPEN,
  HAS_ON_CLICK,
  HREF_BRACKET,
  IMPORT,
  IMPORT_CUSTOM,
  IMPORT_USE,
  JSX_FORMAT,
  LESS_THAN,
  LINE_JUMP,
  MINUS,
  MODULE_CSS_FORMAT,
  MORE_THAN,
  PAGENAME_BRACKET,
  PAGE_EXTERNAL_FILE,
  PAGE_EXTERNAL_FUNCTIONS,
  PARAMETERS,
  PARENTESIS_BRACKECT,
  POINT,
  REACT_OBSERVER,
  REFERENCES_BRACKET,
  RETURN_PARENTESIS,
  SAME_PATH,
  SELECT_ITEM,
  SEMICOLON,
  SEMICOLON_LINE_JUMP,
  SLASH,
  UNDERSCORE,
  USE_CLIENT
} from '../../constants/constants';
import { type LayerProperties, type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  type TypeItems,
  type ReactInfo,
  type TypeItemData,
  type IComponentinfo
} from '@globalTypes/conversor';
import { type ITreeNode } from '@globalUtils/tree';
import { type TypeDictionary } from '../dictionary/dictionary';

export function componenteChildrenParameters (neededInfo: ReactInfo, item: LayerProperties,
  component: string, viewDOM: IComponentinfo): string {
  const componentInfo = viewDOM.getComponentByName(item.component, viewDOM.components);
  if (componentInfo?.code == null || typeof (componentInfo.code) === 'string') {
    window.logger.warn('component ' + component + ' not found');
    return component.replace(PARAMETERS, '');
  }
  const code = componentInfo.code;
  if (code == null) return component.replace(PARAMETERS, '');
  const values = code.parameters;
  let parameters = '';
  for (const value of values) {
    const name = adaptItemName(item.name, true);
    if (neededInfo.creatingComponent) {
      parameters += value.name + EQUAL_BRACKET + ' ' + name + POINT + value.name + CLOSE_BRACKET + ' ';
    }
  }
  return component.replace(PARAMETERS, parameters);
}

export function createComponentChildren (neededInfo: ReactInfo, pageName: string, item: LayerProperties,
  actualComponent: string, viewDOM: IComponentinfo, dictionary: TypeDictionary): string {
  if (neededInfo.componentsUsed[pageName] == null) neededInfo.componentsUsed[pageName] = [];
  if (!neededInfo.componentsUsed[pageName].includes(actualComponent)) {
    neededInfo.componentsUsed[pageName].push(actualComponent);
  }
  neededInfo.objectClassnames[item.id] = [];
  let component = neededInfo.componentCreated[actualComponent][0];
  const name = adaptItemName(item.name, false);
  neededInfo.item = item;
  neededInfo.name = name;
  neededInfo.pageName = pageName;
  const references = getReferenceItem(viewDOM, neededInfo);
  component = component.replace(REFERENCES_BRACKET, references[0]);
  const compName = 'component' in item && item.component !== '' ? item.component : '';
  const isLayout = isComponentALayout(compName, neededInfo.componentsUsed);
  const refVal = isLayout ? [...references[1], item.name + UNDERSCORE + compName] : [...references[1]];
  updatePageReferences(pageName, refVal, neededInfo);
  if (neededInfo.creatingComponent) {
    component = component.replace(CHILDREN_VARIABLES, getLayoutModeChildrenVariables(item, viewDOM));
  } else component = componentChildrenReferencesValues(component, item, pageName, neededInfo, dictionary);
  const hrefVal = hrefHandle(item, false, neededInfo.creatingComponent ? name : '', neededInfo,
    dictionary);
  component = component.replace(HREF_BRACKET, hrefVal);
  if (component.includes(PARAMETERS)) {
    component = componenteChildrenParameters(neededInfo, item, component, viewDOM);
  }
  return LINE_JUMP + component;
}

export function createExternalFuctionChildren (components: string, item: TypeItemProperties,
  dictionary: TypeDictionary, neededInfo: ReactInfo, viewDOM: IComponentinfo, items: TypeItems,
  treeRoot: ITreeNode[]): string {
  let component = LINE_JUMP + startElement(item.commonKind, dictionary);
  component = createElement(item, component, items, dictionary, neededInfo, viewDOM);
  component = component.replace(HREF_BRACKET, hrefHandle(item, false, item.name, neededInfo, dictionary));
  if (treeRoot.length > 0) {
    const children = createTreeParent(treeRoot, items, neededInfo.pageName, neededInfo, dictionary, viewDOM);
    component = component.replace(CHILDREN, children);
  } else component = component.replace(CHILDREN, '');
  const inVal = FUN_OPEN + RETURN_PARENTESIS + LINE_JUMP + component + LINE_JUMP + CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP + CLOSE_BRACKET + SEMICOLON_LINE_JUMP;
  const exVal = LINE_JUMP + FUNCTION + item.name.replaceAll(MINUS, UNDERSCORE) + inVal;
  neededInfo.pageExternalFunctions[neededInfo.pageName] += exVal;
  if (neededInfo.aguaEditables?.[0][item.name + MINUS + item.id] != null) {
    const aguaVal = AGUANO_DECLARATION + item.id + AGUANO_CLOSE + item.id + SELECT_ITEM + CLOSE_BRACKET;
    const editVal = neededInfo.aguaEditables[0][item.name + MINUS + item.id];
    components += (neededInfo.canvas ? editVal.replace(AGUA_NOT_OVERWRITABLE, aguaVal) : editVal);
  } else components += BRACKET + ' ' + item.name.replaceAll(MINUS, UNDERSCORE) + PARENTESIS_BRACKECT;
  return components;
}

export function externalFileChildren (dictionary: TypeDictionary, neededInfo: ReactInfo,
  viewDOM: IComponentinfo, file: string, items: TypeItems, item: TypeItemProperties,
  treeRoot: ITreeNode[]): string {
  const children = createTreeParent(treeRoot, items, item.name, neededInfo, dictionary, viewDOM);
  const aguaEdit = neededInfo.aguaEditables?.[1][item.name + MINUS + item.id] ?? '';
  file = file.replace(CHILDREN, children + LINE_JUMP + aguaEdit);
  neededInfo.styles[neededInfo.pageName] += neededInfo.styles[item.name];
  file = handleImports(neededInfo.componentsUsed[item.name], file, neededInfo);
  file = file.replace(PAGE_EXTERNAL_FUNCTIONS, neededInfo.pageExternalFunctions[item.name]);
  file = file.replace(PAGE_EXTERNAL_FILE, neededInfo.pageExternalFile[item.name]);
  if (neededInfo.pageAnimationVisible[item.name] !== '') {
    const aniImport = USE_CLIENT + LINE_JUMP + REACT_OBSERVER + SEMICOLON_LINE_JUMP;
    file = file.replace(ANIMATION_IMPORTS, aniImport);
    file = file.replace(ANIMATION_REFS, neededInfo.pageAnimationVisible[item.name]);
  } else file = file.replace(ANIMATION_IMPORTS, '').replace(ANIMATION_REFS, '');
  const onClickVer = neededInfo.pageHasChildrenHrefExternal[item.name] != null && !neededInfo.canvas;
  file = file.replace(HAS_ON_CLICK, onClickVer ? dictionary.openInNewTab : '');
  const archiveName = item.name.substring(0, 1).toLowerCase() +
    item.name.substring(1).replaceAll(MINUS, UNDERSCORE);
  if (neededInfo.customStyles[item.name] != null && neededInfo.customStyles[item.name] !== '') {
    const importVal = LINE_JUMP + IMPORT_CUSTOM + archiveName + MODULE_CSS_FORMAT + '"' + SEMICOLON;
    file = file.replace(CUSTOMSTYLES_IMPORT, importVal);
  } else file = file.replace(CUSTOMSTYLES_IMPORT, '');
  if (neededInfo.customStyles[item.name] != null) {
    neededInfo.pageExternalFiles[CUSTOM + archiveName + MODULE_CSS_FORMAT] =
      neededInfo.customStyles[item.name];
  }
  return file.replace(COMPONENT_IMPORT, '');
}

export function externalFileNoChildren (file: string): string {
  file = file.replace(CHILDREN, '');
  file = file.replace(ANIMATION_IMPORTS, '');
  file = file.replace(ANIMATION_REFS, '');
  file = file.replace(HAS_ON_CLICK, '');
  file = file.replace(PAGE_EXTERNAL_FUNCTIONS, '');
  file = file.replace(PAGE_EXTERNAL_FILE, '');
  file = file.replace(CUSTOMSTYLES_IMPORT, '');
  file = file.replace(COMPONENT_IMPORT, '');
  return file;
}

export function addExternalFile (file: string, fileName: string, archiveName: string, components: string,
  neededInfo: ReactInfo, item: TypeItemProperties): string {
  if (neededInfo.pageExternalFile[neededInfo.pageName] == null) {
    neededInfo.pageExternalFile[neededInfo.pageName] = '';
  }
  neededInfo.pageExternalFile[neededInfo.pageName] += IMPORT + ' ' + fileName + FROM + '"' + SAME_PATH +
    archiveName + JSX_FORMAT + '"' + SEMICOLON_LINE_JUMP;
  neededInfo.pageExternalFiles[neededInfo.pageName][archiveName] = file;
  for (const externalFile in neededInfo.pageExternalFiles[item.name]) {
    neededInfo.pageExternalFiles[neededInfo.pageName][externalFile] =
      neededInfo.pageExternalFiles[item.name][externalFile];
  }
  if (neededInfo.aguaEditables?.[0][item.name + MINUS + item.id] != null) {
    const aguaVal = AGUANO_DECLARATION + item.id + AGUANO_CLOSE + item.id + SELECT_ITEM + CLOSE_BRACKET;
    const aguaEdit = neededInfo.aguaEditables[0][item.name + MINUS + item.id];
    components += (neededInfo.canvas ? aguaEdit.replace(AGUA_NOT_OVERWRITABLE, aguaVal) : aguaEdit);
  } else components += LESS_THAN + fileName + SLASH + MORE_THAN;
  return components;
}

export function createExternalFileChildren (item: TypeItemProperties, components: string,
  neededInfo: ReactInfo, items: TypeItems, dictionary: TypeDictionary, treeRoot: ITreeNode[],
  viewDOM: IComponentinfo): string {
  let component = LINE_JUMP + startElement(item.commonKind, dictionary);
  component = createElement(item, component, items, dictionary, neededInfo, viewDOM);
  component = component.replace(HREF_BRACKET, hrefHandle(item, false, item.name, neededInfo, dictionary));
  let file = dictionary.baseExternalFile;
  const fileName = item.name.substring(0, 1).toUpperCase() +
    item.name.substring(1).replaceAll(MINUS, UNDERSCORE);
  const filePageName = neededInfo.pageName.substring(0, 1).toLowerCase() + neededInfo.pageName.substring(1);
  const archiveName = item.name.substring(0, 1).toLowerCase() +
    item.name.substring(1).replaceAll(MINUS, UNDERSCORE);
  file = file.replace(COMPONENT_BRACKET, component).replaceAll(BRACKET_NAME, fileName);
  file = file.replace(PAGENAME_BRACKET, filePageName);
  if (treeRoot.length > 0) {
    neededInfo.customStyles[item.name] = '';
    neededInfo.pageAnimationVisible[item.name] = '';
    neededInfo.pageExternalFunctions[item.name] = '';
    neededInfo.pageExternalFile[item.name] = '';
    neededInfo.pageExternalFiles[item.name] = {};
    file = externalFileChildren(dictionary, neededInfo, viewDOM, file, items, item, treeRoot);
  } else {
    file = externalFileNoChildren(file);
  }
  file = file.replace(CANVAS_BRACKET, neededInfo.canvas ? dictionary.selectItem : '');
  const canvasMode = neededInfo.canvas ? IMPORT_USE : '';
  file = file.replace(CANVAS_MODE, canvasMode);
  components = addExternalFile(file, fileName, archiveName, components, neededInfo, item);
  return components;
}

export function createNormalChildren (item: TypeItemProperties, dictionary: TypeDictionary,
  components: string, items: TypeItems, neededInfo: ReactInfo, viewDOM: IComponentinfo,
  treeRoot: ITreeNode[]): string {
  let component = LINE_JUMP + startElement(item.commonKind, dictionary);
  component = createElement(item, component, items, dictionary, neededInfo, viewDOM);
  if ('component' in item && item.component != null && item.component === neededInfo.pageName) {
    component = component.replace(HREF_BRACKET, '');
  } else {
    component = component.replace(HREF_BRACKET, hrefHandle(item, false, item.name, neededInfo, dictionary));
  }
  if (treeRoot.length > 0) {
    const childValue =
      createTreeParent(treeRoot, items, neededInfo.pageName, neededInfo, dictionary, viewDOM);
    const aguaEditables = (neededInfo.aguaEditables?.[1][item.name + MINUS + item.id] ?? '');
    component = component.replace(CHILDREN, childValue + LINE_JUMP + aguaEditables);
  }
  if (component.includes(CHILDREN)) component = component.replace(CHILDREN, '');
  if (neededInfo.aguaEditables?.[0][item.name + MINUS + item.id] != null) {
    const aguaEditables = neededInfo.aguaEditables[0][item.name + MINUS + item.id];
    const aguaVal = AGUANO_DECLARATION + item.id + AGUANO_CLOSE + item.id + SELECT_ITEM + CLOSE_BRACKET;
    components += (neededInfo.canvas ? aguaEditables.replace(aguaVal) : aguaEditables);
  } else components += component + dictionary.endingChildren;
  return components;
}

export function getActualComponent (neededInfo: ReactInfo, item: TypeItemProperties): string {
  let actualComponent = '';
  for (const comp in neededInfo.componentCreated) {
    if ('component' in item && item.component != null && item.component !== '' &&
        comp.toLowerCase() === item.component.toLowerCase()) {
      actualComponent = comp;
    }
  }
  return actualComponent;
}

export function createNonComponentChildren (components: string, data: TypeItemData,
  dictionary: TypeDictionary, neededInfo: ReactInfo, viewDOM: IComponentinfo): string {
  const treeRoot = data.treeItem.children;
  if ('externalFunction' in data.item && data.item.externalFunction != null && data.item.externalFunction) {
    components = createExternalFuctionChildren(components, data.item, dictionary, neededInfo, viewDOM,
      data.items, treeRoot);
  } else if ('externalFile' in data.item && data.item.externalFile != null && data.item.externalFile) {
    components = createExternalFileChildren(data.item, components, neededInfo, data.items, dictionary,
      treeRoot, viewDOM);
  } else {
    components = createNormalChildren(data.item, dictionary, components, data.items, neededInfo, viewDOM,
      treeRoot);
  }
  return components;
}

export function createTreeParent (tree: ITreeNode[], items: TypeItems, pageName: string,
  neededInfo: ReactInfo, dictionary: TypeDictionary, viewDOM: IComponentinfo): string {
  let components = '';
  if (neededInfo.pageName === '') neededInfo.pageName = pageName;
  for (const treeItem of tree) {
    const item = items[treeItem.id];
    if ('href' in item && item.href != null && item.href !== CANVAS) {
      if (item.href.includes(POINT)) neededInfo.pageHasChildrenHrefExternal[pageName] = true;
      neededInfo.pageHasChildrenHref[pageName] = true;
    }
    const actualComponent = getActualComponent(neededInfo, item);
    if ('component' in item && item.component != null &&
        neededInfo.componentCreated[actualComponent] != null &&
        actualComponent !== pageName) {
      components += createComponentChildren(neededInfo, pageName, item, actualComponent, viewDOM, dictionary);
    } else {
      const data = { tree, items, treeItem, item };
      components = createNonComponentChildren(components, data, dictionary, neededInfo, viewDOM);
    }
  }
  return components;
}
