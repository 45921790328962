import { BACKGROUND, DATA, KIND } from '@globalTypes/constants';

export namespace BackgroundType {
  export type BackgroundProperty = 'background';
  export const BackgroundProperty: BackgroundProperty = BACKGROUND;
  export type BackgroundKindProperty = 'kind';
  export const BackgroundKindProperty: BackgroundKindProperty = KIND;
  export type BackgroundDataProperty = 'data';
  export const BackgroundDataProperty: BackgroundDataProperty = DATA;

  export interface BackgroundType {
    [BackgroundKindProperty]: string;
    [BackgroundDataProperty]: string | string[];
  }
}
