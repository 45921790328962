import { HORIZONTAL_ALIGNMENT, VERTICAL_ALIGNMENT } from '@globalTypes/constants/';

export namespace VerticalAlignmentType {
  export type VerticalAlignmentProperty = 'verticalAlignment';
  export const VerticalAlignmentProperty: VerticalAlignmentProperty = VERTICAL_ALIGNMENT;

  export type VerticalAlignmentType = string;
}
export namespace HorizontalAlignmentType {
  export type HorizontalAlignmentProperty = 'horizontalAlignment';
  export const HorizontalAlignmentProperty: HorizontalAlignmentProperty = HORIZONTAL_ALIGNMENT;

  export type HorizontalAlignmentType = string;
}
