import { isColor } from '@compiler/ConversionMethods/reactThemeMethods/reactThemeUtils';
import { UNIT_PX } from '@globalTypes/constants';
import { type ReactInfo } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  ALL,
  BORDER,
  BORDER_COLOR,
  BORDER_COLOR_CSS,
  BORDER_STYLE_CSS,
  BORDER_WIDTH,
  BORDER_WIDTH_CSS,
  CLOSING_PARENTESIS,
  DEFAULT_COLOR,
  LINE_JUMP,
  NUMBER_SIGN,
  SEMICOLON,
  SEMICOLON_LINE_JUMP,
  TWO_POINTS_SPACE,
  VAR_OPENING
} from '../../../../../constants/constants';
import { type TypeDictionary } from '../../../../dictionary/dictionary';
import { referenceValue } from '../../reactItemUtils';

export function getSpecificBorderValues (item: TypeItemProperties,
  creatingComponent: boolean): Record<string, string> {
  if ('border' in item && 'topLeft' in item.border.data.radius.data) {
    let topLeft = item.border.data.radius.data.topLeft.value + item.border.data.radius.data.topLeft.unit;
    const topLeftVariable = referenceValue('border-topLeft', item, creatingComponent);
    if (topLeftVariable !== false) topLeft = VAR_OPENING + topLeftVariable + CLOSING_PARENTESIS;
    let topRight = item.border.data.radius.data.topRight.value + item.border.data.radius.data.topRight.unit;
    const topRightVariable = referenceValue('border-topRight', item, creatingComponent);
    if (topRightVariable !== false) topRight = VAR_OPENING + topRightVariable + CLOSING_PARENTESIS;
    let bottomLeft = item.border.data.radius.data.bottomLeft.value +
      item.border.data.radius.data.bottomLeft.unit;
    const bottomLeftVariable = referenceValue('border-bottomLeft', item, creatingComponent);
    if (bottomLeftVariable !== false) bottomLeft = VAR_OPENING + bottomLeftVariable + CLOSING_PARENTESIS;
    let bottomRight = item.border.data.radius.data.bottomRight.value +
      item.border.data.radius.data.bottomRight.unit;
    const bottomRightVariable = referenceValue('border-bottomRight', item, creatingComponent);
    if (bottomRightVariable !== false) bottomRight = VAR_OPENING + bottomRightVariable + CLOSING_PARENTESIS;
    return { topLeft, topRight, bottomLeft, bottomRight };
  } else return { topLeft: '0px', topRight: '0px', bottomLeft: '0px', bottomRight: '0px' };
}

export function specificBorderStyle (item: TypeItemProperties, dictionary: TypeDictionary,
  creatingComponent: boolean): string {
  const { topLeft, topRight, bottomLeft, bottomRight } = getSpecificBorderValues(item, creatingComponent);
  if (topLeft === topRight && bottomLeft === bottomRight && topLeft === bottomLeft) {
    return dictionary.radius + topLeft + dictionary.styleClosing;
  } else if (topLeft === topRight && bottomLeft === bottomRight) {
    const topInfo = dictionary.radius + topLeft;
    const bottomInfo = bottomLeft + dictionary.styleClosing;
    return topInfo + ' ' + bottomInfo;
  } else {
    const info = topLeft + ' ' + topRight + ' ' + bottomRight + ' ' + bottomLeft;
    return dictionary.radius + info + dictionary.styleClosing;
  }
}

export function borderRadiusStyle (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  if ('border' in item) {
    if (item.border.data.radius.kind !== ALL) {
      const bVal = specificBorderStyle(item, dictionary, neededInfo.creatingComponent);
      return bVal;
    } else if ('value' in item.border.data.radius.data && item.border.data.radius.data.value != null) {
      const value = item.border.data.radius.data.value;
      const unit = item.border.data.radius.data.unit;
      const borderClass = neededInfo.variableListInfo == null
        ? false
        : neededInfo.variableListInfo.normal.scrollable != null;
      if ((value !== 0 && unit !== UNIT_PX) || borderClass) {
        return dictionary.radius + value + dictionary[unit as keyof typeof dictionary] +
          dictionary.styleClosing;
      }
    }
  }
  return '';
}

export function partialBorderColorStyles(topColor: string, bottomColor: string, leftColor: string,
  rightColor: string): string {
  if (topColor === bottomColor && leftColor === rightColor && topColor === leftColor) {
    return BORDER_COLOR_CSS + TWO_POINTS_SPACE + (isColor(topColor) ? '#' : '') + topColor +
      SEMICOLON_LINE_JUMP;
  } else if (topColor === bottomColor && leftColor === rightColor) {
    return BORDER_COLOR_CSS + TWO_POINTS_SPACE + (isColor(topColor) ? '#' : '') + topColor + ' ' +
      (isColor(leftColor) ? '#' : '') + leftColor + SEMICOLON_LINE_JUMP;
  } else {
    return BORDER_COLOR_CSS + TWO_POINTS_SPACE + (isColor(topColor) ? '#' : '') + topColor + ' ' +
      (isColor(bottomColor) ? '#' : '') + bottomColor + ' ' + (isColor(leftColor) ? '#' : '') + leftColor +
      ' ' + (isColor(rightColor) ? '#' : '') + rightColor + SEMICOLON_LINE_JUMP;
  }
}

export function getPartialBorderInfo (item: TypeItemProperties, direction: string,
  creatingComponent: boolean): Record<string, string> {
  if (!('border' in item) || !('direction' in item.border.data)) return {};
  const place = item.border.data.direction;
  const pKey = direction as keyof typeof place;
  let color = place[pKey].color;
  const colorVariable = referenceValue('border-' + direction + 'color', item, creatingComponent);
  if (colorVariable !== false) color = VAR_OPENING + colorVariable + CLOSING_PARENTESIS;
  let width = place[pKey].width.value + place[pKey].width.unit;
  const widthVariable = referenceValue('border-' + direction + 'width', item, creatingComponent);
  if (widthVariable !== false) width = VAR_OPENING + widthVariable + CLOSING_PARENTESIS;
  return { [direction + 'Color']: color, [direction + 'Width']: width };
}

export function partialBorderStyles(item: TypeItemProperties, creatingComponent: boolean): string {
  let style = '';
  if ('border' in item && 'direction' in item.border.data) {
    const { topColor, topWidth } = getPartialBorderInfo(item, 'top', creatingComponent);
    const { bottomColor, bottomWidth } = getPartialBorderInfo(item, 'bottom', creatingComponent);
    const { leftColor, leftWidth } = getPartialBorderInfo(item, 'left', creatingComponent);
    const { rightColor, rightWidth } = getPartialBorderInfo(item, 'right', creatingComponent);
    style += partialBorderColorStyles(topColor, bottomColor, leftColor, rightColor);
    if (topWidth === bottomWidth && leftWidth === rightWidth && topWidth === leftWidth) {
      style += BORDER_WIDTH_CSS + TWO_POINTS_SPACE + topWidth + SEMICOLON_LINE_JUMP;
    } else if (topWidth === bottomWidth && leftWidth === rightWidth) {
      style += BORDER_WIDTH_CSS + TWO_POINTS_SPACE + topWidth + ' ' + leftWidth + SEMICOLON_LINE_JUMP;
    } else {
      style += BORDER_WIDTH_CSS + TWO_POINTS_SPACE + topWidth + ' ' + bottomWidth + ' ' + leftWidth + ' ' +
        rightWidth + SEMICOLON_LINE_JUMP;
    }
  }
  return style;
}

export function borderStyles (item: TypeItemProperties, creatingComponent: boolean,
  borderColor: string, borderWidth: string, borderDashed: string): string {
  const colorReference = referenceValue(BORDER_COLOR, item, false);
  const colorIsReference = colorReference !== false && !creatingComponent;
  const color = colorIsReference
    ? VAR_OPENING + colorReference + CLOSING_PARENTESIS
    : NUMBER_SIGN + borderColor;
  const widthReference = referenceValue(BORDER_WIDTH, item, false);
  const widthIsReference = widthReference !== false && !creatingComponent;
  const width = widthIsReference ? VAR_OPENING + widthReference + CLOSING_PARENTESIS : borderWidth;
  return LINE_JUMP + BORDER + TWO_POINTS_SPACE + width + ' ' + borderDashed + ' ' + color + SEMICOLON;
}

export function border (item: TypeItemProperties, creatingComponent: boolean, neededInfo: ReactInfo): string {
  if (!('border' in item)) return '';
  const borderDashed = item.border.data.style;
  if (item.border.kind !== ALL) {
    const style = BORDER_STYLE_CSS + TWO_POINTS_SPACE + borderDashed + SEMICOLON_LINE_JUMP;
    return style + partialBorderStyles(item, creatingComponent);
  }
  const borderClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.border != null;
  const borderColor = item.border.data.color;
  const notDefaultColor = borderColor !== DEFAULT_COLOR || borderClass;
  const borderWidth = item.border.data.width.value + item.border.data.width.unit;
  const notDefaultWidth = borderWidth !== '0px' || borderClass;
  if (notDefaultColor && notDefaultWidth) {
    const bVal = borderStyles(item, creatingComponent, borderColor, borderWidth, borderDashed);
    return bVal;
  } else {
    let styles = '';
    if (notDefaultColor) {
      styles += LINE_JUMP + BORDER_COLOR_CSS + TWO_POINTS_SPACE + NUMBER_SIGN + borderColor + SEMICOLON;
    }
    if (notDefaultWidth) {
      styles += LINE_JUMP + BORDER_WIDTH_CSS + TWO_POINTS_SPACE + borderWidth + SEMICOLON;
      styles += LINE_JUMP + BORDER_STYLE_CSS + TWO_POINTS_SPACE + borderDashed + SEMICOLON;
    }
    return styles;
  }
}
