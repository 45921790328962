export interface TypeOrientationAlignDictionary {
  verticalOrientation: string;
  horizontalOrientation: string;
  primaryAxisAlign: string;
  secondaryAxisAlign: string;
  topVerticalAlignment: string;
  centerVerticalAlignment: string;
  bottomVerticalAlignment: string;
  distributeVerticalAlignment: string;
  leftHorizontalAlignment: string;
  centerHorizontalAlignment: string;
  rightHorizontalAlignment: string;
  distributeHorizontalAlignment: string;
  scrollableVertical: string;
  scrollableHorizontal: string;
}

export const ReactOrientationAlignDictionary: TypeOrientationAlignDictionary = {
  verticalOrientation: 'flex-direction: column;',
  horizontalOrientation: 'flex-direction: row;',
  primaryAxisAlign: 'justify-content: ',
  secondaryAxisAlign: 'align-items: ',
  topVerticalAlignment: 'flex-start;',
  centerVerticalAlignment: 'center;',
  bottomVerticalAlignment: 'flex-end;',
  distributeVerticalAlignment: 'space-between;',
  leftHorizontalAlignment: 'flex-start;',
  centerHorizontalAlignment: 'center;',
  rightHorizontalAlignment: 'flex-end;',
  distributeHorizontalAlignment: 'space-between;',
  scrollableVertical: 'overflow-y: {scrollable};\nscrollbar-width: none;\n-ms-overflow-style: none;',
  scrollableHorizontal: 'overflow-x: {scrollable};\nscrollbar-width: none;\n-ms-overflow-style: none;',
};
