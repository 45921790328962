import { DimensionType, SizeValueType, type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  AGUA_ITEM,
  AGUA_ITEM_CLOSE,
  ANTD_FORM,
  CALC_VAR,
  CALC_VAR_MARGINS,
  CALC_VAR_MARGINS_VAR,
  CALC_VAR_MARGIN_VAR,
  CALC_VAR_MARGIN_VAR_BOTH,
  CALC_VAR_MARGIN_VAR_TWO,
  CLOSE_BRACKET,
  CLOSING_PARENTESIS,
  COMMA,
  DEFAULT_THEME,
  FILL_VALUE,
  FIT_CONTENT,
  HEIGHT,
  LINE_JUMP,
  MARGIN_BOTTOM,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MINUS,
  MIN_MINUS,
  PARENTESIS_MINUS,
  PARENTESIS_PLUS,
  POINT,
  RESPONSIVENESS_THEME,
  SEMICOLON_LINE_JUMP,
  SPACE_PLUS,
  THREE_CLOSING_PARENTESIS,
  TWO_CLOSING_PARENTESIS,
  TWO_POINTS_SPACE,
  UNIT_PX,
  VALUE,
  VAR_DEFINITION,
  VAR_OPENING,
  WRAP
} from '../../../../constants/constants';
import {
  type TypeRefItemInfo,
  type ReactInfo,
  type TypeReactAttribute,
  type TypeVerifications
} from '@globalTypes/conversor';
import { referenceValue } from '../../reactHTMLMethods/reactItemUtils';

export function dimensionReferenceTheme (neededInfo: ReactInfo, variableName: string): string {
  let nameTheme = DEFAULT_THEME;
  if (neededInfo.creatingComponent) {
    if (neededInfo.infoThemes[neededInfo.pageName] != null) nameTheme = neededInfo.pageName;
    else {
      nameTheme = neededInfo.pageName.substring(0, 1).toLowerCase() + neededInfo.pageName.substring(1);
    }
  }
  if (nameTheme === '') nameTheme = DEFAULT_THEME;
  if (neededInfo.infoThemes[nameTheme] == null ||
    (nameTheme === DEFAULT_THEME && neededInfo.infoThemes[DEFAULT_THEME][variableName] == null)) {
    nameTheme = RESPONSIVENESS_THEME;
  }
  return nameTheme;
}

export function heightReferenceTopMargin (margenes: string, item: TypeItemProperties, variableName: string,
  marginTop: string): string {
  if ('margins' in item) {
    const definedBottomValues = item.margins.bottom != null &&
        (item.margins.bottom?.value !== 0 || item.margins.bottom.unit !== UNIT_PX);
    const bottomValues = (item.margins.bottom?.value ?? 0) + (item.margins.bottom?.unit ?? UNIT_PX);
    if (!definedBottomValues) {
      margenes = CALC_VAR + variableName + CALC_VAR_MARGIN_VAR + marginTop + TWO_CLOSING_PARENTESIS +
      SEMICOLON_LINE_JUMP;
    } else {
      margenes =
      CALC_VAR + variableName + CALC_VAR_MARGINS_VAR + marginTop + PARENTESIS_PLUS + bottomValues +
        TWO_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return margenes;
}

export function heightReferenceBottomMargin (margenes: string, item: TypeItemProperties, variableName: string,
  marginBottom: string): string {
  if ('margins' in item) {
    const definedTopValues = item.margins.top != null &&
          (item.margins.top.value !== 0 || item.margins.top.unit !== UNIT_PX);
    const topValues = (item.margins.top?.value ?? 0) + (item.margins.top?.unit ?? UNIT_PX);
    if (!definedTopValues) {
      margenes = CALC_VAR + variableName + CALC_VAR_MARGIN_VAR + marginBottom + TWO_CLOSING_PARENTESIS +
      SEMICOLON_LINE_JUMP;
    } else {
      margenes = CALC_VAR + variableName + CALC_VAR_MARGINS + topValues + CALC_VAR_MARGIN_VAR_TWO +
      marginBottom + THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return margenes;
}

export function handleHeightReferenceNoVariableMargin (definedTopValues: boolean,
  definedBottomValues: boolean, margenes: string, variableName: string, topValues: string,
  bottomValues: string): string {
  if (!definedTopValues && !definedBottomValues) {
    margenes = VAR_OPENING + variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (definedTopValues && definedBottomValues) {
    margenes = CALC_VAR + variableName + CALC_VAR_MARGINS + topValues + SPACE_PLUS + bottomValues +
    TWO_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (definedTopValues) {
    margenes = CALC_VAR + variableName + PARENTESIS_MINUS + topValues + CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP;
  } else if (definedBottomValues) {
    margenes = CALC_VAR + variableName + PARENTESIS_MINUS + bottomValues + CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP;
  }
  return margenes;
}

export function heightReferenceNoVariableMargin (margenes: string, item: TypeItemProperties,
  variableName: string): string {
  if ('margins' in item) {
    const definedTopValues = item.margins.top != null &&
        (item.margins.top.value !== 0 || item.margins.top.unit !== UNIT_PX);
    const topValues = (item.margins.top?.value ?? 0) + (item.margins.top?.unit ?? UNIT_PX);
    const definedBottomValues = item.margins.bottom != null &&
        (item.margins.bottom?.value !== 0 || item.margins.bottom.unit !== UNIT_PX);
    const bottomValues = (item.margins.bottom?.value ?? 0) + (item.margins.bottom?.unit ?? UNIT_PX);
    margenes = handleHeightReferenceNoVariableMargin(definedTopValues, definedBottomValues,
      margenes, variableName, topValues, bottomValues);
  }
  return margenes;
}

export function heightReferenceMargins (margenes: string, item: TypeItemProperties, variableName: string,
  creatingComponent: boolean): string {
  const marginTop = referenceValue(MARGIN_TOP, item, creatingComponent);
  const marginBottom = referenceValue(MARGIN_BOTTOM, item, creatingComponent);
  if (marginTop !== false && marginBottom !== false) {
    margenes = CALC_VAR + variableName + CALC_VAR_MARGINS_VAR + marginTop + CALC_VAR_MARGIN_VAR_BOTH +
      marginBottom + THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (typeof (marginTop) !== 'boolean') {
    margenes = heightReferenceTopMargin(margenes, item, variableName, marginTop);
  } else if (typeof (marginBottom) !== 'boolean') {
    margenes = heightReferenceBottomMargin(margenes, item, variableName, marginBottom);
  } else {
    margenes = heightReferenceNoVariableMargin(margenes, item, variableName);
  }
  return margenes;
}

export function widthReferenceLeftMargin (margenes: string, item: TypeItemProperties, variableName: string,
  marginLeft: string): string {
  if ('margins' in item) {
    const definedRightValues = item.margins.right != null &&
      (item.margins.right?.value !== 0 || item.margins.right.unit !== UNIT_PX);
    const rightValues = (item.margins.right?.value ?? 0) + (item.margins.right?.unit ?? UNIT_PX);
    if (!definedRightValues) {
      margenes = CALC_VAR + variableName + CALC_VAR_MARGIN_VAR + marginLeft + TWO_CLOSING_PARENTESIS +
      SEMICOLON_LINE_JUMP;
    } else {
      margenes =
      CALC_VAR + variableName + CALC_VAR_MARGINS_VAR + marginLeft + PARENTESIS_PLUS + rightValues +
        TWO_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return margenes;
}

export function widthReferenceRightMargin (margenes: string, item: TypeItemProperties, variableName: string,
  marginRight: string): string {
  if ('margins' in item) {
    const definedLeftValues = item.margins.left != null &&
        (item.margins.left.value !== 0 || item.margins.left.unit !== UNIT_PX);
    const leftValues = (item.margins.left?.value ?? 0) + (item.margins.left?.unit ?? UNIT_PX);
    if (!definedLeftValues) {
      margenes = CALC_VAR + variableName + CALC_VAR_MARGIN_VAR + marginRight + TWO_CLOSING_PARENTESIS +
      SEMICOLON_LINE_JUMP;
    } else {
      margenes = CALC_VAR + variableName + CALC_VAR_MARGINS + leftValues + CALC_VAR_MARGIN_VAR_TWO +
      marginRight + THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return margenes;
}

export function handleWidthReferenceNoVariableMargin (definedLeftValues: boolean,
  definedRightValues: boolean, margenes: string, variableName: string, leftValues: string,
  rightValues: string): string {
  if (!definedLeftValues && !definedRightValues) {
    margenes = VAR_OPENING + variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (definedLeftValues && definedRightValues) {
    margenes = CALC_VAR + variableName + CALC_VAR_MARGINS + leftValues + SPACE_PLUS + rightValues +
    TWO_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (definedLeftValues) {
    margenes = CALC_VAR + variableName + PARENTESIS_MINUS + leftValues + CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP;
  } else if (definedRightValues) {
    margenes = CALC_VAR + variableName + PARENTESIS_MINUS + rightValues + CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP;
  }
  return margenes;
}

export function widthReferenceNoVariableMargin (margenes: string, item: TypeItemProperties,
  variableName: string): string {
  if ('margins' in item) {
    const definedLeftValues = item.margins.left != null &&
      (item.margins.left.value !== 0 || item.margins.left.unit !== UNIT_PX);
    const leftValues = (item.margins.left?.value ?? 0) + (item.margins.left?.unit ?? UNIT_PX);
    const definedRightValues = item.margins.right != null &&
      (item.margins.right?.value !== 0 || item.margins.right.unit !== UNIT_PX);
    const rightValues = (item.margins.right?.value ?? 0) + (item.margins.right?.unit ?? UNIT_PX);
    margenes = handleWidthReferenceNoVariableMargin(definedLeftValues, definedRightValues, margenes,
      variableName, leftValues, rightValues);
  }
  return margenes;
}

export function widthReferenceMargins (margenes: string, item: TypeItemProperties, variableName: string,
  creatingComponent: boolean): string {
  const marginLeft = referenceValue(MARGIN_LEFT, item, creatingComponent);
  const marginRight = referenceValue(MARGIN_RIGHT, item, creatingComponent);
  if (marginLeft !== false && marginRight !== false) {
    margenes = CALC_VAR + variableName + CALC_VAR_MARGINS_VAR + marginLeft + CALC_VAR_MARGIN_VAR_BOTH +
      marginRight + THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (typeof (marginLeft) !== 'boolean') {
    margenes = widthReferenceLeftMargin(margenes, item, variableName, marginLeft);
  } else if (typeof (marginRight) !== 'boolean') {
    margenes = widthReferenceRightMargin(margenes, item, variableName, marginRight);
  } else {
    margenes = widthReferenceNoVariableMargin(margenes, item, variableName);
  }
  return margenes;
}

export function dimensionReferenceMargins (item: TypeItemProperties, variableName: string,
  creatingComponent: boolean, location: string): string {
  let margenes = '100px';
  if (location === HEIGHT) {
    margenes = heightReferenceMargins(margenes, item, variableName, creatingComponent);
  } else {
    margenes = widthReferenceMargins(margenes, item, variableName, creatingComponent);
  }
  return margenes;
}

export function handleFillVariables (neededInfo: ReactInfo, theme: string, variableName: string,
  location: string, margenes: string): void {
  if (neededInfo.item == null) return;
  if (neededInfo.fillVariables[theme] == null) neededInfo.fillVariables[theme] = {};
  if (neededInfo.fillVariables[theme][variableName] == null) {
    neededInfo.fillVariables[theme][variableName] = {};
  }
  if (neededInfo.fillVariables[theme][variableName][neededInfo.pageName] == null) {
    neededInfo.fillVariables[theme][variableName][neededInfo.pageName] = '';
  }
  const tagVal = 'tag' in neededInfo.item && neededInfo.item.tag !== ANTD_FORM ? neededInfo.item.tag : '';
  const nameVal = neededInfo.name + (neededInfo.item.nameChanged != null ? '' : MINUS + neededInfo.item.id);
  const customAgua = AGUA_ITEM + neededInfo.item.commonKind + AGUA_ITEM_CLOSE;
  neededInfo.fillVariables[theme][variableName][neededInfo.pageName] += LINE_JUMP + tagVal + POINT +
    nameVal + customAgua + LINE_JUMP + location + TWO_POINTS_SPACE + margenes + LINE_JUMP +
    CLOSE_BRACKET;
}

export function addDimensionReference (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  variableName: string, referenceItem: TypeReactAttribute, ver: TypeVerifications, value: string): void {
  if (neededInfo.item == null) return;
  const margenes = dimensionReferenceMargins(neededInfo.item, variableName, neededInfo.creatingComponent,
    refItemInfo.location ?? '');
  if (value === FILL_VALUE && neededInfo.responsiveValues.desktop[referenceItem[2]][variableName] == null) {
    if (ver.groupVerification) {
      refItemInfo.referenceString += refItemInfo.location + TWO_POINTS_SPACE + margenes + LINE_JUMP;
    }
  } else if (ver.groupVerification) {
    refItemInfo.referenceString += refItemInfo.location + VAR_DEFINITION + variableName +
      CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  if (!ver.responsiveVariable) {
    refItemInfo.referenceAsProps += COMMA + ' ' + refItemInfo.location + TWO_POINTS_SPACE + variableName;
  }
  if (ver.creatingCompRes) {
    handleFillVariables(neededInfo, referenceItem[2], variableName, refItemInfo.location ?? '', margenes);
  }
}

export function handleDimensionReference (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  referenceItem: TypeReactAttribute, ver: TypeVerifications, variableName: string): void {
  if (neededInfo.item == null) return;
  refItemInfo.location = referenceItem[0].replace(VALUE, '');
  const theme = dimensionReferenceTheme(neededInfo, variableName);
  const variable = neededInfo.infoThemes[theme][variableName];
  let valueString = '';
  if (!('dimension' in variable)) return;
  const value = variable.value;
  if (value.data != null) {
    valueString = value.data[SizeValueType.SizeValueProperty] + value.data.unit;
  } else if (value[DimensionType.DimensionKindProperty] === WRAP) valueString = FIT_CONTENT;
  else valueString = FILL_VALUE;
  if (ver.noAddVerification) refItemInfo.referenceList.push(variableName);
  if (ver.groupVerification) {
    refItemInfo.referenceString += MIN_MINUS + refItemInfo.location + VAR_DEFINITION +
      MIN_MINUS + variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  addDimensionReference(neededInfo, refItemInfo, variableName, referenceItem, ver, valueString);
}
