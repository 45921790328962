import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { APIS_DATA } from '@data/apis';

import { type PostmanItem, type PostmanItems } from '@globalTypes/apis';

import { type StateType } from './store';

export interface APIReducerState {
  items: PostmanItems;
  selectedItemID: string;
}

const initialData: APIReducerState = {
  items: APIS_DATA,
  selectedItemID: 'af5e2f28-5a17-4809-8fa9-81228349a4b9',
};

export const APISlice = createSlice({
  name: 'apis',
  initialState: initialData,
  reducers: {
    setSelectedItemID: (state, action: PayloadAction<string>) => {
      state.selectedItemID = action.payload;
    },
    setAPIItems: (state, action: PayloadAction<PostmanItems>) => {
      const items = action.payload;
      state.items = items;
    },
  },
});

export const { setSelectedItemID, setAPIItems } = APISlice.actions;

// Getters
export const getAPIItems = (state: StateType): PostmanItems => state.apis.items;

export const getSelectedAPIItem = (state: StateType): PostmanItem | undefined => {
  const items = state.apis.items;
  const selectedItemID = state.apis.selectedItemID;
  let searchedItem;
  for (const itemKey in items) {
    const item = items[itemKey];
    if (item.id === selectedItemID) {
      searchedItem = item;
      break;
    }
  }
  return searchedItem;
};

export const APISReducer = APISlice.reducer;
