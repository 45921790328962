import { type TypeComponentData } from '@globalTypes/conversor';
import { GRADIENT, MINUS, PNG_FORMAT, RESPONSIVE_VARIABLE } from '../../../constants/constants';

export function setUpDefaultValuesNested (defaultValuesNested: Record<string, Record<string, boolean>>,
  component: TypeComponentData): void {
  if (!('references' in component) || component.references == null) return;
  const childrenComponent = component.references.componentChildren;
  for (const childComponent in childrenComponent) {
    defaultValuesNested[childComponent] = {};
    defaultValuesNested[childComponent][childrenComponent[childComponent].component] = true;
  }
}

export function adaptPartsProps (cRef: any, mref: string): string[] {
  let parts = [cRef[mref].value, cRef[mref].type];
  if (cRef[mref].tag === RESPONSIVE_VARIABLE) {
    parts = [cRef[mref].valueDesktop, cRef[mref].type];
  }
  if (parts[1].indexOf(GRADIENT) === 0 ||
      parts[1].endsWith(PNG_FORMAT) === true) {
    parts = [parts[0] + MINUS + parts[1], parts[2]];
  }
  return parts;
}
