/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbBrandVisualStudio } from 'react-icons/tb';
import { useSelector } from 'react-redux';

import { Divider } from '@components/divider';

import { getPath, getSetup } from '@reducers/graphs';

import { trackEvent } from '@globalUtils/metrics';

import styles from './styles.module.css';
import {
  getFirstLevelDecendentNodes,
  getFirstLevelParentNodes,
  getNameFromLabel,
  getNodeById,
} from './utils';

const NodeComponent = ({
  label,
  path,
  graphType,
  kind,
}: {
  label: string;
  path: string;
  graphType: string;
  kind: string;
}): React.JSX.Element => {
  // const dispatch = useDispatch();

  /*
  const notifyOpenLocal = (fn: string): void => {
    trackEvent('Went to Local VS on ' + graphType + ' Graph for ' + kind);
    void fetch('http://localhost:4035/fs/openFileServer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename: fn }),
    });
    dispatch(setMainMenuTab('code'));
  };
  */

  const notifyOpenVisual = (fn: string): void => {
    trackEvent('Went to VS on ' + graphType + ' Graph for ' + kind);
    void fetch('http://localhost:4035/fs/openFile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename: fn }),
    });
  };

  return (
    <div className={styles.hovered} style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <div style={{ flex: '1 1 50%', minWidth: 0, overflowWrap: 'break-word' }}>
        {getNameFromLabel(label)}
      </div>
      <div className={styles.options} style={{ position: 'absolute', right: 'var(--padding-m)' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'var(--padding-xl)' }}>
          <div
            onClick={() => {
              notifyOpenVisual(path);
            }}
            className={styles.hovered}
            style={{
              padding: 'var(--padding-xs)',
              borderRadius: 'var(--border-radius-m)',
              cursor: 'pointer',
              fontSize: 'var(--font-size-icon-large)',
              marginLeft: 'var(--padding-s)',
            }}
          >
            <TbBrandVisualStudio />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SelectedNode = ({
  data,
  node,
  network,
  graphType,
}: {
  node: any;
  data: any;
  network: any;
  graphType: string;
}): React.JSX.Element => {
  const { t } = useTranslation();

  const [currentNode, setCurrentNode] = useState<any>(null);
  const [currentDependencies, setCurrentDependencies] = useState<any>([]);
  const [currentReferences, setCurrentReferences] = useState<any>([]);

  const mPath = useSelector(getPath);
  const mDir = useSelector(getSetup).setup.directory;
  let tDir = mDir.startsWith('./') ? mDir.substring(2) : mDir;
  tDir = tDir.endsWith('/') ? tDir : tDir + '/';
  const tPath = graphType === 'dependencies' ? mPath + '/' + tDir : '';

  useEffect(() => {
    if (node == null) return;
    const dependencies = getFirstLevelDecendentNodes(network, data, node.id);
    const references = getFirstLevelParentNodes(network, data, node.id);
    const referencesData: any = [];
    const dependenciesData: any = [];
    references.forEach((ref) => {
      const mNode = getNodeById(data, ref);
      referencesData.push({
        label: mNode.label,
        path: graphType === 'tree' ? data.paths[ref] : data.idsMap[ref],
      });
    });
    dependencies.forEach((dep) => {
      const mNode = getNodeById(data, dep);
      dependenciesData.push({
        label: mNode.label,
        path: graphType === 'tree' ? data.paths[dep] : data.idsMap[dep],
      });
    });
    const mCurrentNode = {
      label: node.label,
      path: graphType === 'tree' ? data.paths[node.id] : data.idsMap[node.id],
    };
    setCurrentNode(mCurrentNode);
    setCurrentDependencies(dependenciesData);
    setCurrentReferences(referencesData);
  }, [node, data, network]);

  if (currentNode == null) return <></>;

  return (
    <div
      style={{
        padding: 'var(--padding-m)',
        width: '100%',
        height: 'fit-content',
        overflow: 'hidden',
      }}
    >
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <div
          style={{
            flex: '1 1 50%',
            fontSize: 'var(--font-size-body-info)',
            color: 'var(--primary-icon-color-contrast)',
            minWidth: 0,
            overflowWrap: 'break-word',
          }}
        >
          {currentNode.path !== '' ? currentNode.path : t('graphs-node-no-path')}
        </div>
      </div>
      <NodeComponent
        label={currentNode.label}
        path={tPath + currentNode.path}
        graphType={graphType}
        kind="self"
      />
      <div style={{ marginTop: 'var(--padding-m)' }}>
        <Divider orientation="horizontal" />
      </div>
      <div style={{ marginTop: 'var(--padding-m)' }}>
        <div>{`${currentReferences.length} ${
          currentReferences.length === 1
            ? t('graphs-node-references-singular-label')
            : t('graphs-node-references-label')
        }`}</div>
        <div style={{ marginLeft: 'var(--padding-m)' }}>
          {currentReferences.map((ref: any, i: number) => (
            <div key={i} style={{ marginTop: 'var(--padding-s)' }}>
              {i === 1 ? (
                <div style={{ marginTop: 'var(--padding-s)' }}>
                  <Divider orientation="horizontal" />
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  fontSize: 'var(--font-size-body-info)',
                  color: 'var(--primary-icon-color-contrast)',
                  overflowWrap: 'break-word',
                }}
              >
                {ref.path !== '' ? ref.path : t('graphs-node-no-path')}
              </div>
              <NodeComponent
                label={ref.label}
                path={tPath + ref.path}
                graphType={graphType}
                kind="reference"
              />
              {i > 0 ? (
                <div style={{ marginTop: 'var(--padding-s)' }}>
                  <Divider orientation="horizontal" />
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: 'var(--padding-m)' }}>
        <Divider orientation="horizontal" />
      </div>
      <div style={{ marginTop: 'var(--padding-m)' }}>
        <div>{`${currentDependencies.length} ${
          currentDependencies.length === 1
            ? t('graphs-node-dependencies-singular-label')
            : t('graphs-node-dependencies-label')
        }`}</div>
        <div style={{ marginLeft: 'var(--padding-m)' }}>
          {currentDependencies.map((ref: any, i: number) => (
            <div key={i} style={{ marginTop: 'var(--padding-s)' }}>
              {i === 1 ? (
                <div style={{ marginTop: 'var(--padding-s)' }}>
                  <Divider orientation="horizontal" />
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  fontSize: 'var(--font-size-body-info)',
                  color: 'var(--primary-icon-color-contrast)',
                  overflowWrap: 'break-word',
                }}
              >
                {ref.path !== '' ? ref.path : t('graphs-node-no-path')}
              </div>
              <NodeComponent
                label={ref.label}
                path={tPath + ref.path}
                graphType={graphType}
                kind="dependency"
              />
              {i > 0 ? (
                <div style={{ marginTop: 'var(--padding-s)' }}>
                  <Divider orientation="horizontal" />
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
