import type React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setComponentTreeData } from '@reducers/graphs';

import { trackEvent } from '@globalUtils/metrics';

export const ComponentTreeDataListener = ({
  children,
}: {
  children: React.JSX.Element;
}): React.JSX.Element => {
  const dispatch = useDispatch();

  const firstTime = useRef<boolean>(true);

  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to CTDL Page');
  }, []);

  const receiveEvents = (event: any): void => {
    if (event?.data?.sender === 'sendComponentsInfo') {
      const info = event.data.content;
      dispatch(setComponentTreeData(JSON.parse(info)));
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveEvents);
    return () => {
      window.removeEventListener('message', receiveEvents);
    };
  }, []);

  return children;
};
