/* eslint-disable max-lines-per-function */
import {
  AGUA_ITEM,
  AGUA_ITEM_CLOSE,
  ANTD_FORM,
  BRACKET,
  BRACKET_CLOSE,
  BRACKET_NAME,
  CHILDREN,
  CHILDREN_VARIABLES,
  COMPONENTS,
  DEFAULT_THEME,
  FILL,
  FROM_TWO_FOLDERS,
  HAS_ON_CLICK,
  IMAGES_VARIABLES,
  JSX_FORMAT,
  LAYER,
  LAYOUTS,
  LESS_THAN,
  LINE_JUMP,
  MAX_WIDTH,
  MEDIA_DEF,
  MEDIA_DEF_CLOSE,
  MEDIA_DESKTOP,
  MEDIA_PHONE,
  MEDIA_TABLET,
  MIN_WIDTH,
  MODULE_CSS_FORMAT,
  MORE_THAN,
  NAME_STYLE,
  POINT,
  PROPS,
  PROPSDOC,
  PROPSTYPE,
  PROPSTYPEIMPORT,
  PROPSTYPE_IMPORT,
  RESPONSIVENESS_THEME,
  SLASH,
  UNDEFINED,
  UNDERSCORE,
} from '../../constants/constants';
import { type TypeDictionary } from '../dictionary/dictionary';
import { createTreeParent } from '../reactFileMethods/reactCreateTreeParentMethods';
import { handleImports } from '../reactFileMethods/reactFileUtils';
import { getConvertNametoReact } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';

import { type ITreeNode } from '@globalUtils/tree';

import {
  type DimensionVariable,
  type IComponentinfo,
  type OrientationVariable,
  type ReactInfo,
  type TypeComponentData,
  type TypeItems,
  type TypeVarInfo,
} from '@globalTypes/conversor';
import { DimensionType } from '@globalTypes/itemProperties';

import { getComponentPropTypes } from './reactComponentProps/reactComponentPropTypesMethods';
import { getComponentPropsDoc } from './reactComponentProps/reactComponentPropsDoc';
import { getComponentProps } from './reactComponentProps/reactComponentPropsMethods';
import { isComponentALayout } from './reactComponentUtils';

export function addComponentCode(
  isLayout: boolean,
  neededInfo: ReactInfo,
  archiveName: string,
  component: string,
  componentTheme: string,
  name: string
): void {
  if (isLayout) {
    neededInfo.layoutCode[archiveName] = {};
    neededInfo.layoutCode[archiveName][archiveName + JSX_FORMAT] = component;
    neededInfo.layoutCode[archiveName][archiveName + MODULE_CSS_FORMAT] = componentTheme.replaceAll(
      UNDEFINED,
      ''
    );
  } else {
    neededInfo.componentCode[archiveName] = {};
    neededInfo.componentCode[archiveName][archiveName + JSX_FORMAT] = component;
    neededInfo.componentCode[archiveName][archiveName + MODULE_CSS_FORMAT] = componentTheme.replaceAll(
      UNDEFINED,
      ''
    );
  }
  const route = isLayout ? LAYOUTS : COMPONENTS;
  neededInfo.componentsImport[name] = {
    path: FROM_TWO_FOLDERS + route + SLASH + archiveName + SLASH + archiveName + JSX_FORMAT,
    name,
  };
  neededInfo.aditionalTextVariable[name] = '';
}

export function handleOrientationComponentVariables(
  componentName: string,
  variable: string,
  neededInfo: ReactInfo,
  name: string,
  varInfo: TypeVarInfo,
  defaultOrientationVariables: any
): void {
  let theme = variable.includes('_') ? RESPONSIVENESS_THEME : DEFAULT_THEME;
  if (neededInfo.infoThemes[componentName][variable] != null) theme = componentName;
  const defaultValue = neededInfo.infoThemes[theme][variable] as OrientationVariable;
  if (defaultValue != null) {
    varInfo.defaultVariables += defaultOrientationVariables[variable][defaultValue.value.toLowerCase()][name];
  }
  if (neededInfo.responsiveValues.desktop[componentName] == null) {
    neededInfo.responsiveValues.desktop[componentName] = {};
  }
  const defaultWeb = neededInfo.responsiveValues.desktop[theme][variable] as OrientationVariable;
  if (defaultWeb != null) {
    varInfo.webVariables += defaultOrientationVariables[variable][defaultWeb.value.toLowerCase()][name];
  }
  if (neededInfo.responsiveValues.phone[componentName] == null) {
    neededInfo.responsiveValues.phone[componentName] = {};
  }
  const defaultPhone = neededInfo.responsiveValues.phone[theme][variable] as OrientationVariable;
  if (defaultPhone != null) {
    varInfo.phoneVariables += defaultOrientationVariables[variable][defaultPhone.value.toLowerCase()][name];
  }
  if (neededInfo.responsiveValues.tablet[componentName] == null) {
    neededInfo.responsiveValues.tablet[componentName] = {};
  }
  const defaultTablet = neededInfo.responsiveValues.tablet[theme][variable] as OrientationVariable;
  if (defaultTablet != null) {
    varInfo.tabletVariables += defaultOrientationVariables[variable][defaultTablet.value.toLowerCase()][name];
  }
}

export function initFillComponentVariables(neededInfo: ReactInfo, componentName: string): void {
  if (neededInfo.responsiveValues.desktop[componentName] == null) {
    neededInfo.responsiveValues.desktop[componentName] = {};
  }
  if (neededInfo.responsiveValues.phone[componentName] == null) {
    neededInfo.responsiveValues.phone[componentName] = {};
  }
  if (neededInfo.responsiveValues.tablet[componentName] == null) {
    neededInfo.responsiveValues.tablet[componentName] = {};
  }
}

export function handleFillComponentVariables(
  componentName: string,
  variable: string,
  neededInfo: ReactInfo,
  name: string,
  varInfo: TypeVarInfo,
  defaultFillVariables: any
): void {
  let theme = variable.includes(UNDERSCORE) ? RESPONSIVENESS_THEME : DEFAULT_THEME;
  if (neededInfo.infoThemes[componentName][variable] != null) theme = componentName;
  const defaultWeb = neededInfo.responsiveValues.desktop[theme][variable] as DimensionVariable;
  if (defaultWeb != null && defaultWeb.value[DimensionType.DimensionKindProperty] === FILL) {
    varInfo.webVariables += defaultFillVariables[variable][name];
  }
  const defaultPhone = neededInfo.responsiveValues.phone[theme][variable] as DimensionVariable;
  if (defaultPhone != null && defaultPhone.value[DimensionType.DimensionKindProperty] === FILL) {
    varInfo.phoneVariables += defaultFillVariables[variable][name];
  } else if (
    defaultPhone == null &&
    defaultWeb != null &&
    defaultWeb.value[DimensionType.DimensionKindProperty] !== FILL
  ) {
    varInfo.phoneVariables += defaultFillVariables[variable][neededInfo.pageName];
  }
  const defaultTablet = neededInfo.responsiveValues.tablet[theme][variable] as DimensionVariable;
  if (defaultTablet != null && defaultTablet.value[DimensionType.DimensionKindProperty] === FILL) {
    varInfo.tabletVariables += defaultFillVariables[variable][name];
  } else if (
    defaultTablet == null &&
    defaultWeb != null &&
    defaultWeb.value[DimensionType.DimensionKindProperty] !== FILL
  ) {
    varInfo.phoneVariables += defaultFillVariables[variable][neededInfo.pageName];
  }
}

export function handleOrientationFillVariables(
  componentTheme: string,
  varInfo: TypeVarInfo,
  neededInfo: ReactInfo
): string {
  varInfo.defaultVariables = varInfo.defaultVariables.replaceAll(UNDEFINED, '');
  varInfo.phoneVariables = varInfo.phoneVariables.replaceAll(UNDEFINED, '');
  varInfo.webVariables = varInfo.webVariables.replaceAll(UNDEFINED, '');
  varInfo.tabletVariables = varInfo.tabletVariables.replaceAll(UNDEFINED, '');
  if (varInfo.defaultVariables !== '') componentTheme += LINE_JUMP + varInfo.defaultVariables;
  let mediaP = MEDIA_PHONE;
  if (neededInfo.mediaValues != null) {
    mediaP = MIN_WIDTH + neededInfo.mediaValues.phone.value + neededInfo.mediaValues.phone.unit;
  }
  let mediaT = MEDIA_TABLET;
  if (neededInfo.mediaValues != null) {
    mediaT =
      MAX_WIDTH +
      (neededInfo.mediaValues.desktop.value - 1) +
      neededInfo.mediaValues.desktop.unit +
      ') and (' +
      MIN_WIDTH +
      (neededInfo.mediaValues.phone.value + neededInfo.mediaValues.phone.unit + 1);
  }
  let mediaD = MEDIA_DESKTOP;
  if (neededInfo.mediaValues != null) {
    mediaD = MIN_WIDTH + neededInfo.mediaValues.desktop.value + neededInfo.mediaValues.desktop.unit;
  }
  if (varInfo.phoneVariables !== '') {
    componentTheme += MEDIA_DEF + mediaP + MEDIA_DEF_CLOSE + varInfo.phoneVariables + BRACKET_CLOSE;
  }
  if (varInfo.webVariables !== '') {
    componentTheme += MEDIA_DEF + mediaD + MEDIA_DEF_CLOSE + varInfo.webVariables + BRACKET_CLOSE;
  }
  if (varInfo.tabletVariables !== '') {
    componentTheme += MEDIA_DEF + mediaT + MEDIA_DEF_CLOSE + varInfo.tabletVariables + BRACKET_CLOSE;
  }
  return componentTheme;
}

export function addOrientationFillVariables(
  componentTheme: string,
  name: string,
  neededInfo: ReactInfo,
  componentName: string
): string {
  const defaultOrientationVariables = neededInfo.orientationVariables[componentName];
  const defaultFillVariables = neededInfo.fillVariables[componentName];
  if (defaultOrientationVariables != null || defaultFillVariables != null) {
    const varInfo = {
      defaultVariables: '',
      phoneVariables: '',
      webVariables: '',
      tabletVariables: '',
    };
    for (const variable in defaultOrientationVariables) {
      handleOrientationComponentVariables(
        componentName,
        variable,
        neededInfo,
        name,
        varInfo,
        defaultOrientationVariables
      );
    }
    for (const variable in defaultFillVariables) {
      handleFillComponentVariables(componentName, variable, neededInfo, name, varInfo, defaultFillVariables);
    }
    componentTheme = handleOrientationFillVariables(componentTheme, varInfo, neededInfo);
  }
  return componentTheme;
}

export function initComponentVariables(neededInfo: ReactInfo, componentData: TypeComponentData): string {
  const name = getConvertNametoReact(componentData.name);
  neededInfo.pagesExtraStyles[name] = '';
  neededInfo.pagesResponsiveVariables[name] = ['', '', ''];
  neededInfo.orientationVariables[name] = {};
  neededInfo.fillVariables[name] = {};
  neededInfo.pageImage[name] = '';
  neededInfo.creatingComponent = true;
  return name;
}

export function getComponentPropsInfo(
  component: string,
  items: TypeItems,
  neededInfo: ReactInfo,
  viewDOM: IComponentinfo,
  treenode: ITreeNode,
  children: string
): string {
  neededInfo.items = items;
  const mCompProps = getComponentProps(items[treenode.id], viewDOM, children, neededInfo);
  component = component.replace(PROPS, mCompProps);
  if (mCompProps.length > 0) {
    component = component.replace(PROPSTYPEIMPORT, PROPSTYPE_IMPORT);
    const typeVal = getComponentPropTypes(items[treenode.id], viewDOM, children, neededInfo);
    component = component.replace(PROPSTYPE, typeVal);
  } else {
    component = component.replace(PROPSTYPEIMPORT, '');
    component = component.replace(PROPSTYPE, '');
  }
  const docVal = getComponentPropsDoc(items[treenode.id], viewDOM, children, neededInfo);
  component = component.replace(PROPSDOC, docVal);
  return component;
}

export function createGeneratedComponent(
  neededInfo: ReactInfo,
  componentData: TypeComponentData,
  dictionary: TypeDictionary,
  viewDOM: IComponentinfo,
  items: TypeItems,
  treenode: ITreeNode
): void {
  const name = initComponentVariables(neededInfo, componentData);
  let component = dictionary.baseComponent.replaceAll(BRACKET_NAME, name);
  component = component.replaceAll(NAME_STYLE, name.substring(0, 1).toLowerCase() + name.substring(1));
  const initialItem = neededInfo.items[treenode.id];
  initialItem.name = name;
  const children = createTreeParent([treenode], items, name, neededInfo, dictionary, viewDOM);
  neededInfo.componentChilrenForProps[name] = children;
  component = component.replace(CHILDREN, children);
  component = getComponentPropsInfo(component, items, neededInfo, viewDOM, treenode, children);
  neededInfo.creatingComponent = false;
  component = handleImports(neededInfo.componentsUsed[name], component, neededInfo);
  neededInfo.componentCreated[name] = [
    LESS_THAN + name + ' ' + CHILDREN_VARIABLES + SLASH + MORE_THAN,
    neededInfo.pagesReferences[name],
  ];
  const isLayout = isComponentALayout(name, neededInfo.componentsUsed);
  const archiveName = name.substring(0, 1).toLowerCase() + name.substring(1);
  let componentTheme = neededInfo.styles[name] + (neededInfo.stylesComponent[name]?.[1] ?? '');
  const vTag =
    ('tag' in initialItem && initialItem.tag === ANTD_FORM ? initialItem.tag : '') +
    POINT +
    name.toLowerCase();
  componentTheme = componentTheme.replaceAll(
    POINT + name.toLowerCase() + BRACKET,
    vTag + AGUA_ITEM + LAYER + AGUA_ITEM_CLOSE
  );
  if (neededInfo.aditionalTextVariable[name] !== '') componentTheme += neededInfo.aditionalTextVariable[name];
  const componentName = componentData.name.replaceAll(' ', '');
  componentTheme = addOrientationFillVariables(componentTheme, name, neededInfo, componentName);
  const onclickVal = neededInfo.pageHasChildrenHrefExternal[name] != null ? dictionary.openInNewTab : '';
  component = component.replace(HAS_ON_CLICK, onclickVal);
  component = component.replace(IMAGES_VARIABLES, neededInfo.pageImage[name] ?? '');
  addComponentCode(isLayout, neededInfo, archiveName, component, componentTheme, name);
}
