import React from 'react';

import { type ItemsStructure } from '@fragments/screenItemsTree/utils';

import { type ItemOptionsBar, type ItemSummary } from './item';
import { ItemList } from './itemList';
import styles from './styles.module.css';

export interface ItemsTreeProps<T> {
  items: ItemsStructure;
  disableSorting?: boolean;
  currentlySelectedItem: string;
  currentlyCollapsedItems: Record<string, boolean>;
  itemOptionsBar?: ItemOptionsBar<T>;
  contextMenuView?: React.JSX.Element;
  contextMenuOpen?: boolean;
  addMenuView?: React.JSX.Element;
  addMenuOpen?: boolean;
  itemIconPreviewGetter: (item: ItemSummary) => React.JSX.Element;
  notifyChangeHierarchy: (from: string, to: string, id: string, position: number) => any;
  notifyItemClick: (item: string) => any;
  notifyItemFolding: (id: string, folded: boolean) => any;
  notifyItemHovered: (id: string) => any;
  notifyContextMenuOpened: (item: ItemSummary, position: [number, number], offset: number) => any;
  notifyAddViewMenuOpened: (item: ItemSummary, position: [number, number], offset: number) => any;
}
export const ItemsTree = <T,>({
  items,
  disableSorting = false,
  currentlySelectedItem,
  currentlyCollapsedItems,
  itemOptionsBar,
  contextMenuView,
  contextMenuOpen = false,
  addMenuView,
  addMenuOpen = false,
  itemIconPreviewGetter,
  notifyChangeHierarchy,
  notifyItemClick,
  notifyItemFolding,
  notifyItemHovered,
  notifyContextMenuOpened,
  notifyAddViewMenuOpened,
}: ItemsTreeProps<T>): React.JSX.Element => {
  const handleItemClick = (id: string): void => {
    notifyItemClick(id);
  };

  return (
    <>
      {contextMenuOpen && contextMenuView != null && contextMenuView}
      {addMenuOpen && addMenuView != null && addMenuView}
      <div className={styles.itemListContainer}>
        <div className={`hide-native-scrollbar ${styles.aguaItemsTreeStructureContainer}`}>
          <ItemList<T>
            disableSorting={disableSorting}
            currentlyCollapsedItems={currentlyCollapsedItems}
            itemIconPreviewGetter={itemIconPreviewGetter}
            itemOptionsBar={itemOptionsBar}
            notifyItemHovered={notifyItemHovered}
            notifyOpenContextMenu={notifyContextMenuOpened}
            notifyAddViewMenuOpened={notifyAddViewMenuOpened}
            notifyCollapsedItem={(id) => {
              notifyItemFolding(id, true);
            }}
            currentlySelectedItem={currentlySelectedItem}
            notifyItemClick={handleItemClick}
            itemNode={items}
            level={0}
            notifyChangeHierarchy={notifyChangeHierarchy}
          />
        </div>
      </div>
    </>
  );
};
