import {
  BORDER,
  BORDERRADIUS,
  BORDER_RADIUS,
  BORDER_WIDTH,
  CLOSING_PARENTESIS,
  COLOR,
  DEFAULT_COLOR,
  MINUS,
  RADIUS,
  SEMICOLON_LINE_JUMP,
  VAR_DEFINITION,
  WIDTH
} from '../../../../constants/constants';
import {
  type TypeReactAttribute,
  type ReactInfo,
  type TypeRefItemInfo,
  type TypeVerifications
} from '@globalTypes/conversor';

export function handleBorderReference (neededInfo: ReactInfo, groupVerification: boolean,
  referenceItem: TypeReactAttribute, refItemInfo: TypeRefItemInfo, variableName: string,
  noAddVerification: boolean): void {
  if (neededInfo.item == null) return;
  if (!('border' in neededInfo.item && 'color' in neededInfo.item.border.data &&
      neededInfo.item.border.data.color !== DEFAULT_COLOR && 'width' in neededInfo.item.border.data &&
      neededInfo.item.border.data.width.value !== 0)) {
    if (groupVerification) {
      const mVal = referenceItem[0].includes(BORDER_WIDTH) ? WIDTH : COLOR;
      refItemInfo.referenceString += BORDER + MINUS + mVal + VAR_DEFINITION +
        variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
    if (noAddVerification) refItemInfo.referenceList.push(variableName);
  } else {
    refItemInfo.location = referenceItem[0];
  }
  if (noAddVerification) refItemInfo.referenceList.push(variableName);
}

export function borderReferencesItem (neededInfo: ReactInfo, referenceItem: TypeReactAttribute,
  ver: TypeVerifications, refItemInfo: TypeRefItemInfo, variableName: string): void {
  if (referenceItem[0].includes(RADIUS)) {
    refItemInfo.location = BORDERRADIUS;
    if (ver.groupVerification) {
      refItemInfo.referenceString += BORDER_RADIUS + VAR_DEFINITION + variableName +
      CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP; ;
    }
    if (ver.noAddVerification) refItemInfo.referenceList.push(variableName);
  } else if (referenceItem[0].includes(BORDER)) {
    handleBorderReference(neededInfo, ver.groupVerification, referenceItem, refItemInfo,
      variableName, ver.noAddVerification);
  }
}
