import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BOOL,
  CLOSE_BRACKET,
  COMMA,
  EXACT,
  LINE_JUMP,
  POINT,
  POINT_PROPTYPE,
  PROPSTYPE_CLOSE,
  PROPSTYPE_EQUAL,
  SEMICOLON,
  STRING,
  VISIBILITY_VARIABLE
} from '../../../constants/constants';
import { type IComponentinfo, type ReactInfo } from '@globalTypes/conversor';
import { adaptItemName } from '../../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { setUpDefaultValuesNested } from './reactComponentPropsUtils';

export function loadComponentPropTypes (viewDOM: IComponentinfo, componentName: string,
  defaultValuesNested: Record<string, Record<string, boolean>>, types: Record<string, string>): void {
  const component = viewDOM.getComponentByName(componentName, viewDOM.components);
  if (component?.references == null) {
    window.logger.warn('component ' + componentName + ' not found');
    return;
  }
  const cRef = component.references.variables;
  setUpDefaultValuesNested(defaultValuesNested, component);
  for (const mref in cRef) {
    let mType = STRING;
    if (cRef[mref].type === VISIBILITY_VARIABLE) mType = BOOL;
    types[mref] = mType;
  }
}

export function getNestedChildrenContent (reference: string, neededInfo: ReactInfo, content: string,
  nestName: string): string {
  let compPName = '';
  const rName = adaptItemName(reference, true);
  for (const item in neededInfo.items) {
    if (adaptItemName(neededInfo.items[item].name, true) === rName) {
      const pItem = neededInfo.items[item];
      compPName = 'component' in pItem && typeof (pItem.component) !== 'boolean' ? pItem.component : '';
    }
  }
  content += '    ' + nestName + POINT_PROPTYPE + EXACT +
    neededInfo.componentProptypes[compPName] + PROPSTYPE_CLOSE + LINE_JUMP;
  return content;
}

export function getComponentPropTypesValueNested (neededInfo: ReactInfo,
  defaultValuesNested: Record<string, Record<string, boolean>>, children: string, content: string): string {
  for (const reference in defaultValuesNested) {
    const nestName = adaptItemName(reference, true);
    if (children.includes(nestName + POINT)) {
      content = getNestedChildrenContent(reference, neededInfo, content, nestName);
    }
  }
  return content;
}

export function getComponentPropTypes (item: TypeItemProperties, viewDOM: IComponentinfo, children: string,
  neededInfo: ReactInfo): string {
  const componentName = 'component' in item && item.component !== '' ? item.component : '';
  const types: Record<string, string> = {};
  const defaultValuesNested = {};
  loadComponentPropTypes(viewDOM, componentName, defaultValuesNested, types);
  const references = neededInfo.pagesReferences[componentName];
  if (references == null) return '';
  let content = '';
  for (const name of references) {
    if (types[name] != null) content += '    ' + name + POINT_PROPTYPE + types[name] + COMMA + LINE_JUMP;
  }
  content = getComponentPropTypesValueNested(neededInfo, defaultValuesNested, children, content);
  neededInfo.componentProptypes[componentName] = content;
  return componentName + PROPSTYPE_EQUAL + content + CLOSE_BRACKET + SEMICOLON;
}
