import { type HierarchicalData } from '@reducers/graphs';

import { getRandomColor, getRandomColorsArray } from '@globalUtils/colors';

export const getData = (
  data: any,
  theme: 'light' | 'dark',
  callbackNode?: (values?: any, id?: any, selected?: any, hovering?: any) => any,
  callbackEdge?: (values?: any, id?: any, selected?: any, hovering?: any) => any,
  callbackLabel?: (values?: any, id?: any, selected?: any, hovering?: any) => any,
  isTree: boolean = false,
  levels?: any,
  isReal: boolean = false
): [any, any, any, any, any] => {
  const colorGroups: Record<string, string> = {};
  const colors = getRandomColorsArray();
  const mnodes = data.nodes;
  const tnodes = [];
  const idsMap: Record<number, string> = {};
  const paths: Record<number, string> = {};
  const dic: Record<string, number> = {};
  let counter = 1;
  const groups: Record<string, any> = {};
  for (const node of mnodes) {
    idsMap[counter] = node;
    paths[counter] = data.fileNames?.[counter - 1] ?? '';
    const pathSplitted = node.split('/');
    let group = 'root';
    if (pathSplitted.length > 3) group = pathSplitted[0] + '/' + pathSplitted[1] + '/' + pathSplitted[2];
    else if (pathSplitted.length > 2) group = pathSplitted[0] + '/' + pathSplitted[1];
    else if (pathSplitted.length > 1) group = pathSplitted[0];
    let label = pathSplitted[pathSplitted.length - 1];
    if (!isTree) label = node;
    const mNode: any = {
      id: counter,
      label,
      group,
      widthConstraint: {
        maximum: 140,
        minimum: 140,
      },
      heightConstraint: {
        maximum: 70,
        minimum: 70,
      },
      chosen: {
        node: callbackNode,
        label: callbackLabel,
      },
      font: { color: theme === 'dark' ? '#fff' : '#000' },
    };
    if (isTree) mNode.color = theme === 'dark' ? '#444' : '#ccc';
    let randomColor = theme === 'dark' ? '#000' : '#fff';
    let randomColorBg = theme === 'dark' ? '#000' : '#fff';
    if (isReal) [randomColor, randomColorBg] = getRandomColor(colors, theme);
    if (colorGroups[group] == null) colorGroups[group] = randomColor;
    const groupColor = colorGroups[group];
    if (groups[group] == null) {
      groups[group] = {
        color: {
          border: groupColor,
          background: randomColorBg,
        },
        borderWidth: 1,
      };
    }
    if (levels != null) mNode.level = levels[counter];
    tnodes.push(mNode);
    dic[node] = counter;
    counter++;
  }

  const medges = data.edges;
  const tedges = [];
  for (const edge of medges) {
    const myEdge = {
      from: dic[edge.source],
      to: dic[edge.target],
      chosen: { edge: callbackEdge },
      smooth: true,
      arrows: { to: true },
      color: theme === 'dark' ? '#444' : '#ccc',
    };
    tedges.push(myEdge);
  }
  const nodes = new window.vis.DataSet(tnodes);
  const edges = new window.vis.DataSet(tedges);
  return [nodes, edges, groups, idsMap, paths];
};

export const getNetOptions = (
  theme: 'light' | 'dark',
  real: boolean,
  fontSize: number,
  hierarchical: HierarchicalData | null | undefined,
  isTree: boolean = false,
  groups?: any
): any => {
  const options: any = {
    nodes: {
      shape: 'box',
      font: { size: fontSize },
      margin: {
        top: 16,
        bottom: 16,
        left: 32,
        right: 32,
      },
      scaling: {
        label: { drawThreshold: 1 },
      },
    },
    edges: {
      smooth: true,
      arrows: { to: true },
      color: theme === 'dark' ? '#444' : '#ccc',
    },
  };
  let physics: any = {};
  if (real) {
    if (hierarchical != null) physics.enabled = false;
    else {
      physics = {
        stabilization: {
          enabled: true,
          fit: true,
          updateInterval: 3,
          iterations: 30,
        },
        barnesHut: {
          gravitationalConstant: -80000,
          springConstant: 0.001,
          springLength: 200,
        },
        solver: 'barnesHut',
        minVelocity: 5,
      };
    }
  }
  options.physics = physics;

  if (hierarchical != null) options.layout = { hierarchical };
  else options.layout = { improvedLayout: false };
  if (isTree) options.nodes.color = theme === 'dark' ? '#444' : '#ccc';
  if (groups != null) options.groups = groups;
  else options.nodes.font = { color: theme === 'dark' ? '#fff' : '#000' };
  return options;
};

export const displayData = (
  container: HTMLDivElement,
  theme: 'light' | 'dark',
  nodes: any,
  edges: any,
  fontSize: number = 14,
  real: boolean,
  hierarchical: HierarchicalData | null | undefined,
  isTree: boolean = false,
  groups?: any
): any => {
  const data = {
    nodes,
    edges,
  };
  const options = getNetOptions(theme, real, fontSize, hierarchical, isTree, groups);
  const network = new window.vis.Network(container, data, options);
  return network;
};

export const normalizeLevels = (levels: any): any => {
  let keys = [];
  for (const key in levels) keys.push(levels[key]);
  keys = [...new Set(keys)];
  const l = keys.length;
  keys = keys.sort((a, b) => a - b);
  let prevValue = keys[0];
  const dict: Record<string, number> = {};
  for (let i = 1; i < l; i++) {
    if (keys[i] !== prevValue + 1) {
      dict[keys[i]] = prevValue + 1;
    } else {
      dict[keys[i]] = keys[i];
    }
    prevValue = dict[keys[i]];
  }
  const levelsCopy = JSON.parse(JSON.stringify(levels));
  for (const key in levelsCopy) {
    const oldValue = levelsCopy[key];
    levelsCopy[key] = dict[oldValue] ?? 0;
  }
  return levelsCopy;
};

export const getAllNestedDecendentNodes = (
  network: any,
  data: any,
  id: any,
  marked: any = [],
  level = 0
): any[] => {
  let nodes: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.from === id) {
      const to = mEdge.to;
      nodes.push(to);
      if (marked[to] !== true) {
        marked[to] = true;
        const decendentNodes = getAllNestedDecendentNodes(network, data, to, marked, level + 1);
        nodes = [...nodes, ...decendentNodes];
      }
    }
  }
  if (level === 0) return [...new Set(nodes)];
  return nodes;
};

export const getAllNestedDecendentEdges = (
  network: any,
  data: any,
  id: any,
  marked: any = [],
  level = 0
): any[] => {
  let edges: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.from === id) {
      const to = mEdge.to;
      edges.push(edge);
      if (marked[to] !== true) {
        marked[to] = true;
        const decendentEdges = getAllNestedDecendentEdges(network, data, to, marked, level + 1);
        edges = [...edges, ...decendentEdges];
      }
    }
  }
  if (level === 0) return [...new Set(edges)];
  return edges;
};

export const getAllNestedParentNodes = (
  network: any,
  data: any,
  id: any,
  marked: any = [],
  level = 0
): any[] => {
  let nodes: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.to === id) {
      const from = mEdge.from;
      nodes.push(from);
      if (marked[from] !== true) {
        marked[from] = true;
        const decendentNodes = getAllNestedParentNodes(network, data, from, marked, level + 1);
        nodes = [...nodes, ...decendentNodes];
      }
    }
  }
  if (level === 0) return [...new Set(nodes)];
  return nodes;
};

export const getAllNestedParentEdgesNodes = (
  network: any,
  data: any,
  id: any,
  marked: any = [],
  level = 0
): any[] => {
  let edges: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.to === id) {
      const from = mEdge.from;
      edges.push(edge);
      if (marked[from] !== true) {
        marked[from] = true;
        const decendentEdges = getAllNestedParentEdgesNodes(network, data, from, marked, level + 1);
        edges = [...edges, ...decendentEdges];
      }
    }
  }
  if (level === 0) return [...new Set(edges)];
  return edges;
};

export const getFirstLevelParentEdges = (network: any, data: any, id: any, marked: any = []): any[] => {
  let edges: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.to === id) {
      const from = mEdge.from;
      edges.push(edge);
      if (marked[from] !== true) {
        marked[from] = true;
        edges = [...edges];
      }
    }
  }
  return [...new Set(edges)];
};

export const getNodeById = (data: any, id: string): any => {
  const nodes = data.nodes._data;
  return nodes.get(id);
};

export const getFirstLevelParentNodes = (network: any, data: any, id: any, marked: any = []): any[] => {
  let nodes: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.to === id) {
      const from = mEdge.from;
      nodes.push(from);
      if (marked[from] !== true) {
        marked[from] = true;
        nodes = [...nodes];
      }
    }
  }
  return [...new Set(nodes)];
};

export const getFirstLevelDecendentNodes = (network: any, data: any, id: any, marked: any = []): any[] => {
  let nodes: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.from === id) {
      const to = mEdge.to;
      nodes.push(to);
      if (marked[to] !== true) {
        marked[to] = true;
        nodes = [...nodes];
      }
    }
  }
  return [...new Set(nodes)];
};

export const getFirstLevelDecendentEdges = (network: any, data: any, id: any, marked: any = []): any[] => {
  let edges: any = [];
  const allEdges = data.edges._data;
  const connectedEdges = network.getConnectedEdges(id);
  for (const edge of connectedEdges) {
    const mEdge = allEdges.get(edge);
    if (mEdge != null && mEdge.from === id) {
      const to = mEdge.to;
      edges.push(edge);
      if (marked[to] !== true) {
        marked[to] = true;
        edges = [...edges];
      }
    }
  }
  return [...new Set(edges)];
};

export const branchesAreEqual = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) return false;
  let equal = true;
  for (const branch of a) {
    const isContained = b.includes(branch);
    if (!isContained) {
      equal = false;
      break;
    }
  }
  return equal;
};

export const getNameFromLabel = (label: string): string => {
  if (!label.includes('/')) return label;
  const parts = label.split('/');
  return parts[parts.length - 1].trim();
};
