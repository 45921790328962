export const DATA = 'data';
export const KIND = 'kind';
export const COMMON_KIND = 'commonKind';
export const ID = 'id';
export const NAME = 'name';
export const PARENT = 'parent';
export const INDEX = 'index';
export const CLASSES = 'classes';
export const CURSOR_POINTER = 'cursorPointer';
export const CLASSNAME = 'classname';
export const CUSTOM_STYLES = 'customStyles';
export const CURSOR = 'cursor';
export const CURSOR_POINTER_CSS = 'cursor: ';
export const HREF = 'href';
export const HREF_BRACKET = '{href}';
export const CHILDREN = '{children}';
export const SCROLLABLE = 'scrollable';
export const TAG = 'tag';
export const VISIBLE = 'visible';
export const SRC = 'src';
export const STYLE = 'style';
export const S = 's';
export const BRACKET_SCROLLABLE = '{scrollable}';
export const UNDEFINED = 'undefined';
export const ALL = 'all';
export const VISIBILITY_BRACKET = '{visibility}';
export const CURSOR_BRACKET = '{cursorPointer}';
