/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { TbBrandVisualStudio } from 'react-icons/tb';
import { useDispatch } from 'react-redux';

import { Icon } from '@components/icon';

import { setMainMenuTab } from '@reducers/editor';

import { trackEvent } from '@globalUtils/metrics';

import styles from './styles.module.css';

export const FloatingAgua = ({
  children,
  notifyPointer,
  pointer,
  expanded,
  notifyExpanded,
}: {
  children: React.JSX.Element;
  notifyPointer: (pointer: boolean) => any;
  pointer: boolean;
  expanded: boolean;
  notifyExpanded: (expand: boolean) => any;
}): React.JSX.Element => {
  const handleExpandClick = (e: any): void => {
    e.stopPropagation();
    notifyExpanded(true);
  };

  const minimizeClick = (e: any): void => {
    e.stopPropagation();
    if (expanded) notifyExpanded(false);
  };

  const togglePointer = (e: any): void => {
    e.stopPropagation();
    notifyPointer(!pointer);
  };

  const mWidth = '150px';

  return (
    <div
      onClick={minimizeClick}
      style={{
        position: 'absolute',
        width: expanded ? '100%' : mWidth,
        height: expanded ? '100%' : 'calc(var(--button-height) * 2)',
        top: expanded ? 0 : '100%',
        left: expanded ? 0 : '50%',
        transform: expanded ? '' : 'translateY(-50%) translateX(-50%)',
        zIndex: 2,
      }}
    >
      <div
        onClick={handleExpandClick}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 3,
          bottom: expanded ? 'calc(var(--button-height) * -1)' : '0',
          left: expanded ? 'calc(50% - calc(' + mWidth + ' / 2))' : '',
          borderRadius: 'var(--border-radius-m)',
          width: mWidth,
          height: 'calc(var(--button-height) * 2)',
          border: '1px solid var(--border-color)',
          backgroundColor: 'var(--theme-color)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            flex: '1 1 50%',
            width: '100%',
            height: 'var(--button-height)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div
            style={{
              flex: '1 1 50%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/agua_white.png" style={{ height: '15px' }}></img>
          </div>
          <div
            style={{
              flexShrink: 0,
              width: '1px',
              height: 'calc(100% - calc(var(--padding-s) * 3))',
              backgroundColor: 'var(--border-color)',
            }}
          ></div>
          <div
            style={{
              width: 'calc(' + mWidth + ' / 2.3)',
              height: 'fit-content',
              flexShrink: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Agua
          </div>
          <div
            style={{
              flexShrink: 0,
              width: '1px',
              height: 'calc(100% - calc(var(--padding-s) * 3))',
              backgroundColor: 'var(--border-color)',
            }}
          ></div>
          <div
            style={{
              flex: '1 1 50%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              onClick={togglePointer}
              className={styles.hovered}
              style={{ padding: 'var(--padding-xs)', borderRadius: 'var(--border-radius-m)' }}
            >
              <Icon color={pointer ? 'var(--primary-color)' : 'var(--title-color)'} icon="my_location" />
            </div>
          </div>
        </div>
        <div
          style={{
            flex: '1 1 50%',
            width: '100%',
            height: 'var(--button-height)',
            display: 'flex',
            alignItems: 'center',
          }}
        ></div>
      </div>
      {expanded && (
        <div
          style={{
            position: 'absolute',
            zIndex: 4,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              position: 'absolute',
              zIndex: 5,
              width: 'calc(100% - calc(var(--padding-l) * 2))',
              height: 'calc(100% - calc(var(--padding-l) * 2))',
              border: '1px solid var(--border-color)',
              borderRadius: 'var(--border-radius-xxl)',
              overflow: 'hidden',
              backgroundColor: 'var(--background-color-2)',
            }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export const Overlay = ({
  info,
  notifyStop,
  restartPointer,
  notifyOpenVisual,
  notifyOpenLocal,
}: {
  info: {
    top: number;
    left: number;
    width: number;
    height: number;
    fileName: string;
    lineNumber: number;
  } | null;
  notifyStop: (show: boolean) => any;
  restartPointer: () => any;
  notifyOpenVisual: () => any;
  notifyOpenLocal: () => any;
}): React.JSX.Element => {
  const [clicked, setClicked] = useState<boolean>(false);

  const receiveEvents = (event: any): void => {
    const sender = event?.data?.sender;
    if (sender === 'clickItem') {
      setClicked(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveEvents);
    return () => {
      window.removeEventListener('message', receiveEvents);
    };
  }, []);

  useEffect(() => {
    setClicked(false);
  }, [info]);

  if (info == null) return <></>;

  const fileName = info.fileName;
  const fileNameParts = fileName.split('/');
  const name = fileNameParts[fileNameParts.length - 1];

  const maxWidth = window.innerWidth;
  const maxHeight = window.innerHeight;

  const tooltipMaxWidth = 150;

  let top = info.top + info.height + 4;
  if (top + 30 > maxHeight) top = info.top - 34;

  let left: string = info.left + '';
  let right: string = '';
  if (info.left + tooltipMaxWidth > maxWidth) {
    left = '';
    right = '0';
  }

  return (
    <div style={{ pointerEvents: 'none', width: '100%', height: '100%', position: 'absolute', zIndex: 20 }}>
      <div
        style={{
          position: 'fixed',
          width: info.width + 'px',
          height: info.height + 'px',
          left: info.left + 'px',
          top: info.top + 'px',
          border: (clicked ? '2' : '1') + 'px solid var(--primary-color)',
          borderRadius: 'var(--border-radius-m)',
        }}
      ></div>
      <div
        onMouseLeave={restartPointer}
        style={{
          pointerEvents: 'auto',
          position: 'fixed',
          width: 'fit-content',
          height: '30px',
          left: left !== '' ? left + 'px' : '',
          right: right !== '' ? right + 'px' : '',
          top: top + 'px',
          padding: '0px var(--padding-s)',
          border: (clicked ? '2' : '1') + 'px solid white',
          backgroundColor: 'var(--background-color-0)',
          color: 'var(--title-color)',
          borderRadius: 'var(--border-radius-m)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 15px',
        }}
      >
        <div>{name}</div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'var(--padding-xl)' }}>
          <div
            onClick={() => {
              notifyStop(false);
              notifyOpenVisual();
            }}
            className={styles.hovered}
            style={{
              padding: 'var(--padding-xs)',
              borderRadius: 'var(--border-radius-m)',
              cursor: 'pointer',
              fontSize: 'var(--font-size-icon-large)',
              marginLeft: 'var(--padding-s)',
            }}
          >
            <TbBrandVisualStudio />
          </div>
          {clicked && (
            <div
              onClick={() => {
                setClicked(false);
                restartPointer();
              }}
              style={{
                borderRadius: '100px',
                padding: '2px',
                backgroundColor: 'var(--border-color)',
                marginLeft: 'var(--padding-m)',
                cursor: 'pointer',
              }}
            >
              <Icon color="var(--title-color)" icon="close" iconSize="small" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const MainIframe = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const firstTime = useRef<boolean>(false);

  const dispatch = useDispatch();

  const [pointer, setPointer] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [overlayInfo, setOverlayInfo] = useState<any>(null);

  const receiveEvents = (event: any): void => {
    const sender = event?.data?.sender;
    if (sender === 'overlayItem') {
      const info = event.data.content;
      setOverlayInfo(JSON.parse(info));
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveEvents);
    return () => {
      window.removeEventListener('message', receiveEvents);
    };
  }, []);

  const setFrameSrcWithRetry = async (
    iframe: HTMLIFrameElement,
    src: string,
    maxRetries = 6,
    initialDelayMs = 1500
  ): Promise<void> => {
    if (iframe == null) return;
    try {
      await fetch(src);
      iframe.src = src;
    } catch (e) {
      setTimeout(() => {
        void setFrameSrcWithRetry(iframe, src, maxRetries - 1);
      }, initialDelayMs);
    }
  };

  useEffect(() => {
    const mIframe = iframeRef.current;
    if (mIframe == null) return;
    if (firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to Main Page');
    void setFrameSrcWithRetry(mIframe, 'http://localhost:4040/agua.index.html');
  }, []);

  const stopPointer = (show: boolean): void => {
    const iframe = iframeRef.current;
    if (iframe == null) return;
    let message = 'startPointerMode';
    if (!show) message = 'stopPointerMode';
    iframe.contentWindow?.postMessage(
      {
        sender: message,
      },
      '*'
    );
  };

  const notifyOpenLocal = (): void => {
    const fn = overlayInfo.fileName;
    void fetch('http://localhost:4035/fs/openFileServer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename: fn }),
    });
    trackEvent('Opened Local VS from pointer');
    dispatch(setMainMenuTab('code'));
    setExpanded(true);
  };

  const notifyOpenVisual = (): void => {
    const fn = overlayInfo.fileName;
    void fetch('http://localhost:4035/fs/openFile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename: fn }),
    });
    trackEvent('Opened VS from pointer');
  };

  const handlePointerClicked = (pointer: boolean): void => {
    setPointer(pointer);
    stopPointer(pointer);
    if (pointer) trackEvent('Selected pointer');
  };

  const restartPointer = (): void => {
    stopPointer(false);
    setTimeout(() => {
      stopPointer(true);
    }, 200);
  };

  const notifyExpanded = (expand: boolean): void => {
    setExpanded(expand);
    if (expand) trackEvent('Expanded Agua');
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <iframe ref={iframeRef} style={{ border: 'none', width: '100%', height: '100%' }}></iframe>
      <FloatingAgua
        expanded={expanded}
        notifyExpanded={notifyExpanded}
        pointer={pointer}
        notifyPointer={handlePointerClicked}
      >
        {children}
      </FloatingAgua>
      {pointer && (
        <Overlay
          notifyOpenVisual={notifyOpenVisual}
          notifyOpenLocal={notifyOpenLocal}
          notifyStop={handlePointerClicked}
          restartPointer={restartPointer}
          info={overlayInfo}
        />
      )}
    </div>
  );
};
