import { DimensionType, type TypeItemProperties } from '@globalTypes/itemProperties';
import { type ITreeNode } from '@globalUtils/tree';
import {
  AGUARENDER,
  AGUA_ITEM,
  AGUA_ITEM_CLOSE,
  AGUA_NO_BRACKET,
  AGUA_NO_IMPORT,
  ANIMATION_IMPORTS,
  ANIMATION_REFS,
  CANVAS_MODE,
  CLOSE_BRACKET,
  CUSTOM,
  DEFAULT_THEME,
  FILL,
  FUNCTION,
  HAS_ON_CLICK,
  IMAGES_VARIABLES,
  IMPORT_ANIMATE,
  IMPORT_REACT_ROUTER,
  IMPORT_USE,
  JSX_FORMAT,
  LAYER,
  LINE_JUMP,
  LINK,
  MAIN,
  MAX_WIDTH,
  MEDIA_DEF,
  MEDIA_DEF_CLOSE,
  MEDIA_DESKTOP,
  MEDIA_PHONE,
  MEDIA_TABLET,
  MIN_WIDTH,
  MODULE_CSS_FORMAT,
  PAGE_EXTERNAL_FILE,
  PAGE_EXTERNAL_FUNCTIONS,
  PARENTESIS,
  POINT,
  REACT_OBSERVER,
  RESPONSIVENESS_THEME,
  RESPONSIVE_VARIABLES,
  SCREEN,
  UNDEFINED,
  UNDERSCORE,
  USE_CLIENT
} from '../../constants/constants';
import {
  type IComponentinfo,
  type TypeItems,
  type ReactInfo,
  type DimensionVariable,
  type OrientationVariable
} from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';
import { adaptItemName } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { createPage } from './reactCreatePagesMethods';
import { createTreeParent } from './reactCreateTreeParentMethods';
import { handleImports } from './reactFileUtils';
import {
  combineCssUserEditGenerated,
  getAllAguaEditable,
  replaceAguaGeneratedFromUserEdited,
  unifyImports
} from './reactUserEditedMethods';

export function addResponsiveOrientationVariable (responsiveOrientationVariables: any,
  neededInfo: ReactInfo): void {
  const pageName = neededInfo.pageName;
  for (const variable in responsiveOrientationVariables) {
    const theme = variable.includes(UNDERSCORE) ? RESPONSIVENESS_THEME : DEFAULT_THEME;
    const defaultValue = neededInfo.infoThemes[theme][variable] as OrientationVariable;
    neededInfo.defaultVariables +=
      responsiveOrientationVariables[variable][defaultValue.value.toLowerCase()]?.[pageName] ?? '';
    const webVar = neededInfo.responsiveValues.desktop[theme][variable] as OrientationVariable;
    if (webVar != null) {
      neededInfo.webVariables +=
        responsiveOrientationVariables[variable][webVar.value.toLowerCase()][pageName];
    }
    const phoneVar = neededInfo.responsiveValues.phone[theme][variable] as OrientationVariable;
    if (phoneVar != null) {
      neededInfo.phoneVariables +=
        responsiveOrientationVariables[variable][phoneVar.value.toLowerCase()][pageName];
    }
    const tableteVar = neededInfo.responsiveValues.tablet[theme][variable] as OrientationVariable;
    if (tableteVar != null) {
      neededInfo.tabletVariables +=
        responsiveOrientationVariables[variable][tableteVar.value.toLowerCase()][pageName];
    }
  }
}

export function addOrientationVariables (defaultOrientationVariables: any,
  responsiveOrientationVariables: any, neededInfo: ReactInfo): void {
  const pageName = neededInfo.pageName;
  for (const variable in defaultOrientationVariables) {
    const theme = variable.includes(UNDERSCORE) ? RESPONSIVENESS_THEME : DEFAULT_THEME;
    const defaultValue = neededInfo.infoThemes[theme][variable] as OrientationVariable;
    neededInfo.defaultVariables +=
      defaultOrientationVariables[variable][defaultValue.value.toLowerCase()]?.[pageName] ?? '';
    const webVar = neededInfo.responsiveValues.desktop[theme][variable] as OrientationVariable;
    if (webVar != null) {
      neededInfo.webVariables += defaultOrientationVariables[variable][webVar.value.toLowerCase()][pageName];
    }
    const phoneVar = neededInfo.responsiveValues.phone[theme][variable] as OrientationVariable;
    if (phoneVar != null) {
      neededInfo.phoneVariables +=
        defaultOrientationVariables[variable][phoneVar.value.toLowerCase()][pageName];
    }
    const tabletVar = neededInfo.responsiveValues.tablet[theme][variable] as OrientationVariable;
    if (tabletVar != null) {
      neededInfo.tabletVariables +=
        defaultOrientationVariables[variable][tabletVar.value.toLowerCase()][pageName];
    }
  }
  if (responsiveOrientationVariables != null) {
    addResponsiveOrientationVariable(responsiveOrientationVariables, neededInfo);
  }
}

export function addResponsiveFillVariables (responsiveFillVariables: any, neededInfo: ReactInfo): void {
  for (const variable in responsiveFillVariables) {
    const theme = variable.includes(UNDERSCORE) ? RESPONSIVENESS_THEME : DEFAULT_THEME;
    const desVal = neededInfo.responsiveValues.desktop[theme][variable] as DimensionVariable;
    if (desVal != null && desVal.value[DimensionType.DimensionKindProperty] === FILL) {
      neededInfo.webVariables += responsiveFillVariables[variable][neededInfo.pageName];
    }
    const phoneVal = neededInfo.responsiveValues.phone[theme][variable] as DimensionVariable;
    if (phoneVal != null && phoneVal.value[DimensionType.DimensionKindProperty] === FILL) {
      neededInfo.phoneVariables += responsiveFillVariables[variable][neededInfo.pageName];
    }
    const tabletVal = neededInfo.responsiveValues.tablet[theme][variable] as DimensionVariable;
    if (tabletVal != null && tabletVal.value[DimensionType.DimensionKindProperty] === FILL) {
      neededInfo.tabletVariables += responsiveFillVariables[variable][neededInfo.pageName];
    }
  }
}

export function addFillVairables (defaultFillVariables: any, responsiveFillVariables: any,
  neededInfo: ReactInfo): void {
  for (const variable in defaultFillVariables) {
    const theme = variable.includes('_') ? RESPONSIVENESS_THEME : DEFAULT_THEME;
    const desVal = neededInfo.responsiveValues.desktop[theme][variable] as DimensionVariable;
    if (desVal != null && desVal.value[DimensionType.DimensionKindProperty] === FILL) {
      neededInfo.webVariables += defaultFillVariables[variable][neededInfo.pageName];
    }
    const phoneVal = neededInfo.responsiveValues.phone[theme][variable] as DimensionVariable;
    if (phoneVal != null && phoneVal.value[DimensionType.DimensionKindProperty] === FILL) {
      neededInfo.phoneVariables += defaultFillVariables[variable][neededInfo.pageName];
    }
    const tabletVal = neededInfo.responsiveValues.tablet[theme][variable] as DimensionVariable;
    if (tabletVal != null && tabletVal.value[DimensionType.DimensionKindProperty] === FILL) {
      neededInfo.tabletVariables += defaultFillVariables[variable][neededInfo.pageName];
    }
  }
  if (responsiveFillVariables != null) {
    addResponsiveFillVariables(responsiveFillVariables, neededInfo);
  }
}

export function handleFillOrientationVariables (neededInfo: ReactInfo, pageStyles: string): string {
  neededInfo.defaultVariables = neededInfo.defaultVariables.replaceAll(UNDEFINED, '');
  neededInfo.phoneVariables = neededInfo.phoneVariables.replaceAll(UNDEFINED, '');
  neededInfo.webVariables = neededInfo.webVariables.replaceAll(UNDEFINED, '');
  neededInfo.tabletVariables = neededInfo.tabletVariables.replaceAll(UNDEFINED, '');
  if (neededInfo.defaultVariables !== '') pageStyles += LINE_JUMP + neededInfo.defaultVariables;
  let mediaP = MEDIA_PHONE;
  if (neededInfo.mediaValues != null) {
    mediaP = MIN_WIDTH + neededInfo.mediaValues.phone.value + neededInfo.mediaValues.phone.unit;
  }
  let mediaT = MEDIA_TABLET;
  if (neededInfo.mediaValues != null) {
    mediaT = MAX_WIDTH + (neededInfo.mediaValues.desktop.value - 1) +
      neededInfo.mediaValues.desktop.unit + ') and (' + MIN_WIDTH + (neededInfo.mediaValues.phone.value +
      neededInfo.mediaValues.phone.unit + 1);
  }
  let mediaD = MEDIA_DESKTOP;
  if (neededInfo.mediaValues != null) {
    mediaD = MIN_WIDTH + neededInfo.mediaValues.desktop.value + neededInfo.mediaValues.desktop.unit;
  }
  if (neededInfo.phoneVariables !== '') {
    pageStyles += LINE_JUMP + MEDIA_DEF + mediaP + MEDIA_DEF_CLOSE + neededInfo.phoneVariables +
      LINE_JUMP + CLOSE_BRACKET;
  }
  if (neededInfo.webVariables !== '') {
    pageStyles += LINE_JUMP + MEDIA_DEF + mediaD + MEDIA_DEF_CLOSE + neededInfo.webVariables +
      LINE_JUMP + CLOSE_BRACKET;
  }
  if (neededInfo.tabletVariables !== '') {
    pageStyles += LINE_JUMP + MEDIA_DEF + mediaT + MEDIA_DEF_CLOSE + neededInfo.tabletVariables +
      LINE_JUMP + CLOSE_BRACKET;
  }
  return pageStyles;
}

export function addFillOrientationVariables (neededInfo: ReactInfo, pageName: string,
  pageStyles: string): string {
  neededInfo.pageName = pageName;
  const defaultOrientationVariables = neededInfo.orientationVariables.Default;
  const responsiveOrientationVariables = neededInfo.orientationVariables.Responsiveness;
  const defaultFillVariables = neededInfo.fillVariables.Default;
  const responsiveFillVariables = neededInfo.fillVariables.Responsiveness;
  if (defaultOrientationVariables != null || responsiveOrientationVariables != null ||
      defaultFillVariables != null || responsiveFillVariables != null ||
      neededInfo.pagesResponsiveVariables[pageName] != null) {
    neededInfo.defaultVariables = '';
    neededInfo.phoneVariables = '';
    neededInfo.webVariables = '';
    neededInfo.tabletVariables = '';
    if (neededInfo.pagesResponsiveVariables[pageName] != null) {
      const className = MAIN + POINT + pageName + AGUA_ITEM + SCREEN + AGUA_ITEM_CLOSE + LINE_JUMP;
      const mVal = LINE_JUMP + CLOSE_BRACKET + LINE_JUMP;
      neededInfo.phoneVariables += className + neededInfo.pagesResponsiveVariables[pageName][0] + mVal;
      neededInfo.webVariables += className + neededInfo.pagesResponsiveVariables[pageName][1] + mVal;
      neededInfo.tabletVariables += className + neededInfo.pagesResponsiveVariables[pageName][2] + mVal;
    }
    addOrientationVariables(defaultOrientationVariables, responsiveOrientationVariables,
      neededInfo);
    addFillVairables(defaultFillVariables, responsiveFillVariables, neededInfo);
    pageStyles = handleFillOrientationVariables(neededInfo, pageStyles);
  }
  return pageStyles;
}

export function getPageStyles (neededInfo: ReactInfo, pageName: string, archiveName: string): string {
  let pageStyles = neededInfo.styles[pageName];
  if (neededInfo.aditionalTextVariable[pageName] !== '') {
    pageStyles += neededInfo.aditionalTextVariable[pageName];
  }
  pageStyles = addFillOrientationVariables(neededInfo, pageName, pageStyles);
  pageStyles = pageStyles.replace(RESPONSIVE_VARIABLES, '');
  if (neededInfo.userPagesObject?.[archiveName]?.[archiveName + MODULE_CSS_FORMAT] != null) {
    const userEdited = neededInfo.userPagesObject[archiveName][archiveName + MODULE_CSS_FORMAT];
    pageStyles = combineCssUserEditGenerated(userEdited, pageStyles);
  }
  return pageStyles;
}

export function handlePageHtmlAnimations (neededInfo: ReactInfo, pageHtml: string, pageName: string): string {
  if (neededInfo.pageHasAnimation[pageName] != null) {
    pageHtml = pageHtml.replace(ANIMATION_IMPORTS, ANIMATION_IMPORTS + IMPORT_ANIMATE + LINE_JUMP);
  }
  if (neededInfo.pageAnimationVisible[pageName] !== '') {
    const importVal = USE_CLIENT + LINE_JUMP + REACT_OBSERVER + LINE_JUMP;
    pageHtml = pageHtml.replace(ANIMATION_IMPORTS, importVal);
    pageHtml = pageHtml.replace(ANIMATION_REFS, neededInfo.pageAnimationVisible[pageName]);
  } else {
    pageHtml = pageHtml.replace(ANIMATION_IMPORTS, '');
    pageHtml = pageHtml.replace(ANIMATION_REFS, '');
  }
  return pageHtml;
}

export function getPageHtml (treeRoot: ITreeNode[], items: TypeItems, item: TypeItemProperties,
  pageName: string, neededInfo: ReactInfo, dictionary: TypeDictionary, viewDOM: IComponentinfo): string {
  const body = createTreeParent(treeRoot, items, pageName, neededInfo, dictionary, viewDOM);
  let pageHtml = createPage(item, body, pageName, neededInfo, dictionary, viewDOM);
  if (neededInfo.aguaEditables != null) {
    const aguaImport = LINE_JUMP + AGUA_NO_IMPORT;
    pageHtml = pageHtml.replace(AGUA_NO_BRACKET, aguaImport);
  } else pageHtml = pageHtml.replace(AGUA_NO_BRACKET, '');
  if (neededInfo.pageHasChildrenHref[pageName] != null) {
    pageHtml = pageHtml.replace(LINK, IMPORT_REACT_ROUTER);
  } else pageHtml = pageHtml.replace(LINK, '');
  pageHtml =
    handleImports(neededInfo.componentsUsed[pageName], pageHtml, neededInfo);
  const cVal = neededInfo.isPreview ? dictionary.openInNewTab : dictionary.openInPreview;
  const clickVal = neededInfo.pageHasChildrenHrefExternal[pageName] != null ? cVal : '';
  pageHtml = pageHtml.replace(HAS_ON_CLICK, neededInfo.canvas ? dictionary.selectItem : clickVal);
  pageHtml = pageHtml.replace(IMAGES_VARIABLES, neededInfo.pageImage[pageName] ?? '');
  if (neededInfo.pageResponsiveVariables[pageName] != null) {
    pageHtml = pageHtml.replace(RESPONSIVE_VARIABLES, neededInfo.pageResponsiveVariables[pageName]);
  } else pageHtml = pageHtml.replace(RESPONSIVE_VARIABLES, '');
  pageHtml = handlePageHtmlAnimations(neededInfo, pageHtml, pageName);
  pageHtml = pageHtml.replace(PAGE_EXTERNAL_FUNCTIONS, neededInfo.pageExternalFunctions[pageName]);
  pageHtml = pageHtml.replace(PAGE_EXTERNAL_FILE, neededInfo.pageExternalFile[pageName]);
  const canvasMode = neededInfo.canvas ? LINE_JUMP + IMPORT_USE : '';
  pageHtml = pageHtml.replace(CANVAS_MODE, canvasMode);
  return pageHtml;
}

export function initCreateFileInstance (neededInfo: ReactInfo, pageName: string): void {
  if (neededInfo.styles[pageName] == null) neededInfo.styles[pageName] = '';
  if (neededInfo.customStyles[pageName] != null) neededInfo.customStyles[pageName] = undefined;
  neededInfo.pageAnimationVisible[pageName] = '';
  neededInfo.pageImage[pageName] = '';
  neededInfo.pageExternalFunctions[pageName] = '';
  neededInfo.pageExternalFile[pageName] = '';
  neededInfo.pageExternalFiles[pageName] = {};
}

export function getFunctionsItems (treeRoot: ITreeNode[], items: TypeItems): string[] {
  let functions: string[] = [];
  for (const treeItem of treeRoot) {
    const item = items[treeItem.id];
    if ('externalFunction' in item && item.externalFunction != null) {
      functions.push(adaptItemName(item.name, false));
    }
    if (item.commonKind === LAYER) {
      functions = functions.concat(getFunctionsItems(treeItem.children, items));
    }
  }
  return functions;
}

export function getPageHtmlUserEdited (treeRoot: ITreeNode[], items: TypeItems,
  item: TypeItemProperties, pageName: string, neededInfo: ReactInfo, dictionary: TypeDictionary,
  viewDOM: IComponentinfo): string {
  let pageHtml = '';
  const archiveName = pageName.substring(0, 1).toLowerCase() + pageName.substring(1);
  const functionsItems = getFunctionsItems(treeRoot, items);
  functionsItems.push(AGUARENDER);
  const temporaryPage = neededInfo.userPagesObject[archiveName][archiveName + JSX_FORMAT];
  neededInfo.aguaEditables = getAllAguaEditable(temporaryPage, functionsItems, items);
  pageHtml = getPageHtml(treeRoot, items, item, pageName, neededInfo, dictionary, viewDOM);
  let page = '';
  const componentName = FUNCTION + pageName + PARENTESIS;
  for (const funItem of functionsItems) {
    const functionName = FUNCTION + funItem + PARENTESIS;
    page = replaceAguaGeneratedFromUserEdited(componentName, pageHtml, temporaryPage, functionName);
  }
  pageHtml = unifyImports(componentName, pageHtml, page);
  neededInfo.aguaEditables = false;
  return pageHtml;
}

export function createFileInstance (data: any, neededInfo: ReactInfo, dictionary: TypeDictionary,
  viewDOM: IComponentinfo, pagesObject: any): void {
  if (neededInfo.firstPage === '') neededInfo.firstPage = data.pageName;
  initCreateFileInstance(neededInfo, data.pageName);
  const archiveName = data.pageName.substring(0, 1).toLowerCase() + data.pageName.substring(1);
  let pageHtml = '';
  if (neededInfo.userPagesObject?.[archiveName]?.[archiveName + JSX_FORMAT] != null) {
    pageHtml = getPageHtmlUserEdited(data.treeRoot, data.items, data.item, data.pageName, neededInfo,
      dictionary, viewDOM);
  } else {
    pageHtml = getPageHtml(data.treeRoot, data.items, data.item, data.pageName, neededInfo, dictionary,
      viewDOM);
  }
  const pageStyles = getPageStyles(neededInfo, data.pageName, archiveName);
  pagesObject[archiveName] = {};
  pagesObject[archiveName][archiveName + JSX_FORMAT] = pageHtml;
  pagesObject[archiveName][archiveName + MODULE_CSS_FORMAT] = pageStyles.replaceAll(UNDEFINED, '');
  if (neededInfo.customStyles[data.pageName] != null) {
    pagesObject[archiveName][CUSTOM + archiveName + MODULE_CSS_FORMAT] =
      neededInfo.customStyles[data.pageName];
  }
  for (const file in neededInfo.pageExternalFiles[data.pageName]) {
    pagesObject[archiveName][file + JSX_FORMAT] = neededInfo.pageExternalFiles[data.pageName][file];
  }
}
