import React from 'react';

import { APILayout } from '@layouts/apiLayout';

import { APIRequestPane } from '@sections/apiRequestPane';
import { APISideMenu } from '@sections/apiSideMenu';
import { APISidebarPane } from '@sections/apiSidebarPane';

/*
import { Collection } from 'postman-collection';
import { extractPostmanItems } from '@globalUtils/postman';
import mData from './out.json';
const myCollection = new Collection(mData);
const initialItems = myCollection.items.all();
const postmanItems = extractPostmanItems(initialItems);
console.log(postmanItems);
*/

export const APIPage = (): React.JSX.Element => {
  const MenuSideBar = <APISideMenu />;
  const SideBar = <APISidebarPane />;
  const Header = <div>Hola header</div>;
  const Content = <APIRequestPane />;
  const Response = <div>Hola response</div>;

  return (
    <APILayout
      menuSideBar={MenuSideBar}
      sideBar={SideBar}
      headerPane={Header}
      requestPane={Content}
      responsePane={Response}
    />
  );
};
