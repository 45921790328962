import React from 'react';

import { Icon } from '@components/icon';

import styles from './styles.module.css';
import { type Kinds } from './types';

interface AppBarButtonProps {
  icon: string;
  label: string;
  kind: Kinds.BarItemKinds;
  active?: boolean;
  hideIcon?: boolean;
}
export const AppBarButton = ({
  icon,
  label,
  kind,
  active = false,
  hideIcon = false,
}: AppBarButtonProps): React.JSX.Element => {
  return (
    <div
      className={styles[kind]}
      style={{
        width: hideIcon ? '110px' : '',
        display: 'flex',
        height: 'calc(100% + 1px)',
        justifyContent: hideIcon ? 'center' : '',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingRight: '16px',
        backgroundColor: 'transparent',
        borderBottom: '2px solid ' + (active ? 'var(--primary-color)' : 'transparent'),
      }}
    >
      {!hideIcon && <Icon icon={icon} color="var(--title-color)" />}
      <div
        style={{
          marginLeft: hideIcon ? '' : '16px',
          marginRight: hideIcon ? '' : '16px',
          color: 'var(--title-color)',
          fontSize: 'var(--font-size-body-small)',
        }}
      >
        {label}
      </div>
    </div>
  );
};
