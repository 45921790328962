export interface TypeLayerDictionary {
  radius: string;
  layerShadow: string;
  sticky: string;
  wrapStyle: string;
  transparentColor: string;
  transparentClosing: string;
}

export const ReactLayerDictionary: TypeLayerDictionary = {
  radius: 'border-radius: ',
  layerShadow: 'box-shadow: {OffsetX} {OffsetY} {Blur}px #{Color};',
  sticky: 'position: sticky;\n top: 0;\nz-index: 1;',
  wrapStyle: 'flex-wrap: wrap;',
  transparentColor: 'background-color: ',
  transparentClosing: ' ;',
};
