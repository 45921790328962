import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BRACKET,
  COMPONENT_VARIABLE,
  DEFAULT_THEME,
  ICON,
  LINE_JUMP,
  MINUS,
  REFERENCE_BRACKET,
  UNDERSCORE,
  VALUE_HEIGHT,
  VALUE_WIDTH,
  VARIANT_PAGES
} from '../../../constants/constants';
import {
  type ActionLocationDictionaryType,
  type IComponentinfo,
  type TypeReactAttributeReferences,
  type ReactInfo
} from '@globalTypes/conversor';
import { type TypeDictionary } from '../../dictionary/dictionary';
import { externalStyle } from './reactCSSMethods';
import { getReferenceItem } from './reactReferenceMethods';
import {
  convertClassToItem
} from '@compiler/ConversionMethods/reactThemeMethods/reactGroupReferencesMethods';
import { type ReferencesGroupType } from '@globalTypes/itemProperties/classesType';

export function handleItemState (item: TypeItemProperties, pageName: string, neededInfo: ReactInfo,
  viewDOM: IComponentinfo, dictionary: TypeDictionary): boolean {
  if ('state' in item && item.state != null) {
    for (const state in item.state) {
      const tempState = item.state[state];
      const tempItem = convertClassToItem(tempState);
      tempItem.name = item.name + UNDERSCORE + state;
      tempItem.id = item.id;
      tempItem.nameChanged = true;
      let stateStyle = externalStyle(tempItem, dictionary, neededInfo);
      stateStyle = stateStyle.replace(COMPONENT_VARIABLE, '');
      stateStyle = stateStyle.replace(REFERENCE_BRACKET,
        referencesVariantVariables(tempState, tempItem, neededInfo, viewDOM));
      stateStyle = '*' +
        stateStyle.replace(BRACKET, '[custom-agua-item][custom-agua-variant] ' + BRACKET);
      neededInfo.styles[pageName] += stateStyle + LINE_JUMP;
      if ('currentVariant' in item && item.currentVariant === state) {
        neededInfo.objectClassnames[item.id].push([item.name + UNDERSCORE + state, true]);
      }
    }
    return true;
  } else return false;
}

export function getReferencesVariantVariable (references: ActionLocationDictionaryType, values: string[],
  item: TypeItemProperties, newReferences: TypeReactAttributeReferences, variable: string): void {
  const key = values[1] as keyof typeof references;
  const mReferences = references[key];
  const key2 = values[2] as keyof typeof mReferences;
  let attrributeInfo: string | string[] = references[key][key2];
  if (attrributeInfo === VALUE_WIDTH && item.commonKind === ICON) {
    attrributeInfo = [VALUE_HEIGHT, VALUE_WIDTH];
  }
  if (typeof (attrributeInfo) !== 'string') {
    for (const itemId of attrributeInfo) {
      newReferences[itemId] = [itemId, variable, DEFAULT_THEME, undefined, values[0]];
    }
  } else {
    newReferences[attrributeInfo] = [attrributeInfo, variable, DEFAULT_THEME, undefined, values[0]];
  }
}

export function getReferencesVariantVariables (stateVariables: Record<string, string>,
  references: ActionLocationDictionaryType, item: TypeItemProperties): TypeReactAttributeReferences {
  const newReferences = {};
  for (const variable in stateVariables) {
    const info = stateVariables[variable];
    for (const information of info) {
      const values = information.split(MINUS);
      if (values[0] === item.id) {
        getReferencesVariantVariable(references, values, item, newReferences, variable);
      }
    }
  }
  return newReferences;
}

export function referencesVariantVariables (state: ReferencesGroupType, item: TypeItemProperties,
  neededInfo: ReactInfo, viewDOM: IComponentinfo): string {
  const stateVariables = state.variable;
  const newNeededInfo = JSON.parse(JSON.stringify(neededInfo));
  if (stateVariables != null) {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.references = getReferencesVariantVariables(stateVariables, neededInfo.references, item);
    newNeededInfo.name = newItem.name;
    newNeededInfo.item = item;
    newNeededInfo.pageName = VARIANT_PAGES;
  } else newNeededInfo.item = {};
  const reference = getReferenceItem(viewDOM, newNeededInfo);
  return reference[0].toString();
}
