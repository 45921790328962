import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import {
  APISReducer,
  type ConversorData,
  EditorReducer,
  FilesReducer,
  ItemsReducer,
  ThemesReducer,
  saveData,
  updateIframe,
} from '@src/reducers';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { GraphsReducer } from './graphs';

const productionMode = window.prodMode;
const reduxLogs = window.reduxLogs;

const logger = (store: any) => (next: any) => (action: any) => {
  if (!productionMode && reduxLogs) {
    console.log('dispatching ', action.type, action);
    console.log('prev state', store.getState());
  }
  const result = next(action);
  if (!productionMode && reduxLogs) {
    console.log('next state', store.getState());
  }
  return result;
};

const crashReporter = (store: any) => (next: any) => (action: any) => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    if (productionMode) Sentry.captureException(err);
    throw err;
  }
};

const updateIFrameMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  next(updateIframe(createIframeData(store)));
  return result;
};

export const createIframeData = (mStore: typeof store): ConversorData => {
  const mStoreState: StateType = mStore.getState();
  const data = {
    script: {
      name: mStoreState.themes.siteName,
      icon: undefined,
      classDeviceCoverage: {},
      userEdited: {},
      themes: {
        parametersValuesList: {},
        variantsAttributeReference: [],
        referenceGroups: mStoreState.items.classes,
        responsiveVariableInClass: [],
        references: mStoreState.items.variables.model.attributeReference,
        values: mStoreState.items.variables.model.referenceValues,
        responsiveValues: mStoreState.items.variables.model.responsiveReferenceValues,
      },
      selectedScreen: mStoreState.editor.selectedScreen,
      showWatermark: mStoreState.themes.watermark,
      items: mStoreState.items.items.byId,
      tree: mStoreState.items.tree,
      mediaValues: {
        desktop: { value: mStoreState.editor.desktopBreakpoint, unit: 'px' },
        phone: { value: mStoreState.editor.phoneBreakpoint, unit: 'px' },
      },
    },
    files: mStoreState.files.assets,
    isPreview: true,
    components: mStoreState.items.components.byId,
    loadedComponents: mStoreState.files.loadedComponents,
  };
  return data;
};

export const getAllStates = (mStore: StateType): string => {
  const mCopy = JSON.parse(JSON.stringify(mStore));
  delete mCopy.files;
  delete mCopy.editor.iframePreviewData;
  delete mCopy.items.componentIframePreviewData;
  delete mCopy.items.components;
  delete mCopy.graphs.fontSize;
  delete mCopy.graphs.hierarchical;
  delete mCopy.graphs.hierarchicalData;
  delete mCopy.graphs.dependenciesData;
  delete mCopy.graphs.componentTreeData;
  delete mCopy.apis;
  return JSON.stringify(mCopy);
};

const saveMiddleware = (mStore: any) => (next: any) => (action: any) => {
  const result = next(action);
  const actionIsSave = action.type === 'files/triggerSaveMiddleware';
  const actionIsSetup = action.type === 'graphs/setSetup';
  const actionIsViteSetup = action.type === 'graphs/setViteSetup';
  if (actionIsSave || actionIsSetup || actionIsViteSetup) {
    const tStore = mStore as typeof store;
    const mState = tStore.getState();
    const path = mState.files.dirHandlePath;
    next(saveData({ data: getAllStates(mState), dirHandlePath: path }));
  }
  return result;
};

export const store = configureStore({
  reducer: {
    items: ItemsReducer,
    editor: EditorReducer,
    files: FilesReducer,
    themes: ThemesReducer,
    apis: APISReducer,
    graphs: GraphsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger, crashReporter, updateIFrameMiddleware, saveMiddleware),
});

export type StateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<StateType> = useSelector;
