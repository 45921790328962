/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscTypeHierarchySub, VscTypeHierarchySuper } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import { OptionSelector } from '@fragments/optionSelector';
import { Tabs } from '@fragments/tabs';

import { IconButton } from '@components/iconButton';
import { SearchBar } from '@components/searchBar';
import { type ITab } from '@components/tab';

import {
  type HierarchicalData,
  fetchDependencies,
  getComponentTreeData,
  getCurrentFileToShow,
  getCurrentGraphTab,
  getDependenciesData,
  getGraphFontSize,
  getHierarchical,
  getSetup,
  setCurrentFileToShow,
  setCurrentGraphTab,
} from '@reducers/graphs';
import { type AppDispatch } from '@reducers/store';
import { getCurrentTheme } from '@reducers/themes';

import { trackEvent } from '@globalUtils/metrics';

import { NodesSummary } from './nodesSummary';
import { SelectedNode } from './selectedNode';
import {
  branchesAreEqual,
  displayData,
  getAllNestedDecendentEdges,
  getAllNestedDecendentNodes,
  getAllNestedParentEdgesNodes,
  getAllNestedParentNodes,
  getData,
  normalizeLevels,
} from './utils';

export const PageCodeGraph = (): React.JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const currentTab = useSelector(getCurrentGraphTab);
  const currentFileToShow = useSelector(getCurrentFileToShow);

  const dataDeps = useSelector(getDependenciesData);
  const dataTree = useSelector(getComponentTreeData);
  const setup = useSelector(getSetup).setup;

  const fontSize = useSelector(getGraphFontSize);
  const hierarchical = useSelector(getHierarchical);

  const theme = useSelector(getCurrentTheme);

  const networkRef = useRef<HTMLDivElement>(null);
  const falseNetworkRef = useRef<HTMLDivElement>(null);

  const [actionOption, setActionOption] = useState<string>('select');

  const [highlightedNodes, setHighlightedNodes] = useState<any[]>([]);
  const [highlightedEdges, setHighlightedEdges] = useState<any[]>([]);
  const [branches, setBranches] = useState<string[]>([]);

  const [primaryColor, setPrimaryColor] = useState<string>('blue');
  const [titleColor, setTitleColor] = useState<string>('white');
  const [disabledBGColor, setDisabledBGColor] = useState<string>('#ccc');
  const [primaryColorContrast, setPrimaryColorContrast] = useState<string>('white');
  const [primaryColorHover, setPrimaryColorHover] = useState<string>('blue');

  const [network, setNetwork] = useState<any>(null);
  const [data, setCurrentData] = useState<any>({});

  const isTree = currentTab === 'tree';

  const tabs = [
    {
      label: t('code-graph-tree-label'),
      value: 'tree',
    },
    {
      label: t('code-graph-dependencies-label'),
      value: 'dependencies',
    },
    /*
    {
      label: t('code-graph-functions-label'),
      value: 'function',
    },
    */
  ];

  const actionOptions = [
    {
      value: 'select',
      icon: 'ads_click',
      hint: t('graphs-option-nodeselection-label'),
    },
    {
      value: 'selectChildren',
      icon: (
        <VscTypeHierarchySub
          style={{ width: 'var(--font-size-icon-xlarge)', height: 'var(--font-size-icon-xlarge)' }}
        />
      ),
      hint: t('graphs-option-decendentnodes-label'),
    },
    {
      value: 'selectParents',
      icon: (
        <VscTypeHierarchySuper
          style={{ width: 'var(--font-size-icon-xlarge)', height: 'var(--font-size-icon-xlarge)' }}
        />
      ),
      hint: t('graphs-option-ascendantnodes-label'),
    },
  ];

  useEffect(() => {
    if (currentFileToShow === '') return;
    if (data == null || Object.keys(data).length === 0) return;
    const nodes = data?.nodes?._data;
    if (nodes == null) return;
    const ids: number[] = [];
    let fileName = currentFileToShow.toLocaleLowerCase();
    if (fileName.startsWith('./')) fileName = fileName.substring(2);
    nodes.forEach((node: any) => {
      const label = (node.label as string).toLocaleLowerCase();
      if (label.includes(fileName)) {
        ids.push(node.id);
      }
    });
    setHighlightedNodes(ids);
    dispatch(setCurrentFileToShow(''));
  }, [currentFileToShow, data]);

  useEffect(() => {
    const elem = document.body;
    const cs = getComputedStyle(elem);
    const mPrimaryColor = cs.getPropertyValue('--primary-color');
    const mTitleColor = cs.getPropertyValue('--title-color');
    const mDisabledBGColor = cs.getPropertyValue('--input-field-background-color');
    const mPrimaryColorContrast = cs.getPropertyValue('--primary-color-contrast');
    const mPrimaryColorHover = cs.getPropertyValue('--primary-color-hover');
    setPrimaryColor(mPrimaryColor);
    setTitleColor(mTitleColor);
    setDisabledBGColor(mDisabledBGColor);
    setPrimaryColorContrast(mPrimaryColorContrast);
    setPrimaryColorHover(mPrimaryColorHover);
  }, [theme]);

  useEffect(() => {
    renderNetwork();
  }, []);

  useEffect(() => {
    renderNetwork();
  }, [currentTab, dataTree, dataDeps, fontSize, hierarchical]);

  useEffect(() => {
    setHighlightedNodes([]);
  }, [currentTab]);

  useEffect(() => {
    drawHighlightedNodes();
  }, [highlightedNodes, currentTab]);

  useEffect(() => {
    drawHighlightedEdges();
  }, [highlightedEdges]);

  useEffect(() => {
    if (network == null) return;
    network.on('selectNode', handleSelectNode);
    network.on('deselectNode', handleUnselectNode);
    network.on('selectEdge', handleSelectEdge);
    network.on('deselectEdge', handleUnselectEdge);
    network.on('click', handleNetworkClick);
    return () => {
      if (network == null) return;
      network.off('selectNode', handleSelectNode);
      network.off('deselectNode', handleUnselectNode);
      network.off('selectEdge', handleSelectEdge);
      network.off('deselectEdge', handleUnselectEdge);
      network.off('click', handleNetworkClick);
    };
  }, [
    data,
    network,
    actionOption,
    branches,
    theme,
    primaryColor,
    titleColor,
    disabledBGColor,
    primaryColorContrast,
    primaryColorHover,
  ]);

  const renderNetwork = (): void => {
    setTimeout(() => {
      if (networkRef.current == null) return;
      if (falseNetworkRef.current == null) return;
      if (network != null) network.destroy();
      const data = currentTab === 'tree' ? dataTree : dataDeps;
      const mHierarchical = hierarchical.hierarchical ? hierarchical.hierarchicalData : null;
      let mHierarchicalCopy = null;
      let newLevels;
      if (mHierarchical != null) {
        const [tnodes, tedges] = getData(data, theme);
        mHierarchicalCopy = JSON.parse(JSON.stringify(mHierarchical)) as HierarchicalData;
        mHierarchicalCopy.direction = 'LR';
        mHierarchicalCopy.shakeTowards = 'roots';
        mHierarchicalCopy.sortMethod = 'directed';
        const net = displayData(
          falseNetworkRef.current,
          theme,
          tnodes,
          tedges,
          fontSize,
          false,
          mHierarchicalCopy
        );
        const levels = net.layoutEngine.hierarchical.levels;
        newLevels = normalizeLevels(levels);
        net.destroy();
      }

      const [nodes, edges, groups, idsMap, paths] = getData(
        data,
        theme,
        handleStyleNodeSelected,
        handleStyleEdgeSelected,
        handleStyleLabelSelected,
        isTree,
        newLevels,
        true
      );
      setCurrentData({ nodes, edges, groups, idsMap, paths });
      const mNetwork = displayData(
        networkRef.current,
        theme,
        nodes,
        edges,
        fontSize,
        true,
        mHierarchical,
        isTree,
        groups
      );
      setNetwork(mNetwork);
    }, 0);
  };

  const handleActionOptionChange = (val: string): void => {
    setActionOption(val);
    trackEvent('Changed Selection Option on ' + currentTab + '');
  };

  const drawHighlightedNodes = (): void => {
    if (data?.nodes?._data == null) return;
    if (highlightedNodes.length === 0) return;
    trackEvent('Selected Item on ' + currentTab + ' Graph');
    const allNodes = data.nodes._data;
    const updateNodes: any = [];
    allNodes.forEach((node: any) => {
      const mNode = node;
      if (mNode.color == null) mNode.color = {};
      if (!(highlightedNodes.includes(node.id) as boolean)) {
        if (typeof mNode.color === 'string') mNode.color = disabledBGColor;
        else {
          mNode.color.background = disabledBGColor;
          mNode.color.border = disabledBGColor;
        }
        mNode.font.color = titleColor;
      } else {
        if (typeof mNode.color === 'string') mNode.color = primaryColor;
        else {
          mNode.color.background = primaryColor;
          mNode.color.border = primaryColorHover;
        }
        mNode.font.color = primaryColorContrast;
      }
      updateNodes.push(mNode);
    });
    data.nodes.update(updateNodes);
  };

  const drawHighlightedEdges = (): void => {
    if (data?.edges?._data == null) return;
    const allEdges = data.edges._data;
    const updateEdges: any = [];
    highlightedEdges.forEach((edge: any) => {
      const mEdge = allEdges.get(edge);
      if (mEdge.color == null) mEdge.color = { color: primaryColorHover };
      else if (typeof mEdge.color === 'string') mEdge.color = primaryColorHover;
      else mEdge.color.color = primaryColorHover;
      updateEdges.push(mEdge);
    });
    data.edges.update(updateEdges);
  };

  const handleStyleNodeSelected = (values: any, id: any, selected: any, hovering: any): void => {};

  const handleStyleEdgeSelected = (values: any, id: any, selected: any, hovering: any): void => {};

  const handleStyleLabelSelected = (values: any, id: any, selected: any, hovering: any): void => {};

  const handleSelectNode = (a: any): void => {
    const nodesIds = a.nodes;
    let nodesSelected: any = [];
    for (const nodeId of nodesIds) {
      if (actionOption === 'selectChildren') {
        nodesSelected = getAllNestedDecendentNodes(network, data, nodeId);
        nodesSelected.push(nodeId);
        nodesSelected = [...new Set(nodesSelected)];
      } else if (actionOption === 'selectParents') {
        nodesSelected = getAllNestedParentNodes(network, data, nodeId);
        nodesSelected.push(nodeId);
        nodesSelected = [...new Set(nodesSelected)];
      } else nodesSelected.push(nodeId);
    }
    setHighlightedNodes(nodesSelected);
  };

  const handleSelectEdge = (a: any): void => {
    if (a.nodes == null || a.nodes.length === 0) return;
    const selectedNode = a.nodes[0];
    const edgesIds = a.edges;
    let edgesSelected: any = [];
    for (const edgeId of edgesIds) {
      if (actionOption === 'selectChildren') {
        const mEdgesSelected = getAllNestedDecendentEdges(network, data, selectedNode);
        mEdgesSelected.push(edgeId);
        edgesSelected = [...new Set([...edgesSelected, ...mEdgesSelected])];
      } else if (actionOption === 'selectParents') {
        const mEdgesSelected = getAllNestedParentEdgesNodes(network, data, selectedNode);
        edgesSelected = [...new Set([...edgesSelected, ...mEdgesSelected])];
      } else edgesSelected.push(edgeId);
    }
    setHighlightedEdges(edgesSelected);
  };

  const handleUnselectNode = (a: any): void => {
    const allNodes = data.nodes._data;
    const updateNodes: any = [];
    allNodes.forEach((node: any) => {
      const mNode = node;
      if (isTree) mNode.color = theme === 'dark' ? '#444' : '#ccc';
      else delete mNode.color;
      mNode.font = { color: theme === 'dark' ? '#fff' : '#000' };
      updateNodes.push(mNode);
    });
    data.nodes.update(updateNodes);
    setHighlightedNodes([]);
  };

  const handleUnselectEdge = (a: any): void => {
    const allEdges = data.edges._data;
    const updateEdges: any = [];
    allEdges.forEach((edge: any) => {
      const mEdge = edge;
      mEdge.color = theme === 'dark' ? '#444' : '#ccc';
      updateEdges.push(mEdge);
    });
    data.edges.update(updateEdges);
  };

  const handleChangeTab = (tab: ITab): void => {
    dispatch(setCurrentGraphTab(tab.value));
    trackEvent('Changed Graph Tab to ' + tab.value);
  };

  const getSelectedNodeData = (): any => {
    const mSelected = highlightedNodes[0];
    return data.nodes._data.get(mSelected);
  };

  const handleSelectedBranch = useCallback(
    (mBranches: string[]): void => {
      if (!branchesAreEqual(mBranches, branches)) {
        trackEvent('Changed branches on ' + currentTab + ' Graph');
        setBranches([...mBranches]);
        const nodes = data?.nodes?._data ?? new Map();
        const mSelectedNodes: any = [];
        nodes.forEach((node: any) => {
          const label = node.label as string;
          for (const branch of mBranches) {
            if (label.startsWith(branch)) {
              mSelectedNodes.push(node.id);
              break;
            } else if (branch === 'root' && !label.includes('/')) {
              mSelectedNodes.push(node.id);
              break;
            }
          }
        });
        setHighlightedNodes(mSelectedNodes);
        if (mSelectedNodes.length === 0) handleUnselectNode({});
      } else {
        setBranches([]);
        handleUnselectNode({});
      }
    },
    [branches, data]
  );

  const handleNetworkClick = (a: any): void => {
    if (a?.nodes?.length === 0) {
      setBranches([]);
      handleUnselectNode({});
    }
  };

  const handleSearch = (searched: string): void => {
    trackEvent('Searched on ' + currentTab + ' Graph');
    const nodes = data?.nodes?._data;
    const ids: number[] = [];
    nodes.forEach((node: any) => {
      const label = node.label as string;
      if (label.toLowerCase().includes(searched.toLowerCase())) ids.push(node.id);
    });
    setHighlightedNodes(ids);
  };

  const handleRefresh = (): void => {
    if (currentTab === 'dependencies') void dispatch(fetchDependencies(JSON.stringify(setup)));
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0,
        }}
      >
        <div
          style={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
            padding: 'var(--padding-s)',
            borderBottom: '1px solid var(--border-color)',
          }}
        >
          <div
            style={{
              height: 'fit-content',
              flex: '1 1 50%',
              minWidth: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: 'fit-content',
                backgroundColor: 'var(--input-field-background-color)',
                borderRadius: 'var(--border-radius-m)',
              }}
            >
              <OptionSelector
                size="large"
                options={actionOptions}
                defaultOption={actionOption}
                notifyChange={handleActionOptionChange}
                width="fit-content"
                height="fit-content"
                padding="var(--padding-s)"
              />
            </div>
          </div>
          <div style={{ width: '500px', display: 'flex', justifyContent: 'center', flexShrink: 0 }}>
            <div
              style={{
                width: '100%',
                backgroundColor: 'var(--input-field-background-color)',
                borderRadius: 'var(--border-radius-m)',
              }}
            >
              <SearchBar onFinish={handleSearch} />
            </div>
          </div>
          <div
            style={{
              height: 'fit-content',
              flex: '1 1 50%',
              minWidth: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          ></div>
          <div
            style={{
              display: 'none',
              height: 'fit-content',
              flex: '1 1 50%',
              minWidth: 0,
              justifyContent: 'center',
            }}
          >
            <IconButton
              hasText={false}
              hover="outline"
              paddingHorizontal="0px"
              width="var(--button-height)"
              onClick={handleRefresh}
              border=""
              icon="sync"
              variant="secondary"
            />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
            padding: 'var(--padding-xs)',
            backgroundColor: 'var(--background-color-1)',
          }}
        >
          <div style={{ margin: 'var(--padding-xs)', height: 'fit-content', width: '400px', flexShrink: 0 }}>
            <Tabs defaultSelected={currentTab} tabs={tabs} notifySelectedTab={handleChangeTab} />
          </div>
        </div>
      </div>
      <div
        ref={networkRef}
        style={{ width: '100%', flex: '1 1 50%', minHeight: 0, backgroundColor: 'var(--background-color-1)' }}
      ></div>
      <div ref={falseNetworkRef} style={{ display: 'none' }}></div>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          right: '0px',
          top: '44px',
          width: 'var(--side-pane-width)',
          height: 'calc(100% - 44px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className="hide-native-scrollbar"
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            border: '1px solid var(--border-color)',
            backdropFilter: 'blur(5px)',
            overflowY: 'scroll',
          }}
        >
          {highlightedNodes.length === 1 && (
            <SelectedNode graphType={currentTab} network={network} data={data} node={getSelectedNodeData()} />
          )}
          {highlightedNodes.length !== 1 && (
            <NodesSummary
              graph={currentTab}
              selectedBranches={branches ?? []}
              groups={data.groups ?? {}}
              notifySelectedBranch={handleSelectedBranch}
            />
          )}
        </div>
      </div>
    </div>
  );
};
