import {
  ALIGN_VAR,
  ANTD_FORM,
  CLOSING_PARENTESIS,
  HORIZONTAL_AXIS,
  JUSTIFY_VAR,
  MINUS,
  ORIENTATION,
  ORIENTATION_CLOSE,
  ORIENTATION_MID,
  ORIENTATION_VAR,
  POINT,
  SEMICOLON_LINE_JUMP,
  VAR_DEFINITION,
  VERTICAL_AXIS
} from '../../../../constants/constants';
import {
  type TypeRefItemInfo,
  type ReactInfo,
  type TypeReactAttribute,
  type TypeOrientationVariables
} from '@globalTypes/conversor';

export function verifyOrientationRouteExistance (orientationVariables: TypeOrientationVariables,
  referenceItem: TypeReactAttribute, varName: string, pageName: string): void {
  if (orientationVariables[referenceItem[2]] == null) orientationVariables[referenceItem[2]] = {};
  if (orientationVariables[referenceItem[2]][varName] == null) {
    orientationVariables[referenceItem[2]][varName] = { vertical: {}, horizontal: {} };
  }
  if (orientationVariables[referenceItem[2]][varName].horizontal[pageName] == null) {
    orientationVariables[referenceItem[2]][varName].horizontal[pageName] = '';
  }
  if (orientationVariables[referenceItem[2]][varName].vertical[pageName] == null) {
    orientationVariables[referenceItem[2]][varName].vertical[pageName] = '';
  }
}

export function handleOrientationReference (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  variableName: string, referenceItem: TypeReactAttribute, creatingCompRes: boolean,
  groupVerification: boolean, noAddVerification: boolean): void {
  if (neededInfo.item == null) return;
  verifyOrientationRouteExistance(neededInfo.orientationVariables, referenceItem, variableName,
    neededInfo.pageName);
  const itemTag = 'tag' in neededInfo.item && neededInfo.item.tag !== ANTD_FORM ? neededInfo.item.tag : '';
  const itemName = neededInfo.name + (neededInfo.item.nameChanged != null ? '' : MINUS + neededInfo.item.id);
  const alignId = neededInfo.item.nameChanged != null ? neededInfo.item.name : neededInfo.item.id;
  if (creatingCompRes) {
    neededInfo.orientationVariables[referenceItem[2]][variableName].horizontal[neededInfo.pageName] +=
      itemTag + POINT + itemName + ORIENTATION_VAR + neededInfo.item.commonKind + ORIENTATION_MID +
      ALIGN_VAR + VERTICAL_AXIS + alignId + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP + JUSTIFY_VAR +
      HORIZONTAL_AXIS + alignId + ORIENTATION_CLOSE;
  }
  if (creatingCompRes) {
    neededInfo.orientationVariables[referenceItem[2]][variableName].vertical[neededInfo.pageName] +=
      itemTag + POINT + itemName + ORIENTATION_VAR + neededInfo.item.commonKind + ORIENTATION_MID +
      ALIGN_VAR + HORIZONTAL_AXIS + alignId + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP + JUSTIFY_VAR +
      VERTICAL_AXIS + alignId + ORIENTATION_CLOSE;
    neededInfo.objectClassnames[neededInfo.item.id].push([itemName + MINUS + ORIENTATION, true]);
  }
  if (groupVerification) {
    refItemInfo.referenceString += 'flex-direction' + VAR_DEFINITION + variableName +
      CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  if (noAddVerification) refItemInfo.referenceList.push(variableName);
}
