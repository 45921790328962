import { type ReactInfo } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { BRACKET_SCROLLABLE, MHORIZONTAL } from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';

export function scrollable (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  const scrollClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.scrollable != null;
  if ('scrollable' in item && (item.scrollable !== 'hidden' || scrollClass)) {
    if ('orientation' in item && item.orientation === MHORIZONTAL) {
      return dictionary.scrollableHorizontal.replace(BRACKET_SCROLLABLE, item.scrollable);
    } else {
      return dictionary.scrollableVertical.replace(BRACKET_SCROLLABLE, item.scrollable);
    }
  } else return '';
}
