import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BRACKET,
  CLOSE_BRACKET,
  COLOR_VARIABLE,
  COMMA,
  DEFAULT_FONT,
  DEFAULT_IMAGE,
  EQUAL,
  EQUAL_BRACKEST_COMMA,
  EQUAL_BRACKET,
  FONT_VARIABLE,
  IMAGE_VARIABLE,
  IS_NESTED,
  LINE_JUMP,
  MINUS,
  NUMBER_SIGN,
  POINT,
  SIZE_VARIABLE,
  SLASH,
  TEXT_VARIABLE,
  TRUE,
  UNDERSCORE,
  VISIBILITY_VARIABLE
} from '../../../constants/constants';
import { type IComponentinfo, type TypeItems, type ReactInfo } from '@globalTypes/conversor';
import { adaptItemName } from '../../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { adaptPartsProps, setUpDefaultValuesNested } from './reactComponentPropsUtils';

export function setDefaultValuesComponentProps (defaultValues: Record<string, string | boolean>,
  parts: string[], mref: string): void {
  const mKind = parts[1];
  let defaultValue: string | boolean = '';
  if (mKind === SIZE_VARIABLE) defaultValue = (parts[0] + '').replaceAll(COMMA, '');
  else if (mKind === COLOR_VARIABLE) defaultValue = NUMBER_SIGN + parts[0];
  else if (mKind === TEXT_VARIABLE) defaultValue = parts[0];
  else if (mKind === VISIBILITY_VARIABLE) defaultValue = parts[0].startsWith(TRUE);
  else if (mKind === IMAGE_VARIABLE) defaultValue = DEFAULT_IMAGE;
  else if (mKind === FONT_VARIABLE) {
    defaultValue = parts[0][1];
    defaultValue = defaultValue.split(POINT)[0];
    if (!defaultValue.includes(SLASH)) defaultValue = DEFAULT_FONT;
  } else defaultValue = parts[0];
  defaultValues[mref] = defaultValue;
}

export function loadDefaultValuesComponentProps (defaultValues: Record<string, string | boolean>,
  defaultValuesNested: Record<string, Record<string, boolean>>, viewDOM: IComponentinfo,
  item: TypeItemProperties): void {
  const componentName = 'component' in item && item.component !== '' ? item.component : '';
  const component = viewDOM.getComponentByName(componentName, viewDOM.components);
  if (component?.references == null) {
    window.logger.warn('component ' + componentName + ' not found');
    return;
  }
  const cRef = component.references.variables;
  setUpDefaultValuesNested(defaultValuesNested, component);
  for (const mref in cRef) {
    const parts = adaptPartsProps(cRef, mref);
    setDefaultValuesComponentProps(defaultValues, parts, mref);
  }
}

export function addNestedComponentProps (content: string, nestedContent: Record<string, string>,
  defaultValuesNested: Record<string, string | boolean>, children: string): string {
  if (Object.keys(defaultValuesNested).length !== 0) {
    for (const nest in defaultValuesNested) {
      const nestName = adaptItemName(nest, false);
      const nestNameNoHyphen = nestName.replaceAll(MINUS, '');
      if (nestedContent[nestName] == null && children.includes(nestName + POINT) &&
          Object.keys(defaultValuesNested[nest]).length !== 0 && nestedContent[nestNameNoHyphen] == null) {
        nestedContent[nestNameNoHyphen] = nestNameNoHyphen + ' ' + EQUAL + ' ' + BRACKET;
      }
    }
  }
  for (const nested in nestedContent) {
    if (children.includes(nested + POINT) && !content.includes(nestedContent[nested])) {
      content += nestedContent[nested] + CLOSE_BRACKET + COMMA + LINE_JUMP;
    }
  }
  return content;
}

export function renderingCreateAllSubnodes (allChildren: string[], id: string,
  items: TypeItems): void {
  for (const itemId in items) {
    const pItem = items[itemId];
    if ('parent' in pItem && pItem.parent === id) {
      renderingCreateAllSubnodes(allChildren, itemId, items);
      allChildren.push(itemId);
    }
  }
}

export function addComponentChildrenProps (content: string, neededInfo: ReactInfo, item: TypeItemProperties,
  nestedContent: Record<string, string>,): string {
  let allChildren: string[] = [];
  if (neededInfo.renderingComponent) {
    renderingCreateAllSubnodes(allChildren, item.id, neededInfo.items);
  } else allChildren = neededInfo.unmodifiedTree.getAllNodeSubnodes(item.id);
  for (const child of allChildren) {
    const pChild = neededInfo.items[child];
    const name = adaptItemName(pChild.name, true);
    if ('customcode' in pChild && (pChild.customcode != null ||
        ('component' in pChild && pChild.component != null && typeof (pChild.component) !== 'boolean' &&
          nestedContent[name] == null &&
          !content.includes(name + EQUAL_BRACKEST_COMMA)))) {
      content += name + EQUAL_BRACKEST_COMMA + ' ' + LINE_JUMP;
    }
  }
  return content;
}

export function getComponentProp (name: string, defaultValues: Record<string, string>,
  nestedContent: Record<string, string>, content: string): string {
  if (defaultValues[name] == null) {
    if (nestedContent[name.split(UNDERSCORE)[0]] == null) {
      content += '    ' + name.split(UNDERSCORE)[0] + EQUAL_BRACKEST_COMMA + LINE_JUMP;
    }
  } else if (typeof (defaultValues[name]) === 'boolean' ||
      !defaultValues[name].includes(IS_NESTED)) {
    if (typeof (defaultValues[name]) === 'boolean') {
      content += '    ' + name + ' ' + EQUAL + ' ' + defaultValues[name] + COMMA + LINE_JUMP;
    } else content += '    ' + name + ' ' + EQUAL + ' ' + '"' + defaultValues[name] + '"' + COMMA + LINE_JUMP;
  } else {
    const nest = defaultValues[name].split(MINUS)[1];
    const items = nest.split(COMMA);
    for (const item of items) {
      const mName = adaptItemName(item, true);
      if (nestedContent[mName] == null) nestedContent[mName] = mName + EQUAL_BRACKET;
    }
  }
  return content;
}

export function getComponentProps (item: TypeItemProperties, viewDOM: IComponentinfo, children: string,
  neededInfo: ReactInfo): string {
  const defaultValues = {};
  const defaultValuesNested = {};
  loadDefaultValuesComponentProps(defaultValues, defaultValuesNested, viewDOM, item);
  const references = neededInfo.pagesReferences['component' in item && item.component !== ''
    ? item.component
    : ''];
  if (references == null) return '';
  let content = LINE_JUMP + BRACKET;
  const nestedContent = {};
  for (const reference of references) {
    const name = reference;
    content = getComponentProp(name, defaultValues, nestedContent, content);
  }
  content = addNestedComponentProps(content, nestedContent, defaultValuesNested, children);
  content = addComponentChildrenProps(content, neededInfo, item, nestedContent);
  return content + CLOSE_BRACKET;
}
