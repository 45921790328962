import { DEFAULT_COLOR } from '@compiler/constants/colorConstants';
import { DEFAULT_TEXT, NORMAL, P, TEXT } from '@compiler/constants/textConstants';

import {
  COLOR,
  CONTENT,
  DEFAULT,
  FONT_FAMILY,
  LEADING,
  LEFT,
  SPACING,
  TEXT_ALIGNMENT,
  UNIT_PX,
  WEIGHT,
  WRAP,
} from '@globalTypes/constants';

import {
  type AnimationType,
  type AnimationWhenVisibleType,
  type AspectRatio,
  BackgroundColorType,
  type ClassNamesType, type ClassesType,
  type ClassnameType,
  CommonKindType,
  type CurrentVariantType,
  CursorPointerType,
  type CustomStylesType,
  DimensionType,
  DirectionsType,
  FontSizeType,
  type GroupVariablesType,
  HeightType,
  type HrefType,
  IdType,
  IndexType,
  MarginsType,
  type NameChangedType,
  NameType,
  ParentType,
  type ReferencesType,
  SizeValueType,
  type StateType,
  TagType,
  type VariantType,
  VisibleType,
  WidthType,
} from './common';

export namespace TextAlignmentType {
  export type TextAlignmentProperty = 'textAlignment';
  export const TextAlignmentProperty: TextAlignmentProperty = TEXT_ALIGNMENT;

  export type TextAlignmentType = string;
}

export namespace FontStyleType {
  export type FontStyleProperty = 'style';
  export const FontStyleProperty: FontStyleProperty = 'style';

  export type FontStyleType = string;
}

export namespace WeightType {
  export type WeightProperty = 'weight';
  export const WeightProperty: WeightProperty = WEIGHT;

  export type WeightType = number;
}

export namespace FontFamilyType {
  export type FontFamilyProperty = 'fontFamily';
  export const FontFamilyProperty: FontFamilyProperty = FONT_FAMILY;

  export type FontFamilyType = string;
}

export namespace LeadingType {
  export type LeadingProperty = 'leading';
  export const LeadingProperty: LeadingProperty = LEADING;

  export type LeadingType = number;
}

export namespace SpacingType {
  export type SpacingProperty = 'spacing';
  export const SpacingProperty: SpacingProperty = SPACING;

  export type SpacingType = number;
}

export namespace LanguagesType {
  export type LanguagesProperty = 'default';
  export const LanguagesProperty: LanguagesProperty = DEFAULT;

  export type LanguagesType = string;
}

export namespace ContentType {
  export type ContentProperty = 'content';
  export const ContentProperty: ContentProperty = CONTENT;

  export interface ContentType {
    [LanguagesType.LanguagesProperty]: LanguagesType.LanguagesType;
  }
}

export interface TextProperties {
  paddingsLocked?: boolean;
  marginsLocked?: boolean;
  [AspectRatio.AspectRatioProperty]?: AspectRatio.AspectRatioType;
  [WidthType.WidthProperty]: WidthType.WidthType;
  [HeightType.HeightProperty]: HeightType.HeightType;
  [MarginsType.MarginsProperty]: MarginsType.MarginsType;
  [CommonKindType.CommonKindProperty]: 'text';
  [IdType.IdProperty]: IdType.IdType;
  [TagType.TagProperty]: TagType.TagType;
  [BackgroundColorType.BackgroundColorProperty]: BackgroundColorType.BackgroundColorType;
  [FontSizeType.FontSizeProperty]: FontSizeType.FontSizeType;
  [TextAlignmentType.TextAlignmentProperty]: TextAlignmentType.TextAlignmentType;
  [FontStyleType.FontStyleProperty]: FontStyleType.FontStyleType;
  [WeightType.WeightProperty]: WeightType.WeightType;
  [NameType.NameProperty]: NameType.NameType;
  [FontFamilyType.FontFamilyProperty]: FontFamilyType.FontFamilyType;
  [LeadingType.LeadingProperty]: LeadingType.LeadingType;
  [SpacingType.SpacingProperty]: SpacingType.SpacingType;
  [CursorPointerType.CursorPointerProperty]: CursorPointerType.CursorPointerType;
  [VisibleType.VisibleProperty]: VisibleType.VisibleType;
  [ParentType.ParentProperty]: ParentType.ParentType;
  [IndexType.IndexProperty]: IndexType.IndexType;
  [HrefType.HrefProperty]?: HrefType.HrefType;
  [AnimationType.AnimationProperty]?: AnimationType.AnimationType;
  [AnimationWhenVisibleType.AnimationWhenVisibleProperty]?: AnimationWhenVisibleType.AnimationWhenVisibleType;
  [ClassesType.ClassesProperty]?: ClassesType.ClassesType;
  [ClassnameType.ClassnameProperty]?: ClassnameType.ClassnameType;
  [CustomStylesType.CustomStylesProperty]?: CustomStylesType.CustomStylesType;
  [ContentType.ContentProperty]: ContentType.ContentType;
  [ClassNamesType.ClassNamesProperty]?: ClassNamesType.ClassNamesType;
  [NameChangedType.NameChangedProperty]?: NameChangedType.NameChangedType;
  [ReferencesType.ReferencesProperty]?: ReferencesType.ReferencesType;
  [GroupVariablesType.GroupVariablesProperty]?: GroupVariablesType.GroupVariablesType;
  [VariantType.VariantProperty]?: VariantType.VariantType;
  [CurrentVariantType.CurrentVariantProperty]?: CurrentVariantType.CurrentVariantType;
  [StateType.StateProperty]?: StateType.StateType;
}

export const DefaultText: TextProperties = {
  [WidthType.WidthProperty]: {
    [DimensionType.DimensionKindProperty]: WRAP,
  },
  [HeightType.HeightProperty]: {
    [DimensionType.DimensionKindProperty]: WRAP,
  },
  [MarginsType.MarginsProperty]: {
    [DirectionsType.TopProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.BottomProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.LeftProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.RightProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [CommonKindType.CommonKindProperty]: TEXT,
  [IdType.IdProperty]: '0',
  [TagType.TagProperty]: P,
  [BackgroundColorType.BackgroundColorProperty]: {
    [BackgroundColorType.BackgroundColorKindProperty]: COLOR,
    [BackgroundColorType.BackgroundColorDataProperty]: DEFAULT_COLOR,
  },
  [FontSizeType.FontSizeProperty]: {
    [SizeValueType.SizeValueProperty]: 18,
    [SizeValueType.SizeUnitProperty]: UNIT_PX,
  },
  [TextAlignmentType.TextAlignmentProperty]: LEFT,
  [FontStyleType.FontStyleProperty]: NORMAL,
  [WeightType.WeightProperty]: 400,
  [NameType.NameProperty]: TEXT,
  [FontFamilyType.FontFamilyProperty]: DEFAULT,
  [LeadingType.LeadingProperty]: 0,
  [SpacingType.SpacingProperty]: 0,
  [CursorPointerType.CursorPointerProperty]: 'none',
  [VisibleType.VisibleProperty]: true,
  [ParentType.ParentProperty]: '0',
  [IndexType.IndexProperty]: 0,
  [ContentType.ContentProperty]: { [LanguagesType.LanguagesProperty]: DEFAULT_TEXT },
};
