export const MAX_WIDTH = 'max-width: ';
export const MIN_WIDTH = 'min-width: ';
export const MEDIA_DESKTOP = MIN_WIDTH + '1020px';
export const MEDIA_PHONE = MAX_WIDTH + '480px';
export const MEDIA_TABLET = MAX_WIDTH + '1019px) and (' + MIN_WIDTH + '481px';
export const MEDIA_DEF = '\n@media (';
export const MEDIA_DEF_CLOSE = ') {';
export const ROOT = ':root {\n';
export const ONLY_ROOT = ':root';
export const BRACKET_SRC = '{src}';
export const SOURCE_OPEN = '<source srcSet=';
export const SOURCE_CLOSE = ')" />';
export const SOURCE_MEDIA = ' media="(';
export const CONTENT_RESPONSIVE = '{contentResponsive}';
export const PICTURE_OPEN = '<picture>';
export const PICTURE_CLOSE = '</picture>';
export const IMPORT_IMAGE = '\nimport image_';
export const IMAGE_BRACKET = '{ image_';
