export const DIRECTION = 'direction';
export const IS_MAIN = 'isMain';
export const URL = 'url';
export const TITLE = 'title';
export const DESCRIPTION = 'description';
export const KEYWORDS = 'keywords';
export const AUTHOR = 'author';
export const CANONICAL = 'canonical';
export const ROBOTS = 'robots';
export const MAIN = 'main';
export const SCREEN = 'screen';
export const SCREEN_BACKGROUND = '{screenBackgroundColor}';
export const SCREEN_SCROLLABLE = '{screenScollable}';
