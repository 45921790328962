/* eslint-disable no-dupe-keys */

/* eslint-disable max-len */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { locales } from './locales';

const resources = locales;

const languageDetector = new LanguageDetector(null, { order: ['navigator'] });

void i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
