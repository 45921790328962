/* eslint-disable max-lines-per-function */

/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Checkbox } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from '@components/divider';
import { HeaderText } from '@components/headerText';
import { IconButton } from '@components/iconButton';
import { InputNumber } from '@components/inputNumber';
import { MultiSelect } from '@components/multiselect';
import { RadioIconButton } from '@components/radioIconButton';

import {
  type HierarchicalData,
  getGraphFontSize,
  getHierarchical,
  setGraphFontSize,
  setHierarchical,
  setInitialGraphsData,
} from '@reducers/graphs';

import { trackEvent } from '@globalUtils/metrics';

import styles from './styles.module.css';

export type GroupsType = Record<string, any>;
export interface NodesSummaryProps {
  groups: GroupsType;
  selectedBranches: string[];
  notifySelectedBranch: (branches: string[]) => any;
  graph: string;
}

const areArraysEqual = (a: any[], b: any[]): boolean => {
  if (a.length !== b.length) return false;
  const copyOfB = JSON.parse(JSON.stringify(b));
  let areEqual = true;
  for (const obj of a) {
    const mParsed = JSON.stringify(obj);
    let foundMatch = false;
    for (const objComparisonKey in copyOfB) {
      const objComparison = copyOfB[objComparisonKey];
      const parsedObjComparison = JSON.stringify(objComparison);
      if (parsedObjComparison === mParsed) {
        foundMatch = true;
        delete copyOfB[objComparisonKey];
        break;
      }
    }
    if (!foundMatch) {
      areEqual = false;
      break;
    }
  }
  return areEqual;
};

const areGroupsEqual = (a: GroupsType, b: GroupsType): boolean => {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  return areArraysEqual(aKeys, bKeys);
};

const propsAreEqual = (a: NodesSummaryProps, b: NodesSummaryProps): boolean => {
  if (a.notifySelectedBranch !== b.notifySelectedBranch) return false;
  if (!areGroupsEqual(a.groups, b.groups)) return false;
  if (!areArraysEqual(a.selectedBranches, b.selectedBranches)) return false;
  if (a.graph !== b.graph) return false;
  return true;
};

export const NodesSummary = React.memo(
  ({ groups, selectedBranches, notifySelectedBranch, graph }: NodesSummaryProps): React.JSX.Element => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const fontSize = useSelector(getGraphFontSize);
    const hierarchical = useSelector(getHierarchical);
    const hierarchicalData = hierarchical.hierarchicalData;

    const isHierarchical = hierarchical.hierarchical;
    const levelSeparation = hierarchicalData.levelSeparation;
    const nodeSpacing = hierarchicalData.nodeSpacing;
    const treeSpacing = hierarchicalData.treeSpacing;
    const direction = hierarchicalData.direction;
    const sortMethod = hierarchicalData.sortMethod;
    const shakeTowards = hierarchicalData.shakeTowards;
    const parentCentralization = hierarchicalData.parentCentralization;
    const edgeMinimization = hierarchicalData.edgeMinimization;
    const blockShifting = hierarchicalData.blockShifting;

    const options = React.useMemo(() => {
      const mOptions = Object.keys(groups ?? {}).map((key, i) => {
        const mGroupColors = groups[key].color;
        return {
          label: key,
          value: key,
          icon: (
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50px',
                backgroundColor: mGroupColors.background,
                border: '1px solid ' + mGroupColors.border,
                flexShrink: 0,
              }}
            ></div>
          ),
        };
      });
      mOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
      return mOptions;
    }, [groups]);

    const directionOptions = [
      {
        label: t('graphs-direction-ud-label'),
        value: 'UD',
        hint: t('graphs-direction-ud-hint'),
      },
      {
        label: t('graphs-direction-du-label'),
        value: 'DU',
        hint: t('graphs-direction-du-hint'),
      },
      {
        label: t('graphs-direction-lr-label'),
        value: 'LR',
        hint: t('graphs-direction-lr-hint'),
      },
      {
        label: t('graphs-direction-rl-label'),
        value: 'RL',
        hint: t('graphs-direction-rl-hint'),
      },
    ];

    const sortMethodOptions = [
      {
        label: t('graphs-sortMethod-hubsize-label'),
        value: 'hubsize',
      },
      {
        label: t('graphs-sortMethod-directed-label'),
        value: 'directed',
      },
    ];

    const shakeTowardsOptions = [
      {
        label: t('graphs-shakeTowards-leaves-label'),
        value: 'leaves',
      },
      {
        label: t('graphs-shakeTowards-roots-label'),
        value: 'roots',
      },
    ];

    const handleSelectedBranches = (options: string | string[]): void => {
      if (typeof options === 'string') return;
      notifySelectedBranch(options);
    };

    const handleRestore = (): void => {
      dispatch(setInitialGraphsData({}));
      trackEvent('Restored settings on ' + graph + ' Graph');
    };

    const handleFontSize = (val: string): void => {
      const num = parseInt(val);
      dispatch(setGraphFontSize(num));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleChangeHierarchical = (): void => {
      dispatch(setHierarchical({ hierarchical: !isHierarchical, hierarchicalData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleLevelSeparation = (val: string): void => {
      const num = parseInt(val);
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.levelSeparation = num;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleNodeSpacing = (val: string): void => {
      const num = parseInt(val);
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.nodeSpacing = num;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleTreeSpacing = (val: string): void => {
      const num = parseInt(val);
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.treeSpacing = num;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleDirection = (val: string): void => {
      const data = val as typeof hierarchicalData.direction;
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.direction = data;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleSortMethod = (val: string): void => {
      const data = val as typeof hierarchicalData.sortMethod;
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.sortMethod = data;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleShakeTowards = (val: string): void => {
      const data = val as typeof hierarchicalData.shakeTowards;
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.shakeTowards = data;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleParentCentralization = (): void => {
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.parentCentralization = !copyData.parentCentralization;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleEdgeMinimization = (): void => {
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.edgeMinimization = !copyData.edgeMinimization;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    const handleBlockShifting = (): void => {
      const copyData = JSON.parse(JSON.stringify(hierarchicalData)) as HierarchicalData;
      copyData.blockShifting = !copyData.blockShifting;
      dispatch(setHierarchical({ hierarchical: isHierarchical, hierarchicalData: copyData }));
      trackEvent('Changed settings on ' + graph + ' Graph');
    };

    return (
      <div
        className="hide-native-scrollbar"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          padding: 'var(--padding-m)',
        }}
      >
        <div
          style={{
            width: '100%',
            flexShrink: 0,
            marginBottom: 'var(--padding-m)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MultiSelect
            key={selectedBranches.length}
            multiple
            defaultOption={selectedBranches}
            notifySelectedValue={handleSelectedBranches}
            width="100%"
            options={options}
            placeholder={t('graphs-placeholder-label')}
            emptyPlaceholder={t('graphs-notfound-label')}
            searchPlaceholder={t('graphs-search-label')}
          />
        </div>
        <Divider orientation="horizontal" />
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className={styles.selector} style={{ marginBottom: 'var(--padding-m)' }}>
            <HeaderText bold={false}>{t('graphs-fontsize-label')}</HeaderText>
            <InputNumber defaultValue={fontSize.toString()} notifyValueUpdate={handleFontSize} />
          </div>
          <Divider orientation="horizontal" />
          <div className={styles.selector} style={{ justifyContent: 'flex-start' }}>
            <Checkbox checked={isHierarchical} onChange={handleChangeHierarchical} />
            <div style={{ marginLeft: 'var(--padding-s)' }}>
              <HeaderText bold={false}>{t('graphs-hierarchical-label')}</HeaderText>
            </div>
          </div>
          <div className={styles.selector}>
            <HeaderText bold={false}>{t('graphs-levelSeparation-label')}</HeaderText>
            <InputNumber
              defaultValue={levelSeparation.toString()}
              notifyValueUpdate={handleLevelSeparation}
            />
          </div>
          <div className={styles.selector}>
            <HeaderText bold={false}>{t('graphs-nodeSpacing-label')}</HeaderText>
            <InputNumber defaultValue={nodeSpacing.toString()} notifyValueUpdate={handleNodeSpacing} />
          </div>
          <div className={styles.selector}>
            <HeaderText bold={false}>{t('graphs-treeSpacing-label')}</HeaderText>
            <InputNumber defaultValue={treeSpacing.toString()} notifyValueUpdate={handleTreeSpacing} />
          </div>
          <div className={styles.selector}>
            <div style={{ flexShrink: 0, marginRight: 'var(--padding-m)' }}>
              <HeaderText bold={false}>{t('graphs-direction-label')}</HeaderText>
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <RadioIconButton
                options={directionOptions}
                defaultOption={direction}
                notifyOptionChanged={handleDirection}
              />
            </div>
          </div>
          <div className={styles.selector}>
            <div style={{ flexShrink: 0, marginRight: 'var(--padding-m)' }}>
              <HeaderText bold={false}>{t('graphs-sortMethod-label')}</HeaderText>
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <RadioIconButton
                options={sortMethodOptions}
                defaultOption={sortMethod}
                notifyOptionChanged={handleSortMethod}
              />
            </div>
          </div>
          <div className={styles.selector}>
            <div style={{ flexShrink: 0, marginRight: 'var(--padding-m)' }}>
              <HeaderText bold={false}>{t('graphs-shakeTowards-label')}</HeaderText>
            </div>
            <div style={{ flex: '1 1 50%', minWidth: 0 }}>
              <RadioIconButton
                options={shakeTowardsOptions}
                defaultOption={shakeTowards}
                notifyOptionChanged={handleShakeTowards}
              />
            </div>
          </div>
          <div className={styles.selector} style={{ justifyContent: 'flex-start' }}>
            <Checkbox checked={parentCentralization} onChange={handleParentCentralization} />
            <div style={{ marginLeft: 'var(--padding-s)' }}>
              <HeaderText bold={false}>{t('graphs-parentCentralization-label')}</HeaderText>
            </div>
          </div>
          <div className={styles.selector} style={{ justifyContent: 'flex-start' }}>
            <Checkbox checked={edgeMinimization} onChange={handleEdgeMinimization} />
            <div style={{ marginLeft: 'var(--padding-s)' }}>
              <HeaderText bold={false}>{t('graphs-edgeMinimization-label')}</HeaderText>
            </div>
          </div>
          <div className={styles.selector} style={{ justifyContent: 'flex-start' }}>
            <Checkbox checked={blockShifting} onChange={handleBlockShifting} />
            <div style={{ marginLeft: 'var(--padding-s)' }}>
              <HeaderText bold={false}>{t('graphs-blockShifting-label')}</HeaderText>
            </div>
          </div>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 'var(--padding-m)' }}
        >
          <IconButton
            icon="history"
            onClick={handleRestore}
            backgroundColor="var(--api-del-bg-color)"
            border="1px solid var(--alert-color-1)"
          >
            {t('graphs-restore-label')}
          </IconButton>
        </div>
      </div>
    );
  },
  propsAreEqual
);
NodesSummary.displayName = 'NodesSummary';
