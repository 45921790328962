import { BACKGROUND_COLOR, DATA, KIND } from '@globalTypes/constants';

export namespace BackgroundColorType {
  export type BackgroundColorProperty = 'backgroundColor';
  export const BackgroundColorProperty: BackgroundColorProperty = BACKGROUND_COLOR;
  export type BackgroundColorKindProperty = 'kind';
  export const BackgroundColorKindProperty: BackgroundColorKindProperty = KIND;
  export type BackgroundColorDataProperty = 'data';
  export const BackgroundColorDataProperty: BackgroundColorDataProperty = DATA;

  export interface BackgroundColorType {
    [BackgroundColorKindProperty]: string;
    [BackgroundColorDataProperty]: string;
  }
}
