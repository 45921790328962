/* eslint-disable max-lines-per-function */

/* eslint-disable max-lines */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  type IComponentinfo,
  type TypeComponentData,
  type TypeCustomCode,
} from '../types/conversor/componentInfo';

import { type ITree, type ITreeNode, Tree, TreeNode } from '@globalUtils/tree';

import {
  ACTION_LOCATION_DICTIONARY,
  type ActionLocationDictionaryType,
  type ConversionLanguage,
  type IAguaRender,
  type IConversorThemesType,
  type IFiles,
  type IParametersValuesList,
  type IReactMethods,
  type IScriptThemesType,
  type IScriptType,
  type TypeItems,
  type TypeResponsiveValues,
  type TypeVariableValuesComponents,
} from '@globalTypes/conversor';
import { DefaultLayer, type TypeItemProperties } from '@globalTypes/itemProperties';

import { ReactMethods } from './ConversionMethods/reactMethods';

export interface TypeMark {
  index: number;
  example: string;
}

export interface TypeDir {
  name: string;
  sampleId: string;
}

interface IConversor {
  language: ConversionLanguage;
  viewDOM: IComponentinfo;
}

export class Conversor implements IConversor {
  language: ConversionLanguage;
  viewDOM: IComponentinfo = {
    components: {},
    getComponentByName(name: string): TypeComponentData | null {
      return null;
    },
  };

  methods: IReactMethods | undefined;
  references: ActionLocationDictionaryType;
  items: TypeItems = {};
  tree: ITree = new Tree(new TreeNode('', ''));

  themes: IScriptThemesType | undefined;

  constructor(language: ConversionLanguage) {
    this.language = language;
    this.methods = this.initMethodsForLanguage(language);
    this.references = ACTION_LOCATION_DICTIONARY;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  initMethodsForLanguage(language: ConversionLanguage) {
    let methods;
    if (language === 'react') methods = new ReactMethods();
    return methods;
  }

  cloneObject<Type>(obj: Type): Type {
    return JSON.parse(JSON.stringify(obj));
  }

  initMethodsObject(script: IScriptType, themes: IScriptThemesType, items: TypeItems): void {
    if (this.methods == null) return;
    this.methods.styles = {};
    this.methods.unmodifiedItem = script.items;
    this.methods.viewDOM = this.viewDOM;
    this.methods.watermark = script.showWatermark;
    this.methods.unmodifiedTree = new Tree(Tree.objectToTreeNode([script.tree.root])[0]);
    this.methods.scripts = script.scripts;
    this.methods.classDeviceCoverage = script.classDeviceCoverage;
    this.methods.infoThemes = themes.values;
    const defaultThemes = { Default: {}, Responsiveness: {} };
    const responsiveThemes = themes.responsiveValues ?? {
      desktop: defaultThemes,
      tablet: defaultThemes,
      phone: defaultThemes,
    };
    this.methods.responsiveValues = this.cleanNonDifferentResponsive(responsiveThemes);
    this.methods.attributeReference = themes.references;
    this.methods.variantsAttributeReference = this.cloneObject(themes.variantsAttributeReference);
    this.methods.responsiveVariableInClass = this.cloneObject(themes.responsiveVariableInClass);
    this.methods.referenceGroupsList = this.cloneObject(themes.referenceGroups);
    this.methods.items = items;
    this.methods.mediaValues = script.mediaValues;
  }

  cleanNonDifferentResponsive(responsiveValues: TypeResponsiveValues) {
    const defaultThemes = { Default: {}, Responsiveness: {} };
    const newResponsiveValues: TypeResponsiveValues = {
      desktop: defaultThemes,
      tablet: defaultThemes,
      phone: defaultThemes,
    };
    for (const theme in responsiveValues.desktop) {
      const themeVariables = responsiveValues.desktop[theme];
      for (const variable in themeVariables) {
        const valueDesktop = themeVariables[variable];
        const valueTablet = responsiveValues.tablet[theme][variable];
        const valuePhone = responsiveValues.phone[theme][variable];
        const isNonResponsive = valueDesktop === valueTablet && valueDesktop === valuePhone;
        if (!isNonResponsive) {
          if (newResponsiveValues.desktop[theme] == null) {
            newResponsiveValues.desktop[theme] = {};
            newResponsiveValues.tablet[theme] = {};
            newResponsiveValues.phone[theme] = {};
          }
          newResponsiveValues.desktop[theme][variable] = valueDesktop;
          newResponsiveValues.tablet[theme][variable] = valuePhone;
          newResponsiveValues.phone[theme][variable] = valueTablet;
        }
      }
    }

    return newResponsiveValues;
  }

  renderThemesComponentsAssets(files: IFiles, isPreview: boolean, root: ITreeNode): void {
    this.createFile(files, isPreview);
    this.createThemes();
    this.createComponents(this.tree, this.items, root, this.viewDOM);
  }

  initAndRenderThemesComponentsAssets(
    script: IScriptType,
    files: IFiles,
    isPreview: boolean
  ): Record<string, any> {
    const { name, icon, userEdited } = script;
    const parsedItem = this.cloneObject(script);
    const tree = parsedItem.tree;
    const { items, themes } = parsedItem;
    this.initMethodsObject(script, themes, items);
    this.items = items;
    this.themes = themes;
    this.tree = new Tree(Tree.objectToTreeNode([tree.root])[0]);
    this.renderThemesComponentsAssets(files, isPreview, this.tree.root);
    return { name, icon, userEdited, tree, items };
  }

  renderScript(
    script: IScriptType,
    files: IFiles,
    isPreview: boolean,
    viewDOM: IComponentinfo
  ): IAguaRender | undefined {
    this.viewDOM = viewDOM;
    const { name, icon, userEdited } = this.initAndRenderThemesComponentsAssets(script, files, isPreview);
    return this.renderPage(name, icon, userEdited);
  }

  renderCanvas(
    script: IScriptType,
    files: IFiles,
    isPreview: boolean,
    viewDOM: IComponentinfo
  ): IAguaRender | undefined {
    this.viewDOM = viewDOM;
    const selectedScreen = script.selectedScreen ?? '';
    const { name, icon, userEdited, tree, items } = this.initAndRenderThemesComponentsAssets(
      script,
      files,
      isPreview
    );
    return this.methods?.canvasMode(selectedScreen, tree, items, icon, name, userEdited, viewDOM);
  }

  createCustomCodeWrapper(componentName: string, code: TypeCustomCode): TypeItems {
    const mobj: TypeItemProperties = { ...DefaultLayer };
    mobj.id = '1';
    mobj.component = componentName;
    mobj.name = componentName + '1';
    mobj.nameChanged = true;
    mobj.code = code;
    mobj.customcode = true;
    return { 1: mobj };
  }

  initComponentRendering(isImported: boolean, script: IScriptType): void {
    if (this.methods == null) return;
    this.methods.styles = {};
    this.methods.renderingComponent = true;
    this.methods.viewDOM = this.viewDOM;
    this.methods.unmodifiedTree = isImported
      ? new Tree(new TreeNode('0', '1'))
      : new Tree(script.component?.data?.tree ?? new TreeNode('0', '1'));
  }

  initAndRenderThemesComponentsAssetsForCompPreview(
    script: IScriptType,
    files: IFiles,
    themes: IConversorThemesType,
    items: TypeItems,
    tree: ITreeNode[],
    componentName: string
  ): void {
    if (this.methods == null) return;
    const params = script.params ?? {};
    this.createFile(files, true);
    this.methods.createComponentModeThemes(themes, items, tree[0], params, componentName);
    this.methods.items = items;
    this.createComponentPreviewThemes(items, new Tree(tree[0]));
    this.createComponents(new Tree(tree[0]), items, tree[0], this.viewDOM);
  }

  renderComponent(
    script: IScriptType,
    files: IFiles,
    viewDOM: IComponentinfo
  ): [IAguaRender, string, { components: string }] | undefined {
    if (this.methods == null) return;
    const component = script.component;
    if (component == null) return;
    this.viewDOM = viewDOM;
    const isImported = component.imported != null && component.imported;
    this.initComponentRendering(isImported, script);
    const items: TypeItems = {};
    const tree: ITreeNode[] = isImported
      ? [new TreeNode('0', '1')]
      : [this.cloneObject(component.data?.tree) ?? new TreeNode('0', '1')];
    const componentName = component.name;
    const pitems =
      isImported && component.code != null && typeof component.code !== 'string'
        ? this.createCustomCodeWrapper(componentName, component.code)
        : this.cloneObject(component.data?.items);

    for (const p in pitems) {
      pitems[p].id = p;
      items[p] = pitems[p];
    }
    const compItem = items[tree[0].id];
    if ('component' in compItem) compItem.component = componentName;
    const themes: IConversorThemesType = {
      references: isImported ? undefined : this.cloneObject(component.references),
      attributeReference: isImported ? undefined : this.cloneObject(component.data?.attributeReference),
    };
    this.methods.unmodifiedItem = this.cloneObject(items);
    this.initAndRenderThemesComponentsAssetsForCompPreview(script, files, themes, items, tree, componentName);
    const entryFile = this.getEntryFile(isImported, script, componentName);
    return this.methods.componentPreviewMode(componentName, tree[0], entryFile);
  }

  getEntryFile(isImported: boolean, script: IScriptType, componentName: string): string {
    if (isImported && script.component?.code != null && typeof script.component?.code !== 'string') {
      return script.component.code.entryFile;
    }
    return componentName.substring(0, 1).toLowerCase() + componentName.substring(1) + '.jsx';
  }

  createComponentPreviewThemes(items: TypeItems, tree: ITree): void {
    if (this.themes == null || this.methods == null) return;
    const { infoThemes, attributeReference, responsiveValues, parametersValuesList } = this.methods;
    this.themes.references = attributeReference;
    this.themes.values = infoThemes;
    this.themes.responsiveValues = responsiveValues;
    this.themes.parametersValuesList = parametersValuesList;
    this.items = items;
    this.tree = tree;
    this.createThemes();
  }

  renderPage(name: string, icon: string, userEdited: any): IAguaRender | undefined {
    if (this.methods == null) return;
    return this.methods.createPages(this.tree, this.items, icon, name, userEdited);
  }

  createConversionById(id: string, script: IScriptType): string[] {
    if (this.methods == null) return [];
    const root = Tree.objectToTreeNode([script.tree.root])[0];
    const items = script.items;
    const item = items[id];
    if (item == null) return [];
    const treeNode = this.treeNodeById(root, id);
    if (treeNode == null) return [];
    if (item.commonKind === 'screen') {
      const body = this.methods.createTreeParent(treeNode, items, 'none', this.viewDOM);
      const page = this.methods.createPage(item, body, 'styleOf-' + id);
      return [page, this.methods.styles['styleOf-' + id]];
    }
    const treeRoot = new TreeNode('0', id);
    treeRoot.children = treeNode.children;
    this.methods.styles['styleOf-' + id] = '';
    const elements = this.methods.createTreeParent(treeRoot, items, 'none', this.viewDOM);
    const styles = this.methods.styles['styleOf-' + id];
    if (this.language === 'react') return [elements];
    return [elements, styles];
  }

  treeNodeById(treeNode: ITreeNode, id: string): ITreeNode | null {
    if (treeNode.id !== id) {
      let node = null;
      for (let i = 0; i < treeNode.children.length && node == null; i++) {
        if (treeNode.children[i] != null) node = this.treeNodeById(treeNode.children[i], id);
      }
      return node;
    } else return treeNode;
  }

  createThemes(): void {
    if (this.methods == null || this.themes == null) return;
    const references = this.themes.references;
    const valuesList = this.themes.values;
    const responsiveValuesList = this.themes.responsiveValues;
    const parametersValuesList = this.themes.parametersValuesList;
    for (const theme in references) {
      const referenceList = references[theme as keyof typeof references];
      if (theme.includes('_')) {
        this.setComponentReferences(referenceList, theme);
      } else this.setItemReferences(referenceList, theme);
    }
    this.adaptCustomCodeParameters(parametersValuesList);
    this.methods.createThemes(valuesList, responsiveValuesList, references, this.viewDOM);
  }

  setComponentReference(
    variableList: any,
    itemId: string,
    theme: string,
    variable: string,
    mTheme: string
  ): void {
    const attribute = variableList[itemId];
    const item = this.items[theme.split('_')[1]];
    if (item != null) {
      for (const values of attribute) {
        let attrInfo = values.attribute;
        const isIcon = item.commonKind === 'icon';
        if (attrInfo.indexOf('Dimension') !== -1 && isIcon) {
          attrInfo = ['valueheight', 'valuewidth', 'fontSize'];
        }
        if (typeof attrInfo === 'string') attrInfo = [attrInfo];
        const themes = { parent: theme, child: mTheme };
        this.setCompRefInItems(item, attrInfo, variable, themes, itemId, values);
      }
    }
  }

  setComponentReferences(referenceList: any, theme: string): void {
    for (const mTheme in referenceList) {
      const mReferenceList = referenceList[mTheme];
      for (const variable in mReferenceList) {
        const variableList = mReferenceList[variable];
        for (const itemId in variableList) {
          this.setComponentReference(variableList, itemId, theme, variable, mTheme);
        }
      }
    }
  }

  setCompRefResponsive(
    responsiveValuesList: any,
    themes: any,
    variable: string,
    item: TypeItemProperties,
    values: any,
    valuesList: any
  ): void {
    const theme = themes.parent;
    const mTheme = themes.child;
    if ('childrenReferences' in item) {
      if (responsiveValuesList.desktop[theme]?.[mTheme]?.[variable] != null) {
        const valueWeb = responsiveValuesList.desktop[theme][mTheme][variable];
        const valuePhone = responsiveValuesList.phone[theme][mTheme][variable] ?? valueWeb;
        const valueTablet = responsiveValuesList.tablet[theme][mTheme][variable] ?? valueWeb;
        if (item.childrenReferences == null) item.childrenReferences = [];
        item.childrenReferences.push([
          variable,
          valueWeb + ',' + valuePhone + ',' + valueTablet,
          values.route.toString(),
          '',
          '',
        ]);
      } else {
        const value = valuesList[theme][mTheme][variable];
        if (item.childrenReferences == null) item.childrenReferences = [];
        item.childrenReferences.push([variable, value, values.route, '', '']);
      }
    }
  }

  setCompRefInItems(
    item: TypeItemProperties,
    attrInfo: string[],
    variable: string,
    themes: any,
    itemId: string,
    values: any
  ): void {
    const responsiveValuesList = this.themes?.responsiveValues ?? {};
    const valuesList = this.themes?.values;
    if (valuesList == null) return;
    const theme = themes.parent as string;
    const mTheme = themes.child;
    for (const attribute of attrInfo) {
      if (valuesList[theme] == null) valuesList[theme] = {};
      const parentTheme: TypeVariableValuesComponents = valuesList[
        theme
      ] as any as TypeVariableValuesComponents;
      if (parentTheme[mTheme] == null) parentTheme[mTheme] = {};
      const pTheme = valuesList[theme][mTheme];
      if (valuesList[theme]?.[mTheme]?.[variable as keyof typeof pTheme] != null) {
        this.setCompRefResponsive(responsiveValuesList, themes, variable, item, values, valuesList);
      }
      const childItem = this.items[itemId];
      if (childItem.references == null) childItem.references = {};
      childItem.references[attribute] = [attribute, variable, mTheme.split('_')[0], undefined, itemId];
    }
  }

  setItemReferenceAttribute(values: any, item: TypeItemProperties, variable: string, theme: string): void {
    let attrributeInfo: string | string[] = values.attribute;
    if (attrributeInfo === 'valuewidth' && item.commonKind === 'icon') {
      attrributeInfo = ['valueheight', 'valuewidth', 'fontSize'];
    } else if (attrributeInfo === 'fontStyles') {
      attrributeInfo = ['fontFamily', 'fontSize', 'leading', 'spacing', 'weight'];
    }
    if (item.references == null) item.references = {};
    if (typeof attrributeInfo !== 'string') {
      if (attrributeInfo.length === 2) {
        for (let j = 0; j < 2; j++) {
          item.references[attrributeInfo[j]] = [attrributeInfo[j], variable, theme, undefined, values.id];
        }
      } else {
        for (let j = 0; j < 5; j++) {
          item.references[attrributeInfo[j]] = [
            attrributeInfo[j],
            attrributeInfo[j] + '_' + variable,
            theme,
            undefined,
            values.id,
          ];
        }
      }
    } else item.references[attrributeInfo] = [attrributeInfo, variable, theme, undefined, values.id];
  }

  setItemReferences(referenceList: any, theme: string): void {
    for (const variable in referenceList) {
      const variableList = referenceList[variable];
      for (const itemId in variableList) {
        const attribute = variableList[itemId];
        const item = this.items[itemId];
        if (item != null) {
          for (const values of attribute) {
            this.setItemReferenceAttribute(values, item, variable, theme);
          }
        }
      }
    }
  }

  adaptCustomParameterAttribute(
    parameterList: any,
    attribute: string,
    item: TypeItemProperties,
    childItem: TypeItemProperties,
    mTheme: string
  ): void {
    if ('childrenReferences' in item && 'references' in childItem) {
      const value = parameterList[attribute].value;
      if (item.childrenReferences == null) item.childrenReferences = [];
      item.childrenReferences.push([
        attribute,
        value,
        parameterList[attribute].route,
        undefined,
        '',
        'isParameter',
      ]);
      if (childItem.references == null) childItem.references = {};
      childItem.references[attribute] = [
        attribute,
        attribute,
        mTheme.split('_')[0],
        undefined,
        mTheme.split('_')[1],
      ];
    }
  }

  adaptCustomCodeParameters(parametersValuesList: IParametersValuesList): void {
    for (const theme in parametersValuesList) {
      const parameterThemeList = parametersValuesList[theme];
      for (const mTheme in parameterThemeList) {
        const item = this.items[theme.split('_')[1]];
        if (item != null) {
          const parameterList = parameterThemeList[mTheme];
          const childItem = this.items[mTheme.split('_')[1]];
          for (const attribute in parameterList) {
            this.adaptCustomParameterAttribute(parameterList, attribute, item, childItem, mTheme);
          }
        }
      }
    }
  }

  createComponents(tree: ITree, items: TypeItems, root: ITreeNode, viewDOM: IComponentinfo): void {
    if (this.methods == null) return;
    const componentsInOrder = this.orderComponents(tree, items);
    this.methods.componentCode = {};
    this.methods.layoutCode = {};
    this.methods.componentsUsed = {};
    for (const component of componentsInOrder) {
      const treeNode = this.treeNodeById(root, component.sampleId);
      if (treeNode != null) this.methods.createComponent(treeNode, items, component, viewDOM);
    }
  }

  orderComponents(tree: ITree, items: TypeItems): TypeDir[] {
    const marks: Record<string, TypeMark> = {};
    const pTree = tree.root.children.length === 0 ? [tree.root] : tree.root.children;
    this.getBranchComponent(pTree, 0, items, marks);
    const dir: Record<string, TypeDir[]> = {};
    let max = 0;
    for (const id in marks) {
      const mark = marks['' + id];
      const value = Math.abs(mark.index);
      if (dir['' + value] == null) dir['' + value] = [];
      dir['' + value].push({ name: id, sampleId: mark.example });
      if (value > max) max = value;
    }
    let ordered: TypeDir[] = [];
    for (let i = max; i >= 0; i--) {
      const orderedItems = dir['' + i];
      if (orderedItems != null) ordered = ordered.concat(orderedItems);
    }
    return ordered;
  }

  getBranchComponent(
    tree: ITreeNode[],
    index: number,
    items: TypeItems,
    marks: Record<string, TypeMark>
  ): void {
    for (const treeItem of tree) {
      const item = items[treeItem.id];
      if (item != null) {
        if ('component' in item && item.component != null && item.component !== '') {
          if (marks[item.component] != null) {
            if (marks[item.component].index < index) marks[item.component].index = index;
          } else {
            marks[item.component] = { index, example: item.id };
          }
        }
        if (treeItem.children == null) treeItem.children = [];
        this.getBranchComponent(treeItem.children, index + 1, items, marks);
      }
    }
  }

  createFile(files: IFiles, isPreview: boolean): void {
    if (this.methods == null) return;
    this.methods.isPreview = isPreview;
    for (const file of files) {
      this.methods.addFile(file.name, file.data);
    }
  }

  transformDimensionValue(info: string, groupVariableList: Record<string, string>, variable: string): string {
    if (info !== 'fix') return info;
    const sizeValue = groupVariableList[variable.replace('type', 'value')];
    const sizeUnit = groupVariableList['dimensionfix-' + variable.replace('type', '') + '-unit'];
    const mval = sizeValue + ',' + sizeUnit;
    return mval;
  }

  variableIsMarginOrPadding(variable: string): boolean {
    if (variable?.includes('margin')) return true;
    return variable?.includes('padding');
  }

  /**
  adaptReferenceGroup (referenceGroups: ReferencesGroupType): ConversorReferenceGroupType {
    const referenceGroupList: ConversorReferenceGroupType = {};
    for (const group in referenceGroups) {
      referenceGroupList[group] = {};
      const groupVariableList = referenceGroups[group];
      for (const variable in groupVariableList) {
        if (variable === 'fontStyles') {
          this.apadtFontClassVariable(groupVariableList, referenceGroupList, group);
        } else if (variable === WIDTH || variable === HEIGHT) {
          this.adaptWidthClassVariable(variable, groupVariableList, referenceGroupList, group);
        } else if (variable === BORDERRADIUS) {
          const info = groupVariableList[variable];
          if (info != null) {
            if ('value' in info) {
              referenceGroupList[group][variable] = info.value + info.unit;
            } else {
              const topLeft = info.topLeft.value + info.topLeft.unit + ' ';
              const topRight = info.topRight.value + info.topRight.unit + ' ';
              const bottomLeft = info.bottomLeft.value + info.bottomLeft.unit + ' ';
              const bottomRight = info.bottomRight.value + info.bottomRight.unit;
              referenceGroupList[group][variable] = topLeft + topRight + bottomLeft + bottomRight;
            }
          }
        } else if (typeof (groupVariableList[variable as keyof typeof groupVariableList]) !== 'string') {
          const value =
            groupVariableList[variable as keyof typeof groupVariableList] as SizeValueType.SizeValueType;
          referenceGroupList[group][variable] = value.value + value.unit;
        } else {
          const value = groupVariableList[variable as keyof typeof groupVariableList] as string;
          referenceGroupList[group][variable] = value;
        }
      }
    }
    return referenceGroupList;
  }

  apadtFontClassVariable (groupVariableList: ClassesType, referenceGroupList: ConversorReferenceGroupType,
    group: string): void {
    const info = groupVariableList.fontStyles;
    if (info != null) {
      referenceGroupList[group].fontFamily = info.fontFamily.toString();
      referenceGroupList[group].fontSize =
        info.fontSize[SizeValueType.SizeValueProperty] + info.fontSize.unit;
      referenceGroupList[group].leading = info.leading.toString();
      referenceGroupList[group].spacing = info.spacing.toString();
      referenceGroupList[group].weight = info.weight.toString();
    }
  }

  adaptWidthClassVariable (pVar: string, groupVariableList: ClassesType,
    referenceGroupList: ConversorReferenceGroupType, group: string): void {
    const variable = pVar === WIDTH ? WIDTH : HEIGHT;
    const info = groupVariableList[variable];
    if (info != null) {
      if (info[DimensionType.DimensionKindProperty] === FIX &&
          info[DimensionType.DimensionDataProperty] != null) {
        referenceGroupList[group]['value' + variable] =
          info[DimensionType.DimensionDataProperty].value + info[DimensionType.DimensionDataProperty].unit;
      } else referenceGroupList[group]['value' + variable] = info[DimensionType.DimensionKindProperty];
      if (info[DimensionType.DimensionMaxProperty] != null) {
        referenceGroupList[group]['max-' + variable] =
          info[DimensionType.DimensionMaxProperty].value + info[DimensionType.DimensionMaxProperty].unit;
      }
      if (info[DimensionType.DimensionMinProperty] != null) {
        referenceGroupList[group]['min-' + variable] =
          info[DimensionType.DimensionMinProperty].value + info[DimensionType.DimensionMinProperty].unit;
      }
    }
  } */
}
