import React from 'react';

import styles from './styles.module.css';

export const APILayout = ({
  menuSideBar,
  sideBar,
  headerPane,
  requestPane,
  responsePane,
}: {
  menuSideBar: React.JSX.Element;
  sideBar: React.JSX.Element;
  headerPane: React.JSX.Element;
  requestPane: React.JSX.Element;
  responsePane: React.JSX.Element;
}): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.menuSideBar}>{menuSideBar}</div>
      <div className={styles.sideBar}>{sideBar}</div>
      <div className={styles.mainContainer}>
        <div className={styles.header}>{headerPane}</div>
        <div className={styles.content}>
          <div className={styles.requestPane}>{requestPane}</div>
          <div className={styles.responsePane}>{responsePane}</div>
        </div>
      </div>
    </div>
  );
};
