import React from 'react';

import { SearchBar } from '@components/searchBar';

export const SingleLineInput = ({
  placeholder,
  onFinish,
  onChange,
  register,
  defaultValue,
  disabled = false,
  preventLeftBorderRadius = false,
}: {
  placeholder: string;
  onFinish?: (value: string) => any;
  onChange?: (value: string) => any;
  register?: any;
  defaultValue?: string;
  disabled?: boolean;
  preventLeftBorderRadius?: boolean;
}): React.JSX.Element => {
  return (
    <SearchBar
      preventLeftBorderRadius={preventLeftBorderRadius}
      disabled={disabled}
      register={register}
      onChange={onChange}
      placeholder={placeholder}
      onFinish={onFinish}
      hideIcon
      defaultValue={defaultValue}
    />
  );
};
