import {
  BR,
  BRACKET_NAME,
  COMPONENT_IMPORT,
  FROM,
  FROM_PAGES,
  HTTP,
  HTTP_URL,
  IMPORT,
  LINE_JUMP,
  MAILTO,
  MINUS,
  ON_CLICK,
  ON_CLICK_CLOSE,
  SCREEN,
  SEMICOLON,
  SEMICOLON_LINE_JUMP,
  SLASH,
  TWO_POINTS,
  URL_BRACKET,
} from '../../constants/constants';
import { type TypeDictionary } from '../dictionary/dictionary';
import { isComponentALayout } from '../reactComponentMethods/reactComponentUtils';
import { getConvertNametoReact } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';

import { type ITreeNode } from '@globalUtils/tree';

import { type ReactInfo, type TypeItems } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';

export function addDependencies(dependencies: string[], neededInfo: ReactInfo): void {
  if (dependencies != null) {
    for (const dependency of dependencies) {
      const info = dependency.replaceAll(BR, '').split(TWO_POINTS);
      if (neededInfo.dependenciesList[info[0]] == null) {
        neededInfo.dependenciesList[info[0]] = info[1];
      }
    }
  }
}

export function findLongestCommonPrefix(strs: string[]): string {
  let oneWasNull = false;
  strs.forEach((str) => {
    if (str == null) oneWasNull = true;
  });
  if (oneWasNull) return '';
  if (strs.length < 1) return '';

  let prefix = strs[0];

  for (let i = 1; i < strs.length; i++) {
    while (strs[i].indexOf(prefix) !== 0) {
      prefix = prefix.substring(0, prefix.length - 1);
      if (prefix === '') return '';
    }
  }

  return prefix;
}

export function handlePath(importedPath: string, objectivePath: string): string {
  console.log(importedPath);
  console.log(objectivePath);
  const longestPreffix = findLongestCommonPrefix([importedPath, objectivePath]);
  let res;
  if (longestPreffix !== '') {
    const parts = longestPreffix.split('/');
    let convertedToDots = '';
    parts.forEach((part) => {
      if (part !== '.' && part !== '') convertedToDots += '../';
    });
    res = importedPath.replace(longestPreffix, convertedToDots);
  } else {
    const parts = objectivePath.split('/');
    let converted = '';
    for (let i = 0; i < parts.length - 1; i++) converted += '../';
    res = converted + importedPath;
  }
  console.log(res);
  return res;
}

export function handleImports(componentsUsed: string[], component: string, neededInfo: ReactInfo): string {
  let imports = '';
  let objectivePath = 'src/agua/';
  if (neededInfo.creatingComponent) {
    if (isComponentALayout(neededInfo.pageName, neededInfo.componentsUsed)) {
      objectivePath += 'layouts';
    } else {
      objectivePath += 'components';
    }
  } else {
    objectivePath += 'pages';
  }
  objectivePath += '/' + neededInfo.pageName + '/' + neededInfo.pageName + '.jsx';
  if (componentsUsed == null) return component.replace(COMPONENT_IMPORT, '');
  for (const component of componentsUsed) {
    const info = neededInfo.componentsImport[component];
    const path = handlePath(info.path, objectivePath);
    const mVal = IMPORT + ' ' + info.name + FROM + path + '"' + SEMICOLON_LINE_JUMP;
    imports += mVal;
  }
  return component.replace(COMPONENT_IMPORT, imports);
}

export function hrefHandle(
  item: TypeItemProperties,
  layout: boolean,
  name: string,
  neededInfo: ReactInfo,
  dictionary: TypeDictionary
): string {
  if (layout) {
    return dictionary.hrefParameter.replace(URL_BRACKET, name.replaceAll(MINUS, ''));
  } else if ('href' in item && item.href != null) {
    if (neededInfo.canvas) return ON_CLICK + item.id + ON_CLICK_CLOSE;
    if (!item.href.includes('.') && !item.href.includes(TWO_POINTS)) return '';
    const httpVal = item.href.startsWith(HTTP) ? item.href : HTTP_URL + item.href;
    const url = item.href.startsWith(MAILTO) ? item.href : httpVal;
    return dictionary.href.replace(URL_BRACKET, url);
  } else if (neededInfo.canvas) return ON_CLICK + item.id + ON_CLICK_CLOSE;
  else return '';
}

export function updatePageReferences(pageName: string, references: string[], neededInfo: ReactInfo): void {
  if (neededInfo.pagesReferences[pageName] == null) neededInfo.pagesReferences[pageName] = [];
  for (const reference of references) {
    if (!neededInfo.pagesReferences[pageName].includes(reference)) {
      neededInfo.pagesReferences[pageName].push(reference);
    }
  }
}

export function getScreenRoutesCode(
  selectedScreen: string,
  screen: TypeItemProperties,
  screens: ITreeNode[],
  i: number,
  dictionary: TypeDictionary,
  routes: string,
  imports: string
): [string, string] {
  if (selectedScreen === '' || selectedScreen === screens[i].id) {
    const screenName = getConvertNametoReact(screen.name);
    const url = 'url' in screen && screen.url != null ? screen.url : SLASH + screenName;
    let screenRoute = dictionary.routePath.replace(BRACKET_NAME, url);
    screenRoute = screenRoute.replace(BRACKET_NAME, screenName);
    const archiveNameIndex = screenName.substring(0, 1).toLowerCase() + screenName.substring(1);
    if (i === 0 || selectedScreen != null) {
      let screenRouteHome = dictionary.routePath.replace(BRACKET_NAME, SLASH);
      screenRouteHome = screenRouteHome.replace(BRACKET_NAME, screenName);
      routes += screenRouteHome + LINE_JUMP;
    }
    routes += screenRoute + LINE_JUMP;
    const indexVal = archiveNameIndex + SLASH + archiveNameIndex;
    imports += IMPORT + ' ' + screenName + FROM_PAGES + indexVal + '"' + SEMICOLON;
  }
  return [routes, imports];
}

export function getRoutesCode(
  screens: ITreeNode[],
  items: TypeItems,
  selectedScreen: string,
  dictionary: TypeDictionary
): string[] {
  let routes = '';
  let imports = '';
  for (let i = 0; i < screens.length; i++) {
    const screen = items[screens[i].id];
    if (screen.commonKind === SCREEN) {
      const info = getScreenRoutesCode(selectedScreen, screen, screens, i, dictionary, routes, imports);
      routes = info[0];
      imports = info[1];
    }
  }
  return [routes, imports];
}
