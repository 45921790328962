import { UNIT, UNIT_PERCENTAGE, UNIT_PX, UNIT_VH, UNIT_VW, VALUE } from '@globalTypes/constants';

export namespace SizeValueType {
  export type SizeValueProperty = 'value';
  export const SizeValueProperty: SizeValueProperty = VALUE;
  export const SizeUnitVHProperty = UNIT_VH;
  export const SizeUnitPXProperty = UNIT_PX;
  export const SizeUnitVWProperty = UNIT_VW;
  export const SizeUnitPercentageProperty = UNIT_PERCENTAGE;
  export const SizeUnits = [SizeUnitVHProperty, SizeUnitPXProperty,
    SizeUnitVWProperty, SizeUnitPercentageProperty];
  export type SizeUnitProperty = 'unit';
  export const SizeUnitProperty: SizeUnitProperty = UNIT;

  export type SizeUnitWithPercent = 'px' | 'vh' | 'vw' | '%';
  export type SizeUnitWithoutPercent = 'px' | 'vh' | 'vw';

  export type SizeUnits = SizeUnitWithPercent | SizeUnitWithoutPercent;
  export interface SizeValueType {
    [SizeValueProperty]: number;
    [SizeUnitProperty]: string; // SizeUnits;
  }
}
