/* eslint-disable multiline-ternary */

/* eslint-disable max-len */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { BackgroundGradient } from '@components/backgroundGradient';

import { setCurrentFileName } from '@reducers/files';

import { trackEvent } from '@globalUtils/metrics';

export const WithWebsocket = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const dispatch = useDispatch();

  const { sendMessage, lastMessage, readyState } = useWebSocket('ws://localhost:4044/');

  const firstTime = useRef<boolean>(true);

  useEffect(() => {
    if (!firstTime.current) return;
    firstTime.current = true;
    trackEvent('Got to WS Page');
  }, []);

  useEffect(() => {
    if (lastMessage == null) return;
    const msg = lastMessage.data;
    const obj = JSON.parse(msg);
    if (obj.fileName != null) {
      const mFileName = obj.fileName as string;
      dispatch(setCurrentFileName(mFileName));
      trackEvent('Changed Local VS file');
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return connectionStatus === 'Open' ? (
    children
  ) : (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'var(--background-color-2)' }}>
      <div className={'h-full w-full relative flex items-center justify-center bg-dot-white/[0.2]'}>
        <div
          style={{ backgroundColor: 'black' }}
          className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"
        ></div>
        <BackgroundGradient className="w-auto h-auto">
          <div
            style={{
              width: '400px',
              height: 'fit-content',
              padding: 'var(--padding-l)',
              backgroundColor: 'var(--background-color-2)',
              borderRadius: 'var(--border-radius-xxl)',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 'calc(var(--padding-l) + var(--padding-m))',
              }}
            >
              <img style={{ height: '50px' }} src={'/agua_white.png'}></img>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: 'var(--padding-xl) 0px',
              }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          </div>
        </BackgroundGradient>
      </div>
    </div>
  );
};
