import { HeaderText } from '..';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SingleLineInput } from '@components/singleLineInput';

const areFieldsEqual = (a: DataInput, b: DataInput): boolean => {
  // TODO: Check that those are not equal
  return false;
};

export type DataInput = string[][];

export interface MultipleSingleLineInputProps {
  defaultValue: DataInput;
  notifyChange: (value: DataInput) => any;
}

export const MultipleSingleLineInput = ({
  defaultValue,
  notifyChange,
}: MultipleSingleLineInputProps): React.JSX.Element => {
  const { t } = useTranslation();

  const [values, setValues] = useState<string[][]>([]);

  let mValues = values;
  if (values.length === 0) {
    mValues = [['', '']];
  }

  useEffect(() => {
    if (!areFieldsEqual(defaultValue, values)) {
      setValues(defaultValue);
    }
  }, [defaultValue, values]);

  const handleWritingAlias = (i: number, val: string): void => {
    const copy = [...mValues];
    copy[i][0] = val;
    if (i === mValues.length - 1) copy.push(['', '']);
    setValues(copy);
    notifyChange(copy);
  };

  const handleWritingPath = (i: number, val: string): void => {
    const copy = [...mValues];
    copy[i][1] = val;
    setValues(copy);
    notifyChange(copy);
  };

  return (
    <div
      className="hide-native-scrollbar"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '280px',
        overflowY: 'scroll',
        border: '1px solid var(--border-color)',
        borderBottomLeftRadius: 'var(--border-radius-m)',
        borderBottomRightRadius: 'var(--border-radius-m)',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          position: 'sticky',
          top: '0',
          border: '1px solid var(--border-color)',
          borderTop: 'none',
          borderBottom: 'none',
          backgroundColor: 'var(--background-color-2)',
        }}
      >
        <div
          style={{
            flex: '1 1 50%',
            padding: 'var(--padding-s)',
            borderRight: '1px solid var(--border-color)',
          }}
        >
          <HeaderText>{t('alias-alias-label')}</HeaderText>
        </div>
        <div style={{ flex: '1 1 50%', padding: 'var(--padding-s)' }}>
          <HeaderText>{t('alias-path-label')}</HeaderText>
        </div>
      </div>
      {mValues.map((vals, i) => {
        const isLast = mValues.length - 1 === i;
        return (
          <div
            key={i}
            style={{
              width: '100%',
              display: 'flex',
              border: '1px solid var(--border-color)',
              borderBottom: isLast ? 'none' : '1px solid var(--border-color)',
            }}
          >
            <div
              style={{
                flex: '1 1 50%',
                padding: 'var(--padding-s)',
                borderRight: '1px solid var(--border-color)',
              }}
            >
              <SingleLineInput
                placeholder={''}
                defaultValue={vals[0]}
                onChange={(val) => {
                  handleWritingAlias(i, val);
                }}
              />
            </div>
            <div style={{ flex: '1 1 50%', padding: 'var(--padding-s)' }}>
              <SingleLineInput
                placeholder={''}
                defaultValue={vals[1]}
                onChange={(val) => {
                  handleWritingPath(i, val);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
