/* eslint-disable max-lines */
import { type ReactInfo } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  CALC_FILL,
  CALC_FILL_MARGINS,
  CLOSING_PARENTESIS,
  FILL_VALUE,
  FIT_CONTENT,
  FIX,
  FLEX_SHRINK,
  FONT_SIZE_CSS,
  HEIGHT,
  ICON,
  IMAGE,
  IMAGE_VIEW,
  LINE_JUMP,
  MARGIN_BOTTOM,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MIN_MINUS,
  SCREEN,
  SEMICOLON_LINE_JUMP,
  SPACE_PLUS,
  TEXT,
  THREE_CLOSING_PARENTESIS,
  TWO_CLOSING_PARENTESIS,
  TWO_POINTS,
  TWO_POINTS_SPACE,
  UNIT_PERCENTAGE,
  UNIT_PX,
  UNSET,
  VALUE_HEIGHT,
  VALUE_WIDTH,
  VAR_DEFINITION,
  VAR_OPENING,
  WIDTH,
  WRAP
} from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { referenceValue } from '../reactItemUtils';

export function initDefaultDimensionValues (item: TypeItemProperties, dimension: string,
  componentModeActive: boolean): Array<number | string> {
  let values = dimension === WIDTH ? [300, UNIT_PX] : [100, UNIT_PX];
  if (item.commonKind === IMAGE_VIEW) values = dimension === WIDTH ? [115, UNIT_PX] : [90, UNIT_PX];
  else if (item.commonKind === ICON) values = [30, UNIT_PX];
  else if (item.commonKind === TEXT) values = dimension === WIDTH ? [55, UNIT_PX] : [25, UNIT_PX];
  if (componentModeActive) values = [100, UNIT_PERCENTAGE];
  return values;
}

export function dimensionWrapStyle (item: TypeItemProperties, dimension: string, dimensions: string,
  parent: TypeItemProperties, dictionary: TypeDictionary, attributeValue: string,
  creatingComponent: boolean): string {
  const defaultMin = MIN_MINUS + attributeValue + ' ' + UNSET + dictionary.dimensionClosing;
  if (referenceValue(MIN_MINUS + dimension, item, creatingComponent) === false) {
    if (dimension === HEIGHT && 'height' in item) {
      if (item.height.min != null) {
        dimensions += MIN_MINUS + attributeValue + ' ' + item.height.min.value + item.height.min.unit +
          dictionary.dimensionClosing;
      } else dimensions += defaultMin;
    } else if ('width' in item) {
      if (item.width.min != null) {
        dimensions += MIN_MINUS + attributeValue + ' ' + item.width.min.value + item.width.min.unit +
          dictionary.dimensionClosing;
      } else dimensions += defaultMin;
    }
  }
  dimensions += LINE_JUMP + dictionary[dimension as keyof typeof dictionary] + dictionary.wrap;
  if (parent == null || parent.commonKind === SCREEN) {
    if (dimension === HEIGHT || ('height' in item && item.height.kind !== WRAP)) {
      dimensions += FLEX_SHRINK + TWO_POINTS_SPACE + '0' + SEMICOLON_LINE_JUMP;
    }
  }
  return dimensions;
}

export function dimensionFixStyle (item: TypeItemProperties, dimension: string, dimensions: string,
  dictionary: TypeDictionary, attributeValue: string, values: [number, string],
  creatingComponent: boolean): string {
  let constPropety;
  if (dimension === WIDTH) {
    constPropety = WIDTH in item ? item.width : null;
  } else {
    constPropety = HEIGHT in item ? item.height : null;
  }
  if (constPropety == null) return '';
  let unit = constPropety.data?.unit ?? values[1];
  unit = unit ?? dictionary.px;
  const size = constPropety.data?.value ?? values[0];
  dimensions += attributeValue + size + dictionary[unit as keyof typeof dictionary] +
    dictionary.dimensionClosing;
  const defaultMin = LINE_JUMP + MIN_MINUS + attributeValue + size +
    dictionary[unit as keyof typeof dictionary] + dictionary.dimensionClosing;
  if (referenceValue(MIN_MINUS + dimension, item, creatingComponent) === false) {
    if (dimension === HEIGHT && 'height' in item) {
      if (item.height.min != null) {
        dimensions += LINE_JUMP + MIN_MINUS + attributeValue + ' ' + item.height.min.value +
          item.height.min.unit + dictionary.dimensionClosing;
      } else dimensions += defaultMin;
    } else if ('width' in item) {
      if (item.width.min != null) {
        dimensions += LINE_JUMP + MIN_MINUS + attributeValue + ' ' + item.width.min.value +
          item.width.min.unit + dictionary.dimensionClosing;
      } else dimensions += defaultMin;
    }
  }
  return dimensions;
}

export function dimensionFillStyle (item: TypeItemProperties, dimension: string, dimensions: string,
  dictionary: TypeDictionary, creatingComponent: boolean): string {
  const attributeValue = dictionary[dimension as keyof typeof dictionary];
  const defaultMin = MIN_MINUS + attributeValue + UNSET + SEMICOLON_LINE_JUMP;
  if (referenceValue(MIN_MINUS + dimension, item, creatingComponent) === false) {
    if (dimension === HEIGHT && 'height' in item) {
      if (item.height.min != null) {
        dimensions += MIN_MINUS + attributeValue + ' ' + item.height.min.value +
          item.height.min.unit + dictionary.dimensionClosing;
      } else dimensions += defaultMin;
    } else if ('width' in item) {
      if (item.width.min != null) {
        dimensions += MIN_MINUS + attributeValue + ' ' + item.width.min.value + item.width.min.unit +
          dictionary.dimensionClosing;
      } else dimensions += defaultMin;
    }
  }
  dimensions += dictionary[dimension as keyof typeof dictionary];
  if (dimension === HEIGHT) dimensions = dimensionFillHeightStyle(item, dimensions);
  else dimensions = dimensionFillWidthStyle(item, dimensions);
  return dimensions;
}

export function dimensionFillHeightStyle (item: TypeItemProperties, dimensions: string): string {
  const marginTop = referenceValue(MARGIN_TOP, item, false);
  const marginBottom = referenceValue(MARGIN_BOTTOM, item, false);
  if (marginTop !== false && marginBottom !== false) {
    dimensions += CALC_FILL_MARGINS + VAR_OPENING + marginTop + CLOSING_PARENTESIS + SPACE_PLUS +
      VAR_OPENING + marginBottom + THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (typeof (marginTop) !== 'boolean') {
    dimensions = dimensionFillHeightOnlyMarginTop(item, dimensions, marginTop);
  } else if (typeof (marginBottom) !== 'boolean') {
    dimensions = dimensionFillHeightOnlyMarginBottom(item, dimensions, marginBottom);
  } else {
    dimensions = dimensionFillHeightNoVariables(item, dimensions);
  }
  return dimensions;
}

export function dimensionFillHeightOnlyMarginTop (item: TypeItemProperties, dimensions: string,
  marginTop: string): string {
  if ('margins' in item) {
    const definedBottomValues = (item.margins.bottom?.value ?? 0) !== 0 ||
      (item.margins.bottom?.unit !== UNIT_PX);
    const bottomValues = (item.margins.bottom?.value ?? 0) + (item.margins.bottom?.unit ?? UNIT_PX);
    if (!definedBottomValues) {
      dimensions += CALC_FILL + VAR_OPENING + marginTop + TWO_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    } else {
      dimensions += CALC_FILL_MARGINS + bottomValues + SPACE_PLUS + VAR_OPENING + marginTop +
      THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return dimensions;
}

export function dimensionFillHeightOnlyMarginBottom (item: TypeItemProperties, dimensions: string,
  marginBottom: string): string {
  if ('margins' in item) {
    const definedTopValues = (item.margins.top?.value ?? 0) !== 0 ||
      (item.margins.top?.unit !== UNIT_PX);
    const topValues = (item.margins.top?.value ?? 0) + (item.margins.top?.unit ?? UNIT_PX);
    if (!definedTopValues) {
      dimensions += CALC_FILL + VAR_OPENING + marginBottom + TWO_CLOSING_PARENTESIS +
      SEMICOLON_LINE_JUMP;
    } else {
      dimensions += CALC_FILL_MARGINS + topValues + SPACE_PLUS + VAR_OPENING + marginBottom +
      THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return dimensions;
}

export function getDimensionFillHeightNoVariablesValues (dimensions: string, definedTopValues: boolean,
  definedBottomValues: boolean, topValues: string, bottomValues: string): string {
  if (!definedTopValues && !definedBottomValues) {
    dimensions += FILL_VALUE + SEMICOLON_LINE_JUMP;
  } else if (definedTopValues && definedBottomValues) {
    dimensions += CALC_FILL_MARGINS + topValues + SPACE_PLUS + bottomValues + TWO_CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP;
  } else if (definedTopValues) {
    dimensions += CALC_FILL + topValues + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (definedBottomValues) {
    dimensions += CALC_FILL + bottomValues + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  return dimensions;
}

export function dimensionFillHeightNoVariables (item: TypeItemProperties, dimensions: string): string {
  if ('margins' in item) {
    const definedTopValues = (item.margins.top?.value ?? 0) !== 0 ||
      (item.margins.top?.unit !== UNIT_PX);
    const topValues = (item.margins.top?.value ?? 0) + (item.margins.top?.unit ?? UNIT_PX);
    const definedBottomValues = (item.margins.bottom?.value ?? 0) !== 0 ||
      (item.margins.bottom?.unit !== UNIT_PX);
    const bottomValues = (item.margins.bottom?.value ?? 0) + (item.margins.bottom?.unit ?? UNIT_PX);
    dimensions = getDimensionFillHeightNoVariablesValues(dimensions, definedTopValues, definedBottomValues,
      topValues, bottomValues);
  }
  return dimensions;
}

export function dimensionFillWidthStyle (item: TypeItemProperties, dimensions: string): string {
  const marginLeft = referenceValue(MARGIN_LEFT, item, false);
  const marginRight = referenceValue(MARGIN_RIGHT, item, false);
  if (marginLeft !== false && marginRight !== false) {
    dimensions += CALC_FILL_MARGINS + VAR_OPENING + marginLeft + CLOSING_PARENTESIS + SPACE_PLUS +
    VAR_OPENING + marginRight + THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (typeof (marginLeft) !== 'boolean') {
    dimensions = dimensionFillWidthOnlyMarginLeft(item, dimensions, marginLeft);
  } else if (typeof (marginRight) !== 'boolean') {
    dimensions = dimensionFillWidthOnlyMarginRight(item, dimensions, marginRight);
  } else {
    dimensions = dimensionFillWidthNoVariables(item, dimensions);
  }
  return dimensions;
}

export function dimensionFillWidthOnlyMarginLeft (item: TypeItemProperties, dimensions: string,
  marginLeft: string): string {
  if ('margins' in item) {
    const definedRightValues = (item.margins.right?.value ?? 0) !== 0 ||
      (item.margins.right?.unit !== UNIT_PX);
    const rightValues = (item.margins.right?.value ?? 0) + (item.margins.right?.unit ?? UNIT_PX);
    if (!definedRightValues) {
      dimensions += CALC_FILL + VAR_OPENING + marginLeft + TWO_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    } else {
      dimensions += CALC_FILL_MARGINS + rightValues + SPACE_PLUS + VAR_OPENING + marginLeft +
      THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return dimensions;
}

export function dimensionFillWidthOnlyMarginRight (item: TypeItemProperties, dimensions: string,
  marginRight: string): string {
  if ('margins' in item) {
    const definedLeftValues = (item.margins.left?.value ?? 0) !== 0 ||
        (item.margins.left?.unit !== UNIT_PX);
    const leftValues = (item.margins.left?.value ?? 0) + (item.margins.left?.unit ?? UNIT_PX);
    if (!definedLeftValues) {
      dimensions += CALC_FILL + VAR_OPENING + marginRight + TWO_CLOSING_PARENTESIS +
      SEMICOLON_LINE_JUMP;
    } else {
      dimensions += CALC_FILL_MARGINS + leftValues + SPACE_PLUS + VAR_OPENING + marginRight +
      THREE_CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  }
  return dimensions;
}

export function getDimensionFillWidthNoVariablesValues (dimensions: string, definedLeftValues: boolean,
  definedRightValues: boolean, leftValues: string, rightValues: string): string {
  if (!definedLeftValues && !definedRightValues) {
    dimensions += FILL_VALUE + SEMICOLON_LINE_JUMP;
  } else if (definedLeftValues && definedRightValues) {
    dimensions += CALC_FILL_MARGINS + leftValues + SPACE_PLUS + rightValues + TWO_CLOSING_PARENTESIS +
    SEMICOLON_LINE_JUMP;
  } else if (definedLeftValues) {
    dimensions += CALC_FILL + leftValues + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  } else if (definedRightValues) {
    dimensions += CALC_FILL + rightValues + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  return dimensions;
}

export function dimensionFillWidthNoVariables (item: TypeItemProperties, dimensions: string): string {
  if ('margins' in item) {
    const definedLeftValues = (item.margins.left?.value ?? 0) !== 0 ||
        (item.margins.left?.unit !== UNIT_PX);
    const leftValues = (item.margins.left?.value ?? 0) + (item.margins.left?.unit ?? UNIT_PX);
    const definedRightValues = (item.margins.right?.value ?? 0) !== 0 ||
      (item.margins.right?.unit !== UNIT_PX);
    const rightValues = (item.margins.right?.value ?? 0) + (item.margins.right?.unit ?? UNIT_PX);
    dimensions = getDimensionFillWidthNoVariablesValues(dimensions, definedLeftValues,
      definedRightValues, leftValues, rightValues);
  }
  return dimensions;
}

export function verifyDefaultImageView (dimensions: string, dimension: string): string {
  if (dimension === WIDTH) {
    const defaultWidth = WIDTH + TWO_POINTS_SPACE + '115' + UNIT_PX;
    if (dimensions.includes(defaultWidth)) {
      dimensions = dimensions.replace(defaultWidth, '');
    }
    const defaultMin = MIN_MINUS + WIDTH + TWO_POINTS_SPACE + '115' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  if (dimension === HEIGHT) {
    const defaultHeight = HEIGHT + TWO_POINTS_SPACE + '90' + UNIT_PX;
    if (dimensions.includes(defaultHeight)) {
      dimensions = dimensions.replace(defaultHeight, '');
    }
    const defaultMin = MIN_MINUS + HEIGHT + TWO_POINTS_SPACE + '90' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  return dimensions;
}

export function verifyDefaultText (dimensions: string, dimension: string): string {
  if (dimension === WIDTH) {
    const defaultWidth = WIDTH + TWO_POINTS_SPACE + FIT_CONTENT + UNIT_PX;
    if (dimensions.includes(defaultWidth)) {
      dimensions = dimensions.replace(defaultWidth, '');
    }
    const defaultMin = MIN_MINUS + WIDTH + TWO_POINTS_SPACE + '55' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  if (dimension === HEIGHT) {
    const defaultHeight = HEIGHT + TWO_POINTS_SPACE + FIT_CONTENT + UNIT_PX;
    if (dimensions.includes(defaultHeight)) {
      dimensions = dimensions.replace(defaultHeight, '');
    }
    const defaultMin = MIN_MINUS + HEIGHT + TWO_POINTS_SPACE + '55' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  return dimensions;
}

export function verifyDefaultIcon (dimensions: string, dimension: string): string {
  if (dimension === WIDTH) {
    const defaultWidth = WIDTH + TWO_POINTS_SPACE + '30' + UNIT_PX;
    if (dimensions.includes(defaultWidth)) {
      dimensions = dimensions.replace(defaultWidth, '');
    }
    const defaultMin = MIN_MINUS + WIDTH + TWO_POINTS_SPACE + '30' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  if (dimension === HEIGHT) {
    const defaultHeight = HEIGHT + TWO_POINTS_SPACE + '30' + UNIT_PX;
    if (dimensions.includes(defaultHeight)) {
      dimensions = dimensions.replace(defaultHeight, '');
    }
    const defaultMin = MIN_MINUS + HEIGHT + TWO_POINTS_SPACE + '30' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  return dimensions;
}

export function verifyDefaultLayer (dimensions: string, dimension: string): string {
  if (dimension === WIDTH) {
    const defaultWidth = WIDTH + TWO_POINTS_SPACE + '100' + UNIT_PX;
    if (dimensions.includes(defaultWidth)) {
      dimensions = dimensions.replace(defaultWidth, '');
    }
    const defaultMin = MIN_MINUS + WIDTH + TWO_POINTS_SPACE + '100' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  if (dimension === HEIGHT) {
    const defaultHeight = HEIGHT + TWO_POINTS_SPACE + '100' + UNIT_PX;
    if (dimensions.includes(defaultHeight)) {
      dimensions = dimensions.replace(defaultHeight, '');
    }
    const defaultMin = MIN_MINUS + HEIGHT + TWO_POINTS_SPACE + '100' + UNIT_PX;
    if (dimensions.includes(defaultMin)) {
      dimensions = dimensions.replace(defaultMin, '');
    }
  }
  return dimensions;
}

export function verifyDefaultDimensionsValues (item: TypeItemProperties, dimension: string,
  dimensions: string): string {
  if (item.commonKind === IMAGE_VIEW) dimensions = verifyDefaultImageView(dimensions, dimension);
  else if (item.commonKind === TEXT) dimensions = verifyDefaultText(dimensions, dimension);
  else if (item.commonKind === ICON) dimensions = verifyDefaultIcon(dimensions, dimension);
  else dimensions = verifyDefaultLayer(dimensions, dimension);
  return dimensions;
}

export function defaultGetDimension (dimensions: string, attributeValue: string,
  values: Array<string | number>, dictionary: TypeDictionary, item: TypeItemProperties,
  creatingComponent: boolean): string {
  const defaultSizeValue = attributeValue + values[0] + dictionary[values[1] as keyof typeof dictionary];
  dimensions += defaultSizeValue + dictionary.dimensionClosing;
  const varMin = referenceValue(MIN_MINUS + attributeValue.replace(TWO_POINTS, ''), item, creatingComponent);
  if (varMin !== false) {
    dimensions += MIN_MINUS + attributeValue + ' ' + VAR_OPENING + varMin + CLOSING_PARENTESIS +
      dictionary.dimensionClosing;
  } else dimensions += MIN_MINUS + defaultSizeValue + dictionary.dimensionClosing;
  const varMax = referenceValue('max-' + attributeValue.replace(TWO_POINTS, ''), item, creatingComponent);
  if (varMax !== false) {
    dimensions += 'max-' + attributeValue + ' ' + VAR_OPENING + varMax + CLOSING_PARENTESIS +
      dictionary.dimensionClosing;
  }
  return dimensions;
}

export function getDimensionKind (dimensions: string, item: TypeItemProperties, dimension: string,
  parent: TypeItemProperties, dictionary: TypeDictionary, attributeValue: string,
  values: Array<string | number>, creatingComponent: boolean): string {
  if (values[2] === WRAP) {
    dimensions = dimensionWrapStyle(item, dimension, dimensions, parent, dictionary, attributeValue,
      creatingComponent);
  } else if (values[2] === FIX) {
    dimensions = dimensionFixStyle(item, dimension, dimensions, dictionary, attributeValue,
      [typeof (values[0]) === 'number' ? values[0] : 0, values[1].toString()], creatingComponent);
  } else {
    dimensions = dimensionFillStyle(item, dimension, dimensions, dictionary, creatingComponent);
  }
  return dimensions;
}

export function getDimension (dimension: string, item: TypeItemProperties, parent: TypeItemProperties,
  dictionary: TypeDictionary, componentModeActive: boolean, neededInfo: ReactInfo): string {
  let dimensions = '';
  const values = initDefaultDimensionValues(item, dimension, componentModeActive);
  const attributeValue = item.commonKind === IMAGE_VIEW
    ? dictionary[(dimension + IMAGE) as keyof typeof dictionary]
    : dictionary[dimension as keyof typeof dictionary];
  let constPropety;
  if (dimension === WIDTH) constPropety = WIDTH in item ? item.width : null;
  else constPropety = HEIGHT in item ? item.height : null;
  if (constPropety == null) return '';
  if (constPropety.kind != null) {
    values.push(constPropety.kind);
    dimensions = getDimensionKind(dimensions, item, dimension, parent, dictionary, attributeValue, values,
      neededInfo.creatingComponent);
  } else {
    dimensions = defaultGetDimension(dimensions, attributeValue, values, dictionary, item,
      neededInfo.creatingComponent);
  }
  const isClass = neededInfo.variableListInfo == null
    ? false
    : (dimension === 'height'
        ? neededInfo.variableListInfo.normal.height != null
        : neededInfo.variableListInfo.normal.width != null);
  if (!isClass) dimensions = verifyDefaultDimensionsValues(item, dimension, dimensions);
  return dimensions;
}

export function dimensions (item: TypeItemProperties, parent: TypeItemProperties, dictionary: TypeDictionary,
  componentModeActive: boolean, neededInfo: ReactInfo): string {
  let dimensions = '';
  if (referenceValue(VALUE_HEIGHT, item, false) === false && 'height' in item && item.height != null) {
    dimensions += LINE_JUMP + getDimension(HEIGHT, item, parent, dictionary, componentModeActive, neededInfo);
  }
  if (referenceValue(VALUE_WIDTH, item, false) === false && 'width' in item && item.width != null) {
    dimensions += LINE_JUMP + getDimension(WIDTH, item, parent, dictionary, componentModeActive, neededInfo);
  }
  if (item.commonKind === ICON &&
        referenceValue(VALUE_HEIGHT, item, false) === false &&
        referenceValue(VALUE_WIDTH, item, false) === false && 'height' in item) {
    const unit = item.height.data?.unit ?? UNIT_PX;
    if (!(unit === UNIT_PX && item.height.data?.value === 30)) {
      let mVal = (item.height.data?.value ?? 0) + dictionary[unit as keyof typeof dictionary] +
        dictionary.dimensionClosing;
      if (mVal === '0px;') mVal = item.fontSize.value + item.fontSize.unit + dictionary.dimensionClosing;
      dimensions += ' ' + dictionary.fontSize + mVal;
    }
  } else if (item.commonKind === ICON) {
    dimensions += LINE_JUMP + FONT_SIZE_CSS + VAR_DEFINITION + referenceValue(VALUE_HEIGHT, item, false) +
     CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  return dimensions;
}
