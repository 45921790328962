import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  AGUA,
  BASE_IMAGE,
  BRACKET_NAME,
  BRACKET_SRC,
  CLOSE_BRACKET,
  COMPONENTS,
  CONTENT,
  CONTENT_RESPONSIVE,
  DEFAULT_IMAGE,
  DEFAULT_THEME,
  DESKTOP,
  DOUBLE_PATH,
  FROM,
  IMAGE_BRACKET,
  IMAGE_DEFAULT_VAL,
  IMPORT_IMAGE,
  LAYOUTS,
  LINE_JUMP,
  MAX_WIDTH,
  MEDIA_DESKTOP,
  MEDIA_PHONE,
  MEDIA_TABLET,
  MINUS,
  MIN_WIDTH,
  PAGES,
  PATH,
  PHONE,
  PICTURE_CLOSE,
  PICTURE_OPEN,
  RESPONSIVENESS_THEME,
  SAME_PATH,
  SEMICOLON,
  SLASH,
  SOURCE_CLOSE,
  SOURCE_MEDIA,
  SOURCE_OPEN,
  SRC,
  TABLET,
  TRIPLE_PATH,
  UNDERSCORE,
  VARIABLE_CONTENT
} from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { isComponentALayout } from '../../../reactComponentMethods/reactComponentUtils';
import { type ReactInfo } from '@globalTypes/conversor';
import { referenceValue } from '../reactItemUtils';

export function getOriginPath (origin: string, creatingComponent: boolean,
  componentsUsed: Record<string, string[]>): string {
  const valOrigin = origin.substring(0, 1).toLowerCase() + origin.substring(1);
  if (creatingComponent) {
    if (isComponentALayout(origin, componentsUsed)) {
      return LAYOUTS + SLASH + valOrigin;
    } else {
      return COMPONENTS + SLASH + valOrigin;
    }
  } else return PAGES + SLASH + valOrigin;
}

export function handlePath (path: string, from: string, isPart: boolean[], info: string[]): string {
  let newPath = path;
  if (isPart[0]) {
    newPath = TRIPLE_PATH + path;
  } else {
    let route = DOUBLE_PATH;
    let j = 0;
    if (info[j] === AGUA) {
      newPath = path.replace(AGUA + SLASH, '');
      j++;
    }
    if (isPart[j]) {
      if (from.startsWith(info[j])) {
        route = PATH;
        newPath = newPath.replace(info[j] + SLASH, '');
        j++;
        if (isPart[j] != null && path.includes(from)) {
          newPath = newPath.replace(info[j] + SLASH, '');
          route = SAME_PATH;
        }
      }
    }
    newPath = route + newPath;
  }
  return newPath;
}

export function adaptImagePath (path: string, origin: string, creatingComponent: boolean,
  componentsUsed: Record<string, string[]>, differentArchives: any): string {
  const from = getOriginPath(origin, creatingComponent, componentsUsed);
  const info = path.split(SLASH);
  if (info.length === 1) {
    path = TRIPLE_PATH + path;
  } else {
    const carpetas = JSON.stringify(differentArchives);
    const isPart = [];
    for (let i = 0; i < info.length; i++) {
      isPart[i] = carpetas.includes(info[i]);
    }
    path = handlePath(path, from, isPart, info);
  }
  return path;
}

export function handleImagePreviewSrc (neededInfo: ReactInfo, defaultImage: string, item: TypeItemProperties,
  src: string): string {
  if ('src' in item) {
    const iSrc = item.src;
    if (neededInfo.pageImage[neededInfo.pageName] == null) neededInfo.pageImage[neededInfo.pageName] = '';
    const mVal = defaultImage === ''
      ? '"' + BASE_IMAGE + neededInfo.files[iSrc] + '"'
      : defaultImage;
    src = src.replace(BRACKET_SRC, mVal);
    return src;
  } else return src.replace(BRACKET_SRC, defaultImage);
}

export function handleImageFileSrc (neededInfo: ReactInfo, defaultImage: string, item: TypeItemProperties,
  src: string): string {
  if ('src' in item) {
    if (neededInfo.pageImage[neededInfo.pageName] == null) neededInfo.pageImage[neededInfo.pageName] = '';
    const iSrc = item.src;
    const adaptedPath = adaptImagePath(neededInfo.files[iSrc], neededInfo.pageName,
      neededInfo.creatingComponent, neededInfo.componentsUsed, neededInfo.differentArchives);
    const mVal = defaultImage === '' ? '"' + adaptedPath + '"' : defaultImage;
    neededInfo.pageImage[neededInfo.pageName] += IMPORT_IMAGE + item.name.replaceAll(MINUS, UNDERSCORE) +
    FROM + mVal + SEMICOLON;
    src = src.replace(BRACKET_SRC, IMAGE_BRACKET + item.name.replaceAll(MINUS, UNDERSCORE) + CLOSE_BRACKET);
    return src;
  } else return src.replace(BRACKET_SRC, '');
}

export function handleImageSrc (src: string, item: TypeItemProperties, neededInfo: ReactInfo): string {
  if (referenceValue(SRC, item, false) !== false) src = VARIABLE_CONTENT;
  else if ('src' in item) {
    const defaultImage = item.src === IMAGE_DEFAULT_VAL ? '"' + DEFAULT_IMAGE + '"' : '';
    const iSrc = item.src;
    if (neededInfo.files[iSrc] == null) {
      src = src.replace(BRACKET_SRC, '"' + DEFAULT_IMAGE + '"');
    } else if (neededInfo.isPreview) {
      src = handleImagePreviewSrc(neededInfo, defaultImage, item, src);
    } else if (item.src === IMAGE_DEFAULT_VAL) src = src.replace(BRACKET_SRC, defaultImage);
    else {
      src = handleImageFileSrc(neededInfo, defaultImage, item, src);
    }
  }
  return src;
}

export function handleResponsiveImageLink (device: string, theme: string, isReference: string,
  neededInfo: ReactInfo, item: TypeItemProperties): string {
  let link = '';
  const kdevice = device as keyof typeof neededInfo.responsiveValues;
  const variable = neededInfo.responsiveValues[kdevice][theme][isReference];
  if (!('asset' in variable)) return '';
  const value = variable.value;
  const image = value[1];
  if (neededInfo.isPreview) {
    link = '"' + BASE_IMAGE +
      neededInfo.files[image.toString()] + '"';
  } else {
    if (neededInfo.pageImage[neededInfo.pageName] == null) neededInfo.pageImage[neededInfo.pageName] = '';
    const resPath = adaptImagePath(neededInfo.files[image], neededInfo.pageName,
      neededInfo.creatingComponent, neededInfo.componentsUsed, neededInfo.differentArchives);
    const mVal = IMPORT_IMAGE + item.name.replaceAll(MINUS, UNDERSCORE) + UNDERSCORE + device + FROM +
      ('"' + resPath + '"') + SEMICOLON;
    neededInfo.pageImage[neededInfo.pageName] += mVal;
    link = IMAGE_BRACKET + item.name.replaceAll(MINUS, UNDERSCORE) + UNDERSCORE + device + ' ' +
      CLOSE_BRACKET;
  }
  return link;
}

export function getImageAttributeContent (isRef: string, neededInfo: ReactInfo,
  item: TypeItemProperties): string {
  let content = PICTURE_OPEN + LINE_JUMP;
  const theme = !isRef.includes(UNDERSCORE) ? DEFAULT_THEME : RESPONSIVENESS_THEME;
  if (neededInfo.responsiveValues.phone[theme][isRef] != null) {
    const link = handleResponsiveImageLink(PHONE, theme, isRef, neededInfo, item);
    let mediaVal = MEDIA_PHONE;
    if (neededInfo.mediaValues != null) {
      mediaVal = MIN_WIDTH + neededInfo.mediaValues.phone.value +
        neededInfo.mediaValues.phone.unit;
    }
    content += SOURCE_OPEN + link + SOURCE_MEDIA + mediaVal + SOURCE_CLOSE + LINE_JUMP;
  }
  if (neededInfo.responsiveValues.tablet[theme][isRef] != null) {
    const link = handleResponsiveImageLink(TABLET, theme, isRef, neededInfo, item);
    let mediaVal = MEDIA_TABLET;
    if (neededInfo.mediaValues != null) {
      mediaVal = MAX_WIDTH + (neededInfo.mediaValues.desktop.value - 1) +
        neededInfo.mediaValues.desktop.unit + ') and (' + MIN_WIDTH + (neededInfo.mediaValues.phone.value +
        neededInfo.mediaValues.phone.unit + 1);
    }
    content += SOURCE_OPEN + link + SOURCE_MEDIA + mediaVal + SOURCE_CLOSE + LINE_JUMP;
  }
  if (neededInfo.responsiveValues.desktop[theme][isRef] != null) {
    const link = handleResponsiveImageLink(DESKTOP, theme, isRef, neededInfo, item);
    let mediaVal = MEDIA_DESKTOP;
    if (neededInfo.mediaValues != null) {
      mediaVal = MIN_WIDTH + neededInfo.mediaValues.desktop.value + neededInfo.mediaValues.desktop.unit;
    }
    content += SOURCE_OPEN + link + SOURCE_MEDIA + mediaVal + SOURCE_CLOSE + LINE_JUMP;
  }
  return content;
}

export function imageAttributes (item: TypeItemProperties, component: string, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  let src = dictionary.baseSrcImage;
  const alt = item.name;
  component = component.replace(BRACKET_NAME, alt);
  src = handleImageSrc(src, item, neededInfo);
  component = component.replace(BRACKET_SRC, src);
  const isRef = referenceValue(SRC, item, false);
  if (typeof (isRef) !== 'boolean') {
    const content = getImageAttributeContent(isRef, neededInfo, item);
    component = component.replace(CONTENT_RESPONSIVE, content) + LINE_JUMP + PICTURE_CLOSE;
  } else component = component.replace(CONTENT_RESPONSIVE, '');
  const mCval = 'content' in item ? item.content.default ?? '' : '';
  const contentVal = isRef !== false ? VARIABLE_CONTENT : mCval;
  return component.replace(CONTENT, contentVal);
}
