import { borderReferencesItem } from './reactReferenceMethods/borderReferenceMethods';
import { alignReferencesItem } from './reactReferenceMethods/alignReferencesMethods';
import { handleMarginReference } from './reactReferenceMethods/marginReferenceMethods';
import { handleOrientationReference } from './reactReferenceMethods/orientationReferenceMethods';
import { handleDimensionReference } from './reactReferenceMethods/dimensionReferenceMethods';
import {
  initShadowVariables,
  createShadowVariableAsCSS,
  createShadowVariableAsProps
} from './reactReferenceMethods/shadowReferencesMethods';
import {
  ALIGNMENT,
  BACKGROUND,
  BACKGROUND_COLOR,
  BACKGROUND_IMAGE,
  BACKGROUND_IMAGE_ATTRIBUTES,
  BACKGROUND_IMAGE_CSS,
  BASE_IMAGE,
  BORDER,
  BORDERRADIUS,
  BRACKET,
  CLOSE_BRACKET,
  CLOSING_PARENTESIS,
  COLOR,
  COMMA,
  DEFAULT_THEME,
  DISPLAY,
  EQUAL_BRACKET,
  EQUAL_QUOTE,
  FLEX,
  FLEX_WRAP,
  FONT_STYLE,
  FONT_WEIGHT,
  ICON,
  ICON_COLOR,
  IMAGE_BRACKET,
  LAYER,
  LINE_JUMP,
  MARGIN,
  MINUS,
  MINUS_STYLE,
  NONE,
  ORIENTATION,
  PADDING,
  POSITION,
  PROP_DECLARATION,
  RADIUS,
  RESPONSIVENESS_THEME,
  SEMICOLON,
  SEMICOLON_LINE_JUMP,
  SHADOW,
  SRC,
  STYLE_CLOSE,
  STYLE_OPEN,
  TERNARY_CLOSE,
  TERNARY_OPEN,
  TEXT,
  TWO_POINTS_SPACE,
  UNDERSCORE,
  UNSET,
  VALUE,
  VAR_DEFINITION,
  VAR_OPENING,
  VISIBLE,
  WEIGHT,
  WRAP
} from '../../../constants/constants';
import {
  type TypeReactAttributeReferences,
  type ReactInfo,
  type TypeVerifications,
  type TypeRefItemInfo,
  type TypeReactAttribute,
  type TypeReferenceVerifications,
  type IComponentinfo
} from '@globalTypes/conversor';

export function isResponsiveVariable (variableName: string, referenceItem: TypeReactAttribute,
  viewDOM: IComponentinfo): boolean {
  if (variableName.includes(UNDERSCORE)) {
    if ((variableName.includes(MARGIN) ||
        variableName.includes(PADDING)) &&
        !variableName.includes(MINUS)) {
      variableName = variableName + MINUS + referenceItem[0].split(MINUS)[1];
    }
    const theme = referenceItem[2];
    if (theme !== DEFAULT_THEME && theme !== RESPONSIVENESS_THEME) {
      const componentName = theme;
      const component = viewDOM.getComponentByName(componentName, viewDOM.components);
      if (component == null) {
        window.logger.warn('component ' + componentName + ' not found');
        return false;
      }
      if (component.references != null) {
        const cRef = component.references.variables;
        if (cRef[variableName] != null) {
          const mVal = cRef[variableName].type;
          if (mVal === RESPONSIVENESS_THEME) return true;
        }
      }
    }
  }
  return false;
}

export function handleVariableContentSrc (neededInfo: ReactInfo, variableName: string): void {
  if (neededInfo.item == null) return;
  neededInfo.variableContent[neededInfo.item.id] = SRC + EQUAL_BRACKET + variableName + CLOSE_BRACKET;
  if (!neededInfo.creatingComponent) {
    if (neededInfo.isPreview && 'src' in neededInfo.item) {
      const iSrc = neededInfo.item.src;
      neededInfo.variableContent[neededInfo.item.id] =
          SRC + EQUAL_QUOTE + BASE_IMAGE + neededInfo.files[iSrc] + '"';
    } else {
      neededInfo.variableContent[neededInfo.item.id] =
          SRC + IMAGE_BRACKET + neededInfo.item.name.replaceAll(MINUS, UNDERSCORE) + ' ' + CLOSE_BRACKET;
    }
  }
}

export function handleVariableContentTextIcon (referenceItem: TypeReactAttribute, neededInfo: ReactInfo,
  variableName: string): void {
  if (neededInfo.item == null) return;
  let defaultValue = '';
  if (referenceItem[0] === TEXT && 'content' in neededInfo.item) {
    defaultValue = neededInfo.item.content.default;
  } else if (referenceItem[0] === ICON && 'icon' in neededInfo.item) {
    const iVal = neededInfo.item.icon;
    defaultValue = iVal;
  }
  neededInfo.variableContent[neededInfo.item.id] = BRACKET + variableName + CLOSE_BRACKET;
  if (!neededInfo.creatingComponent) neededInfo.variableContent[neededInfo.item.id] = defaultValue;
  const isResponsive = variableName.includes(UNDERSCORE);
  if (isResponsive) neededInfo.variableContent[neededInfo.item.id] = '';
}

export function handleVariableContent (neededInfo: ReactInfo, referenceItem: TypeReactAttribute,
  responsiveVariable: boolean, refItemInfo: TypeRefItemInfo, variableName: string): void {
  if (referenceItem[0] === SRC) handleVariableContentSrc(neededInfo, variableName);
  else handleVariableContentTextIcon(referenceItem, neededInfo, variableName);
  if (!neededInfo.creatingComponent || !responsiveVariable) {
    refItemInfo.referenceList.push(variableName);
  }
}

export function handleMoreGeneralNoMayusBackImage (refItemInfo: TypeRefItemInfo,
  ver: TypeVerifications): void {
  refItemInfo.location = BACKGROUND_IMAGE_CSS;
  if (ver.creatingCompRes) {
    refItemInfo.referenceString += BACKGROUND_IMAGE_ATTRIBUTES;
  }
  if (ver.inGroupVerification ?? false) {
    refItemInfo.referenceString += LINE_JUMP + BACKGROUND + TWO_POINTS_SPACE + UNSET + SEMICOLON;
  }
}

export function handleMoreGeneralNoMayusVer (ver: TypeVerifications, refItemInfo: TypeRefItemInfo,
  variableName: string): void {
  if (ver.groupVerification) {
    refItemInfo.referenceString += refItemInfo.location + VAR_DEFINITION +
        variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
  if (ver.noAddVerification) refItemInfo.referenceList.push(variableName);
}

export function handleMoreGeneralNoMayus (referenceItem: TypeReactAttribute, refItemInfo: TypeRefItemInfo,
  neededInfo: ReactInfo, mayusLocation: RegExpMatchArray, variableName: string,
  ver: TypeVerifications): void {
  if (neededInfo.item == null) return;
  refItemInfo.location = referenceItem[0].replace(mayusLocation[0], MINUS + mayusLocation[0].toLowerCase());
  const bakgroundImageVer = (ver.inGroupVerification ?? false) && neededInfo.item.commonKind === LAYER;
  if (referenceItem[0] === BACKGROUND_COLOR &&
    neededInfo.item.commonKind === LAYER && BACKGROUND in neededInfo.item &&
    neededInfo.item.background.kind === BACKGROUND_IMAGE) {
    handleMoreGeneralNoMayusBackImage(refItemInfo, ver);
  } else if (((referenceItem[0] === BACKGROUND_COLOR &&
        (neededInfo.item.commonKind === TEXT ||
        neededInfo.item.commonKind === ICON)) ||
        referenceItem[0] === ICON_COLOR) || referenceItem[0] === BACKGROUND_COLOR) {
    refItemInfo.location = BACKGROUND;
    if (bakgroundImageVer) {
      refItemInfo.referenceString += LINE_JUMP + BACKGROUND_IMAGE_CSS + TWO_POINTS_SPACE + UNSET + SEMICOLON;
    }
  }
  handleMoreGeneralNoMayusVer(ver, refItemInfo, variableName);
}

export function addMayusAsProps (refItemInfo: TypeRefItemInfo, neededInfo: ReactInfo,
  referenceItem: TypeReactAttribute, responsiveVariable: boolean, variableName: string): string {
  if (neededInfo.item == null) return '';
  if (refItemInfo.location == null) refItemInfo.location = '';
  if ((refItemInfo.location === BACKGROUND_COLOR && neededInfo.item.commonKind === TEXT) ||
        refItemInfo.location === ICON_COLOR) {
    refItemInfo.location = COLOR;
  } else if (refItemInfo.location.includes(RADIUS)) {
    refItemInfo.location = BORDERRADIUS;
  }
  if (refItemInfo.location?.includes(MINUS)) {
    const infoLocation = refItemInfo.location.split(MINUS);
    refItemInfo.location = infoLocation[0] +
          infoLocation[1].substring(0, 1).toUpperCase() +
          infoLocation[1].substring(1);
  }
  if (!referenceItem[0].includes(SHADOW) && !responsiveVariable) {
    const mVal = refItemInfo.location === '' ? variableName : refItemInfo.location;
    return COMMA + ' ' + mVal + TWO_POINTS_SPACE + variableName;
  } else return '';
}

export function handleVariableMayus (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  referenceItem: TypeReactAttribute, verify: TypeReferenceVerifications, variableName: string,
  mayusLocation: RegExpMatchArray): void {
  refItemInfo.location = '';
  const noAddVerification = !neededInfo.creatingComponent || !verify.responsiveVariable;
  const groupVerification = !verify.groupVariable && verify.creatingCompRes;
  const inGroupVerification = !verify.groupVariable && verify.creatingCompRes;
  const ver = {
    noAddVerification,
    groupVerification,
    inGroupVerification,
    responsiveVariable: verify.responsiveVariable,
    creatingCompRes: verify.creatingCompRes
  };
  if (referenceItem[0].includes(BORDER) || referenceItem[0].includes(RADIUS)) {
    borderReferencesItem(neededInfo, referenceItem, ver, refItemInfo, variableName);
  } else if (referenceItem[0].includes(SHADOW)) {
    refItemInfo.shadowVariable = true;
    refItemInfo.shadowVariables[referenceItem[0] as keyof typeof refItemInfo.shadowVariables] =
        [VAR_OPENING + variableName + CLOSING_PARENTESIS, variableName, verify.creatingCompRes];
    if (noAddVerification) refItemInfo.referenceList.push(variableName);
  } else if (referenceItem[0].includes(ALIGNMENT)) {
    alignReferencesItem(neededInfo, referenceItem, refItemInfo, variableName, ver);
  } else {
    handleMoreGeneralNoMayus(referenceItem, refItemInfo, neededInfo, mayusLocation, variableName, ver);
  }
  refItemInfo.referenceAsProps +=
      addMayusAsProps(refItemInfo, neededInfo, referenceItem, verify.responsiveVariable, variableName);
}

export function adpatLocationPaddingOrPosition (location: string): string {
  const val = location.split(MINUS);
  const mVal = val[0] + val[1].substring(0, 1).toUpperCase() + val[1].substring(1);
  return mVal;
}

export function addVariableMayusOther (groupVerification: boolean, refItemInfo: TypeRefItemInfo,
  referenceItem: TypeReactAttribute, variableName: string): void {
  if (groupVerification) {
    refItemInfo.referenceString += referenceItem[0] + VAR_DEFINITION + variableName +
    CLOSING_PARENTESIS + SEMICOLON;
  }
}

export function handleVariableMayusOtherVer (refItemInfo: TypeRefItemInfo, referenceItem: TypeReactAttribute,
  variableName: string, ver: TypeVerifications): void {
  if (refItemInfo.location === '') refItemInfo.location = referenceItem[0];
  if (ver.noAddVerification) refItemInfo.referenceList.push(variableName);
  if (!ver.responsiveVariable) {
    refItemInfo.referenceAsProps += COMMA + ' ' + refItemInfo.location + TWO_POINTS_SPACE + variableName;
  }
  if (!ver.responsiveVariable && refItemInfo.location === DISPLAY) {
    refItemInfo.referenceAsProps += TERNARY_OPEN + FLEX + TERNARY_CLOSE + NONE + '"';
  }
}

export function handleVariableMayusOther (referenceItem: TypeReactAttribute, refItemInfo: TypeRefItemInfo,
  variableName: string, ver: TypeVerifications): void {
  if (referenceItem[0].includes(PADDING) || referenceItem[0].includes(POSITION)) {
    addVariableMayusOther(ver.groupVerification, refItemInfo, referenceItem, variableName);
    refItemInfo.location = adpatLocationPaddingOrPosition(referenceItem[0]);
  } else if (referenceItem[0].includes(VISIBLE)) {
    if (ver.groupVerification) {
      refItemInfo.referenceString += DISPLAY + VAR_DEFINITION + variableName +
        CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
    refItemInfo.location = DISPLAY;
  } else if (referenceItem[0].includes(WRAP)) {
    if (ver.groupVerification) {
      refItemInfo.referenceString += FLEX_WRAP + VAR_DEFINITION + variableName +
        CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  } else if (referenceItem[0].includes(WEIGHT)) {
    if (ver.groupVerification) {
      refItemInfo.referenceString += FONT_WEIGHT + VAR_DEFINITION + variableName +
        CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
      refItemInfo.referenceString += FONT_STYLE + VAR_DEFINITION + variableName +
        MINUS_STYLE + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  } else addVariableMayusOther(ver.groupVerification, refItemInfo, referenceItem, variableName);
  handleVariableMayusOtherVer(refItemInfo, referenceItem, variableName, ver);
}

export function handleVariableNoMayus (neededInfo: ReactInfo, refItemInfo: TypeRefItemInfo,
  verify: TypeReferenceVerifications, variableName: string, referenceItem: TypeReactAttribute): void {
  const groupVer = !verify.groupVariable && verify.creatingCompRes;
  const noAddVerification = !neededInfo.creatingComponent || !verify.responsiveVariable;
  const ver = {
    creatingCompRes: verify.creatingCompRes,
    groupVerification: groupVer,
    noAddVerification,
    responsiveVariable: verify.responsiveVariable
  };
  if (referenceItem[0].includes(ORIENTATION)) {
    handleOrientationReference(neededInfo, refItemInfo, variableName, referenceItem, verify.creatingCompRes,
      noAddVerification, noAddVerification);
  } else if (referenceItem[0].includes(VALUE)) {
    handleDimensionReference(neededInfo, refItemInfo, referenceItem, ver, variableName);
  } else if (referenceItem[0].includes(MARGIN) && neededInfo.item != null) {
    handleMarginReference(referenceItem, neededInfo.item, refItemInfo, variableName, groupVer,
      noAddVerification);
    if (!verify.responsiveVariable) {
      refItemInfo.referenceAsProps += COMMA + ' ' + refItemInfo.location + TWO_POINTS_SPACE + variableName;
    }
  } else {
    handleVariableMayusOther(referenceItem, refItemInfo, variableName, ver);
  }
}

export function handleReference (neededInfo: ReactInfo, references: TypeReactAttributeReferences,
  reference: string, refItemInfo: TypeRefItemInfo, viewDOM: IComponentinfo): void {
  if (neededInfo.item == null) return;
  const referenceItem = references[reference];
  const variableName = referenceItem[1];
  const group = neededInfo.item.groupVariables?.[reference] != null;
  const groupVariable = 'groupVariables' in neededInfo.item ? group : false;
  const responsiveVariable = isResponsiveVariable(variableName, referenceItem, viewDOM);
  const creatingCompRes = !neededInfo.creatingComponent ||
    (neededInfo.creatingComponent && responsiveVariable);
  if (referenceItem[3] != null) {
    if (referenceItem[5] === neededInfo.item.id) {
      refItemInfo.referenceString += referenceItem[0] + PROP_DECLARATION + referenceItem[0] + ' ' +
        CLOSE_BRACKET + LINE_JUMP;
    } else {
      refItemInfo.referenceString += referenceItem[0] + TWO_POINTS_SPACE +
        referenceItem[3].replace(UNDERSCORE, MINUS) + SEMICOLON_LINE_JUMP;
    }
  } else {
    const mayusLocation = /[A-Z]/.exec(referenceItem[0]);
    const verify = { responsiveVariable, groupVariable, creatingCompRes };
    if (referenceItem[0] === SRC || referenceItem[0] === TEXT || referenceItem[0] === ICON) {
      handleVariableContent(neededInfo, referenceItem, responsiveVariable, refItemInfo, variableName);
    } else if (mayusLocation != null) {
      handleVariableMayus(neededInfo, refItemInfo, referenceItem, verify, variableName, mayusLocation);
    } else handleVariableNoMayus(neededInfo, refItemInfo, verify, variableName, referenceItem);
  }
}

/**
   * neededInfo {
   *  item: el objeto del item,
   *  name: el nombre del item convertido,
   *  pageName: el nombre de la screen/componente,
   *  creatingComponent: booleano que dice si esta creandose un component,
   *  infoThemes: valores de los temas y sus variables,
   *  variableContent: objeto con el contenido variable (Texto, iconos, imagenes)
   *  fillVariables: cuando hay responsive size y uno es fill
   *  orientationVariables: cuando el orientation es variable
   *  responsiveValues: los valores responsive de las variables
   *  isPreview: si es preview o canvas
   *  objectClassnames: las clases de los items
   * }
   *
   */
export function getReferenceItem (viewDOM: IComponentinfo,
  neededInfo: ReactInfo): [string, string[], string] {
  if (neededInfo.item == null) return ['', [], ''];
  const references = 'references' in neededInfo.item ? neededInfo.item.references : null;
  if (references == null) return ['', [], ''];
  if ('customcode' in neededInfo.item && neededInfo.item.customcode === true) return ['', [], ''];
  const refItemInfo = {
    referenceString: '',
    referenceAsProps: STYLE_OPEN,
    referenceList: [],
    shadowVariable: false,
    shadowVariables: initShadowVariables(neededInfo.item),
  };
  for (const reference in references) {
    handleReference(neededInfo, references, reference, refItemInfo, viewDOM);
  }
  if (refItemInfo.shadowVariable) {
    refItemInfo.referenceString += createShadowVariableAsCSS(refItemInfo.shadowVariables);
    refItemInfo.referenceAsProps += createShadowVariableAsProps(refItemInfo.shadowVariables);
  }
  refItemInfo.referenceAsProps += STYLE_CLOSE;
  refItemInfo.referenceAsProps = refItemInfo.referenceAsProps.replace(COMMA, '');
  refItemInfo.referenceString =
      refItemInfo.referenceString.substring(0, refItemInfo.referenceString.length - 1);
  return [refItemInfo.referenceString, refItemInfo.referenceList, refItemInfo.referenceAsProps];
}
