import {
  DEFAULT_FONT,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  LINE_JUMP,
  MTEXT_ALIGNMENT,
  MTEXT_WEIGHT,
  NORMAL,
  SEMICOLON,
  SLASH,
  TEXT_ALIGN,
  TEXT_WEIGHT_VAR,
  TWO_POINTS_SPACE,
} from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { referenceValue } from '../reactItemUtils';

import { LEADING, SPACING, UNIT_PX } from '@globalTypes/constants';
import { type ReactInfo } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';

export function getTextAlignStyle(item: TypeItemProperties, dictionary: TypeDictionary): string {
  if ('textAlignment' in item && item.textAlignment != null) {
    const key = (item.textAlignment + MTEXT_ALIGNMENT) as keyof typeof dictionary;
    return dictionary[key];
  } else return '';
}

export function getTextFontStyle(
  item: TypeItemProperties,
  dictionary: TypeDictionary,
  files: Record<string, string>,
  proyectFonts: string[]
): string {
  if ('fontFamily' in item && item.fontFamily != null) {
    const altFont = typeof item.fontFamily === 'string' ? item.fontFamily : item.fontFamily[0];
    if (!proyectFonts.includes(altFont)) proyectFonts.push(altFont);
    let name = altFont;
    if (name.includes(SLASH)) name = name.substring(name.lastIndexOf(SLASH) + 1);
    return dictionary.fontOpen + name + dictionary.fontClose;
  }
  return '';
}

export function getTextSizeStyle(item: TypeItemProperties, dictionary: TypeDictionary): string {
  if ('fontSize' in item && item.fontSize != null) {
    const size = item.fontSize.value + item.fontSize.unit;
    return dictionary.fontSize + size + dictionary.textSizeEnding;
  } else return '';
}

export function getTextWeightStyle(
  item: TypeItemProperties,
  dictionary: TypeDictionary,
  neededInfo: ReactInfo
): string {
  let styleText = '';
  const weightClass =
    neededInfo.variableListInfo == null ? false : neededInfo.variableListInfo.normal.weight != null;
  if ('style' in item && item.style != null && (item.style !== NORMAL || weightClass)) {
    const key = (item.style + MTEXT_WEIGHT) as keyof typeof dictionary;
    styleText = dictionary[key];
  }
  if ('weight' in item && item.weight != null) {
    styleText += FONT_WEIGHT + TWO_POINTS_SPACE + item.weight + SEMICOLON;
  }
  return styleText;
}

export function getTextLeadingStyle(item: TypeItemProperties, dictionary: TypeDictionary): string {
  if ('leading' in item && item.leading != null && item.leading !== 0) {
    const size = item.leading;
    return 'line-height: ' + size + UNIT_PX + dictionary.textSizeEnding;
  } else return '';
}

export function getTextSpacingStyle(item: TypeItemProperties, dictionary: TypeDictionary): string {
  if ('spacing' in item && item.spacing != null && item.spacing !== 0) {
    const size = item.spacing;
    return 'letter-spacing: ' + size + UNIT_PX + dictionary.textSizeEnding;
  } else return '';
}

export function getTextStyles(
  item: TypeItemProperties,
  dictionary: TypeDictionary,
  filesId: Record<string, string>,
  proyectFonts: string[],
  neededInfo: ReactInfo
): string {
  let styles = ' ';
  const textAlign = getTextAlignStyle(item, dictionary);
  if (referenceValue(TEXT_ALIGN, item, false) === false) styles += textAlign + LINE_JUMP;
  const textStyle = getTextFontStyle(item, dictionary, filesId, proyectFonts);
  if (referenceValue(FONT_FAMILY, item, false) === false && !textStyle.includes(DEFAULT_FONT)) {
    styles += textStyle + LINE_JUMP;
  }
  const textSize = getTextSizeStyle(item, dictionary);
  if (referenceValue(FONT_SIZE, item, false) === false) styles += textSize + LINE_JUMP;
  const textWeight = getTextWeightStyle(item, dictionary, neededInfo);
  if (referenceValue(TEXT_WEIGHT_VAR, item, false) === false) styles += textWeight + LINE_JUMP;
  const textLeading = getTextLeadingStyle(item, dictionary);
  if (referenceValue(LEADING, item, false) === false) styles += textLeading + LINE_JUMP;
  const textSpacing = getTextSpacingStyle(item, dictionary);
  if (referenceValue(SPACING, item, false) === false) styles += textSpacing + LINE_JUMP;
  return styles;
}
