import {
  ASTERISK,
  A_PARAM,
  CLOSE_BRACKET,
  COMMA,
  CUSTOM_COMPONENT_HTML,
  LESS_THAN,
  LINE_JUMP,
  POINT_PROPTYPE,
  PROP_COMPONENT_INSTANCE,
  STRING,
  SUBCOMPONENT,
  THE,
} from '../../constants/constants';
import { addDependencies } from '../reactFileMethods/reactFileUtils';

import { type IComponentinfo, type ReactInfo, type TypeComponentData } from '@globalTypes/conversor';

export function createCustomComponent(
  componentData: TypeComponentData,
  viewDOM: IComponentinfo,
  neededInfo: ReactInfo
): void {
  const name = componentData.name;
  const componentInfo = viewDOM.getComponentByName(name, viewDOM.components);
  if (componentInfo == null) {
    window.logger.warn('component ' + name + ' not found');
    return;
  }
  const code = componentInfo.code;
  if (code == null || typeof code === 'string') return;
  const fileName = name.substring(0, 1).toLowerCase() + name.substring(1);
  neededInfo.componentCreated[name] = [LESS_THAN + name + CUSTOM_COMPONENT_HTML, ['', '']];
  neededInfo.customCode[fileName] = {};
  for (const file of code.files) neededInfo.customCode[fileName][file.name] = file.content;
  const path = componentData.path;
  neededInfo.componentsImport[name] = { name, path };
  addDependencies(code.dependencies, neededInfo);
  let propType = '';
  let parameters = '';
  for (const parameter of code.parameters) {
    propType += '    ' + parameter.name + POINT_PROPTYPE + STRING + COMMA + LINE_JUMP;
    const paramStr = A_PARAM + STRING + CLOSE_BRACKET;
    const paramNameDocLayout = PROP_COMPONENT_INSTANCE + parameter.name + CLOSE_BRACKET;
    const paramDescriptionLayout = THE + parameter.name + SUBCOMPONENT;
    parameters += ASTERISK + paramStr + ' ' + paramNameDocLayout + ' ' + paramDescriptionLayout + LINE_JUMP;
  }
  neededInfo.componentProptypes[name] = propType;
  neededInfo.componentPropParameters[name] = parameters;
}
