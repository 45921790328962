/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
'use client';

import { Icon } from '../icon';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { type OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types';

import { BasePopup } from '@components/basePopup';

import { getKeyBinding } from '@globalUtils/shortcuts';

import styles from './styles.module.css';

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */

/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */

/* eslint-disable multiline-ternary */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */

const isMac = navigator.userAgent.includes('Mac OS X');
const keyboardShortcutOptions: OptionsOrDependencyArray = {
  enableOnFormTags: ['input'],
};

export interface IOption {
  value: string;
  label: string;
  icon?: JSX.Element;
}
export type IOptions = IOption[];
export interface IMultiSelect {
  headless?: boolean;
  isOpen?: boolean;
  options: IOptions;
  placeholder: string;
  emptyPlaceholder: string;
  searchPlaceholder: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  multiple?: boolean;
  defaultOption?: string | string[];
  notifySelectedValue?: (value: string | string[]) => any;
  ignoreTags?: string[];
  allowUnselected?: boolean;
  offsetY?: number;
  disabled?: boolean;
  preventSelection?: boolean;
  preventRightBorderRadius?: boolean;
}

const optionsAreEqual = (a: IOptions, b: IOptions): boolean => {
  if (a.length !== b.length) return false;
  let same = true;
  a.forEach((opt, index) => {
    if (opt.label !== b[index].label) same = false;
    if (opt.value !== b[index].value) same = false;
  });
  return same;
};

const propsAreEqual = (a: IMultiSelect, b: IMultiSelect): boolean => {
  if (!optionsAreEqual(a.options, b.options)) return false;
  if (a.placeholder !== b.placeholder) return false;
  if (a.emptyPlaceholder !== b.emptyPlaceholder) return false;
  if (a.searchPlaceholder !== b.searchPlaceholder) return false;
  if (a.width !== b.width) return false;
  if (a.minWidth !== b.minWidth) return false;
  if (a.maxWidth !== b.maxWidth) return false;
  if (a.height !== b.height) return false;
  if (a.maxHeight !== b.maxHeight) return false;
  if (a.multiple !== b.multiple) return false;
  if (a.defaultOption !== b.defaultOption) return false;
  return true;
};

export const MultiSelect = React.memo(function MultiSelect({
  isOpen = false,
  headless = false,
  options,
  placeholder,
  emptyPlaceholder,
  searchPlaceholder,
  minWidth = '0',
  maxWidth = 'auto',
  width = 'min(240px, 100%)',
  height = 'var(--button-height)',
  maxHeight = '300px',
  multiple = false,
  defaultOption,
  notifySelectedValue,
  ignoreTags = [],
  allowUnselected = true,
  offsetY = 0,
  disabled = false,
  preventSelection = false,
  preventRightBorderRadius = false,
}: IMultiSelect): React.JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isHeadless, setIsHeadless] = React.useState<boolean>(headless);
  const [value, setValue] = React.useState<string>('');
  const [values, setValues] = React.useState<string[]>([]);
  const [valueDisplay, setValueDisplay] = React.useState<string>('');
  const [valuesDisplay, setValuesDisplay] = React.useState<string[]>([]);
  const [searched, setSearched] = useState<string>('');
  const [activeOption, setActiveOption] = useState<number>(0);
  const [numHeight, setNumHeight] = useState<number>(0);
  const [scrollTop, setScrollTop] = React.useState<boolean>(false);
  const [scrollBottom, setScrollBottom] = React.useState<boolean>(false);
  const [hoveringScrollTop, setHoveringScrollTop] = React.useState<boolean>(false);
  const [hoveringScrollBottom, setHoveringScrollBottom] = React.useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollTopRef = useRef<HTMLDivElement>(null);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useHotkeys(getKeyBinding('arrowDown', isMac), (e) => handleArrowDown(e), keyboardShortcutOptions);
  useHotkeys(getKeyBinding('arrowUp', isMac), (e) => handleArrowUp(e), keyboardShortcutOptions);
  useHotkeys(getKeyBinding('enter', isMac), (e) => handleSelectOption(e), keyboardShortcutOptions);

  useEffect(() => {
    if (isOpen) setOpen(true);
  }, [isOpen]);

  useEffect(() => {
    setIsHeadless(headless);
  }, [headless]);

  useEffect(() => {
    if (defaultOption == null) return;
    if (typeof defaultOption === 'string') {
      const option = options.find((op) => op.value === defaultOption);
      if (option == null) return;
      setValue(option.value);
      setValueDisplay(option.label);
    } else {
      const optionValues: string[] = [];
      const optionDisplays: string[] = [];
      for (const opt of defaultOption) {
        const option = options.find((op) => op.value === opt);
        if (option == null) continue;
        optionValues.push(option.value);
        optionDisplays.push(option.label);
      }
      setValues(optionValues);
      setValuesDisplay(optionDisplays);
    }
  }, [defaultOption, options]);

  useEffect(() => {
    if (!hoveringScrollTop) return;
    const elem = optionsContainerRef.current?.parentNode as HTMLDivElement;
    if (elem == null) return;
    let increment = -1;
    const interval = setInterval(() => {
      const st = elem.scrollTop;
      if (increment !== st) {
        increment = st;
        elem.scrollTo(0, Math.max(st - 1, 0));
      }
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, [hoveringScrollTop]);

  useEffect(() => {
    if (!hoveringScrollBottom) return;
    const elem = optionsContainerRef.current?.parentNode as HTMLDivElement;
    if (elem == null) return;
    let increment = -1;
    const interval = setInterval(() => {
      const st = elem.scrollTop;
      if (increment !== st) {
        increment = st;
        elem.scrollTo(0, st + 1);
      }
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, [hoveringScrollBottom]);

  const handleHoverScrollTopOn = (): void => {
    setHoveringScrollTop(true);
  };

  const handleHoverScrollTopOff = (): void => {
    setHoveringScrollTop(false);
  };

  const handleHoverScrollBottomOn = (): void => {
    setHoveringScrollBottom(true);
  };

  const handleHoverScrollBottomOff = (): void => {
    setHoveringScrollBottom(false);
  };

  useEffect(() => {
    if (!open) return;
    if (!scrollTop) {
      setHoveringScrollTop(false);
      return;
    }
    const container: any = scrollTopRef.current;
    if (container == null) return;
    container.addEventListener('mouseover', handleHoverScrollTopOn);
    container.addEventListener('mouseleave', handleHoverScrollTopOff);
    return () => {
      container.removeEventListener('mouseover', handleHoverScrollTopOn);
      container.removeEventListener('mouseleave', handleHoverScrollTopOff);
    };
  }, [scrollTop, open]);

  useEffect(() => {
    if (!open) return;
    if (!scrollBottom) {
      setHoveringScrollBottom(false);
      return;
    }
    const container: any = scrollBottomRef.current;
    if (container == null) return;
    container.addEventListener('mouseover', handleHoverScrollBottomOn);
    container.addEventListener('mouseleave', handleHoverScrollBottomOff);
    return () => {
      container.removeEventListener('mouseover', handleHoverScrollBottomOn);
      container.removeEventListener('mouseleave', handleHoverScrollBottomOff);
    };
  }, [scrollBottom, open]);

  useEffect(() => {
    const container: any = ref.current;
    if (container == null) return;
    const mHeight = container.children[0].getBoundingClientRect().height;
    setNumHeight(mHeight);
  }, []);

  useEffect(() => {
    if (!open) setActiveOption(0);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    const container: any = optionsContainerRef.current;
    if (container == null) return;
    const elem = container?.parentNode as HTMLDivElement;
    elem.addEventListener('scroll', handleScroll);
    return () => {
      elem.addEventListener('scroll', handleScroll);
    };
  }, [open, scrollTop, scrollBottom]);

  useEffect(() => {
    if (!open) return;
    const container = optionsContainerRef.current as HTMLDivElement;
    const elem = container?.parentNode as HTMLDivElement;
    if (elem == null) return;
    const mHeight = elem.getBoundingClientRect().height;
    const st = elem.scrollTop;
    const bottomVisible = Math.round(elem.scrollHeight - st) > Math.round(mHeight);
    if (scrollBottom !== bottomVisible) setScrollBottom(bottomVisible);
  }, [open]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [open, isHeadless]);

  const handleScroll = (e: Event): void => {
    const elem = e.target as HTMLDivElement;
    if (elem == null) return;
    const mHeight = elem.getBoundingClientRect().height;
    const st = elem.scrollTop;
    const offset = 5;
    const topVisible = st > 0;
    const bottomVisible = Math.round(elem.scrollHeight - st - offset) >= mHeight;
    if (scrollTop !== topVisible) setScrollTop(topVisible);
    if (scrollBottom !== bottomVisible) setScrollBottom(bottomVisible);
  };

  const handleOutsideClick = ({ target }: MouseEvent): void => {
    if (ref.current != null && !ref.current.contains(target as Node)) {
      if (open && !isHeadless) setOpen(false);
    }
  };

  useEffect(() => {
    setActiveOption(0);
  }, [searched]);

  useEffect(() => {
    const container: any = optionsContainerRef.current;
    if (container == null) return;
    const children = container.children;
    for (const child of children) {
      child.addEventListener('mouseover', handleHoverOption);
    }
    return () => {
      for (const child of children) {
        child.removeEventListener('mouseover', handleHoverOption);
      }
    };
  }, [open, activeOption, searched]);

  const handleHoverOption = (e: Event): void => {
    const container: any = optionsContainerRef.current;
    if (container == null) return;
    const children = container.children;
    let elem: any = e.target;
    while (elem?.getAttribute != null && elem.getAttribute('data-option') == null) elem = elem.parentNode;
    let index = -1;
    let counter = 0;
    for (const child of children) {
      if (child === elem) index = counter;
      counter++;
    }
    if (index !== activeOption) setActiveOption(index);
  };

  useEffect(() => {
    if (open && inputRef.current != null) {
      inputRef.current.focus();
      if (!multiple && value === '') return;
      if (multiple && values.length === 0) return;
      const container = optionsContainerRef.current;
      if (container == null) return;
      const children = container.children;
      for (const child of children) {
        const mValue = multiple ? values[0] : value;
        if (child.getAttribute('data-value') === mValue) {
          const parentTop = container.getBoundingClientRect().top;
          const parent = container.parentNode as HTMLDivElement;
          const top = child.getBoundingClientRect().top;
          const scroll = top - parentTop;
          parent.scrollTo(0, scroll);
          const sh = parent.scrollHeight;
          const h = parent.getBoundingClientRect().height;
          if (Math.round(sh - scroll) < Math.round(h)) setScrollBottom(false);
          break;
        }
      }
    }
  }, [open]);

  const handleRemoveValue = (e: any, display: string): void => {
    e.stopPropagation();
    const index = valuesDisplay.indexOf(display);
    if (index === -1) return;
    const copy = [...values];
    copy.splice(index, 1);
    setValues(copy);

    const copyDisplay = [...valuesDisplay];
    copyDisplay.splice(copyDisplay.indexOf(display), 1);
    setValuesDisplay(copyDisplay);

    if (notifySelectedValue != null) notifySelectedValue(copy);
  };

  const handleValueSelected = (option: IOption): void => {
    if (ignoreTags.length > 0 && ignoreTags.includes(option.value)) {
      if (notifySelectedValue != null) notifySelectedValue(option.value);
      setOpen(false);
      setSearched('');
      return;
    }
    let copy;
    let copyDisplay;
    if (!preventSelection) {
      if (multiple) {
        copy = [...values];
        copyDisplay = [...valuesDisplay];
        if (!values.includes(option.value)) {
          copy.push(option.value);
          copyDisplay.push(option.label);
        } else if (allowUnselected) {
          copy.splice(copy.indexOf(option.value), 1);
          copyDisplay.splice(copyDisplay.indexOf(option.label), 1);
        }
        setValues(copy);
        setValuesDisplay(copyDisplay);
      } else {
        if (option.value !== value) {
          copy = option.value;
          copyDisplay = option.label;
          setValue(copy);
          setValueDisplay(copyDisplay);
        } else if (allowUnselected) {
          copy = '';
          copyDisplay = '';
          setValue(copy);
          setValueDisplay(copyDisplay);
        }
      }
    }
    if (notifySelectedValue != null && copy != null) notifySelectedValue(copy);
    if (preventSelection && notifySelectedValue != null) notifySelectedValue(option.value);
    setSearched('');
    if (!multiple && !isHeadless) setOpen(false);
  };

  const handleClickDropdown = (): void => {
    setOpen(!open);
  };

  const searchOptions = (str: string): IOptions => {
    if (str === '') return options;
    const opts: IOptions = [];
    options.forEach((opt) => {
      if (opt.label.toLowerCase().includes(str.toLowerCase())) opts.push(opt);
    });
    return opts;
  };

  const optionsToDisplay: IOptions = searchOptions(searched);

  const handleArrowDown = (e: Event): boolean => {
    e.preventDefault();
    if (open) setActiveOption(Math.min(activeOption + 1, optionsToDisplay.length - 1));
    return true;
  };

  const handleArrowUp = (e: Event): boolean => {
    e.preventDefault();
    if (open) setActiveOption(Math.max(activeOption - 1, 0));
    return true;
  };

  const handleSelectOption = (e: Event): boolean => {
    e.preventDefault();
    if (open) {
      const option: IOption = optionsToDisplay[activeOption];
      handleValueSelected(option);
    }
    return true;
  };

  const getMultipleOptions = (options: string[]): React.JSX.Element[] => {
    return options.map((display, i) => (
      <div className={styles.chip} key={'display-' + i}>
        <div>{display}</div>
        <div
          className={styles.chipClose}
          onClick={(e: any) => {
            handleRemoveValue(e, display);
          }}
        >
          <Icon bold icon="close" color="var(--title-color)" iconSize="xsmall" />
        </div>
      </div>
    ));
  };

  return (
    <div ref={ref} className={styles.container} style={{ minWidth, maxWidth, width, height }}>
      <div
        onClick={() => {
          if (!disabled) handleClickDropdown();
        }}
        className={styles.selectedContainer}
        style={{
          minWidth,
          maxWidth,
          width,
          height,
          display: isHeadless ? 'none' : 'flex',
          cursor: disabled ? 'not-allowed' : '',
          backgroundColor: disabled ? 'var(--background-color-disabled-1)' : '',
          borderColor: disabled ? 'var(--border-color)' : '',
          borderRadius: preventRightBorderRadius ? 0 : '',
          borderTopLeftRadius: preventRightBorderRadius ? 'var(--border-radius-m)' : '',
          borderBottomLeftRadius: preventRightBorderRadius ? 'var(--border-radius-m)' : '',
        }}
      >
        <div
          className="hide-native-scrollbar"
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            flex: '1 1 50%',
            minWidth: '0',
            overflowX: 'scroll',
            marginLeft: 'calc(var(--padding-s) / 2)',
            contain: 'paint',
          }}
        >
          {multiple ? (
            valuesDisplay.length !== 0 ? (
              getMultipleOptions(valuesDisplay)
            ) : defaultOption != null && typeof defaultOption !== 'string' && defaultOption.length > 0 ? (
              getMultipleOptions(defaultOption)
            ) : (
              <div className={styles.childSelectedValue}>{placeholder}</div>
            )
          ) : valueDisplay !== '' ? (
            <div className={styles.childSelectedValue}>{valueDisplay}</div>
          ) : (
            <div className={styles.childSelectedValue}>
              {defaultOption != null && defaultOption !== '' ? defaultOption : placeholder}
            </div>
          )}
        </div>
        <Icon icon="unfold_more" color="var(--title-color)" />
      </div>
      {open && (
        <div style={{ marginTop: isHeadless ? '0px' : '2px' }}>
          <BasePopup offsetY={isHeadless ? offsetY : -(numHeight - 64)}>
            <div
              ref={dropdownRef}
              className={styles.dropdownContainer}
              style={{ minWidth, maxWidth, width, maxHeight }}
            >
              <div className={styles.searchBarContainer}>
                <Icon icon="search" color="var(--title-color)" />
                <input
                  ref={inputRef}
                  onChange={(e) => {
                    setSearched(e.target.value);
                  }}
                  value={searched}
                  placeholder={searchPlaceholder}
                  className={styles.searchBar}
                  type="text"
                />
              </div>
              {scrollTop && (
                <div ref={scrollTopRef} style={{ position: 'relative', width: '100%', height: '0' }}>
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: 'var(--background-color-8)',
                      zIndex: 3,
                    }}
                  >
                    <Icon
                      bold
                      icon="expand_less"
                      color={hoveringScrollTop ? 'var(--secondary-color)' : 'var(--title-color)'}
                      iconSize="normal"
                    />
                  </div>
                </div>
              )}
              <div className={`hide-native-scrollbar ${styles.optionsContainer}`}>
                <div ref={optionsContainerRef}>
                  {optionsToDisplay.length === 0 ? (
                    <div
                      style={{
                        padding: 'var(--padding-s)',
                      }}
                    >
                      {emptyPlaceholder}
                    </div>
                  ) : (
                    optionsToDisplay.map((option, i) => (
                      <div
                        data-option={true}
                        data-value={option.value}
                        onClick={() => {
                          handleValueSelected(option);
                        }}
                        className={styles.item}
                        key={'option-' + option.value}
                        style={{
                          backgroundColor:
                            i === activeOption ? ' var(--input-field-background-color)' : 'transparent',
                        }}
                      >
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          {option.icon != null && (
                            <div style={{ marginRight: 'var(--padding-s)', flexShrink: 0 }}>
                              {option.icon}
                            </div>
                          )}
                          <div style={{ flex: '1 1 50%', minWidth: 0, overflow: 'hidden' }}>
                            <div
                              title={option.label}
                              style={{
                                width: '100%',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {option.label}
                            </div>
                          </div>
                        </div>
                        {((!multiple && option.value === value) ||
                          (multiple && values.includes(option.value))) && (
                          <div
                            style={{
                              position: 'absolute',
                              right: 'var(--padding-s)',
                              paddingLeft: 'var(--padding-xs)',
                              backgroundColor:
                                i === activeOption
                                  ? ' var(--input-field-background-color)'
                                  : 'var(--background-color-8)',
                            }}
                          >
                            <Icon icon="check" color="var(--title-color)" />
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
              {scrollBottom && (
                <div ref={scrollBottomRef} style={{ position: 'relative', width: '100%', height: '0' }}>
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      bottom: 0,
                      backgroundColor: 'var(--background-color-8)',
                      zIndex: 3,
                    }}
                  >
                    <Icon
                      icon="expand_more"
                      bold
                      color={hoveringScrollBottom ? 'var(--secondary-color)' : 'var(--title-color)'}
                      iconSize="normal"
                    />
                  </div>
                </div>
              )}
            </div>
          </BasePopup>
        </div>
      )}
    </div>
  );
}, propsAreEqual);
