import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { type IShadowVariables } from '@globalTypes/conversor';
import {
  BOX_SHADOW,
  BOX_SHADOW_CSS,
  CLOSE_BRACKET,
  DEFAULT_COLOR_WHITE,
  EXTERNAL_CLASSNAME,
  NUMBER_SIGN,
  SEMICOLON_LINE_JUMP,
  TWO_POINTS_SPACE,
  UNIT_PX,
  UNSET
} from '../../../../constants/constants';

export function initShadowVariables (item: TypeItemProperties): IShadowVariables {
  if ('shadow' in item) {
    const mVal = {
      shadowOffsetX: [(item.shadow?.offSetX?.value ?? '0') + (item.shadow?.offSetX?.unit ?? UNIT_PX, '')],
      shadowOffsetY: [(item.shadow?.offSetY?.value ?? '0') + (item.shadow?.offSetY?.unit ?? UNIT_PX, '')],
      shadowBlur: [item.shadow?.blur != null
        ? item.shadow?.blur?.value + (item.shadow?.blur?.unit ?? UNIT_PX, '')
        : UNSET, ''],
      shadowColor: [NUMBER_SIGN + (item.shadow?.color ?? NUMBER_SIGN + DEFAULT_COLOR_WHITE), ''],
    };
    return mVal;
  } else {
    return {
      shadowOffsetX: ['0px'],
      shadowOffsetY: ['0px'],
      shadowBlur: ['', ''],
      shadowColor: ['', ''],
    };
  }
}

export function createShadowVariableAsCSS (shadowVariables: IShadowVariables): string {
  if (shadowVariables.shadowOffsetX[2] != null &&
      shadowVariables.shadowOffsetY[2] != null &&
      shadowVariables.shadowBlur[2] != null &&
      shadowVariables.shadowColor[2] != null) {
    const mVal = shadowVariables.shadowOffsetX[0] + ' ' +
    shadowVariables.shadowOffsetY[0] + ' ' +
    shadowVariables.shadowBlur[0] + ' ' +
    shadowVariables.shadowColor[0];
    return BOX_SHADOW_CSS + TWO_POINTS_SPACE + mVal + SEMICOLON_LINE_JUMP;
  } else return '';
}

export function createShadowVariableAsProps (shadowVariables: IShadowVariables): string {
  if (shadowVariables.shadowOffsetX[1] !== '') {
    shadowVariables.shadowOffsetX[1] = EXTERNAL_CLASSNAME + shadowVariables.shadowOffsetX[1] + CLOSE_BRACKET;
  } else shadowVariables.shadowOffsetX[1] = '' + shadowVariables.shadowOffsetX[0] + '';
  if (shadowVariables.shadowOffsetY[1] !== '') {
    shadowVariables.shadowOffsetY[1] = EXTERNAL_CLASSNAME + shadowVariables.shadowOffsetY[1] + CLOSE_BRACKET;
  } else shadowVariables.shadowOffsetY[1] = '' + shadowVariables.shadowOffsetY[0] + '';
  if (shadowVariables.shadowBlur[1] !== '') {
    shadowVariables.shadowBlur[1] = EXTERNAL_CLASSNAME + shadowVariables.shadowBlur[1] + CLOSE_BRACKET;
  } else shadowVariables.shadowBlur[1] = '' + shadowVariables.shadowBlur[0] + '';
  if (shadowVariables.shadowColor[1] !== '') {
    shadowVariables.shadowColor[1] = EXTERNAL_CLASSNAME + shadowVariables.shadowColor[1] + CLOSE_BRACKET;
  } else shadowVariables.shadowColor[1] = '' + shadowVariables.shadowColor[0] + '';
  const mVal = shadowVariables.shadowOffsetX[1] + ' ' +
      shadowVariables.shadowOffsetY[1] + ' ' +
      shadowVariables.shadowBlur[1] + ' ' +
      shadowVariables.shadowColor[1];
  return BOX_SHADOW + '`' + mVal + '`';
}
