/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/indent */
import { type IIconSize, Icon, Tooltip } from '..';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';

export type IOptionRadio =
  | {
      value: string;
      icon: string;
      hint?: string;
    }
  | {
      label: string;
      value: string;
      hint?: string;
    };

export type IOptionsRadio = IOptionRadio[];

const compareOptions = (a: IOptionsRadio, b: IOptionsRadio): boolean => {
  if (a.length !== b.length) return false;
  const l = a.length;
  let same = true;
  for (let i = 0; i < l && same; i++) {
    const x = a[i];
    const y = b[i];
    if (x.value !== y.value) same = false;
    else if ('icon' in x !== 'icon' in y) same = false;
    else if ('icon' in x && 'icon' in y && x.icon !== y.icon) same = false;
    else if ('label' in x !== 'label' in y) same = false;
    else if ('label' in x && 'label' in y && x.label !== y.label) same = false;
    else if ('hint' in x !== 'hint' in y) same = false;
    else if ('hint' in x && 'hint' in y && x.hint !== y.hint) same = false;
  }
  return same;
};

const propsAreEqual = (a: RadioIconButtonProps, b: RadioIconButtonProps): boolean => {
  if (a.defaultOption !== b.defaultOption) return false;
  if (a.notifyOptionChanged !== b.notifyOptionChanged) return false;
  if (!compareOptions(a.options, b.options)) return false;
  if (a.disabled !== b.disabled) return false;
  return true;
};

export interface RadioIconButtonProps {
  options: IOptionsRadio;
  defaultOption?: string;
  notifyOptionChanged?: (value: string) => any;
  size?: IIconSize;
  disabled?: boolean;
}

export const RadioIconButton = React.memo(
  ({
    options,
    defaultOption = '',
    notifyOptionChanged,
    size = 'normal',
    disabled = false,
  }: RadioIconButtonProps): React.JSX.Element => {
    const [currentlySelectedOption, setCurrentlySelectedOption] = useState<string>(defaultOption);

    useEffect(() => {
      setCurrentlySelectedOption(defaultOption);
    }, [defaultOption]);

    const handleChangeOption = (val: string): void => {
      if (val === currentlySelectedOption) return;
      setCurrentlySelectedOption(val);
      if (notifyOptionChanged != null) notifyOptionChanged(val);
    };

    if (options.length === 0) return <></>;
    return (
      <div
        className={styles.container}
        style={{
          backgroundColor: disabled ? 'var(--background-color-disabled-1)' : '',
          border: disabled ? '1px solid var(--border-color)' : '',
        }}
      >
        {options.map((option) => {
          const isSelected = currentlySelectedOption === option.value;
          const mContent = (
            <div key={'tradiobutton-o-' + option.value} style={{ flex: '1 1 50%' }}>
              <div
                className={styles.radioButton}
                onClick={() => {
                  if (!disabled) handleChangeOption(option.value);
                }}
                key={'radiobutton-o-' + option.value}
                style={{
                  backgroundColor: disabled
                    ? 'var(--background-color-disabled-1)'
                    : isSelected
                      ? 'var(--primary-color)'
                      : 'var(--input-field-background-color)',
                  color: !isSelected || disabled ? 'var(--title-color)' : 'var(--title-color-contrast)',
                  cursor: disabled ? 'not-allowed' : '',
                }}
              >
                {'label' in option ? (
                  option.label
                ) : (
                  <Icon
                    icon={option.icon}
                    bold
                    color={isSelected ? 'var(--title-color-contrast)' : 'var(--title-color)'}
                    iconSize={size}
                  />
                )}
              </div>
            </div>
          );
          if (option.hint == null) return mContent;
          else {
            return (
              <Tooltip width="100%" flex="1 1 50%" key={'tradiobutton-o-' + option.value} text={option.hint}>
                {mContent}
              </Tooltip>
            );
          }
        })}
      </div>
    );
  },
  propsAreEqual
);
RadioIconButton.displayName = 'RadioIconButton';
