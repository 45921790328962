import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BRACKET_BLUR,
  BRACKET_COLOR,
  BRACKET_OFFSET_X,
  BRACKET_OFFSET_Y,
  CLOSING_PARENTESIS,
  DEFAULT_COLOR_WHITE,
  LINE_JUMP,
  NUMBER_SIGN,
  SHADOW_BLUR,
  SHADOW_COLOR,
  SHADOW_OFFSET_X,
  SHADOW_OFFSET_Y,
  UNIT_PX,
  VAR_OPENING
} from '../../../../../constants/constants';
import { type TypeDictionary } from '../../../../dictionary/dictionary';
import { referenceValue } from '../../reactItemUtils';

export function handleShadowStyle (item: TypeItemProperties, shadow: string,
  values: Array<string | number>): string {
  const referenceShadowX = referenceValue(SHADOW_OFFSET_X, item, false);
  const referenceShadowY = referenceValue(SHADOW_OFFSET_Y, item, false);
  const referenceShadowBlur = referenceValue(SHADOW_BLUR, item, false);
  const referenceShadowColor = referenceValue(SHADOW_COLOR, item, false);
  const xValue = referenceShadowX !== false ? VAR_OPENING + referenceShadowX + CLOSING_PARENTESIS : values[0];
  shadow = shadow.replace(BRACKET_OFFSET_X, xValue.toString());
  const yValue = referenceShadowY !== false ? VAR_OPENING + referenceShadowY + CLOSING_PARENTESIS : values[1];
  shadow = shadow.replace(BRACKET_OFFSET_Y, yValue.toString());
  const colorValue = referenceShadowColor !== false
    ? VAR_OPENING + referenceShadowColor + CLOSING_PARENTESIS
    : values[2];
  shadow = shadow.replace(BRACKET_COLOR, colorValue.toString());
  const blurValue = referenceShadowBlur !== false
    ? VAR_OPENING + referenceShadowBlur + CLOSING_PARENTESIS
    : values[3];
  shadow = shadow.replace(BRACKET_BLUR, blurValue.toString());
  if (referenceShadowColor !== false) shadow = shadow.replace(NUMBER_SIGN, '');
  return shadow;
}

export function getShadowValues (dictionary: TypeDictionary,
  item: TypeItemProperties): Array<string | number> {
  if ('shadow' in item) {
    const offsetUnitX = dictionary[(item.shadow.offSetX.unit ?? UNIT_PX) as keyof typeof dictionary];
    const offsetUnitY = dictionary[(item.shadow.offSetY.unit ?? UNIT_PX) as keyof typeof dictionary];
    const values = [
      item.shadow.offSetX.value != null ? item.shadow.offSetX.value + offsetUnitX : 0 + '' + offsetUnitX,
      item.shadow.offSetY.value != null ? item.shadow.offSetY.value + offsetUnitY : 0 + '' + offsetUnitY,
      item.shadow.color ?? DEFAULT_COLOR_WHITE,
      item.shadow.blur.value ?? 0];
    return values;
  } return [''];
}

export function shadowStyle (item: TypeItemProperties, dictionary: TypeDictionary): string {
  if ('shadow' in item) {
    if (item.shadow.offSetX.value !== 0 || item.shadow.offSetY.value !== 0 ||
        item.shadow.color !== DEFAULT_COLOR_WHITE || item.shadow.blur.value !== 0) {
      if (item.shadow.offSetX.value == null && item.shadow.offSetY.value == null &&
          item.shadow.color == null && item.shadow.blur == null) {
        return '';
      }
      const values = getShadowValues(dictionary, item);
      let shadow = dictionary.layerShadow;
      shadow = handleShadowStyle(item, shadow, values);
      return LINE_JUMP + shadow;
    }
  }
  return '';
}
