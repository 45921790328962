/* eslint-disable max-lines */
import { type ITreeNode } from '@globalUtils/tree';
import {
  CLOSE_SCRIPT,
  COMMA,
  COMPONENTMODE_IMPORT,
  COMPONENTS,
  COMPONENT_BRACKET,
  COMPONENT_ENTRY_FILE,
  COMPONENT_LAYOUT,
  COMPONENT_MODE,
  COMPONENT_MODE_ARCHIVE,
  COMPONENT_MODE_HTML,
  COMPONENT_MODE_IMPORT,
  COMPONENT_MODE_INFO,
  COMPONENT_NAME, DESKTOP,
  FIRST_SCREEN,
  FIRST_SCREEN_IMPORT,
  FONTS,
  ICON_BRACKET,
  IMAGE_VARIABLE,
  IMPORTS,
  INDEX_CSS,
  INDEX_HTML,
  LAYOUTS,
  LAYOUT_THEME_SEPARATOR,
  LOGO,
  OPEN_SCRIPT,
  PARAMETER_SEPARATOR,
  PHONE,
  RESPONSIVE_VARIABLE,
  ROUTES,
  ROUTES_STRING,
  SCRIPT,
  SLASH,
  TABLET,
  UNDERSCORE,
  UNIT_PX,
  UNIT_VH,
  UNIT_VW,
  WINDOWS_ESC
} from '../../constants/constants';
import { type TypeDictionary } from '../dictionary/dictionary';
import {
  componentChildrenReferencesValues
} from '../reactComponentMethods/reactComponentChildrenVariablesMethods';
import { isComponentALayout } from '../reactComponentMethods/reactComponentUtils';
import { getConvertNametoReact } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import {
  type TypeItems,
  type ReactInfo,
  type TypeAttributeReferenceVariable,
  type IAguaRender,
  type IConversorThemesType,
  type IParametersTypeList,
  type IParametersType
} from '@globalTypes/conversor';
import { getFontImportStyle, handlePackageJsonDependencies } from './reactConversionMethods';

export function initComponentModeThemes (mName: string, neededInfo: ReactInfo): void {
  neededInfo.infoThemes = { Default: {}, Responsiveness: {} };
  neededInfo.infoThemes[mName] = {};
  neededInfo.responsiveValues = {
    desktop: { Default: {}, Responsiveness: {} },
    tablet: { Default: {}, Responsiveness: {} },
    phone: { Default: {}, Responsiveness: {} }
  };
  neededInfo.attributeReference = { Default: {}, Responsiveness: {} };
  neededInfo.responsiveValues.desktop[mName] = {};
  neededInfo.responsiveValues.phone[mName] = {};
  neededInfo.responsiveValues.tablet[mName] = {};
  neededInfo.parametersValuesList = {};
  neededInfo.idsWithName = {};
}

export function setComponentModeDeviceValues (responsiveValues: any, neededInfo: ReactInfo, device: string,
  i: number, mName: string, varName: string): void {
  const key = device as keyof typeof neededInfo.responsiveValues;
  if (neededInfo.responsiveValues[key][mName] == null) neededInfo.responsiveValues[key][mName] = {};
  if (typeof (responsiveValues[i]) === 'string' &&
      (responsiveValues[i].endsWith(COMMA + UNIT_PX) === true ||
      responsiveValues[i].endsWith(COMMA + UNIT_VW) === true ||
      responsiveValues[i].endsWith(COMMA + UNIT_VH) === true)) {
    responsiveValues[i] = responsiveValues[i].split(COMMA);
  }
  neededInfo.responsiveValues[key][mName][varName] = responsiveValues[i];
}

export function setComponentModeResponsiveValues (neededInfo: ReactInfo, mReferences: any, mName: string,
  varName: string, refType: string): void {
  const responsiveValues = [
    mReferences[varName].valueDesktop,
    mReferences[varName].valueTablet,
    mReferences[varName].valuePhone
  ];
  neededInfo.infoThemes[mName][varName] = responsiveValues[0];
  if (!mName.includes(UNDERSCORE)) {
    setComponentModeDeviceValues(responsiveValues, neededInfo, DESKTOP, 0, mName, varName);
    setComponentModeDeviceValues(responsiveValues, neededInfo, TABLET, 1, mName, varName);
    setComponentModeDeviceValues(responsiveValues, neededInfo, PHONE, 2, mName, varName);
  }
}

export function setComponentModeValuesTheme (themeInfo: IParametersType, neededInfo: ReactInfo,
  varName: string, componentName: string, id: string): void {
  const variables = themeInfo.variables;
  const children = themeInfo.children;
  for (const child in children) {
    setComponentModeValuesTheme(children[child], neededInfo, varName, componentName, id);
  }
  for (const variable in variables) {
    if (variable === varName) {
      const refVal = variables[variable];
      const mVal = componentName + UNDERSCORE + id;
      if (neededInfo.infoThemes[mVal] == null) {
        neededInfo.infoThemes[mVal] = {};
      }
      neededInfo.infoThemes[mVal][varName] = { parameter: true, value: refVal };
    }
  }
}

export function setComponentModeNormalValues (neededInfo: ReactInfo, varName: string,
  params: IParametersTypeList, componentName: string, refType: string, ref: string[],
  root: ITreeNode): void {
  for (const theme in params) {
    const themeInfo = params[theme];
    setComponentModeValuesTheme(themeInfo, neededInfo, varName, componentName, root.id);
  }
  /**
  const themes = params;
  const typeVer = refType === SIZE_VARIABLE || refType === FONT_VARIABLE || refType === IMAGE_VARIABLE;
  const splitVer = ref[0].includes(COMMA) && typeVer;
  let refVal = splitVer ? ref[0].split(COMMA) : ref[0];
  if (params[varName] != null) {
    const paramSplitVer = params[varName].value.includes(COMMA) && typeVer;
    refVal = paramSplitVer ? params[varName].value.split(COMMA) : params[varName].value;
  }
  if (refType === FONT_VARIABLE || refType === IMAGE_VARIABLE) refVal = refVal[1];
  if (params[varName] != null) {
    const mVal = componentName + UNDERSCORE + root.id;
    if (neededInfo.infoThemes[mVal] == null) {
      neededInfo.infoThemes[mVal] = {};
    }
    if (typeof (refVal) !== 'string') neededInfo.infoThemes[mVal][varName] = [refVal[0], refVal[1]];
    else neededInfo.infoThemes[mVal][varName] = refVal;
  }
  */
}

export function setComponentModeValues (neededInfo: ReactInfo, mReferences: any,
  params: IParametersTypeList, componentName: string, root: ITreeNode): void {
  for (const varName in mReferences) {
    const ref = [mReferences[varName].value, mReferences[varName].type];
    let refType = ref[1] === LOGO ? IMAGE_VARIABLE : ref[1];
    if (ref.length !== 2) {
      refType = ref[ref.length - 1];
      ref[0] = mReferences[varName].substring(0, mReferences[varName].length - refType.length - 1);
    }
    if (mReferences[varName].tag === RESPONSIVE_VARIABLE) {
      setComponentModeResponsiveValues(neededInfo, mReferences, componentName, varName, refType);
    } else {
      setComponentModeNormalValues(neededInfo, varName, params, componentName, refType, ref, root);
    }
  }
}

export function initComponentModeAttributes (neededInfo: ReactInfo, externalTheme: string,
  internalTheme: string, variable: string, id: string): void {
  const kTheme = externalTheme as keyof typeof neededInfo.attributeReference;
  if (neededInfo.attributeReference[kTheme] == null) {
    neededInfo.attributeReference[kTheme] = {};
  }
  if (neededInfo.attributeReference[kTheme][internalTheme] == null) {
    neededInfo.attributeReference[kTheme][internalTheme] = {};
  }
  if (neededInfo.attributeReference[kTheme][internalTheme][variable] == null) {
    neededInfo.attributeReference[kTheme][internalTheme][variable] = {};
  }
  const componentLocation =
    neededInfo.attributeReference[kTheme][internalTheme][variable] as TypeAttributeReferenceVariable;
  if (componentLocation[id] == null) {
    componentLocation[id] = [];
  }
}

export function setComponentModeAttributes (attributes: TypeAttributeReferenceVariable, externalTheme: string,
  neededInfo: ReactInfo,
  items: TypeItems, root: ITreeNode): void {
  const kTheme = externalTheme as keyof typeof neededInfo.attributeReference;
  for (const id in attributes) {
    const attributeList = attributes[id];
    for (const info of attributeList) {
      if (info.route != null) {
        const themes = info.theme.split(LAYOUT_THEME_SEPARATOR);
        initComponentModeAttributes(neededInfo, externalTheme, themes[1], info.variable, id);
        info.route = items[root.id].name.replaceAll(' ', '') + SLASH + info.route;
        const componentLocation = neededInfo.attributeReference[kTheme][themes[1]][info.variable];
        const keyId = id as keyof typeof componentLocation;
        const place = componentLocation[keyId];
        if (Array.isArray(place)) place.push(info);
      } else {
        initComponentModeAttributes(neededInfo, externalTheme, externalTheme, info.variable, id);
        info.route = items[root.id].name.replaceAll(' ', '');
        const componentLocation = neededInfo.attributeReference[kTheme][externalTheme][info.variable];
        const keyId = id as keyof typeof componentLocation;
        const place = componentLocation[keyId];
        if (Array.isArray(place)) place.push(info);
      }
    }
  }
}

export function setIdsWithNameTheme (neededInfo: ReactInfo, themeName: string, items: TypeItems): string[] {
  let themeIdComponent = [''];
  if (neededInfo.idsWithName[themeName] != null) {
    themeIdComponent = neededInfo.idsWithName[themeName];
  } else {
    for (const idItem in items) {
      const pitem = items[idItem];
      if (pitem.name === themeName) {
        const compName = 'component' in pitem && pitem.component !== ''
          ? pitem.component
          : '';
        themeIdComponent = [idItem, compName];
        neededInfo.idsWithName[themeName] = [idItem, compName];
        break;
      }
    }
  }
  return themeIdComponent;
}

export function setParameterValuesListValues (neededInfo: ReactInfo, items: TypeItems, themeName: string,
  param: string, params: IParametersTypeList, componentName: string, root: ITreeNode): void {
  let parent = items[-1];
  for (const idItem in items) {
    const pitem = items[idItem];
    if (pitem.name.replaceAll(' ', '') === themeName) {
      parent = items[idItem];
    }
  }
  if (parent == null) return;
  const parents = ['name' in parent ? parent.name.replaceAll(' ', '') : ''];
  let currentParent = items['parent' in parent ? parent.parent : parent.id];
  while (currentParent.id !== root.id) {
    if ('component' in currentParent && currentParent.component != null &&
    typeof (currentParent.component) !== 'boolean') parents.push(currentParent.name.replaceAll(' ', ''));
    currentParent = items['parent' in currentParent ? currentParent.parent : currentParent.id];
  }
  let route = items[root.id].name.replaceAll(' ', '') + SLASH;
  for (let j = parents.length - 1; j >= 0; j--) route += parents[j] + SLASH;
  const externalTheme = componentName + UNDERSCORE + root.id;
  const internalTheme = ('component' in parent ? parent.component : '') + UNDERSCORE + parent.id;
  if (neededInfo.parametersValuesList[externalTheme] == null) {
    neededInfo.parametersValuesList[externalTheme] = {};
  }
  if (neededInfo.parametersValuesList[externalTheme][internalTheme] == null) {
    neededInfo.parametersValuesList[externalTheme][internalTheme] = {};
  }
  const mVal = { value: params[param], route: route.substring(0, route.length - 1) };
  neededInfo.parametersValuesList[externalTheme][internalTheme][param.split(PARAMETER_SEPARATOR)[1]] = mVal;
}

export function setComponentModeParams (neededInfo: ReactInfo, items: TypeItems, componentName: string,
  params: IParametersTypeList, root: ITreeNode): void {
  for (const themeName in params) {
    const themeIdComponent: string[] = setIdsWithNameTheme(neededInfo, themeName, items);
    if (themeIdComponent[1] == null) {
      setParameterValuesListValues(neededInfo, items, themeName, themeName, params, componentName, root);
    } else {
      const externalTheme = componentName + UNDERSCORE + root.id;
      if (neededInfo.infoThemes[externalTheme] == null) neededInfo.infoThemes[externalTheme] = {};
      if (neededInfo.infoThemes[externalTheme] == null) {
        neededInfo.infoThemes[externalTheme] = {};
      }
      for (const variable in params[themeName].variables) {
        neededInfo.infoThemes[externalTheme][variable] = {
          parameter: true, value: params[themeName].variables[variable]
        }; ;
      }
    }
  }
}

export function createComponentModeThemes (themes: IConversorThemesType, items: TypeItems, root: ITreeNode,
  params: IParametersTypeList, componentName: string, neededInfo: ReactInfo): void {
  const mName = componentName.replaceAll(' ', '');
  initComponentModeThemes(mName, neededInfo);
  const mReferences = themes.references?.variables ?? {};
  const externalTheme = mName + UNDERSCORE + root.id;
  setComponentModeValues(neededInfo, mReferences, params, componentName, root);
  const attributes = themes.attributeReference;
  if (attributes != null) setComponentModeAttributes(attributes, externalTheme, neededInfo, items, root);
  setComponentModeParams(neededInfo, items, componentName, params, root);
}

export function getComponentModeInformation (name: string, items: TypeItems, root: ITreeNode,
  dictionary: TypeDictionary, neededInfo: ReactInfo): string {
  let information = COMPONENT_MODE_INFO;
  information = information.replace(COMPONENT_NAME, name);
  information = componentChildrenReferencesValues(information, items[root.id], COMPONENT_MODE, neededInfo,
    dictionary);
  return information;
}

export function getComponentModePage (name: string, items: TypeItems, neededInfo: ReactInfo,
  dictionary: TypeDictionary, componentEntryFile: string, root: ITreeNode): string {
  let firstPage = dictionary.componentModeScreen;
  firstPage = firstPage.replaceAll(COMPONENT_NAME, name);
  firstPage = firstPage.replaceAll(COMPONENT_BRACKET, name.substring(0, 1).toLowerCase() + name.substring(1));
  const isLayout = isComponentALayout(name, neededInfo.componentsUsed);
  firstPage = firstPage.replace(COMPONENT_LAYOUT, isLayout ? LAYOUTS : COMPONENTS);
  firstPage = componentChildrenReferencesValues(firstPage, items[root.id], COMPONENT_MODE, neededInfo,
    dictionary);
  firstPage = firstPage.replace(COMPONENT_ENTRY_FILE, componentEntryFile);
  return firstPage;
}

export function getComponentModePublicArchives (dictionary: TypeDictionary, scripts: string): any {
  const publicArchives = { 'index.html': '' };
  publicArchives[INDEX_HTML] = dictionary.initialTemplateComponent.replace(ICON_BRACKET, '');
  const scriptVal = scripts != null ? OPEN_SCRIPT + scripts + CLOSE_SCRIPT : '';
  publicArchives[INDEX_HTML] = publicArchives[INDEX_HTML].replace(SCRIPT, scriptVal);
  return publicArchives;
}

export function getComponentModeAppJs (dictionary: TypeDictionary): string {
  let appjs = dictionary.appJs;
  appjs = appjs.replace(FIRST_SCREEN, COMPONENT_MODE_HTML);
  const importVal = COMPONENT_MODE_IMPORT;
  appjs = appjs.replace(FIRST_SCREEN_IMPORT, importVal);
  return appjs;
}

export function getComponentModeRouteArchive (dictionary: TypeDictionary): string {
  let routesArchive = dictionary.routesArchive;
  const routes = ROUTES_STRING;
  routesArchive = routesArchive.replace(ROUTES, routes);
  const imports = COMPONENTMODE_IMPORT;
  routesArchive = routesArchive.replace(IMPORTS, imports);
  return routesArchive;
}

export function getComponentRender (info: any, neededInfo: ReactInfo, dictionary: TypeDictionary,
  neededFiles: any): IAguaRender {
  const agua: IAguaRender = {
    public: info.publicArchives,
    src: {
      agua: {
        routes: {
          'routes.jsx': info.routesArchive
        },
        pages: info.pagesObject,
        components: neededInfo.componentCode
      },
      'app.js': info.appjs.replace(WINDOWS_ESC, ''),
      'index.js': info.indexjs,
      'index.css': dictionary[INDEX_CSS].replace(FONTS, info.fonts ?? ''),
      'aguaNotOverwritable.jsx': ''
    },
    'package.json': info.packageJson
  };
  if (Object.keys(neededInfo.layoutCode).length !== 0) {
    agua.src.agua.layouts = neededInfo.layoutCode;
    neededFiles.layouts = neededInfo.layoutCode;
  }
  return agua;
}

export function componentPreviewMode (componentName: string, root: ITreeNode, componentEntryFile: string,
  neededInfo: ReactInfo, dictionary: TypeDictionary): [IAguaRender, string, { components: string; }] {
  const pagesObject: any = {};
  const name = getConvertNametoReact(componentName);
  const information = getComponentModeInformation(name, neededInfo.items, root, dictionary, neededInfo);
  const firstPage = getComponentModePage(name, neededInfo.items, neededInfo, dictionary, componentEntryFile,
    root);
  pagesObject.page = {};
  pagesObject.page[COMPONENT_MODE_ARCHIVE] = firstPage;
  const fonts = getFontImportStyle(neededInfo, dictionary);
  for (const custom in neededInfo.customCode) {
    neededInfo.componentCode[custom] = neededInfo.customCode[custom];
  }
  const packageJson = handlePackageJsonDependencies(dictionary, name, neededInfo);
  const publicArchives = getComponentModePublicArchives(dictionary, neededInfo.scripts);
  const indexjs = dictionary.indexApp;
  const appjs = getComponentModeAppJs(dictionary);
  const routesArchive = getComponentModeRouteArchive(dictionary);
  const neededFiles = { components: neededInfo.componentCode };
  const info = { publicArchives, pagesObject, appjs, indexjs, fonts, packageJson, routesArchive };
  const agua = getComponentRender(info, neededInfo, dictionary, neededFiles);
  return [agua, information, neededFiles];
}
