import { type PayloadAction } from '@reduxjs/toolkit';

import { type ItemsType } from '..';
import { IconType } from '@globalTypes/itemProperties';
import { checkResponsiveCase } from './itemUtils';

interface IconIconPayload {
  [IconType.IconProperty]: IconType.IconType;
  id: string;
};
export const changeIconIcon = (state: ItemsType, action: PayloadAction<IconIconPayload>): void => {
  const { id } = action.payload;
  const icon = action.payload[IconType.IconProperty];
  const item = state.items.byId[id];
  if (IconType.IconProperty in item) {
    checkResponsiveCase(state, id, { content: { default: icon } }, 'icon',
      { content: { default: item[IconType.IconProperty] } });
    item[IconType.IconProperty] = icon;
  }
};
