import { CLASSNAME, CUSTOM_STYLES } from '@globalTypes/constants';

export namespace ClassnameType {
  export type ClassnameProperty = 'classname';
  export const ClassnameProperty: ClassnameProperty = CLASSNAME;

  export type ClassnameType = string;
}

export namespace CustomStylesType {
  export type CustomStylesProperty = 'customStyles';
  export const CustomStylesProperty: CustomStylesProperty = CUSTOM_STYLES;

  export type CustomStylesType = string;
}

export namespace ClassNamesType {
  export type ClassNamesProperty = 'classNames';
  export const ClassNamesProperty: ClassNamesProperty = 'classNames';

  export type ClassNamesType = string[];
}
