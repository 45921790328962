export const VERTICAL_ALIGNMENT = 'verticalAlignment';
export const HORIZONTAL_ALIGNMENT = 'horizontalAlignment';

export const ANIMATION = 'animation';
export const ANIMATION_WHEN_VISIBLE = 'animationWhenVisible';

export const DATA = 'data';
export const KIND = 'kind';

export const COMMON_KIND = 'commonKind';
export const ID = 'id';
export const NAME = 'name';
export const PARENT = 'parent';
export const INDEX = 'index';

export const BACKGROUND_COLOR = 'backgroundColor';
export const BACKGROUND = 'background';

export const CLASSES = 'classes';

export const CURSOR_POINTER = 'cursorPointer';

export const CLASSNAME = 'classname';
export const CUSTOM_STYLES = 'customStyles';

export const WIDTH = 'width';
export const HEIGHT = 'height';
export const ASPECT_RATIO = 'aspectRatio';
export const WRAP = 'wrap';
export const FILL = 'fill';
export const FIX = 'fix';

export const TOP = 'top';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
export const RIGHT = 'right';

export const FONT_SIZE = 'fontSize';

export const HREF = 'href';

export const MARGINS = 'margins';

export const ORIENTATION = 'orientation';

export const PADDINGS = 'paddings';

export const POSITIONS = 'positions';

export const SCROLLABLE = 'scrollable';

export const VALUE = 'value';
export const UNIT = 'unit';
export const UNIT_PX = 'px';
export const UNIT_VH = 'vh';
export const UNIT_VW = 'vw';
export const UNIT_PERCENTAGE = '%';

export const TAG = 'tag';

export const VISIBLE = 'visible';

export const ICON = 'icon';

export const SRC = 'src';

export const OFFSET_X = 'offSetX';
export const OFFSET_Y = 'offSetY';
export const BLUR = 'blur';
export const COLOR = 'color';
export const SHADOW = 'shadow';
export const Z_INDEX = 'zindex';
export const TOP_LEFT = 'topLeft';
export const TOP_RIGHT = 'topRight';
export const BOTTOM_LEFT = 'bottomLeft';
export const BOTTOM_RIGHT = 'bottomRight';
export const RADIUS = 'radius';
export const STYLE = 'style';
export const DIRECTION = 'direction';
export const BORDER = 'border';

export const IS_MAIN = 'isMain';
export const URL = 'url';
export const TITLE = 'title';
export const DESCRIPTION = 'description';
export const KEYWORDS = 'keywords';
export const AUTHOR = 'author';
export const CANONICAL = 'canonical';
export const ROBOTS = 'robots';

export const TEXT_ALIGNMENT = 'textAlignment';
export const WEIGHT = 'weight';
export const FONT_FAMILY = 'fontFamily';
export const LEADING = 'leading';
export const SPACING = 'spacing';
export const DEFAULT = 'default';
export const CONTENT = 'content';
