import { type PayloadAction } from '@reduxjs/toolkit';

import { type ItemsType } from '..';
import {
  ContentType,
  FontFamilyType,
  FontSizeType,
  FontStyleType,
  LanguagesType,
  LeadingType,
  SpacingType,
  TextAlignmentType,
  WeightType
} from '@globalTypes/itemProperties';
import { checkResponsiveCase } from './itemUtils';

interface TextAlignmentPayload {
  [TextAlignmentType.TextAlignmentProperty]: TextAlignmentType.TextAlignmentType;
  id: string;
};
export const changeTextAlignment = (state: ItemsType, action: PayloadAction<TextAlignmentPayload>): void => {
  const { id } = action.payload;
  const textAlignment = action.payload[TextAlignmentType.TextAlignmentProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.textAlignment = textAlignment;
    }
  } else if (TextAlignmentType.TextAlignmentProperty in item) {
    checkResponsiveCase(state, id, textAlignment, 'textAlignment',
      item[TextAlignmentType.TextAlignmentProperty]);
    item[TextAlignmentType.TextAlignmentProperty] = textAlignment;
  }
};

interface TextWeightPayload {
  [WeightType.WeightProperty]: WeightType.WeightType;
  id: string;
};
export const changeTextWeight = (state: ItemsType, action: PayloadAction<TextWeightPayload>): void => {
  const { id } = action.payload;
  const textWeight = action.payload[WeightType.WeightProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.weight = textWeight;
    }
  } else if (WeightType.WeightProperty in item) {
    checkResponsiveCase(state, id, { value: textWeight, unit: 'px' }, 'textWeight',
      { value: item[WeightType.WeightProperty], unit: 'px' });
    item[WeightType.WeightProperty] = textWeight;
  }
};

interface TextStylePayload {
  [FontStyleType.FontStyleProperty]: string;
  id: string;
};
export const changeTextStyle = (state: ItemsType, action: PayloadAction<TextStylePayload>): void => {
  const { id } = action.payload;
  const textStyle = action.payload[FontStyleType.FontStyleProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.style = textStyle;
    }
  } else if (FontStyleType.FontStyleProperty in item) {
    checkResponsiveCase(state, id, textStyle, 'style', item[FontStyleType.FontStyleProperty]);
    item[FontStyleType.FontStyleProperty] = textStyle;
  }
};

interface FontSizePayload {
  [FontSizeType.FontSizeProperty]: FontSizeType.FontSizeType;
  id: string;
};
export const changeFontSize = (state: ItemsType, action: PayloadAction<FontSizePayload>): void => {
  const { id } = action.payload;
  const fontSize = action.payload[FontSizeType.FontSizeProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.fontSize = fontSize;
    }
  } else if (FontSizeType.FontSizeProperty in item) {
    checkResponsiveCase(state, id, fontSize, 'fontSize', item[FontSizeType.FontSizeProperty]);
    item[FontSizeType.FontSizeProperty] = fontSize;
  }
};

interface FontFamilyPayload {
  [FontFamilyType.FontFamilyProperty]: FontFamilyType.FontFamilyType;
  id: string;
};
export const changeFontFamily = (state: ItemsType, action: PayloadAction<FontFamilyPayload>): void => {
  const { id } = action.payload;
  const fontFamily = action.payload[FontFamilyType.FontFamilyProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.fontFamily = fontFamily;
    }
  } else if (FontFamilyType.FontFamilyProperty in item) {
    checkResponsiveCase(state, id, fontFamily, 'fontFamily', item[FontFamilyType.FontFamilyProperty]);
    item[FontFamilyType.FontFamilyProperty] = fontFamily;
  }
};

interface TextLeadingPayload {
  [LeadingType.LeadingProperty]: LeadingType.LeadingType;
  id: string;
};
export const changeTextLeading = (state: ItemsType, action: PayloadAction<TextLeadingPayload>): void => {
  const { id } = action.payload;
  const textLeading = action.payload[LeadingType.LeadingProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.leading = textLeading;
    }
  } else if (LeadingType.LeadingProperty in item) {
    checkResponsiveCase(state, id, { value: textLeading, unit: 'px' }, 'leading',
      { value: item[LeadingType.LeadingProperty], unit: 'px' });
    item[LeadingType.LeadingProperty] = textLeading;
  }
};

interface TextSpacingPayload {
  [SpacingType.SpacingProperty]: SpacingType.SpacingType;
  id: string;
};
export const changeTextSpacing = (state: ItemsType, action: PayloadAction<TextSpacingPayload>): void => {
  const { id } = action.payload;
  const textSpacing = action.payload[SpacingType.SpacingProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.spacing = textSpacing;
    }
  } else if (SpacingType.SpacingProperty in item) {
    checkResponsiveCase(state, id, { value: textSpacing, unit: 'px' }, 'spacing',
      { value: item[SpacingType.SpacingProperty], unit: 'px' });
    item[SpacingType.SpacingProperty] = textSpacing;
  }
};

interface TextContentPayload {
  [LanguagesType.LanguagesProperty]: LanguagesType.LanguagesType;
  id: string;
};
export const changeTextContent = (state: ItemsType, action: PayloadAction<TextContentPayload>): void => {
  const { id } = action.payload;
  const textContent = action.payload[LanguagesType.LanguagesProperty];
  const item = state.items.byId[id];
  if (ContentType.ContentProperty in item) {
    const content = item[ContentType.ContentProperty];
    if (LanguagesType.LanguagesProperty in content) {
      checkResponsiveCase(state, id, { content: { default: textContent } }, 'text',
        { content: { default: content[LanguagesType.LanguagesProperty] } });
      content[LanguagesType.LanguagesProperty] = textContent;
    }
  }
};
