import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BRACKET_CLOSE,
  BRACKET_OPEN,
  DEFAULT,
  DEFAULT_THEME,
  DESKTOP,
  LINE_JUMP,
  MAX_WIDTH,
  MEDIA_DEF,
  MEDIA_DEF_CLOSE,
  MEDIA_DESKTOP,
  MEDIA_PHONE,
  MEDIA_TABLET,
  MIN_WIDTH,
  ONLY_ROOT,
  PHONE,
  POINT,
  RESPONSIVENESS_THEME,
  ROOT,
  SEMICOLON_LINE_JUMP,
  TABLET,
  TWO_POINTS_SPACE,
  UNDEFINED,
  UNDERSCORE,
  UNIT_PX,
  VAR
} from '../../constants/constants';
import {
  type IComponentinfo,
  type TypeAttributeReferences,
  type ReactInfo,
  type TypeVariableValues,
  type TypeVariable,
  type TypeThemeInfo,
  type TypeResponsiveValues,
  type TypeValuesInfo
} from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';
import { getConvertNametoReact } from '../reactItemMethods/reactHTMLMethods/reactItemUtils';
import { createGroupRefereneces } from './reactGroupReferencesMethods';
import { getPage } from './reactThemeUtils';
import { convertVariableinfo } from './reactVariableMethods';

export function loadResponsiveRefs (theme: string, viewDOM: IComponentinfo): Record<string, boolean> {
  const responsiveRefs: Record<string, boolean> = {};
  if (theme !== DEFAULT_THEME && theme !== RESPONSIVENESS_THEME) {
    const componentName = theme;
    const component = viewDOM.getComponentByName(componentName, viewDOM.components);
    if (component?.references == null) {
      window.logger.warn('component ' + componentName + ' not found');
      return responsiveRefs;
    }
    const cRef = component.references.variables;
    for (const ref in cRef) {
      const mVal = cRef[ref].type;
      if (mVal === RESPONSIVENESS_THEME) responsiveRefs[ref] = true;
    }
  }
  return responsiveRefs;
}

export function addDeviceResponsiveValuesCode (theme: string, place: string, i: number, j: number,
  query: string, neededInfo: ReactInfo, responsiveValuesCode: string[]): void {
  if (theme === DEFAULT_THEME || theme === RESPONSIVENESS_THEME) {
    neededInfo.variablesDefaultResponsive[i] += responsiveValuesCode[j];
    neededInfo.classnamesDefaultResponsive[i] += neededInfo.aditionalTextVariable[j];
  } else {
    neededInfo.responsiveVariables[theme] += MEDIA_DEF + query + UNIT_PX + MEDIA_DEF_CLOSE + place +
      BRACKET_OPEN + responsiveValuesCode[j] + BRACKET_CLOSE + neededInfo.aditionalTextVariable[j] +
      BRACKET_CLOSE;
  }
}

export function addResponsiveValuesCode (responsiveValuesCode: string[], theme: string, themeName: string,
  neededInfo: ReactInfo): void {
  if (responsiveValuesCode[0].length > 0 ||
      responsiveValuesCode[1].length > 0 ||
      responsiveValuesCode[2].length > 0) {
    const place = theme === DEFAULT_THEME || theme === RESPONSIVENESS_THEME
      ? ONLY_ROOT
      : POINT + theme.substring(0, 1).toLowerCase() + theme.substring(1);
    if (responsiveValuesCode[2].length > 0) {
      addDeviceResponsiveValuesCode(theme, place, 0, 2, MEDIA_PHONE, neededInfo, responsiveValuesCode);
    }
    if (responsiveValuesCode[0].length > 0) {
      addDeviceResponsiveValuesCode(theme, place, 1, 0, MEDIA_DESKTOP, neededInfo, responsiveValuesCode);
    }
    if (responsiveValuesCode[1].length > 0) {
      addDeviceResponsiveValuesCode(theme, place, 2, 1, MEDIA_TABLET, neededInfo, responsiveValuesCode);
    }
    neededInfo.responsiveVariables[themeName] += LINE_JUMP;
  }
}

export function responsivenessThemeCreation (neededInfo: ReactInfo, theme: string, variable: string,
  values: TypeVariableValues, dictionary: TypeDictionary, references: TypeAttributeReferences): void {
  let isResponsiveInClass = false;
  for (let i = 0; i < neededInfo.responsiveVariableInClass.length && !isResponsiveInClass; i++) {
    const val = neededInfo.responsiveVariableInClass[i];
    if ((val.id + UNDERSCORE + val.property) === variable) {
      isResponsiveInClass = true;
    }
  }
  if (isResponsiveInClass) {
    const value = convertVariableinfo(theme, variable, values[theme][variable],
      neededInfo, RESPONSIVENESS_THEME, false, dictionary);
    if (value !== '') {
      neededInfo.variableList += VAR + variable + TWO_POINTS_SPACE + value + SEMICOLON_LINE_JUMP;
    }
  } else {
    const page = getPage(variable, references, neededInfo.items);
    if (typeof (page) !== 'boolean') {
      const pageName = getConvertNametoReact(page.name);
      if (neededInfo.pagesExtraStyles[pageName] == null) neededInfo.pagesExtraStyles[pageName] = '';
      const otherInfo = { ...neededInfo };
      otherInfo.extraStyles[pageName] = neededInfo.pagesExtraStyles[pageName];
      convertVariableinfo(theme, variable, values[theme][variable], otherInfo, RESPONSIVENESS_THEME,
        false, dictionary);
      neededInfo.pagesExtraStyles[pageName] = otherInfo.extraStyles[pageName];
    }
  }
}

export function defaultThemeCreation (neededInfo: ReactInfo, theme: string, variable: string,
  values: TypeVariableValues, dictionary: TypeDictionary): void {
  if (neededInfo.responsiveValues.desktop[theme][variable] == null) {
    const value = convertVariableinfo(theme, variable, values[theme][variable], neededInfo, DEFAULT,
      false, dictionary);
    if (value !== '') {
      neededInfo.themeList += VAR + variable + TWO_POINTS_SPACE + value + SEMICOLON_LINE_JUMP;
    }
  }
}

export function responsivenessPageTheme (page: TypeItemProperties, neededInfo: ReactInfo,
  dictionary: TypeDictionary, j: number, theme: string, variable: string, valueJson: TypeVariable): void {
  const pageName = getConvertNametoReact(page.name);
  if (neededInfo.pagesExtraStyles[pageName] == null) neededInfo.pagesExtraStyles[pageName] = '';
  if (neededInfo.pagesResponsiveVariables[pageName] == null) {
    neededInfo.pagesResponsiveVariables[pageName] = ['', '', ''];
  }
  const devicePlace = [1, 2, 0];
  if (neededInfo.pagesResponsiveVariables[pageName][devicePlace[j]] == null) {
    neededInfo.pagesResponsiveVariables[pageName][devicePlace[j]] = '';
  }
  const otherInfo = { ...neededInfo };
  otherInfo.variableList = neededInfo.pagesResponsiveVariables[pageName][devicePlace[j]];
  otherInfo.extraStyles[pageName] = neededInfo.pagesExtraStyles[pageName];
  const value = convertVariableinfo(theme, variable, valueJson, otherInfo, RESPONSIVENESS_THEME,
    false, dictionary);
  neededInfo.pagesResponsiveVariables[pageName][devicePlace[j]] = otherInfo.variableList;
  neededInfo.pagesExtraStyles[pageName] = otherInfo.extraStyles[pageName];
  if (value !== '') {
    const mVal = VAR + variable + TWO_POINTS_SPACE + value + SEMICOLON_LINE_JUMP;
    neededInfo.pagesResponsiveVariables[pageName][devicePlace[j]] += mVal;
  }
}

export function responsivenessResponsiveTheme (theme: string, variable: string, neededInfo: ReactInfo,
  valuesInfo: TypeValuesInfo, themeInfo: TypeThemeInfo, dictionary: TypeDictionary, j: number): void {
  const device = themeInfo.device[j];
  let isResponsiveInClass = false;
  for (let i = 0; i < neededInfo.responsiveVariableInClass.length && !isResponsiveInClass; i++) {
    const val = neededInfo.responsiveVariableInClass[i];
    if ((val.id + UNDERSCORE + val.property) === variable) isResponsiveInClass = true;
  }
  if (isResponsiveInClass) {
    defaultResponsiveTheme(theme, variable, neededInfo, valuesInfo.responsiveValues,
      themeInfo, dictionary, j);
  } else {
    const page = getPage(variable, valuesInfo.references, neededInfo.items);
    if (typeof (page) !== 'boolean') {
      const resDevice = valuesInfo.responsiveValues[device as keyof typeof valuesInfo.responsiveValues];
      const valueJson = resDevice[theme][variable];
      responsivenessPageTheme(page, neededInfo, dictionary, j, theme, variable, valueJson);
    }
  }
}

export function defaultResponsiveTheme (theme: string, variable: string, neededInfo: ReactInfo,
  responsiveValues: TypeResponsiveValues, themeInfo: TypeThemeInfo, dictionary: TypeDictionary,
  j: number): void {
  const device = themeInfo.device[j];
  const valueJson = responsiveValues[device as keyof typeof responsiveValues][theme][variable];
  if (j === 0) themeInfo.desktopVariableValues.push(valueJson.value as string);
  const otherInfo = { ...neededInfo };
  otherInfo.variableList = themeInfo.responsiveValuesCode[j];
  otherInfo.extraStyles = neededInfo.extraStyles;
  otherInfo.aditionalTextVariable = themeInfo.aditionalTextVariable;
  const value = convertVariableinfo(theme, variable, valueJson, otherInfo, RESPONSIVENESS_THEME,
    false, dictionary);
  themeInfo.responsiveValuesCode[j] = otherInfo.variableList;
  neededInfo.extraStyles = otherInfo.extraStyles;
  themeInfo.aditionalTextVariable = otherInfo.aditionalTextVariable;
  if (value !== '') {
    themeInfo.responsiveValuesCode[j] += VAR + variable + TWO_POINTS_SPACE + value + SEMICOLON_LINE_JUMP;
  }
}

export function addResponsiveThemeVariables (themeInfo: TypeThemeInfo, theme: string, neededInfo: ReactInfo,
  valuesInfo: TypeValuesInfo, dictionary: TypeDictionary, responsiveRefs: Record<string, boolean>): void {
  for (let j = 0; j < 3; j++) {
    const device = themeInfo.device[j];
    const aTheme = valuesInfo.responsiveValues[device as keyof typeof valuesInfo.responsiveValues][theme];
    for (const variable in aTheme) {
      if (theme === RESPONSIVENESS_THEME) {
        responsivenessResponsiveTheme(theme, variable, neededInfo, valuesInfo,
          themeInfo, dictionary, j);
      } else if ((theme === DEFAULT_THEME) || responsiveRefs[variable] != null) {
        defaultResponsiveTheme(theme, variable, neededInfo, valuesInfo.responsiveValues,
          themeInfo, dictionary, j);
      }
    }
  }
}

export function createThemeStyles (theme: string, neededInfo: ReactInfo, variable: string,
  valuesInfo: TypeValuesInfo, responsiveRefs: Record<string, boolean>, dictionary: TypeDictionary): void {
  if (theme === RESPONSIVENESS_THEME) {
    responsivenessThemeCreation(neededInfo, theme, variable, valuesInfo.values, dictionary,
      valuesInfo.references);
  } else if ((theme === DEFAULT_THEME) || responsiveRefs[variable] != null) {
    defaultThemeCreation(neededInfo, theme, variable, valuesInfo.values, dictionary);
  }
}

export function themeCreation (theme: string, viewDOM: IComponentinfo, neededInfo: ReactInfo,
  valuesInfo: TypeValuesInfo, extraStyles: Record<string, string>, dictionary: TypeDictionary): void {
  if (!theme.includes(UNDERSCORE)) {
    const responsiveRefs = loadResponsiveRefs(theme, viewDOM);
    extraStyles[theme] = '';
    for (const variable in valuesInfo.values[theme]) {
      createThemeStyles(theme, neededInfo, variable, valuesInfo, responsiveRefs, dictionary);
    }
    const themeInfo: TypeThemeInfo = {
      variableList: '',
      responsiveValuesCode: ['', '', ''],
      device: [DESKTOP, TABLET, PHONE],
      desktopVariableValues: [],
      aditionalTextVariable: ['', '', ''],
    };
    addResponsiveThemeVariables(themeInfo, theme, neededInfo, valuesInfo, dictionary, responsiveRefs);
    const themeName = getConvertNametoReact(theme);
    neededInfo.responsiveVariables[themeName] = '';
    addResponsiveValuesCode(themeInfo.responsiveValuesCode, theme, themeName, neededInfo);
    if (theme === DEFAULT_THEME || theme === RESPONSIVENESS_THEME) {
      neededInfo.themeList += themeInfo.variableList;
    } else {
      const styleVal = [themeInfo.variableList,
        extraStyles[themeName] + neededInfo.responsiveVariables[themeName]];
      neededInfo.stylesComponent[themeName] = styleVal;
      neededInfo.hasVariables[themeName] = true;
    }
  }
}

export function getVariableIndex (neededInfo: ReactInfo): string {
  let variablesIndex = '';
  for (const device in neededInfo.variablesDefaultResponsive) {
    if (neededInfo.variablesDefaultResponsive[device] !== '') {
      const mVal = LINE_JUMP + ROOT + neededInfo.variablesDefaultResponsive[device] + BRACKET_CLOSE;
      neededInfo.variablesDefaultResponsive[device] = mVal;
    }
  }
  let mediaP = MEDIA_PHONE;
  if (neededInfo.mediaValues != null) {
    mediaP = MIN_WIDTH + neededInfo.mediaValues.phone.value + neededInfo.mediaValues.phone.unit;
  }
  let mediaT = MEDIA_TABLET;
  if (neededInfo.mediaValues != null) {
    mediaT = MAX_WIDTH + (neededInfo.mediaValues.desktop.value - 1) +
      neededInfo.mediaValues.desktop.unit + ') and (' + MIN_WIDTH + (neededInfo.mediaValues.phone.value +
      neededInfo.mediaValues.phone.unit + 1);
  }
  let mediaD = MEDIA_DESKTOP;
  if (neededInfo.mediaValues != null) {
    mediaD = MIN_WIDTH + neededInfo.mediaValues.desktop.value + neededInfo.mediaValues.desktop.unit;
  }
  const querys = [MEDIA_DEF + mediaP + MEDIA_DEF_CLOSE,
    MEDIA_DEF + mediaD + MEDIA_DEF_CLOSE,
    MEDIA_DEF + mediaT + MEDIA_DEF_CLOSE];
  for (let i = 0; i < 3; i++) {
    const defaultVariables = neededInfo.variablesDefaultResponsive[i];
    const defaultClassnames = neededInfo.classnamesDefaultResponsive[i];
    const groupClassnames = neededInfo.classNameGroups[i];
    if (defaultVariables !== '' || defaultClassnames !== '' || groupClassnames !== '') {
      variablesIndex += querys[i] + defaultVariables + defaultClassnames + groupClassnames + BRACKET_CLOSE;
    }
  }
  return variablesIndex;
}

export function createThemes (values: TypeVariableValues, responsiveValues: TypeResponsiveValues,
  references: TypeAttributeReferences, viewDOM: IComponentinfo, neededInfo: ReactInfo,
  dictionary: TypeDictionary): void {
  neededInfo.themeList = '';
  neededInfo.groupList = '';
  neededInfo.pagesExtraStyles = {};
  neededInfo.pagesResponsiveVariables = {};
  neededInfo.responsiveVariables = {};
  neededInfo.classNameGroups = ['', '', ''];
  createGroupRefereneces(neededInfo, dictionary, viewDOM);
  const extraStyles: Record<string, string> = {};
  neededInfo.variablesDefaultResponsive = ['', '', ''];
  neededInfo.classnamesDefaultResponsive = ['', '', ''];
  const valuesInfo: TypeValuesInfo = { responsiveValues, references, values };
  for (const theme in values) {
    themeCreation(theme, viewDOM, neededInfo, valuesInfo, extraStyles, dictionary);
  }
  const variablesIndex = getVariableIndex(neededInfo);
  const tList = neededInfo.themeList !== '' ? ROOT + neededInfo.themeList + BRACKET_CLOSE : '';
  const indexVal = tList + extraStyles.Default + neededInfo.groupList + variablesIndex;
  neededInfo.themes = indexVal.replaceAll(UNDEFINED, '');
}
