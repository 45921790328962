import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMainMenuTab } from '@reducers/editor';
import { getCurrentFileName } from '@reducers/files';
import { getPath, setCurrentFileToShow, setCurrentGraphTab } from '@reducers/graphs';

/*
import { CodeEditorLayout } from '@layouts/codeEditorLayout';

import { type EditorTabProps } from '@components/editorTab';
import { EditorTabViewer } from '@components/editorTabViewer';
*/

export const CodeEditorPage = (): React.JSX.Element => {
  /*
  const [activeTab, setActiveTab] = useState<string>('');
  const [openTabs, setOpenTabs] = useState<EditorTabProps[]>([]);

  const notifyTabClosed = (tabId: string): void => {
    const newTabs = [];
    for (const tab of openTabs) if (tab.id !== tabId) newTabs.push(tab);
    setOpenTabs(newTabs);
  };

  const notifyTabClicked = (tab: string): void => {
    setActiveTab(tab);
  };

  const editorSection = (
    <EditorTabViewer
      openTabs={openTabs}
      activeTab={activeTab}
      notifyTabClosed={notifyTabClosed}
      notifyTabClicked={notifyTabClicked}
    />
  );
  */

  const dispatch = useDispatch();

  // const foldersSection = <div>Folders</div>;

  // return <CodeEditorLayout folders={foldersSection} editor={editorSection} />;

  const path = useSelector(getPath);
  const currentFile = useSelector(getCurrentFileName);
  const loaded = useRef<boolean>(false);

  const handleGraphClick = (event: any): void => {
    const type = event?.data?.sender;
    if (type !== 'vsiconClick') return;
    dispatch(setCurrentFileToShow(currentFile));
    dispatch(setMainMenuTab('graphs'));
    dispatch(setCurrentGraphTab('dependencies'));
  };

  useEffect(() => {
    if (loaded.current) return;
    if (path === '') return;
    (window as any).mountCodeIframe(path);
    return () => {
      (window as any).unmountCodeIframe(path);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleGraphClick);
    return () => {
      window.removeEventListener('message', handleGraphClick);
    };
  }, [currentFile]);

  return <div style={{ width: '100%', height: '100%' }}></div>;
};
