import {
  type TypeAttributeReferenceInternalTheme,
  type TypeAttributeReference
} from '@globalTypes/conversor';
import { type ItemsType } from '..';

const typeIdentifier: Record<string, string> = {
  valuewidth: 'dimension',
  valueheight: 'dimension',
  'margin-top': 'size',
  'margin-bottom': 'size',
  'margin-right': 'size',
  'margin-left': 'size',
  'padding-top': 'size',
  'padding-bottom': 'size',
  'padding-right': 'size',
  'padding-left': 'size',
  'position-top': 'size',
  'position-bottom': 'size',
  'position-right': 'size',
  'position-left': 'size',
  backgroundColor: 'color',
  background: 'color',
  cursorPointer: 'asset',
  visible: 'boolean',
  wrap: 'boolean',
  verticalAlignment: 'alignment',
  horizontalAlignment: 'alignment',
  orientation: 'orientation',
  scrollable: 'boolean',
  textAlignment: 'alignment',
  textWeight: 'size',
  style: 'asset',
  spacing: 'size',
  leading: 'size',
  fontSize: 'size',
  fontFamily: 'asset',
  text: 'text',
  icon: 'text',
  src: 'asset',
  borderWidth: 'size',
  'border-topwidth': 'size',
  'border-bottomwidth': 'size',
  'border-leftwidth': 'size',
  'border-rightwidth': 'size',
  borderColor: 'color',
  'border-topcolor': 'color',
  'border-bottomcolor': 'color',
  'border-leftcolor': 'color',
  'border-rightcolor': 'color',
  radiusAbsolute: 'size',
  'border-topLeft': 'size',
  'border-topRight': 'size',
  'border-bottomRight': 'size',
  'border-bottomLeft': 'size',
  shadowColor: 'color',
  shadowOffsetX: 'size',
  shadowOffsetY: 'size',
  shadowBlur: 'size',
};

export const checkResponsiveCase = (state: ItemsType, id: string, value: any, property: string,
  prevVal: any): void => {
  const references = getItemReferences(state, id);
  const item = state.items.byId[id];
  const isResponsiveValue = references[property]?.includes('_');
  if (state.currentDevice !== 'desktop' || isResponsiveValue) {
    const varName = (item.nameChanged != null && item.nameChanged ? item.name : item.id) + '_' + property;
    const typeCheck = typeIdentifier[property];
    const modelValues = state.variables.model;
    const currentDevice = state.currentDevice;
    if (!isResponsiveValue) {
      const attributeReferenceResponsivenes =
        modelValues.attributeReference.Responsiveness as TypeAttributeReferenceInternalTheme;
      attributeReferenceResponsivenes[varName] = {};
      attributeReferenceResponsivenes[varName][id] = [];
      attributeReferenceResponsivenes[varName][id].push({
        id,
        theme: 'Responsiveness',
        variable: varName,
        attribute: property,
      });
      const desktopResponsiveness = modelValues.responsiveReferenceValues.desktop.Responsiveness;
      const tabletResponsiveness = modelValues.responsiveReferenceValues.tablet.Responsiveness;
      const phoneResponsiveness = modelValues.responsiveReferenceValues.phone.Responsiveness;
      switch (typeCheck) {
        case 'dimension':
          modelValues.referenceValues.Responsiveness[varName] = { dimension: true, value };
          desktopResponsiveness[varName] = {
            dimension: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            dimension: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            dimension: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'size':
          modelValues.referenceValues.Responsiveness[varName] = { size: true, value };
          desktopResponsiveness[varName] = {
            size: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            size: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            size: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'boolean': {
          const pKind = property === 'scrollable' ? 'Scrollable' : 'Wrap';
          const kind = property === 'visible' ? 'Visibility' : pKind;
          modelValues.referenceValues.Responsiveness[varName] = { boolean: true, value, kind };
          desktopResponsiveness[varName] = {
            boolean: true,
            value: currentDevice === 'desktop' ? value : prevVal,
            kind
          };
          tabletResponsiveness[varName] = {
            boolean: true,
            value: currentDevice === 'tablet' ? value : prevVal,
            kind
          };
          phoneResponsiveness[varName] = {
            boolean: true,
            value: currentDevice === 'phone' ? value : prevVal,
            kind
          };
          break;
        }
        case 'color':
          modelValues.referenceValues.Responsiveness[varName] = { color: true, value };
          desktopResponsiveness[varName] = {
            color: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            color: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            color: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'text':
          modelValues.referenceValues.Responsiveness[varName] = { text: true, value };
          desktopResponsiveness[varName] = {
            text: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            text: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            text: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'typography':
          modelValues.referenceValues.Responsiveness[varName] = { typography: true, value };
          desktopResponsiveness[varName] = {
            typography: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            typography: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            typography: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'asset':
          modelValues.referenceValues.Responsiveness[varName] = { asset: true, value };
          desktopResponsiveness[varName] = {
            asset: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            asset: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            asset: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'orientation':
          modelValues.referenceValues.Responsiveness[varName] = { orientation: true, value };
          desktopResponsiveness[varName] = {
            orientation: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            orientation: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            orientation: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
        case 'alignment':
          modelValues.referenceValues.Responsiveness[varName] = { alignment: true, value };
          desktopResponsiveness[varName] = {
            alignment: true,
            value: currentDevice === 'desktop' ? value : prevVal
          };
          tabletResponsiveness[varName] = {
            alignment: true,
            value: currentDevice === 'tablet' ? value : prevVal
          };
          phoneResponsiveness[varName] = {
            alignment: true,
            value: currentDevice === 'phone' ? value : prevVal
          };
          break;
      }
    } else {
      const responsiveReferenceValues = modelValues.responsiveReferenceValues;
      const deviceKey = state.currentDevice as keyof typeof responsiveReferenceValues;
      const responsiveDeviceTheme = responsiveReferenceValues[deviceKey].Responsiveness;
      const varValue = responsiveDeviceTheme[varName];
      if (varValue != null && typeCheck in varValue) {
        varValue.value = value;
      }
    }
  }
};

export const getItemReferences = (state: ItemsType, id: string): Record<string, string> => {
  const references: Record<string, string> = {};
  const defaultTheme = state.variables.model.attributeReference.Default;
  for (const variable in defaultTheme) {
    const idList = defaultTheme[variable];
    if (idList[id] != null) {
      const vReferences = idList[id] as TypeAttributeReference[];
      for (const infoRef of vReferences) {
        references[infoRef.attribute] = variable;
      }
    }
  }
  const responsivenessTheme = state.variables.model.attributeReference.Responsiveness;
  for (const variable in responsivenessTheme) {
    const idList = responsivenessTheme[variable];
    if (idList[id] != null) {
      const vReferences = idList[id] as TypeAttributeReference[];
      for (const infoRef of vReferences) {
        if (infoRef.attribute === 'fontStyles') {
          references.fontFamily = 'fontFamily_' + variable;
          references.fontSize = 'fontSize_' + variable;
          references.leading = 'leading_' + variable;
          references.spacing = 'spacing_' + variable;
          references.weight = 'weight_' + variable;
        } else references[infoRef.attribute] = variable;
      }
    }
  }
  return references;
};
