/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';

import { type IIconSize, Icon, Tooltip } from '@components/index';

import styles from './styles.module.css';

export const OptionSelector = ({
  notifyChange,
  defaultOption = '',
  options,
  size = 'large',
  width = 'var(--button-height)',
  height = 'var(--button-height)',
  padding = '0px',
}: {
  notifyChange: (val: string) => any;
  defaultOption?: string;
  options: Array<{
    icon: string | JSX.Element;
    value: string;
    hint?: string;
  }>;
  size?: IIconSize;
  width?: string;
  height?: string;
  padding?: string;
}): React.JSX.Element => {
  const [currentlySelected, setCurrentlySelected] = useState<string>(defaultOption);

  useEffect(() => {
    if (defaultOption !== currentlySelected) setCurrentlySelected(defaultOption);
  }, [defaultOption]);

  const handleChange = (val: string): void => {
    if (val !== currentlySelected) {
      setCurrentlySelected(val);
      if (notifyChange != null) notifyChange(val);
    }
  };

  return (
    <div className={styles.container}>
      {options.map((option) => {
        let icon;
        if (typeof option.icon === 'string') {
          icon = <Icon icon={option.icon} color="var(--title-color)" iconSize={size} bold />;
        } else {
          icon = option.icon;
        }
        const content = (
          <div
            onClick={() => {
              handleChange(option.value);
            }}
            style={{ width, height, padding }}
            className={
              styles.option + (currentlySelected === option.value ? ' ' + styles.selectedOption : '')
            }
          >
            {icon}
          </div>
        );
        if (option.hint == null) return <React.Fragment key={option.value}>{content}</React.Fragment>;
        else {
          return (
            <Tooltip key={option.value} text={option.hint ?? ''}>
              {content}
            </Tooltip>
          );
        }
      })}
    </div>
  );
};
