import { adaptItemName, referenceValue } from '../reactHTMLMethods/reactItemUtils';
import { externalLayerStyle } from '../reactHTMLMethods/propertyTypeMethods/reactLayerCSSMethods';
import { getTextStyles } from '../reactHTMLMethods/propertyTypeMethods/reactTextStylesMethods';
import { paddingAndMarginStyle } from '../reactHTMLMethods/propertyTypeMethods/reactPaddingAndMarginMethods';
import { backgroundColor } from '../reactHTMLMethods/propertyTypeMethods/reactColorMethods';
import { dimensions } from '../reactHTMLMethods/propertyTypeMethods/reactDimensionMethods';
import { getReferenceItem } from './reactReferenceMethods';
import { handleItemState } from './reactVariantMethods';
import { updatePageReferences } from '../../reactFileMethods/reactFileUtils';
import {
  AGUA_ITEM,
  AGUA_ITEM_CLOSE,
  ANIMATE,
  ANIMATED,
  ANIMATE_INFINITE,
  ANIMATION_BRACKET,
  ANTD_FORM,
  BACKGROUND_COLOR,
  BORDER_BOX,
  BOX_SIZING,
  BOX_SIZING_BRACKET,
  BRACKET,
  BRACKET_ID,
  BRACKET_ID_MINUS,
  BRACKET_NAME,
  CLOSE_BRACKET,
  COLOR_BRACKET,
  COMPONENT_VARIABLE,
  CURSOR_BRACKET,
  CURSOR_POINTER_CSS,
  DIMENSIONS_BRACKET,
  DISPLAY,
  ICON,
  ICON_COLOR,
  IMAGE_VIEW,
  LAYER,
  LINE_JUMP,
  MARGIN,
  MARGIN_BRACKET,
  MINUS,
  MSTYLE_CLOSE,
  NONE,
  PADDING,
  POINT,
  REFERENCE_BRACKET,
  REF_BRACKET,
  SEMICOLON,
  SEMICOLON_LINE_JUMP,
  TEXT,
  TEXT_STYLE_BRACKET,
  TWO_POINTS_SPACE,
  UNDERSCORE,
  VARIABLE_CONTENT,
  VISIBILITY_BRACKET,
  VISIBLE
} from '../../../constants/constants';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { type ReactInfo, type IComponentinfo } from '@globalTypes/conversor';
import { type TypeDictionary } from '../../dictionary/dictionary';

export function kindRelatedStyles (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  let style = dictionary[(item.commonKind + MSTYLE_CLOSE) as keyof typeof dictionary];
  if (item.commonKind === LAYER) {
    style = externalLayerStyle(item, style, dictionary, neededInfo.creatingComponent, neededInfo);
  } else if (item.commonKind === TEXT) {
    const mVal = getTextStyles(item, dictionary, neededInfo.files, neededInfo.proyectFonts, neededInfo);
    style = style.replace(TEXT_STYLE_BRACKET, mVal);
  }
  return style;
}

export function handleExternalStyleMargin (item: TypeItemProperties, neededInfo: ReactInfo,
  dictionary: TypeDictionary, style: string): string {
  const marginVal = paddingAndMarginStyle(item, MARGIN, neededInfo.creatingComponent, dictionary, neededInfo);
  style = style.replace(MARGIN_BRACKET, marginVal);
  if (style.includes(MARGIN) || style.includes(PADDING)) {
    style = style.replace(BOX_SIZING_BRACKET, LINE_JUMP + BOX_SIZING + TWO_POINTS_SPACE + BORDER_BOX +
      SEMICOLON);
  } else style = style.replace(BOX_SIZING_BRACKET, '');
  return style;
}

export function handleExternalStyleVisibility (item: TypeItemProperties, style: string): string {
  if (referenceValue(VISIBLE, item, false) === false) {
    if ('visible' in item && item.visible == null) style = style.replace(VISIBILITY_BRACKET, '');
    else {
      const visible = 'visible' in item && !item.visible
        ? LINE_JUMP + DISPLAY + TWO_POINTS_SPACE + NONE + SEMICOLON
        : '';
      style = style.replace(VISIBILITY_BRACKET, visible);
    }
  } else style = style.replace(VISIBILITY_BRACKET, '');
  return style;
}

export function externalStyle (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  let style = kindRelatedStyles(item, dictionary, neededInfo);
  style = handleExternalStyleMargin(item, neededInfo, dictionary, style);
  const color = (item.commonKind === ICON && referenceValue(ICON_COLOR, item, false) !== false) ||
        referenceValue(BACKGROUND_COLOR, item, false) !== false;
  let colorValue = '';
  if (item.commonKind !== IMAGE_VIEW && !color) colorValue = backgroundColor(item, dictionary, neededInfo);
  style = style.replace(COLOR_BRACKET, colorValue);
  const dimensionVal =
    dimensions(item, neededInfo.parent ?? item, dictionary, neededInfo.componentModeActive, neededInfo);
  style = style.replace(DIMENSIONS_BRACKET, dimensionVal);
  const cursorValue = 'cursorPointer' in item
    ? (item.cursorPointer.toLowerCase() === 'default'
        ? 'auto'
        : item.cursorPointer.toLowerCase())
    : '';
  const cursorClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.cursorPointer != null;
  const cursor = 'cursorPointer' in item && (item.cursorPointer !== 'default' || cursorClass)
    ? CURSOR_POINTER_CSS + cursorValue + SEMICOLON_LINE_JUMP
    : '';
  style = style.replace(CURSOR_BRACKET, cursor);
  let styleName = item.name;
  if (neededInfo.creatingComponent && item.nameChanged != null && 'component' in item &&
          item.component != null && item.component === neededInfo.pageName) {
    styleName = item.component.substring(0, 1).toLowerCase() + item.component.substring(1);
  }
  style = style.replace(BRACKET_NAME, styleName);
  if (item.nameChanged != null) style = style.replaceAll(BRACKET_ID_MINUS, '');
  else style = style.replace(BRACKET_ID, item.id);
  style = handleExternalStyleVisibility(item, style);
  return style;
}

export function handelDefaultStyleName (item: TypeItemProperties, style: string,
  neededInfo: ReactInfo): string {
  let className = '';
  if (item.nameChanged != null) className = item.name;
  else className = item.name + MINUS + item.id;
  const partVerification = 'component' in item && item.component != null &&
    item.component !== '' && item.component === neededInfo.pageName;
  if (neededInfo.creatingComponent && item.nameChanged != null && partVerification &&
      item.component !== '') {
    className = item.component.substring(0, 1).toLowerCase() + item.component.substring(1);
  }
  neededInfo.objectClassnames[item.id].push([className, true]);
  style = style.replace(POINT, ('tag' in item && item.tag !== ANTD_FORM ? item.tag : '') + POINT);
  style = style.replace(BRACKET, AGUA_ITEM + item.commonKind + AGUA_ITEM_CLOSE);
  return style;
}

export function addAnimationClassToItem (item: TypeItemProperties, nameVariable: string, pageName: string,
  component: string, dictionary: TypeDictionary, neededInfo: ReactInfo): string {
  let classObject = dictionary.classNameRef;
  let animationRef = dictionary.animationRef;
  let componentRef = dictionary.ref;
  classObject = classObject.replace(BRACKET_NAME, nameVariable);
  animationRef = animationRef.replaceAll(BRACKET_NAME, nameVariable);
  componentRef = componentRef.replace(BRACKET_NAME, nameVariable);
  const animation = 'animation' in item && item.animation != null ? item.animation : '';
  classObject = classObject.replace(ANIMATION_BRACKET, animation);
  if (item.nameChanged != null) {
    classObject = classObject.replaceAll(BRACKET_ID_MINUS, '');
    animationRef = animationRef.replaceAll(BRACKET_ID_MINUS, '');
    componentRef = componentRef.replaceAll(BRACKET_ID_MINUS, '');
  } else {
    classObject = classObject.replaceAll(BRACKET_ID, item.id);
    animationRef = animationRef.replaceAll(BRACKET_ID, item.id);
    componentRef = componentRef.replaceAll(BRACKET_ID, item.id);
  }
  neededInfo.objectClassnames[item.id].push([classObject, false]);
  neededInfo.pageAnimationVisible[pageName] += animationRef;
  return component.replace(REF_BRACKET, componentRef);
}

export function handleItemAnimationStyle (item: TypeItemProperties, pageName: string, component: string,
  neededInfo: ReactInfo, dictionary: TypeDictionary): string {
  if ('animation' in item && item.animation != null) {
    neededInfo.proyectAnimation = true;
    neededInfo.pageHasAnimation[pageName] = true;
    if (item.animationWhenVisible != null) {
      const nameVariable = adaptItemName(item.name, false).replaceAll(MINUS, UNDERSCORE);
      neededInfo.proyectAnimationVisible = true;
      component = addAnimationClassToItem(item, nameVariable, pageName, component, dictionary, neededInfo);
    } else {
      neededInfo.objectClassnames[item.id].push([ANIMATED, false]);
      if ('animationInfinite' in item && item.animationInfinite != null) {
        neededInfo.objectClassnames[item.id].push([ANIMATE_INFINITE, false]);
      }
      neededInfo.objectClassnames[item.id].push([ANIMATE + item.animation, false]);
      component = component.replace(REF_BRACKET, '');
    }
  } else component = component.replace(REF_BRACKET, '');
  return component;
}

export function handleVariableContentStyle (item: TypeItemProperties, component: string,
  neededInfo: ReactInfo): string {
  if (component.includes(VARIABLE_CONTENT)) {
    const specialCase = item.commonKind === IMAGE_VIEW || neededInfo.creatingComponent != null;
    const mVal = specialCase ? neededInfo.variableContent[item.id] : '';
    component = component.replace(VARIABLE_CONTENT, mVal);
  }
  return component;
}

/**
 * neededInfo: {
 *  parent,
 *  pageName,
 *  creatingComponent,
 *  filesId,
 *  poryectFonts,
 *  isPreview,
 *  componentsUsed,
 *  differentArchives,
 *  aditionalStyles,
 *  stylesComponent,
 *  styles,
 *  references,
 * proyectAnimationVisible,
 * proyectAnimation,
 *  infoThemes: valores de los temas y sus variables,
 *  variableContent: objeto con el contenido variable (Texto, iconos, imagenes),
 *  fillVariables: cuando hay responsive size y uno es fill,
 *  orientationVariables: cuando el orientation es variable,
 *  responsiveValues: los valores responsive de las variables,
 *  objectClassnames: las clases de los items,
 * }
 */
export function createElementStyle (item: TypeItemProperties, component: string, neededInfo: ReactInfo,
  dictionary: TypeDictionary, viewDOM: IComponentinfo): string {
  const name = item.name;
  neededInfo.aditionalStyles[item.id] = '';
  let style = externalStyle(item, dictionary, neededInfo);
  neededInfo.item = item;
  neededInfo.name = name;
  const references = getReferenceItem(viewDOM, neededInfo);
  style = style.replace(REFERENCE_BRACKET, references[0]);
  const refVal = neededInfo.creatingComponent ? references[2] : '';
  component = component.replace(REFERENCE_BRACKET, refVal);
  if ('component' in item && item.component != null && item.component !== '') {
    style = style.replace(COMPONENT_VARIABLE, neededInfo.stylesComponent[item.component]?.[0] ?? '');
  } else style = style.replace(COMPONENT_VARIABLE, '');
  const hasAttributes = style.replaceAll(' ', '').replaceAll(LINE_JUMP, '').indexOf(CLOSE_BRACKET) -
        style.replaceAll(' ', '').replaceAll(LINE_JUMP, '').indexOf(BRACKET);
  if (hasAttributes === 1) {
    style = '';
  } else {
    style = handelDefaultStyleName(item, style, neededInfo);
  }
  if ('customStyles' in item && item.customStyles != null) style += LINE_JUMP + item.customStyles;
  if (neededInfo.styles[neededInfo.pageName] == null) neededInfo.styles[neededInfo.pageName] = '';
  neededInfo.styles[neededInfo.pageName] += style + LINE_JUMP;
  const hasVariant = handleItemState(item, neededInfo.pageName, neededInfo, viewDOM, dictionary);
  component = component.replace('{variant}', hasVariant ? 'custom-agua-variant="true"' : '');
  updatePageReferences(neededInfo.pageName, references[1], neededInfo);
  component = handleVariableContentStyle(item, component, neededInfo);
  component = handleItemAnimationStyle(item, neededInfo.pageName, component, neededInfo, dictionary);
  return component;
}
