import { referenceValue } from '../reactItemUtils';
import { externalLayerOrientationAndAligmentStyle } from './reactOrientationAligmentMethods';
import { borderRadiusStyle, border } from './layerPropertyMethods/reactBorderMethods';
import { shadowStyle } from './layerPropertyMethods/reactShadowMethods';
import { paddingAndMarginStyle } from './reactPaddingAndMarginMethods';
import { position, zindex } from './layerPropertyMethods/reactPositionMethods';
import { wrap } from './layerPropertyMethods/reactWrapMethods';
import { scrollable } from './reactScrollableMethods';
import {
  BRACKET_BORDER,
  BRACKET_BORDER_STYLE,
  BRACKET_PADDING,
  BRACKET_POSITION,
  BRACKET_SCROLLABLE,
  BRACKET_SHADOW,
  BRACKET_WRAP,
  BRACKET_Z_INDEX,
  LINE_JUMP,
  PADDING,
  RADIUS_ABSOLUTE,
  SCROLLABLE
} from '../../../../constants/constants';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { type ReactInfo } from '@globalTypes/conversor';

export function externalLayerStyle (item: TypeItemProperties, style: string, dictionary: TypeDictionary,
  creatingComponent: boolean, neededInfo: ReactInfo): string {
  style = externalLayerOrientationAndAligmentStyle(item, style, dictionary, neededInfo);
  if (referenceValue(RADIUS_ABSOLUTE, item, false) === false) {
    style = style.replace(BRACKET_BORDER, LINE_JUMP + borderRadiusStyle(item, dictionary, neededInfo));
  } else style = style.replace(BRACKET_BORDER, '');
  style = style.replace(BRACKET_SHADOW, shadowStyle(item, dictionary));
  let scrollableLayer = '';
  if (referenceValue(SCROLLABLE, item, false) === false) {
    scrollableLayer = scrollable(item, dictionary, neededInfo);
  }
  style = style.replace(BRACKET_SCROLLABLE, scrollableLayer);
  style = style.replace(BRACKET_PADDING,
    paddingAndMarginStyle(item, PADDING, creatingComponent, dictionary, neededInfo));
  style = style.replace(BRACKET_POSITION, position(item, neededInfo));
  style = style.replace(BRACKET_WRAP, wrap(item, dictionary));
  style = style.replace(BRACKET_Z_INDEX, zindex(item));
  style = style.replace(BRACKET_BORDER_STYLE, border(item, creatingComponent, neededInfo));
  return style;
}
