import { adaptNameOfElement } from './reactItemUtils';
import { imageAttributes } from './propertyTypeMethods/reactImageMethods';
import { getContent, destinationAttribute } from './reactHTMLMethods';
import { createElementStyle } from '../reactCssMethods/reactCSSMethods';
import { handleItemClassNames } from './reactClassnamesMethods';
import { handleComponentTag } from './propertyTypeMethods/reactTypeMethods';
import {
  BRACKET_ID,
  BRACKET_NAME,
  CLOSE_LINK,
  DESTINATION,
  IMAGE_VIEW,
  OPEN_LINK,
  TEMPLATE
} from '../../../constants/constants';
import { type TypeItems, type ReactInfo, type IComponentinfo } from '@globalTypes/conversor';
import { type TypeDictionary } from '../../dictionary/dictionary';
import { type TypeItemProperties } from '@globalTypes/itemProperties';

export function startElement (kind: string, dictionary: TypeDictionary): string {
  const key = (kind + TEMPLATE) as keyof typeof dictionary;
  return dictionary[key];
}

/**
 * neededInfo: {
 *  parent,
 *  pageName,
 *  creatingComponent,
 *  filesId,
 *  poryectFonts,
 *  isPreview,
 *  componentsUsed,
 *  differentArchives,
 *  aditionalStyles,
 *  stylesComponent,
 *  styles,
 *  references,
 *  pageImages,
 *  canvas,
 *  infoThemes: valores de los temas y sus variables,
 *  variableContent: objeto con el contenido variable (Texto, iconos, imagenes),
 *  fillVariables: cuando hay responsive size y uno es fill,
 *  orientationVariables: cuando el orientation es variable,
 *  responsiveValues: los valores responsive de las variables,
 *  objectClassnames: las clases de los items,
 *  pageHas form,
 * }
 */
export function createElement (item: TypeItemProperties, component: string, items: TypeItems,
  dictionary: TypeDictionary, neededInfo: ReactInfo, viewDOM: IComponentinfo): string {
  component = adaptNameOfElement(item, component, neededInfo.objectClassnames);
  if (item.commonKind === IMAGE_VIEW) {
    component = imageAttributes(item, component, dictionary, neededInfo);
  }
  const parent = 'parent' in item ? items[item.parent] : item;
  component = getContent(item, component);
  neededInfo.parent = parent;
  component = createElementStyle(item, component, neededInfo, dictionary, viewDOM);
  component = component.replace(DESTINATION, destinationAttribute(item, dictionary, neededInfo));
  if (component.includes(OPEN_LINK)) {
    component = component + CLOSE_LINK;
    component = component.replaceAll(BRACKET_NAME, item.name);
    component = component.replaceAll(BRACKET_ID, item.id);
  }
  component = handleComponentTag(item, component, neededInfo);
  component = handleItemClassNames(item, component, neededInfo);
  return component;
}
