import { ANIMATION, ANIMATION_WHEN_VISIBLE } from '@globalTypes/constants';

export namespace AnimationType {
  export type AnimationProperty = 'animation';
  export const AnimationProperty: AnimationProperty = ANIMATION;

  export type AnimationType = string;
}

export namespace AnimationWhenVisibleType {
  export type AnimationWhenVisibleProperty = 'animationWhenVisible';
  export const AnimationWhenVisibleProperty: AnimationWhenVisibleProperty = ANIMATION_WHEN_VISIBLE;

  export type AnimationWhenVisibleType = boolean;
}
