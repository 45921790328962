import { ICON, IMAGE_VIEW, LAYER, TEXT } from '../../constants/constants';

export function isComponentALayout (name: string, componentsUsed: Record<string, string[]>): boolean {
  const used = componentsUsed[name];
  if (used != null) {
    for (const comp of used) {
      if (comp !== LAYER &&
        comp !== TEXT &&
        comp !== ICON &&
        comp !== IMAGE_VIEW &&
        comp !== name) return true;
    }
  }
  return false;
}
