/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderText } from '@components/headerText';
import { IconButton } from '@components/iconButton';
import { RadioIconButton } from '@components/radioIconButton';
import { SingleLineInput } from '@components/singleLineInput';

import { getCustomCSS, getCustomScript, setCustomCSS, setCustomScript } from '@reducers/editor';
import {
  getCurrentTheme,
  getSiteName,
  getWatermark,
  setSiteName,
  toggleTheme,
  toggleWatermark,
} from '@reducers/themes';

import styles from './styles.module.css';

export const SettingsPage = (): React.JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const customStyles = useSelector(getCustomCSS);
  const customScript = useSelector(getCustomScript);
  const theme = useSelector(getCurrentTheme);
  const currentWatermark = useSelector(getWatermark);
  const currentSiteName = useSelector(getSiteName);

  const [showCSSUpdate, setShowCSSUpdate] = useState<boolean>(false);
  const [showJSUpdate, setShowJSUpdate] = useState<boolean>(false);
  const [currentCustomCssValue, setCurrentCustomCssValue] = useState<string>('');
  const [commitedCSS, setCommitedCSS] = useState<string>('');
  const [currentCustomJSValue, setCurrentCustomJSValue] = useState<string>('');
  const [commitedJS, setCommitedJS] = useState<string>('');

  const alreadyMounted = useRef<boolean>(false);
  const editorCSSRefDOM = useRef<HTMLDivElement>(null);
  const editorCSSRef = useRef<any>(null);
  const editorJSRefDOM = useRef<HTMLDivElement>(null);
  const editorJSRef = useRef<any>(null);

  useEffect(() => {
    if (!alreadyMounted.current) {
      alreadyMounted.current = true;
      const mtheme = theme === 'light' ? 'ace/theme/tomorrow' : 'ace/theme/tomorrow_night';
      if (editorCSSRefDOM.current != null) {
        const cssEditor = window.ace.edit(editorCSSRefDOM.current);
        cssEditor.setTheme(mtheme);
        cssEditor.session.setValue(currentCustomCssValue);
        cssEditor.session.setMode('ace/mode/css');
        editorCSSRef.current = cssEditor;
        cssEditor.getSession().removeListener('change', handleCSSEditorChange);
        cssEditor.session.setValue(customStyles ?? '');
        setCurrentCustomCssValue(customStyles ?? '');
        setCommitedCSS(customStyles ?? '');
        setShowCSSUpdate(false);
      }
      if (editorJSRefDOM.current != null) {
        const jsEditor = window.ace.edit(editorJSRefDOM.current);
        jsEditor.setTheme(mtheme);
        jsEditor.session.setValue(currentCustomJSValue);
        jsEditor.session.setMode('ace/mode/javascript');
        editorJSRef.current = jsEditor;
        jsEditor.getSession().removeListener('change', handleJSEditorChange);
        jsEditor.session.setValue(customScript ?? '');
        setCurrentCustomJSValue(customScript ?? '');
        setCommitedJS(customScript ?? '');
        setShowJSUpdate(false);
      }
    }
  }, []);

  useEffect(() => {
    const mtheme = theme === 'light' ? 'ace/theme/tomorrow' : 'ace/theme/tomorrow_night';
    const editorCSS = editorCSSRef.current;
    const editorJS = editorJSRef.current;
    if (editorCSS != null) editorCSS.setTheme(mtheme);
    if (editorJS != null) editorJS.setTheme(mtheme);
  }, [theme]);

  useEffect(() => {
    if (editorCSSRef.current == null) return;
    editorCSSRef.current.getSession().on('change', handleCSSEditorChange);
    return () => {
      editorCSSRef.current.getSession().removeListener('change', handleCSSEditorChange);
    };
  }, [currentCustomCssValue, commitedCSS]);

  useEffect(() => {
    if (editorJSRef.current == null) return;
    editorJSRef.current.getSession().on('change', handleJSEditorChange);
    return () => {
      editorJSRef.current.getSession().removeListener('change', handleJSEditorChange);
    };
  }, [currentCustomJSValue, commitedJS]);

  const booleanOptions = [
    {
      label: t('true-label'),
      value: 'true',
    },
    {
      label: t('false-label'),
      value: 'false',
    },
  ];

  const themeOptions = [
    {
      label: t('themeLight-tabBar'),
      value: 'light',
    },
    {
      label: t('themeDark-tabBar'),
      value: 'dark',
    },
  ];

  const handleSiteNameChanged = (name: string): void => {
    dispatch(setSiteName(name));
  };

  const notifyToggleWatermark = (): void => {
    dispatch(toggleWatermark());
  };

  const notifyToggleTheme = (): void => {
    dispatch(toggleTheme());
  };

  const handleCSSEditorChange = (): void => {
    if (editorCSSRef.current == null) return;
    const val = editorCSSRef.current.session.getValue();
    if (currentCustomCssValue !== val) setCurrentCustomCssValue(val);
    if (commitedCSS !== val) {
      setShowCSSUpdate(true);
    } else setShowCSSUpdate(false);
  };

  const handleJSEditorChange = (): void => {
    if (editorJSRef.current == null) return;
    const val = editorJSRef.current.session.getValue();
    if (currentCustomJSValue !== val) setCurrentCustomJSValue(val);
    if (commitedJS !== val) {
      setShowJSUpdate(true);
    } else setShowJSUpdate(false);
  };

  const updateCSS = (): void => {
    if (!showCSSUpdate) return;
    setCommitedCSS(currentCustomCssValue);
    handleChangeCustomCSS(currentCustomCssValue);
    setShowCSSUpdate(false);
  };

  const updateJS = (): void => {
    if (!showJSUpdate) return;
    setCommitedJS(currentCustomJSValue);
    handleChangeCustomScript(currentCustomJSValue);
    setShowJSUpdate(false);
  };

  const handleChangeCustomCSS = (value: string): void => {
    dispatch(setCustomCSS(value));
  };

  const handleChangeCustomScript = (value: string): void => {
    dispatch(setCustomScript(value));
  };

  return (
    <div
      className="hide-native-scrollbar"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'scroll',
        padding: 'calc(var(--padding-l) * 2) 0px',
      }}
    >
      <div style={{ width: '550px', height: 'fit-content', paddingBottom: 'var(--padding-l)' }}>
        <HeaderText fontSize="var(--font-size-title)" bold>
          {t('settings-tabBar')}
        </HeaderText>
        <div className={styles.row}>
          <div className={styles.headerText}>
            <HeaderText bold={false} fontSize="var(--font-size-title)">
              {t('site-name-label')}
            </HeaderText>
          </div>
          <div className={styles.optionContainer}>
            <SingleLineInput
              defaultValue={currentSiteName}
              onFinish={handleSiteNameChanged}
              placeholder={t('site-name-placeholder')}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.headerText}>
            <HeaderText bold={false} fontSize="var(--font-size-title)">
              {t('show-watermark-label')}
            </HeaderText>
          </div>
          <div className={styles.optionContainer}>
            <RadioIconButton
              notifyOptionChanged={notifyToggleWatermark}
              defaultOption={currentWatermark.toString()}
              options={booleanOptions}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.headerText}>
            <HeaderText bold={false} fontSize="var(--font-size-title)">
              {t('theme-label')}
            </HeaderText>
          </div>
          <div className={styles.optionContainer}>
            <RadioIconButton
              notifyOptionChanged={notifyToggleTheme}
              defaultOption={theme}
              options={themeOptions}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              marginTop: 'var(--padding-l)',
              marginBottom: 'var(--padding-s)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <HeaderText>{t('change-css')}</HeaderText>
            <IconButton onClick={updateCSS} disabled={!showCSSUpdate} icon="check">
              {t('update-button-label')}
            </IconButton>
          </div>
          <div style={{ height: '550px', marginTop: 'var(--padding-m)' }}>
            <div className="hide-native-scrollbar fileEditor" ref={editorCSSRefDOM}></div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              marginTop: 'var(--padding-l)',
              marginBottom: 'var(--padding-s)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <HeaderText>{t('change-js')}</HeaderText>
            <IconButton onClick={updateJS} disabled={!showJSUpdate} icon="check">
              {t('update-button-label')}
            </IconButton>
          </div>
          <div style={{ height: '550px', marginTop: 'var(--padding-m)' }}>
            <div className="hide-native-scrollbar fileEditor" ref={editorJSRefDOM}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
