import { type ITreeNode } from '@globalUtils/tree';
import {
  type TypeComponentData,
  type IComponentinfo,
  type TypeItems,
  type ReactInfo
} from '@globalTypes/conversor';
import { type TypeDictionary } from '../dictionary/dictionary';
import { createCustomComponent } from './reactCustomComponentMethods';
import { createGeneratedComponent } from './reactGeneratedComponentMethods';

export function createComponent (treenode: ITreeNode, items: TypeItems, componentData: TypeComponentData,
  viewDOM: IComponentinfo, neededInfo: ReactInfo, dictionary: TypeDictionary): void {
  const item = items[treenode.id];
  if ('customcode' in item && item.customcode != null) {
    createCustomComponent(componentData, viewDOM, neededInfo);
  } else {
    createGeneratedComponent(neededInfo, componentData, dictionary, viewDOM, items, treenode);
  }
}
