/* eslint-disable max-lines */
import { type ItemsType } from '..';
import { type PayloadAction } from '@reduxjs/toolkit';

import {
  BackgroundType,
  BorderDataType,
  BorderDirectionType,
  BorderRadiusPartialType,
  BorderRadiusType,
  BorderType,
  CssPositionType,
  DirectionsType,
  ExternalFileType,
  ExternalFunctionType,
  FlexWrapType,
  PositionsType,
  ShadowType,
  type SizeValueType,
  ZIndexType,
} from '@globalTypes/itemProperties';
import { checkResponsiveCase } from './itemUtils';

interface BackgroundPayload extends BackgroundType.BackgroundType {
  id: string;
}
export const changeBackground = (state: ItemsType, action: PayloadAction<BackgroundPayload>): void => {
  const { id, kind, data } = action.payload;
  const item = state.items.byId[id];
  const pKind = kind === 'image' ? 'backgroundImage' : kind;
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.background = { kind: pKind, data };
    }
  } else if (BackgroundType.BackgroundProperty in item) {
    checkResponsiveCase(state, id, { kind: pKind, data }, 'background',
      item[BackgroundType.BackgroundProperty]);
    item.background = { kind: pKind, data };
  }
};

interface PositionPayload {
  [CssPositionType.CssPositionProperty]: CssPositionType.CssPositionType;
  id: string;
}
export const changePosition = (state: ItemsType, action: PayloadAction<PositionPayload>): void => {
  const { id } = action.payload;
  const position = action.payload[CssPositionType.CssPositionProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.cssPosition = position;
    }
  } else if (CssPositionType.CssPositionProperty in item) {
    item[CssPositionType.CssPositionProperty] = position;
  }
};

interface ZIndexPayload {
  [ZIndexType.ZIndexProperty]: ZIndexType.ZIndexType;
  id: string;
}
export const changeZIndex = (state: ItemsType, action: PayloadAction<ZIndexPayload>): void => {
  const { id } = action.payload;
  const zindex = action.payload[ZIndexType.ZIndexProperty];
  const item = state.items.byId[id];
  if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
    if ('state' in item) {
      if (item.state == null) item.state = {};
      if (item.state[item.currentVariant] == null) {
        item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
      }
      item.state[item.currentVariant].normal.zindex = zindex;
    }
  } else if (ZIndexType.ZIndexProperty in item) item[ZIndexType.ZIndexProperty] = zindex;
};

interface PositionLocatedPayload {
  value: SizeValueType.SizeValueType;
  position: string;
  id: string;
}
export const changePositionLocated = (
  state: ItemsType,
  action: PayloadAction<PositionLocatedPayload>
): void => {
  const { id, position, value } = action.payload;
  const item = state.items.byId[id];
  if (item.commonKind === 'layer') {
    let indexing;
    if (position === 'top') {
      indexing = DirectionsType.TopProperty;
    } else if (position === 'bottom') {
      indexing = DirectionsType.BottomProperty;
    } else if (position === 'left') {
      indexing = DirectionsType.LeftProperty;
    } else if (position === 'right') {
      indexing = DirectionsType.RightProperty;
    }
    if (indexing != null) {
      if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
        if ('state' in item) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          const itemVariant = item.state[item.currentVariant];
          if (itemVariant.normal.positions == null) itemVariant.normal.positions = {};
          itemVariant.normal.positions[indexing] = value;
        }
      } else {
        checkResponsiveCase(state, id, value, 'position-' + position,
          item[PositionsType.PositionsProperty][indexing]);
        item[PositionsType.PositionsProperty][indexing] = value;
      }
    }
  }
};

interface FlexWrapPayload {
  [FlexWrapType.FlexWrapProperty]: FlexWrapType.FlexWrapType;
  id: string;
}
export const changeFlexWrap = (state: ItemsType, action: PayloadAction<FlexWrapPayload>): void => {
  const { id } = action.payload;
  const flexWrap = action.payload[FlexWrapType.FlexWrapProperty];
  const item = state.items.byId[id];
  if (FlexWrapType.FlexWrapProperty in item) {
    checkResponsiveCase(state, id, flexWrap, 'wrap', item[FlexWrapType.FlexWrapProperty]);
    item[FlexWrapType.FlexWrapProperty] = flexWrap;
  }
};

interface ExternalFilePayload {
  [ExternalFileType.ExternalFileProperty]: ExternalFileType.ExternalFileType;
  id: string;
}
export const changeExternalFile = (state: ItemsType, action: PayloadAction<ExternalFilePayload>): void => {
  const { id } = action.payload;
  const externalFile = action.payload[ExternalFileType.ExternalFileProperty];
  const item = state.items.byId[id];
  if (ExternalFileType.ExternalFileProperty in item) {
    item[ExternalFileType.ExternalFileProperty] = externalFile;
  } else if (item.commonKind === 'layer') item[ExternalFileType.ExternalFileProperty] = externalFile;
};

interface ExternalFunctionPayload {
  [ExternalFunctionType.ExternalFunctionProperty]: ExternalFunctionType.ExternalFunctionType;
  id: string;
}
export const changeExternalFunction = (
  state: ItemsType,
  action: PayloadAction<ExternalFunctionPayload>
): void => {
  const { id } = action.payload;
  const externalFunction = action.payload[ExternalFunctionType.ExternalFunctionProperty];
  const item = state.items.byId[id];
  if (ExternalFunctionType.ExternalFunctionProperty in item) {
    item[ExternalFunctionType.ExternalFunctionProperty] = externalFunction;
  } else if (item.commonKind === 'layer') {
    item[ExternalFunctionType.ExternalFunctionProperty] = externalFunction;
  }
};

interface BorderWidthPayload {
  [BorderDataType.BorderDataWidthProperty]: SizeValueType.SizeValueType;
  id: string;
}
export const changeBorderWidth = (state: ItemsType, action: PayloadAction<BorderWidthPayload>): void => {
  const { id } = action.payload;
  const borderWidth = action.payload[BorderDataType.BorderDataWidthProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        const place = item.state[item.currentVariant].normal;
        if (place.border == null) place.border = border;
        if ('width' in place.border.data) place.border.data.width = borderWidth;
      }
    } else if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if (BorderDataType.BorderDataWidthProperty in borderData) {
        checkResponsiveCase(state, id, borderWidth, 'borderWidth',
          item[BorderDataType.BorderDataWidthProperty]);
        borderData[BorderDataType.BorderDataWidthProperty] = borderWidth;
      }
    }
  }
};

interface BorderLocationWidthPayload {
  [BorderDataType.BorderDataWidthProperty]: SizeValueType.SizeValueType;
  location: string;
  id: string;
}
export const changeBorderLocationWidth = (
  state: ItemsType,
  action: PayloadAction<BorderLocationWidthPayload>
): void => {
  const { id, location } = action.payload;
  const borderWidth = action.payload[BorderDataType.BorderDataWidthProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if (BorderDirectionType.BorderDirectionProperty in borderData) {
        const borderDirections = borderData[BorderDirectionType.BorderDirectionProperty];
        if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
          if ('state' in item) {
            if (item.state == null) item.state = {};
            if (item.state[item.currentVariant] == null) {
              item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
            }
            const place = item.state[item.currentVariant].normal;
            if (place.border == null) place.border = border;
            if ('direction' in place.border.data) {
              const directionPlace = place.border.data.direction;
              directionPlace[location as keyof typeof directionPlace].width = borderWidth;
            }
          }
        } else if (location === 'top') {
          if (BorderDirectionType.BorderTopProperty in borderDirections) {
            checkResponsiveCase(state, id, borderWidth, 'border-topwidth',
              borderDirections[BorderDirectionType.BorderTopProperty].width);
            borderDirections[BorderDirectionType.BorderTopProperty].width = borderWidth;
          }
        } else if (location === 'bottom') {
          if (BorderDirectionType.BorderBottomProperty in borderDirections) {
            checkResponsiveCase(state, id, borderWidth, 'border-bottomwidth',
              borderDirections[BorderDirectionType.BorderBottomProperty].width);
            borderDirections[BorderDirectionType.BorderBottomProperty].width = borderWidth;
          }
        } else if (location === 'left') {
          if (BorderDirectionType.BorderLeftProperty in borderDirections) {
            checkResponsiveCase(state, id, borderWidth, 'border-leftwidth',
              borderDirections[BorderDirectionType.BorderLeftProperty].width);
            borderDirections[BorderDirectionType.BorderLeftProperty].width = borderWidth;
          }
        } else if (location === 'right') {
          if (BorderDirectionType.BorderRightProperty in borderDirections) {
            checkResponsiveCase(state, id, borderWidth, 'border-rightwidth',
              borderDirections[BorderDirectionType.BorderRightProperty].width);
            borderDirections[BorderDirectionType.BorderRightProperty].width = borderWidth;
          }
        }
      }
    }
  }
};

interface BorderStylePayload {
  [BorderDataType.BorderDataStyleProperty]: string;
  id: string;
}
export const changeBorderStyle = (state: ItemsType, action: PayloadAction<BorderStylePayload>): void => {
  const { id } = action.payload;
  const borderStyle = action.payload[BorderDataType.BorderDataStyleProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
        if ('state' in item) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          const place = item.state[item.currentVariant].normal;
          if (place.border == null) place.border = border;
          if ('style' in place.border.data) {
            place.border.data.style = borderStyle;
          }
        }
      } else if (BorderDataType.BorderDataStyleProperty in borderData) {
        borderData[BorderDataType.BorderDataStyleProperty] = borderStyle;
      }
    }
  }
};

interface BorderColorPayload {
  [BorderDataType.BorderDataColorProperty]: string;
  id: string;
}
export const changeBorderColor = (state: ItemsType, action: PayloadAction<BorderColorPayload>): void => {
  const { id } = action.payload;
  const borderColor = action.payload[BorderDataType.BorderDataColorProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
        if ('state' in item) {
          if (item.state == null) item.state = {};
          if (item.state[item.currentVariant] == null) {
            item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
          }
          const place = item.state[item.currentVariant].normal;
          if (place.border == null) place.border = border;
          if ('color' in place.border.data) {
            place.border.data.color = borderColor;
          }
        }
      } else if (BorderDataType.BorderDataColorProperty in borderData) {
        checkResponsiveCase(state, id, borderColor, 'borderColor',
          borderData[BorderDataType.BorderDataColorProperty]);
        borderData[BorderDataType.BorderDataColorProperty] = borderColor;
      }
    }
  }
};

interface BorderLocationColorPayload {
  [BorderDataType.BorderDataColorProperty]: string;
  location: string;
  id: string;
}
export const changeBorderLocationColor = (
  state: ItemsType,
  action: PayloadAction<BorderLocationColorPayload>
): void => {
  const { id, location } = action.payload;
  const borderColor = action.payload[BorderDataType.BorderDataColorProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if (BorderDirectionType.BorderDirectionProperty in borderData) {
        const borderDirections = borderData[BorderDirectionType.BorderDirectionProperty];
        if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
          if ('state' in item) {
            if (item.state == null) item.state = {};
            if (item.state[item.currentVariant] == null) {
              item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
            }
            const place = item.state[item.currentVariant].normal;
            if (place.border == null) place.border = border;
            if ('direction' in place.border.data) {
              const directionPlace = place.border.data.direction;
              directionPlace[location as keyof typeof directionPlace].color = borderColor;
            }
          }
        } else if (location === 'top') {
          if (BorderDirectionType.BorderTopProperty in borderDirections) {
            checkResponsiveCase(state, id, borderColor, 'border-topwidth',
              borderDirections[BorderDirectionType.BorderTopProperty].color);
            borderDirections[BorderDirectionType.BorderTopProperty].color = borderColor;
          }
        } else if (location === 'bottom') {
          if (BorderDirectionType.BorderBottomProperty in borderDirections) {
            checkResponsiveCase(state, id, borderColor, 'border-bottomwidth',
              borderDirections[BorderDirectionType.BorderBottomProperty].color);
            borderDirections[BorderDirectionType.BorderBottomProperty].color = borderColor;
          }
        } else if (location === 'left') {
          if (BorderDirectionType.BorderLeftProperty in borderDirections) {
            checkResponsiveCase(state, id, borderColor, 'border-leftwidth',
              borderDirections[BorderDirectionType.BorderLeftProperty].color);
            borderDirections[BorderDirectionType.BorderLeftProperty].color = borderColor;
          }
        } else if (location === 'right') {
          if (BorderDirectionType.BorderRightProperty in borderDirections) {
            checkResponsiveCase(state, id, borderColor, 'border-rightwidth',
              borderDirections[BorderDirectionType.BorderRightProperty].color);
            borderDirections[BorderDirectionType.BorderRightProperty].color = borderColor;
          }
        }
      }
    }
  }
};

interface BorderKindPayload {
  [BorderType.BorderKindProperty]: 'all' | 'partial';
  id: string;
}
export const changeBorderKind = (state: ItemsType, action: PayloadAction<BorderKindPayload>): void => {
  const { id } = action.payload;
  const borderKind = action.payload[BorderType.BorderKindProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        const place = item.state[item.currentVariant].normal;
        if (place.border == null) place.border = border;
        if ('data' in place.border) {
          place.border[BorderType.BorderKindProperty] = borderKind;
          if (borderKind === 'partial') {
            if (!(BorderDirectionType.BorderDirectionProperty in border[BorderType.BorderData])) {
              place.border[BorderType.BorderData] = {
                style: border[BorderType.BorderData].style,
                radius: border[BorderType.BorderData].radius,
                direction: {
                  top: {
                    color: border[BorderType.BorderData].color,
                    width: border[BorderType.BorderData].width,
                  },
                  bottom: {
                    color: border[BorderType.BorderData].color,
                    width: border[BorderType.BorderData].width,
                  },
                  left: {
                    color: border[BorderType.BorderData].color,
                    width: border[BorderType.BorderData].width,
                  },
                  right: {
                    color: border[BorderType.BorderData].color,
                    width: border[BorderType.BorderData].width,
                  },
                },
              };
            }
          } else {
            if (BorderDirectionType.BorderDirectionProperty in border[BorderType.BorderData]) {
              place.border[BorderType.BorderData] = {
                style: border[BorderType.BorderData].style,
                radius: border[BorderType.BorderData].radius,
                color: border[BorderType.BorderData].direction.top.color,
                width: border[BorderType.BorderData].direction.top.width,
              };
            }
          }
        }
      }
    } else if (BorderType.BorderKindProperty in border) {
      border[BorderType.BorderKindProperty] = borderKind;
      if (borderKind === 'partial') {
        if (!(BorderDirectionType.BorderDirectionProperty in border[BorderType.BorderData])) {
          border[BorderType.BorderData] = {
            style: border[BorderType.BorderData].style,
            radius: border[BorderType.BorderData].radius,
            direction: {
              top: {
                color: border[BorderType.BorderData].color,
                width: border[BorderType.BorderData].width,
              },
              bottom: {
                color: border[BorderType.BorderData].color,
                width: border[BorderType.BorderData].width,
              },
              left: {
                color: border[BorderType.BorderData].color,
                width: border[BorderType.BorderData].width,
              },
              right: {
                color: border[BorderType.BorderData].color,
                width: border[BorderType.BorderData].width,
              },
            },
          };
        }
      } else {
        if (BorderDirectionType.BorderDirectionProperty in border[BorderType.BorderData]) {
          border[BorderType.BorderData] = {
            style: border[BorderType.BorderData].style,
            radius: border[BorderType.BorderData].radius,
            color: border[BorderType.BorderData].direction.top.color,
            width: border[BorderType.BorderData].direction.top.width,
          };
        }
      }
    }
  }
};

interface BorderRadiusKindPayload {
  [BorderRadiusType.BorderRadiusKindProperty]: 'all' | 'partial';
  id: string;
}
export const changeBorderRadiusKind = (
  state: ItemsType,
  action: PayloadAction<BorderRadiusKindPayload>
): void => {
  const { id } = action.payload;
  const borderRadiusKind = action.payload[BorderRadiusType.BorderRadiusKindProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if (BorderRadiusType.BorderRadiusProperty in borderData) {
        const borderRadiusData = borderData[BorderRadiusType.BorderRadiusProperty];
        if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
          if ('state' in item) {
            if (item.state == null) item.state = {};
            if (item.state[item.currentVariant] == null) {
              item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
            }
            const place = item.state[item.currentVariant].normal;
            if (place.border == null) place.border = border;
            if ('data' in place.border.data.radius) {
              const pPlace = place.border.data.radius;
              pPlace.kind = borderRadiusKind;
              if (borderRadiusKind === 'partial') {
                if (!('topLeft' in borderRadiusData.data)) {
                  pPlace.data = {
                    topLeft: borderRadiusData.data,
                    bottomRight: borderRadiusData.data,
                    bottomLeft: borderRadiusData.data,
                    topRight: borderRadiusData.data,
                  };
                }
              } else {
                if ('topLeft' in borderRadiusData.data) {
                  pPlace.data = borderRadiusData.data.topLeft;
                }
              }
            }
          }
        } else if (BorderRadiusType.BorderRadiusKindProperty in borderRadiusData) {
          borderRadiusData[BorderRadiusType.BorderRadiusKindProperty] = borderRadiusKind;
          if (borderRadiusKind === 'partial') {
            if (!('topLeft' in borderRadiusData.data)) {
              borderRadiusData.data = {
                topLeft: borderRadiusData.data,
                bottomRight: borderRadiusData.data,
                bottomLeft: borderRadiusData.data,
                topRight: borderRadiusData.data,
              };
            }
          } else {
            if ('topLeft' in borderRadiusData.data) {
              borderRadiusData.data = borderRadiusData.data.topLeft;
            }
          }
        }
      }
    }
  }
};

interface BorderRadiusLocationDataPayload {
  value: SizeValueType.SizeValueType;
  location: string;
  id: string;
}
export const changeBorderRadiusLocationData = (
  state: ItemsType,
  action: PayloadAction<BorderRadiusLocationDataPayload>
): void => {
  const { id, location, value } = action.payload;
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if (BorderRadiusType.BorderRadiusProperty in borderData) {
        const borderRadiusData = borderData[BorderRadiusType.BorderRadiusProperty];
        if (BorderRadiusType.BorderRadiusKindProperty in borderRadiusData) {
          const place = borderRadiusData[BorderRadiusType.BorderRadiusDataProperty];
          if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
            if ('state' in item) {
              if (item.state == null) item.state = {};
              if (item.state[item.currentVariant] == null) {
                item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
              }
              const pPlace = item.state[item.currentVariant].normal;
              if (pPlace.border == null) pPlace.border = border;
              if (location in pPlace.border.data.radius.data) {
                const cPlace = pPlace.border.data.radius.data;
                if (location === 'topleft') {
                  if ('topLeft' in cPlace) cPlace.topLeft = value;
                } else if (location === 'topright') {
                  if ('topRight' in cPlace) cPlace.topRight = value;
                } else if (location === 'bottomleft') {
                  if ('bottomLeft' in cPlace) cPlace.bottomLeft = value;
                } else if (location === 'bottomright') {
                  if ('bottomRight' in cPlace) cPlace.bottomRight = value;
                }
              }
            }
          } else if (location === 'topleft') {
            if (BorderRadiusPartialType.TopLeftProperty in place) {
              checkResponsiveCase(state, id, value, 'border-topLeft',
                place[BorderRadiusPartialType.TopLeftProperty]);
              place[BorderRadiusPartialType.TopLeftProperty] = value;
            }
          } else if (location === 'topright') {
            if (BorderRadiusPartialType.TopRightProperty in place) {
              checkResponsiveCase(state, id, value, 'border-topright',
                place[BorderRadiusPartialType.TopRightProperty]);
              place[BorderRadiusPartialType.TopRightProperty] = value;
            }
          } else if (location === 'bottomleft') {
            if (BorderRadiusPartialType.BottomLeftProperty in place) {
              checkResponsiveCase(state, id, value, 'border-bottomleft',
                place[BorderRadiusPartialType.BottomLeftProperty]);
              place[BorderRadiusPartialType.BottomLeftProperty] = value;
            }
          } else if (location === 'bottomright') {
            if (BorderRadiusPartialType.BottomRightProperty in place) {
              checkResponsiveCase(state, id, value, 'border-bottomright',
                place[BorderRadiusPartialType.BottomRightProperty]);
              place[BorderRadiusPartialType.BottomRightProperty] = value;
            }
          }
        }
      }
    }
  }
};

interface BorderRadiusDataPayload {
  [BorderRadiusType.BorderRadiusDataProperty]: SizeValueType.SizeValueType;
  id: string;
}
export const changeBorderRadiusData = (
  state: ItemsType,
  action: PayloadAction<BorderRadiusDataPayload>
): void => {
  const { id } = action.payload;
  const borderRadiusValue = action.payload[BorderRadiusType.BorderRadiusDataProperty];
  const item = state.items.byId[id];
  if (BorderType.BorderProperty in item) {
    const border = item[BorderType.BorderProperty];
    if (BorderType.BorderData in border) {
      const borderData = border[BorderType.BorderData];
      if (BorderRadiusType.BorderRadiusProperty in borderData) {
        const borderRadiusData = borderData[BorderRadiusType.BorderRadiusProperty];
        if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
          if ('state' in item) {
            if (item.state == null) item.state = {};
            if (item.state[item.currentVariant] == null) {
              item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
            }
            const place = item.state[item.currentVariant].normal;
            if (place.border == null) place.border = border;
            if ('data' in place.border.data.radius) {
              place.border.data.radius.data = borderRadiusValue;
            }
          }
        } else if (BorderRadiusType.BorderRadiusKindProperty in borderRadiusData) {
          checkResponsiveCase(state, id, borderRadiusValue, 'radiusAbsolute',
            borderRadiusData[BorderRadiusType.BorderRadiusDataProperty]);
          borderRadiusData[BorderRadiusType.BorderRadiusDataProperty] = borderRadiusValue;
        }
      }
    }
  }
};

interface ShadowColorPayload {
  [ShadowType.ShadowColorProperty]: string;
  id: string;
}
export const changeShadowColor = (state: ItemsType, action: PayloadAction<ShadowColorPayload>): void => {
  const { id } = action.payload;
  const shadowColor = action.payload[ShadowType.ShadowColorProperty];
  const item = state.items.byId[id];
  if (ShadowType.ShadowProperty in item) {
    const shadow = item[ShadowType.ShadowProperty];
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        const place = item.state[item.currentVariant].normal;
        if (place.shadow == null) place.shadow = item.shadow;
        place.shadow.color = shadowColor;
      }
    } else if (ShadowType.ShadowColorProperty in shadow) {
      checkResponsiveCase(state, id, shadowColor, 'shadowColor',
        shadow[ShadowType.ShadowColorProperty]);
      shadow[ShadowType.ShadowColorProperty] = shadowColor;
    }
  }
};

interface ShadowBlurPayload {
  [ShadowType.BlurProperty]: SizeValueType.SizeValueType;
  id: string;
}
export const changeShadowBlur = (state: ItemsType, action: PayloadAction<ShadowBlurPayload>): void => {
  const { id } = action.payload;
  const shadowBlur = action.payload[ShadowType.BlurProperty];
  const item = state.items.byId[id];
  if (ShadowType.ShadowProperty in item) {
    const shadow = item[ShadowType.ShadowProperty];
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        const place = item.state[item.currentVariant].normal;
        if (place.shadow == null) place.shadow = item.shadow;
        place.shadow.blur = shadowBlur;
      }
    } else if (ShadowType.BlurProperty in shadow) {
      checkResponsiveCase(state, id, shadowBlur, 'shadowBlur',
        shadow[ShadowType.BlurProperty]);
      shadow[ShadowType.BlurProperty] = shadowBlur;
    }
  }
};

interface ShadowOffsetXPayload {
  [ShadowType.OffSetXProperty]: SizeValueType.SizeValueType;
  id: string;
}
export const changeShadowOffsetX = (state: ItemsType, action: PayloadAction<ShadowOffsetXPayload>): void => {
  const { id } = action.payload;
  const shadowOffsetX = action.payload[ShadowType.OffSetXProperty];
  const item = state.items.byId[id];
  if (ShadowType.ShadowProperty in item) {
    const shadow = item[ShadowType.ShadowProperty];
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        const place = item.state[item.currentVariant].normal;
        if (place.shadow == null) place.shadow = item.shadow;
        place.shadow.offSetY = shadowOffsetX;
      }
    } else if (ShadowType.OffSetXProperty in shadow) {
      checkResponsiveCase(state, id, shadowOffsetX, 'shadowOffsetX',
        shadow[ShadowType.OffSetXProperty]);
      shadow[ShadowType.OffSetXProperty] = shadowOffsetX;
    }
  }
};

interface ShadowOffsetYPayload {
  [ShadowType.OffSetYProperty]: SizeValueType.SizeValueType;
  id: string;
}
export const changeShadowOffsetY = (state: ItemsType, action: PayloadAction<ShadowOffsetYPayload>): void => {
  const { id } = action.payload;
  const shadowOffsetY = action.payload[ShadowType.OffSetYProperty];
  const item = state.items.byId[id];
  if (ShadowType.ShadowProperty in item) {
    const shadow = item[ShadowType.ShadowProperty];
    if ('currentVariant' in item && item.currentVariant != null && item.currentVariant !== 'default') {
      if ('state' in item) {
        if (item.state == null) item.state = {};
        if (item.state[item.currentVariant] == null) {
          item.state[item.currentVariant] = { normal: { commonKind: item.commonKind }, variable: {} };
        }
        const place = item.state[item.currentVariant].normal;
        if (place.shadow == null) place.shadow = item.shadow;
        place.shadow.offSetY = shadowOffsetY;
      }
    } else if (ShadowType.OffSetYProperty in shadow) {
      checkResponsiveCase(state, id, shadowOffsetY, 'shadowOffsetY',
        shadow[ShadowType.OffSetYProperty]);
      shadow[ShadowType.OffSetYProperty] = shadowOffsetY;
    }
  }
};
