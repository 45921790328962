import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AssetsPage } from '@pages/assetsPage';
import { ComponentsPage } from '@pages/componentsPage';
import { HomePage } from '@pages/homePage';
import { APIPage } from '@pages/pageAPI';
// import { CodePage } from '@pages/pageCode';
import { CodeEditorPage } from '@pages/pageCodeEditor';
import { PageCodeGraph } from '@pages/pageCodeGraph';
import { SettingsPage } from '@pages/pageSettings';
// import { UIEditorPage } from '@pages/pageUIEditor';
import { ShortcutsPage } from '@pages/shortcutsPage';

import { MainLayout } from '@layouts/mainLayout';

import { type AppBarItemsProps, AppsBarLayout, type BarItemProps, Kinds } from '@sections/appsBar';

import { trackEvent } from '@globalUtils/metrics';

import { DOCS_AGUA, WHATSAPP } from '@constants/urls';

import { getMainMenuTab, setMainMenuTab, toggleTheme, triggerSaveMiddleware } from './reducers';

export const MainLanding = (): React.JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const currentAppName = useSelector(getMainMenuTab);

  // const UI = 'ui';
  const HOME = 'home';
  const COMPONENTS = 'components';
  const CODE = 'code';
  const GRAPHS = 'graphs';
  const ASSETS = 'assets';
  const SETTINGS = 'settings';
  const SHORTCUTS = 'shortcuts';
  const APIS = 'apis';

  const appBarItems: AppBarItemsProps = {
    left: [
      /*
      {
        label: t('ui-tabBar'),
        icon: 'format_paint',
        kind: Kinds.BarItemKindPage,
        value: UI,
      },
      */
      {
        label: t('home-tabBar'),
        icon: 'home',
        kind: Kinds.BarItemKindPage,
        value: HOME,
      },
      /*
      {
        label: t('components-tabBar'),
        icon: 'format_paint',
        kind: Kinds.BarItemKindPage,
        value: COMPONENTS,
      },
      */
      {
        label: t('graphs-tabBar'),
        icon: 'code',
        kind: Kinds.BarItemKindPage,
        value: GRAPHS,
      },
      {
        label: t('assets-tabBar'),
        icon: 'photo_library',
        kind: Kinds.BarItemKindPage,
        value: ASSETS,
      },
      /*
      {
        label: t('code-tabBar'),
        icon: 'code',
        kind: Kinds.BarItemKindPage,
        value: CODE,
      },
      */
      /*
      {
        label: t('apis-tabBar'),
        icon: 'database',
        kind: Kinds.BarItemKindPage,
        value: APIS,
      },
      */
      /*
      {
        label: t('settings-tabBar'),
        icon: 'settings',
        kind: Kinds.BarItemKindPage,
        value: SETTINGS,
      },
      */
      /*
      {
        label: t('shortcuts-tabBar'),
        icon: 'keyboard',
        kind: Kinds.BarItemKindPage,
        value: SHORTCUTS,
      },
      */
    ],
    right: [
      {
        label: t('docs-tabBar'),
        icon: 'description',
        kind: Kinds.BarItemKindLink,
        value: 'openDocs',
        link: DOCS_AGUA,
      },
      {
        label: t('support-tabBar'),
        icon: 'call',
        kind: Kinds.BarItemKindLink,
        value: 'openSupport',
        link: WHATSAPP,
      },
      /*
      {
        label: t('save-tabBar'),
        icon: 'cloud_done',
        kind: Kinds.BarItemKindButton,
        value: 'save',
      },
      */
    ],
  };

  // const uiPage = <UIEditorPage />;
  // const componentsPage = <UIEditorPage />;
  const homePage = <HomePage />;
  // const componentsPage = <ComponentsPage />;
  const codePage = <CodeEditorPage />;
  const graphsPage = <PageCodeGraph />;
  const settingsPage = <SettingsPage />;
  const shortcutsPage = <ShortcutsPage />;
  const apisPage = <APIPage />;
  const assetsPage = <AssetsPage />;

  const getCurrentApp = (appName: string): React.JSX.Element => {
    if (appName === COMPONENTS) return <></>; // componentsPage;
    else if (appName === HOME) return homePage;
    else if (appName === CODE) return codePage;
    else if (appName === ASSETS) return assetsPage;
    else if (appName === GRAPHS) return graphsPage;
    else if (appName === SETTINGS) return settingsPage;
    else if (appName === SHORTCUTS) return shortcutsPage;
    else if (appName === APIS) return apisPage;
    return <></>;
  };

  const ca = getCurrentApp(currentAppName);

  const [currentApp, setCurrentApp] = useState<React.JSX.Element>(ca);

  useEffect(() => {
    const ca = getCurrentApp(currentAppName);
    setCurrentApp(ca);
    trackEvent('Went to ' + currentAppName + ' tab');
  }, [currentAppName]);

  const appChangeHandler = (barItem: BarItemProps): void => {
    const appName = barItem.value;
    if (barItem.kind === Kinds.BarItemKindPage) {
      dispatch(setMainMenuTab(appName));
      const cApp = getCurrentApp(appName);
      setCurrentApp(cApp);
    } else if (appName === 'changeTheme') {
      dispatch(toggleTheme());
    } else if (appName === 'save') {
      dispatch(triggerSaveMiddleware(null));
    }
  };

  const appBar = useMemo(() => {
    return (
      <AppsBarLayout
        onChange={appChangeHandler}
        items={appBarItems}
        currentApp={currentAppName}
        hideLeftItemsIcon
      />
    );
  }, [currentAppName]);

  return <MainLayout appBar={appBar} content={currentApp} />;
};
