export const IMAGE_VIEW = 'imageView';
export const DEFAULT_IMAGE = 'https://i.ibb.co/5ndGgWv/image-43.png';
export const IMAGE_DEFAULT_VAL = 'mthumbnail.png';
export const JPG_FORMAT = '.jpg';
export const JPEG_FORMAT = '.jpeg';
export const PNG_FORMAT = '.png';
export const BASE_IMAGE = 'data:image/png;base64,';
export const URL_OPENING = 'url(';
export const HTTP_URL = 'http://';
export const HTTP = 'http';
export const URL_BRACKET = '{url}';
