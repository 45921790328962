/* eslint-disable max-lines */
/* eslint-disable max-len */
export interface TypeFileDictionary {
  href: string;
  hrefParameter: string;
  ref: string;
  classNameRef: string;
  goToTemmplate: string;
  openInNewTab: string;
  selectItem: string;
  openInPreview: string;
  animationRef: string;
  mediaSubscribe: string;
  fontImport: string;
  initialTemplate: string;
  initialTemplateComponent: string;
  indexApp: string;
  appJs: string;
  windowESCEvent: string;
  routePath: string;
  navBarPath: string;
  baseTheme: string;
  baseComponent: string;
  routesArchive: string;
  importsComponents: string;
  'index.css': string;
  packageJson: string;
  baseComponentMode: string;
  baseExternalFile: string;
  aguaNotOverwritable: string;
  componentModeScreen: string;
}

export const ReactFileDictionary: TypeFileDictionary = {
  href: 'onClick={() => openInNewTab("{url}")}',
  hrefParameter: 'onClick={() => openInNewTab(props?.componentStyles?.href??"")}',
  ref: 'ref={{name}_{id}Ref}',
  classNameRef: "({name}_{id}IsVisible ? 'animate__animated animate__{animation}' : '')",
  goToTemmplate: '<Link to="{destination}">',
  openInNewTab: `const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};`,

  selectItem: `const [selectedItem, setSelectedItem] = useState('');
const [layerBorder, setLayerBorder] = useState(false);
useEffect(() => {
    const handler = (e) => {
      if (e?.data?.sender === 'aguaXrays') {
        const displayed = e.data.content;
        setLayerBorder(displayed);
      } else if (e?.data?.sender === 'aguaClickHappened') {
        const id = e.data.content;
        setSelectedItem(id);
      }
    }
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);
const selectItem = (e, id) => {
    setSelectedItem(id);
    window.parent.postMessage({
        sender: "clickItemAgua",
        content: id,
    }, "*");
    e.stopPropagation();

};`,

  openInPreview: `const openInNewTab = url => {
    window.parent.postMessage({
        sender: "openlink",
        content: url,
    }, "*");
};`,

  animationRef: 'const { ref: {name}_{id}Ref, inView: {name}_{id}IsVisible } = useInView();',
  mediaSubscribe: `mediaQueryListener.subscribe((device) => {
    if (device === 'phone') {{phoneSubscribe}
    } else if (device === 'tablet') {{tabletSubscribe}
    } else if (device === 'desktop') {{desktopSubscribe}
    }
});
        `,

  fontImport: `@font-face {
    font-family: "{name}";
    src: url("{url}"){isWoff};
    font-display: swap;
}
            `,

  initialTemplate: `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8" />{icon}
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" media="print"
    onload="this.media='all'">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Round" rel="stylesheet" media="print"
    onload="this.media='all'">
    <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
    rel="stylesheet" media="print" onload="this.media='all'" />
    <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0"
    rel="stylesheet" media="print" onload="this.media='all'" />
    <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    rel="stylesheet" media="print" onload="this.media='all'" />
    <meta name="description" content="{projectDescription}">

    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <title>React App</title>
    <style>
        @font-face {
            font-family: "Courgette";
            font-style: normal;
            font-weight: 400;
            src: url("https://fonts.gstatic.com/s/courgette/v13/wEO_EBrAnc9BLjLQAUk1WPoK7Es.woff2")
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }
        @font-face {
            font-family: "Courgette";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/courgette/v13/wEO_EBrAnc9BLjLQAUk1VvoK.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "DancingScript";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3ROp8ltA.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "DancingScript";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "EBGaramond";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkCo9_S6w.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "EBGaramond";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkBI9_.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Lobster";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9zoymM5Ez.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }
        @font-face {
            font-family: "Lobster";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9zoKmMw.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "NoticiaText";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP12aDtYh0o.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }
        @font-face {
            font-family: "NoticiaText";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP12ZjtY.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "OpenSansCondensed";
            font-style: normal;
            font-weight: 300;
            src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMR6WR.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "OpenSansCondensed";
            font-style: normal;
            font-weight: 300;
            src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMRw.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Pacifico";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6J6MmTpA.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "Pacifico";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6H6Mk.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCGPrEHJA.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Ranchers";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/ranchers/v14/zrfm0H3Lx-P2Xvs2ArDRBi_NXQ.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "Ranchers";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/ranchers/v14/zrfm0H3Lx-P2Xvs2ArDfBi8.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Righteous";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/righteous/v14/1cXxaUPXBpj2rGoU7C9WhnGFucE.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "Righteous";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/righteous/v14/1cXxaUPXBpj2rGoU7C9WiHGF.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "RussoOne";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOwcmJrrg.woff2)
            format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }

        @font-face {
            font-family: "RussoOne";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOz8mJ.woff2)
            format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        
        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        html,
        body,
        #root {
            overflow: hidden;
            width: 100%;
            min-width: 100%;
            height: 100%;
            min-height: 100%;
            margin: 0;
            font-size: 100%;
            font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
            Helvetica, Roboto, Oxygen, Arial, "Apple Color Emoji", "Segoe UI Emoji",
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
    </style>
</head>

<body>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="root"></div>
    <a
        style="position: absolute;z-index:10;right: 16px;bottom: 16px;padding: 8px 16px;background-color: #fff;box-shadow: 3px 3px 10px 3px rgba(56,28,100,.1);border: 1px solid #eff1f4;border-radius: 5px;font-size: 12px;line-height: 24px;-webkit-text-fill-color: #000;-webkit-background-clip: unset !important;font-weight: 500;text-decoration: none !important;"
        target="_new" href="https://agua.app"
    >Made in agua.app</a>
</body>

</html>
            `,

  initialTemplateComponent: `<!DOCTYPE html>
<html lang="en">

<head>
<meta charset="utf-8" />{icon}
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<meta name="description" content="{projectDescription}">

<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta name="theme-color" content="#000000" />
<link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
<title>React App</title>
<style>
    .material-symbols-rounded {
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
    }
    @font-face {
        font-family: "Courgette";
        font-style: normal;
        font-weight: 400;
        src: url("https://fonts.gstatic.com/s/courgette/v13/wEO_EBrAnc9BLjLQAUk1WPoK7Es.woff2")
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }
    @font-face {
        font-family: "Courgette";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/courgette/v13/wEO_EBrAnc9BLjLQAUk1VvoK.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "DancingScript";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3ROp8ltA.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "DancingScript";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "EBGaramond";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkCo9_S6w.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "EBGaramond";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkBI9_.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Lobster";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9zoymM5Ez.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }
    @font-face {
        font-family: "Lobster";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9zoKmMw.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "NoticiaText";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP12aDtYh0o.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }
    @font-face {
        font-family: "NoticiaText";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP12ZjtY.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "OpenSansCondensed";
        font-style: normal;
        font-weight: 300;
        src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMR6WR.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "OpenSansCondensed";
        font-style: normal;
        font-weight: 300;
        src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMRw.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Pacifico";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6J6MmTpA.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "Pacifico";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6H6Mk.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCGPrEHJA.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Ranchers";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/ranchers/v14/zrfm0H3Lx-P2Xvs2ArDRBi_NXQ.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "Ranchers";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/ranchers/v14/zrfm0H3Lx-P2Xvs2ArDfBi8.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Righteous";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/righteous/v14/1cXxaUPXBpj2rGoU7C9WhnGFucE.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "Righteous";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/righteous/v14/1cXxaUPXBpj2rGoU7C9WiHGF.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "RussoOne";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOwcmJrrg.woff2)
        format("woff2");
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }

    @font-face {
        font-family: "RussoOne";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOz8mJ.woff2)
        format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    html,
    body,
    #root {
        overflow: hidden;
        width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
        margin: 0;
        font-size: 100%;
        font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
        Helvetica, Roboto, Oxygen, Arial, "Apple Color Emoji", "Segoe UI Emoji",
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
</style>
</head>

<body>
<noscript>You need to enable JavaScript to run this app.</noscript>
<div id="root"></div>
</body>

</html>
        `,

  indexApp: `import React from 'react';
                import ReactDOM from 'react-dom/client';
                import App from './app';
                import './index.css';
                
                const root = ReactDOM.createRoot(document.getElementById('root'));
                root.render(<App />);
                `,

  appJs: `import React, { useEffect } from 'react';
                import RoutingApp from './agua/routes/routes';
                import { BrowserRouter } from 'react-router-dom';
                
                const App = () =>  {
                    {windowESCEvent}                
                    return (
                        <React.StrictMode>
                            <BrowserRouter>
                                <RoutingApp />
                            </BrowserRouter>
                        </React.StrictMode>
                    );
                }
                
                export default App;`,

  windowESCEvent: `useEffect(() => {
                    window.addEventListener("keydown", function (event) {
                      const key = event.key;
                      if (key === "Escape") {
                        window.parent.postMessage({
                          sender: 'ESCPressed',
                        }, '*');
                      }
                    });
                  }, []);`,

  routePath: '<Route path="{name}" element={<{name} />} />',
  navBarPath: `<li>
                <Link to="/{name}">{name}</Link>
            </li>`,

  baseTheme: `const initialState = {
    {variables}
};

const responsiveValuse = {
    desktop: {
        {desktopValues}
    },
    tablet: {
        {tabletValues}
    },
    phone: {
        {phoneValues}
    }
}

export default (state = initialState, action) => {
    {variablesSetter}

    if (action.type === '{themeName}/ChangeDevice') {
        const values = responsiveValuse[action.payload];
        for (const value in values) state[value] = values[value];
        return {
            ...state
        };
    }

    return state;
};
            `,

  baseComponent: `import React from 'react';
{proptypesImport}import styles from './{nameStyle}.module.css';
{componentImports}
{imagesVariables}

/**
 * {name} component.
 * @component
 * @type {React.FC}
 * 
{propsdocs} * @returns {React.ReactElement} The {name} component.
 */
const {name} = ({props}) => {
    {hasOnclick}

    return(
        {children}
    );
}

{proptypes}

export default {name};`,

  routesArchive: `import React from 'react';
import { Routes, Route } from 'react-router';
{imports}

const RoutingApp = () => {
    return (
        <Routes>
            {routes}
        </Routes>
    );

}

export default RoutingApp;`,

  importsComponents: '{variablesVerification}',
  'index.css': ` {fonts}
            
body, html, #root {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    margin: 0;
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
    Helvetica, Roboto, Oxygen, Arial, "Apple Color Emoji", "Segoe UI Emoji",
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main[custom-agua-item="screen"] {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

main[custom-agua-item="screen"]::-webkit-scrollbar {
    display: none;
}

section[custom-agua-item="layer"],
div[custom-agua-item="layer"],
nav[custom-agua-item="layer"],
header[custom-agua-item="layer"],
footer[custom-agua-item="layer"],
article[custom-agua-item="layer"],
aside[custom-agua-item="layer"],
button[custom-agua-item="layer"],
form[custom-agua-item="layer"] {
    display: flex;
    position: static;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-width: 0px;
    background: transparent;
}
  
section[custom-agua-item="layer"]::-webkit-scrollbar,
div[custom-agua-item="layer"]::-webkit-scrollbar,
nav[custom-agua-item="layer"]::-webkit-scrollbar,
header[custom-agua-item="layer"]::-webkit-scrollbar,
footer[custom-agua-item="layer"]::-webkit-scrollbar,
article[custom-agua-item="layer"]::-webkit-scrollbar,
aside[custom-agua-item="layer"]::-webkit-scrollbar,
button[custom-agua-item="layer"]::-webkit-scrollbar,
form[custom-agua-item="layer"]::-webkit-scrollbar {
    display: none;
}

p[custom-agua-item="text"],
h1[custom-agua-item="text"],
h2[custom-agua-item="text"],
h3[custom-agua-item="text"],
h4[custom-agua-item="text"],
h5[custom-agua-item="text"],
h6[custom-agua-item="text"],
a[custom-agua-item="text"],
ol[custom-agua-item="text"],
ul[custom-agua-item="text"] {
    padding: 0;
    margin: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Roboto", sans-serif;
    font-size: 18px;
    background: #000000;
    height: fit-content;
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
}

img[custom-agua-item="imageView"] {
    box-sizing: border-box;
    object-fit: contain;
    height: 90px;
    min-height: 90px;
    width: 115px;
    min-width: 115px;
}

span[custom-agua-item="icon"] {
    box-sizing: border-box;
    background-color: #000000;
    height: fit-content;
    width: fit-content;
    font-family: 'Material Symbols Rounded';
    background-clip: text;
    -webkit-background-clip: text  !important;
    -webkit-text-fill-color: transparent;
    font-weight: normal;
    font-style: normal;
    font-size: 30px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

`,

  packageJson: `{
    "name": "{projectName}",
    "version": "0.1.0",
    "private": true,
    "dependencies": {        
        "prop-types": "^15.8.1",
        "react": "^18.2.0",
        "react-dom": "^18.2.0",
        "react-router-dom": "^6.10.0",
        "react-scripts": "^2.1.3"{dependencies}
    },
    "engines": {
        "node": "18.x"
    },
    "scripts": {
        "start": "react-scripts start",
        "build": "react-scripts build",
        "test": "react-scripts test",
        "eject": "react-scripts eject"
    },
    "eslintConfig": {
        "extends": [
        "react-app",
        "react-app/jest"
        ]
    },
    "browserslist": {
        "production": [
        ">0.2%",
        "not dead",
        "not op_mini all"
        ],
        "development": [
        "last 1 chrome version",
        "last 1 firefox version",
        "last 1 safari version"
        ]
    }
}
  `,

  baseComponentMode: `import styles from './{nameModule}.module.css';
  {componentImports}  
  import { useState, useRef, useEffect } from 'react';
  
  const {name} = () => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('onloading');
    const firstTime = useRef(false);

    useEffect(() => {
      if (!firstTime.current) {
        fetchData();
        firstTime.current = true;
      }
    }, []);
  
    {iterator}

    function fetchData() {
        const url = '{url}';
        fetch(url).then(async res => {
          const data = await res.json();
          setStatus('ondata');
          setData(iterator(data));
        }).catch(err => {
          setStatus('onerror');
        });
    }  
  
    return (
        <div className={styles[status]}>
          {
            status === 'ondata' ?
              data.map((mData, i) => {
                return (
                  {OnDataPage}
                );
              })
              : status === 'onloading' ?
                {OnLoadingPage}
                : {OnErroPage}
          }
        </div>
    );
  }
  
  export default {name};  
  `,

  baseExternalFile: `{animationImports}import React from 'react';
  import { Link } from 'react-router-dom';
  import styles from './{pagename}.module.css';{customStylesImport}
  {componentImports}{pageExternalFile}{canvasMode}
  
  const {name} = () => {
      {animationRefs}
      {hasOnclick}
      {pageExternalFunctions}
      {canvas}
  
      return(
          {component}
      );
  }
  
  export default {name};`,

  aguaNotOverwritable:
    'export default function AguaNotOverwritable({ children }) { return <div>{children}</div> }',
  componentModeScreen: `import React from 'react';
                import {componentName} from '../../{componentOrLayout}/{component}/{componentEntryFile}';
                
                /**
                 * ComponentMode component.
                 * @component
                 * @type {React.FC}
                 * 
                 * @returns {React.ReactElement} The ComponentMode component.
                 */
                const ComponentMode = () =>{
                    
                    function aguaRender() {
                        return(
                            <{componentName} {childrenVariables}/>
                        );
                    };
                
                    return aguaRender();
                }
                
                export default ComponentMode;`,
};
