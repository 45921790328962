import { DEFAULT_COLOR } from '@compiler/constants/colorConstants';
import { DEFAULT_ICON } from '@compiler/constants/iconConstants';

import { COLOR, ICON, UNIT_PX, WRAP } from '@globalTypes/constants';

import {
  type AnimationType,
  type AnimationWhenVisibleType,
  BackgroundColorType,
  type ClassNamesType, type ClassesType,
  type ClassnameType,
  CommonKindType,
  type CurrentVariantType,
  CursorPointerType,
  type CustomStylesType,
  DimensionType,
  DirectionsType,
  FontSizeType,
  type GroupVariablesType,
  HeightType,
  type HrefType,
  IdType,
  IndexType,
  MarginsType,
  type NameChangedType,
  NameType,
  ParentType,
  type ReferencesType,
  SizeValueType,
  type StateType,
  type VariantType,
  VisibleType,
  WidthType,
} from './common';

export namespace IconType {
  export type IconProperty = 'icon';
  export const IconProperty: IconProperty = ICON;

  export type IconType = string;
}

export interface IconProperties {
  paddingsLocked?: boolean;
  marginsLocked?: boolean;
  [WidthType.WidthProperty]: WidthType.WidthType;
  [HeightType.HeightProperty]: HeightType.HeightType;
  [MarginsType.MarginsProperty]: MarginsType.MarginsType;
  [CommonKindType.CommonKindProperty]: 'icon';
  [IdType.IdProperty]: IdType.IdType;
  [NameType.NameProperty]: NameType.NameType;
  [CursorPointerType.CursorPointerProperty]: CursorPointerType.CursorPointerType;
  [VisibleType.VisibleProperty]: VisibleType.VisibleType;
  [ParentType.ParentProperty]: ParentType.ParentType;
  [IndexType.IndexProperty]: IndexType.IndexType;
  [HrefType.HrefProperty]?: HrefType.HrefType;
  [AnimationType.AnimationProperty]?: AnimationType.AnimationType;
  [AnimationWhenVisibleType.AnimationWhenVisibleProperty]?: AnimationWhenVisibleType.AnimationWhenVisibleType;
  [ClassesType.ClassesProperty]?: ClassesType.ClassesType;
  [ClassnameType.ClassnameProperty]?: ClassnameType.ClassnameType;
  [CustomStylesType.CustomStylesProperty]?: CustomStylesType.CustomStylesType;
  [IconType.IconProperty]: IconType.IconType;
  [BackgroundColorType.BackgroundColorProperty]: BackgroundColorType.BackgroundColorType;
  [FontSizeType.FontSizeProperty]: FontSizeType.FontSizeType;
  [ClassNamesType.ClassNamesProperty]?: ClassNamesType.ClassNamesType;
  [NameChangedType.NameChangedProperty]?: NameChangedType.NameChangedType;
  [ReferencesType.ReferencesProperty]?: ReferencesType.ReferencesType;
  [GroupVariablesType.GroupVariablesProperty]?: GroupVariablesType.GroupVariablesType;
  [VariantType.VariantProperty]?: VariantType.VariantType;
  [CurrentVariantType.CurrentVariantProperty]?: CurrentVariantType.CurrentVariantType;
  [StateType.StateProperty]?: StateType.StateType;
}

export const DefaultIcon: IconProperties = {
  [WidthType.WidthProperty]: {
    [DimensionType.DimensionKindProperty]: WRAP,
  },
  [HeightType.HeightProperty]: {
    [DimensionType.DimensionKindProperty]: WRAP,
  },
  [MarginsType.MarginsProperty]: {
    [DirectionsType.TopProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.BottomProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.LeftProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
    [DirectionsType.RightProperty]: {
      [SizeValueType.SizeValueProperty]: 0,
      [SizeValueType.SizeUnitProperty]: UNIT_PX,
    },
  },
  [CommonKindType.CommonKindProperty]: ICON,
  [IdType.IdProperty]: '0',
  [BackgroundColorType.BackgroundColorProperty]: {
    [BackgroundColorType.BackgroundColorKindProperty]: COLOR,
    [BackgroundColorType.BackgroundColorDataProperty]: DEFAULT_COLOR,
  },
  [FontSizeType.FontSizeProperty]: {
    [SizeValueType.SizeValueProperty]: 18,
    [SizeValueType.SizeUnitProperty]: UNIT_PX,
  },
  [NameType.NameProperty]: ICON,
  [CursorPointerType.CursorPointerProperty]: 'none',
  [VisibleType.VisibleProperty]: true,
  [ParentType.ParentProperty]: '0',
  [IndexType.IndexProperty]: 0,
  [IconType.IconProperty]: DEFAULT_ICON,
};
