export const GRADIENTS = {
  'linear-gradient(135deg, rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%)': 1707431836282,
  'linear-gradient(135deg, rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%)': 1707431838245,
  'linear-gradient(103deg, #ff0036 0%, #ff9116 50%, #fe19fe 100%)': 1707431844160,
  'linear-gradient(135deg, rgba(6,147,227,1) 0%,rgb(155,81,224) 100%)': 1707431850419,
  'linear-gradient(135deg, rgba(74,222,222,1) 0%, rgba(1,167,236,1) 100%)': 1707431852223,
  'linear-gradient(90deg, #000000 0%, #434343 100%)': 1707431860204,
  '#43A047': 1707431974214,
  '#FBC02D': 1707431996500,
  '#1E88E5': 1707432002047,
  '#F44336': 1707432009630,
  '#000000': 1707432019630,
  '#ffffff': 1707432029630
};
