import { type ReferencesGroupType } from '../classesType';

export namespace VariantType {
  export type VariantProperty = 'variants';
  export const VariantProperty: VariantProperty = 'variants';

  export type VariantType = string[];
}

export namespace CurrentVariantType {
  export type CurrentVariantProperty = 'currentVariant';
  export const CurrentVariantProperty: CurrentVariantProperty = 'currentVariant';

  export type CurrentVariantType = string;

}

export namespace StateType {
  export type StateProperty = 'state';
  export const StateProperty: StateProperty = 'state';

  export type StateType = Record<string, ReferencesGroupType>;
}
