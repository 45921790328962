import * as layerReducer from './layerActions';
import * as imageViewReducer from './imageViewActions';
import * as screenReducer from './screenActions';
import * as textReducer from './textActions';
import * as iconReducer from './iconActions';
import * as baseReducer from './baseActions';

export const layerActions = layerReducer;
export const imageViewActions = imageViewReducer;
export const screenActions = screenReducer;
export const textActions = textReducer;
export const iconActions = iconReducer;
export const baseActions = baseReducer;
