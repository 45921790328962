import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BOTTOM,
  CLOSING_PARENTESIS,
  FILL,
  LEFT,
  LINE_JUMP,
  MINUS,
  ORIENTATION,
  RIGHT,
  SEMICOLON_LINE_JUMP,
  TOP,
  VALUE_HEIGHT,
  VALUE_WIDTH,
  VAR,
  VAR_DEFINITION,
} from '../../../../constants/constants';
import { type TypeReactAttribute, type TypeRefItemInfo } from '@globalTypes/conversor';
import { referenceValue } from '../../reactHTMLMethods/reactItemUtils';

export function handleMarginReferenceString (item: TypeItemProperties, referenceItem: TypeReactAttribute,
  groupVerification: boolean, refItemInfo: TypeRefItemInfo, variableName: string): void {
  const isWidthVariable = referenceValue(VALUE_WIDTH, item, false) !== false;
  const isHeightVariable = referenceValue(VALUE_HEIGHT, item, false) !== false;
  const isOrientationVariable = referenceValue(ORIENTATION, item, false) !== false;
  const isVertical = referenceItem[0].includes(TOP) || referenceItem[0].includes(BOTTOM);
  const isHorizontal = referenceItem[0].includes(RIGHT) || referenceItem[0].includes(LEFT);
  if (((isWidthVariable && isHorizontal) ||
      (isHeightVariable && isVertical)) ||
      (isOrientationVariable &&
        (('height' in item && item.height.kind === FILL && isVertical) ||
        ('width' in item && item.width.kind === FILL && isHorizontal)))) {
    if (groupVerification) {
      refItemInfo.referenceString += LINE_JUMP + VAR + referenceItem[0] + VAR_DEFINITION +
        variableName + CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
      refItemInfo.referenceString += referenceItem[0] + VAR_DEFINITION + referenceItem[0] +
      CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
    }
  } else if (groupVerification) {
    refItemInfo.referenceString += referenceItem[0] + VAR_DEFINITION + variableName +
      CLOSING_PARENTESIS + SEMICOLON_LINE_JUMP;
  }
}

export function handleMarginReference (referenceItem: TypeReactAttribute, item: TypeItemProperties,
  refItemInfo: TypeRefItemInfo, variableName: string, groupVerification: boolean,
  noAddVerification: boolean): void {
  handleMarginReferenceString(item, referenceItem, groupVerification, refItemInfo, variableName);
  if (noAddVerification) refItemInfo.referenceList.push(variableName);
  const location = referenceItem[0].split(MINUS);
  refItemInfo.location = location[0] + location[1].substring(0, 1).toUpperCase() + location[1].substring(1);
}
