import React from 'react';

interface MainLayoutProps {
  appBar: React.JSX.Element;
  content: React.JSX.Element;
}
export const MainLayout = ({ appBar, content }: MainLayoutProps): React.JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '25px',
          flexShrink: 0,
          overflow: 'hidden',
        }}
      >
        {appBar}
      </div>
      <div style={{ width: '100%', flex: '1 1 50%', flexShrink: 0, minHeight: '0' }}>{content}</div>
    </div>
  );
};
