import { type ReactInfo } from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BRACKET_ALIGN,
  BRACKET_HOR_ALIGN,
  BRACKET_ORIENTATION,
  BRACKET_VER_ALIGN,
  COLUMN,
  FLEX_START,
  HORIZONTAL_AXIS,
  LINE_JUMP,
  MHORIZONTAL_ALIGNMENT,
  MORIENTATION,
  MVERTICAL,
  MVERTICAL_ALIGNMENT,
  ORIENTATION,
  SEMICOLON,
  TWO_POINTS_SPACE,
  VERTICAL_AXIS
} from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { referenceValue } from '../reactItemUtils';

export function externalLayerOrientationAndAligmentStyle (item: TypeItemProperties, style: string,
  dictionary: TypeDictionary, neededInfo: ReactInfo): string {
  let orientation = orientationStyle(item, dictionary);
  const orientationClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.orientation != null;
  if (referenceValue(ORIENTATION, item, false) !== false ||
    (orientation.includes(COLUMN) && !orientationClass)) {
    orientation = '';
  }
  style = style.replace(BRACKET_ORIENTATION, orientation);
  const aligns = aligmentStyle(item, dictionary);
  const splitedAlign = aligns.split(SEMICOLON);
  let newAligns = '';
  const verAlignClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.verticalAlignment != null;
  const verticalAlignVerification = splitedAlign[0] !== '' &&
      referenceValue(BRACKET_VER_ALIGN, item, false) === false &&
      (!splitedAlign[0].includes(FLEX_START) || verAlignClass);
  if (verticalAlignVerification) newAligns += LINE_JUMP + splitedAlign[0] + SEMICOLON;
  const horAlignClass = neededInfo.variableListInfo == null
    ? false
    : neededInfo.variableListInfo.normal.horizontalAlignment != null;
  const horizontalAlignVerification = splitedAlign[1] !== '' &&
      referenceValue(BRACKET_HOR_ALIGN, item, false) === false &&
      (!splitedAlign[1].includes(FLEX_START) || horAlignClass);
  if (horizontalAlignVerification) newAligns += LINE_JUMP + splitedAlign[1] + SEMICOLON;
  style = style.replace(BRACKET_ALIGN, newAligns);
  return style;
}

export function orientationStyle (item: TypeItemProperties, dictionary: TypeDictionary): string {
  const orientation = 'orientation' in item ? item.orientation ?? MVERTICAL : MVERTICAL;
  const key = (orientation + MORIENTATION) as keyof typeof dictionary;
  return dictionary[key];
}

export function aligmentStyleVariable (item: TypeItemProperties, verticalSame: string,
  horSame: string): string {
  let style = '';
  if ('verticalAlignment' in item && item.verticalAlignment != null) {
    style += VERTICAL_AXIS + (item.nameChanged != null ? item.name : item.id) + TWO_POINTS_SPACE +
      verticalSame;
  }
  if ('horizontalAlignment' in item && item.horizontalAlignment != null) {
    style += HORIZONTAL_AXIS + (item.nameChanged != null ? item.name : item.id) + TWO_POINTS_SPACE + horSame;
  }
  return style;
}

export function aligmentStyleNoVariable (item: TypeItemProperties, dictionary: TypeDictionary,
  orientation: string, verticalSame: string, horSame: string): string {
  let style = '';
  if (orientation === MVERTICAL) {
    if ('verticalAlignment' in item && item.verticalAlignment != null) {
      style += dictionary.primaryAxisAlign + verticalSame;
    }
    if ('horizontalAlignment' in item && item.horizontalAlignment != null) {
      style += dictionary.secondaryAxisAlign + horSame;
    }
  } else {
    if ('horizontalAlignment' in item && item.horizontalAlignment != null) {
      style += dictionary.primaryAxisAlign + horSame;
    }
    if ('verticalAlignment' in item && item.verticalAlignment != null) {
      style += dictionary.secondaryAxisAlign + verticalSame;
    }
  }
  return style;
}

export function aligmentStyle (item: TypeItemProperties, dictionary: TypeDictionary): string {
  const orientation = 'orientation' in item ? item.orientation ?? MVERTICAL : MVERTICAL;
  let style = '';
  const verVar = 'verticalAlignment' in item ? item.verticalAlignment : 'top';
  const horVar = 'horizontalAlignment' in item ? item.horizontalAlignment : 'left';
  const verticalKey = (verVar + MVERTICAL_ALIGNMENT) as keyof typeof dictionary;
  const verticalSame = dictionary[verticalKey];
  const horizontalKey = (horVar + MHORIZONTAL_ALIGNMENT) as keyof typeof dictionary;
  const horSame = dictionary[horizontalKey];
  if (referenceValue(ORIENTATION, item, false) !== false) {
    style = aligmentStyleVariable(item, verticalSame, horSame);
  } else style = aligmentStyleNoVariable(item, dictionary, orientation, verticalSame, horSame);
  return style;
}
