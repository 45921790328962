/* eslint-disable max-lines */
import {
  AGUA_NOT_OVERWRITABLE,
  AGUA_NO_CLOSE,
  BRACKET,
  CLOSE_BRACKET,
  CLOSING_PARENTESIS,
  COMMA,
  FOR,
  FROM,
  ID_EQUAL,
  IMPORT,
  LESS_THAN,
  LINE_JUMP,
  MINUS,
  MORE_THAN,
  NUMBER_SIGN,
  POINT,
  RETURN_BRACKECT,
  RETURN_LESS,
  RETURN_PARENTESIS,
  RETURN_SPACE,
  RETURN_SPACE_BRACKECT,
  RETURN_SPACE_LESS,
  RETURN_SPACE_PARENTESIS,
  SCREEN,
  SEMICOLON,
  SEMICOLON_LINE_JUMP,
  SLASH,
  TWO_POINTS,
  TWO_POINTS_SPACE,
  UNDERSCORE
} from '../../constants/constants';
import { type TypeItems } from '@globalTypes/conversor';

export function getAguaEditable (res: Array<Record<string, string | boolean>>,
  aguaEditables: Record<string, string>, aguaEditablesNotId: Record<string, string>): void {
  for (const mRes of res) {
    const id = mRes.id;
    if (id != null && typeof (id) === 'string') {
      if (mRes.notId != null) {
        if (aguaEditablesNotId[mRes.parentId.toString()] != null) {
          aguaEditablesNotId[mRes.parentId.toString()] += mRes.value;
        } else aguaEditablesNotId[id] = mRes.value.toString();
      } else aguaEditables[id] = mRes.value.toString();
    }
  }
}

export function getAllAguaEditable (userEdited: string, funcs: string[],
  items: TypeItems): Array<Record<string, string>> {
  const res: Array<Record<string, string | boolean>> = [];
  for (const fun of funcs) {
    const resEdited = extractFunction(userEdited, fun.replaceAll(MINUS, UNDERSCORE));
    const val = extractAguaOverwritable(resEdited, items);
    if (val != null) res.push(...val);
  }
  const aguaEditables: Record<string, string> = {};
  const aguaEditablesNotId: Record<string, string> = {};
  getAguaEditable(res, aguaEditables, aguaEditablesNotId);
  return [aguaEditables, aguaEditablesNotId];
}

export function extractAOMinus (referencePoint: string, closingTag: string, actualTag: string,
  userEdited: string, actualIndex: number, parentId: string): string[] {
  if (referencePoint === SLASH && closingTag === '' && actualTag !== '') {
    closingTag = LESS_THAN + actualTag.replace(SLASH, '').replace(MORE_THAN, '');
    actualTag = '';
  } else {
    actualTag = LESS_THAN + actualTag;
  }
  if (closingTag === '' && referencePoint === MORE_THAN) {
    let actualPlace = userEdited.substring(actualIndex).replace("'", '"');
    actualPlace = actualPlace.substring(actualPlace.indexOf(ID_EQUAL + '"') + 4);
    actualPlace = actualPlace.substring(0, actualPlace.indexOf('"'));
    parentId = actualPlace;
  }
  if (actualTag.includes(closingTag)) {
    closingTag = '';
  }
  actualTag = '';
  referencePoint = LESS_THAN;
  return [closingTag, actualTag, parentId, referencePoint];
}

export function extractAOIndexMinusOne (startIndex: number, userEdited: string, closeToken: string,
  start: string): Record<string, string | boolean> {
  let referencePoint = '';
  let actualIndex = startIndex - 1;
  let parentId = '';
  let actualTag = '';
  let closingTag = '';
  while (parentId === '') {
    const actualCharacter = userEdited[actualIndex];
    if (actualCharacter === MORE_THAN) {
      referencePoint = MORE_THAN;
      actualTag = MORE_THAN;
    } else if (actualCharacter === LESS_THAN) {
      const val = extractAOMinus(referencePoint, closingTag, actualTag, userEdited, actualIndex, parentId);
      closingTag = val[0];
      actualTag = val[1];
      parentId = val[2];
      referencePoint = val[3];
    } else if (actualCharacter === SLASH) {
      referencePoint = SLASH;
      if (actualTag === MORE_THAN) actualTag = '';
    }
    actualIndex--;
    if (actualTag !== '' && actualCharacter !== MORE_THAN) actualTag = actualCharacter + actualTag;
    if (actualIndex === -1) parentId = SCREEN;
  }
  const value = start.substring(0, start.indexOf(closeToken) + closeToken.length);
  const notId = true;
  return { parentId, value, notId };
}

export function extractAguaOverwritable (userEdited: string,
  items: TypeItems): Array<Record<string, string | boolean>> | undefined {
  const results = [];
  const openToken = AGUA_NOT_OVERWRITABLE;
  const closeToken = AGUA_NO_CLOSE;
  const otherResults = [];
  while (userEdited.includes(openToken)) {
    const startIndex = userEdited.indexOf(openToken);
    const start = userEdited.substring(startIndex);
    let idDeclaration = FOR + '"';
    let closeIdDeclaration = '"';
    let idIndex = start.indexOf(idDeclaration);
    if (idIndex === -1) {
      idDeclaration = FOR + "'";
      closeIdDeclaration = "'";
      idIndex = start.indexOf(idDeclaration);
    }
    if (idIndex !== -1) {
      let id = start.substring(idIndex + idDeclaration.length);
      id = id.substring(0, id.indexOf(closeIdDeclaration));
      const value = start.substring(0, start.indexOf(closeToken) + closeToken.length);
      if (items[id] != null) results.push({ id, value });
      else idIndex = -1;
    }
    if (idIndex === -1) otherResults.push(extractAOIndexMinusOne(startIndex, userEdited, closeToken, start));
    userEdited = userEdited.substring(userEdited.indexOf(closeToken) + closeToken.length);
  }
  const otherResultsVal = otherResults.length > 0 ? otherResults : undefined;
  return results.length > 0 ? results : otherResultsVal;
}

export function loadAguaUserImports (lines: string[]): string[] {
  const aguaUserImports = [];
  for (const line of lines) {
    const mLine = line.replaceAll("'", '"').trim();
    if (mLine.startsWith(IMPORT)) {
      aguaUserImports.push(mLine);
    }
  }
  return aguaUserImports;
}

export function getImportResultThings (importObject: any, resultThings: RegExpExecArray,
  regexCorchetes: RegExp, importThings: string, importPath: string): void {
  const importCorchetes = importThings.substring(resultThings.index, regexCorchetes.lastIndex);
  const corchetes = importCorchetes.replace(BRACKET, '').replace(CLOSE_BRACKET, '').trim().split(COMMA);
  const importNoCorchetes = importThings.substring(0, resultThings.index).trim() +
   importThings.toString().substring(regexCorchetes.lastIndex + 1).trim();
  const noCorchetes = importNoCorchetes.split(COMMA);
  for (const importCor of corchetes) {
    if (importObject[importPath] == null) importObject[importPath] = {};
    importObject[importPath][importCor.trim()] = true;
  }
  for (const importNoCor of noCorchetes) {
    if (importObject[importPath] == null) importObject[importPath] = {};
    if (importNoCor.trim() !== '') importObject[importPath][importNoCor.trim()] = false;
  }
}

export function getImportObject (imports: string[]): any {
  const importObject: any = {};
  for (const importLine of imports) {
    const regexPath = /from([ \t])+"/gm;
    const result = regexPath.exec(importLine);
    const importPath =
      importLine.substring(regexPath.lastIndex).replaceAll('"', '').replace(SEMICOLON, '').trim();
    const importThings = importLine.substring(7, result?.index);
    const regexCorchetes = /{(.)+}/gm;
    const resultThings = regexCorchetes.exec(importThings);
    if (resultThings != null) {
      getImportResultThings(importObject, resultThings, regexCorchetes, importThings, importPath);
    } else {
      const importNoCorchetes = importThings.split(COMMA);
      for (const importNoCor of importNoCorchetes) {
        if (importObject[importPath] != null) importObject[importPath] = {};
        importObject[importPath][importNoCor.trim()] = false;
      }
    }
  }
  return importObject;
}

export function getUnifyObjectInfo (info: Record<string, string>, importObject: any, route: string): void {
  for (const importThing in importObject[route]) {
    if (importObject[route][importThing] === true) {
      info.brakets += (info.brakets === BRACKET + ' ' ? '' : COMMA + ' ') + importThing;
    } else info.noBrakets += (info.noBrakets === '' ? '' : COMMA + ' ') + importThing;
  }
  if (info.brakets === BRACKET + ' ') info.brakets = '';
  else info.brakets = (info.noBrakets !== '' ? COMMA + ' ' : '') + info.brakets + CLOSE_BRACKET;
}

export function getUnifyimportsString (importObject: any): string {
  let importString = '';
  for (const route in importObject) {
    const info = { noBrakets: '', brakets: '{ ' };
    getUnifyObjectInfo(info, importObject, route);
    importString += IMPORT + ' ' + info.noBrakets + info.brakets + FROM + '"' + route +
      '"' + SEMICOLON_LINE_JUMP;
  }
  return importString;
}

export function unifyImports (componentName: string, aguaGenerated: string, userEdited: string): string {
  const indexStartUser = userEdited.indexOf(componentName);
  const indexStartAgua = aguaGenerated.indexOf(componentName);
  if (indexStartUser === -1) return '';
  const startUser = userEdited.substring(0, indexStartUser);
  const restOfFile = userEdited.substring(indexStartUser);
  const startAgua = aguaGenerated.substring(0, indexStartAgua);
  const userImports = loadAguaUserImports(startUser.split(LINE_JUMP));
  const aguaImports = loadAguaUserImports(startAgua.split(LINE_JUMP));
  const imports = [...new Set([...aguaImports, ...userImports])];
  const importObject = getImportObject(imports);
  const res = getUnifyimportsString(importObject);
  return res + LINE_JUMP + LINE_JUMP + restOfFile;
}

export function getRealReturn (subs: string): number {
  const ret1 = RETURN_PARENTESIS;
  const ret2 = RETURN_SPACE_PARENTESIS;
  const ret3 = RETURN_BRACKECT;
  const ret4 = RETURN_SPACE_BRACKECT;
  const ret5 = RETURN_SPACE;
  const ret6 = RETURN_LESS;
  const ret7 = RETURN_SPACE_LESS;
  const indexRet1 = subs.lastIndexOf(ret1);
  const indexRet2 = subs.lastIndexOf(ret2);
  const indexRet3 = subs.lastIndexOf(ret3);
  const indexRet4 = subs.lastIndexOf(ret4);
  const indexRet5 = subs.lastIndexOf(ret5);
  const indexRet6 = subs.lastIndexOf(ret6);
  const indexRet7 = subs.lastIndexOf(ret7);
  return Math.max(indexRet1, indexRet2, indexRet3, indexRet4, indexRet5, indexRet6, indexRet7);
}

export function addFunction (componentName: string, content: string, value: string): string {
  const startOfComponent = content.indexOf(componentName);
  if (startOfComponent === -1) return 'undefined';
  const realStart = content.substring(0, startOfComponent);
  let subs = content.substring(startOfComponent);
  const mFunctionStart = subs;
  const closeParenthesisIndex = subs.indexOf(CLOSING_PARENTESIS);
  subs = subs.substring(closeParenthesisIndex);
  const openBracesIndex = subs.indexOf(BRACKET) + 1;
  subs = subs.substring(openBracesIndex);
  const endOfDeclaration = closeParenthesisIndex + openBracesIndex;
  const functionDeclaration = mFunctionStart.substring(0, endOfDeclaration);
  let iterate = true;
  let i = 0;
  let countBraces = 1;
  while (iterate) {
    if (subs.charAt(i) === BRACKET) countBraces++;
    if (subs.charAt(i) === CLOSE_BRACKET) countBraces--;
    if (countBraces === 0) iterate = false;
    i++;
  }
  const realAfter = subs.substring(i);
  subs = subs.substring(0, i);
  const realReturn = getRealReturn(subs);
  const before = subs.substring(0, realReturn);
  const after = subs.substring(realReturn);
  const res = realStart + functionDeclaration + before + value + LINE_JUMP + LINE_JUMP + after + realAfter;
  return res;
}

export function extractFunction (content: string, name: string): string {
  const index = content.indexOf(name);
  if (index === -1) return '';

  let substr = content.substring(index + name.length);
  const closeParenthesisIndex = substr.indexOf(CLOSING_PARENTESIS);
  substr = substr.substring(closeParenthesisIndex);
  const openBracesIndex = substr.indexOf(BRACKET) + 1;
  substr = substr.substring(openBracesIndex);

  const start = content.substring(index, index + name.length + closeParenthesisIndex + openBracesIndex);

  let iterate = true;
  let i = 0;
  let countBraces = 1;
  while (iterate) {
    if (substr.charAt(i) === BRACKET) countBraces++;
    if (substr.charAt(i) === CLOSE_BRACKET) countBraces--;
    if (countBraces === 0) iterate = false;
    i++;
  }
  return start + substr.substring(0, i);
}

export function replaceFunction (componentName: string, content: string, name: string,
  value: string): string {
  const index = content.indexOf(name);
  if (index === -1) {
    return addFunction(componentName, content, value);
  }
  const start = content.substring(0, index);
  let substr = content.substring(index + name.length);
  substr = substr.substring(substr.indexOf(CLOSING_PARENTESIS));
  substr = substr.substring(substr.indexOf(BRACKET) + 1);
  let declaration = content.substring(index);
  declaration = declaration.substring(0, declaration.indexOf(CLOSING_PARENTESIS) + 3);
  let iterate = true;
  let i = 0;
  let countBraces = 1;
  while (iterate) {
    if (substr.charAt(i) === BRACKET) countBraces++;
    if (substr.charAt(i) === CLOSE_BRACKET) countBraces--;
    if (countBraces === 0) iterate = false;
    i++;
  }
  const end = substr.substring(i);
  const result = start + declaration + value.replace(name + CLOSING_PARENTESIS + ' ' + BRACKET, '') + end;
  return result;
}

export function replaceAguaGeneratedFromUserEdited (componentName: string, aguaGenerated: string,
  userEdited: string, functionName: string): string {
  const resAgua = extractFunction(aguaGenerated, functionName.replaceAll(MINUS, UNDERSCORE));
  const str = replaceFunction(componentName, userEdited, functionName.replaceAll(MINUS, UNDERSCORE), resAgua);
  return str;
}

export function generalCurrentLine (cssObject: Record<string, Record<string, string>>,
  actualObject: Record<string, string>, actualClassnames: string,
  currentLine: string): [Record<string, string>, string] {
  const infoProperty = currentLine.split(SEMICOLON);
  for (let j = 0; j < infoProperty.length; j++) {
    if (infoProperty[j].includes(CLOSE_BRACKET)) {
      cssObject[actualClassnames] = actualObject;
      actualObject = {};
      actualClassnames = '';
    } else if (!infoProperty[j].includes(TWO_POINTS) && infoProperty[j] !== '') {
      let otherIndex = j - 1;
      let otherProperty = infoProperty[j];
      while (!otherProperty.includes(TWO_POINTS)) otherProperty = infoProperty[--otherIndex];
      const property = otherProperty.split(TWO_POINTS);
      actualObject[property[0].trim()] += infoProperty[j].trim();
    } else if (infoProperty[j] !== '') {
      const property = infoProperty[j].split(TWO_POINTS);
      actualObject[property[0].trim()] = property[1].trim();
    }
  }
  return [actualObject, actualClassnames];
}

export function pointOrNumeralCurrentLine (currentLine: string, actualClassnames: string,
  cssObject: Record<string, Record<string, string>>,
  actualObject: Record<string, string>): [Record<string, string>, string] {
  if (currentLine.endsWith(BRACKET)) {
    actualClassnames = currentLine.replace(BRACKET, '').trim();
  } else {
    const info = currentLine.split(BRACKET);
    actualClassnames = currentLine.trim();
    const infoProperty = info[1].split(SEMICOLON);
    for (const pInfo of infoProperty) {
      if (pInfo.includes(CLOSE_BRACKET)) {
        cssObject[actualClassnames] = actualObject;
        actualObject = {};
        actualClassnames = '';
      } else if (pInfo !== '') {
        const property = pInfo.split(TWO_POINTS);
        actualObject[property[0].trim()] = property[1].trim();
      }
    }
  }
  return [actualObject, actualClassnames];
}

export function getCssObject (css: string): Record<string, Record<string, string>> {
  const cssObject: Record<string, Record<string, string>> = {};
  const cssLines = css.split(LINE_JUMP);
  let actualObject = {};
  let actualClassnames = '';
  for (const cssLine of cssLines) {
    const currentLine = cssLine.trim();
    if (currentLine.startsWith(POINT) || currentLine.startsWith(NUMBER_SIGN)) {
      const mVal = pointOrNumeralCurrentLine(currentLine, actualClassnames, cssObject, actualObject);
      actualObject = mVal[0];
      actualClassnames = mVal[1];
    } else if (currentLine.startsWith(CLOSE_BRACKET)) {
      cssObject[actualClassnames] = actualObject;
      actualObject = '';
      actualClassnames = '';
    } else {
      const mVal = generalCurrentLine(cssObject, actualObject, actualClassnames, currentLine);
      actualObject = mVal[0];
      actualClassnames = mVal[1];
    }
  }
  return cssObject;
}

export function combineCssUserEditGenerated (editedCss: string, generatedCss: string): string {
  const userEdited = getCssObject(editedCss);
  const generated = getCssObject(generatedCss);
  const combinedCss = userEdited;
  for (const cssClass in generated) {
    if (combinedCss[cssClass] != null) {
      for (const property in generated[cssClass]) {
        combinedCss[cssClass][property] = generated[cssClass][property];
      }
    } else combinedCss[cssClass] = generated[cssClass];
  }
  let cssString = '';
  for (const cssClass in combinedCss) {
    cssString += cssClass + BRACKET + LINE_JUMP;
    for (const property in combinedCss[cssClass]) {
      cssString += property + TWO_POINTS_SPACE + combinedCss[cssClass][property] + SEMICOLON_LINE_JUMP;
    }
    cssString += CLOSE_BRACKET + LINE_JUMP;
  }
  return cssString;
}
