import React from 'react';

import styles from './styles.module.css';

export type IIconSize = 'xsmall' | 'small' | 'normal' | 'big' | 'large' | 'xlarge' | 'xxlarge';

export interface IconProps {
  icon: string;
  iconSize?: IIconSize;
  bold?: boolean;
  color?: string;
}

export const Icon = ({
  icon,
  bold = true,
  color = '#000000',
  iconSize = 'normal',
}: IconProps): React.JSX.Element => {
  const weightClass = bold ? styles.iconBold : styles.iconNormal;
  const size = styles[iconSize];

  return (
    <span className={`material-symbols-rounded ${styles.icon} ${weightClass} ${size}`} style={{ color }}>
      {icon}
    </span>
  );
};
