export const TEXT_WEIGHT = 'text-weight';
export const TEXT_STYLE = 'text-style';
export const TEXT = 'text';
export const TEXT_ALIGNMENT = 'textAlignment';
export const TEXT_ALIGN = 'textAlign';
export const MTEXT_ALIGNMENT = 'TextAlignment';
export const WEIGHT = 'weight';
export const TEXT_WEIGHT_VAR = 'textWeight';
export const MTEXT_WEIGHT = 'TextWeight';
export const FONT_FAMILY = 'fontFamily';
export const LEADING = 'leading';
export const SPACING = 'spacing';
export const DEFAULT = 'default';
export const CONTENT = 'content';
export const DEFAULT_FONT = ' -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica,' +
' Roboto, Oxygen, Arial, "Apple Color Emoji", "Segoe UI Emoji", Ubuntu, Cantarell, Fira Sans, Droid Sans,' +
' Helvetica Neue, sans-serif';
export const DEFAULT_TEXT = 'Type...';
export const BOLD = 'bold';
export const ITALIC = 'italic';
export const NORMAL = 'normal';
export const MINUS_STYLE = '-style';
export const P = 'p';
export const TEXT_CONTENT = 'textContent';
export const FONT_WEIGHT = 'font-weight';
export const FONT_STYLE = 'font-style';
export const FONTS = '{fonts}';
export const TEXT_STYLE_BRACKET = '{textStyles}';
export const FONT_SIZE = 'fontSize';
export const FONT_SIZE_CSS = 'font-size';
export const OTF_FORMAT = '.otf';
export const TTF_FORMAT = '.ttf';
export const IS_WOFF = '{isWoff}';
export const WOFF_FORMAT = '.woff';
export const WOFFTWO_FORMAT = '.woff2';
export const IS_WOFF_FORMAT = ' format("woff")';
export const IS_WOFFTWO_FORMAT = ' format("woff2")';
export const BASE_FONT = 'data:font/truetype;base64,';
