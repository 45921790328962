export const WIDTH = 'width';
export const HEIGHT = 'height';
export const WRAP = 'wrap';
export const FILL = 'fill';
export const FILL_VALUE = '100%';
export const CALC_FILL = 'calc(100% - ';
export const CALC_FILL_MARGINS = 'calc(100% - calc(';
export const CALC_VAR = 'calc(var(--';
export const CALC_VAR_MARGINS = ') - calc(';
export const CALC_VAR_MARGINS_VAR = ') - calc(var(--';
export const CALC_VAR_MARGIN_VAR = ') - var(--';
export const CALC_VAR_MARGIN_VAR_TWO = ' + var(--';
export const CALC_VAR_MARGIN_VAR_BOTH = ') + var(--';
export const FIX = 'fix';
export const VALUE = 'value';
export const UNIT = 'unit';
export const UNIT_PX = 'px';
export const UNIT_VH = 'vh';
export const UNIT_VW = 'vw';
export const UNIT_PERCENTAGE = '%';
export const MIN_MINUS = 'min-';
export const DIMENSIONS_BRACKET = '{dimensions}';
export const FIT_CONTENT = 'fit-content';
export const VALUE_HEIGHT = 'valueheight';
export const VALUE_WIDTH = 'valuewidth';
