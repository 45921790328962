import { type ItemsStructure } from '@fragments/screenItemsTree/utils';

export type PlainTree<T> = Record<string, T>;
export type Transformer<T> = (item: T, currentPath: string) => string;

export type Transformers<T> = {
  [K in keyof ItemsStructure]: Transformer<T>;
};

const arrayIncludesItemName = (arr: ItemsStructure[], name: string): [boolean, number] => {
  const isInside = false;
  let counter = 0;
  for (const item of arr) {
    if (item.name === name) return [true, counter];
    counter++;
  }
  return [isInside, -1];
};

export const getStructuredItemsFromPlainObject = <T>(
  items: PlainTree<T>,
  transformers: Transformers<T>,
  collapsable: (item: T, currentPath: string) => boolean,
  initialData: {
    firstName: string;
    firstId: string;
    firstKind: string;
    firstData?: string;
    firstCollapsable: boolean;
  },
  startFromZero: boolean = false
): ItemsStructure | null => {
  const keys = Object.keys(items);
  const l = keys.length;
  if (keys.length === 0) return null;
  const parentName = initialData.firstName;
  const dataTrans = transformers.otherData;
  const res: ItemsStructure = {
    id: initialData.firstId,
    pid: '',
    kind: initialData.firstKind,
    name: initialData.firstName,
    collapsable: initialData.firstCollapsable,
    children: [],
    otherData: initialData.firstData,
  };
  for (let i = startFromZero ? 0 : 1; i < l; i++) {
    const itemsKey = keys[i];
    const item = items[itemsKey];
    let currentRef = res;
    const path = itemsKey.split('/');
    let pid = parentName;
    let currentPath = '';
    for (let i = 0; i < path.length; i++) {
      const partPath = path[i];
      currentPath += partPath + '/';
      let cPath = currentPath;
      if (cPath.endsWith('/')) cPath = cPath.substring(0, cPath.length - 1);
      if (i === path.length - 1) {
        const mRes: ItemsStructure = {
          id: transformers.id(item, cPath),
          pid,
          kind: transformers.kind(item, cPath),
          name: transformers.name(item, cPath),
          collapsable: collapsable(item, cPath),
          children: [],
        };
        if (dataTrans != null) mRes.otherData = dataTrans(item, cPath);
        currentRef.children.push(mRes);
      } else {
        const found = arrayIncludesItemName(currentRef.children, partPath);
        const isInside = found[0];
        let pos = found[1];
        if (isInside) {
          pid = partPath;
        } else {
          const mRes: ItemsStructure = {
            id: transformers.id(item, cPath),
            pid,
            kind: transformers.kind(item, cPath),
            name: transformers.name(item, cPath),
            collapsable: collapsable(item, cPath),
            children: [],
          };
          if (dataTrans != null) mRes.otherData = dataTrans(item, cPath);
          currentRef.children.push(mRes);
          pid = partPath;
          pos = currentRef.children.length - 1;
        }
        currentRef = currentRef.children[pos];
      }
    }
  }
  return res;
};
