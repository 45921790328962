import React from 'react';

import { OpenWindowWrapper } from '@components/openWindowWrapper';

import { trackEvent } from '@globalUtils/metrics';

import { AppBarButton } from './appBarButton';
import styles from './styles.module.css';
import { type AppBarItemsProps, type BarItemInnerProps, type BarItemProps, Kinds } from './types';

export interface AppsBarLayoutProps {
  onChange: (item: BarItemProps) => void;
  items: AppBarItemsProps;
  hideLeftItemsIcon?: boolean;
  hideRightItemsIcon?: boolean;
  currentApp: string;
}
export const AppsBarLayout = ({
  onChange,
  items,
  currentApp,
  hideLeftItemsIcon = false,
  hideRightItemsIcon = false,
}: AppsBarLayoutProps): React.JSX.Element => {
  const handleItemClick = (elem: BarItemInnerProps): void => {
    if (elem.kind !== Kinds.BarItemKindLink) onChange(elem);
  };

  const renderAppItem = (
    elem: BarItemInnerProps,
    active: boolean,
    isLeft: boolean,
    i: number,
    hideIcons: boolean
  ): React.JSX.Element => {
    const appButton = (
      <AppBarButton
        active={active}
        icon={elem.icon}
        label={elem.label}
        kind={elem.kind}
        hideIcon={hideIcons}
      />
    );
    const side = isLeft ? 'Left' : 'Right';
    if (elem.kind !== Kinds.BarItemKindLink) {
      return (
        <div
          key={`appBarItem${side}-${i}`}
          style={{ width: 'fit-content', height: '100%' }}
          onClick={() => {
            handleItemClick(elem);
          }}
        >
          {appButton}
        </div>
      );
    }
    return (
      <OpenWindowWrapper key={`appBarItemLeft-${i}`} link={elem.link} width="fit-content" height="100%">
        <div
          style={{ width: 'fit-content', height: '100%', display: 'flex', alignItems: 'center' }}
          onClick={() => {
            trackEvent('Went to ' + elem.value + ' tab');
          }}
        >
          {appButton}
        </div>
      </OpenWindowWrapper>
    );
  };

  const renderAppItems = (
    items: BarItemInnerProps[],
    isLeft: boolean,
    hideIcons: boolean
  ): React.JSX.Element[] => {
    return items.map((elem, i) => renderAppItem(elem, elem.value === currentApp, isLeft, i, hideIcons));
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--background-color-component-contrast)',
        color: 'var(--title-color)',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: 'var(--font-size-body-small)',
        fontWeight: 500,
        boxSizing: 'border-box',
        borderBottom: '1px solid var(--border-color)',
      }}
    >
      <div className={styles.aguaLogo} style={{ marginRight: '8px' }}></div>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flex: '1 1 50%',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
          paddingLeft: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
          }}
        >
          {renderAppItems(items.left, true, hideLeftItemsIcon)}
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
          }}
        >
          {renderAppItems(items.right, false, hideRightItemsIcon)}
        </div>
      </div>
    </div>
  );
};
