import { type TypeItemProperties } from '@globalTypes/itemProperties';
import {
  BACKGROUND,
  BACKGROUND_COLOR,
  BACKGROUND_IMAGE,
  BACKGROUND_IMAGE_ATTRIBUTES,
  BACKGROUND_IMAGE_CSS,
  BASE_IMAGE,
  CLOSING_PARENTESIS,
  DEFAULT_COLOR,
  DEFAULT_IMAGE,
  ICON,
  LAYER,
  LINE_JUMP,
  NUMBER_SIGN,
  SCREEN,
  TEXT,
  TRANSPARENT,
  TWO_POINTS_SPACE,
  URL_OPENING
} from '../../../../constants/constants';
import { type TypeDictionary } from '../../../dictionary/dictionary';
import { type ReactInfo } from '@globalTypes/conversor';
import { adaptImagePath } from './reactImageMethods';

export function handleBackgroundImage (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo, variable: string): string {
  let constPropety;
  if (variable === BACKGROUND) {
    constPropety = BACKGROUND in item ? item.background : null;
  } else {
    constPropety = BACKGROUND_COLOR in item ? item.backgroundColor : null;
  }
  if (constPropety == null) return '';
  const image = constPropety.data as string;
  let mVal = '';
  if (neededInfo.isPreview) {
    let url = neededInfo.files[image];
    if (url == null) url = DEFAULT_IMAGE;
    else url = BASE_IMAGE + url;
    mVal = URL_OPENING + '"' + url + '"' + CLOSING_PARENTESIS;
  } else {
    const path = adaptImagePath(neededInfo.files[image] ?? '',
      neededInfo.pageName,
      neededInfo.creatingComponent,
      neededInfo.componentsUsed,
      neededInfo.differentArchives);
    mVal = URL_OPENING + '"' + path + '' + CLOSING_PARENTESIS;
  }
  return BACKGROUND_IMAGE_ATTRIBUTES + BACKGROUND_IMAGE_CSS + TWO_POINTS_SPACE + mVal +
    dictionary.styleClosing;
}

export function handleInformation (data: string | string[],
  dictionary: TypeDictionary): Record<string, string> {
  const color = data === TRANSPARENT
    ? data
    : dictionary.colorNumeral + data.toString();
  const closing = data === TRANSPARENT
    ? dictionary.transparentClosing
    : dictionary.styleClosing;
  return { color, closing };
}

export function getGradientColor (item: TypeItemProperties, data: string, closing: string): string {
  const place = BACKGROUND + TWO_POINTS_SPACE;
  if (item.commonKind === LAYER || item.commonKind === SCREEN) {
    return LINE_JUMP + place + data + closing;
  } else return LINE_JUMP + place + data + closing;
}

export function handleTranparentFixedColor (item: TypeItemProperties, variable: string,
  dictionary: TypeDictionary, place: string): string {
  let constPropety;
  if (variable === BACKGROUND) {
    constPropety = BACKGROUND in item ? item.background : null;
  } else {
    constPropety = BACKGROUND_COLOR in item ? item.backgroundColor : null;
  }
  if (constPropety == null) return '';
  const { color, closing } = handleInformation(constPropety.data, dictionary);
  if (color.length > 7) {
    return getGradientColor(item, constPropety.data.toString(), closing);
  } else {
    if (item.commonKind !== LAYER && color === NUMBER_SIGN + DEFAULT_COLOR) return '';
    return LINE_JUMP + place + color + closing;
  }
}

export function handleBackgroundColor (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo, variable: string, place: string): string {
  let constPropety;
  if (variable === BACKGROUND) {
    constPropety = BACKGROUND in item ? item.background : null;
  } else {
    constPropety = BACKGROUND_COLOR in item ? item.backgroundColor : null;
  }
  if (constPropety == null) return '';
  if (constPropety.kind === TRANSPARENT) return '';
  if (constPropety.kind === BACKGROUND_IMAGE) {
    return handleBackgroundImage(item, dictionary, neededInfo, variable);
  } else {
    return handleTranparentFixedColor(item, variable, dictionary, place);
  }
}

export function backgroundColor (item: TypeItemProperties, dictionary: TypeDictionary,
  neededInfo: ReactInfo): string {
  let place = dictionary.backgroundColor;
  if (item.commonKind === TEXT || item.commonKind === ICON) place = dictionary.color;
  const variable = item.commonKind === LAYER ? BACKGROUND : BACKGROUND_COLOR;
  if (item[variable as keyof typeof item] != null) {
    return handleBackgroundColor(item, dictionary, neededInfo, variable, place);
  } else return '';
}
