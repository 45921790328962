import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AssetPreview } from '@sections/assetPreview';

import { ItemsTree } from '@fragments/itemsTree';
import { type ItemSummary } from '@fragments/itemsTree/item';

import { Icon } from '@components/icon';

import { getAssets, getCurrentlySelectedAsset, setCurrentlySelectedAsset } from '@reducers/files';

import { generateId } from '@globalUtils/ids';
import { trackEvent } from '@globalUtils/metrics';
import { objectToHash } from '@globalUtils/strings';
import { type Transformers, getStructuredItemsFromPlainObject } from '@globalUtils/tree/utils';

import { type IFile } from '@globalTypes/conversor';

const transformers: Transformers<IFile> = {
  id: function (item: IFile, currentPath: string): string {
    return generateId() + '-' + currentPath;
  },
  kind: function (item: IFile, currentPath: string): string {
    const path = item.name;
    let mType = 'folder';
    if (path === currentPath) mType = path.substring(path.lastIndexOf('.') + 1);
    return mType;
  },
  name: function (item: IFile, currentPath: string): string {
    const parts = currentPath.split('/').filter((part) => part !== '');
    return parts[parts.length - 1];
  },
  pid: (item: IFile, currentPath: string): string => {
    return '';
  },
  children: (item: IFile, currentPath: string): string => {
    return '';
  },
  collapsable: (item: IFile, currentPath: string): string => {
    return '';
  },
};

const collapsableTransformer = (item: IFile, currentPath: string): boolean => {
  return item.name !== currentPath;
};

export const AssetsPage = (): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const assets = useSelector(getAssets);
  const currentlySelectedAsset = useSelector(getCurrentlySelectedAsset);

  const [foldedItems, setFoldedItems] = useState<Record<string, boolean>>({});

  const formattedAssets = useMemo(() => {
    type CollectionType = Record<string, IFile>;
    const mObj: CollectionType = {};
    assets.forEach((asset) => {
      mObj[asset.name] = asset;
    });
    return getStructuredItemsFromPlainObject<IFile>(
      mObj,
      transformers,
      collapsableTransformer,
      {
        firstName: t('main-asset-label'),
        firstId: '1',
        firstKind: 'mainFolder',
        firstData: '',
        firstCollapsable: true,
      },
      true
    );
  }, [assets]);

  const getPreviewItem = (item: ItemSummary): React.JSX.Element => {
    if (item.kind === 'mainFolder') return <></>;
    if (item.kind === 'folder') return <Icon icon="folder" color="var(--tittle-color)" />;
    return <Icon icon="image" color="var(--tittle-color)" />;
  };

  const MTree =
    // eslint-disable-next-line multiline-ternary
    formattedAssets != null ? (
      <ItemsTree
        key={objectToHash(foldedItems)}
        disableSorting
        items={formattedAssets}
        currentlySelectedItem={currentlySelectedAsset}
        currentlyCollapsedItems={foldedItems}
        itemIconPreviewGetter={getPreviewItem}
        notifyChangeHierarchy={() => {}}
        notifyItemClick={(itemId: string) => {
          dispatch(setCurrentlySelectedAsset(itemId));
          trackEvent('Selected asset');
        }}
        notifyItemFolding={(id: string, folded: boolean) => {
          const copy: Record<string, boolean> = JSON.parse(JSON.stringify(foldedItems));
          copy[id] = copy[id] != null ? !copy[id] : true;
          setFoldedItems(copy);
        }}
        notifyItemHovered={() => {}}
        notifyContextMenuOpened={() => {}}
        notifyAddViewMenuOpened={() => {}}
      />
    ) : (
      <></>
    );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <div
        className="hide-native-scrollbar"
        style={{
          width: 'var(--side-pane-width)',
          height: '100%',
          flexShrink: 0,
          overflow: 'scroll',
          borderRight: '1px solid var(--border-color)',
        }}
      >
        <div
          className="hide-native-scrollbar"
          style={{
            borderBottom: '1px solid var(--border-color)',
            paddingTop: 'var(--padding-m)',
            paddingLeft: '0px',
            paddingRight: 'var(--padding-m)',
            paddingBottom: 'var(--padding-m)',
            display: 'flex',
            flex: '1 1 50%',
            minHeight: 0,
            overflowY: 'scroll',
          }}
        >
          {MTree}
        </div>
      </div>
      <div style={{ flex: '1 1 50%', height: '100%' }}>
        <AssetPreview assetId={currentlySelectedAsset ?? ''} />
      </div>
    </div>
  );
};
