'use strict';

import { type ITreeNodeAttributes, TreeNode, type ITreeNode } from './treeNode';

export interface ITreeAttributes {
  root: ITreeNodeAttributes;
}

export interface ITree {
  root: ITreeNode;
  getAllNodeSubnodes: (id: string) => string[];
}

class Tree implements ITree {
  root: ITreeNode = new TreeNode('', '');

  constructor (root: ITreeNode) {
    if (root == null) console.warn('Tree constructor expected root as param.');
    else this.root = root;
  }

  static objectToTreeNode (children: ITreeNodeAttributes[]): ITreeNode[] {
    const r = [];
    const l = children.length;
    for (let i = 0; i < l; i++) {
      const child = children[i];
      const p = child.parent ?? Number.NEGATIVE_INFINITY;
      const node = new TreeNode(p, child.id);
      const mChildren = this.objectToTreeNode(child.children);
      node.children = mChildren;
      r.push(node);
    }
    return r;
  }

  arraymove (arr: ITreeNode[], fromIndex: number, toIndex: number): void {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  moveChildren (parent: ITreeNode, id: string, newIndex: number): void {
    const l = parent.children.length;
    let prevIndex = 0;
    for (let i = 0; i < l; i++) {
      if (parent.children[i].id === id) {
        prevIndex = i;
        i = l;
      }
    }
    this.arraymove(parent.children, prevIndex, newIndex);
  }

  parentHasChild (parentId: string, childId: string): boolean {
    return this.root.parentHasChild(parentId, childId);
  }

  addChild (idParent: string, idChild: string): boolean {
    return this.root.addChild(idParent, idChild) != null;
  }

  addNode (idParent: string, nodeChild: ITreeNode): boolean {
    return this.root.addNode(idParent, nodeChild) != null;
  }

  removeNode (id: string): boolean {
    const children = this.root.getChildren();
    let node = null;
    for (let i = 0; i < children.length && node == null; i++) {
      if (children[i].id === id) {
        node = children[i];
        this.root.children.splice(i, 1);
      } else node = children[i].removeChild(id);
    }
    return node != null;
  }

  removeChildFromRoot (id: string): boolean {
    let removed = false;
    const children = this.root.getChildren();
    for (let i = 0; i < children.length && !removed; i++) {
      if (children[i].getId() === id) {
        this.root.children.splice(i, 1);
        removed = true;
      }
    }
    return removed;
  }

  removeNodeChildren (id: string): boolean {
    return this.root.removeChildren(id);
  }

  getAllNodeSubnodes (id: string): string[] {
    return this.root.getAllSubnodes(id);
  }

  getNodeById (id: string): ITreeNode | null {
    return this.root.getNodeById(id);
  }
}

export { Tree };
