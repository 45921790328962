import { type TypeItemProperties } from '@globalTypes/itemProperties';
import { ANTD_FORM, BRACKET_TAG, FORM, LAYER, MAIN, P, SECTION, TEXT } from '../../../../constants/constants';
import { type ReactInfo } from '@globalTypes/conversor';

export function handleTag (item: TypeItemProperties, component: string, neededInfo: ReactInfo): string {
  if ('tag' in item) {
    if (item.tag === ANTD_FORM) {
      component = component.replaceAll(BRACKET_TAG, FORM);
      neededInfo.pageHasForm[neededInfo.pageName] = true;
    } else component = component.replaceAll(BRACKET_TAG, item.tag);
  } else component = component.replaceAll(BRACKET_TAG, '');
  return component;
}

export function handleComponentTag (item: TypeItemProperties, component: string,
  neededInfo: ReactInfo): string {
  const isLayerOrText = item.commonKind === LAYER || item.commonKind === TEXT;
  if (isLayerOrText) {
    if ('tag' in item && item.tag != null) {
      component = handleTag(item, component, neededInfo);
    } else {
      const defTagVal = item.commonKind === TEXT ? P : SECTION;
      const tagDefault = isLayerOrText ? defTagVal : MAIN;
      component = component.replaceAll(BRACKET_TAG, tagDefault);
    }
  }
  return component;
}
