export const SIZE_VARIABLE = 'Size';
export const COLOR_VARIABLE = 'Color';
export const TEXT_VARIABLE = 'Text';
export const IMAGE_VARIABLE = 'Image';
export const FONT_VARIABLE = 'Font';
export const STICKY_VARIABLE = 'Sticky';
export const WRAP_VARIABLE = 'Wrap';
export const VISIBILITY_VARIABLE = 'Visibility';
export const CHILDREN_VARIABLES = '{childrenVariables}';
export const VAR_OPENING = 'var(--';
export const VAR_DEFINITION = ': var(--';
export const RESPONSIVENESS_THEME = 'Responsiveness';
export const DEFAULT_THEME = 'Default';
export const FLEX = 'flex';
export const NONE = 'none';
export const MNONE = 'None';
export const UNSET = 'unset';
export const SCROLL = 'scroll';
export const HIDDEN = 'hidden';
export const STICKY = 'sticky';
export const DISPLAY = 'display';
export const IMAGE = 'Image';
export const DESKTOP = 'desktop';
export const TABLET = 'tablet';
export const PHONE = 'phone';
export const VARIABLE_CONTENT = '{variableContent}';
export const STRING = 'string';
export const BOOLEAN = 'boolean';
export const E = 'e';
export const T = 't';
export const F = 'f';
export const TRUE = 'true';
export const EQUAL_TRUE = '={true}';
export const EQUAL_FALSE = '={false}';
export const BOOL = 'bool';
export const REFERENCE_BRACKET = '{reference}';
export const REFERENCES_BRACKET = '{references}';
export const RESPONSIVE_VARIABLE = 'responsiveVariable';
