import { type ActionLocationDictionaryType } from './themeConstants.types';

export * from './themeConstants.types';

export const ACTION_LOCATION_DICTIONARY: ActionLocationDictionaryType = {
  'executeChangeDimension{value}Action': {
    width: 'valuewidth',
    height: 'valueheight',
    reference: ['valueheight', 'valuewidth', 'fontSize']
  },
  executeChangeDimensionFixedAction: {
    width: 'valuewidth',
    height: 'valueheight',
    reference: ['valueheight', 'valuewidth', 'fontSize']
  },
  executeChangeTextSizeAction: {
    reference: 'fontSize',
    width: 'fontSize',
    height: 'fontSize'
  },
  executeChangeTextFontAction: {
    reference: 'fontFamily'
  },
  executeChangeTextContentAction: {
    reference: 'text'
  },
  executeChangeShadowColorAction: {
    reference: 'shadowColor'
  },
  executeChangeBorderColorAction: {
    reference: 'borderColor'
  },
  executeChangeBorderWidthAction: {
    reference: 'borderWidth'
  },
  executeChangePaddingAction: {
    top: 'padding-top',
    bottom: 'padding-bottom',
    right: 'padding-right',
    left: 'padding-left'
  },
  executeChangeMarginAction: {
    top: 'margin-top',
    bottom: 'margin-bottom',
    right: 'margin-right',
    left: 'margin-left'
  },
  executeChangeIconColorAction: {
    reference: 'iconColor'
  },
  executeChangeIconAction: {
    reference: 'icon'
  },
  executeChangeShadowOffsetXAction: {
    x: 'shadowOffsetX'
  },
  executeChangeShadowOffsetYAction: {
    y: 'shadowOffsetY'
  },
  executeChangeShadowBlurAction: {
    reference: 'shadowBlur'
  },
  executeChangeBorderRadiusAction: {
    reference: 'radiusAbsolute'
  },
  executeChangeBackgroundColorAction: {
    reference: 'backgroundColor'
  },
  executeChangeImageAction: {
    reference: 'src'
  },
  executeChangeTextWeightAction: {
    reference: 'text-weight'
  },
  executeChangeTextAlignmentAction: {
    reference: 'textAlign'
  },
  executeChangeGotoAction: {
    reference: 'goTo'
  },
  'executeChangeAlignmentVertical{value}Action': {
    reference: '{verticalAlignment}'
  },
  'executeChangeAlignmentHorizontal{value}Action': {
    reference: '{horizontalAlignment}'
  },
  'executeChangeOrientation{value}Action': {
    reference: 'orientation'
  },
  executeChangeOrientationHorizontalAction: {
    reference: 'orientation'
  },
  executeChangeOrientationVerticalAction: {
    reference: 'orientation'
  },
  executeChangeScrollAction: {
    reference: 'scrollable'
  },
  executeUpdateWrapAction: {
    reference: 'wrap'
  },
  executeChangeVisibilityAction: {
    reference: 'visible'
  },
  executeChangePositionAction: {
    top: 'position-top',
    bottom: 'position-bottom',
    right: 'position-right',
    left: 'position-left'
  },
  executeUpdateStickyAction: {
    reference: 'sticky'
  }
};
