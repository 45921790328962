export interface TypePunctuactionDictionary {
  styleClosing: string;
  dimensionClosing: string;
  endingChildren: string;
}

export const ReactPunctuationDictionary: TypePunctuactionDictionary = {
  styleClosing: ';',
  dimensionClosing: ';',
  endingChildren: '',
};
