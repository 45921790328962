/* eslint-disable max-lines */
import { type ITree, type ITreeNode } from '@globalUtils/tree';

import {
  ACTION_LOCATION_DICTIONARY,
  type ActionLocationDictionaryType,
  type ComponentImportInfo,
  type IAguaRender,
  type IComponentinfo,
  type IConversorThemesType,
  type IParametersTypeList,
  type IReactMethods,
  type MediaValuesType,
  type ReactInfo,
  type ReactInfoClassnames,
  type ReactInfoComponent,
  type ReactInfoConversor,
  type ReactInfoFile,
  type ReactInfoPages,
  type ReactInfoProyect,
  type ReactOtherInfo,
  type TypeAttributeReferences,
  type TypeItems,
  type TypeResponsiveValues,
  type TypeVariableValues,
} from '@globalTypes/conversor';
import { type TypeItemProperties } from '@globalTypes/itemProperties';

import { Dictionary, type TypeDictionary } from './dictionary/dictionary';
import { createComponent } from './reactComponentMethods/reactComponentMethods';
import {
  componentPreviewMode,
  createComponentModeThemes,
} from './reactFileMethods/reactComponentPreviewMethods';
import { canvasMode, createPages } from './reactFileMethods/reactConversionMethods';
import { createPage } from './reactFileMethods/reactCreatePagesMethods';
import { createTreeParent } from './reactFileMethods/reactCreateTreeParentMethods';
import { createThemes } from './reactThemeMethods/reactThemeMethods';

class ReactMethods implements IReactMethods {
  dictionary: TypeDictionary;
  references: ActionLocationDictionaryType;

  styles: any;
  dependenciesList: any;
  isPreview: boolean = false;
  proyectFonts: string[] = [];
  customStyles: any;
  proyectAnimation: boolean = false;
  proyectAnimationVisible: boolean = false;
  objectClassnames: Record<string, Array<[string, boolean]>> = {};
  groupListClassnames: Record<string, string> = {};

  files: Record<string, string> = {};
  differentArchives: any;

  pagesReferences: Record<string, string[]> = {};
  pageResponsiveVariables: Record<string, string> = {};
  pagesResponsiveValues: Record<string, [string, string, string]> = {};
  pageHasChildrenHref: Record<string, boolean> = {};
  pageHasChildrenHrefExternal: Record<string, boolean> = {};
  pageHasAnimation: Record<string, boolean> = {};
  pageAnimationVisible: Record<string, string> = {};
  pageExternalFile: Record<string, string> = {};
  pageExternalFiles: Record<string, any> = {};
  pageExternalFunctions: Record<string, string> = {};
  pagesExtraStyles: Record<string, string> = {};
  pagesResponsiveVariables: Record<string, [string, string, string]> = {};
  pageHasForm: Record<string, boolean> = {};
  pageImage: Record<string, string> = {};

  componentCreated: Record<string, [string, string[]]> = {};
  componentsUsed: Record<string, string[]> = {};
  componentsImport: Record<string, ComponentImportInfo> = {};
  layoutCode: any;
  customCode: any;
  componentCode: any;
  stylesComponent: Record<string, string[]> = {};
  componentChilrenForProps: Record<string, string> = {};
  componentProptypes: Record<string, string> = {};
  componentPropParameters: Record<string, string> = {};

  themes: string = `:root {
  }`;

  hasVariables: any;
  aditionalStyles: any;
  variableContent: any;
  orientationVariables: any;
  fillVariables: any;
  aditionalTextVariable: any;

  unmodifiedItem: TypeItems = {};
  watermark: boolean = true;
  unmodifiedTree: any;
  scripts: string = '';
  classDeviceCoverage: any;
  infoThemes: any;
  responsiveValues: any;
  attributeReference: any;
  variantsAttributeReference: any;
  responsiveVariableInClass: any;
  referenceGroupsList: any;
  items: TypeItems = {};

  variableList: string = '';
  creatingComponent: boolean = false;
  themeList: string = '';
  groupList: string = '';
  responsiveVariables: any;
  classNameGroups: [string, string, string] = ['', '', ''];
  variablesDefaultResponsive: [string, string, string] = ['', '', ''];
  classnamesDefaultResponsive: [string, string, string] = ['', '', ''];
  extraStyles: Record<string, string> = {};
  referenceString: string = '';
  canvas: boolean = false;
  pageName: string = '';
  name: string = '';
  aguaEditables: any;
  hasAttributes: number = 0;
  defaultVariables: string = '';
  webVariables: string = '';
  phoneVariables: string = '';
  tabletVariables: string = '';
  userPagesObject: any;
  firstPage: string = '';
  userEdited: any;
  parametersValuesList: any;
  idsWithName: any;
  renderingComponent: boolean = false;
  componentModeActive: boolean = false;
  mediaValues?: MediaValuesType;

  viewDOM: IComponentinfo = {
    components: {},
    getComponentByName(name: string) {
      for (const mCom in this.components) {
        const compo = this.components[mCom];
        if (compo.name === name || compo.name.replaceAll(' ', '') === name) {
          return compo;
        }
      }
      return null;
    },
  };

  constructor() {
    this.initializePageVariables();
    this.initializeComponentsVariables();
    this.initializeThemesVariables();
    this.initializeFileVariables();
    this.initializeProjectVariables();
    this.dictionary = new Dictionary().react;
    this.references = ACTION_LOCATION_DICTIONARY;
  }

  initializeProjectVariables(): void {
    this.styles = {};
    this.dependenciesList = {};
    this.isPreview = false;
    this.proyectFonts = [];
    this.customStyles = {};
    this.proyectAnimation = false;
    this.proyectAnimationVisible = false;
    this.objectClassnames = {};
    this.groupListClassnames = {};
  }

  initializeFileVariables(): void {
    this.files = {};
    this.differentArchives = {};
  }

  initializePageVariables(): void {
    this.pagesReferences = {};
    this.pageResponsiveVariables = {};
    this.pagesResponsiveValues = {};
    this.pageHasChildrenHref = {};
    this.pageHasChildrenHrefExternal = {};
    this.pageHasAnimation = {};
    this.pageAnimationVisible = {};
    this.pageExternalFile = {};
    this.pageExternalFiles = {};
    this.pageExternalFunctions = {};
    this.pagesExtraStyles = {};
    this.pagesResponsiveVariables = {};
    this.pageHasForm = {};
    this.pageImage = {};
  }

  initializeComponentsVariables(): void {
    this.componentCreated = {};
    this.componentsUsed = {};
    this.componentsImport = {};
    this.layoutCode = {};
    this.customCode = {};
    this.componentCode = {};
    this.stylesComponent = {};
    this.componentChilrenForProps = {};
    this.componentProptypes = {};
    this.componentPropParameters = {};
  }

  initializeThemesVariables(): void {
    this.themes = `:root {
    }`;
    this.hasVariables = {};
    this.aditionalStyles = {};
    this.variableContent = {};
    this.orientationVariables = {};
    this.fillVariables = {};
    this.aditionalTextVariable = {};
  }

  getNeededInfoComponent(): ReactInfoComponent {
    return {
      componentChilrenForProps: this.componentChilrenForProps,
      componentProptypes: this.componentProptypes,
      componentPropParameters: this.componentPropParameters,
      componentCreated: this.componentCreated,
      componentsUsed: this.componentsUsed,
      componentsImport: this.componentsImport,
      layoutCode: this.layoutCode,
      customCode: this.customCode,
      componentCode: this.componentCode,
      stylesComponent: this.stylesComponent,
    };
  }

  setNeededInfoComponent(neededInfo: ReactInfo): void {
    this.componentChilrenForProps = neededInfo.componentChilrenForProps;
    this.componentProptypes = neededInfo.componentProptypes;
    this.componentPropParameters = neededInfo.componentPropParameters;
    this.componentCreated = neededInfo.componentCreated;
    this.componentsUsed = neededInfo.componentsUsed;
    this.componentsImport = neededInfo.componentsImport;
    this.layoutCode = neededInfo.layoutCode;
    this.customCode = neededInfo.customCode;
    this.componentCode = neededInfo.componentCode;
    this.stylesComponent = neededInfo.stylesComponent;
  }

  getNeededInfoFile(): ReactInfoFile {
    return {
      files: this.files,
      differentArchives: this.differentArchives,
    };
  }

  getNeededInfoPages(): ReactInfoPages {
    return {
      pagesReferences: this.pagesReferences,
      pageResponsiveVariables: this.pageResponsiveVariables,
      pagesResponsiveValues: this.pagesResponsiveValues,
      pageHasAnimation: this.pageHasAnimation,
      pageAnimationVisible: this.pageAnimationVisible,
      pageExternalFile: this.pageExternalFile,
      pageExternalFiles: this.pageExternalFiles,
      pageExternalFunctions: this.pageExternalFunctions,
      pageImage: this.pageImage,
      pagesExtraStyles: this.pagesExtraStyles,
      pagesResponsiveVariables: this.pagesResponsiveVariables,
      pageHasForm: this.pageHasForm,
      pageHasChildrenHref: this.pageHasChildrenHref,
      pageHasChildrenHrefExternal: this.pageHasChildrenHrefExternal,
    };
  }

  setNeededInfoPages(neededInfo: ReactInfo): void {
    this.pagesReferences = neededInfo.pagesReferences;
    this.pageResponsiveVariables = neededInfo.pageResponsiveVariables;
    this.pagesResponsiveValues = neededInfo.pagesResponsiveValues;
    this.pageHasAnimation = neededInfo.pageHasAnimation;
    this.pageAnimationVisible = neededInfo.pageAnimationVisible;
    this.pageExternalFile = neededInfo.pageExternalFile;
    this.pageExternalFiles = neededInfo.pageExternalFiles;
    this.pageExternalFunctions = neededInfo.pageExternalFunctions;
    this.pageImage = neededInfo.pageImage;
    this.pagesExtraStyles = neededInfo.pagesExtraStyles;
    this.pagesResponsiveVariables = neededInfo.pagesResponsiveVariables;
    this.pageHasForm = neededInfo.pageHasForm;
    this.pageHasChildrenHref = neededInfo.pageHasChildrenHref;
    this.pageHasChildrenHrefExternal = neededInfo.pageHasChildrenHrefExternal;
  }

  getNeededInfoClassnames(): ReactInfoClassnames {
    return {
      objectClassnames: this.objectClassnames,
      groupListClassnames: this.groupListClassnames,
    };
  }

  setNeededInfoClassnames(neededInfo: ReactInfo): void {
    this.objectClassnames = neededInfo.objectClassnames;
    this.groupListClassnames = neededInfo.groupListClassnames;
  }

  getNeededInfoProyect(): ReactInfoProyect {
    return {
      proyectAnimation: this.proyectAnimation,
      proyectAnimationVisible: this.proyectAnimationVisible,
      isPreview: this.isPreview,
      proyectFonts: this.proyectFonts,
    };
  }

  setNeededInfoProyect(neededInfo: ReactInfo): void {
    this.proyectAnimation = neededInfo.proyectAnimation;
    this.proyectAnimationVisible = neededInfo.proyectAnimationVisible;
    this.isPreview = neededInfo.isPreview;
    this.proyectFonts = neededInfo.proyectFonts;
  }

  getNeededInfoConversor(): ReactInfoConversor {
    return {
      unmodifiedItem: this.unmodifiedItem,
      watermark: this.watermark,
      unmodifiedTree: this.unmodifiedTree,
      scripts: this.scripts,
      classDeviceCoverage: this.classDeviceCoverage,
      infoThemes: this.infoThemes,
      responsiveValues: this.responsiveValues,
      attributeReference: this.attributeReference,
      variantsAttributeReference: this.variantsAttributeReference,
      responsiveVariableInClass: this.responsiveVariableInClass,
      referenceGroupsList: this.referenceGroupsList,
      items: this.items,
    };
  }

  setNeededInfoConversor(neededInfo: ReactInfo): void {
    this.unmodifiedItem = neededInfo.unmodifiedItem;
    this.watermark = neededInfo.watermark;
    this.unmodifiedTree = neededInfo.unmodifiedTree;
    this.scripts = neededInfo.scripts;
    this.classDeviceCoverage = neededInfo.classDeviceCoverage;
    this.infoThemes = neededInfo.infoThemes;
    this.responsiveValues = neededInfo.responsiveValues;
    this.attributeReference = neededInfo.attributeReference;
    this.variantsAttributeReference = neededInfo.variantsAttributeReference;
    this.responsiveVariableInClass = neededInfo.responsiveVariableInClass;
    this.referenceGroupsList = neededInfo.referenceGroupsList;
    this.items = neededInfo.items;
  }

  getOtherInfo(): ReactOtherInfo {
    return {
      variableList: this.variableList,
      creatingComponent: this.creatingComponent,
      themeList: this.themeList,
      groupList: this.groupList,
      responsiveVariables: this.responsiveVariables,
      classNameGroups: this.classNameGroups,
      variablesDefaultResponsive: this.variablesDefaultResponsive,
      classnamesDefaultResponsive: this.classnamesDefaultResponsive,
      extraStyles: this.extraStyles,
      referenceString: this.referenceString,
      canvas: this.canvas,
      pageName: this.pageName,
      name: this.name,
      aguaEditables: this.aguaEditables,
      hasAttributes: this.hasAttributes,
      defaultVariables: this.defaultVariables,
      webVariables: this.webVariables,
      phoneVariables: this.phoneVariables,
      tabletVariables: this.tabletVariables,
      userPagesObject: this.userPagesObject,
      firstPage: this.firstPage,
      userEdited: this.userEdited,
      parametersValuesList: this.parametersValuesList,
      idsWithName: this.idsWithName,
      renderingComponent: this.renderingComponent,
      componentModeActive: this.componentModeActive,
    };
  }

  getNeededInfo(): ReactInfo {
    const neededInfo: ReactInfo = {
      ...this.getNeededInfoConversor(),
      ...this.getNeededInfoClassnames(),
      ...this.getNeededInfoComponent(),
      ...this.getNeededInfoFile(),
      ...this.getNeededInfoProyect(),
      ...this.getNeededInfoPages(),
      ...this.getOtherInfo(),
      styles: this.styles,
      dependenciesList: this.dependenciesList,
      themes: this.themes,
      hasVariables: this.hasVariables,
      aditionalStyles: this.aditionalStyles,
      variableContent: this.variableContent,
      orientationVariables: this.orientationVariables,
      fillVariables: this.fillVariables,
      customStyles: this.customStyles,
      aditionalTextVariable: this.aditionalTextVariable,
      references: this.references,
      mediaValues: this.mediaValues,
    };
    return neededInfo;
  }

  setNeededInfo(neededInfo: ReactInfo): ReactInfo {
    this.styles = neededInfo.styles;
    this.dependenciesList = neededInfo.dependenciesList;
    this.themes = neededInfo.themes;
    this.hasVariables = neededInfo.hasVariables;
    this.aditionalStyles = neededInfo.aditionalStyles;
    this.variableContent = neededInfo.variableContent;
    this.orientationVariables = neededInfo.orientationVariables;
    this.fillVariables = neededInfo.fillVariables;
    this.customStyles = neededInfo.customStyles;
    this.aditionalTextVariable = neededInfo.aditionalTextVariable;
    this.references = neededInfo.references;
    this.setNeededInfoConversor(neededInfo);
    this.setNeededInfoClassnames(neededInfo);
    this.setNeededInfoComponent(neededInfo);
    this.setNeededInfoPages(neededInfo);
    this.setNeededInfoProyect(neededInfo);
    return neededInfo;
  }

  createPage(item: TypeItemProperties, body: string, pageName: string): string {
    const neededInfo = this.getNeededInfo();
    const mVal = createPage(item, body, pageName, neededInfo, this.dictionary, this.viewDOM);
    this.setNeededInfo(neededInfo);
    return mVal;
  }

  createTreeParent(tree: ITreeNode, items: TypeItems, pageName: string, viewDOM: IComponentinfo): string {
    const neededInfo = this.getNeededInfo();
    const mVal = createTreeParent(tree.children, items, pageName, neededInfo, this.dictionary, viewDOM);
    this.setNeededInfo(neededInfo);
    return mVal;
  }

  createPages(tree: ITree, items: TypeItems, icon: string, name: string, userEdited: any): IAguaRender {
    const neededInfo = this.getNeededInfo();
    const data = { tree: tree.root.children, items, icon, name };
    const mVal = createPages(data, userEdited, neededInfo, this.dictionary, this.viewDOM);
    this.setNeededInfo(neededInfo);
    return mVal;
  }

  createThemes(
    values: TypeVariableValues,
    responsiveValues: TypeResponsiveValues,
    references: TypeAttributeReferences,
    viewDOM: IComponentinfo
  ): void {
    const neededInfo = this.getNeededInfo();
    createThemes(values, responsiveValues, references, viewDOM, neededInfo, this.dictionary);
    this.setNeededInfo(neededInfo);
  }

  addFile(name: string, data: string): void {
    this.files[name] = data;
  }

  createComponent(treeNode: ITreeNode, items: TypeItems, component: any, viewDOM: IComponentinfo): void {
    const neededInfo = this.getNeededInfo();
    createComponent(treeNode, items, component, viewDOM, neededInfo, this.dictionary);
    this.setNeededInfo(neededInfo);
  }

  createComponentModeThemes(
    themes: IConversorThemesType,
    items: TypeItems,
    root: ITreeNode,
    params: IParametersTypeList,
    componentName: string
  ): void {
    const neededInfo = this.getNeededInfo();
    createComponentModeThemes(themes, items, root, params, componentName, neededInfo);
    this.setNeededInfo(neededInfo);
  }

  componentPreviewMode(
    componentName: string,
    root: ITreeNode,
    componentEntryFile: string
  ): [IAguaRender, string, { components: string }] {
    const neededInfo = this.getNeededInfo();
    const mVal = componentPreviewMode(componentName, root, componentEntryFile, neededInfo, this.dictionary);
    this.setNeededInfo(neededInfo);
    this.renderingComponent = false;
    return mVal;
  }

  canvasMode(
    selectedScreen: string,
    tree: ITree,
    items: TypeItems,
    icon: string,
    name: string,
    userEdited: any,
    viewDOM: IComponentinfo
  ): IAguaRender {
    const neededInfo = this.getNeededInfo();
    const data = {
      selectedScreen,
      tree: tree.root.children,
      items,
      icon,
      name,
      treeItem: tree.root,
      item: items[selectedScreen],
    };
    const mVal = canvasMode(data, userEdited, neededInfo, this.dictionary, viewDOM);
    this.setNeededInfo(neededInfo);
    return mVal;
  }
}

export { ReactMethods };
