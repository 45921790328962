import React from 'react';

export const Divider = ({
  color,
  marginTop,
  marginBottom,
  orientation,
}: {
  color?: string;
  marginTop?: string;
  marginBottom?: string;
  orientation: 'vertical' | 'horizontal';
}): React.JSX.Element => {
  return (
    <div
      style={{
        width: orientation === 'horizontal' ? '100%' : '1px',
        height: orientation === 'horizontal' ? '1px' : '100%',
        backgroundColor: color ?? 'var(--border-color)',
        marginTop: marginTop ?? '0px',
        marginBottom: marginBottom ?? '0px',
        flexShrink: 0,
      }}
    ></div>
  );
};
